import { Box, Button, Typography } from '@mui/material';
import { Error } from '@mui/icons-material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  handleOpenModal: () => void;
  handleClickReUpLoadScan: () => void;
};

export const RealogramCandidateError: FC<Props> = ({
  handleOpenModal,
  handleClickReUpLoadScan,
}) => {
  return (
    <>
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100%"
      >
        <Error
          sx={{
            width: '32px',
            height: '32px',
            color: theme.palette.icons.disabled,
          }}
        />
        <Typography
          variant="subtitle1"
          color={theme.palette.textBlack.disabled}
          lineHeight="30px"
          mt={1}
        >
          画像のスキャンに失敗しました
        </Typography>
        <Typography
          variant="body2"
          color={theme.palette.textBlack.disabled}
          lineHeight="21px"
          mt={1}
        >
          最初からアップロードをやり直してください。
        </Typography>
        <Button
          variant="outlined"
          sx={{
            width: 242,
            padding: 0,
            height: 40,
            borderColor: theme.palette.primary.lightBorder,
            marginTop: 4,
          }}
          onClick={handleClickReUpLoadScan}
        >
          <Typography component="div" fontSize={14} fontWeight={700}>
            最初からアップロードをやり直す
          </Typography>
        </Button>
      </Box>

      <Box component="div" textAlign="right" py={1} px={3}>
        <Button
          variant="text"
          onClick={handleOpenModal}
          sx={{ color: theme.palette.system.errorMain, fontWeight: 700 }}
        >
          スキャン結果を削除
        </Button>
      </Box>
    </>
  );
};
