import { DirectoryIcon } from '@components/molecules/directoryIcon/directoryIcon';
import { PlanogramsTableMenu } from '@components/molecules/planogramsTableMenu/planogramsTableMenu';
import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { ActionVisible } from '@components/molecules/actionVisible/actionVisible';
import { ShelfIcon } from '@components/molecules/shelfIcon/shelfIcon';
import { OrganizationStatusLabel } from '@components/organisms/organizationStatusLabel/organizationStatusLabel';
import { useBayPlanCodes } from '@hooks/useBayPlanCodes';
import { usePlanogramImage } from '@hooks/useImage';
import { useGetPlanogramPermission } from '@hooks/useGetPlanogramPermission';
import { MoreVert } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from '@mui/material';
import { useAppSelector } from '@store/index';
import { Format, format } from '@utils/date';
import { FC, useState } from 'react';
import { PlanogramSharePermissionProps } from 'types/sharePermission';
import { PlanogramDirectory, PlanogramDirectorySearch } from 'types/planogram';
import { theme } from '../../../theme';

type Props = {
  imageHeight: number;
  planogramDirectory: PlanogramDirectory | PlanogramDirectorySearch;
  isFilteredByViewed: boolean;
  handleOpenDeleteDialog: (planogram: PlanogramDirectory) => void;
  handleUpdatePlanogramName: (planogramDirecotry: PlanogramDirectory) => void;
  handleUpdateDirectoryName: (planogramDirecotry: PlanogramDirectory) => void;
  handleMoveDirectory: (planogramDirecotry: PlanogramDirectory) => void;
  handleClonePlanogram: (planogramDirecotry: PlanogramDirectory) => void;
  handleClick: (planogram: PlanogramDirectory) => void;
  handleFavoriteClick: (id: string, isFavorite: boolean) => void;
  handleSharePermission: (item: PlanogramSharePermissionProps) => void;
};

export const PlanogramCard: FC<Props> = ({
  imageHeight,
  planogramDirectory,
  isFilteredByViewed,
  handleOpenDeleteDialog,
  handleUpdatePlanogramName,
  handleUpdateDirectoryName,
  handleMoveDirectory,
  handleClonePlanogram,
  handleClick,
  handleFavoriteClick,
  handleSharePermission,
}) => {
  const { type, planogram, name, updated_at } = planogramDirectory;
  const { current_user_accessed_at } =
    planogramDirectory as PlanogramDirectorySearch;
  const { bayPlanCode } = useBayPlanCodes(planogram?.bay_plan_code_id);
  const planogramId = planogram?.id ?? 0;
  const { image, error: imageError } = usePlanogramImage(
    { planogramId: planogramId, camera: 'front-orthographic', size: 'medium' },
    { skip: !planogramId }
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDirectory = planogramDirectory.type === 'directory';
  const { user: me } = useAppSelector((state) => state.Auth);
  const isStarred = planogramDirectory.favorite?.owner_id === me?.id;

  const { isNotEnable: isNotCanUpdate } = useGetPlanogramPermission({
    action: 'update',
    planogram: planogramDirectory,
    isPlanogram: false,
    isCan: false,
  });
  const { isNotEnable: isNotCanCopy } = useGetPlanogramPermission({
    action: 'copy',
    planogram: planogramDirectory,
    isPlanogram: false,
    isCan: false,
  });
  const { isNotEnable: isNotCanDelete } = useGetPlanogramPermission({
    action: 'delete',
    planogram: planogramDirectory,
    isPlanogram: false,
    isCan: false,
  });
  const { isNotEnable: isNotCanMove } = useGetPlanogramPermission({
    action: 'move',
    planogram: planogramDirectory,
    isPlanogram: false,
    isCan: false,
  });
  const { isNotEnable: isNotCanRead } = useGetPlanogramPermission({
    action: 'read',
    planogram: planogramDirectory,
    isPlanogram: false,
    isCan: false,
  });
  const { isNotEnable: isNotCanReadRole } = useGetPlanogramPermission({
    action: 'read_role',
    planogram: planogramDirectory,
    isPlanogram: false,
    isCan: false,
  });
  const { isEnable: isCanUpdate } = useGetPlanogramPermission({
    action: 'update',
    planogram: planogramDirectory,
    isPlanogram: false,
    isCan: true,
  });
  const { isEnable: isCanRead } = useGetPlanogramPermission({
    action: 'read',
    planogram: planogramDirectory,
    isPlanogram: false,
    isCan: true,
  });

  return (
    <>
      <Card
        sx={{
          border: `1px solid ${theme.palette.dividerBlack.dark}`,
          position: 'relative',
          height: '100%',
        }}
        elevation={0}
        onClick={() => {
          if (isNotCanRead) return;
          handleClick(planogramDirectory);
        }}
      >
        <CardActionArea
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            ...(isNotCanRead && {
              '&:hover': {
                cursor: 'default',
              },
            }),
          }}
        >
          <Box component="div" sx={{ width: '100%' }}>
            {planogramId ? (
              <Box component="div" sx={{ position: 'relative' }}>
                {isNotCanRead || !image || !!imageError ? (
                  <Box
                    component="div"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: theme.palette.backgroundBlack.primary,
                      borderBottom: `1px solid ${theme.palette.dividerBlack.medium}`,
                    }}
                    height={imageHeight}
                  >
                    {isNotCanRead && <ShelfIcon sx={{ fontSize: '56px' }} />}
                  </Box>
                ) : (
                  <CardMedia
                    image={image}
                    height={imageHeight}
                    component="img"
                    sx={{
                      borderBottom: `1px solid ${theme.palette.dividerBlack.medium}`,
                      backgroundColor: theme.palette.white.primary,
                      objectFit: 'contain',
                    }}
                  />
                )}
                {isStarred && (
                  <StarIcon
                    htmlColor={theme.palette.confidence.middle}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      width: 20,
                      height: 20,
                      m: 0.5,
                    }}
                  />
                )}
              </Box>
            ) : (
              <Box
                component="div"
                sx={{
                  background: theme.palette.backgroundBlack.primary,
                  borderBottom: `1px solid ${theme.palette.dividerBlack.medium}`,
                }}
                height={imageHeight}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <DirectoryIcon />
                {isStarred && (
                  <StarIcon
                    htmlColor={theme.palette.confidence.middle}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      width: 20,
                      height: 20,
                      m: 0.5,
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
          <CardContent
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              p: '8px',
              width: '100%',
              height: '143px',
            }}
          >
            <Box
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: '4px',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: isNotCanRead
                    ? theme.palette.textBlack.disabled
                    : theme.palette.textBlack.primary,
                  overflow: 'hidden',
                  display: '-webkit-box',
                  // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                  WebkitLineClamp: 2,
                  // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {name}
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
                }}
                sx={{ p: 0, width: '32px', height: '32px' }}
              >
                <MoreVert />
              </IconButton>
            </Box>
            {type === 'file' && (
              <Typography
                variant="caption1"
                sx={{
                  color: isNotCanRead
                    ? theme.palette.textBlack.disabled
                    : theme.palette.textBlack.primary,
                  alignItems: 'center',
                  mb: '8px',
                }}
              >
                {bayPlanCode?.name}
              </Typography>
            )}
            <Box
              component="div"
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 'auto',
                mb: '8px',
              }}
            >
              {type === 'file' && (
                <OrganizationStatusLabel
                  id={planogram?.organization_status_id}
                  isDisabledCard={isNotCanRead}
                />
              )}
              <Box component="div" sx={{ ml: 'auto' }}>
                <ActionVisible
                  isCanUpdate={isCanUpdate}
                  isCanRead={isCanRead}
                  isDirectory={isDirectory}
                  isGridView
                />
              </Box>
            </Box>

            <Box
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="caption1"
                sx={{
                  color: isNotCanRead
                    ? theme.palette.textBlack.disabled
                    : theme.palette.textBlack.secondary,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {planogram?.owner.fullname ??
                  planogramDirectory?.owner?.fullname}
              </Typography>
              <Typography
                variant="caption1"
                sx={{
                  color: isNotCanRead
                    ? theme.palette.textBlack.disabled
                    : theme.palette.textBlack.secondary,
                  whiteSpace: 'nowrap',
                  pl: 0.5,
                }}
              >
                {format(
                  isFilteredByViewed ? current_user_accessed_at : updated_at,
                  Format.datetime
                )}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
      <PlanogramsTableMenu
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        handleOpenDeleteDialog={() => {
          setAnchorEl(null);
          handleOpenDeleteDialog(planogramDirectory);
        }}
        handleUpdatePlanogramName={() => {
          setAnchorEl(null);
          handleUpdatePlanogramName(planogramDirectory);
        }}
        handleUpdateDirectoryName={() => {
          setAnchorEl(null);
          handleUpdateDirectoryName(planogramDirectory);
        }}
        handleMoveDirectory={() => {
          setAnchorEl(null);
          handleMoveDirectory(planogramDirectory);
        }}
        handleClonePlanogram={() => {
          setAnchorEl(null);
          if (planogram) {
            handleClonePlanogram(planogramDirectory);
          }
        }}
        handleFavoriteClick={() => {
          handleFavoriteClick(planogramDirectory.id, isStarred);
        }}
        handleSharePermission={() => {
          setAnchorEl(null);
          handleSharePermission({
            planogramDirectory: planogramDirectory,
            type: planogramDirectory.type,
          });
        }}
        status={planogram?.status}
        isDirectory={isDirectory}
        isStarred={isStarred}
        isDisabledSharePermission={isNotCanReadRole}
        isDisabledDuplicateButton={isNotCanCopy}
        isDisabledDeleteButton={isNotCanDelete}
        isDisabledMoveButton={isNotCanMove}
        isDisabledEditButton={isNotCanUpdate}
      />
    </>
  );
};
