import { SvgIcon, SvgIconProps } from '@mui/material';

type StartIconProps = SvgIconProps & {
  pathFill?: string;
};

export const StartIcon = ({ pathFill = 'white', ...props }: StartIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M10.0003 13.5C11.4731 13.5 12.667 12.3061 12.667 10.8333C12.667 9.36057 11.4731 8.16667 10.0003 8.16667C8.52757 8.16667 7.33366 9.36057 7.33366 10.8333C7.33366 12.3061 8.52757 13.5 10.0003 13.5Z"
        fill={pathFill}
      />
      <path
        d="M7.50033 2.5L5.97533 4.16667H3.33366C2.41699 4.16667 1.66699 4.91667 1.66699 5.83333V15.8333C1.66699 16.75 2.41699 17.5 3.33366 17.5H16.667C17.5837 17.5 18.3337 16.75 18.3337 15.8333V5.83333C18.3337 4.91667 17.5837 4.16667 16.667 4.16667H14.0253L12.5003 2.5H7.50033ZM10.0003 15C7.70033 15 5.83366 13.1333 5.83366 10.8333C5.83366 8.53333 7.70033 6.66667 10.0003 6.66667C12.3003 6.66667 14.167 8.53333 14.167 10.8333C14.167 13.1333 12.3003 15 10.0003 15Z"
        fill={pathFill}
      />
    </SvgIcon>
  );
};
