import { planogramsApi } from '@api/services/planogram';
import { GetPlanogramDirectoryApiV1PlanogramDirectoriesGetParams } from '@api/types/generated';
import { getNextPage, mapper } from '@api/utils/pagenation';
import { useInfiniteQuery } from '@tanstack/react-query';
import { objectToSnake } from 'ts-case-convert';

export const planogramDirectoriesQueryKey = 'planogram_directories';

type Params = GetPlanogramDirectoryApiV1PlanogramDirectoriesGetParams & {
  enabled?: boolean;
};

export const usePlanogramDirectories = ({
  enabled = false,
  ...params
}: Params) => {
  return useInfiniteQuery({
    enabled,
    queryKey: [planogramDirectoriesQueryKey, params],
    queryFn: ({ pageParam = 0 }) => {
      return planogramsApi.getPlanogramDirectories({
        ...objectToSnake(params),
        offset: pageParam,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => getNextPage(lastPage.pager),
    select: (data) => {
      return {
        breadcrumb: data.pages.at(-1)?.breadcrumb,
        parent: data.pages.at(-1)?.parent,
        pager: data.pages.at(-1)?.pager,
        planogram_directories: mapper(data?.pages, 'planogram_directories'),
      };
    },
    refetchOnWindowFocus: false,
  });
};
