import { StopPropagationButton } from '@components/organisms/stopPropagationButton/stopPropagationButton';
import { AddCircle, ArrowDropUp } from '@mui/icons-material';
import { SxProps } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';

type Props = {
  handleClick: () => void;
  sx?: SxProps;
  disabled?: boolean;
};

export const AddIconVertical: FC<Props> = ({
  handleClick,
  sx,
  disabled = false,
}) => {
  return (
    <StopPropagationButton
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: CommonColors.dark,
        p: 0,
        minWidth: 26,
        '&:hover': { backgroundColor: 'transparent' },
        ...sx,
      }}
      onClick={handleClick}
      disabled={disabled}
    >
      <ArrowDropUp fontSize="small" sx={{ transform: 'translateY(8px)' }} />
      <AddCircle />
    </StopPropagationButton>
  );
};
