import { createSelector } from '@reduxjs/toolkit';
import { selectAppState } from '@store/index';

export const selectPlan = createSelector(
  selectAppState,
  (state) => state.plan.present
);

export const selectProductsLayout = createSelector(
  selectPlan,
  (plan) => plan.products_layout
);

export const selectHistories = createSelector(selectAppState, (state) => ({
  past: state.plan.past,
  future: state.plan.future,
}));

export const selectShelves = createSelector(selectPlan, (plan) => plan.shelves);

export const selectIsDirty = createSelector(
  selectAppState,
  (state) => state.plan.cursor !== state.plan.past.length
);
export const selectBayPartPosition = createSelector(
  selectAppState,
  (state) => state.plan.bayPartPosition
);
