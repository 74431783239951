import CloseIcon from '@mui/icons-material/Close';
import { Button, SxProps, Theme } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';
import { theme } from '../../../theme';

type Props = {
  onClick: () => void;
  sx?: SxProps<Theme>;
};

export const CloseButton: FC<Props> = ({ onClick, sx = {} }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        color: CommonColors.black,
        cursor: 'pointer',
        width: 48,
        height: 48,
        minWidth: 0,
        border: 'solid 1px black',
        borderRadius: '4px',
        background: theme.palette.white.primary,
        ...sx,
      }}
    >
      <CloseIcon
        sx={{
          width: '1em',
          height: '1em',
        }}
      />
    </Button>
  );
};
