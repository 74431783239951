import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type ScreenType = 'Main' | 'Modal';

type BreadcrumbListProps = {
  breadcrumbs: string[];
  screenType: ScreenType;
};

const currentDirectoryMagnification = 2;
const mainMaxDisplayNum = 3;
const modalMaxDisplayNum = 2;
const mainMaxWidth = 160;
const modalMaxWidth = 120;

export const CategoryBreadcrumbs: FC<BreadcrumbListProps> = ({
  breadcrumbs,
  screenType,
}) => {
  const displayFolderNum =
    screenType === 'Main' ? mainMaxDisplayNum : modalMaxDisplayNum;
  const maxWidth = screenType === 'Main' ? mainMaxWidth : modalMaxWidth;
  const displayItems = breadcrumbs.slice(-displayFolderNum);
  const omitItems = breadcrumbs.slice(0, -displayFolderNum);
  const isCurrentFolder = (index: number) => {
    return index === displayItems.length - 1;
  };

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        itemsAfterCollapse={displayFolderNum}
        aria-label="breadcrumb"
      >
        {omitItems.length && <MoreHorizIcon />}
        {displayItems.map((item, index) =>
          isCurrentFolder(index) ? (
            <Box component="div" key={index} maxWidth={maxWidth}>
              <Typography
                noWrap
                fontSize="12px"
                fontWeight="bold"
                color={theme.palette.textBlack.secondary}
              >
                {item}
              </Typography>
            </Box>
          ) : (
            <Box
              component="div"
              key={index}
              maxWidth={maxWidth * currentDirectoryMagnification}
            >
              <Typography
                noWrap
                fontSize="12px"
                color={theme.palette.textBlack.secondary}
              >
                {item}
              </Typography>
            </Box>
          )
        )}
      </Breadcrumbs>
    </>
  );
};
