import { CompressedTexture, FileLoader } from 'three';
import { KTX2Loader as BaseKTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';

// eslint-disable-next-line @typescript-eslint/naming-convention -- _ is required
const _taskCache = new WeakMap();

export class KTX2Loader extends BaseKTX2Loader {
  /**
   * @override
   */
  load(url, onLoad, onProgress, onError) {
    if (this.workerConfig === null) {
      throw new Error(
        'THREE.KTX2Loader: Missing initialization with `.detectSupport( renderer )`.'
      );
    }

    const loader = new FileLoader(this.manager);

    loader.setResponseType('arraybuffer');
    loader.setWithCredentials(this.withCredentials);
    // set additional headers
    loader.setRequestHeader(this.requestHeader);

    const texture = new CompressedTexture();

    loader.load(
      url,
      (buffer) => {
        // Check for an existing task using this buffer. A transferred buffer cannot be transferred
        // again from this thread.
        if (_taskCache.has(buffer)) {
          const cachedTask = _taskCache.get(buffer);

          return cachedTask.promise.then(onLoad).catch(onError);
        }

        this._createTexture([buffer])
          .then(function (_texture) {
            texture.copy(_texture);
            texture.needsUpdate = true;

            if (onLoad) onLoad(texture);
          })
          .catch(onError);
      },
      onProgress,
      onError
    );

    return texture;
  }
}
