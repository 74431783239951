import { getQueryParams } from '@utils/const';
import { useNavigate } from 'react-router-dom';

export const useNavigateToDirectory = (data: { folderPath: string }) => {
  const { folderPath } = data;
  const navigate = useNavigate();

  const navigateToRootDirectory = (urlSearchParams?: URLSearchParams) => {
    const queryParams = getQueryParams(urlSearchParams);
    navigate(`${folderPath}${queryParams}`);
  };

  const navigateToSelectedDirectory = (
    directoryId: string,
    urlSearchParams?: URLSearchParams
  ) => {
    const queryParams = getQueryParams(urlSearchParams);
    navigate(`${folderPath}/${directoryId}${queryParams}`);
  };

  return {
    navigateToRootDirectory,
    navigateToSelectedDirectory,
  };
};
