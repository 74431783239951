import { Error } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';
import { theme } from '../../../../theme';
import { CancelButton } from '../../../molecules/cancelButton/cancelButton';
import { DeleteButton } from './deleteButton';

type Props = {
  open: boolean;
  isCorrectable: boolean;
  handleClose: () => void;
  handleClickDeleteFace: () => void;
  handleCorrectDrawerOpen: () => void;
};

//NOTE: ダイアログにボタンが3つある特殊ケースのため、AlertDialogを使用しない
export const DeleteFaceDialog: FC<Props> = ({
  open,
  isCorrectable,
  handleClose,
  handleClickDeleteFace,
  handleCorrectDrawerOpen,
}) => {
  return (
    <Dialog open={open} PaperProps={{ sx: { width: 600, m: 0 } }}>
      <DialogTitle>
        <Alert
          severity="error"
          icon={<Error sx={{ color: CommonColors.appRed }} />}
          sx={{
            p: 0,
            height: 32,
            fontSize: 20,
            backgroundColor: theme.palette.white.primary,
            color: CommonColors.black,
            alignItems: 'center',
          }}
        >
          商品の認識結果を削除します。
        </Alert>
      </DialogTitle>
      <DialogContent>
        <DialogContentText color={CommonColors.black}>
          {isCorrectable
            ? '商品以外のものを商品と認識している場合に削除してください。商品を誤認識している場合は訂正を行ってください。'
            : '商品以外のものを商品と認識している場合に削除してください。'}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 2, justifyContent: 'space-between' }}>
        <Box component="div">
          {isCorrectable && (
            <Button
              onClick={() => {
                handleClose();
                handleCorrectDrawerOpen();
              }}
            >
              商品の訂正
            </Button>
          )}
        </Box>
        <Box component="div">
          <CancelButton onClick={handleClose} />
          <DeleteButton
            handleClick={() => {
              handleClose();
              handleClickDeleteFace();
            }}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
