import { Box, Typography } from '@mui/material';
import {
  convertMeterToPixel,
  shelfElevationToStepElevation,
  stepIndex,
} from '@utils/planogram';
import { FC } from 'react';
import { PlanogramPlan } from 'types/planogram';

type Props = {
  plan: PlanogramPlan;
};

export const BayPartsStepIndicator: FC<Props> = ({ plan }) => {
  const height = plan.shelves_frame.detail.exterior.height;
  const steps = plan.shelves_frame.detail.shelf_steps.map(
    ({ elevation }) => elevation
  );

  const indicators = plan.shelves
    .map((shelf) => ({
      elevation: shelf.elevation,
      // TODO: 昇順・降順の判定が必要
      step:
        steps.length -
        stepIndex(shelfElevationToStepElevation(shelf) ?? -1, steps),
    }))
    .filter(({ step }) => step <= steps.length);
  return (
    <Box
      component="div"
      sx={{
        width: '32px',
        height: convertMeterToPixel(height),
        position: 'relative',
      }}
      ml={-2}
    >
      {indicators.map(({ elevation, step }) => {
        return (
          <Box
            key={step}
            component="div"
            position="absolute"
            bottom={convertMeterToPixel(elevation)}
            sx={{
              width: '20px',
              height: '20px',
              marginLeft: '4px',
              marginBottom: '-10px',
              borderRadius: '4px',
              backgroundColor: '#fff',
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography sx={{ fontSize: '10px' }}>{step}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};
