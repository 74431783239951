/* eslint-disable no-magic-numbers -- TODO: magic numberの対応を行う */
import { BayPart } from '@components/organisms/bayPart/bayPart';
import { Buckets } from '@components/organisms/frame/fragments/buckets';
import { ProductsGroup } from '@components/organisms/productsGroup/productsGroup';
import { Box } from '@mui/material';
import {
  convertMeterToPixel,
  createCompartment,
  getCompartmentSize,
} from '@utils/planogram';
import { FC, useMemo } from 'react';
import { useDragLayer } from 'react-dnd';
import { DndData, ShelfDetailMode } from 'types/common';
import { ItemTypes } from 'types/rack';

type Props = {
  scale: number;
  detailMode?: ShelfDetailMode;
};

type Item = {
  data?: DndData;
  index: number;
  rowIndex: number;
};

export const PreviewDragLayer: FC<Props> = ({ scale, detailMode }) => {
  const { itemType, isDragging, item, clientOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem<Item | undefined>(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      clientOffset: monitor.getClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  const offsets = useMemo(() => {
    const zeroOffset = { x: 0, y: 0 };
    if (!item?.data) return zeroOffset;
    const { product, compartment, bayPart, bucket } = item.data;
    switch (itemType) {
      case ItemTypes.ITEM: {
        if (!product.shape) return zeroOffset;
        const { width, height } = product.shape.size.actual;
        return { x: width / 2, y: height / 2 };
      }
      case ItemTypes.ITEM_GROUP: {
        if (!compartment) return zeroOffset;
        const { width, height } = getCompartmentSize(compartment, product);
        return { x: width / 2, y: height / 2 };
      }
      case ItemTypes.BAY_PART: {
        if (!bayPart) return zeroOffset;
        return { x: bayPart.width / 2, y: bayPart.height / 2 };
      }
      case ItemTypes.BUCKET_BAY_PART: {
        if (!bucket) return zeroOffset;
        const contentX =
          bucket.detail.content_max.x - bucket.detail.content_min.x;
        const contentZ =
          bucket.detail.content_max.z - bucket.detail.content_min.z;
        const { padding } = bucket.detail;
        const height = contentZ + padding.front + padding.behind;
        const width = contentX + padding.left + padding.right;
        return { x: width / 2, y: height / 2 };
      }
      default:
        return zeroOffset;
    }
  }, [item?.data, itemType]);

  const transform = useMemo(() => {
    if (!clientOffset) return;
    let { x, y } = clientOffset;
    x -= convertMeterToPixel(offsets.x);
    const yOffset = convertMeterToPixel(offsets.y);
    const yOffsetAdjusted =
      detailMode === 'comparison' ? yOffset + 70 : yOffset;
    y -= yOffsetAdjusted;
    return `translate(${x}px, ${y}px) scale(${scale})`;
  }, [clientOffset, offsets, scale, detailMode]);

  if (!isDragging || !clientOffset) {
    return <></>;
  }
  return (
    <Box
      component="div"
      sx={{
        position: 'fixed',
        zIndex: 100,
        pointerEvents: 'none',
        transform,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase for vendor prefix
        WebkitTransform: transform,
      }}
    >
      {item?.data && (
        <Preview
          itemType={itemType as string}
          dndData={item.data}
          index={item.index}
        />
      )}
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
const Preview: FC<{ itemType: string; dndData: DndData; index: number }> = ({
  itemType,
  dndData: { product, compartment, bayPart, bucket, isExceedSize },
  index,
}) => {
  switch (itemType) {
    case ItemTypes.ITEM: {
      return (
        <ProductsGroup
          {...createCompartment(product)}
          product={product}
          isSelected={false}
          isOverflown={false}
          bboxColors={{
            borderColor: 'transparent',
            backgroundColor: 'transparent',
          }}
          bboxEnabled={false}
          handleClickGroup={() => void {}}
          justifyMargin={0}
        />
      );
    }
    case ItemTypes.ITEM_GROUP: {
      return (
        <>
          {compartment && (
            <ProductsGroup
              {...compartment}
              product={product}
              isSelected={false}
              isOverflown={false}
              bboxColors={{
                borderColor: 'transparent',
                backgroundColor: 'transparent',
              }}
              bboxEnabled={false}
              handleClickGroup={() => void {}}
              justifyMargin={0}
            />
          )}
        </>
      );
    }
    case ItemTypes.BAY_PART: {
      return (
        <>
          {bayPart && (
            <Box
              component="div"
              width={convertMeterToPixel(bayPart.width)}
              height={convertMeterToPixel(bayPart.height)}
            >
              <BayPart {...bayPart} isDragPreview rowIndex={0} />
            </Box>
          )}
        </>
      );
    }
    case ItemTypes.BUCKET_BAY_PART: {
      return (
        <>
          {bucket && (
            <Box component="div">
              <Buckets
                bucket={bucket}
                index={index}
                zoomScale={1}
                setZoomScale={() => void 0}
                setTranslatePosition={() => void 0}
                bboxEnabled={false}
                translatePosition={0}
                isExceedSize={isExceedSize}
              />
            </Box>
          )}
        </>
      );
    }
    default:
      return <></>;
  }
};
