import { FC } from 'react';
import { Product } from '../../../../types/common';
import { ProductCandidate } from '../../../../types/realogram';
import { ListWrapper } from '@components/organisms/productCandidatesDrawer/fragments/listWrapper';
import { ProductCandidatesList } from '@components/organisms/productCandidatesList/productCandidatesList';

export const HighestScoreCandidate: FC<{
  products?: Product[];
  primaryCandidate?: ProductCandidate;
  productCandidates?: ProductCandidate[];
  selectedCandidate?: ProductCandidate;
  handleProductClick: (productCandidate: ProductCandidate) => void;
}> = ({
  products,
  selectedCandidate,
  primaryCandidate,
  productCandidates,
  handleProductClick,
}) => {
  if (!productCandidates) {
    return <></>;
  }
  return (
    <ListWrapper title="最も確信度の高い商品">
      <ProductCandidatesList
        offset={1}
        products={products}
        selectedCandidate={selectedCandidate}
        primaryCandidate={primaryCandidate}
        productCandidates={[productCandidates[0]]}
        handleClick={handleProductClick}
      />
    </ListWrapper>
  );
};
