import { FC } from 'react';
import { Stack, Typography } from '@mui/material';

type Props = {
  email: string | undefined;
};

export const AccountEmail: FC<Props> = ({ email }) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6" fontWeight="bold">
        メールアドレス
      </Typography>
      <Typography>{email}</Typography>
    </Stack>
  );
};
