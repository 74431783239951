import { ButtonGroup, styled } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { theme } from '../../../theme';

export const StyledButtonGroup = styled(ButtonGroup)(() => ({
  width: 40,
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
  backgroundColor: theme.palette.white.primary,
  '& .MuiButtonGroup-grouped': {
    height: 48,
    border: 0,
    borderColor: 'transparent',
    color: CommonColors.black,
    '&:hover ': {
      border: 0,
      backgroundColor: 'transparent',
    },
    '&:disabled': {
      border: 0,
      opacity: 0.3,
    },
    '& svg': {
      fill: '#444444',
    },
  },
}));
