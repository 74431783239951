import { FC } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { AppBar } from '@components/organisms';
import { AccountEmail } from './accountEmail';
import { AccountDemoMode } from './accountDemoMode';
import { AccountMfa } from './accountMfa';
import { AccountPassword } from './accountPassword';
import { DeleteMfaDialog } from '../dialogs/deleteMfaDialog';
import { PasswordInputDialog } from '../dialogs/passwordInputDialog';

type Props = {
  email: string | undefined;
  setIsPasswordChangeMode: () => void;
  isMfaDeviceLoading: boolean;
  preferredMfaSetting: string | undefined;
  setIsPasswordInputOpen: (isPasswordInputOpen: boolean) => void;
  isDemoOn: boolean;
  handleDemoToggleChange: () => void;
  isPasswordInputOpen: boolean;
  isSignInError: boolean;
  isSignInLoading: boolean;
  handleEmailPasswordLogin: (password: string) => void;
  isDeleteMfaOpen: boolean;
  isDeleteMfaLoading: boolean;
  handleDeleteMfa: () => void;
  setDeleteMfaOpen: () => void;
  isDemoModeEnabled: boolean;
  isSAMLEnable: boolean;
  isTenantsPrivateInfoLoading: boolean;
  isTenantsPublicInfoLoading: boolean;
};

export const AccountInfo: FC<Props> = ({
  email,
  setIsPasswordChangeMode,
  isMfaDeviceLoading,
  preferredMfaSetting,
  setIsPasswordInputOpen,
  isDemoOn,
  handleDemoToggleChange,
  isPasswordInputOpen,
  isSignInError,
  isSignInLoading,
  handleEmailPasswordLogin,
  isDeleteMfaOpen,
  handleDeleteMfa,
  isDeleteMfaLoading,
  setDeleteMfaOpen,
  isDemoModeEnabled,
  isSAMLEnable,
  isTenantsPrivateInfoLoading,
  isTenantsPublicInfoLoading,
}) => {
  return (
    <>
      <AppBar title="アカウント情報" position="fixed" />
      <Stack spacing={3} flex={1} mt={8} mb={4} px={2} mx="auto" maxWidth={400}>
        <Typography variant="h5" fontWeight="bold">
          アカウント情報
        </Typography>
        <AccountEmail email={email} />
        <Divider />
        <AccountPassword setIsPasswordChangeMode={setIsPasswordChangeMode} />
        <Divider />
        <AccountMfa
          preferredMfaSetting={preferredMfaSetting}
          isMfaDeviceLoading={isMfaDeviceLoading}
          setIsPasswordInputOpen={() => setIsPasswordInputOpen(true)}
          isSAMLEnable={isSAMLEnable}
          isTenantsPublicInfoLoading={isTenantsPublicInfoLoading}
        />
        {isDemoModeEnabled && !isTenantsPrivateInfoLoading && (
          <>
            <Divider />
            <AccountDemoMode
              isDemoOn={isDemoOn}
              handleDemoToggleChange={handleDemoToggleChange}
            />
          </>
        )}
      </Stack>
      <PasswordInputDialog
        open={isPasswordInputOpen}
        isError={isSignInError}
        isLoading={isSignInLoading}
        next={handleEmailPasswordLogin}
        handleClose={setIsPasswordInputOpen}
      />
      <DeleteMfaDialog
        open={isDeleteMfaOpen}
        handleClose={setDeleteMfaOpen}
        handleDeleteMfa={handleDeleteMfa}
        disabledConfirm={isDeleteMfaLoading}
      />
    </>
  );
};
