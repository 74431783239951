import { ProductCandidatePlanogram } from '@components/organisms';
import { NotPermittedModal } from '@components/organisms/NotPermittedModal/NotPermittedModal';
import { HeatMap } from '@components/organisms/heatMap/heatMap';
import { PlanogramDetailHeader } from '@components/organisms/planogramDetailHeader/planogramDetailHeader';
import { PlanogramOverview } from '@components/organisms/planogramOverview/planogramOverview';
import { ShelfDetailTabs } from '@components/organisms/shelfDetailTabs/shelfDetailTabs';
import { ZoomableFlatPlanogram } from '@components/organisms/zoomableFlatPlanogram/zoomableFlatPlanogram';
import { PlanButtonGroup } from '@components/pages/planogramDetail/fragments/planButtonGroup';
import { useRerenderingDetails } from '@hooks/rerenderingComponents';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useBrowserOperate } from '@hooks/useBrowserOperate';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';
import { useFlatPlanogramPlan } from '@hooks/useFlatPlanogramPlan';
import { useInitSelectedPlanogram } from '@hooks/useInitSelectedPlanogram';
import { useOrganizationStatuses } from '@hooks/useOrganizationStatuses';
import { PlanogramProvider } from '@hooks/usePlanogramProvider';
import { useUpdateUrlQueryParamsOfDetailPages } from '@hooks/useUpdateUrlQueryParamsOfDetailPages';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { useZoomController } from '@hooks/useZoomController';
import { Box } from '@mui/material';
import { selectComparisonItemModal } from '@reducers/comparisonItemModal/selectors';
import {
  setSelectedBucketId,
  updateDetailMode,
  updateDetailView,
  updateProductTagTab,
  updateProfitTab,
  updateRateValue,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { useListProductsBulkQuery } from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  embed,
  formatNumberToYen,
  fullHeight,
  planogramRightSideHeight,
  profitTabSales,
} from '@utils/const';
import { getTextDateStatistic } from '@utils/date';
import {
  calcFlatPlanStatistics,
  getBucketsProductIds,
  getFlatPlanogramAreaWidthAndCenter,
  getProductsLayout,
  isPlanogramBucketPlan,
} from '@utils/planogram';
import { hasProductTag } from '@utils/product';
import { Refs, scrollToCenterOfList } from '@utils/realogram';
import { isEqual } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { theme } from 'theme';
import {
  Product,
  ProductTag,
  ProfitTab,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import { Planogram, Position } from 'types/planogram';
import { RealogramSelectedItem } from 'types/realogram';
import { ProductTagValue } from 'types/statistics';
import { BucketCompartmentsTable } from './fragments/bucketCompartmentsTable';
import { FlatComparison } from './fragments/flatComparison';
import {
  getBucketCompartmentsByView,
  getBucketPosition,
  getCurrentCompartmentIndex,
  getNewPosition,
} from './utils';

type Props = {
  planogram?: Planogram;
  handleNavigateView: () => void;
  isForbidden?: boolean;
  embedParam: string;
};

export const FlatPlanogramDetail: FC<Props> = ({
  planogram,
  handleNavigateView,
  isForbidden,
  embedParam,
}) => {
  const dispatch = useAppDispatch();
  const { updateQueryParameter, removeQueryParameter } = useUrlQueryParams();
  const listItemRefs = useRef<Refs>(new Map());
  const {
    zoomScale,
    handleDecrementZoom,
    handleIncrementZoom,
    setZoomScale,
    handleTriggerZoom,
  } = useZoomController();
  const [profitTab, setProfitTab] = useState<ProfitTab>(profitTabSales);
  const [initRerenderingHeaderStatus, setInitRerenderingHeaderStatus] =
    useState(false); // 初回描画：ヘッダータブ更新フラグ
  const [initRerenderingSelectedProduct, setInitRerenderingSelectedProduct] =
    useState(false); // 初回描画：選択中のアイテム情報更新フラグ
  const [
    initRerenderingSelectedProductPosition,
    setInitRerenderingSelectedProductPosition,
  ] = useState(false); // 初回描画：選択中のアイテム位置情報更新フラグ
  const [initUpdateUrl, setInitUpdateUrl] = useState(false); // 初回描画：URLの更新フラグ
  const [bboxEnabled, setBboxEnabled] = useState(true);
  const { selectedProductCompartment, rateValue, selectedBucketId } =
    useAppSelector(selectPlanogramEditorState);
  const { selectedDirectoryId } = useAppSelector((state) => state.Planogram);
  const { plan, forget, bucketProductPosition, updateBucketProductPosition } =
    useFlatPlanogramPlan(
      isPlanogramBucketPlan(planogram?.plan) ? planogram?.plan : undefined
    );
  const { updateModeQueryParams, updateEvaluationQueryParams } =
    useUpdateUrlQueryParamsOfDetailPages();

  const {
    detailView: view,
    detailMode: mode,
    productTag,
  } = useAppSelector(selectPlanogramEditorState);

  const { currentSelectedItemId, currentSelectedType } = useAppSelector(
    selectComparisonItemModal
  );
  const { planogramEstimatedData } = useEstimatedProfit({ planogram });
  const productIds = plan.frame.detail.buckets
    ?.flatMap((bucket) => getBucketsProductIds(bucket.detail.area))
    .sort()
    .join(',');
  const { data: productsBulk, isLoading } = useListProductsBulkQuery(
    { productIds: productIds, shape: true, detail: true },
    { skip: productIds === '' }
  );

  const isComparisonMode = mode === 'comparison';

  const resetSelectedProduct = useCallback(() => {
    dispatch(updateSelectedProductCompartment(undefined));
    dispatch(setSelectedBucketId(undefined));
    updateBucketProductPosition(undefined);
  }, [dispatch, updateBucketProductPosition]);

  const handleClickListItem = (
    position: Position[],
    bucketIndex: number,
    product?: Product
  ) => {
    if (
      isEqual(position, bucketProductPosition) &&
      bucketIndex === selectedBucketId
    ) {
      removeQueryParameter('item');
      resetSelectedProduct();
      return;
    }
    dispatch(setSelectedBucketId(bucketIndex));
    dispatch(updateSelectedProductCompartment(product));
    updateBucketProductPosition(position);
    dispatch(setSelectedBucketId(bucketIndex));
    if (product) {
      updateQueryParameter('item', String(product.id));
    } else {
      removeQueryParameter('item');
    }
  };
  const statistic = calcFlatPlanStatistics(
    plan,
    view,
    productTag,
    productsBulk?.products
  );

  const { productTagValues } = useMemo(() => {
    const productTagValues: ProductTagValue[] = [
      {
        item: 'new_products_first_week',
        value: statistic?.productFlag.totalNewFacesFirstWeek ?? '',
      },
      {
        item: 'new_products_second_week',
        value: statistic?.productFlag.totalNewFacesSecondWeek ?? '',
      },
      {
        item: 'base_product',
        value: statistic?.productFlag.totalMainFaces ?? '',
      },
      {
        item: 'sales_ended',
        value: statistic?.productFlag.totalRecomendedToCancelationFaces ?? '',
      },
      {
        item: 'not_3D_scanned',
        value: statistic?.productFlag.totalNot3DScannedFaces ?? '',
      },
    ];
    return { productTagValues };
  }, [
    statistic?.productFlag.totalNewFacesFirstWeek,
    statistic?.productFlag.totalNewFacesSecondWeek,
    statistic?.productFlag.totalMainFaces,
    statistic?.productFlag.totalRecomendedToCancelationFaces,
    statistic?.productFlag.totalNot3DScannedFaces,
  ]);

  const {
    isViewQueryParams,
    selector,
    operate,
    data: { isLoadingTenant, canReadDemo, isTenantSalesAnalytics },
  } = useRerenderingDetails();

  // item情報を取得
  const { productsSelected } = operate.getSelectedItemOfPlanogram(
    productsBulk?.products,
    getProductsLayout(planogram?.plan)
  );

  const positions = useMemo(
    () =>
      getBucketCompartmentsByView(
        view,
        productTag,
        plan,
        productsBulk?.products,
        planogramEstimatedData?.estimate.products
      ),
    [
      plan,
      view,
      productTag,
      productsBulk?.products,
      planogramEstimatedData?.estimate.products,
    ]
  );

  const bucketPosition = useMemo(
    () => getBucketPosition(productsSelected, positions),
    [productsSelected, positions]
  );

  const checkHasSelectedItem = useCallback(
    (view?: ShelfDetailView, productTag?: ProductTag) => {
      const filteredRows = positions?.flatMap((position) =>
        position.map(({ compartment }) => {
          const product = productsBulk?.products?.find(
            (product) => product.id === compartment.product_id
          );

          const shouldIncludeProduct =
            view !== 'productFlag' ||
            (productTag
              ? hasProductTag(productTag, product?.detail?.tags)
              : false);

          return shouldIncludeProduct ? product : null;
        })
      );
      const products = filteredRows?.filter(Boolean);
      const hasSelectedItem = (products || []).some(
        (product) => product?.id === selectedProductCompartment?.id
      );

      return hasSelectedItem;
    },
    [positions, productsBulk?.products, selectedProductCompartment?.id]
  );

  // 初回描画：ヘッダータブ
  useEffect(() => {
    if (embedParam !== embed) {
      if (initRerenderingHeaderStatus || isLoading || isLoadingTenant) return;

      if (selector.modeQueryParams) {
        dispatch(updateDetailMode(selector.modeQueryParams));
      }
      // view=layout
      if (selector.viewQueryParams === 'default') {
        dispatch(updateDetailView(selector.viewQueryParams));
      }
      // view=attribute
      else if (selector.viewQueryParams === 'productFlag') {
        dispatch(updateDetailView(selector.viewQueryParams));
        dispatch(
          updateProductTagTab(
            selector.attributeQueryParams
              ? selector.attributeQueryParams
              : 'new_products_first_week'
          )
        );
      }
      // view=analytics
      else if (
        selector.viewQueryParams === 'profit' &&
        isTenantSalesAnalytics
      ) {
        const profitValue = selector.profitTab
          ? selector.profitTab
          : profitTabSales;
        dispatch(updateDetailView(selector.viewQueryParams));
        setProfitTab(profitValue);
        // Update profit value in comparison mode when back-browsing
        if (selector.modeQueryParams === 'comparison') {
          dispatch(updateProfitTab(profitValue));
        }
      }
      // view=evaluation
      else if (selector.viewQueryParams === 'rate' && canReadDemo) {
        dispatch(updateDetailView(selector.viewQueryParams));
        dispatch(
          updateRateValue(
            selector.evaluationQueryParams
              ? selector.evaluationQueryParams
              : '評価OK'
          )
        );
      }
      setInitRerenderingHeaderStatus(true);
    }
  }, [
    canReadDemo,
    dispatch,
    embedParam,
    initRerenderingHeaderStatus,
    isLoading,
    isLoadingTenant,
    isTenantSalesAnalytics,
    isViewQueryParams,
    operate,
    selector,
  ]);

  // 初回描画：選択中のアイテム情報
  useInitSelectedPlanogram({
    planogramPlan: plan,
    initSelectedData: {
      isSkipInit:
        !productsSelected ||
        initRerenderingSelectedProduct ||
        isLoading ||
        isComparisonMode,
      view,
      productTag,
      onInitCompleted: setInitRerenderingSelectedProduct,
    },
  });

  // 初回描画：選択中のアイテム位置情報
  useEffect(() => {
    if (
      initRerenderingSelectedProductPosition ||
      isLoading ||
      !bucketPosition ||
      selectedBucketId
    ) {
      return;
    }

    dispatch(setSelectedBucketId(bucketPosition.bucketId));
    updateBucketProductPosition(bucketPosition.position);
    setInitRerenderingSelectedProductPosition(true);
  }, [
    dispatch,
    initRerenderingSelectedProductPosition,
    updateBucketProductPosition,
    isLoading,
    bucketPosition,
    selectedBucketId,
  ]);

  // 初回描画のみ：URLの更新
  useEffect(() => {
    if (
      initUpdateUrl ||
      !initRerenderingHeaderStatus ||
      isLoading ||
      isLoadingTenant ||
      !productsSelected ||
      isComparisonMode
    )
      return;

    const isProduct = !!productsSelected.length;
    operate.updateUrlQueryParams({
      ...selector,
      isProduct,
    });
    setInitUpdateUrl(true);
  }, [
    initRerenderingHeaderStatus,
    initUpdateUrl,
    isComparisonMode,
    isLoading,
    isLoadingTenant,
    operate,
    productsSelected,
    selector,
  ]);

  // ブラウザバックなどの操作時に発火する
  const updateStateByBrowserOperated = () => {
    setInitRerenderingHeaderStatus(false);
    setInitRerenderingSelectedProduct(false);
    setInitRerenderingSelectedProductPosition(false);
  };
  useBrowserOperate(updateStateByBrowserOperated);

  useEffect(() => {
    if (bucketProductPosition && selectedBucketId?.toString()) {
      const key = `${JSON.stringify(
        bucketProductPosition
      )}-${selectedBucketId}}`;
      scrollToCenterOfList(listItemRefs, key);
    }
  }, [bucketProductPosition, selectedBucketId]);

  useEffect(() => {
    return () => {
      forget();
    };
  }, [forget]);

  useEffect(() => {
    dispatch(updateSelectedRealogramItem(undefined));
  }, [view, dispatch]);

  // 通常・比較モードの切り替え
  const handleChangeMode = (mode: ShelfDetailMode) => {
    if (mode) {
      const profitValue = mode === 'default' ? profitTab : profitTabSales;
      const originPlanogramId = planogram?.link?.origin_planogram_id;
      const originRealogramCandidateId =
        planogram?.link?.origin_realogram_candidate_id;
      dispatch(updateDetailMode(mode));
      dispatch(updateSelectedRealogramItem(undefined));
      const queryString =
        currentSelectedItemId && currentSelectedType
          ? `${currentSelectedType}-${currentSelectedItemId}`
          : originPlanogramId
          ? `plan-${originPlanogramId}`
          : originRealogramCandidateId
          ? `actual-${originRealogramCandidateId}`
          : '';
      updateModeQueryParams(mode, view, productTag, profitValue, rateValue, {
        selectedItem: queryString,
      });
    }
  };

  // 表示内容の切り替え
  const handleChangeView = (view: ShelfDetailView) => {
    if (view) {
      const profitValue = mode === 'default' ? profitTab : profitTabSales;
      const hasSelectedItem = checkHasSelectedItem(view, productTag);
      if (!hasSelectedItem) {
        resetSelectedProduct();
      }
      dispatch(updateDetailView(view));
      operate.updateUrlQueryParams(
        {
          viewQueryParams: view,
          isProduct: !!hasSelectedItem,
          attributeQueryParams: productTag,
          profitTab: profitValue,
          evaluationQueryParams: rateValue,
        },
        {
          replace: false,
        }
      );
    }
  };

  // 商品属性の中の項目を更新
  const handleChangeProductTag = (productTag: ProductTag) => {
    const profitValue = mode === 'default' ? profitTab : profitTabSales;
    const hasSelectedItem = checkHasSelectedItem(view, productTag);
    if (!hasSelectedItem) {
      resetSelectedProduct();
    }
    dispatch(updateProductTagTab(productTag));
    operate.updateUrlQueryParams(
      {
        viewQueryParams: view,
        isProduct: !!hasSelectedItem,
        attributeQueryParams: productTag,
        profitTab: profitValue,
        evaluationQueryParams: rateValue,
      },
      {
        replace: false,
      }
    );
  };

  const handleClickAwayPlanogram = () => {
    if (selectedProductCompartment && bucketProductPosition) {
      removeQueryParameter('item');
      resetSelectedProduct();
    }
  };

  const updateCompartmentState = (
    product?: Product,
    bucketProductPosition?: Position[]
  ) => {
    dispatch(updateSelectedProductCompartment(product));
    updateBucketProductPosition(bucketProductPosition);
    updateQueryParameter('item', String(product?.id));
  };

  const selectNext = () => {
    if (
      !bucketProductPosition ||
      !selectedBucketId?.toString() ||
      !positions?.length
    ) {
      return;
    }
    const currentCompartmentIndex = getCurrentCompartmentIndex(
      positions[selectedBucketId],
      bucketProductPosition
    );
    if (positions[selectedBucketId].length === currentCompartmentIndex + 1) {
      const firstIndexOfNextBucketCompartment = positions.findIndex(
        (el, index) => {
          if (index <= selectedBucketId) return false;
          return el.length;
        }
      );
      const newPosition = getNewPosition(
        positions,
        firstIndexOfNextBucketCompartment,
        0
      );
      const product = productsBulk?.products?.find(
        (p) => p.id === newPosition?.compartment.product_id
      );
      updateCompartmentState(product, newPosition?.position);
      dispatch(setSelectedBucketId(firstIndexOfNextBucketCompartment));
    } else {
      const newPosition = getNewPosition(
        positions,
        selectedBucketId,
        currentCompartmentIndex + 1
      );
      const product = productsBulk?.products?.find(
        (p) => p.id === newPosition?.compartment.product_id
      );
      updateCompartmentState(product, newPosition?.position);
    }
  };

  const selectPrev = () => {
    if (
      !bucketProductPosition ||
      !selectedBucketId?.toString() ||
      !positions?.length
    ) {
      return;
    }
    const currentCompartmentIndex = getCurrentCompartmentIndex(
      positions[selectedBucketId],
      bucketProductPosition
    );
    if (currentCompartmentIndex === 0) {
      const lastIndexOfPrevBucketCompartment = positions.findLastIndex(
        (el, index) => {
          if (index >= selectedBucketId) return false;
          return el.length;
        }
      );
      const newPosition = getNewPosition(
        positions,
        lastIndexOfPrevBucketCompartment,
        positions[lastIndexOfPrevBucketCompartment].length - 1
      );
      const product = productsBulk?.products?.find(
        (p) => p.id === newPosition?.compartment.product_id
      );
      updateCompartmentState(product, newPosition?.position);
      dispatch(setSelectedBucketId(lastIndexOfPrevBucketCompartment));
    } else {
      const newPosition = getNewPosition(
        positions,
        selectedBucketId,
        currentCompartmentIndex - 1
      );
      const product = productsBulk?.products?.find(
        (p) => p.id === newPosition?.compartment.product_id
      );
      updateCompartmentState(product, newPosition?.position);
    }
  };

  const shouldButtonBeDisabled = (value: 'prev' | 'next') => {
    if (
      !bucketProductPosition ||
      !selectedBucketId?.toString() ||
      !positions?.length
    )
      return true;
    const currentCompartmentIndex = getCurrentCompartmentIndex(
      positions[selectedBucketId],
      bucketProductPosition
    );
    if (value === 'prev') {
      const firstBucketCompartmentIndex = positions.findIndex((el) => {
        return el.length;
      });

      return (
        firstBucketCompartmentIndex === selectedBucketId &&
        currentCompartmentIndex === 0
      );
    } else {
      const lastBucketCompartmentIndex = positions.findLastIndex((el) => {
        return el.length;
      });
      return (
        lastBucketCompartmentIndex === selectedBucketId &&
        currentCompartmentIndex === positions[selectedBucketId].length - 1
      );
    }
  };

  const { displayPosition } = useMemo(() => {
    return getFlatPlanogramAreaWidthAndCenter(plan.frame.detail);
  }, [plan.frame.detail]);

  const scrollRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(displayPosition, 0);
    }
  }, [scrollRef, displayPosition]);

  const { start_date: startDateFromAPI, end_date: endDateFromAPI } =
    planogramEstimatedData?.estimate.summary.aggregation_period || {};
  const dateTerm = getTextDateStatistic(startDateFromAPI, endDateFromAPI);
  const { isLarger } = useBreakpoint();
  const { organizationStatus } = useOrganizationStatuses(
    planogram?.organization_status_id
  );
  return (
    <Box
      component="div"
      height={fullHeight}
      display="flex"
      flexDirection="column"
    >
      <PlanogramDetailHeader
        handleChangeView={handleChangeView}
        handleChangeMode={handleChangeMode}
        planogram={planogram}
        hiddenElement={embedParam === embed}
        isShowAnalyticsButton={isTenantSalesAnalytics}
        organizationStatus={organizationStatus}
      />
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        {/* 比較対応時に追加 */}

        {mode === 'comparison' ? (
          <FlatComparison
            planogram={planogram}
            products={productsBulk?.products}
            plan={plan}
            positions={positions}
            isTenantSalesAnalytics={isTenantSalesAnalytics}
            initRerenderingSelectedProduct={initRerenderingSelectedProduct}
            setInitRerenderingSelectedProduct={
              setInitRerenderingSelectedProduct
            }
            compareQueryParams={selector.compareQueryParams}
          />
        ) : (
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', breakpoint: 'row' },
              borderTop: '1px solid #EDEDED',
              flex: 1,
              overflow: 'hidden',
            }}
          >
            <Box
              component="div"
              display="flex"
              width="100%"
              flexDirection={{ xs: 'column', breakpoint: 'row' }}
              flex={1}
              overflow="hidden"
            >
              <Box
                component="div"
                display="flex"
                flexDirection="column"
                position="relative"
                ref={scrollRef}
                sx={{
                  flex: 5,
                  overflowX: 'scroll',
                  background: theme.palette.shelf.backgroundTana,
                }}
              >
                <PlanogramProvider
                  value={{
                    products: productsBulk?.products,
                    productSalesReport: planogramEstimatedData?.estimate,
                  }}
                >
                  <ZoomableFlatPlanogram
                    bboxEnabled={bboxEnabled}
                    plan={plan}
                    scale={zoomScale}
                    handleClickAway={handleClickAwayPlanogram}
                    zoomScale={zoomScale}
                    setZoomScale={setZoomScale}
                    isEditor={false}
                  />
                </PlanogramProvider>

                {view === 'profit' && (
                  <Box
                    component="div"
                    sx={{ position: 'absolute', bottom: 24, left: 24 }}
                  >
                    <HeatMap
                      start={formatNumberToYen(0)}
                      // eslint-disable-next-line no-magic-numbers -- formatNumberToYen
                      end={formatNumberToYen(20000)}
                    />
                  </Box>
                )}
              </Box>
              <Box
                component="div"
                position="absolute"
                top={24}
                zIndex={5}
                sx={{ transform: `translate(0px, 24px)` }}
              >
                <PlanButtonGroup
                  bboxEnabled={bboxEnabled}
                  handleChangeBboxEnabled={() => setBboxEnabled(!bboxEnabled)}
                  handleNavigateView={handleNavigateView}
                  handleIncrementZoom={handleIncrementZoom}
                  handleDecrementZoom={handleDecrementZoom}
                  handleTriggerZoom={handleTriggerZoom}
                  zoomScale={zoomScale}
                  isOrientationModalOpen={false}
                  isEditor={false}
                  isFlatPlanogram
                />
              </Box>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: { breakpoint: 5.5 },
                  overflow: 'hidden',
                  borderLeft: `1px solid ${theme.palette.divider}`,
                  height: {
                    xs: planogramRightSideHeight,
                    breakpoint: 'auto',
                  },
                }}
              >
                {!selectedProductCompartment && (
                  <ShelfDetailTabs
                    productTag={productTag}
                    detailView={view}
                    handleChangeProductTag={handleChangeProductTag}
                    rateValue={rateValue}
                    handleChangeRateValue={(item) => {
                      dispatch(updateRateValue(item));
                      updateEvaluationQueryParams(item);
                    }}
                    rateValues={[]}
                    productTagValues={productTagValues}
                    hasSelected={!!selectedProductCompartment}
                    isPlan
                  />
                )}

                {selectedProductCompartment ? (
                  <Box component="div" px={2} py={isLarger ? '16px' : 0}>
                    <ProductCandidatePlanogram
                      realogramDetailView={view}
                      shouldButtonBeDisabled={(value) => {
                        return shouldButtonBeDisabled(value);
                      }}
                      selectNext={selectNext}
                      selectPrevious={selectPrev}
                      selectedItem={
                        {
                          item: {
                            in_stock: true,
                            primary_candidate: {
                              product_id: selectedProductCompartment.id,
                            },
                          },
                        } as RealogramSelectedItem //TODO: select状態のためにindexを追加する
                      }
                      products={productsBulk?.products}
                    />
                  </Box>
                ) : (
                  <PlanogramOverview
                    term={dateTerm}
                    profitTab={profitTab}
                    setProfitTab={(profitTab: ProfitTab) => {
                      setProfitTab(profitTab);
                      // updateAnalyticsQueryParams(index);
                    }}
                    statistic={statistic}
                    isFlat
                    displayAnalyticsData={planogramEstimatedData?.estimate}
                  />
                )}

                <BucketCompartmentsTable
                  refs={listItemRefs}
                  products={productsBulk?.products}
                  frame={plan.frame}
                  profitTab={profitTab}
                  handleClickListItem={handleClickListItem}
                  positions={positions}
                  productSales={planogramEstimatedData?.estimate.products}
                />
              </Box>
            </Box>
          </Box>
        )}
      </DndProvider>
      <NotPermittedModal
        embedParam={embedParam ?? undefined}
        open={!!isForbidden}
        selectedDirectoryId={selectedDirectoryId}
        isScan
        errorMessage="このページへのアクセス権限がありません"
        buttonMessage="フォルダ一覧へ"
      />
    </Box>
  );
};
