import { bayPlansApi } from '@reducers/shelfAppsApi';
import { useAppDispatch } from '@store/index';
import { useCallback } from 'react';

export const useBayPlanApi = () => {
  const dispatch = useAppDispatch();
  const fetchBayPlan = useCallback(
    async (bayPlanId: number) => {
      const { bay_plan: bayPlan } = await dispatch(
        bayPlansApi.endpoints.getBayPlan.initiate({ bayPlanId })
      ).unwrap();
      return bayPlan;
    },
    [dispatch]
  );
  return {
    fetchBayPlan,
  };
};
