import { AlertDialog } from '@components/organisms/alertDialog/alertDialog';
import { Button, DialogContentText } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { User } from 'types/user';

type Props = {
  open: boolean;
  user: User;
  handleClose: () => void;
  handleDelete: () => void;
};

export const DeleteUserMfaDialog: FC<Props> = ({
  open,
  user,
  handleClose,
  handleDelete,
}) => {
  return (
    <AlertDialog
      open={open}
      title={`${user.fullname}さんの二要素認証を削除します。`}
      content={
        <DialogContentText sx={{ color: theme.palette.textBlack.primary }}>
          二要素認証を削除した場合、ユーザーが二要素認証の設定を初めからやり直す必要があります。問題がない場合のみ削除を行なってください。
        </DialogContentText>
      }
      cancelButton={
        <Button onClick={handleClose} color="inherit">
          キャンセル
        </Button>
      }
      confirmButton={
        <Button color="error" onClick={handleDelete}>
          削除
        </Button>
      }
    />
  );
};
