import { FC } from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ButtonGroup from '@mui/material/ButtonGroup';
import { StyledButtonNavigate } from '@components/molecules/moveBucketButtons/fragments/styledButtonNavigate';

type Props = {
  handleNavigateBefore: () => void;
  handleNavigateNext: () => void;
};
export const MoveBucketButtons: FC<Props> = ({
  handleNavigateBefore,
  handleNavigateNext,
}) => {
  return (
    <ButtonGroup>
      <StyledButtonNavigate onClick={handleNavigateBefore}>
        <NavigateBeforeIcon />
      </StyledButtonNavigate>
      <StyledButtonNavigate onClick={handleNavigateNext}>
        <NavigateNextIcon />
      </StyledButtonNavigate>
    </ButtonGroup>
  );
};
