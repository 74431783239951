import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LoadingIndicatorState = {
  isLoading: boolean;
  isResetData: boolean;
};

const initialState: LoadingIndicatorState = {
  isLoading: false,
  isResetData: false,
};

const LoadingIndicatorSlice = createSlice({
  name: 'LoadingInidicatorSlice',
  initialState,
  reducers: {
    updateLoadingIndicatorState: (
      state: LoadingIndicatorState,
      action: PayloadAction<boolean>
    ) => {
      state.isLoading = action.payload;
    },
    updateResetData: (
      state: LoadingIndicatorState,
      action: PayloadAction<boolean>
    ) => {
      state.isResetData = action.payload;
    },
  },
});

export const { updateLoadingIndicatorState, updateResetData } =
  LoadingIndicatorSlice.actions;

export const LoadingIndicatorReducer = LoadingIndicatorSlice.reducer;
