import { baseUrl, productFaceDirection } from '@utils/const';
import { useCallback, useEffect, useState } from 'react';
import { ProductFaceDirection, UploadedFiles } from 'types/products';

const maxRotateAngle = 270;
const rotateAngle = 90;

const initialData = {
  top: { angle: 0 },
  bottom: { angle: 0 },
  left: { angle: 0 },
  right: { angle: 0 },
  front: { angle: 0 },
  back: { angle: 0 },
};

export const updateProductMockupImage = (
  token: string,
  productId: number,
  faceFront: ProductFaceDirection,
  file: File
) => {
  const formData = new FormData();
  formData.append('image', file);
  const res = fetch(
    `${baseUrl}/products/${productId}/mockup_images/${faceFront}.png`,
    {
      method: 'PUT',
      body: formData,
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

const getProductMockupImage = (
  productId: number,
  faceFront: ProductFaceDirection,
  token: string
) =>
  fetch(`${baseUrl}/products/${productId}/mockup_images/${faceFront}.png`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

export const useProductMockupImages = (
  productId: number,
  token: string,
  isNot3DScanned: boolean
) => {
  const [uploadedFiles, setUploadedFiles] =
    useState<UploadedFiles>(initialData);
  const getMockupImages = useCallback(async () => {
    try {
      const results = await Promise.all(
        productFaceDirection.map(async (face) => {
          const response = await getProductMockupImage(productId, face, token);
          if (response.ok) {
            return await response.blob();
          }
          return undefined;
        })
      );
      const updatedFiles = productFaceDirection.reduce<UploadedFiles>(
        (acc, face, index) => {
          const result = results[index];
          acc[face] = {
            originalFile: result ? URL.createObjectURL(result) : undefined,
            hasOriginalFile: !!result,
            angle: 0,
          };
          return acc;
        },
        {} as UploadedFiles
      );
      setUploadedFiles(updatedFiles);
    } catch (e) {
      console.log(e);
    }
  }, [productId, token]);

  const deleteImage = (face: ProductFaceDirection) => {
    const newFiles = { ...uploadedFiles };
    newFiles[face].file = undefined;
    newFiles[face].originalFile = undefined;
    newFiles[face].angle = 0;
    newFiles[face].fileObj = undefined;
    setUploadedFiles(newFiles);
  };

  const changeImage = (face: ProductFaceDirection, files: FileList) => {
    const newFiles = {
      ...uploadedFiles,
      [face]: {
        ...uploadedFiles[face],
        file: window.URL.createObjectURL(files[0]),
        fileObj: files[0],
        angle: 0,
      },
    };
    setUploadedFiles(newFiles);
  };

  const rotateImage = (face: ProductFaceDirection) => {
    const newFiles = { ...uploadedFiles };
    if (newFiles[face].angle === maxRotateAngle) {
      newFiles[face].angle = 0;
    } else {
      newFiles[face].angle = newFiles[face].angle + rotateAngle;
    }
    setUploadedFiles(newFiles);
  };

  const resetImage = () => {
    setUploadedFiles(initialData);
  };

  useEffect(() => {
    if (isNot3DScanned) {
      void getMockupImages();
    }
  }, [getMockupImages, isNot3DScanned]);

  return {
    uploadedFiles,
    changeImage,
    deleteImage,
    rotateImage,
    resetImage,
    getMockupImages,
  };
};
