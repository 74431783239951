import { CorrectedChip } from '@components/molecules';
import { RealogramScoreChip } from '@components/organisms/realogramScoreChip/realogramScoreChip';
import { FC } from 'react';
import { RealogramCandidateFace } from '../../../types/realogram';

type Props = {
  face?: RealogramCandidateFace;
};

export const PrimaryCandidateScore: FC<Props> = ({ face }) => {
  if (face?.revised) return <CorrectedChip />;
  if (face?.is_unknown || !face?.in_stock || !face.primary_candidate)
    return <></>;
  return (
    <RealogramScoreChip
      confidenceLevel={face.primary_candidate.confidence_level}
    />
  );
};
