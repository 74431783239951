import { ModalInnerProps } from '@components/modal';
import { GoBackButton } from '@components/molecules/goBackButtonIcon/goBackButton';
import { useProductCategories } from '@hooks/useProductCategories';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  setCategoryHistory,
  setCategoryIndex,
} from '@reducers/productCategory';
import { useAppDispatch } from '@store/index';
import { FC, Fragment, useState } from 'react';
import { theme } from 'theme';
import { ProductCategory } from 'types/productCategories';
import { CategorySelectionModalActions } from './fragments/categorySelectionModalActions';

export type CategorySelectionModalProps = {
  name: string;
  selectedProductCategories?: ProductCategory;
  handleChangeSelectedProductCategory: (category?: ProductCategory) => void;
};
type Props = ModalInnerProps<CategorySelectionModalProps>;

export const CategorySelectionModal: FC<Props> = ({
  handleClose,
  data: {
    name,
    selectedProductCategories,
    handleChangeSelectedProductCategory,
  },
}) => {
  const dispatch = useAppDispatch();
  const { categoryIndex, productCategoryHistory, handleBack, handleClick } =
    useProductCategories();

  const productCategoryHistoryBreadcrumbs = productCategoryHistory.map(
    (productCategory) => {
      if (productCategory.name === 'root') return '全カテゴリ';
      return productCategory.name;
    }
  );

  const category = productCategoryHistory[categoryIndex];

  const subCategories = productCategoryHistory[categoryIndex]?.sub_categories;

  const hasSubCategory = !!subCategories?.at(0)?.sub_categories.length;

  const [selectedCategories, setSelectedCategories] = useState<
    ProductCategory | undefined
  >(selectedProductCategories);

  const handleClickCategory = (category: ProductCategory) => {
    setSelectedCategories(category);
  };

  const handleClickCategoryCancel = () => {
    setSelectedCategories(undefined);
  };

  const isSelectedParentCategory = selectedCategories === category;

  const handleClickSelectCategory = () => {
    handleChangeSelectedProductCategory(selectedCategories);
    dispatch(
      setCategoryHistory(selectedCategories ? productCategoryHistory : [])
    );
    dispatch(setCategoryIndex(selectedCategories ? categoryIndex : 0));
  };

  return (
    <Box component="div" width={480} maxHeight={560}>
      <Box
        component="div"
        display="flex"
        padding="12px 16px"
        alignItems="center"
        gap={1}
        sx={{
          backgroundColor: theme.palette.backgroundBlack.active,
        }}
      >
        <Typography
          color={theme.palette.textBlack.secondary}
          fontWeight={700}
          fontSize={14}
          noWrap
          maxWidth="390px"
        >
          {name}
        </Typography>
        <Typography
          color={theme.palette.textBlack.secondary}
          noWrap
          fontSize={14}
        >
          を選択
        </Typography>
      </Box>
      {category ? (
        <>
          <Box
            component="div"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            margin="0 16px"
            sx={{
              py: 1,
            }}
          >
            <Typography
              onClick={handleBack}
              component="h2"
              fontSize="20px"
              fontWeight="700"
              letterSpacing="0.15px"
              display="inline-flex"
              alignItems="center"
              lineHeight="175%"
              sx={{
                cursor: hasSubCategory ? 'default' : 'pointer',
              }}
            >
              {!hasSubCategory && <GoBackButton width={28} height={28} />}
              {hasSubCategory ? '全カテゴリ' : category.name}
            </Typography>
          </Box>
          <Divider />
          <List
            sx={{
              width: '100%',
              height: hasSubCategory ? 396 : 360,
              overflowY: 'scroll',
              overflowX: 'auto',
            }}
            disablePadding
            component="div"
          >
            {!hasSubCategory && (
              <ListItemButton
                onClick={() => handleClickCategory(category)}
                sx={{
                  borderTop: 'none',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: isSelectedParentCategory ? '#E5F0FF' : '',
                  '&:hover': {
                    backgroundColor: isSelectedParentCategory ? '#E5F0FF' : '',
                  },
                }}
              >
                <ListItemText
                  primary={`「${category.name}」すべて`}
                  sx={{ fontSize: 14 }}
                />
                {selectedCategories?.organization_category_code ===
                  category.organization_category_code &&
                  subCategories?.length !== 0 && (
                    <CheckCircleIcon
                      fontSize="small"
                      htmlColor="#0A40CA"
                      sx={{ alignItems: 'center' }}
                    />
                  )}
              </ListItemButton>
            )}
            {subCategories?.map((subCategory, index) => {
              const hasNestedSubCategory = !!subCategory.sub_categories.length;
              const isSelected =
                !hasNestedSubCategory && selectedCategories === subCategory;
              return (
                <Fragment key={index}>
                  <Divider />
                  <ListItemButton
                    onClick={() =>
                      subCategory.sub_categories.length
                        ? handleClick(subCategory)
                        : handleClickCategory(subCategory)
                    }
                    sx={{
                      gap: 1,
                      borderTop: 'none',
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: isSelected ? '#E5F0FF' : '',
                      '&:hover': {
                        backgroundColor: isSelected ? '#E5F0FF' : '',
                      },
                    }}
                  >
                    <ListItemText
                      primary={subCategory.name}
                      sx={{ fontSize: 14 }}
                    />
                    {hasNestedSubCategory && (
                      <ListItemIcon sx={{ minWidth: '0px' }}>
                        <ChevronRightIcon
                          htmlColor={theme.palette.icons.secondary}
                        />
                      </ListItemIcon>
                    )}
                    {isSelected && (
                      <CheckCircleIcon
                        fontSize="small"
                        htmlColor="#0A40CA"
                        sx={{ alignItems: 'center' }}
                      />
                    )}
                  </ListItemButton>
                </Fragment>
              );
            })}
          </List>
        </>
      ) : (
        <Box
          component="div"
          height="447px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="#BDBDBD"
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <CategorySelectionModalActions
        handleClick={handleClickSelectCategory}
        handleClose={handleClose}
        breadcrumbs={productCategoryHistoryBreadcrumbs}
        disabledSelect={selectedProductCategories === selectedCategories}
        disabledCancel={!selectedCategories}
        handleClickCategoryCancel={handleClickCategoryCancel}
      />
    </Box>
  );
};
