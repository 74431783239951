import { gridComponents } from '@components/organisms';
import { Box, CircularProgress } from '@mui/material';
import { useAppSelector } from '@store/index';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc';
import { FC, useEffect, useRef } from 'react';
import { VirtuosoGrid, VirtuosoGridHandle } from 'react-virtuoso';
import { theme } from 'theme';
import { RealogramDirectory } from 'types/realogram';
import { RealogramSharePermissionProps } from 'types/sharePermission';
import { RealogramDirectoryCard } from './realogramsDirectoryCard';
import { useLocation } from 'react-router-dom';
import { ShelvesViewMode } from 'types/common';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  isLoading: boolean;
  realogramDirectories?: RealogramDirectory[];
  handleCardClick: (index: number, item: RealogramDirectory) => void;
  handleOpenDeleteDialog: (id: number) => void;
  handleEndReached: (isAtBottom: boolean) => void;
  handleFavoriteClick: (id: string, isFavorite: boolean) => void;
  handleSharePermission: (item: RealogramSharePermissionProps) => void;
  isRefetching?: boolean;
  viewMode?: ShelvesViewMode;
  isFilteredByViewed: boolean;
};

const imageHeight = 120;
const enterThreshold = 400;
const exitThreshold = 30;

const LoadingContent: FC<{ scrollableHeight: string }> = ({
  scrollableHeight,
}) => {
  return (
    <Box
      component="div"
      height={scrollableHeight}
      mt={1}
      color="#D9D9D9"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={theme.palette.white.primary}
    >
      <CircularProgress color="inherit" />
    </Box>
  );
};

export const RealogramsDirectoryGrid: FC<Props> = ({
  isLoading,
  realogramDirectories,
  handleCardClick,
  handleOpenDeleteDialog,
  handleFavoriteClick,
  handleSharePermission,
  handleEndReached,
  isRefetching,
  viewMode,
  isFilteredByViewed,
}) => {
  const { user: me } = useAppSelector((state) => state.Auth);
  const ref = useRef<VirtuosoGridHandle>(null); //NOTE: for typescript support
  const { selectedItemIndex } = useAppSelector((state) => state.Scanner);
  const location = useLocation();

  useEffect(() => {
    if (!selectedItemIndex || !ref.current) return;
    ref.current.scrollToIndex({ index: selectedItemIndex });
  });
  useEffect(() => {
    ref.current?.scrollToIndex({ index: 0 });
  }, [location, isLoading]);

  if (isLoading) {
    return <LoadingContent scrollableHeight="100%" />;
  }

  return (
    <VirtuosoGrid
      ref={ref}
      data={realogramDirectories}
      style={{ height: '100%' }}
      atBottomStateChange={handleEndReached}
      components={{
        ...gridComponents,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        Footer: () => {
          if (isRefetching) return <LoadingContent scrollableHeight="auto" />;
          return <></>;
        },
      }}
      scrollSeekConfiguration={{
        enter: (velocity) => Math.abs(velocity) > enterThreshold,
        exit: (velocity) => {
          const shouldExit = Math.abs(velocity) < exitThreshold;
          return shouldExit;
        },
      }}
      itemContent={(index, realogramDirectory) => {
        if (!realogramDirectory) return;
        return (
          <Box component="div" width="100%">
            <RealogramDirectoryCard
              imageHeight={imageHeight}
              realogramDirectory={realogramDirectory}
              handleOpenDeleteDialog={handleOpenDeleteDialog}
              handleClick={handleCardClick}
              index={index}
              handleFavoriteClick={() =>
                handleFavoriteClick(
                  realogramDirectory.id,
                  realogramDirectory.favorite?.owner_id === me?.id
                )
              }
              isFolder={realogramDirectory.type === 'directory'}
              isStarred={realogramDirectory.favorite?.owner_id === me?.id}
              viewMode={viewMode}
              isFilteredByViewed={isFilteredByViewed}
              handleSharePermission={() => {
                handleSharePermission({
                  directoryId: realogramDirectory.id,
                  type: realogramDirectory.type,
                });
              }}
            />
          </Box>
        );
      }}
    />
  );
};
