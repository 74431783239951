export const passwordErrorMessage = {
  expiredPassword: '期限切れの認証コードです。もう一度リクエストしてください。',
  invalidPassword: '無効な認証コードです。もう一度お試しください。',
  notCorrectPassword:
    'パスワードは英語大文字小文字、数字、記号をすべて含み、11文字以上の長さが必要です。',
  notSetNewPassword: '新しいパスワードの設定に失敗しました。',
  emailIncorrect:
    'メールアドレスが間違っています。正しい情報を入力してください。',
  tooManyRequests:
    '試行制限を超過しました。時間をおいてもう一度お試しください。',
  authenCodeFailed: '認証コードの取得に失敗しました。',
};
