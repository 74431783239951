import { PreviewPlanogram } from '@components/organisms/previewPlanogram/previewPlanogram';
import {
  changeEditorMode,
  rotateGondola,
  rotateGondolaInCompare,
} from '@reducers/planogramEditor/reducer';
import { usePreviewPlanogramMutation } from '@reducers/shelfAppsApi';
import { useAppDispatch } from '@store/index';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Planogram, PreviewMode } from 'types/planogram';

type Props = {
  planogram: Planogram;
  plan: Planogram['plan'];
};

export const ThreeDPreview: FC<Props> = ({ planogram, plan }) => {
  const dispatch = useAppDispatch();
  const [previewPlanogram, { isError: isErrorPreview }] =
    usePreviewPlanogramMutation();
  const [previewUrl, setPreviewUrl] = useState('');
  const [previewMode, setPreviewMode] = useState<PreviewMode>('2d');
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    dispatch(rotateGondola(0));
    dispatch(rotateGondolaInCompare(0));
    setPreviewUrl('');
    dispatch(changeEditorMode('CompartmentEditor'));
  };

  const handleChangePreviewMode = (value: PreviewMode) => {
    setPreviewMode(value);
  };

  const handleCancelLoading3d = () => {
    setPreviewMode('2d');
  };

  const planogramForPreview = useMemo(() => {
    return {
      ...planogram,
      plan,
    };
  }, [plan, planogram]);

  const uploadPlanogramForThreeD = useCallback(async () => {
    if (previewMode !== '3d' || previewUrl) return;
    setIsLoading(true);
    try {
      const {
        preview_planogram: { products_shelves_url: previewUrl },
      } = await previewPlanogram(planogramForPreview).unwrap();
      setPreviewUrl(previewUrl);
    } catch (e) {
      console.log(e);
    }
  }, [planogramForPreview, previewUrl, previewMode, previewPlanogram]);

  const finishLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    void uploadPlanogramForThreeD();
  }, [uploadPlanogramForThreeD]);

  return (
    <PreviewPlanogram
      planogram={planogramForPreview}
      previewMode={previewMode}
      refetchThreeDPlanogram={uploadPlanogramForThreeD}
      isLoading3dPreview={isLoading}
      isError3dPreview={isErrorPreview}
      previewUrl={previewUrl}
      finishLoading={finishLoading}
      handleClose={handleClose}
      handleCancelLoading3d={handleCancelLoading3d}
      handleChangePreviewMode={handleChangePreviewMode}
    />
  );
};
