import { selectAccessToken } from '@reducers/auth/selectors';
import { useGetMfaDeviceQuery } from '@reducers/shelfAppsApi';
import { useAppSelector } from '@store/index';

export const useMfaStatus = () => {
  const accessToken = useAppSelector(selectAccessToken);
  const { data, isLoading } = useGetMfaDeviceQuery({ accessToken });

  return {
    mfaAvailable: !!data?.preferred_mfa_setting,
    isLoading,
  };
};
