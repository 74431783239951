import { Chip, styled } from '@mui/material';

export const SidebarItemChips = styled(Chip)(() => ({
  width: '100%',
  height: '40px',
  gap: '8px',
  padding: '0px 8px',
  justifyContent: 'initial',
  alignSelf: 'stretch',
  borderRadius: '999px',
  cursor: 'pointer',
  [`& .MuiChip-icon`]: {
    marginRight: 0,
    marginLeft: 0,
  },
  [`& .MuiChip-label`]: {
    paddingLeft: 0,
    letterSpacing: '0.1px',
    fontWeight: 700,
    fontSize: '0.875rem',
  },
}));
