import { StyledButton } from '@components/molecules/styledButton/styledButton';
import { Box, Divider, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
  handleClose: () => void;
  handleClick?: () => void;
  actionBtnTitle: string;
  disabled?: boolean;
};
export const ModalActions: FC<Props> = ({
  handleClose,
  handleClick,
  actionBtnTitle,
  disabled,
}) => {
  return (
    <>
      <Divider sx={{ margin: '24px -20px' }} />
      <Box
        component="div"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Typography
          onClick={handleClose}
          component="span"
          sx={{ cursor: 'pointer', marginRight: '24px' }}
        >
          キャンセル
        </Typography>
        <StyledButton
          disabled={disabled}
          type="contained"
          onClick={handleClick}
        >
          {actionBtnTitle}
        </StyledButton>
      </Box>
    </>
  );
};
