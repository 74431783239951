import { Box, Divider, Switch, Typography } from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  isSalesEnded: boolean;
  handleChangeSalesEnded: VoidFunction;
  isLoading: boolean;
  orientation?: 'vertical' | 'horizontal';
};

export const SalesEndSwitch: FC<Props> = ({
  isSalesEnded,
  handleChangeSalesEnded,
  isLoading,
  orientation = 'vertical',
}) => {
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="row"
      textAlign="center"
      alignItems="center"
    >
      <Typography variant="body2" color={theme.palette.textBlack.secondary}>
        {t('sales_ended')}の商品
      </Typography>
      <Switch
        checked={isSalesEnded}
        onChange={handleChangeSalesEnded}
        disabled={isLoading}
      />
      <Divider orientation={orientation} />
    </Box>
  );
};
