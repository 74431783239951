import { IconButton } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { GoParentDirectoryButtonIcon } from './fragment/goParentDirectoryButtonIcon';

type GoParentDirectoryButtonProps = {
  width: number;
  height: number;
  handleClick?: () => void;
};
export const GoParentDirectoryButton: FC<GoParentDirectoryButtonProps> = ({
  handleClick,
  width,
  height,
}) => {
  return (
    <IconButton
      edge="start"
      sx={{
        border: `1px solid ${theme.palette.dividerBlack.dark}`,
        borderRadius: 1,
        width: width,
        height: height,
        ml: 0,
        mr: 1,
      }}
      onClick={handleClick}
    >
      <GoParentDirectoryButtonIcon
        fontSize="small"
        htmlColor={theme.palette.icons.primary}
      />
    </IconButton>
  );
};
