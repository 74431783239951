import { StyledButtonGroup } from '@components/organisms';
import { Add, Remove } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  division: number;
  isDisabled: boolean;
  handleIncrementDivision: () => void;
  handleDecrementDivisioin: () => void;
  isIncrementDisabled: boolean;
};

const minDivision = 2;

export const ParcelDivisionController: FC<Props> = ({
  division,
  isDisabled,
  handleDecrementDivisioin,
  handleIncrementDivision,
  isIncrementDisabled,
}) => {
  return (
    <StyledButtonGroup
      disabled={isDisabled}
      orientation="horizontal"
      sx={{
        borderRadius: '3px',
        background: isDisabled ? theme.palette.icons.disabled : undefined,
        width: '120px',
        '& .MuiButtonGroup-grouped': { height: '40px' },
        justifyContent: 'space-between',
      }}
    >
      <Button
        sx={{ px: 1 }}
        onClick={handleDecrementDivisioin}
        disabled={division <= minDivision}
      >
        <Remove sx={{ color: theme.palette.icons.primary }} />
      </Button>
      <Box
        component="div"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '16px',
          height: 40,
          color: isDisabled
            ? theme.palette.textBlack.disabled
            : theme.palette.textBlack.label,
        }}
      >
        <Typography fontWeight="700">{division}</Typography>
      </Box>
      <Button
        sx={{ px: 1 }}
        onClick={handleIncrementDivision}
        disabled={isIncrementDisabled}
      >
        <Add sx={{ color: theme.palette.icons.primary }} />
      </Button>
    </StyledButtonGroup>
  );
};
