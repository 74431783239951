import { FC } from 'react';
import { Chip, CircularProgress } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { theme } from '../../../theme';

type Props = {
  prependedLabel?: string;
};

export const ProcessingChip: FC<Props> = ({ prependedLabel }) => (
  <Chip
    label={prependedLabel ? prependedLabel + '処理中' : '処理中'}
    variant="outlined"
    sx={{
      px: '6px',
      py: '3px',
      fontSize: '0.7rem',
      height: 'inherit',
      color: CommonColors.appBlue,
      borderColor: CommonColors.appBlue,
      backgroundColor: theme.palette.white.primary,
      '& .MuiChip-label': {
        px: 0,
      },
      '& .MuiChip-icon': {
        margin: 0,
        color: CommonColors.appBlue,
        mr: 1,
      },
    }}
    icon={<CircularProgress size={13} />}
  />
);
