import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

const initialSize = 20;

export const CampaignIcon: FC<SvgIconProps> = (props) => {
  const { htmlColor = 'rgba(0, 0, 0, 0.38)', ...otherProps } = props;
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      sx={{
        height: props.height ?? initialSize,
        width: props.width ?? initialSize,
      }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M6 6C6 6 9.85212 5.78105 12 5C14.1479 4.21895 17 2 17 2V15C17 15 13.9526 13.0325 12 12.5C9.65685 11.861 6 12 6 12V6ZM3 8C3 6.89543 3.89543 6 5 6V12C3.89543 12 3 11.1046 3 10V8ZM9 17L8 13H6L7 17H9Z"
        fill={htmlColor}
      />
    </SvgIcon>
  );
};
