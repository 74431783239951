import { Box } from '@mui/material';
import { convertMeterToPixel } from '@utils/planogram';
import { FC, Fragment } from 'react';
import { theme } from 'theme';

type Props = {
  height: number;
  steps: number[];
  shelfSteps: { indexTop: number; indexBottom: number }[];
};

export const BayPartsPositionIndicator: FC<Props> = ({
  height,
  steps,
  shelfSteps,
}) => {
  return (
    <Box
      component="div"
      sx={{
        width: '8px',
        height: convertMeterToPixel(height),
      }}
      mr={1}
      position="relative"
    >
      {steps.map((step, index) => {
        // eslint-disable-next-line no-magic-numbers -- permit this time
        const used =
          shelfSteps.findIndex(
            (d) => d.indexTop <= index && d.indexBottom >= index
          ) !== -1;

        return (
          <Fragment key={`part-position-${step}`}>
            <Box
              component="div"
              position="absolute"
              sx={{
                width: '2px',
                height: '2px',
                // eslint-disable-next-line no-magic-numbers -- permit this time
                background: 'black',
                opacity: 0.1,
                borderRadius: '50%',
                marginLeft: '3px',
                marginBottom: '-1px',
              }}
              bottom={convertMeterToPixel(step)}
            />
            {used && (
              <Box
                component="div"
                key={`${step}`}
                position="absolute"
                sx={{
                  width: '4px',
                  height: '4px',
                  background: theme.palette.primary.main,

                  borderRadius: '50%',
                  marginLeft: '2px',
                  marginBottom: '-2px',
                }}
                bottom={convertMeterToPixel(step)}
              />
            )}
          </Fragment>
        );
      })}
    </Box>
  );
};
