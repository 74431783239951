import { Box, SxProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type TabPanelProps = {
  index: number;
  value: number;
  sx?: SxProps;
};

export const TabPanel: FC<PropsWithChildren<TabPanelProps>> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box {...other} component="div">
          {children}
        </Box>
      )}
    </Box>
  );
};
