import { InputAdornment, SxProps, TextField } from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form';
import { ProductFormData } from '../productInformationModal';
type Props<T extends keyof ProductFormData> = {
  error: boolean;
  label: string;
  field: ControllerRenderProps<ProductFormData, T>;
  sxTextField?: SxProps;
  isFocused?: boolean;
};

export const SizeTextField = <T extends keyof ProductFormData>({
  error,
  label,
  field,
  sxTextField,
  isFocused,
}: Props<T>) => {
  return (
    <TextField
      {...field}
      required
      label={label}
      focused={isFocused}
      type="tel"
      sx={{ maxWidth: '100px', ...sxTextField }}
      inputMode="numeric"
      InputProps={{
        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
      }}
      inputProps={{
        maxLength: 4,
        min: 0,
      }}
      error={!!error}
      size="small"
    />
  );
};
