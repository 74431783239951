import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
  text: string;
  isValid: boolean;
};

export const Item: FC<Props> = ({ text, isValid }) => {
  return (
    <Stack direction="row" alignItems="center">
      {isValid ? (
        <CheckCircle sx={{ width: 16, height: 16, fill: '#4CAF50' }} />
      ) : (
        <CheckCircleOutline sx={{ width: 16, height: 16, fill: '#757575' }} />
      )}

      <Typography sx={{ fontSize: '12px', color: '#757575', ml: 0.5 }}>
        {text}
      </Typography>
    </Stack>
  );
};
