import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StorageHelper } from '@utils/storageHelper';

type SkipRealogramStepThreeState = {
  isSkipRealogramStepThreeOn: boolean;
};

const storageHelper = new StorageHelper();

const initialState: SkipRealogramStepThreeState = {
  isSkipRealogramStepThreeOn: false,
};

const skipRealogramStepThreeStateSlice = createSlice({
  name: 'skipRealogramStepThree-Reducer',
  initialState,
  reducers: {
    setSkipRealogramStepThreeState: (
      state: SkipRealogramStepThreeState,
      action: PayloadAction<
        SkipRealogramStepThreeState['isSkipRealogramStepThreeOn']
      >
    ) => {
      storageHelper.set(
        'isSkipRealogramStepThreeOn',
        action.payload.toString()
      );
      state.isSkipRealogramStepThreeOn = action.payload;
    },
    removeSkipRealogramStepThreeState: () => {
      storageHelper.remove('isSkipRealogramStepThreeOn');
    },
  },
});

export const {
  setSkipRealogramStepThreeState,
  removeSkipRealogramStepThreeState,
} = skipRealogramStepThreeStateSlice.actions;
export const SkipRealogramStepThreeReducer =
  skipRealogramStepThreeStateSlice.reducer;
