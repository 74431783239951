import { api } from '..';

export const realogramsApi = {
  getSearchRealogramDirectories:
    api.searchRealogramDirectoryApiV1RealogramDirectoriesSearchGet,
  addRealogramToFavorite:
    api.postApiV1RealogramDirectoryFavoritesDirectoryIdPost,
  deleteRealogramFromFavorite:
    api.deleteApiV1RealogramDirectoryFavoritesDirectoryIdDelete,
  getRealogramDirectories:
    api.getRealogramDirectoryApiV1RealogramDirectoriesGet,
  deleteRealogram: api.deleteApiV1RealogramCandidatesRealogramCandidateIdDelete,
};
