import { CircularSpinner } from '@components/molecules';
import { StyledIconButton } from '@components/molecules/faceAreaControllerBox/fragments/createdAt';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  disableChangeToNextWeek?: boolean;
  disableChangeToPreviousWeek?: boolean;
  term?: string;
  handleNextWeekTerm?: () => void;
  handlePreviousWeekTerm?: () => void;
  isLoadingAnalyticsData?: boolean;
};

export const WeekSwitcher: FC<Props> = ({
  disableChangeToNextWeek,
  disableChangeToPreviousWeek,
  term,
  handleNextWeekTerm,
  handlePreviousWeekTerm,
  isLoadingAnalyticsData = false,
}) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {isLoadingAnalyticsData ? (
        <CircularSpinner
          sx={{
            display: 'flex',
            alignItems: 'center',
            margin: 'auto',
            width: 80,
          }}
          size={20}
        />
      ) : (
        <>
          <StyledIconButton
            disabled={disableChangeToPreviousWeek}
            onClick={handlePreviousWeekTerm}
          >
            <KeyboardArrowLeft fontSize="small" />
          </StyledIconButton>
          <Typography
            variant="caption1"
            color={theme.palette.textBlack.primary}
            width="69px"
            textAlign="center"
          >
            {term}
          </Typography>
          <StyledIconButton
            disabled={disableChangeToNextWeek}
            onClick={handleNextWeekTerm}
          >
            <KeyboardArrowRight fontSize="small" />
          </StyledIconButton>
        </>
      )}
    </Box>
  );
};
