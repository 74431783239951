import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import { Box, Divider, Popper } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { rotationMap } from '@utils/planogram';
import { isEqual } from 'lodash';
import { FC } from 'react';
import {
  OrientationDirectionId,
  PlanogramProductCompartment,
  ProductPosition,
} from 'types/planogram';
import { theme } from '../../../theme';
import { precisePosition } from '../productsBay/fragments/compartmentMenu';
import { StopPropagationButton } from '../stopPropagationButton/stopPropagationButton';
import { StopPropagationIconButton } from '../stopPropagationIconButton/stopPropagationIconButton';
import {
  GroupOfOrientationButtons,
  RotateMinusZIcon,
  RotatePlusZIcon,
  RotateXIcon,
  RotateYIcon,
} from './fragments';

type Props = {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
  productPosition: ProductPosition;
};

enum Orientation {
  verticalLeft = 0,
  verticalRight = 1,
  horizontalLeft = 2,
  horizontalRight = 3,
  top = 4,
  bottom = 5,
}

const marginTop = 2;
export const CompartmentOrientationMenu: FC<Props> = ({
  anchorEl,
  open,
  handleClose,
  productPosition,
}) => {
  const { plan, rotateProducts } = usePlanogramPlan();
  const target =
    plan.products_layout[productPosition.indexY]?.row[
      productPosition.subPosition.indexX
    ];

  const rotate = (direction: OrientationDirectionId) => {
    const orientation = target?.orientation ?? 0;
    const faceFront = target?.face_front ?? 1;

    const nextPosition = calculateNextPosition({
      orientation,
      face_front: faceFront,
      direction,
    });

    rotateProducts(productPosition, {
      faceFront: nextPosition.faceFrontId,
      orientation: nextPosition.orientation,
    });
  };

  return (
    <Popper
      anchorEl={anchorEl}
      open={open}
      sx={{
        minHeight: 92,
        width: 290,
        borderRadius: '8px',
        border: '1px solid #DDDDDD',
        backdropFilter: 'blur(6px)',
        // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
        WebkitBackdropFilter: 'blur(6px)',
        backgroundColor: theme.palette.white.translucent,
        boxShadow: 'unset',
        padding: '8px',
        marginTop: marginTop + precisePosition(anchorEl).marginTop, //it needs because <Popper /> logic rounding the position
        '& .MuiMenu-list': {
          padding: 0,
        },
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 1,
        }}
      >
        <GroupOfOrientationButtons
          handleRotateMinus={() => rotate(Orientation.verticalLeft)}
          handleRotatePlus={() => rotate(Orientation.verticalRight)}
        >
          <RotateXIcon />
        </GroupOfOrientationButtons>
        <Divider orientation="vertical" component="div" flexItem />
        <GroupOfOrientationButtons
          handleRotateMinus={() => rotate(Orientation.horizontalLeft)}
          handleRotatePlus={() => rotate(Orientation.horizontalRight)}
        >
          <RotateYIcon />
        </GroupOfOrientationButtons>
        <Divider orientation="vertical" component="div" flexItem />
        <StopPropagationIconButton
          disableRipple
          onClick={() => rotate(Orientation.top)}
          sx={{
            padding: 0,
          }}
        >
          <RotatePlusZIcon />
        </StopPropagationIconButton>
        <StopPropagationIconButton
          disableRipple
          onClick={() => rotate(Orientation.bottom)}
          sx={{
            padding: 0,
          }}
        >
          <RotateMinusZIcon />
        </StopPropagationIconButton>
      </Box>
      <Box
        mt={0.5}
        component="div"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        width="100%"
      >
        <StopPropagationButton
          onClick={handleClose}
          sx={{
            backgroundColor: theme.palette.white.primary,
            border: `1px solid ${CommonColors.appBlue}`,
            color: CommonColors.appBlue,
            ':hover': {
              background: theme.palette.white.primary,
            },
            padding: '5px 15px',
            minWidth: '75px',
          }}
        >
          戻る
        </StopPropagationButton>
      </Box>
    </Popper>
  );
};

const calculateNextPosition = ({
  orientation,
  face_front: faceFront,
  direction,
}: Pick<PlanogramProductCompartment, 'orientation' | 'face_front'> & {
  direction: OrientationDirectionId;
}): (typeof rotationMap)[keyof typeof rotationMap] => {
  for (const key in rotationMap) {
    if (
      isEqual(key.split(','), [
        faceFront.toString(),
        orientation.toString(),
        direction.toString(),
      ])
    ) {
      return rotationMap[key as keyof typeof rotationMap];
    }
  }

  return { faceFrontId: 1, orientation: 0 };
};
