import { useSignInWithCodeMutation } from '@reducers/shelfAppsApi';
import { useGetTenantsPublicQuery } from '@reducers/shelfAppsApi/injections/tenantsApi';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSignInWithCode = () => {
  const [signIn, { error }] = useSignInWithCodeMutation();
  const [searchParams] = useSearchParams();
  const { data, isLoading } = useGetTenantsPublicQuery();

  useEffect(() => {
    const authorizationCode = searchParams.get('code');
    if (!authorizationCode) {
      return;
    }

    void signIn({ authorizationCode });
  }, [searchParams, signIn]);

  return {
    error:
      error ?? searchParams.has('error')
        ? new Error(searchParams.get('error') as string)
        : undefined,
    isLoading,
    // 無効の場合は undefined とする
    signInUrl:
      (data?.features.saml_enabled && data?.features.saml_sign_in_url) ||
      undefined,
  };
};
