import { DynamicImage } from '@components/organisms/dynamicImage/dynamicImage';
import { ProductImage } from '@components/organisms/productImage/productImage';
import { ImageBox } from '@components/organisms/shelfListItem/fragments';
import { ArrowForward } from '@mui/icons-material';
import { Box, Checkbox, Typography } from '@mui/material';
import { useGetProductQuery } from '@reducers/shelfAppsApi';
import { cropProductImage } from '@utils/realogram';
import { useMemo } from 'react';
import { theme } from 'theme';
import { UnknownProduct } from 'types/realogram';
import { FailedGetMostHighScoreCard } from '@components/organisms/productCandidateRealogram/fragments/failedGetMostHighScoreCard';

type Props = {
  unknownProduct: UnknownProduct;
  realogramImageElement: HTMLImageElement | null;
  imageIsLoading: boolean;
  handleCheckboxChange: (id: string) => void;
};

export const UnknownProductReviseCard = ({
  unknownProduct,
  realogramImageElement,
  imageIsLoading,
  handleCheckboxChange,
}: Props) => {
  const { data: mostHighScoreProduct } = useGetProductQuery(
    {
      productId: unknownProduct.mostHighScore?.product_id ?? 0,
    },
    {
      skip: !unknownProduct.mostHighScore?.product_id,
    }
  );

  // 不明な商品の画像
  const referenceImage = useMemo(() => {
    if (!realogramImageElement || !imageIsLoading) return '';
    return cropProductImage(realogramImageElement, unknownProduct.bbox);
  }, [realogramImageElement, unknownProduct, imageIsLoading]);

  return (
    <>
      {mostHighScoreProduct ? (
        <Box
          component="div"
          minHeight="76px"
          border={`1px solid ${
            unknownProduct.isChecked
              ? theme.palette.primary.main
              : theme.palette.dividerBlack.medium
          }`}
          sx={{
            backgroundColor: unknownProduct.isChecked
              ? theme.palette.primary.selected
              : '',
            cursor: 'pointer',
          }}
          borderRadius={0.5}
          display="flex"
          alignItems="center"
          onClick={() => handleCheckboxChange(unknownProduct.id)}
        >
          <Box
            component="div"
            p={1}
            width="40px"
            display="flex"
            justifyContent="center"
          >
            <Checkbox
              checked={unknownProduct.isChecked}
              sx={{ width: 3, height: 3 }}
            />
          </Box>
          <Box
            component="div"
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <ImageBox width="60px" height="60px" margin={0}>
              <DynamicImage
                src={referenceImage}
                alt=""
                layout="fill"
                objectFit="contain"
              />
            </ImageBox>
            <ArrowForward
              sx={{
                width: 16,
                height: 16,
                color: `${theme.palette.icons.secondary}`,
                textAlign: 'center',
              }}
            />
            <ImageBox width="60px" height="60px" margin={0}>
              <ProductImage
                product={mostHighScoreProduct.product}
                layout="fill"
                objectFit="contain"
              />
            </ImageBox>
          </Box>
          <Box component="div" overflow="hidden" width="100%" pr={1}>
            <Typography
              variant="body2"
              sx={{
                wordBreak: 'break-all',
                // maxWidth: '248px',
                minWidth: '108px',
                display: '-webkit-box',
                // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                WebkitBoxOrient: 'vertical',
                // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                WebkitLineClamp: 3,
                textOverflow: 'ellipsis',
              }}
            >
              {mostHighScoreProduct.product.name}
            </Typography>
          </Box>
          {/* 訂正推奨 label */}
          {unknownProduct.isRecommend && (
            <Box
              component="div"
              height="23px"
              border={`1px solid ${theme.palette.primary.light}`}
              mx={1}
              borderRadius={4}
              sx={{ backgroundColor: theme.palette.primary.background }}
              textAlign="center"
            >
              <Typography
                fontSize="12px"
                lineHeight="23px"
                width="64px"
                height="23px"
                color={theme.palette.primary.main}
              >
                訂正推奨
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <FailedGetMostHighScoreCard />
      )}
    </>
  );
};
