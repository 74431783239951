import { AlertDialog } from '@components/organisms/alertDialog/alertDialog';
import { Button, DialogContentText } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';

type Props = {
  open: boolean;
  handleClick: () => void;
  handleClose: () => void;
  title?: string;
  message?: string;
};
export const PlanogramErrorDialog: FC<Props> = ({
  open,
  handleClick,
  handleClose,
  title = '商品がゴンドラからはみ出ています。',
  message = '商品が配置可能エリアを超えています。このまま保存してよろしいですか？',
}) => {
  return (
    <AlertDialog
      open={open}
      title={title}
      content={
        <DialogContentText sx={{ color: CommonColors.black }}>
          {message}
        </DialogContentText>
      }
      cancelButton={
        <Button
          variant="text"
          sx={{
            color: CommonColors.black,
            ':hover': { bgcolor: CommonColors.lightGray },
          }}
          onClick={handleClose}
        >
          編集に戻る
        </Button>
      }
      confirmButton={<Button onClick={handleClick}>保存</Button>}
    />
  );
};
