import { ChangeEvent, useState } from 'react';
import { useAppDispatch } from '@store/index';
import {
  removeSkipRealogramStepThreeState,
  setSkipRealogramStepThreeState,
} from '@reducers/skipRealogramStepThree';
import {
  removeSkipFlatRealogramStepThreeState,
  setSkipFlatRealogramStepThreeState,
} from '@reducers/skipFlatRealogramStepThree';
import { StorageHelper } from '@utils/storageHelper';

export const useSkipRealogramStepThree = (isFlat?: boolean) => {
  const dispatch = useAppDispatch();
  const storageHelper = new StorageHelper();
  const isSkipRealogramStepThreeOn =
    storageHelper.get('isSkipRealogramStepThreeOn') === 'true';
  const isSkipFlatRealogramStepThreeOn =
    storageHelper.get('isSkipFlatRealogramStepThreeOn') === 'true';
  /*
   * realogramをアップロードした時にローカルストレージのisSkipRealogramStepThreeOnが変化する
   * 送信前に次回から表示しないの状態を保持するためにisSkipStepThree、isFlatSkipStepThreeを利用
   */
  const [isSkipStepThree, setIsSkipStepThree] = useState<boolean>(
    isSkipRealogramStepThreeOn
  );
  const [isFlatSkipStepThree, setIsFlatSkipStepThree] = useState<boolean>(
    isSkipFlatRealogramStepThreeOn
  );
  const handleSetSkipRealogramStepThree = (isSkip: boolean) =>
    dispatch(setSkipRealogramStepThreeState(isSkip));

  const handleSetSkipFlatRealogramStepThree = (isSkip: boolean) =>
    dispatch(setSkipFlatRealogramStepThreeState(isSkip));

  const handleResetSkipRealogramStepThree = () => {
    setIsSkipStepThree(false);
    setIsFlatSkipStepThree(false);
    dispatch(removeSkipRealogramStepThreeState());
    dispatch(removeSkipFlatRealogramStepThreeState());
  };

  const handleSetIsSkipStepThree = (e: ChangeEvent<HTMLInputElement>) =>
    isFlat
      ? setIsFlatSkipStepThree(e.target.checked)
      : setIsSkipStepThree(e.target.checked);

  return {
    isSkipRealogramStepThreeOn,
    isSkipFlatRealogramStepThreeOn,
    isSkipStepThree,
    isFlatSkipStepThree,
    handleSetIsSkipStepThree,
    handleSetSkipRealogramStepThree,
    handleSetSkipFlatRealogramStepThree,
    handleResetSkipRealogramStepThree,
  };
};
