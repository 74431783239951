import { datadogRum } from '@datadog/browser-rum';
import {
  updateLoadingIndicatorState,
  updateResetData,
} from '@reducers/loadingIndicator';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import {
  useListProductsBulkQuery,
  useUpdateFaceMutation,
} from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { toastMessages } from '@utils/const';
import { getArgs, getNewSelectedItem } from '@utils/realogram';
import { useState } from 'react';
import { DataDogSourceType } from 'types/common';
import {
  ProductCandidateWithName,
  RealogramCandidate,
  RealogramSelectedItem,
} from 'types/realogram';
import { divisorForSeconds } from '../utils';

export const useProductCandidatesDrawer = (
  realogramCandidateId: number,
  refetchRealogramCandidate: () => void,
  realogramCandidate?: RealogramCandidate,
  productCandidateIds?: string,
  selectedItem?: RealogramSelectedItem
) => {
  const dispatch = useAppDispatch();
  const isResetData = useAppSelector(
    (state) => state.LoadingIndicator.isResetData
  );
  const shelfBoardsSelector = (realogramCandidate?: RealogramCandidate) =>
    realogramCandidate?.detail?.products_shelves.shelf_boards;

  const [updateFace, { isLoading: isLoadingUpdateFace }] =
    useUpdateFaceMutation();
  const { data: productCandidatesBulk } = useListProductsBulkQuery(
    { productIds: productCandidateIds, shape: false, detail: true },
    { skip: !productCandidateIds }
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<
    ProductCandidateWithName | undefined
  >();
  const [selectedUnknown, setSelectedUnknown] = useState(false);
  const [selectedProductChoice, setSelectedProductChoice] = useState(false);
  const [isDisplayProductDetail, setIsDisplayProductDetail] = useState(false);

  const {
    primary_candidate: primaryCandidate,
    product_candidates: productCandidates,
    is_unknown: isUnknown,
  } = selectedItem?.item ?? {};
  const productIdFrom = primaryCandidate
    ? primaryCandidate?.product_id
    : 'unknown';
  const productIdTo = selectedCandidate
    ? selectedCandidate?.product_id
    : 'unknown';
  const bottomDrawerOpen = !!selectedCandidate || selectedUnknown;
  const productCandidatesLength = productCandidates?.length ?? 0;

  const handleProductCandidatesDrawerClose = (
    sourceType: DataDogSourceType,
    productIdFrom: number | 'unknown',
    productIdTo: number | 'unknown'
  ) => {
    setIsDrawerOpen(false);
    performance.mark('end');
    performance.measure('time', 'start', 'end');
    // 該当なし->'unknown'
    datadogRum.addAction('revise_face', {
      source: sourceType,
      // オーバレイを開いてから閉じるまでの時間(秒)を計測
      spent_time: Math.floor(
        performance.getEntriesByType('measure')[0].duration / divisorForSeconds
      ),
      realogram_id: realogramCandidate?.id, // 対象の棚割実績ID
      product_id_from: productIdFrom,
      product_id_to: productIdTo,
    });
    performance.clearMeasures();
  };

  const handleChangeCandidateInDrawer = async () => {
    if (!selectedItem) {
      reset();
      return;
    }
    const args = getArgs(
      realogramCandidateId,
      selectedItem,
      selectedUnknown,
      selectedCandidate
    );

    dispatch(updateLoadingIndicatorState(true));
    try {
      const { realogram_candidate } = await updateFace(args).unwrap();
      const shelfBoards = shelfBoardsSelector(realogram_candidate);
      if (shelfBoards?.length) {
        const item = getNewSelectedItem(shelfBoards, selectedItem);
        dispatch(updateSelectedRealogramItem(item));
      }
      setSelectedCandidate(undefined);
      setSelectedUnknown(false);
      setSelectedProductChoice(false);
      handleProductCandidatesDrawerClose(
        selectedCandidate
          ? selectedProductChoice
            ? 'search'
            : 'top10'
          : 'not_available',
        productIdFrom,
        productIdTo
      );
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.corrected,
        })
      );
      dispatch(updateResetData(true));
      refetchRealogramCandidate();
    } catch (e) {
      console.log(e, 'error');
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  const reset = () => {
    setSelectedCandidate(undefined);
    setSelectedUnknown(false);
    setSelectedProductChoice(false);
    setIsDisplayProductDetail(false);
    handleProductCandidatesDrawerClose('cancel', productIdFrom, 'unknown');
  };

  const handleProductClick = (productCandidate?: ProductCandidateWithName) => {
    setSelectedUnknown(false);
    // 同じ商品を選択した場合はundefinedにする
    if (selectedCandidate?.product_id === productCandidate?.product_id) {
      setSelectedCandidate(undefined);
    } else {
      setSelectedCandidate(productCandidate);
    }
  };

  const handleProductChoiceClick = () => {
    setSelectedCandidate(undefined);
    setSelectedUnknown(false);
    performance.mark('start');
    setSelectedProductChoice(true);
    setSelectedCandidate(undefined);
  };

  const handleSpecialProductClick = () => {
    if (selectedItem?.item.is_unknown) return;
    setSelectedCandidate(undefined);
    setSelectedUnknown(!selectedUnknown);
    setSelectedProductChoice(false);
  };

  const handleCloseProductChoice = () => {
    setSelectedProductChoice(false);
    setSelectedCandidate(undefined);
    setSelectedUnknown(false);
  };

  return {
    isDrawerOpen,
    products: productCandidatesBulk?.products,
    bottomDrawerOpen,
    primaryCandidate,
    productCandidates,
    selectedCandidate,
    productIdFrom,
    isUnknown,
    productCandidatesLength,
    selectedUnknown,
    isLoadingUpdateFace,
    isResetData,
    selectedProductChoice,
    isDisplayProductDetail,
    setIsDrawerOpen,
    setSelectedCandidate,
    handleProductCandidatesDrawerClose,
    reset,
    handleProductClick,
    handleProductChoiceClick,
    handleSpecialProductClick,
    handleChangeCandidateInDrawer,
    handleCloseProductChoice,
    setSelectedProductChoice,
    setIsDisplayProductDetail,
  };
};
