import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rowsPerPage } from '@utils/const';

type PlanogramState = {
  page: number;
  limit: number;
  selectedItemIndex: number;
  selectedDirectoryId?: string;
  lastVisitedPlanogramListURL: string;
};

const initialState: PlanogramState = {
  page: 1,
  limit: rowsPerPage,
  selectedItemIndex: 0,
  selectedDirectoryId: '',
  lastVisitedPlanogramListURL: '',
};

const planogramSlice = createSlice({
  name: 'planogram',
  initialState,
  reducers: {
    reset: () => initialState,
    setPlanogramsPageNumber: (
      state: PlanogramState,
      action: PayloadAction<number>
    ) => {
      state.page = action.payload;
    },
    setSelectedItemIndex: (
      state: PlanogramState,
      action: PayloadAction<number>
    ) => {
      state.selectedItemIndex = action.payload;
    },
    setSelectedDirectoryId: (
      state: PlanogramState,
      action: PayloadAction<string>
    ) => {
      state.selectedDirectoryId = action.payload;
    },
    setLastVisitedPlanogramListURL: (
      state: PlanogramState,
      action: PayloadAction<string>
    ) => {
      state.lastVisitedPlanogramListURL = action.payload;
    },
  },
});

export const {
  setPlanogramsPageNumber,
  reset,
  setSelectedItemIndex,
  setSelectedDirectoryId,
  setLastVisitedPlanogramListURL,
} = planogramSlice.actions;

export const PlanogramReducer = planogramSlice.reducer;
