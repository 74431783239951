import { useModal } from '@hooks/useModal';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { closeModal, updateErrorMessage } from '@reducers/modal';
import { openToast } from '@reducers/toast';
import { useAppDispatch } from '@store/index';
import { toastMessages } from '@utils/const';
import { CreateUserFormProps } from '../fragments/createUserForm';
import { useMutation } from '@tanstack/react-query';
import { userApi } from '@api/services/users';
import { queryClient } from '@api/query-client';
import { userQueryKey } from '@api/hooks/useUsers';
import httpStatus from 'http-status';

type Error = {
  message: string;
  name: string;
  status: number;
};

export const userCreateQueryKey = 'user_create';
export const useCreateUser = () => {
  const dispatch = useAppDispatch();

  const { showModal: showCreateUserModal } = useModal<CreateUserFormProps>(
    'createUserModal',
    '新しいユーザーを追加'
  );
  const { mutateAsync: createUser } = useMutation({
    mutationFn: userApi.createUser,
    onError: (error) => {
      if ((error as Error).status === httpStatus.CONFLICT) {
        dispatch(updateErrorMessage('このユーザーは追加済です。'));
      } else {
        dispatch(
          openToast({
            message: toastMessages.error.createUser,
            type: 'error',
          })
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [userQueryKey],
      });
    },
  });

  const handleCreateUser = () => {
    showCreateUserModal({
      onSubmit: async ({ fullname, email, role }) => {
        dispatch(updateLoadingIndicatorState(true));
        try {
          await createUser({ fullname, email, role });
          dispatch(
            openToast({
              message: toastMessages.success.createUser,
              type: 'success',
            })
          );
          dispatch(closeModal());
        } catch (error) {
          if ((error as Error).status === httpStatus.CONFLICT) {
            dispatch(updateErrorMessage('このユーザーは追加済です。'));
          } else {
            dispatch(
              openToast({
                message: toastMessages.error.createUser,
                type: 'error',
              })
            );
          }
        } finally {
          dispatch(updateLoadingIndicatorState(false));
        }
      },
    });
  };

  return { handleCreateUser };
};
