import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type DialogFieldProps = {
  title: string;
  value: string;
};

export const DialogField: FC<DialogFieldProps> = ({ title, value }) => {
  return (
    <Box component="div" display="flex" gap={2} justifyContent="space-between">
      <Typography
        sx={{
          flex: '0 1 145px',
          fontSize: '14px',
          color: theme.palette.textBlack.primary,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          flex: 1,
          fontSize: '14px',
          color: theme.palette.textBlack.secondary,
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};
