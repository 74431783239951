import { ChangeEvent, FC, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import {
  ChangeStep,
  RealogramType,
  RealogramUploadStep,
} from 'types/realogram';
import { GuideButtonArea } from './buttons/guideButtonArea';
import { Header } from '@components/organisms/header/header';
import { theme } from 'theme';

type Props = {
  changeStep: ChangeStep;
  selectedRealogramType: RealogramType;
  onFirstFileSelect?: (file: File) => void;
  isFlat: boolean;
  currentStep: RealogramUploadStep;
  children: ReactNode;
  title: string;
  isSkipStepThree?: boolean;
  isFlatSkipStepThree?: boolean;
  handleSetIsSkipStepThree?: (
    e: ChangeEvent<HTMLInputElement>,
    type: RealogramType
  ) => void;
  isXs?: boolean;
};

export const StepLayout: FC<Props> = ({
  changeStep,
  selectedRealogramType,
  onFirstFileSelect,
  isFlat,
  currentStep,
  children,
  title,
  isSkipStepThree,
  isFlatSkipStepThree,
  handleSetIsSkipStepThree,
  isXs = false,
}) => {
  return (
    <Box
      component="div"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.white.primary,
      }}
    >
      <Header>
        <Typography variant="headingS">{title}</Typography>
      </Header>
      <Box
        component="div"
        flex={1}
        display="flex"
        alignItems={{
          xs: 'flex-start',
          md: 'center',
        }}
        width={{
          xs: '100%',
          md: 'auto',
        }}
        justifyContent="center"
      >
        {children}
      </Box>
      {currentStep === 'three' && handleSetIsSkipStepThree && isXs && (
        <Box
          component="div"
          sx={{
            backgroundColor: 'transparent',
            alignItems: 'center',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            margin: '16px 0 24px',
          }}
        >
          <label
            htmlFor="dontDisplayNextTime"
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {isFlat ? (
              <input
                type="checkbox"
                id="dontDisplayNextTime"
                style={{
                  width: '18px',
                  height: '18px',
                  padding: '8px',
                  marginRight: '8px',
                }}
                checked={isFlatSkipStepThree}
                onChange={(e) => handleSetIsSkipStepThree(e, 'flat')}
              />
            ) : (
              <input
                type="checkbox"
                id="dontDisplayNextTime"
                style={{
                  width: '18px',
                  height: '18px',
                  padding: '8px',
                  marginRight: '8px',
                }}
                checked={isSkipStepThree}
                onChange={(e) => handleSetIsSkipStepThree(e, 'normal')}
              />
            )}
            <Typography variant="body1">次回から表示しない</Typography>
          </label>
        </Box>
      )}
      <GuideButtonArea
        changeStep={changeStep}
        selectedRealogramType={selectedRealogramType}
        currentStep={currentStep}
        onFirstFileSelect={onFirstFileSelect}
        isFlat={isFlat}
        isSkipStepThree={isSkipStepThree}
        isFlatSkipStepThree={isFlatSkipStepThree}
        handleSetIsSkipStepThree={handleSetIsSkipStepThree}
        isXs={isXs}
      />
    </Box>
  );
};
