import { AlertDialog } from '@components/organisms';
import { Button, DialogContentText } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';

type DirectoryType = 'directory' | 'root' | 'file' | 'trash';
type Props = {
  open: boolean;
  directoryType?: DirectoryType;
  handleDeleteShelfEditorItem: () => void;
  handleCloseDialog: () => void;
};

export const PlanogramDeleteDialog: FC<Props> = ({
  open,
  directoryType = 'file',
  handleDeleteShelfEditorItem,
  handleCloseDialog,
}) => {
  const typeName = directoryType === 'file' ? '棚割' : 'フォルダ';
  return (
    <AlertDialog
      open={open}
      title={`${typeName}を削除します。`}
      content={
        <DialogContentText sx={{ color: CommonColors.black }}>
          この操作は取り消せません。問題がない場合のみ削除を行なってください。
        </DialogContentText>
      }
      cancelButton={
        <Button
          onClick={handleCloseDialog}
          sx={{
            color: CommonColors.black,
            ':hover': { bgcolor: CommonColors.lightGray },
          }}
        >
          キャンセル
        </Button>
      }
      confirmButton={
        <Button
          onClick={handleDeleteShelfEditorItem}
          sx={{
            color: CommonColors.appRed,
            ':hover': { bgcolor: CommonColors.lightGray },
          }}
        >
          削除
        </Button>
      }
    />
  );
};
