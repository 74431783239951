import { CancelButton } from '@components/molecules/cancelButton/cancelButton';
import { StyledButton } from '@components/molecules/styledButton/styledButton';
import { Box, Button, Divider } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { CategoryBreadcrumbs } from './categoryBreadcrumbs';

type Props = {
  handleClose: () => void;
  handleClick: () => void;
  breadcrumbs: string[];
  disabledSelect: boolean;
  disabledCancel: boolean;
  handleClickCategoryCancel: () => void;
};
export const CategorySelectionModalActions: FC<Props> = ({
  handleClose,
  handleClick,
  breadcrumbs,
  disabledSelect,
  disabledCancel,
  handleClickCategoryCancel,
}) => {
  return (
    <>
      {breadcrumbs.length > 1 && (
        <Box
          component="div"
          padding="8px 16px"
          borderTop={`1px solid ${theme.palette.dividerBlack.medium}`}
        >
          <CategoryBreadcrumbs breadcrumbs={breadcrumbs} screenType="Modal" />
        </Box>
      )}
      <Divider />
      <Box
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={1}
      >
        <Button
          variant="text"
          sx={{ fontWeight: 'bold' }}
          onClick={handleClickCategoryCancel}
          disabled={disabledCancel}
        >
          選択を解除
        </Button>
        <Box component="div" display="flex" gap={1} alignItems="center">
          <CancelButton onClick={handleClose} />
          <StyledButton
            disabled={disabledSelect}
            type="contained"
            onClick={handleClick}
            width="60px"
          >
            選択
          </StyledButton>
        </Box>
      </Box>
    </>
  );
};
