import { paths } from 'types/api';
import { baseApi, KeysToCamelcase } from '../baseApi';

type SignInResponse =
  paths['/api/v1/public/users/sign_in']['post']['responses']['200']['content']['application/json'];
type SignInParams = KeysToCamelcase<
  paths['/api/v1/public/users/sign_in']['post']['requestBody']['content']['application/json']
>;

type PostPublicUserMfaAuthParams = KeysToCamelcase<
  paths['/api/v1/public/users/mfa_auth']['post']['requestBody']['content']['application/json']
>;
type PostPublicUserMfaAuthResponse =
  paths['/api/v1/public/users/mfa_auth']['post']['responses']['200']['content']['application/json'];

type ForgotPasswordResponse =
  paths['/api/v1/public/users/forgot_password']['post']['responses']['200']['content']['application/json'];
type ForgotPasswordParams = KeysToCamelcase<
  paths['/api/v1/public/users/forgot_password']['post']['requestBody']['content']['application/json']
>;
type ConfirmForgotPasswordResponse =
  paths['/api/v1/public/users/confirm_forgot_password']['put']['responses']['200']['content']['application/json'];
type ConfirmForgotPasswordParams = KeysToCamelcase<
  paths['/api/v1/public/users/confirm_forgot_password']['put']['requestBody']['content']['application/json']
>;

type SignInWithCodeResponse =
  paths['/api/v1/public/users/sign_in_with_code']['post']['responses']['200']['content']['application/json'];
type SignInWithCodeParams = KeysToCamelcase<
  paths['/api/v1/public/users/sign_in_with_code']['post']['requestBody']['content']['application/json']
> & {
  state?: string;
};

type SetInitialPasswordResponse =
  paths['/api/v1/public/users/set_initial_password']['post']['responses']['200']['content']['application/json'];
type SetInitialPasswordParams = KeysToCamelcase<
  paths['/api/v1/public/users/set_initial_password']['post']['requestBody']['content']['application/json']
>;

// see) https://staging.shelf.pfn.dev/docs#/users
export const publicUsersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation<SignInResponse, SignInParams>({
      query: (params) => ({
        url: 'public/users/sign_in',
        method: 'POST',
        body: {
          username: params.username,
          password: params.password,
          auth_flow: params.authFlow,
        },
      }),
    }),
    postUserMfaAuth: builder.mutation<
      PostPublicUserMfaAuthResponse,
      PostPublicUserMfaAuthParams
    >({
      query: (params) => ({
        url: 'public/users/mfa_auth',
        method: 'POST',
        body: {
          challenge_name: params.challengeName,
          session: params.session,
          username: params.username,
          mfa_token: params.mfaToken,
        },
      }),
    }),
    forgotPassword: builder.mutation<
      ForgotPasswordResponse,
      ForgotPasswordParams
    >({
      query: (params) => ({
        url: 'public/users/forgot_password',
        method: 'POST',
        body: {
          username: params.username,
        },
      }),
    }),
    confirmForgotPassword: builder.mutation<
      ConfirmForgotPasswordResponse,
      ConfirmForgotPasswordParams
    >({
      query: (params) => ({
        url: 'public/users/confirm_forgot_password',
        method: 'PUT',
        body: {
          username: params.username,
          confirmation_code: params.confirmationCode,
          password: params.password,
        },
      }),
    }),
    signInWithCode: builder.mutation<
      SignInWithCodeResponse,
      SignInWithCodeParams
    >({
      query: (params) => ({
        url: 'public/users/sign_in_with_code',
        method: 'POST',
        query: {
          state: params.state,
        },
        body: {
          authorization_code: params.authorizationCode,
        },
      }),
    }),
    setInitialPassword: builder.mutation<
      SetInitialPasswordResponse,
      SetInitialPasswordParams
    >({
      query: (params) => ({
        url: 'public/users/set_initial_password',
        method: 'POST',
        body: {
          username: params.username,
          password: params.password,
          challenge_name: params.challengeName,
          session: params.session,
        },
      }),
    }),
  }),
});

export const {
  usePostUserMfaAuthMutation,
  useSignInMutation,
  useForgotPasswordMutation,
  useConfirmForgotPasswordMutation,
  useSignInWithCodeMutation,
  useSetInitialPasswordMutation,
} = publicUsersApi;
