import { CloseButton } from '@components/molecules/closeButton/closeButton';
import { PlanogramInfoDialog } from '@components/molecules/planogramInfoDialog/planogramInfoDialog';
import { PlanogramPreviewModeToggle } from '@components/organisms/planogramPreviewModeToggle/planogramPreviewModeToggle';
import { InfoOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { FC, useState } from 'react';
import { Planogram, PreviewMode } from 'types/planogram';
import { theme } from '../../../../theme';
import { ThreeDAbsoluteMenuButtons } from './threeDAbsoluteMenuButtons';
import { TwoDAbsoluteMenuButtons } from './twoDAbsoluteMenuButtons';

type Props = {
  previewMode: PreviewMode;
  planogram: Planogram;
  zoomScale: number;
  isDisplay3dViewButton: boolean;
  handleClose: () => void;
  handleChangePreviewMode: (value: PreviewMode) => void;
  handleIncrementZoom: () => void;
  handleDecrementZoom: () => void;
  handleReset3dView: () => void;
  hiddenElement?: boolean;
  handleViewPrint: () => void;
  isViewPrint: boolean;
  isFlat: boolean;
  handleClickRotate: () => void;
  handleRotate: (degree: number) => void;
  isTooltipOpen: boolean;
  closeTooltip: () => void;
};
export const AbsoluteMenuButtons: FC<Props> = ({
  previewMode,
  planogram,
  zoomScale,
  isDisplay3dViewButton,
  handleClose,
  handleChangePreviewMode,
  handleIncrementZoom,
  handleDecrementZoom,
  handleReset3dView,
  hiddenElement,
  handleViewPrint,
  isViewPrint,
  isFlat,
  handleClickRotate,
  handleRotate,
  isTooltipOpen,
  closeTooltip,
}) => {
  const [openInfoDialog, setOpenInfoDialog] = useState(false);

  return (
    <>
      <Box
        component="div"
        sx={{
          position: 'absolute',
          top: 24,
          left: 24,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {!hiddenElement && (
          <CloseButton
            sx={{ borderColor: theme.palette.dividerBlack.dark }}
            onClick={handleClose}
          />
        )}
        {previewMode === '2d' && (
          <TwoDAbsoluteMenuButtons
            zoomScale={zoomScale}
            handleIncrementZoom={handleIncrementZoom}
            handleDecrementZoom={handleDecrementZoom}
            handleClickRotate={handleClickRotate}
            isTooltipOpen={isTooltipOpen}
            closeTooltip={closeTooltip}
            isFlat={isFlat}
          />
        )}
        {previewMode === '3d' && isDisplay3dViewButton && (
          <ThreeDAbsoluteMenuButtons
            handleReset3dView={handleReset3dView}
            handleViewPrint={handleViewPrint}
            isViewPrint={isViewPrint}
            isFlat={isFlat}
            handleRotate={handleRotate}
          />
        )}
      </Box>
      <Box
        component="div"
        sx={{
          position: 'absolute',
          right: 24,
          top: 24,
          display: 'flex',
          gap: 2,
        }}
      >
        <PlanogramPreviewModeToggle
          value={previewMode}
          handleChange={handleChangePreviewMode}
        />
        <IconButton
          disableRipple
          onClick={() => setOpenInfoDialog(true)}
          sx={{ p: 0, width: '40px', height: '40px' }}
        >
          <InfoOutlined
            style={{
              width: '24px',
              height: '24px',
              color: theme.palette.icons.primary,
              background: theme.palette.white.primary,
              borderRadius: '50%',
            }}
          />
        </IconButton>
      </Box>
      <PlanogramInfoDialog
        planogram={planogram}
        open={openInfoDialog}
        onClose={() => setOpenInfoDialog(false)}
      />
    </>
  );
};
