import { Button } from '@mui/material';
import { paths } from '@utils/const';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const CancelButton: FC = () => {
  const navigate = useNavigate();

  return (
    <Button fullWidth onClick={() => navigate(paths.setting)}>
      キャンセル
    </Button>
  );
};
