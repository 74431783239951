import { AlertDialog } from '@components/organisms/alertDialog/alertDialog';
import { DialogContentText } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';
import { CancelButton } from '../../../molecules/cancelButton/cancelButton';
import { DeleteButton } from './deleteButton';

type Props = {
  open: boolean;
  handleClose: () => void;
  handleClickDeleteShelfBoard: () => void;
};

export const DeleteShelfBoardDialog: FC<Props> = ({
  open,
  handleClose,
  handleClickDeleteShelfBoard,
}) => {
  return (
    <AlertDialog
      open={open}
      title="棚板の認識結果を削除します。"
      content={
        <DialogContentText color={CommonColors.black}>
          この操作は取り消せません。問題がない場合のみ削除を行なってください。
        </DialogContentText>
      }
      cancelButton={<CancelButton onClick={handleClose} />}
      confirmButton={
        <DeleteButton
          handleClick={() => {
            handleClose();
            handleClickDeleteShelfBoard();
          }}
        />
      }
    />
  );
};
