import { Box } from '@mui/material';
import { FC, useState } from 'react';
import { ShelfDetailMode, ShelfDetailView } from 'types/common';
import { BboxColors, BucketPlanogramPlan } from 'types/planogram';
import { Frame } from '../frame/frame';

type Props = {
  plan: BucketPlanogramPlan;
  diffColor?: string;
  bboxEnabled?: boolean;
  bboxColors?: BboxColors;
  detailView?: ShelfDetailView;
  detailMode?: ShelfDetailMode;
  zoomScale: number;
  setZoomScale: (zoomScale: number) => void;
  isEditor?: boolean;
  isCompared?: boolean;
  handleChangeFront?: () => void;
};

export const FlatPlanogram: FC<Props> = ({
  plan,
  diffColor,
  bboxEnabled = false,
  bboxColors,
  detailMode,
  detailView,
  zoomScale,
  setZoomScale,
  isEditor,
  isCompared = false,
  handleChangeFront,
}) => {
  // this is used to get value for centering the planogram
  const [translatePosition, setTranslatePosition] = useState(0);
  return (
    <Box
      component="div"
      display="flex"
      sx={{ transform: `translateX(${translatePosition}px)` }}
    >
      <Frame
        isEditor={isEditor}
        plan={plan}
        diffColor={diffColor}
        bboxColors={bboxColors}
        bboxEnabled={bboxEnabled}
        detailMode={detailMode}
        detailView={detailView}
        zoomScale={zoomScale}
        setZoomScale={setZoomScale}
        setTranslatePosition={setTranslatePosition}
        translatePosition={translatePosition}
        isCompared={isCompared}
        handleChangeFront={handleChangeFront}
      />
    </Box>
  );
};
