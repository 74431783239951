import { Link, TextField, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { EmailPasswordFormData } from '../login';
import { paths } from '@utils/const';

export const EmailPasswordField: FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<EmailPasswordFormData>();
  return (
    <>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="メールアドレス"
            type="email"
            inputMode="email"
            value={field.value}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            size="small"
            autoComplete="username"
            sx={{
              mb: 3,
              '& .MuiFormHelperText-root': {
                color: CommonColors.appRed,
              },
            }}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="パスワード"
            value={field.value}
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            type="password"
            size="small"
            autoComplete="current-password"
            sx={{
              mb: 2,
              '& .MuiFormHelperText-root': {
                color: CommonColors.appRed,
              },
            }}
          />
        )}
      />
      <Typography>
        <Link component={RouterLink} to={paths.password.reset}>
          パスワードをお忘れの方
        </Link>
      </Typography>
    </>
  );
};
