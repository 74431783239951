import { useSearchParams } from 'react-router-dom';

/**
 * 責務
 * URLのクエリパラメータの存在を確認する
 *
 * - 与えられた複数のクエリパラメータの中で1つでも存在するかどうかを確認
 */
export const useQueryParameterPresence = () => {
  const [searchParams] = useSearchParams();

  const hasQueryParameters = (queries: string[]): boolean => {
    return queries.some((query) => searchParams.has(query));
  };

  return {
    hasQueryParameters,
  };
};
