import { Box, Button, Typography } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { theme } from 'theme';
import { RealogramType, RealogramUploadStep } from 'types/realogram';
import { NextButton } from './nextButton';

type Props = {
  changeStep: (step: RealogramUploadStep, isNext: boolean) => void;
  selectedRealogramType: RealogramType;
  currentStep: RealogramUploadStep;
  onFirstFileSelect?: (file: File) => void;
  setQuitDialogOpen?: () => void;
  isFlat: boolean;
  disabled?: boolean;
  isSkipStepThree?: boolean;
  isFlatSkipStepThree?: boolean;
  handleSetIsSkipStepThree?: (
    e: ChangeEvent<HTMLInputElement>,
    type: RealogramType
  ) => void;
  isXs?: boolean;
};

export const GuideButtonArea: FC<Props> = ({
  changeStep,
  selectedRealogramType,
  currentStep,
  onFirstFileSelect,
  setQuitDialogOpen,
  isFlat,
  disabled = false,
  isSkipStepThree,
  isFlatSkipStepThree,
  handleSetIsSkipStepThree,
  isXs = false,
}) => {
  const baseButtonStyle = {
    height: '48px',
    p: 2,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '16px',
  };
  const isStepOneOrFour = currentStep === 'one' || currentStep === 'four';
  return (
    <>
      <Box
        component="div"
        height="152px"
        justifyContent="space-between"
        display="flex"
        flexDirection="row"
        alignItems="center"
        width="100%"
        px={3}
        pt="24px"
        pb="80px"
        borderTop={`1px solid ${theme.palette.dividerBlack.light}`}
      >
        <Button
          variant="contained"
          aria-describedby="menuButton"
          component="label"
          sx={{
            ...baseButtonStyle,
            backgroundColor: theme.palette.white.primary,
            width: '136px',
            color: theme.palette.textBlack.primary,
            '&:hover': {
              backgroundColor: theme.palette.backgroundBlack.disabled,
            },
          }}
          onClick={() => {
            if (currentStep === 'four' && setQuitDialogOpen) {
              setQuitDialogOpen();
            } else {
              changeStep('one', false);
            }
          }}
        >
          撮影をやめる
        </Button>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          gap={{
            xs: 1,
            sm: 3,
          }}
        >
          {currentStep === 'three' && handleSetIsSkipStepThree && !isXs && (
            <Box
              component="div"
              sx={{
                backgroundColor: 'transparent',
                alignItems: 'center',
                fontSize: 16,
                fontWeight: 400,
                lineHeight: '24px',
              }}
            >
              <label
                htmlFor="dontDisplayNextTime"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {isFlat ? (
                  <input
                    type="checkbox"
                    id="dontDisplayNextTime"
                    style={{
                      width: '18px',
                      height: '18px',
                      padding: '8px',
                      marginRight: '8px',
                    }}
                    checked={isFlatSkipStepThree}
                    onChange={(e) => handleSetIsSkipStepThree(e, 'flat')}
                  />
                ) : (
                  <input
                    type="checkbox"
                    id="dontDisplayNextTime"
                    style={{
                      width: '18px',
                      height: '18px',
                      padding: '8px',
                      marginRight: '8px',
                    }}
                    checked={isSkipStepThree}
                    onChange={(e) => handleSetIsSkipStepThree(e, 'normal')}
                  />
                )}
                <Typography variant="body1">次回から表示しない</Typography>
              </label>
            </Box>
          )}
          <Button
            variant="contained"
            aria-describedby="menuButton"
            component="label"
            sx={{
              ...baseButtonStyle,
              width: '72px',
              backgroundColor: isStepOneOrFour
                ? theme.palette.backgroundBlack.disabled
                : 'transparent',
              color: theme.palette.textBlack.primary,
              border: isStepOneOrFour
                ? 'none'
                : `1px solid ${theme.palette.dividerBlack.dark}`,
              '&:hover': {
                backgroundColor: theme.palette.backgroundBlack.light,
              },
            }}
            onClick={() => changeStep(currentStep, false)}
            disabled={isStepOneOrFour}
          >
            戻る
          </Button>
          <NextButton
            currentStep={currentStep}
            selectedRealogramType={selectedRealogramType}
            changeStep={changeStep}
            onFirstFileSelect={onFirstFileSelect}
            isFlat={isFlat}
            disabled={disabled}
            baseButtonStyle={baseButtonStyle}
          />
        </Box>
      </Box>
    </>
  );
};
