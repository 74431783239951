import { planogramDirectoriesQueryKey } from '@api/hooks/usePlanogramDirectories';
import { planogramDirectoriesSearchQueryKey } from '@api/hooks/useSearchPlanogramDirectories';
import { queryClient } from '@api/query-client';
import { planogramsApi } from '@api/services/planogram';
import {
  PostPlanogramDirectory,
  PutPlanogramDirectory,
} from '@api/types/generated';
import { CreateOrRenameDirectoryFormProps } from '@components/organisms/createOrRenameDirectoryForm/createOrRenameDirectoryForm';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { closeModal } from '@reducers/modal';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useMutation } from '@tanstack/react-query';
import { toastMessages } from '@utils/const';
import { useParams } from 'react-router-dom';
import { PlanogramDirectory } from 'types/planogram';
import { useGetPlanogramPermission } from './useGetPlanogramPermission';
import { useModal } from './useModal';

export const useDirectoryManagement = (data: {
  afterCreateDirectorySuccessfully: (newDirectoryId: string) => void;
}) => {
  const { afterCreateDirectorySuccessfully } = data;
  const dispatch = useAppDispatch();
  const { isViewFolder, planogramDirectoryParent } = useAppSelector(
    (state) => state.SharePermission
  );
  const { isNotEnable: isCanNotCreate } = useGetPlanogramPermission({
    action: 'create',
    planogram: planogramDirectoryParent,
    isPlanogram: false,
    isCan: false,
  });
  const params = useParams();
  const currentDirectoryId = params.id;

  const { mutateAsync: createDirectory } = useMutation({
    mutationFn: (params: PostPlanogramDirectory) =>
      planogramsApi.createPlanogramDirectory(params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesSearchQueryKey],
      });
    },
  });
  const { mutateAsync: updateDirectory } = useMutation({
    mutationFn: ({
      planogramDirectoryId,
      ...params
    }: PutPlanogramDirectory & { planogramDirectoryId: string }) =>
      planogramsApi.updateDirectory(planogramDirectoryId, params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesSearchQueryKey],
      });
    },
  });

  const { showModal: showCreateDirectoryModal } =
    useModal<CreateOrRenameDirectoryFormProps>(
      'createOrRenameDirectoryForm',
      '新規フォルダ'
    );

  const { showModal: showRenameDirectoryModal } =
    useModal<CreateOrRenameDirectoryFormProps>(
      'createOrRenameDirectoryForm',
      'フォルダ名変更'
    );

  const handleCreateDirectory = () => {
    if (isViewFolder && isCanNotCreate) {
      dispatch(
        openToast({
          message: toastMessages.error.notPermittedCreateDirectory,
          type: 'error',
        })
      );

      return;
    }

    showCreateDirectoryModal({
      type: 'directory',
      onSubmit: async ({ name, type, planogramId }) => {
        dispatch(updateLoadingIndicatorState(true));
        try {
          const { planogram_directory: newDirectory } = await createDirectory({
            name,
            type,
            parent_id: currentDirectoryId,
            planogram_id: planogramId,
          });
          dispatch(closeModal());
          if (newDirectory) {
            afterCreateDirectorySuccessfully(newDirectory.id);
          }
        } catch (e) {
          console.error(e);
          dispatch(
            openToast({
              message: toastMessages.error.createDirectory,
              type: 'error',
            })
          );
        } finally {
          dispatch(updateLoadingIndicatorState(false));
        }
      },
    });
  };

  const handleUpdateDirectoryName = (
    planogramDirectory?: PlanogramDirectory
  ) => {
    if (!planogramDirectory || planogramDirectory?.type === 'file') return;

    showRenameDirectoryModal({
      name: planogramDirectory.name,
      type: 'directory',
      actionBtnTitle: '変更',
      onSubmit: async (data) => {
        dispatch(updateLoadingIndicatorState(true));
        try {
          await updateDirectory({
            ...data,
            planogramDirectoryId: planogramDirectory.id,
          });
          dispatch(
            openToast({
              type: 'success',
              message: toastMessages.success.updateDirectoryName,
            })
          );
          dispatch(closeModal());
        } catch (e) {
          console.error(e);
          dispatch(
            openToast({
              message: toastMessages.error.updateDirectoryName,
              type: 'error',
            })
          );
        } finally {
          dispatch(updateLoadingIndicatorState(false));
        }
      },
    });
  };

  return { handleCreateDirectory, handleUpdateDirectoryName };
};
