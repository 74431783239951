import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ComparisonTabModal } from '@utils/const';
import { SelectedItemType } from 'types/common';

export type CurrentSelectedType = 'actual' | 'plan';

type ComparisonItemModalState = {
  selectedItemId?: number;
  currentSelectedItemId?: number;
  currentSelectedType?: CurrentSelectedType;
  currentSelectedItem?: Partial<SelectedItemType>;
  currentRealogramDirectoryId?: string;
  currentPlanogramDirectoryId?: string;
  currentTab?: ComparisonTabModal;
  planogramSearchText?: string;
  realogramSearchText?: string;
};

const initialState: ComparisonItemModalState = {
  selectedItemId: undefined,
};

const comparisonItemModalSlice = createSlice({
  name: 'comparison',
  initialState,
  reducers: {
    updateSelectedItemId: (
      state: ComparisonItemModalState,
      action: PayloadAction<number | undefined>
    ) => {
      state.selectedItemId = action.payload;
    },
    updateCurrentSelectedType: (
      state: ComparisonItemModalState,
      action: PayloadAction<CurrentSelectedType | undefined>
    ) => {
      state.currentSelectedType = action.payload;
    },
    // save the old value of selectedItemId to keep the currently selected element when opening the popup
    updateCurrentSelectedItemId: (
      state: ComparisonItemModalState,
      action: PayloadAction<number | undefined>
    ) => {
      state.currentSelectedItemId = action.payload;
    },
    setCurrentTab: (
      state: ComparisonItemModalState,
      action: PayloadAction<ComparisonTabModal | undefined>
    ) => {
      state.currentTab = action.payload;
    },
    setCurrentSelectedItem: (
      state: ComparisonItemModalState,
      action: PayloadAction<Partial<SelectedItemType> | undefined>
    ) => {
      state.currentSelectedItem = action.payload;
    },
    setCurrentPlanogramDirectoryId: (
      state: ComparisonItemModalState,
      action: PayloadAction<string | undefined>
    ) => {
      state.currentPlanogramDirectoryId = action.payload;
    },
    setCurrentRealogramDirectoryId: (
      state: ComparisonItemModalState,
      action: PayloadAction<string | undefined>
    ) => {
      state.currentRealogramDirectoryId = action.payload;
    },
    updatePlanogramSearch: (
      state: ComparisonItemModalState,
      action: PayloadAction<string | undefined>
    ) => {
      state.planogramSearchText = action.payload;
    },
    updateRealogramSearch: (
      state: ComparisonItemModalState,
      action: PayloadAction<string | undefined>
    ) => {
      state.realogramSearchText = action.payload;
    },
  },
});

export const {
  updateSelectedItemId,
  setCurrentTab,
  setCurrentPlanogramDirectoryId,
  setCurrentRealogramDirectoryId,
  setCurrentSelectedItem,
  updateCurrentSelectedItemId,
  updatePlanogramSearch,
  updateRealogramSearch,
  updateCurrentSelectedType,
} = comparisonItemModalSlice.actions;
export const ComparisonItemModalReducer = comparisonItemModalSlice.reducer;
