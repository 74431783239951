import { FC } from 'react';
import { Box, Typography, Switch } from '@mui/material';
import { DemoIcon } from '@components/molecules';

type Props = {
  isDemoOn: boolean;
  handleDemoToggleChange: () => void;
};

export const AccountDemoMode: FC<Props> = ({
  isDemoOn,
  handleDemoToggleChange,
}) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 3,
      }}
    >
      <Typography variant="h6" fontWeight="bold">
        デモモード
      </Typography>
      <Typography fontSize={14}>
        デモモードを有効にすると、先行して新機能を試すことができます。ただし、挙動やデータは仮のもので、最終的な仕様は異なる場合があります。
      </Typography>
      <Typography display="flex" alignItems="center" fontSize={14}>
        デモ機能には{' '}
        <DemoIcon sx={{ width: 20, height: 20, fill: '#999999', mx: 0.5 }} />
        アイコンが付いています。
      </Typography>
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Switch checked={isDemoOn} onChange={handleDemoToggleChange} />
        <Typography ml={1} mr={2}>
          {isDemoOn ? 'ON' : 'OFF'}
        </Typography>
      </Box>
    </Box>
  );
};
