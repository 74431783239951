import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { useDeleteFaceMutation } from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch } from '@store/index';
import { toastMessages } from '@utils/const';
import { isSelectedItem } from '@utils/realogram';
import { ShelfDetailView } from 'types/common';
import { RealogramSelectedItem } from 'types/realogram';
import { useUrlQueryParams } from '../../../../hooks/useUrlQueryParams';

type Props = {
  realogramCandidateId: number;
  selectedItem?: RealogramSelectedItem;
  realogramDetailView: ShelfDetailView;
  refetchRealogramCandidate: () => void;
};

export const useDeleteRealogramFace = ({
  realogramCandidateId,
  selectedItem,
  realogramDetailView,
  refetchRealogramCandidate,
}: Props) => {
  const dispatch = useAppDispatch();
  const [deleteFaceMutation] = useDeleteFaceMutation();
  const { removeQueryParameter } = useUrlQueryParams();
  const handleClickDeleteFace = async (item: RealogramSelectedItem) => {
    dispatch(updateLoadingIndicatorState(true));
    try {
      await deleteFaceMutation({
        realogramCandidateId,
        faceId: item.item.id,
      }).unwrap();
      if (isSelectedItem(realogramDetailView, item, selectedItem)) {
        dispatch(updateSelectedRealogramItem(undefined));
        removeQueryParameter('item');
      }
      await refetchRealogramCandidate();
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.deleteFaceMutation,
        })
      );
    } catch (e) {
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.deleteFaceMutation,
        })
      );
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };
  return {
    handleClickDeleteFace,
  };
};
