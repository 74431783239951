import { RealogramCandidate } from 'types/realogram';
import { useModal } from '../../../../hooks/useModal';
import { ClonePlanogramModalProps } from '@components/organisms/clonePlanogramForm/clonePlanogramForm';
import { useRealToPlanMutation } from '@reducers/shelfAppsApi';
import { useNavigate } from 'react-router-dom';
import { paths, toastMessages } from '@utils/const';
import { useAppDispatch } from '@store/index';
import dayjs from 'dayjs';
import { openToast } from '@reducers/toast';
import { closeModal } from '@reducers/modal';

type Props = {
  realogramCandidate?: RealogramCandidate;
  isBucketType?: boolean;
};

export const useCloneRealogramToPlanogram = ({
  realogramCandidate,
  isBucketType,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showModal } = useModal<ClonePlanogramModalProps>(
    'clonePlanogram',
    'スキャン結果から棚割を作成'
  );
  const [realToPlan] = useRealToPlanMutation();
  const handleCreatePlanogram = () => {
    if (!realogramCandidate) {
      // データが取得できていないのでまだモーダル開かない
      // todo: ユーザーにフィードバックを行うべきか検討
      return;
    }
    const storeName = realogramCandidate.store_bay.store.name;
    const storeBayName = realogramCandidate.store_bay.name;
    const dateString = dayjs(realogramCandidate.created_at).format('YYYYMMDD');
    showModal({
      name: `${storeName} [${storeBayName}]-${dateString}の結果`,
      onSubmit: ({ name, bayPlanId }) => {
        realToPlan({
          realogramCandidateId: realogramCandidate.id,
          name,
          bayPlanId,
        })
          .unwrap()
          .then((res) => {
            navigate(paths.plans.edit(res.planogram.id));
          })
          .catch(() => {
            dispatch(
              openToast({
                type: 'error',
                message: toastMessages.error.createPlanogram,
              })
            );
          })
          .finally(() => {
            dispatch(closeModal());
          });
      },
      type: isBucketType ? 'buckets' : 'shelves',
    });
  };
  return { handleCreatePlanogram };
};
