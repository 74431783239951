import { Box, Chip, Popper, Typography } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { theme } from '../../../../theme';

export const ViewOnlyChip: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? undefined : event.currentTarget);
  };

  return (
    <>
      <Chip label="閲覧のみ" color="success" onClick={handleClick} />
      <Popper open={!!anchorEl} anchorEl={anchorEl} placement="bottom-end">
        <Box
          component="div"
          maxWidth="186px"
          sx={{
            py: 0.5,
            px: 1,
            backgroundColor: 'rgba(97, 97, 97, 0.9)',
            borderRadius: 0.5,
          }}
        >
          <Typography
            fontSize="14px"
            sx={{ color: theme.palette.white.primary }}
          >
            この棚割を編集できるのは棚割の作成者のみです。
          </Typography>
        </Box>
      </Popper>
    </>
  );
};
