import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const PostAddIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Start Icon">
          <g id="Union">
            <path
              d="M15.834 0H14.1673V2.5H11.6673V4.16667H14.1673V6.66667H15.834V4.16667H18.334V2.5H15.834V0Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.33398 18.3333V4.16667H10.834V5.83333H5.00065V8.33333H11.6673V6.66667H13.334V18.3333H3.33398ZM5.00065 12.5V10H11.6673V12.5H5.00065ZM11.6673 14.1667H5.00065V16.6667H11.6673V14.1667Z"
              fill="white"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
