import { ProductCompartments } from '@components/organisms/productCompartments/productCompartments';
import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import { usePlanogramContext } from '@hooks/usePlanogramProvider';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { Box } from '@mui/material';
import {
  addRecentProduct,
  setOpenOrientationMenu,
  updateComparedProductPosition,
  updateIsShowProductDetail,
  updateProductPosition,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { selectRealogramSelectedItem } from '@reducers/realogramCandidate/selector';
import { useAppDispatch, useAppSelector } from '@store/index';
import { getProductOrigin, isClickableCompartment } from '@utils/planogram';
import { isEqual } from 'lodash';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import {
  DndData,
  Product,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import {
  BboxColors,
  JustifyContent,
  PlanogramProductCompartment,
  PlanogramShelvesDetail,
  ProductPosition,
} from 'types/planogram';
import { ItemTypes } from 'types/rack';
import { CompartmentOrientationMenu } from '../compartmentOrientationMenu/compartmentOrientationMenu';
import { CompartmentMenu } from '../productsBay/fragments/compartmentMenu';

type Props = {
  shelf: PlanogramShelvesDetail['shelves'][0];
  row?: PlanogramProductCompartment[];
  rowIndex: number;
  shelfHeight: number;
  diffColor?: string;
  bboxColors: BboxColors;
  bboxEnabled?: boolean;
  detailView?: ShelfDetailView;
  detailMode?: ShelfDetailMode;
  justifyContent?: JustifyContent;
  isEditor?: boolean;
  isCompared?: boolean;
};

type DropResult = {
  hoverIndex: number;
  rowIndex: number;
};

type Item = {
  data: DndData | undefined;
  index: number;
  rowIndex: number;
};

export const DisplayShelf: FC<Props> = ({
  shelf,
  row,
  rowIndex,
  diffColor,
  shelfHeight,
  bboxColors,
  bboxEnabled,
  detailView,
  detailMode,
  justifyContent = 'start',
  isEditor = true,
  isCompared = false,
}) => {
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const {
    mode,
    productTag,
    productPosition,
    selectedProductCompartment,
    openOrientationMenu,
    comparedProductPosition,
  } = useAppSelector(selectPlanogramEditorState);
  const selectedRealogramItem = useAppSelector(selectRealogramSelectedItem);
  const { addProduct, moveProducts } = usePlanogramPlan();
  const { updateQueryParameter, removeQueryParameter } = useUrlQueryParams();
  const isEditingBay = isEditor && mode === 'BayEditor';

  const [isAllChildRendered, setIsAllChildRenndered] = useState(false);

  const data = usePlanogramContext();
  const productsBulk = isCompared
    ? data?.comparisonProductsBulk
    : data?.productsBulk;

  const handleClickRotate = () => {
    dispatch(setOpenOrientationMenu(true));
  };

  const handleClickGroup = (
    e: MouseEvent<HTMLElement>,
    position: ProductPosition,
    product: Product
  ) => {
    if (mode === 'BayEditor' || mode === 'preview') return; //NOTE: 什器編集中は商品タップさせない
    e.stopPropagation(); //親要素のonClick(handleClickAwayPlanogram)発火を抑制する
    //NOTE: 商品属性タブ選択時、該当属性を持たない商品はタップさせない
    if (detailView && !isClickableCompartment(detailView, product, productTag))
      return;
    const productPos = isCompared ? comparedProductPosition : productPosition;
    if (
      isEqual(productPos, position) ||
      (isEqual(productPosition, position) &&
        product.id === selectedProductCompartment?.id) ||
      (isEqual(comparedProductPosition, position) &&
        product.id === selectedProductCompartment?.id)
    ) {
      resetState();
      return;
    }
    dispatch(updateSelectedProductCompartment(product));
    dispatch(updateIsShowProductDetail(true));

    if (!isEditor && detailMode === 'comparison') return;
    if (isCompared) {
      dispatch(updateComparedProductPosition(position));
      dispatch(updateProductPosition(undefined));
    } else {
      dispatch(updateComparedProductPosition(undefined));
      dispatch(updateProductPosition(position));
      setAnchorEl(e.currentTarget);
      dispatch(setOpenOrientationMenu(false));
      dispatch(updateSelectedRealogramItem(undefined));
      updateQueryParameter('item', String(product.id));
    }
  };

  const resetState = () => {
    setAnchorEl(null);
    dispatch(setOpenOrientationMenu(false));
    dispatch(updateProductPosition(undefined));
    dispatch(updateSelectedProductCompartment(undefined));
    dispatch(updateIsShowProductDetail(false));
    dispatch(updateSelectedRealogramItem(undefined));
    dispatch(updateComparedProductPosition(undefined));
    removeQueryParameter('item');
  };

  const [{ item }, drop] = useDrop<Item, unknown, { item?: Item }>(
    () => ({
      accept: [ItemTypes.ITEM, ItemTypes.ITEM_GROUP],
      collect: (monitor) => ({
        item: monitor.getItem(),
      }),
      drop: (item, monitor) => {
        if (detailMode === 'comparison' && !isEditor) return;
        const dropResult = monitor.getDropResult<DropResult>();
        const hoverIndex = getHoverIndex(row, dropResult);
        const itemType = monitor.getItemType();
        const { index, rowIndex: shelfIndex } = item;

        if (itemType === ItemTypes.ITEM) {
          if (!item.data?.product) {
            return;
          }
          addProduct({
            to: {
              indexX: 0,
              indexY: rowIndex,
              subPosition: { indexX: hoverIndex, indexY: 0 },
            },
            product: item.data.product,
          });

          data?.addProductToBulk?.(item.data.product);
          dispatch(addRecentProduct(item.data.product));
        } else {
          resetState();
          moveProducts({
            from: {
              indexX: 0,
              indexY: shelfIndex,
              subPosition: { indexX: index, indexY: 0 },
            },
            to: {
              indexX: 0,
              indexY: rowIndex,
              subPosition: { indexX: hoverIndex, indexY: 0 },
            },
          });
        }
      },
    }),
    [row, rowIndex]
  );

  useEffect(() => {
    if (productPosition && isAllChildRendered) {
      const anchorElement = document.querySelector(
        `[data-group-position="group-position-${productPosition.indexY}-${productPosition.subPosition.indexX}"]`
      );
      if (anchorElement) {
        setAnchorEl(anchorElement as HTMLAnchorElement);
      }
    }
  }, [productPosition, isAllChildRendered]);

  return (
    <Box
      component="div"
      sx={{
        position: 'absolute',
      }}
      ref={drop}
      height="100%"
      width="100%"
    >
      <Box
        component="div"
        sx={{
          height: '100%',
          display: 'flex',
        }}
      >
        {row && (
          <>
            <Box
              component="div"
              sx={{
                display: 'flex',
                alignItems:
                  getProductOrigin(shelf) === 'top' ? 'flex-start' : 'flex-end',
                // eslint-disable-next-line no-magic-numbers -- 半透明にするため
                opacity: isEditingBay ? 0.2 : 1,
                width: '100%',
              }}
            >
              <ProductCompartments
                products={productsBulk}
                rowIndex={rowIndex}
                row={row}
                handleClickGroup={handleClickGroup}
                shelfHeight={shelfHeight}
                shelfWidth={shelf.width}
                //TODO: reduxから取得できるためpropsで渡す必要がない
                selectedPosition={productPosition}
                selectedProductCompartment={selectedProductCompartment}
                selectedRealogramItem={selectedRealogramItem}
                diffColor={diffColor}
                bboxColors={bboxColors}
                bboxEnabled={bboxEnabled}
                detailView={detailView}
                detailMode={detailMode}
                justifyContent={justifyContent}
                item={item}
                isEditor={isEditor}
                isCompared={isCompared}
                setIsAllChildRenndered={setIsAllChildRenndered}
              />
            </Box>

            {productPosition &&
              mode === 'CompartmentEditor' &&
              !isCompared &&
              !comparedProductPosition && (
                //編集モードの時のみメニューをopen
                <>
                  <CompartmentMenu
                    products={productsBulk}
                    displayableHeight={shelfHeight}
                    anchorEl={anchorEl}
                    handleClose={resetState}
                    //TODO: reduxから取得できるためpropsで渡す必要がない
                    productPosition={productPosition}
                    open={
                      !!anchorEl &&
                      !openOrientationMenu &&
                      productPosition.indexY === rowIndex
                    }
                    handleClickRotate={handleClickRotate}
                  />
                  <CompartmentOrientationMenu
                    anchorEl={anchorEl}
                    open={
                      !!anchorEl &&
                      openOrientationMenu &&
                      productPosition.indexY === rowIndex
                    }
                    //TODO: reduxから取得できるためpropsで渡す必要がない
                    productPosition={productPosition}
                    handleClose={() => dispatch(setOpenOrientationMenu(false))}
                  />
                </>
              )}
          </>
        )}
      </Box>
    </Box>
  );
};

const getHoverIndex = (
  row?: PlanogramProductCompartment[],
  dropResult?: DropResult | null
) => {
  let hoverIndex = 0;
  if (dropResult) {
    hoverIndex = dropResult.hoverIndex;
  } else if (row?.length) {
    hoverIndex = row.length;
  }
  return hoverIndex;
};
