import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RealogramDirectory } from 'types/realogram';

type SelectionStoreModalState = {
  selectedItemId?: string;
  currentSelectedItemId?: string;
  currentSelectedItem?: RealogramDirectory;
  currentRealogramDirectoryId?: string;
  realogramSearchText?: string;
};

export const initialState: SelectionStoreModalState = {
  selectedItemId: undefined,
};

const selectionStoreModalSlice = createSlice({
  name: 'selectStore',
  initialState,
  reducers: {
    reset: () => initialState,
    updateSelectedItemId: (
      state: SelectionStoreModalState,
      action: PayloadAction<string | undefined>
    ) => {
      state.selectedItemId = action.payload;
    },
    // save the old value of selectedItemId to keep the currently selected element when opening the popup
    updateCurrentSelectedItemId: (
      state: SelectionStoreModalState,
      action: PayloadAction<string | undefined>
    ) => {
      state.currentSelectedItemId = action.payload;
    },

    setCurrentSelectedItem: (
      state: SelectionStoreModalState,
      action: PayloadAction<RealogramDirectory | undefined>
    ) => {
      state.currentSelectedItem = action.payload;
    },

    setCurrentRealogramDirectoryId: (
      state: SelectionStoreModalState,
      action: PayloadAction<string | undefined>
    ) => {
      state.currentRealogramDirectoryId = action.payload;
    },

    updateRealogramSearch: (
      state: SelectionStoreModalState,
      action: PayloadAction<string | undefined>
    ) => {
      state.realogramSearchText = action.payload;
    },
  },
});

export const {
  reset,
  updateSelectedItemId,
  setCurrentRealogramDirectoryId,
  setCurrentSelectedItem,
  updateCurrentSelectedItemId,
  updateRealogramSearch,
} = selectionStoreModalSlice.actions;
export const selectionStoreModalReducer = selectionStoreModalSlice.reducer;
