import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { store } from 'store';
import { theme } from 'theme';
import { AppContainer } from './fragments/appContainer';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@api/query-client';

export const ShelfApp = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AppContainer />
      </ThemeProvider>
    </QueryClientProvider>
  </Provider>
);
