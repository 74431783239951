import {
  Planogram,
  PlanogramDirectory,
  PlanogramDirectorySearch,
} from 'types/planogram';
import { subject } from '@casl/ability';
import { useAbility } from '@casl/react';
import {
  AbilityContext,
  PlanogramAbilities,
  PlanogramDirectoryAbilities,
} from '@utils/abac';

export type PermissionConditions = {
  action: PlanogramAbilities[0] | PlanogramDirectoryAbilities[0];
  planogram:
    | Planogram
    | PlanogramDirectory
    | PlanogramDirectorySearch
    | undefined;
  isPlanogram: boolean;
  isCan: boolean;
};

export const useGetPlanogramPermission = (conditions: PermissionConditions) => {
  const { action, planogram, isCan, isPlanogram } = conditions;
  const ability = useAbility(AbilityContext);
  const subjectType =
    (planogram as PlanogramDirectory | PlanogramDirectorySearch)?.type ===
    'file'
      ? 'Planogram'
      : (planogram as PlanogramDirectory)?.type === 'directory'
      ? 'PlanogramDirectory'
      : '';

  if (isCan) {
    return {
      isEnable:
        isPlanogram || subjectType === 'Planogram'
          ? ability.can(
              action as PlanogramAbilities[0],
              subject('Planogram', {
                ...(planogram as Planogram),
              })
            )
          : subjectType === 'PlanogramDirectory'
          ? ability.can(
              action as PlanogramDirectoryAbilities[0],
              subject('PlanogramDirectory', {
                ...(planogram as PlanogramDirectory),
              })
            )
          : false,
    };
  } else {
    return {
      isNotEnable:
        isPlanogram || subjectType === 'Planogram'
          ? ability.cannot(
              action as PlanogramAbilities[0],
              subject('Planogram', {
                ...(planogram as Planogram),
              })
            )
          : subjectType === 'PlanogramDirectory'
          ? ability.cannot(
              action as PlanogramDirectoryAbilities[0],
              subject('PlanogramDirectory', {
                ...(planogram as PlanogramDirectory),
              })
            )
          : true,
    };
  }
};
