import { Box, Typography } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { FC } from 'react';
import { theme } from 'theme';

export const ProductsSearchEmptyResults: FC = () => {
  const { searchWord } = useAppSelector(selectPlanogramEditorState);
  return (
    <Box
      component="div"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
      sx={{ color: theme.palette.textBlack.disabled }}
      gap={3}
    >
      <Typography
        sx={{
          fontSize: 14,
          letterSpacing: '0.15px',
        }}
      >
        「{searchWord}」が含まれる商品は見つかりませんでした。
      </Typography>
    </Box>
  );
};
