import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { FC } from 'react';
import { User } from 'types/user';
import { number } from 'yup';

type Props = {
  me?: User;
  users?: User[];
  selectedOwnerId?: string;
  handleChangeSelectedOwnerId: (ownerId?: number[], meId?: number) => void;
  selectedStatus: string;
  clearConditions: () => void;
};

export const RealogramsConditions: FC<Props> = ({
  me,
  users,
  selectedOwnerId,
  handleChangeSelectedOwnerId,
  selectedStatus,
  clearConditions,
}) => {
  return (
    <Box component="div" display="flex" gap={2} mb={2}>
      <FormControl sx={{ flex: 1, maxWidth: 320 }}>
        <InputLabel size="small">作成者</InputLabel>
        <Select
          size="small"
          label="作成者"
          value={selectedOwnerId}
          onChange={({ target: { value } }) => {
            if (value === '') {
              handleChangeSelectedOwnerId(undefined);
              return;
            }
            const v = number().cast(value);
            handleChangeSelectedOwnerId(
              typeof v === 'number' ? [v] : undefined,
              me?.id
            );
          }}
        >
          <MenuItem divider value="">
            絞り込みなし
          </MenuItem>
          {me && (
            <MenuItem divider value={me.id}>
              {`自分（${me.fullname}）`}
            </MenuItem>
          )}
          {users?.map(({ id, fullname }, index) => (
            <MenuItem key={index} divider value={id}>
              {fullname}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        disabled={!selectedOwnerId && !selectedStatus}
        onClick={clearConditions}
      >
        すべて解除
      </Button>
    </Box>
  );
};
