import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const BowlTopIcon: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 80 80"
      sx={{ height: props.height, width: props.width }}
      fill="none"
    >
      <path
        d="M32 74H48L45.3271 77.408C44.0437 79.0443 42.0795 80 40 80C37.9205 80 35.9563 79.0443 34.673 77.408L32 74Z"
        fill="#E6E6E6"
      />
      <circle cx="40" cy="38" r="37" fill="white" />
      <path
        d="M6 38.3031C6 47.3205 9.58213 55.9685 15.9584 62.3448C22.3346 68.721 30.9826 72.3031 40 72.3031C49.0174 72.3031 57.6654 68.721 64.0416 62.3448C70.4179 55.9685 74 47.3205 74 38.3032L6 38.3031Z"
        fill="#E6E6E6"
      />
    </SvgIcon>
  );
};
