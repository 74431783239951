import { Box, CircularProgress, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

export const RealogramCandidateProcessing: FC = () => {
  return (
    <Box
      component="div"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
    >
      <Box component="div" color="#D9D9D9">
        <CircularProgress color="inherit" size={40} thickness={4} />
      </Box>
      <Typography
        color={theme.palette.textBlack.disabled}
        fontSize={16}
        lineHeight="24px"
        mt={3}
      >
        画像をスキャン中です。
      </Typography>
      <Typography
        color={theme.palette.textBlack.disabled}
        fontSize={12}
        lineHeight="18px"
        mt={1}
        textAlign="center"
      >
        この処理には時間がかかることがあります。 <br />
        画面を閉じても処理は継続されます。
      </Typography>
    </Box>
  );
};
