import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type AncestorDirectoryProps = {
  directory: string;
  maxWidth: number;
  fontSize: string | number;
  handleClick: () => void;
};

export const AncestorDirectory: FC<AncestorDirectoryProps> = ({
  directory,
  maxWidth,
  fontSize,
  handleClick,
}) => {
  return (
    <Box
      component="div"
      maxWidth={maxWidth}
      padding={0}
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={() => handleClick()}
    >
      <Typography
        noWrap
        fontSize={fontSize}
        color={theme.palette.textBlack.secondary}
      >
        {directory}
      </Typography>
    </Box>
  );
};
