import { ActionVisible } from '@components/molecules/actionVisible/actionVisible';
import { BreadcrumbList } from '@components/molecules/breadcrumbList/breadcrumbList';
import { GoParentDirectoryButton } from '@components/molecules/goParentDirectoryButtonIcon/goParentDirectoryButton';

import {
  realogramDirectorySearchQueryKey,
  useRealogramDirectoriesSearch,
} from '@api/index';
import { queryClient } from '@api/query-client';
import { realogramsApi } from '@api/services/realogramDirectories';
import { useGetRealogramPermission } from '@hooks/useGetRealogramPermission';
import { usePageTitle } from '@hooks/usePageTitle';
import { useSharePermissionModal } from '@hooks/useSharePermissionModal';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { Box, Typography } from '@mui/material';
import { selectUser } from '@reducers/auth/selectors';
import { setDirectoryId, setDirectoryType } from '@reducers/sharePermission';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getListPageTitle,
  pageTitle,
  paths,
  rowsPerPage,
  SidebarValue,
  toastMessages,
} from '@utils/const';
import { FC, useEffect, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  DirectoryBreadcrumbs,
  DirectoryType,
  ParentDirectory,
} from 'types/common';
import { RealogramDirectory, RealogramOrder } from 'types/realogram';
import { RealogramSharePermissionProps } from 'types/sharePermission';
import { SearchText } from '../planograms/fragments/searchText';
import { Layout } from './fragments/layout';
import { RealogramDirectoriesTable } from './fragments/realogramDirectoriesTable';
import { storeBaysQueryKey, StoreBaysTable } from './fragments/storeBaysTable';
import { useCreateStoreBay } from './hooks/useCreateStoreBay';

const getDirectoryPageTitle = (
  sidebarValue: SidebarValue,
  isRoot: boolean,
  directoryParentName: string
) => {
  if (sidebarValue === 'favorite') {
    return 'スター付き';
  } else if (isRoot) {
    return '什器一覧';
  } else {
    return directoryParentName;
  }
};

type Props = {
  sidebarValue?: SidebarValue;
};

export const realogramDirectoriesQueryKey = 'realogram_directories';

export const StoreBays: FC<Props> = ({ sidebarValue = 'all' }) => {
  usePageTitle('什器管理');
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { updateQueryParameter, removeQueryParameters, existingParams } =
    useUrlQueryParams();

  const { showLicenseModal } = useSharePermissionModal();
  const params = useParams<{ id: string }>();
  const directoryId = params.id ?? '';
  const [search, setSearch] = useState(existingParams.name ?? '');
  const firstOrder = existingParams.first_order as RealogramOrder;
  const isFilteredByFavorite =
    sidebarValue === 'favorite' || location.pathname.includes('starred');
  const user = useAppSelector(selectUser);

  const { handleCreateStoreBay } = useCreateStoreBay();

  const { mutateAsync: addRealogramToFavorite } = useMutation({
    mutationFn: realogramsApi.addRealogramToFavorite,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [storeBaysQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [realogramDirectorySearchQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [realogramDirectoriesQueryKey],
      });
    },
  });
  const { mutateAsync: deleteRealogramFromFavorite } = useMutation({
    mutationFn: realogramsApi.deleteRealogramFromFavorite,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [storeBaysQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [realogramDirectorySearchQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [realogramDirectoriesQueryKey],
      });
    },
  });

  const {
    data: directory,
    isLoading: isDirectoryLoading,
    isFetching: isDirectoryFetching,
  } = useQuery({
    enabled: !search && !isFilteredByFavorite,
    // enabled: false,
    queryKey: [realogramDirectoriesQueryKey, directoryId, firstOrder],
    queryFn: () =>
      realogramsApi.getRealogramDirectories({
        id: directoryId ? directoryId : undefined,
        first_order: firstOrder,
        directory_type: 'directory',
      }),
  });

  const {
    data: searchedData,
    fetchNextPage,
    isFetching: isSearchFetching,
    isFetchingNextPage,
    isLoading: isSearchLoading,
    hasNextPage,
  } = useRealogramDirectoriesSearch({
    enabled: !!search || Boolean(isFilteredByFavorite && user?.id),
    name: search,
    first_order: firstOrder as RealogramOrder,
    favorite_owner_id:
      isFilteredByFavorite && user?.id ? [user?.id] : undefined,
    directory_type: 'directory',
    limit: rowsPerPage,
  });

  const { isEnable: isCanCreate } = useGetRealogramPermission({
    action: 'create_file',
    realogram: directory?.parent as RealogramDirectory,
    isCandidate: false,
    isCan: true,
  });
  const hasStoreId = !!directory?.parent.store_id;
  const { user: userInfo } = useAppSelector((state) => state.Auth);
  /** 什器は管理者もしくは店舗のeditor以上の権限を保有していれば編集などの操作が可能 */
  const canEditStoreBay =
    directory?.parent.current_user_role?.role === 'owner' ||
    directory?.parent.current_user_role?.role === 'editor' ||
    userInfo?.role === 'admin';
  const canReadStoreBay =
    directory?.parent.current_user_role?.role === 'owner' ||
    directory?.parent.current_user_role?.role === 'editor' ||
    directory?.parent.current_user_role?.role === 'viewer' ||
    userInfo?.role === 'admin';

  const { isEnable: isCanUpdate } = useGetRealogramPermission({
    action: 'update',
    realogram: directory?.parent as RealogramDirectory,
    isCandidate: false,
    isCan: true,
  });
  const { isEnable: isCanRead } = useGetRealogramPermission({
    action: 'read',
    realogram: directory?.parent as RealogramDirectory,
    isCandidate: false,
    isCan: true,
  });

  const handleSearch = (value: string) => {
    queryClient.removeQueries({
      queryKey: [realogramDirectoriesQueryKey],
    });
    setSearch(value);
    updateQueryParameter('name', value);
    if (isFilteredByFavorite) {
      navigate({
        pathname: paths.storeBays.folders,
        search: createSearchParams(existingParams).toString(),
      });
    }
  };
  const clearSearchText = () => {
    setSearch('');
    removeQueryParameters(['name']);
  };

  const handleRowClick = (item: RealogramDirectory) => {
    if (search) {
      setSearch('');
    }
    const directoryId = item.id;
    navigate(paths.storeBays.foldersId(directoryId));
  };

  const handleClickMoveParentDirectory = () => {
    const parentId = directory?.parent.parent_id;
    const isBackToRootDirectory =
      directory?.breadcrumb?.length === 1 || !parentId;
    const directoryId = isBackToRootDirectory ? '' : parentId;
    navigate(paths.storeBays.foldersId(directoryId));
  };

  const handleClickBreadcrumbs = (directory: DirectoryType) => {
    const isBackToRootDirectory = directory.type === 'root';
    const directoryId = isBackToRootDirectory ? '' : directory.id;
    navigate(paths.storeBays.foldersId(directoryId));
  };

  const handleFavoriteClick = async (id: string, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await deleteRealogramFromFavorite(id);
        dispatch(
          openToast({
            type: 'success',
            message: toastMessages.success.removeStar,
          })
        );
      } else {
        await addRealogramToFavorite(id);
        dispatch(
          openToast({
            type: 'success',
            message: toastMessages.success.addStar,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSharePermission = (item: RealogramSharePermissionProps) => {
    if (!item.directoryId) return;
    dispatch(setDirectoryId(`${item?.directoryId}`));
    dispatch(setDirectoryType('realogram'));
    showLicenseModal({
      type: item.type,
    });
  };

  const handleEndReached = (isAtBottom: boolean) => {
    if (!isAtBottom || !hasNextPage || isFetchingNextPage) return;
    fetchNextPage();
  };
  const tableData =
    search || isFilteredByFavorite
      ? searchedData
      : directory?.realogram_directories;
  const isNoHit = searchedData?.length === 0;

  const isShowBreadcrumbList = !!directory && !!directoryId;
  const isRoot = directory?.parent.type === 'root';

  const isLoading = isDirectoryLoading || isSearchLoading;

  const isApiLoading =
    isDirectoryLoading ||
    isDirectoryFetching ||
    isSearchLoading ||
    isSearchFetching;
  const isEmpty = !tableData?.length && !isLoading;

  const isDisplayLoadingSkeleton = isApiLoading && !isFetchingNextPage;

  useEffect(() => {
    return () => {
      queryClient.clear();
    };
  }, []);

  useEffect(() => {
    setSearch(existingParams.name ?? '');
  }, [existingParams.name]);
  const emptyTableMessage = isNoHit
    ? '条件を変更してください。'
    : '左上の「什器追加」ボタンから追加してください。';
  return (
    <Layout
      sidebarValue={sidebarValue}
      handleCreateStoreBay={() =>
        handleCreateStoreBay(directory?.parent.store_id)
      }
      handleClearSearch={clearSearchText}
      isCreateStoreBayDisabled={!hasStoreId || !isCanCreate}
    >
      <Box
        component="div"
        sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        <SearchText
          key={search}
          searchText={search}
          handleSearch={handleSearch}
          clearSearch={clearSearchText}
          placeholder="什器を検索"
        />
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={-1}
          mt={2}
        >
          {isShowBreadcrumbList ? (
            <Box component="div">
              {!isRoot && (
                <Box component="div" marginLeft={5}>
                  {/* TODO: remove type casting */}
                  <BreadcrumbList
                    breadcrumbs={
                      directory.breadcrumb as unknown as DirectoryBreadcrumbs
                    }
                    parentDirectory={
                      directory.parent as unknown as ParentDirectory
                    }
                    screenType="Main"
                    fontSize="12px"
                    handleClickBreadcrumbs={handleClickBreadcrumbs}
                    directoryRootName="什器一覧"
                  />
                </Box>
              )}
              <Box component="div" display="flex" alignItems="center">
                {!isRoot && (
                  <GoParentDirectoryButton
                    width={32}
                    height={32}
                    handleClick={handleClickMoveParentDirectory}
                  />
                )}
                <Typography
                  fontSize="20px"
                  fontWeight="bold"
                  display="flex"
                  alignItems="center"
                  mr={!isRoot ? '6px' : ''}
                >
                  {getDirectoryPageTitle('all', isRoot, directory.parent.name)}
                </Typography>
                {!isRoot && (
                  <ActionVisible
                    isCanUpdate={isCanUpdate}
                    isCanRead={isCanRead}
                  />
                )}
              </Box>
            </Box>
          ) : (
            <Typography fontWeight="bold" fontSize="20px">
              {getListPageTitle({
                defaultTitle: pageTitle.storeBays,
                sidebarValue: sidebarValue,
                isSearched: !!search,
              })}
            </Typography>
          )}
        </Box>
        <Box component="div" mt={2} flex={1}>
          {hasStoreId ? (
            <StoreBaysTable
              storeId={directory.parent.store_id ?? 0}
              handleFavoriteClick={handleFavoriteClick}
              handleSharePermission={handleSharePermission}
              canEditStoreBay={canEditStoreBay}
              canReadStoreBay={canReadStoreBay}
              emptyTableTitle="什器がありません"
              emptyTableMessage={emptyTableMessage}
            />
          ) : (
            <RealogramDirectoriesTable
              isRefetching={isFetchingNextPage}
              data={tableData as RealogramDirectory[]}
              isDisplayLoadingSkeleton={isDisplayLoadingSkeleton}
              isEmpty={isEmpty}
              firstOrder={firstOrder}
              emptyTableTitle="什器がありません"
              emptyTableMessage={emptyTableMessage}
              handleRowClick={handleRowClick}
              handleFavoriteClick={handleFavoriteClick}
              handleSharePermission={handleSharePermission}
              handleEndReached={handleEndReached}
            />
          )}
        </Box>
      </Box>
    </Layout>
  );
};
