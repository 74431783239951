import { Box } from '@mui/material';
import { convertMeterToPixel } from '@utils/planogram';
import { FC } from 'react';
import { theme } from 'theme';
import { Bucket } from 'types/planogram';

type Props = {
  bucket: Bucket;
  isSelected?: boolean;
  isSelectedInEditor?: boolean;
  isExceedSize?: boolean;
  isCompared?: boolean;
};

const getBorderColor = (
  isSelectedInEditor: boolean,
  isExceedSize: boolean,
  isCompared: boolean
) => {
  if (isSelectedInEditor && !isExceedSize && !isCompared)
    return theme.palette.primary.main;
  if (isExceedSize) return theme.palette.system.errorMain;
  return theme.palette.dividerBlack.dark;
};

export const BucketBayPart: FC<Props> = ({
  bucket,
  isSelectedInEditor = false,
  isExceedSize = false,
  isCompared = false,
}) => {
  const { padding } = bucket.detail;
  const borderType = 'solid';
  const borderColor = getBorderColor(
    isSelectedInEditor,
    isExceedSize,
    isCompared
  );

  return (
    <Box
      component="div"
      position="relative"
      height="100%"
      width="100%"
      sx={{
        borderLeft: `${convertMeterToPixel(
          padding.left
        )}px ${borderType} ${borderColor}`,
        borderRight: `${convertMeterToPixel(
          padding.right
        )}px ${borderType} ${borderColor}`,
        borderTop: `${convertMeterToPixel(
          padding.behind
        )}px ${borderType} ${borderColor}`,
        borderBottom: `${convertMeterToPixel(
          padding.front
        )}px ${borderType} ${borderColor}`,
        borderRadius: '2px',
      }}
    />
  );
};
