import { FC } from 'react';
import {
  Alert,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { theme } from 'theme';

export const UploadRealogramAlert: FC = () => {
  return (
    <Box
      component="div"
      sx={{
        py: 1,
        alignItems: 'center',
        backgroundColor: theme.palette.white.primary,
        border: `1px solid ${theme.palette.dividerBlack.dark}`,
        borderRadius: 1,
        gap: '8px',
        width: { xs: '100%', sm: '300px', md: '100%' },
        maxHeight: { xs: '224px', ms: '224px', md: '210px' },
      }}
    >
      <Alert
        severity="warning"
        sx={{
          fontWeight: 400,
          lineHeight: '24px',
          alignItems: 'center',
          fontSize: '16px',
          px: 2,
          py: 0.5,
          backgroundColor: theme.palette.white.primary,
        }}
      >
        撮影時の注意点
      </Alert>
      <List sx={{ color: theme.palette.system.warningText }}>
        <ListItem sx={{ alignItems: 'baseline', py: 0 }}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              color: theme.palette.system.warningText,
              justifyContent: 'top',
            }}
          >
            ・
          </ListItemIcon>
          <ListItemText>画像は縦向きになっていますか？</ListItemText>
        </ListItem>
        <ListItem sx={{ alignItems: 'baseline', py: 0 }}>
          <ListItemIcon
            sx={{ minWidth: 0, color: theme.palette.system.warningText }}
          >
            ・
          </ListItemIcon>
          <ListItemText>ゴンドラの端が見切れていませんか？</ListItemText>
        </ListItem>
        <ListItem sx={{ alignItems: 'baseline', py: 0 }}>
          <ListItemIcon
            sx={{ minWidth: 0, color: theme.palette.system.warningText }}
          >
            ・
          </ListItemIcon>
          <ListItemText>
            ガラス扉付きのゴンドラの場合はガラス扉が開いていますか？
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  );
};
