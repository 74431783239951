import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { ProductPosition } from 'types/planogram';
import { Product } from '../../../types/common';
import { ProductImage } from '../productImage/productImage';
import { AccordionBodyRotation } from './fragments/accordionBodyRotation';
import { AccordionBodySizing } from './fragments/accordionBodySizing';
import { CustomAccordion } from './fragments/customAccordion';
import { MarginEditor } from './fragments/marginEditor';

type Props = {
  selectedProduct: Product;
  productPosition: ProductPosition;
};

export const PlanogramProductEditor: FC<Props> = ({
  selectedProduct,
  productPosition,
}) => {
  return (
    <>
      <Box component="div" display="flex" alignItems="center" my={1} mx={1}>
        <Box
          component="div"
          width={48}
          height={48}
          borderRadius={0.2}
          borderColor={theme.palette.dividerBlack.dark}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            borderStyle: 'solid',
            borderWidth: 0.4,
          }}
        >
          <ProductImage
            product={selectedProduct}
            width={42}
            height={42}
            layout="fixed"
            objectFit="contain"
            baseSize={48}
          />
        </Box>
        <Box component="div" alignItems="center" mx={2}>
          <Typography
            variant="subtitle2"
            color={theme.palette.textBlack.label}
            fontSize="11px"
          >
            選択中の商品
          </Typography>
          <Typography variant="body1" fontSize="14px">
            {selectedProduct?.name}
          </Typography>
        </Box>
      </Box>
      <Box component="div" margin="0 -8px">
        <CustomAccordion label="回転" id="panel-header-1">
          {/* TODO: orientation, adjust_rotationフィールドの値を渡す */}
          <AccordionBodyRotation productPosition={productPosition} />
        </CustomAccordion>

        <CustomAccordion label="サイズ調整" id="panel-header-2">
          {selectedProduct.shape && (
            // TODO: display_sizeフィールドの値を渡す。
            <AccordionBodySizing
              display_size={selectedProduct.shape?.size.actual}
            />
          )}
        </CustomAccordion>

        <CustomAccordion label="余白" id="panel-header-3">
          {/* TODO: marginフィールドの値を渡す */}
          <MarginEditor />
        </CustomAccordion>
      </Box>
    </>
  );
};
