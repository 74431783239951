import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { Box } from '@mui/material';
import {
  removeArea,
  removeBayPart,
  splitArea,
  updateBucketProductPosition,
} from '@reducers/flatPlan';
import {
  selectBucketProductPosition,
  selectCanSplitHorizontal,
  selectCanSplitVertical,
} from '@reducers/flatPlan/selectors';
import {
  selectBayPartId,
  setIsTooltipOpen,
  setOpenOrientationMenu,
  setSelectedBucketId,
  setSelectedBucketIdClone,
  toggleIsSwappingBayPartMode,
  updateInitialBucket,
  updateIsShowProductDetail,
  updateProductPosition,
  updateSelectedProductCompartment,
  updateSelectedProductCompartmentClone,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  maxChildrenOfArea,
  maxZoomScale,
  rotationAngleFlatPlanogram,
} from '@utils/const';
import {
  convertMeterToPixel,
  getAreaDimensions,
  isClickableCompartment,
} from '@utils/planogram';
import { isEqual } from 'lodash';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { theme } from 'theme';
import { Product, ShelfDetailMode, ShelfDetailView } from 'types/common';
import {
  BboxColors,
  Bucket,
  BucketArea as BucketAreaType,
  JustifyContent,
  Position,
  SplitAreaType,
} from 'types/planogram';
import { BucketArea } from '../bucketArea/bucketArea';
import { BucketBayPartMenu } from './fragments/bucketBayPartMenu';
import { SplitAreaPopper } from './fragments/splitAreaPopper';

type Props = {
  bucket: Bucket;
  // 不要であれば削除
  contentX: number;
  contentY: number;
  diffColor?: string;
  bboxColors?: BboxColors;
  bboxEnabled: boolean;
  detailView?: ShelfDetailView;
  detailMode?: ShelfDetailMode;
  justifyContent?: JustifyContent;
  index: number;
  zoomScale: number;
  setZoomScale: (zoomScale: number) => void;
  initUpperRowIndexThreshold: boolean;
  isDisabledBuckets: boolean;
  setTranslatePosition: (value: number) => void;
  translatePosition: number;
  isEditor?: boolean;
  isCompared?: boolean;
};

const calculateTranslatePosition = (
  translatePosition: number,
  planogramCenterPosition: number,
  leftPosition: number,
  zoomScale: number
) => {
  return Math.round(
    translatePosition + (planogramCenterPosition - leftPosition) / zoomScale
  );
};

export const DisplayBuckets: FC<Props> = ({
  bucket,
  index,
  zoomScale,
  setZoomScale,
  initUpperRowIndexThreshold,
  isDisabledBuckets,
  setTranslatePosition,
  translatePosition,
  isEditor,
  bboxEnabled,
  isCompared = false,
}) => {
  const dispatch = useAppDispatch();
  // Areaと同じanchorを使ってるため分離するか検討
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const contentX = bucket.detail.content_max.x - bucket.detail.content_min.x;
  const contentZ = bucket.detail.content_max.z - bucket.detail.content_min.z;

  const [hightLightAreaPosition, setHightLightAreaPosition] =
    useState<Position[]>();
  const [isHightLightBucket, setHightLightBucket] = useState<boolean>(false);
  const [comparedBucketProductPos, setComparedBucketProductPos] = useState<
    Position[] | undefined
  >();
  const {
    mode,
    detailView,
    selectedBucketId,
    selectedProductCompartment,
    bucketMode,
    isTooltipOpen,
    selectedBucketIdClone,
    detailMode,
    selectedBayPartId,
    isDragProduct,
    isSwappingBayPartMode,
    productTag,
  } = useAppSelector(selectPlanogramEditorState);

  const { padding } = bucket.detail;
  const bucketsRotateDeg = initUpperRowIndexThreshold
    ? rotationAngleFlatPlanogram
    : 0;
  const childrenHasData = bucket.detail.area.children?.length ?? 0;
  const [isDeleteButtonDisabled, setDeleteButtonDisabled] =
    useState<boolean>(false);
  const isAreaMode = bucketMode === 'area';
  const isSelectedBucketId = selectedBucketId === index;
  const bucketProductPosition = useAppSelector(selectBucketProductPosition);
  const canSplitHorizontal = useAppSelector(selectCanSplitHorizontal);
  const canSplitVertical = useAppSelector(selectCanSplitVertical);
  const { removeQueryParameter, updateQueryParameter } = useUrlQueryParams();
  const isEditingBay = isEditor && mode === 'BayEditor';
  const resetState = () => {
    setAnchorEl(null);
    dispatch(setOpenOrientationMenu(false));
    dispatch(updateBucketProductPosition(undefined));
    dispatch(updateSelectedProductCompartment(undefined));
    dispatch(updateIsShowProductDetail(false));
    dispatch(updateSelectedRealogramItem(undefined));
    dispatch(updateProductPosition(undefined));
    dispatch(setSelectedBucketId(undefined));
    dispatch(setSelectedBucketIdClone(undefined));
    removeQueryParameter('item');
  };

  const handleClickCompartment = (
    e: MouseEvent<HTMLElement>,
    position: Position[],
    product: Product,
    bucketIndex: number
  ) => {
    if (isTooltipOpen) {
      dispatch(setIsTooltipOpen(false));
    }
    e.stopPropagation();
    if (!isClickableCompartment(detailView, product, productTag)) return;
    // disabledのカゴの中にある商品は選択できない
    if (isDisabledBuckets) {
      dispatch(setIsTooltipOpen(true));
      return;
    }
    const productPosition = isCompared
      ? comparedBucketProductPos
      : bucketProductPosition;
    if (
      isEqual(productPosition, position) &&
      bucketIndex === selectedBucketId
    ) {
      resetState();
      dispatch(setSelectedBucketIdClone(undefined));
      return;
    }
    if (bucketMode !== 'area') {
      updateQueryParameter('item', String(product.id));
      dispatch(updateIsShowProductDetail(true));
    }
    dispatch(updateSelectedProductCompartment(product));
    dispatch(setOpenOrientationMenu(false));
    dispatch(setSelectedBucketId(index));
    dispatch(setSelectedBucketIdClone(index));
    if (isCompared) {
      setComparedBucketProductPos(position);
      dispatch(updateSelectedRealogramItem(undefined));
      return;
    }
    setAnchorEl(e.currentTarget);
    dispatch(updateBucketProductPosition(position));
  };

  useEffect(() => {
    if (bucketProductPosition && selectedProductCompartment) {
      const anchorElement = document.querySelector(
        `[data-group-position=group-position-${index}--1]`
      );
      if (anchorElement) {
        setAnchorEl(anchorElement as HTMLAnchorElement);
      }
    }
  }, [bucketProductPosition, index, selectedProductCompartment]);

  useEffect(() => {
    if (selectedBucketId === undefined) {
      setAnchorEl(null);
      return;
    }

    if (!childrenHasData) {
      const anchorElement = document.querySelector(
        `[data-area-container=data-area-container-${selectedBucketId}]`
      );
      const planogramContainerWidth = document.querySelector(
        '[data-planogram-container=data-planogram-container]'
      )?.clientWidth;
      const planogramCenterPosition =
        planogramContainerWidth && planogramContainerWidth / 2; // eslint-disable-line no-magic-numbers -- 中心位置を計算するため
      const anchorElementRect = anchorElement?.getBoundingClientRect();
      setAnchorEl(anchorElement as HTMLAnchorElement);
      if (
        planogramCenterPosition &&
        anchorElementRect?.left &&
        mode !== 'BayEditor'
      ) {
        const position = calculateTranslatePosition(
          translatePosition,
          planogramCenterPosition,
          anchorElementRect.left,
          zoomScale
        );
        setTranslatePosition(position);
      }
    }
  }, [
    selectedBucketId,
    bucket,
    setTranslatePosition,
    zoomScale,
    translatePosition,
    childrenHasData,
    mode,
  ]);

  const handleBucketClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (
      isSelectedBucketId &&
      bucketProductPosition &&
      (!canSplitHorizontal || !canSplitVertical)
    ) {
      return;
    }
    if (mode === 'BayEditor') {
      e.stopPropagation();
      if (isSwappingBayPartMode) return;
      const isSelectedBucketId = selectedBucketId === index;

      if (selectedBayPartId && !isSwappingBayPartMode) {
        dispatch(selectBayPartId(undefined));
      }

      dispatch(setSelectedBucketId(isSelectedBucketId ? undefined : index));
    }

    if (mode !== 'CompartmentEditor') return;
    if (isTooltipOpen) {
      dispatch(setIsTooltipOpen(false));
    }
    e.stopPropagation();
    if (isDisabledBuckets) {
      dispatch(setIsTooltipOpen(true));
      return;
    }
    if (bucketMode === 'area' && selectedBucketIdClone) {
      dispatch(setSelectedBucketIdClone(undefined));
    }
    // Click on the cart to zoom in on the planogram edit screen
    if (
      detailMode === 'default' &&
      !!selectedBucketId?.toString() &&
      (bucketMode === 'compartment' || bucketMode === 'area') &&
      zoomScale < maxZoomScale
    ) {
      setZoomScale(maxZoomScale);
    }
    if (zoomScale < maxZoomScale && !selectedBucketId?.toString()) {
      setZoomScale(maxZoomScale);
    }
    if (index === selectedBucketId && selectedBucketIdClone !== undefined)
      return;
    if (!childrenHasData) {
      dispatch(updateBucketProductPosition(undefined));
    }
    dispatch(setSelectedBucketId(index));
    dispatch(setSelectedBucketIdClone(index));
    dispatch(updateBucketProductPosition(undefined));
    dispatch(updateIsShowProductDetail(false));
    dispatch(updateSelectedProductCompartment(undefined));
    dispatch(updateSelectedProductCompartmentClone(undefined));
  };

  useEffect(() => {
    if (bucketProductPosition !== undefined) {
      const areaAttributeKey = bucketProductPosition
        .map((el) => {
          if (el.indexX === 0 && el.indexY === 0) return 0;
          return 1;
        })
        .join('-');
      const anchorElement = document.querySelector(
        `[data-area-popper=area-popper-${selectedBucketId ?? ''}-${
          areaAttributeKey ?? ''
        }]`
      );
      if (anchorElement) {
        setAnchorEl(anchorElement as HTMLAnchorElement);
      }
      return;
    }
    if (selectedBucketId !== undefined) {
      const anchorElement = document.querySelector(
        `[data-area-container=data-area-container-${selectedBucketId}]`
      );
      setAnchorEl(anchorElement as HTMLAnchorElement);
    }
  }, [selectedBucketId, bucket, bucketProductPosition]);

  const handleSplitArea = (type: SplitAreaType) => {
    // check if bucket splitted or not
    const bucketPosition =
      bucket.detail.area.split_axis !== null
        ? bucketProductPosition
        : undefined;
    dispatch(
      splitArea({
        type,
        selectedBucketId,
        bucketProductPosition: bucketPosition,
      })
    );
    if (bucketPosition) {
      dispatch(
        updateBucketProductPosition([
          ...bucketPosition,
          { indexX: 0, indexY: 0 },
        ])
      );
    } else {
      dispatch(updateBucketProductPosition([{ indexX: 0, indexY: 0 }]));
    }
  };
  const onHandleClickArea = (
    e: MouseEvent<HTMLElement>,
    selectedBucketId?: number,
    path?: Position[]
  ) => {
    if (!isEditor && detailMode === 'comparison') return;
    if (mode !== 'CompartmentEditor' || bucketMode === 'compartment') return;
    if (isTooltipOpen) {
      dispatch(setIsTooltipOpen(false));
    }
    e.stopPropagation();

    if (isDisabledBuckets) {
      dispatch(setIsTooltipOpen(true));
      return;
    }
    if (selectedBucketIdClone && bucketProductPosition) {
      dispatch(setSelectedBucketIdClone(undefined));
      updateBucketProductPosition(undefined);
      setAnchorEl(null);
      return;
    }
    if (
      detailMode === 'default' &&
      !!selectedBucketId?.toString() &&
      zoomScale < maxZoomScale
    ) {
      setZoomScale(maxZoomScale);
    }
    dispatch(setSelectedBucketId(selectedBucketId));
    dispatch(setSelectedBucketIdClone(selectedBucketId));
    updateBucketProductPosition(path);
    dispatch(updateSelectedProductCompartmentClone(undefined));
  };

  const checkParentHasMultipleChildren = (
    bucketProductPosition: Position[],
    area: BucketAreaType
  ) => {
    const indexArray = bucketProductPosition.map((el) => {
      if (el.indexX === 0 && el.indexY === 0) return 0;
      return 1;
    });
    let parent = area;

    for (const index of indexArray) {
      const children = parent?.children ?? [];
      if (Array.isArray(children) && children.length >= maxChildrenOfArea) {
        return true;
      }
      parent = children[index];
    }

    return false;
  };

  useEffect(() => {
    const { area } = bucket.detail;
    if (!bucketProductPosition) {
      const childrenHasData = area.children?.length ?? 0;
      setDeleteButtonDisabled(childrenHasData < maxChildrenOfArea);
      return;
    }

    const isDeleteEnabled = checkParentHasMultipleChildren(
      bucketProductPosition,
      area
    );
    setDeleteButtonDisabled(!isDeleteEnabled);
  }, [bucketProductPosition, bucket]);
  const { height: bucketHeight, width: bucketWidth } = getAreaDimensions(
    contentZ,
    contentX,
    bucket.detail.area
  );

  const handleDeleteArea = () => {
    dispatch(removeArea({ selectedBucketId, bucketProductPosition }));
  };

  const hasColor =
    index === selectedBucketId &&
    mode === 'CompartmentEditor' &&
    isAreaMode &&
    (!!bucketProductPosition?.length || selectedBucketIdClone !== undefined);

  const isActiveBucketArea =
    !hasColor &&
    index === selectedBucketIdClone &&
    !bucketProductPosition &&
    isAreaMode &&
    mode === 'CompartmentEditor';

  const getBorder = (value: number) => {
    if (
      (hasColor && !(childrenHasData > 1) && !isDragProduct) ||
      (isHightLightBucket && !isDisabledBuckets && !childrenHasData)
    ) {
      return '2px solid #0A40CA';
    }
    if (isActiveBucketArea) {
      return `${convertMeterToPixel(value)}px solid ${
        theme.palette.dividerBlack.dark
      }`;
    }
    return '';
  };

  const shouldHighlight =
    (hasColor && !(childrenHasData > 1) && !isDragProduct) ||
    (isHightLightBucket && !isDisabledBuckets);
  const bucketBackgroundColor = shouldHighlight
    ? '#E5F0FF'
    : isActiveBucketArea
    ? '#FFFFFF'
    : '';

  const handleDeleteBucket = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(removeBayPart(index));
  };

  const handleSwapMode = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(toggleIsSwappingBayPartMode());
    dispatch(selectBayPartId(bucket.bay_part_id));
    dispatch(updateInitialBucket(bucket));
  };

  useEffect(() => {
    if (!isDragProduct) {
      setHightLightBucket(false);
    }
  }, [isDragProduct, selectedBucketId, dispatch]);
  return (
    <Box
      component="div"
      sx={{
        position: 'absolute',
      }}
      height="100%"
      width="100%"
      onClick={handleBucketClick}
    >
      <Box
        component="div"
        sx={{
          height: '100%',
          display: 'flex',
          transform: `rotate(${bucketsRotateDeg}deg)`,
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            opacity:
              // eslint-disable-next-line no-magic-numbers -- 半透明にするため
              isEditingBay || (isCompared && mode === 'BayEditor') ? 0.2 : 1,
            width: '100%',
            height: '100%',
            paddingLeft: !isActiveBucketArea
              ? `${convertMeterToPixel(padding.left)}px`
              : '',
            paddingRight: !isActiveBucketArea
              ? `${convertMeterToPixel(padding.right)}px`
              : '',
            paddingTop: !isActiveBucketArea
              ? `${convertMeterToPixel(padding.behind)}px`
              : '',
            paddingBottom: !isActiveBucketArea
              ? `${convertMeterToPixel(padding.front)}px`
              : '',
            backgroundColor: bucketBackgroundColor,
            borderLeft: getBorder(padding.left),
            borderRight: getBorder(padding.right),
            borderTop: getBorder(padding.behind),
            borderBottom: getBorder(padding.front),
            flexDirection:
              `${bucket.detail.area.split_axis || ''}` === 'compartment_x'
                ? 'row'
                : 'column',
          }}
          data-area-container={
            isCompared ? undefined : `data-area-container-${index}`
          }
          onDragLeave={() => {
            setHightLightBucket(false);
          }}
          onDragOver={() => {
            if (isDragProduct && !childrenHasData) {
              setHightLightBucket(true);
            }
          }}
        >
          <BucketArea
            bucketArea={bucket.detail.area}
            handleClickCompartment={handleClickCompartment}
            anchorEl={anchorEl}
            resetState={resetState}
            onHandleClickArea={onHandleClickArea}
            selectedBucketId={selectedBucketId}
            bucketIndex={index}
            isDisabledBucket={isDisabledBuckets}
            parentAreaWidth={bucketWidth}
            parentAreaHeight={bucketHeight}
            bboxEnabled={bboxEnabled}
            hightLightAreaPosition={hightLightAreaPosition}
            setHightLightAreaPosition={setHightLightAreaPosition}
            isCompared={isCompared}
            isEditor={isEditor}
          />
        </Box>
        {bucketMode === 'area' && mode === 'CompartmentEditor' && isEditor && (
          <SplitAreaPopper
            anchorEl={anchorEl}
            open={
              !!anchorEl &&
              isSelectedBucketId &&
              !isDragProduct &&
              (selectedBucketIdClone !== undefined ||
                !!bucketProductPosition?.length)
            }
            handleClose={handleDeleteArea}
            handleSplitArea={handleSplitArea}
            isDisabledDeleteButton={isDeleteButtonDisabled}
            isDisabledSplitHorizontalButton={!canSplitHorizontal}
            isDisabledSplitVerticalButton={!canSplitVertical}
          />
        )}
        {selectedBucketId === index &&
          !isSwappingBayPartMode &&
          mode === 'BayEditor' &&
          !isCompared && (
            <BucketBayPartMenu
              anchorEl={anchorEl}
              handleSwapMode={handleSwapMode}
              handleDeleteBucket={handleDeleteBucket}
              open={!!anchorEl && isSelectedBucketId}
            />
          )}
      </Box>
    </Box>
  );
};
