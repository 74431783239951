import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  isDeleted: boolean;
};

export const StatusLabel: FC<Props> = ({ isDeleted }) => {
  const color = isDeleted
    ? theme.palette.textBlack.secondary
    : theme.palette.system.successMain;
  const width = isDeleted ? 58 : 72;
  const text = isDeleted ? '停止中' : '利用可能';
  return (
    <Box
      component="div"
      sx={{
        color,
        border: `1px solid ${color}`,
        borderRadius: '999px',
        height: '22px',
        width: `${width}px`,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body2">{text}</Typography>
    </Box>
  );
};
