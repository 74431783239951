import { DefaultVirtuosoTableRow } from './tableRow';
import { TableRowProps } from './table';

type Props<T> = TableRowProps<T> & {
  handleRowClick: VoidFunction;
};

export const TableRowWrapper = <T,>({ handleRowClick, ...props }: Props<T>) => {
  return <DefaultVirtuosoTableRow handleRowClick={handleRowClick} {...props} />;
};
