import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type CustomAccordionProps = {
  label: string;
  id: string;
  children: React.ReactNode;
};

export const CustomAccordion: FC<CustomAccordionProps> = ({
  label,
  id,
  children,
}) => {
  const defaultStyles = {
    boxShadow: 0,
    border: `1px solid ${theme.palette.dividerBlack.medium}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  };

  return (
    <Accordion disableGutters elevation={0} square sx={{ ...defaultStyles }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id={id}>
        <Typography
          sx={{ fontWeight: 'bold', color: theme.palette.textBlack.secondary }}
        >
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
