import { ColumnProps } from '@components/organisms/dataTable/dataTable';
import { RealogramOrderBy } from 'types/realogram';

const headerSx = {
  padding: '0 0 0 16px',
};

export const getColumns = (
  isFilteredByViewed: boolean
): ColumnProps<RealogramOrderBy>[] => [
  {
    headerName: '',
    headerSx: { width: '32px' },
  },
  {
    headerName: '名前',
    orderBy: isFilteredByViewed ? undefined : 'name',
    headerSx: { ...headerSx, color: 'gray', flex: 2 },
  },
  {
    headerName: '作成者',
    headerSx: { ...headerSx, maxWidth: '160px', minWidth: '160px' },
    hasDivider: true,
  },
  {
    headerName: isFilteredByViewed ? '閲覧日時' : '作成日時',
    orderBy: isFilteredByViewed ? 'current_user_accessed_at' : 'created_at',
    headerSx: { ...headerSx, maxWidth: '136px', minWidth: '136px' },
    hasDivider: true,
  },
  {
    headerName: 'ステータス',
    headerSx: { ...headerSx, maxWidth: '120px', minWidth: '120px' },
    hasDivider: true,
  },
  {
    headerName: '権限',
    headerSx: { ...headerSx, maxWidth: '88px', minWidth: '88px' },
    hasDivider: true,
  },
  {
    headerName: '',
    headerSx: {
      maxWidth: '56px',
      minWidth: '56px',
      position: 'sticky',
      right: 0,
      backgroundColor: '#F6FAFF',
    },
  },
];
