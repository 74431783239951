import { Delete } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import { FC } from 'react';

type Props = {
  handleDeleteDialogOpen: () => void;
  isCanDeleteBaypart?: boolean;
};

export const ShelfBoardMenuList: FC<Props> = ({
  handleDeleteDialogOpen,
  isCanDeleteBaypart,
}) => {
  return (
    <MenuList disablePadding>
      <MenuItem disabled={!isCanDeleteBaypart} onClick={handleDeleteDialogOpen}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <ListItemText>棚板の削除</ListItemText>
      </MenuItem>
    </MenuList>
  );
};
