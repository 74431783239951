import { ChangeEvent, FC } from 'react';
import { ChangeStep, RealogramType } from 'types/realogram';
import { StepLayout } from '../stepLayout';
import { GoodExample } from './realogramNotes/goodExample';
import { BadExample } from './realogramNotes/badExample';
import { theme } from 'theme';
import { Box } from '@mui/material';

type Props = {
  changeStep: ChangeStep;
  selectedRealogramType: RealogramType;
  onFirstFileSelect: (file: File) => void;
  isFlat: boolean;
  isMd: boolean;
  isSkipStepThree: boolean;
  isFlatSkipStepThree: boolean;
  handleSetIsSkipStepThree: (
    e: ChangeEvent<HTMLInputElement>,
    type: RealogramType
  ) => void;
  isXs: boolean;
};

export const StepThree: FC<Props> = ({
  changeStep,
  selectedRealogramType,
  onFirstFileSelect,
  isFlat,
  isMd,
  isSkipStepThree,
  isFlatSkipStepThree,
  handleSetIsSkipStepThree,
  isXs,
}) => {
  return (
    <StepLayout
      changeStep={changeStep}
      selectedRealogramType={selectedRealogramType}
      currentStep="three"
      onFirstFileSelect={onFirstFileSelect}
      isFlat={isFlat}
      title="新規スキャン 撮影するときの注意点"
      isSkipStepThree={isSkipStepThree}
      isFlatSkipStepThree={isFlatSkipStepThree}
      handleSetIsSkipStepThree={handleSetIsSkipStepThree}
      isXs={isXs}
    >
      <Box
        component="div"
        display="flex"
        justifyContent="space-around"
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        maxWidth={{ xs: '540px', sm: '540px', md: '920px' }}
        borderRadius={1}
        p={{ xs: 2, sm: 2 }}
        px={{ md: 1 }}
        gap="8px"
        sx={{
          borderRadius: '4px',
          backgroundColor: theme.palette.primary.selected,
          margin: {
            xs: '24px 16px',
            md: 0,
          },
        }}
      >
        <GoodExample isFlat={isFlat} isMd={isMd} isXs={isXs} />
        <BadExample isFlat={isFlat} isMd={isMd} isXs={isXs} />
      </Box>
    </StepLayout>
  );
};
