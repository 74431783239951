import { ProductCategories } from '@components/organisms/productCategories/productCategories';
import { ProductsList } from '@components/organisms/productsList/productsList';
import { FC, MutableRefObject } from 'react';
import { Product } from 'types/common';
import { ProductCategory } from 'types/productCategories';

type Props = {
  categoryType: 'categories' | 'list';
  productCategories?: ProductCategory[];
  handleClick: (category: ProductCategory) => void;
  categoryName: string;
  productsRef: MutableRefObject<HTMLDivElement | null>;
  isCompareMode?: boolean;
  handleEndReached: (index: number) => void;
  isLoading: boolean;
  products?: Product[];
  handleClickProduct: (product: Product) => void;
  isDraggable?: boolean;
  offset: number;
  selectedItemId?: number;
  isRealogram?: boolean;
};

export const Categories: FC<Props> = ({
  productCategories,
  categoryType,
  handleClick,
  productsRef,
  isCompareMode,
  handleEndReached,
  isLoading,
  products,
  handleClickProduct,
  isDraggable,
  offset,
  selectedItemId,
  isRealogram = false,
}) => {
  switch (categoryType) {
    case 'categories':
      return (
        <ProductCategories
          handleClick={handleClick}
          productCategories={productCategories}
          isCompareMode={isCompareMode}
          isRealogram={isRealogram}
        />
      );
    case 'list':
      return (
        <ProductsList
          isCompareMode={isCompareMode}
          productsRef={productsRef}
          handleEndReached={handleEndReached}
          isLoading={isLoading}
          products={products}
          handleClickProduct={handleClickProduct}
          isDraggable={isDraggable}
          offset={offset}
          selectedItemId={selectedItemId}
        />
      );
    default:
      return null;
  }
};
