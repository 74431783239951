import { ShelfIcon } from '@components/molecules/shelfIcon/shelfIcon';
import { SidebarItemButton } from '@components/molecules/sidebarItemButton/sidebarItemButton';
import { StackListSidebar } from '@components/organisms/stackListSidebar/stackListSidebar';
import { Box, Drawer, Stack, Typography } from '@mui/material';
import { SidebarValue, drawerWidth } from '@utils/const';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  sidebarValue: SidebarValue;
  isCreateStoreBayDisabled: boolean;
  handleCreateStoreBay: () => void;
  onClickTabAll: () => void;
  onClickTabFavorite: () => void;
};

export const StoreBaySidebar: FC<Props> = ({
  sidebarValue,
  isCreateStoreBayDisabled,
  handleCreateStoreBay,
  onClickTabAll,
  onClickTabFavorite,
}) => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          position: 'relative',
          marginTop: '1px',
          background: theme.palette.backgroundBlack.light,
        },
      }}
    >
      <Box
        component="nav"
        display="flex"
        flexDirection="column"
        gap={5}
        padding={1.5}
      >
        <Stack
          direction="column"
          alignSelf="stretch"
          alignItems="center"
          gap="8px"
        >
          <SidebarItemButton
            variant="contained"
            startIcon={
              isCreateStoreBayDisabled ? (
                <ShelfIcon />
              ) : (
                <ShelfIcon fillOpacity={1} fill="#FFFFFF" />
              )
            }
            onClick={handleCreateStoreBay}
            disabled={isCreateStoreBayDisabled}
          >
            <Typography
              component="span"
              sx={{
                width: '100%',
                fontWeight: 700,
                fontSize: '0.875rem',
                textAlign: 'center',
                color: isCreateStoreBayDisabled
                  ? theme.palette.textBlack.disabled
                  : theme.palette.white.primary,
              }}
            >
              什器追加
            </Typography>
          </SidebarItemButton>
        </Stack>
        <StackListSidebar
          sidebarValueItems={['all', 'favorite']}
          sidebarValue={sidebarValue}
          onClickTabAll={onClickTabAll}
          onClickTabFavorite={onClickTabFavorite}
        />
      </Box>
    </Drawer>
  );
};
