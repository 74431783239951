import { Box, Modal, Typography } from '@mui/material';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { selectRealogramCandidateRatio } from '@reducers/realogramCandidate/selector';
import { useSplitCompartmentMutation } from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { toastMessages } from '@utils/const';
import { FC, useEffect, useState } from 'react';
import { theme } from 'theme';
import { RealogramCandidate, RealogramCandidateFace } from 'types/realogram';
import { ModalActions } from './fragments/modalActions';
import { ParcelDivisionController } from './fragments/parcelDivisionController';

type Props = {
  open: boolean;
  handleClose: () => void;
  face?: RealogramCandidateFace;
  realogramCandidate?: RealogramCandidate;
  isRealogramCandidateLoading: boolean;
};

const minSplitQuantity = 2;
const canNotBeSplitText = ' (分割できません)(最大1区画)';
const minWidthPx = 8;
const minSplitNum = 1;
export const ParcelDivisionModal: FC<Props> = ({
  open,
  handleClose,
  face,
  realogramCandidate,
  isRealogramCandidateLoading,
}) => {
  const convertPxByRatio = (value: number) => value * ratio;
  const ratio = useAppSelector(selectRealogramCandidateRatio);
  const [splitCompartment, { isLoading: isSplitLoading }] =
    useSplitCompartmentMutation();
  const [division, setDivision] = useState(1);
  const dispatch = useAppDispatch();
  const handleDecrementDivisioin = () => {
    setDivision((i) => --i);
  };
  const handleIncrementDivision = () => {
    setDivision((i) => ++i);
  };
  const faceWidthPx = face?.bbox.width && convertPxByRatio(face.bbox.width);
  const isMax =
    faceWidthPx &&
    faceWidthPx / division >= minWidthPx &&
    faceWidthPx / (division + 1) < minWidthPx;
  const maxNum = faceWidthPx
    ? Math.trunc(faceWidthPx / minWidthPx)
    : minSplitNum;
  const canBeSplitText = ` (最大${maxNum}区画)`;
  const canBeSplit = !!(maxNum && maxNum > 1);
  const handleSubmit = async () => {
    dispatch(updateLoadingIndicatorState(true));
    try {
      await splitCompartment({
        realogramCandidateId: realogramCandidate?.id ?? 0,
        faceId: face?.id ?? '',
        splitAxis: 'horizontal',
        splitNum: division,
      }).unwrap();
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.divideCompartment,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.success.divideCompartment,
        })
      );
    } finally {
      handleClose();
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  useEffect(() => {
    // close after tags were invalidated and products list is updated
    if (!isRealogramCandidateLoading) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- fire only on isRealogramCandidateLoading change, because handleClose is being recrated on each render, it's better just not include it in deps rather than wrapping handleClose into useCallback
  }, [isRealogramCandidateLoading]);

  useEffect(() => {
    if (!maxNum) return;
    setDivision(maxNum >= minSplitQuantity ? minSplitQuantity : 1);
  }, [maxNum]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        component="div"
        borderRadius={1}
        minWidth="500px"
        sx={{
          px: 3,
          pt: 3,
          pb: '12px',
          background: theme.palette.white.primary,
        }}
      >
        <Box component="div" display="flex" flexDirection="column">
          <Typography variant="h6" fontWeight="700">
            区画を分割
          </Typography>
          <Typography variant="caption" mt={3}>
            分割できる上限数は区画のサイズによって決まっています。
          </Typography>
          <Box
            component="div"
            mt={2}
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="caption">{`区画数${
              canBeSplit ? canBeSplitText : canNotBeSplitText
            }`}</Typography>
            <Box
              component="div"
              display="flex"
              minWidth="120px"
              borderRadius="4px"
              border={`1px solid ${theme.palette.dividerBlack.dark}`}
            >
              <ParcelDivisionController
                isDisabled={!canBeSplit}
                division={division}
                handleIncrementDivision={handleIncrementDivision}
                handleDecrementDivisioin={handleDecrementDivisioin}
                isIncrementDisabled={!!isMax}
              />
            </Box>
          </Box>
        </Box>
        <ModalActions
          disabled={
            isSplitLoading || isRealogramCandidateLoading || !canBeSplit
          }
          handleClose={handleClose}
          actionBtnTitle="変更"
          handleClick={handleSubmit}
        />
      </Box>
    </Modal>
  );
};
