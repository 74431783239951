import { HeatMapColor } from 'types/common';

/* eslint no-magic-numbers: 0 -- ユーティリティなので許容する */

// TODO: text用の色（CommonColors.black）のみ残すか検討する
/**
 * @deprecated
 */
export const CommonColors = {
  black: '#000000',
  white: '#FFFFFF',
  dark: '#2A2A2A',
  light: '#EAEAEA',
  lightGray: '#F7F7F7',
  red: '#EA3434',
  blue: '#2196F3',
  appBlue: '#0A40CA',
  appGreen: '#4CAF50',
  appRed: '#F44336',
  appOrange: '#DB8335',
  grayLight: '#757575',
  green: '#2E992E',
  yellow: '#E8A200',
  deepRed: '#D62A2A',
  turbo: '#E8A200',
  grayAxis: '#777777',
  appBlueLight: '#E5F0FF',
};

export const HeatMapColors = {
  white: '#FFEFE5',
  lightOrange: '#FFB978',
  orange: '#FF8533',
  red: '#ED4F00',
  darkRed: '#B23B00',
};

export const heatMapColors: HeatMapColor[] = [
  'white',
  'lightOrange',
  'orange',
  'red',
  'darkRed',
];

const regexHex8 = /^#(?:[0-9a-fA-F]{3,4}){1,2}$/;
const regexRgba =
  /^rgba[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*,){3}\s*0*(?:\.\d+|1(?:\.0*)?)\s*[)]$/;

/**
 *
 * @param colorCode BaseColor you want to apply transparency
 * @param transparency amount of transparency. Between 0 to 1.
 */
export const withOpacity = (colorCode: string, opacity: number): string => {
  // Safeguard the transparency value.
  if (opacity < 0) opacity = 0;
  if (opacity > 1) opacity = 1;

  // Transparency in hexadecimal
  const transparency16 = Math.round(Math.min(opacity, 1) * 255)
    .toString(16)
    .toUpperCase();

  return `${colorCode}${transparency16}`;
};

/**
 *
 * @param colorCode Must be 8 digit hexadecimal color code string in hexadecimal form
 * @returns rgba code of the provided color code
 */
export const toRgba = (colorCode: string): string => {
  if (!regexHex8.test(colorCode))
    throw new Error(`Incorrect color code has been supplied, ${colorCode}`);
  // The format of the colorCode will be consists of 9 chars: Sample: #EAEA34FF
  // After the #, the first 6 char represents 3 numbers in hexadecimal number
  // The last 2 chars represents the colors transperency amount in hexadecimal number
  const rawColorCode = colorCode.replace('#', '').match(/.{1,2}/g);

  // TODO Add test code
  if (!rawColorCode) throw new Error('RawColorCode is null');

  const rgba = `rgba(${parseInt(rawColorCode[0], 16)}, ${parseInt(
    rawColorCode[1],
    16
  )}, ${parseInt(rawColorCode[2], 16)}, ${
    parseInt(rawColorCode[3], 16) / 255
  })`;
  // Check the generated RGBA if it's incorrect throw error
  if (!regexRgba.test(rgba))
    throw new Error(`Incorrect rgba has been generated: ${rgba}`);
  return rgba;
};
