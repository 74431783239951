/* eslint-disable react/no-unknown-property -- next lintではエラーになるので抑制 */
import { applyProps, useLoader, useThree } from '@react-three/fiber';
import { Instance } from '@react-three/fiber/dist/declarations/src/core/renderer';
import { baseUrl } from '@utils/const';
import { FC, useLayoutEffect } from 'react';
import { Mesh, REVISION } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { KTX2Loader } from 'utils/KTX2Loader';

type Props = {
  url: string;
  token: string; // Model内で取得すると `could not find react-redux context value;` エラーが発生するので外部から受け取る
  finishLoading?: () => void;
};

export const Model: FC<Props> = ({ url, token, finishLoading }) => {
  // todo: パスの調整方法要検討
  const endpoint = baseUrl.replace('/api/v1', '');
  const { gl } = useThree();

  const { scene } = useLoader(GLTFLoader, `${endpoint}${url}`, (loader) => {
    loader.setRequestHeader({ authorization: `Bearer ${token}` });
    const threePath = `https://unpkg.com/three@0.${REVISION}.x`;
    const ktx2Loader = new KTX2Loader(loader.manager);
    ktx2Loader.setTranscoderPath(`${threePath}/examples/js/libs/basis/`);
    ktx2Loader.detectSupport(gl);
    ktx2Loader.setRequestHeader({
      authorization: `Bearer ${token}`,
    });
    (loader as GLTFLoader).setKTX2Loader(ktx2Loader);
  });

  useLayoutEffect(() => {
    scene.traverse((o) => {
      if ((o as Mesh).isMesh) {
        applyProps(o as unknown as Instance, {
          castShadow: true,
          receiveShadow: true,
        });
      }
    });
    if (finishLoading) {
      finishLoading();
    }
  });

  return (
    <group>
      <primitive scale={[1, 1, 1]} object={scene} />
    </group>
  );
};
