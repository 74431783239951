import { init, use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import defaultJson from '../../i18n/ja.json';

export const initI18next = () => {
  use(initReactI18next);
  init({
    // ロケールとリソースの設定
    // debug: true,
    resources: {
      default: {
        translation: defaultJson,
      },
    },
    lng: 'default', // 初期言語
    fallbackLng: 'default', // フォールバック言語
    interpolation: {
      escapeValue: false, // ReactコンポーネントでHTMLエスケープを行わない
    },
  }).catch((error) => console.log(error));
};
