import { FC } from 'react';
import { IconButton } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

type Props = {
  handleClick: () => void;
  isAscending: boolean;
};

export const SortIconButtons: FC<Props> = ({ handleClick, isAscending }) => {
  return (
    <IconButton
      onClick={handleClick}
      sx={{ color: '#B5B5B5', width: 20, height: 20 }}
    >
      {isAscending ? (
        <ArrowUpward fontSize="small" />
      ) : (
        <ArrowDownward fontSize="small" />
      )}
    </IconButton>
  );
};
