import { ModalInnerProps } from '@components/modal';
import { TabPanel } from '@components/molecules';
import { CampaignIcon } from '@components/molecules/campaignIcon/campaignIcon';
import { HistoryIcon } from '@components/molecules/historyIcon/historyIcon';
import { SelectionModalActions } from '@components/molecules/selectionModalActions/selectionModalActions';
import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { Folder } from '@mui/icons-material';
import {
  setCurrentTab,
  updateSelectedItemId,
} from '@reducers/comparisonItemModal';
import { selectComparisonItemModal } from '@reducers/comparisonItemModal/selectors';
import { setDirectoryId } from '@reducers/sharePermission';
import {
  planogramDirectoriesApi,
  realogramDirectoriesApi,
  useGetUserQuery,
  useSearchPlanogramDirectoriesQuery,
  useSearchRealogramDirectoriesQuery,
} from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { ComparisonTabModal, planogramSearchLimit } from '@utils/const';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DirectoryType } from 'types/common';
import { PlanogramDirectories, PlanogramDirectory } from 'types/planogram';
import { RealogramDirectories, RealogramDirectory } from 'types/realogram';
import { BaseModal } from '../baseModal/baseModal';
import { ComparisonPlanograms } from './fragments/comparisonPlanograms';
import { ComparisonRealograms } from './fragments/comparisonRealograms';
import { ComparisonTabs } from './fragments/comparisonTabs';

type PlanogramSelectionModalProps = {
  isPlanogramPlan?: boolean;
  handleChangeSelectedComparison: (
    selectedTab: ComparisonTabModal,
    selectedItem?: Partial<DirectoryType>
  ) => void;
  originPlanogramId?: number;
  originRealogramId?: number;
};

type Props = ModalInnerProps<PlanogramSelectionModalProps>;

const sideBarList = [
  {
    icon: <Folder />,
    label: 'すべて',
    value: 'all',
  },
  {
    icon: <StarIcon />,
    label: 'スター付き',
    value: 'favorite',
  },
  {
    icon: <CampaignIcon />,
    label: '最近のスキャン',
    value: 'latest',
  },
  {
    icon: <HistoryIcon />,
    label: '閲覧履歴',
    value: 'history',
  },
];

const sideBarAll = sideBarList[0].value;
const sideBarFavorite = sideBarList[1].value;
const sideBarLatest = sideBarList[2].value;
const sideBarHistory = sideBarList[3].value;

export const ComparisonSelectionModal: FC<Props> = ({
  handleClose,
  data: {
    handleChangeSelectedComparison,
    originPlanogramId,
    originRealogramId,
  },
}) => {
  const dispatch = useAppDispatch();
  const {
    currentPlanogramDirectoryId,
    currentRealogramDirectoryId,
    currentTab,
    currentSelectedItem,
    currentSelectedItemId,
    currentSelectedType,
  } = useAppSelector(selectComparisonItemModal);
  const [tabValue, setTabValue] = useState<ComparisonTabModal>(
    currentTab ?? ComparisonTabModal.REALOGRAM_TAB
  );
  const [isLoading, setIsLoading] = useState(true);
  const [realogramDirectoryId, setRealogramDirectoryId] = useState<
    string | undefined
  >(currentRealogramDirectoryId);
  const [planogramDirectoryId, setPlanogramDirectoryId] = useState<
    string | undefined
  >(currentPlanogramDirectoryId);
  const [search, setSearch] = useState('');
  const [realogramDirectories, setRealogramDirectories] =
    useState<RealogramDirectories>();

  const [planogramDirectories, setPlanogramDirectories] =
    useState<PlanogramDirectories>();

  const [selectedItem, setSelectedItem] = useState<
    Partial<DirectoryType> | undefined
  >(currentSelectedItem);
  const [listTotal, setListTotal] = useState(0);
  const [planogramSideBarValue, setPlanogramSideBarValue] = useState('all');
  const [planogramOffset, setPlanogramOffset] = useState(0);
  const [planogramScrollPos, setPlanogramScrollPos] = useState(0);
  const [realogramOffset, setRealogramOffset] = useState(0);
  const [realogramScrollPos, setRealogramScrollPos] = useState(0);
  const [realogramSideBarValue, setRealogramSideBarValue] = useState('all');
  const sideBarValue =
    tabValue === ComparisonTabModal.REALOGRAM_TAB
      ? realogramSideBarValue
      : planogramSideBarValue;

  const isFilteredByFavoritePlanogram =
    planogramSideBarValue === sideBarFavorite;

  const isFilterByAllRealogram = realogramSideBarValue === sideBarAll;

  const isFilteredByFavoriteRealogram =
    realogramSideBarValue === sideBarFavorite;
  const isFilteredByLatestRealogram = realogramSideBarValue === sideBarLatest;

  const isFilteredByHistoryRealogram = realogramSideBarValue === sideBarHistory;
  const isFilteredByHistoryPlanogram = planogramSideBarValue === sideBarHistory;

  const isRealogramTabSelected = tabValue === ComparisonTabModal.REALOGRAM_TAB;

  const isFilterByAllPlanogram = planogramSideBarValue === sideBarAll;

  const tabType = isRealogramTabSelected ? 'realogram' : 'planogram';
  const defaultListHeaderTitle = isRealogramTabSelected
    ? 'スキャン結果'
    : '棚割計画';
  const parent = isRealogramTabSelected
    ? realogramDirectories?.parent
    : planogramDirectories?.parent;
  const scrollerRef = useRef<HTMLElement | Window | null>();

  const clearDirectoryId = () => {
    if (isRealogramTabSelected) {
      setRealogramDirectoryId(undefined);
    } else {
      setPlanogramDirectoryId(undefined);
    }
  };

  const handleTabChange = (newValue: number) => {
    setSearch('');
    clearDirectoryId();
    setTabValue(newValue);
    dispatch(setCurrentTab(newValue));
    if (newValue === ComparisonTabModal.REALOGRAM_TAB && scrollerRef.current) {
      setPlanogramScrollPos((scrollerRef.current as HTMLElement)?.scrollTop);
    } else {
      setRealogramScrollPos((scrollerRef.current as HTMLElement)?.scrollTop);
    }
  };

  const getFirstOrderRealogram = useMemo(() => {
    if (isFilteredByHistoryRealogram) return 'current_user_accessed_at_desc';
    if (isFilteredByLatestRealogram) return 'created_at_desc';
    return undefined;
  }, [isFilteredByLatestRealogram, isFilteredByHistoryRealogram]);

  const { data: userData } = useGetUserQuery({
    userId: 'me',
  });
  const {
    data: searchedRealogramData,
    isFetching: isSearchRealogramFetching,
    isLoading: isSearchRealogramLoading,
  } = useSearchRealogramDirectoriesQuery(
    {
      name: search,
      offset: realogramOffset,
      favoriteOwnerId:
        isFilteredByFavoriteRealogram && userData?.user.id
          ? [userData.user.id]
          : undefined,
      firstOrder: getFirstOrderRealogram,
      filterByRole: 'viewer',
    },
    {
      skip:
        !search &&
        !isFilteredByFavoriteRealogram &&
        !isFilteredByLatestRealogram &&
        !isFilteredByHistoryRealogram,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: searchedPlanogramData,
    isFetching: isSearchPlanogramFetching,
    isLoading: isSearchPlanogramLoading,
  } = useSearchPlanogramDirectoriesQuery(
    {
      name: search,
      offset: planogramOffset,
      // Use max limit to optimize the UX
      limit: planogramSearchLimit,
      favoriteOwnerId:
        isFilteredByFavoritePlanogram && userData?.user.id
          ? [userData.user.id]
          : undefined,
      firstOrder: isFilteredByHistoryPlanogram
        ? 'current_user_accessed_at_desc'
        : undefined,
      filterByRole: 'viewer',
    },
    {
      skip:
        !search &&
        !isFilteredByFavoritePlanogram &&
        !isFilteredByHistoryPlanogram,
      refetchOnMountOrArgChange: true,
    }
  );

  const fetchRealogramDirectories = useCallback(
    async ({ id }: { id?: string | undefined }) => {
      setIsLoading(true);
      try {
        const results = await dispatch(
          realogramDirectoriesApi.endpoints.listRealogramDirectories.initiate(
            {
              id,
              filterByRole: 'viewer',
            },
            { forceRefetch: !search }
          )
        );
        if (results.data) {
          setRealogramDirectories(results.data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, search]
  );

  const fetchPlanogramDirectories = useCallback(
    async ({ id }: { id?: string | undefined }) => {
      setIsLoading(true);
      try {
        const results = await dispatch(
          planogramDirectoriesApi.endpoints.listPlanogramDirectories.initiate(
            {
              id,
              offset: planogramOffset,
              limit: planogramSearchLimit,
              filterByRole: 'viewer',
            },
            { forceRefetch: !search }
          )
        );
        if (results.data) {
          setListTotal(results.data.pager.total ?? 0);
          setPlanogramDirectories(results.data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, search, planogramOffset]
  );
  const clearSearch = () => {
    setSearch('');
    if (isRealogramTabSelected) {
      setRealogramOffset(0);
    } else {
      setPlanogramOffset(0);
    }
  };
  const handleSearch = (name: string) => {
    if (isRealogramTabSelected) {
      setRealogramDirectories(undefined);
      setRealogramDirectoryId(undefined);
      setRealogramSideBarValue(sideBarAll);
      setRealogramOffset(0);
      setRealogramScrollPos(0);
    } else {
      setPlanogramDirectories(undefined);
      setPlanogramDirectoryId(undefined);
      setPlanogramSideBarValue(sideBarAll);
      setPlanogramOffset(0);
      setPlanogramScrollPos(0);
    }
    setSearch(name);
  };

  const handleClickSideBarChip = (value: string) => {
    clearSearch();
    clearDirectoryId();
    setDirectoryId('');
    if (isRealogramTabSelected) {
      setRealogramSideBarValue(value);
      setRealogramScrollPos(0);
    } else {
      setPlanogramSideBarValue(value);
      setPlanogramScrollPos(0);
    }
  };

  const originalPlanogramDirectories = useMemo(() => {
    const filteredData =
      search || isFilteredByFavoritePlanogram || isFilteredByHistoryPlanogram
        ? searchedPlanogramData?.planogram_directories
        : planogramDirectories?.planogram_directories;

    return filteredData || [];
  }, [
    search,
    searchedPlanogramData,
    planogramDirectories,
    isFilteredByFavoritePlanogram,
    isFilteredByHistoryPlanogram,
  ]);

  const originalRealogramDirectories = useMemo(() => {
    const filteredData =
      search ||
      isFilteredByLatestRealogram ||
      isFilteredByHistoryRealogram ||
      isFilteredByFavoriteRealogram
        ? searchedRealogramData?.realogram_directories
        : realogramDirectories?.realogram_directories;

    return filteredData || [];
  }, [
    search,
    searchedRealogramData,
    realogramDirectories,
    isFilteredByHistoryRealogram,
    isFilteredByFavoriteRealogram,
    isFilteredByLatestRealogram,
  ]);

  useEffect(() => {
    if (search) return;
    if (tabValue === ComparisonTabModal.REALOGRAM_TAB) {
      if (
        isFilteredByFavoriteRealogram ||
        isFilteredByLatestRealogram ||
        isFilteredByHistoryRealogram
      ) {
        return;
      }
      void fetchRealogramDirectories({
        id: realogramDirectoryId,
      });
    } else {
      if (isFilteredByFavoritePlanogram || isFilteredByHistoryPlanogram) {
        return;
      }
      void fetchPlanogramDirectories({
        id: planogramDirectoryId,
      });
    }
  }, [
    tabValue,
    realogramDirectoryId,
    planogramDirectoryId,
    fetchRealogramDirectories,
    fetchPlanogramDirectories,
    currentRealogramDirectoryId,
    currentPlanogramDirectoryId,
    search,
    isFilteredByFavoriteRealogram,
    isFilteredByLatestRealogram,
    isFilteredByHistoryRealogram,
    isFilteredByFavoritePlanogram,
    isFilteredByHistoryPlanogram,
  ]);

  const stateValues = {
    planogram: {
      isRoot: planogramDirectories?.parent?.type === 'root',
      handleClickBack: () => {
        if (!planogramDirectories?.parent?.parent_id) return;
        setPlanogramDirectoryId(planogramDirectories?.parent.parent_id);
      },
      handleClickBreadcrumbs: (item: DirectoryType) => {
        if (item.type === 'root' || item.type === 'directory') {
          setPlanogramDirectoryId(item.id);
        }
      },
      displayName: () => {
        if (isFilteredByFavoritePlanogram) {
          return 'スター付き';
        }
        if (isFilteredByHistoryPlanogram) {
          return '閲覧履歴';
        }
        if (planogramDirectories?.parent?.type === 'root') {
          return '棚割計画';
        }
        return planogramDirectories?.parent?.name;
      },
    },

    realogram: {
      isRoot: realogramDirectories?.parent?.type === 'root',
      handleClickBack: () => {
        if (!realogramDirectories?.parent?.parent_id) return;
        setRealogramDirectoryId(realogramDirectories?.parent?.parent_id);
      },
      handleClickBreadcrumbs: (item: DirectoryType) => {
        if (item.type === 'root' || item.type === 'directory') {
          setRealogramDirectoryId(item.id);
        }
      },
      displayName: () => {
        if (isFilteredByLatestRealogram) {
          return '最近のスキャン';
        }
        if (isFilteredByFavoriteRealogram) {
          return 'スター付き';
        }
        if (isFilteredByHistoryRealogram) {
          return '閲覧履歴';
        }
        if (realogramDirectories?.parent?.type === 'root') {
          return 'スキャン結果';
        }
        return realogramDirectories?.parent?.name;
      },
    },
  };
  const planogramTotal =
    search || isFilteredByFavoritePlanogram || isFilteredByHistoryPlanogram
      ? searchedPlanogramData?.pager.total
      : listTotal;

  const handleScrollerRef = useCallback((ref: HTMLElement | Window | null) => {
    scrollerRef.current = ref;
  }, []);

  const disabledSelect =
    !selectedItem ||
    // check selected item in the realogram tab
    (tabValue === ComparisonTabModal.PLANOGRAM_TAB &&
      !(selectedItem as PlanogramDirectory).planogram_id) ||
    // check selected item in the planogram tab
    (tabValue === ComparisonTabModal.REALOGRAM_TAB &&
      !(selectedItem as RealogramDirectory).realogram_candidate_id);

  return (
    <BaseModal
      isUnderRoot={
        parent &&
        !stateValues[tabType].isRoot &&
        ((isFilterByAllRealogram && isRealogramTabSelected) ||
          (isFilterByAllPlanogram && !isRealogramTabSelected))
      }
      listHeaderTitle={
        stateValues[tabType].displayName() ?? defaultListHeaderTitle
      }
      handleClickSideBarChip={handleClickSideBarChip}
      sideBarHiddenValue={!isRealogramTabSelected ? 'latest' : ''}
      sideBarValues={sideBarList}
      handleClickBack={stateValues[tabType].handleClickBack}
      clearSearchText={clearSearch}
      modalTitle="比較したい棚割"
      modalSubtitle="を選択"
      search={search}
      searchPlaceholder="キーワードで検索"
      handleSearchText={handleSearch}
      sideBarValue={sideBarValue}
      tabs={
        <ComparisonTabs tabValue={tabValue} handleTabChange={handleTabChange} />
      }
      list={
        <>
          {tabValue === ComparisonTabModal.REALOGRAM_TAB && (
            <TabPanel
              index={ComparisonTabModal.REALOGRAM_TAB}
              value={tabValue}
              sx={{
                flex: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ComparisonRealograms
                key={realogramSideBarValue}
                tabValue={tabValue}
                isLoading={isLoading}
                isFetching={
                  isSearchRealogramFetching || isSearchRealogramLoading
                }
                searchedTotal={searchedRealogramData?.pager.total ?? 0}
                realogramOffset={realogramOffset}
                realogramDirectories={originalRealogramDirectories}
                realogramBreadcrumbs={
                  isFilterByAllRealogram
                    ? realogramDirectories?.breadcrumb
                    : undefined
                }
                realogramDirectoryParent={
                  isFilterByAllRealogram
                    ? realogramDirectories?.parent
                    : undefined
                }
                setRealogramDirectoryId={setRealogramDirectoryId}
                setSelectedItem={setSelectedItem}
                handleClickBreadcrumbs={
                  stateValues.realogram.handleClickBreadcrumbs
                }
                userId={userData?.user.id}
                textSearch={search}
                setSideBarValue={setRealogramSideBarValue}
                setSearch={setSearch}
                handleScrollerRef={handleScrollerRef}
                scrollPos={realogramScrollPos}
                setRealogramOffset={setRealogramOffset}
                isSelectedItemInDirectory={
                  realogramDirectoryId === selectedItem?.parent_id
                }
                originRealogramId={originRealogramId}
              />
            </TabPanel>
          )}
          {tabValue === ComparisonTabModal.PLANOGRAM_TAB && (
            <TabPanel
              index={ComparisonTabModal.PLANOGRAM_TAB}
              value={tabValue}
              sx={{
                flex: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ComparisonPlanograms
                key={planogramSideBarValue}
                tabValue={tabValue}
                isLoading={isLoading}
                isFetching={
                  isSearchPlanogramFetching || isSearchPlanogramLoading
                }
                searchedTotal={planogramTotal ?? 0}
                planogramOffset={planogramOffset}
                planogramDirectories={originalPlanogramDirectories}
                planogramBreadcrumbs={
                  isFilterByAllPlanogram
                    ? planogramDirectories?.breadcrumb
                    : undefined
                }
                planogramDirectoryParent={
                  isFilterByAllPlanogram
                    ? planogramDirectories?.parent
                    : undefined
                }
                setPlanogramDirectoryId={setPlanogramDirectoryId}
                setSelectedItem={setSelectedItem}
                originPlanogramId={originPlanogramId}
                handleClickBreadcrumbs={
                  stateValues.planogram.handleClickBreadcrumbs
                }
                userId={userData?.user.id}
                setSideBarValue={setPlanogramSideBarValue}
                textSearch={search}
                setSearch={setSearch}
                handleScrollerRef={handleScrollerRef}
                scrollPos={planogramScrollPos}
                setPlanogramOffset={setPlanogramOffset}
                isSelectedItemInDirectory={
                  planogramDirectoryId === selectedItem?.parent_id
                }
              />
            </TabPanel>
          )}
        </>
      }
      footer={
        <SelectionModalActions
          handleClick={() => {
            handleChangeSelectedComparison(tabValue, selectedItem);
          }}
          handleClose={() => {
            dispatch(updateSelectedItemId(currentSelectedItemId));
            if (currentSelectedType === 'actual') {
              dispatch(setCurrentTab(ComparisonTabModal.REALOGRAM_TAB));
            } else if (currentSelectedType === 'plan') {
              dispatch(setCurrentTab(ComparisonTabModal.PLANOGRAM_TAB));
            }
            handleClose();
          }}
          disabledSelect={disabledSelect}
        />
      }
    />
  );
};
