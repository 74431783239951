import { Box, IconButton, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  step?: number;
  onUpClick: () => void;
  onDownClick: () => void;
  isUpDisabled?: boolean;
  isDownDisabled?: boolean;
};

export const BayPartController: FC<Props> = ({
  step,
  onUpClick,
  onDownClick,
  isUpDisabled = false,
  isDownDisabled = false,
}) => {
  return (
    <>
      <Box
        component="div"
        position="absolute"
        bottom="24px"
        left="24px"
        // layout
        display="flex"
        width="120px"
        height="120px"
        padding="4px 46px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flexShrink={0}
        // styles
        borderRadius="96px"
        bgcolor="#fff"
        boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.05)"
      >
        <IconButton
          disabled={step == null || isUpDisabled}
          sx={{ padding: 0, paddingTop: '4px', margin: '-8px' }}
          onClick={() => onUpClick()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="47"
            viewBox="0 0 42 47"
            fill="none"
          >
            <g filter="url(#filter0_d_25439_71939)">
              <path
                d="M8 24.7705V14C8 9.58172 11.5817 6 16 6H26C30.4183 6 34 9.58172 34 14V24.7705C34 27.4253 32.683 29.9072 30.4846 31.3954L25.4846 34.78C22.776 36.6136 19.224 36.6136 16.5154 34.78L11.5154 31.3954C9.31698 29.9072 8 27.4253 8 24.7705Z"
                fill={
                  isUpDisabled
                    ? theme.palette.backgroundBlack.disabled
                    : 'white'
                }
              />
              <path
                d="M15.2222 21.4998L21 14.1577L26.7778 21.4998L15.2222 21.4998Z"
                fill={isUpDisabled ? theme.palette.icons.disabled : '#0A40CA'}
              />
            </g>
            <defs>
              <filter
                id="filter0_d_25439_71939"
                x="0"
                y="0"
                width="42"
                height="47"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_25439_71939"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_25439_71939"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </IconButton>
        <Box
          component="div"
          width="120px"
          sx={{
            display: 'flex',
            padding: '4px 10px',
            justifyContent: 'center',
            alignItems: 'baseline',
            gap: '4px',
          }}
        >
          <Typography sx={{ color: '#444', fontSize: '24px', fontWeight: 500 }}>
            {step}
          </Typography>
          <Typography
            sx={{
              fontSize: '10px',
              fontWeight: 400,
            }}
          >
            段目
          </Typography>
        </Box>
        <IconButton
          disabled={step == null || isDownDisabled}
          sx={{ padding: 0, margin: '-8px' }}
          onClick={() => onDownClick()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="47"
            viewBox="0 0 42 47"
            fill="none"
          >
            <g filter="url(#filter0_d_23586_277549)">
              <path
                d="M8 18.2295V29C8 33.4183 11.5817 37 16 37H26C30.4183 37 34 33.4183 34 29V18.2295C34 15.5747 32.683 13.0928 30.4846 11.6046L25.4846 8.21996C22.776 6.38643 19.224 6.38643 16.5154 8.21996L11.5154 11.6046C9.31698 13.0928 8 15.5747 8 18.2295Z"
                fill={
                  isDownDisabled
                    ? theme.palette.backgroundBlack.disabled
                    : 'white'
                }
              />
              <path
                d="M15.2222 21.4999L20.9999 28.842L26.7777 21.4999L15.2222 21.4999Z"
                fill={
                  isDownDisabled
                    ? theme.palette.backgroundBlack.disabled
                    : '#0A40CA'
                }
              />
            </g>
            <defs>
              <filter
                id="filter0_d_23586_277549"
                x="0"
                y="0"
                width="42"
                height="47"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_23586_277549"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_23586_277549"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </IconButton>
      </Box>
    </>
  );
};
