import { ChevronRight } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  categories?: string[];
};

export const CategoryIconDivider: FC<Props> = ({ categories }) => {
  return (
    <>
      {categories ? (
        categories?.map((category, index) => (
          <Typography
            fontSize="inherit"
            color="inherit"
            display="inline-flex"
            alignItems="center"
            key={index}
          >
            {category}
            {categories.length - 1 !== index && (
              <ChevronRight
                sx={{ color: theme.palette.icons.secondary }}
                fontSize="small"
              />
            )}
          </Typography>
        ))
      ) : (
        <Typography
          fontSize="inherit"
          color="inherit"
          display="inline-flex"
          alignItems="center"
        >
          -
        </Typography>
      )}
    </>
  );
};
