import { DataTable } from '@components/organisms';
import { FC, useMemo } from 'react';
import { PlanogramDirectory, PlanogramListOrder } from 'types/planogram';
import { PlanogramSharePermissionProps } from 'types/sharePermission';
import { TableHeader } from '../tableHeader/tableHeader';
import { TableCells } from './fragments/tableCells';
import { getColumns } from './fragments/tableHeaderData';
import { TableRowWrapper } from './fragments/tableRowWrapper';

type Props = {
  planogramDirectories?: PlanogramDirectory[];
  isRefetching: boolean;
  isFilteredByViewed: boolean;
  firstOrder?: PlanogramListOrder;
  isDisplayLoadingSkeleton: boolean;
  isEmpty: boolean;
  emptyTableMessage: string;
  emptyTableTitle: string;
  handleRowClick: (item: PlanogramDirectory) => void;
  handleOpenDeleteDialog: (planogramDirecotry: PlanogramDirectory) => void;
  handleClonePlanogram: (planogramDirecotry: PlanogramDirectory) => void;
  handleUpdatePlanogramName: (planogramDirectory?: PlanogramDirectory) => void;
  handleUpdateDirectoryName: (planogramDirectory?: PlanogramDirectory) => void;
  handleChangeOrder: (order: PlanogramListOrder) => void;
  handleMoveDirectory: (targetDirectory?: PlanogramDirectory) => void;
  handleFavoriteClick: (id: string, isFavorite: boolean) => void;
  handleSharePermission: (item: PlanogramSharePermissionProps) => void;
  handleEndReached: (isAtBottom: boolean) => void;
};

export const PlanogramsTable: FC<Props> = ({
  planogramDirectories,
  isRefetching,
  isDisplayLoadingSkeleton,
  isEmpty,
  emptyTableMessage,
  emptyTableTitle,
  isFilteredByViewed,
  firstOrder,
  handleRowClick,
  handleOpenDeleteDialog,
  handleClonePlanogram,
  handleUpdatePlanogramName,
  handleUpdateDirectoryName,
  handleMoveDirectory,
  handleChangeOrder,
  handleFavoriteClick,
  handleEndReached,
  handleSharePermission,
}) => {
  const columns = useMemo(
    () => getColumns(isFilteredByViewed),
    [isFilteredByViewed]
  );

  return (
    <DataTable<PlanogramDirectory>
      data={planogramDirectories}
      columns={columns}
      tableHeaderContent={
        <TableHeader
          columns={columns}
          firstOrder={firstOrder}
          handleChangeOrder={(order: PlanogramListOrder) =>
            handleChangeOrder(order)
          }
        />
      }
      isDisplayLoadingSkeleton={isDisplayLoadingSkeleton}
      isEmpty={isEmpty}
      isRefetching={isRefetching}
      emptyTableMessage={emptyTableMessage}
      emptyTableTitle={emptyTableTitle}
      itemContent={(_, item) => {
        return (
          <TableCells
            isFilteredByViewed={isFilteredByViewed}
            planogramDirectory={item}
            handleOpenDeleteDialog={handleOpenDeleteDialog}
            handleClonePlanogram={handleClonePlanogram}
            handleUpdatePlanogramName={handleUpdatePlanogramName}
            handleUpdateDirectoryName={handleUpdateDirectoryName}
            handleMoveDirectory={handleMoveDirectory}
            handleFavoriteClick={handleFavoriteClick}
            handleSharePermission={handleSharePermission}
          />
        );
      }}
      handleEndReached={handleEndReached}
      renderTableRow={(props) => {
        return (
          <TableRowWrapper
            handleRowClick={() => {
              handleRowClick(props.item as PlanogramDirectory);
            }}
            {...props}
          />
        );
      }}
    />
  );
};
