import { useListStoreBaysQuery } from '@reducers/shelfAppsApi';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBayPlanCodes } from '@hooks/useBayPlanCodes';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { schema } from '../fragments/stepFour/realogramForm/realogram.schema';
import { selectionStoreModal } from '@reducers/selectionStoreModal/selector';
import { useSelector } from 'react-redux';
import { RealogramFormData } from '../types/realogram';

export const useGetFormOptions = () => {
  const { currentSelectedItem } = useSelector(selectionStoreModal);
  const { control, watch, handleSubmit, setValue, resetField } =
    useForm<RealogramFormData>({
      resolver: yupResolver(schema),
      defaultValues: {
        storeId: 0,
        storeBayId: 0,
        // 仕様検討のため一旦コメントアウト
        // bayPlanId: undefined,
      },
    });
  const { bayPlanCodes } = useBayPlanCodes();
  const [storeId, storeBayId] = watch(['storeId', 'storeBayId']);
  useEffect(() => {
    setValue('storeId', currentSelectedItem?.store_id ?? 0);
  }, [currentSelectedItem, setValue]);

  const disabled = !storeId || !storeBayId;
  const { data: storeBayData } = useListStoreBaysQuery(
    { storeId: storeId || 0 },
    { skip: !storeId }
  );

  const setFormValue = (type: 'storeId' | 'storeBayId', id: number) => {
    setValue(type, id);
  };

  useEffect(() => {
    if (storeId) {
      resetField('storeBayId');
    }
  }, [resetField, storeId]);

  return {
    control,
    handleSubmit,
    disabled,
    storeBayData,
    bayPlanCodes,
    setFormValue,
  };
};
