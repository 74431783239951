import { MenuButton } from '@components/organisms/menuButton/menuButton';
import { FC } from 'react';
import { Planogram } from 'types/planogram';
import { MenuList } from './MenuList';

type Props = {
  menuOpen: boolean;
  planogram?: Planogram;
  handleOpenMenu: () => void;
  handleCloseMenu: () => void;
  handleClickMemoButton: () => void;
  handleClickDeleteButton: () => void;
  handleClickCloneButton: () => void;
};

export const MoreButton: FC<Props> = ({
  menuOpen,
  planogram,
  handleOpenMenu,
  handleCloseMenu,
  handleClickMemoButton,
  handleClickDeleteButton,
  handleClickCloneButton,
}) => {
  return (
    <MenuButton
      menuOpen={menuOpen}
      handleMenuOpen={handleOpenMenu}
      handleMenuClose={handleCloseMenu}
    >
      <MenuList
        planogram={planogram}
        handleClickMemoButton={handleClickMemoButton}
        handleClickDeleteButton={handleClickDeleteButton}
        handleClickCloneButton={handleClickCloneButton}
      />
    </MenuButton>
  );
};
