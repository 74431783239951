import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appName } from '@utils/const';

type PageTitleState = {
  pageTitle: string;
};

const initialState: PageTitleState = {
  pageTitle: '',
};

const pageTitleSlice = createSlice({
  name: 'PageTitle-Reducer',
  initialState,
  reducers: {
    setPageTitle: (
      state: PageTitleState,
      action: PayloadAction<PageTitleState['pageTitle']>
    ) => {
      const getPageTitle = (title: string) => {
        if (!title) {
          //Note: only Home
          return appName;
        }
        return `${title} | ${appName}`;
      };
      state.pageTitle = getPageTitle(action.payload);
    },
  },
});

export const { setPageTitle } = pageTitleSlice.actions;

export const PageTitleReducer = pageTitleSlice.reducer;
