import { planogramsApi } from '@api/services/planogram';
import { SearchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGetParams } from '@api/types/generated';
import { getNextPage, mapper } from '@api/utils/pagenation';
import { useInfiniteQuery } from '@tanstack/react-query';
import { objectToSnake } from 'ts-case-convert';

export const planogramDirectoriesSearchQueryKey = 'search_directories';

type Params =
  SearchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGetParams & {
    enabled?: boolean;
  };

export const useSearchPlanogramDirectories = ({
  enabled = false,
  ...params
}: Params) => {
  return useInfiniteQuery({
    enabled,
    queryKey: [planogramDirectoriesSearchQueryKey, params],
    queryFn: ({ pageParam = 0 }) => {
      return planogramsApi.getSearchPlanogramDirectories({
        ...objectToSnake(params),
        offset: pageParam,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => getNextPage(lastPage.pager),
    select: (data) => {
      return {
        pager: data.pages.at(-1)?.pager,
        planogram_directories: mapper(data?.pages, 'planogram_directories'),
      };
    },
    refetchOnWindowFocus: false,
  });
};
