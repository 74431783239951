import React, { FC } from 'react';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';

type Props = {
  open: boolean;
  submitMemo: () => void;
  memoText?: string;
  handleModalClose: () => void;
};

export const MemoModal: FC<Props> = ({
  open,
  submitMemo,
  memoText = '',
  handleModalClose,
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        component="form"
        onSubmit={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.preventDefault();
          submitMemo();
        }}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'transparent',
          boxShadow: 24,
          outline: 'none',
        }}
      >
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 3,
            paddingBottom: 2,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: 20, lineHeight: 1, fontWeight: 700, marginX: 2 }}
          >
            メモ
          </Typography>
          <Box
            component="div"
            sx={{
              marginX: 2,
              marginY: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                letterSpacing: '0.15px',
                color: '#000000',
              }}
            >
              {memoText}
            </Typography>
          </Box>
          <Box
            component="div"
            sx={{
              paddingX: 3,
              paddingTop: 2,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              borderTopWidth: 1,
              borderTopStyle: 'solid',
              borderTopColor: '#EDEDED',
            }}
          >
            <Button
              variant="text"
              sx={{
                borderWidth: 1,
                paddingX: 3.25,
                paddingY: 1.5,
                borderStyle: 'solid',
                borderRadius: 1,
                borderColor: CommonColors.appBlue,
              }}
              onClick={() => {
                handleModalClose();
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '16.5px',
                  fontWeight: 700,
                  color: CommonColors.appBlue,
                }}
              >
                とじる
              </Typography>
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};
