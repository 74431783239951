import { useBayPartDnD } from '@hooks/useDnD';
import { useBayPartImage } from '@hooks/useImage';
import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import { Box } from '@mui/material';
import {
  selectBayPartId,
  toggleIsSwappingBayPartMode,
  updateInitialBayPart,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { convertMeterToPixel, isEditor } from '@utils/planogram';
import { isEqual } from 'lodash';
import {
  FC,
  MouseEvent,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useLocation } from 'react-router-dom';
import { theme } from 'theme';
import { JustifyContent, PlanogramBayPart } from '../../../types/planogram';
import { BayPartContent, BayPartName } from './fragments';
import { BayPartMenu } from './fragments/bayPartMenu';

type Props = PlanogramBayPart & {
  rowIndex: number;
  isDragPreview?: boolean;
  canAdd?: boolean;
  isEditorInCompare?: boolean;
  isCompared?: boolean;
  justifyContent?: JustifyContent;
};

const thinShelf = 12;
const bayPartBottomOffset = -5;
const bayPartDragBarHeight = 5;
// paddingとborder分の高さをとる
const hookbarAround = 16;

// hookbarの場合、商品の配置可能領域がbarと被るため、上辺に合わせる必要がある。
const getBlueBorderStyles = (height: number, type?: string) => {
  const isThickShelf = height > thinShelf;
  const commonBorderStyles = {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '2px',
    padding: '6px 0px',
  };
  switch (type) {
    case 'hook_bar':
      return { ...commonBorderStyles, top: 0 };
    case 'board':
    case 'shelf_board':
      return {
        ...commonBorderStyles,
        bottom: isThickShelf ? 0 : '50%',
        transform: isThickShelf ? 'unset' : 'translateY(50%)',
      };
    default:
      return { ...commonBorderStyles, top: 0 };
  }
};

export const BayPart: FC<Props> = ({
  isDragPreview = false,
  canAdd = false,
  isEditorInCompare = true,
  isCompared = false,
  justifyContent,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { type, rowIndex } = props;
  const ref = useRef();
  const { drag, drop, preview, isDragging } = useBayPartDnD({
    ...props,
    isCompared,
  });
  const { mode, isSwappingBayPartMode, selectedBayPartId } = useAppSelector(
    selectPlanogramEditorState
  );
  const { removeBayPart, bayPartPosition, selectBayPartPosition } =
    usePlanogramPlan();
  const bayPartId = 'bay_part_id' in props ? props.bay_part_id : 0;
  const { image } = useBayPartImage({
    //TODO: エラー処理
    bayPartId: bayPartId,
    layer: type === 'hook_bar' ? 'background' : 'foreground',
  });
  const isEditingBay = mode === 'BayEditor';
  if (isEditingBay) {
    preview(getEmptyImage(), { captureDraggingState: true });
    drag(drop(ref));
  }
  const toggleType =
    justifyContent === 'space_evenly' ? 'chartSeparated' : 'chart';
  const isSelectedBayPart = isEqual(bayPartPosition, {
    indexX: 0,
    indexY: rowIndex,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [toggleValue, setToggleValue] = useState(toggleType);
  const handleToggleChange = (e: MouseEvent<HTMLElement>, value: string) => {
    e.stopPropagation();
    setToggleValue(value);
  };
  const handleClickBayPart: MouseEventHandler<HTMLDivElement> = (e) => {
    if (mode === 'CompartmentEditor' || isSwappingBayPartMode) return;
    e.stopPropagation(); //親要素のonClick(handleClickAwayPlanogram)発火を抑制する
    const { rowIndex, ...bayPart } = props;

    selectBayPartPosition(
      isSelectedBayPart ? undefined : { indexX: 0, indexY: rowIndex }
    );
    dispatch(updateInitialBayPart(bayPart));
    setAnchorEl(e.currentTarget);
    if (!isSwappingBayPartMode && selectedBayPartId) {
      dispatch(selectBayPartId(undefined));
    }
  };

  const handleDeleteClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    removeBayPart({
      indexX: 0,
      indexY: rowIndex,
    });
  };

  const hasMenu =
    isEditingBay &&
    !isDragPreview &&
    isSelectedBayPart &&
    isEditorInCompare &&
    !isSwappingBayPartMode &&
    !isCompared;
  const height = convertMeterToPixel(props.height);

  const handleSwapMode = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- rowIndex is used to omit from props
    const { rowIndex, ...bayPart } = props;
    dispatch(toggleIsSwappingBayPartMode());
    dispatch(updateInitialBayPart(bayPart));
    dispatch(selectBayPartId(bayPartId));
  };

  useEffect(() => {
    if (hasMenu && !anchorEl) {
      const anchorElement = document.querySelector(
        `[data-baypart-index=data-baypart-index-${rowIndex}]`
      );
      setAnchorEl(anchorElement as HTMLElement);
    }
  }, [isSwappingBayPartMode, rowIndex, hasMenu, anchorEl]);

  return (
    <>
      <Box
        component="div"
        position="relative"
        height={height}
        ref={isEditingBay ? drag : undefined}
        width="100%"
        sx={{ opacity: isDragging ? 0 : 1 }}
        data-baypart-index={isCompared ? '' : `data-baypart-index-${rowIndex}`}
      >
        <Box
          component="div"
          ref={ref}
          position="relative"
          height="100%"
          onClick={handleClickBayPart}
        >
          <BayPartContent
            isEditor={isEditorInCompare}
            {...props}
            image={image}
            isCompared={isCompared}
          />
          {((isSelectedBayPart && isSwappingBayPartMode) || hasMenu) &&
            !isCompared && (
              <Box
                component="div"
                height={
                  type === 'hook_bar' ? height : height + bayPartDragBarHeight
                }
                position="absolute"
                width="100%"
                sx={getBlueBorderStyles(height, type)}
              />
            )}

          {hasMenu && !isCompared && (
            <>
              <BayPartMenu
                value={toggleValue}
                onChange={handleToggleChange}
                anchorEl={anchorEl}
                open={!!anchorEl && hasMenu}
                handleDeleteClick={handleDeleteClick}
                position={{ indexX: 0, indexY: rowIndex }}
                height={type === 'hook_bar' ? height + hookbarAround : 0}
                handleSwapMode={handleSwapMode}
              />
            </>
          )}
        </Box>
        <Box
          component="div"
          sx={{
            ml: 2,
            top: bayPartBottomOffset,
            left: '100%',
            display: 'flex',
            opacity: isDragging ? 0 : 1,
            position: 'absolute',
          }}
        >
          {isEditor(pathname) && mode !== 'preview' && (
            <BayPartName
              isEditorInCompare={isEditorInCompare}
              open={canAdd}
              name={'name' in props ? props.name : ''}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
