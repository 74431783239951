import { regenerateIdToken } from '@reducers/auth';
import { store } from '@store/index';
import { baseUrl } from '@utils/const';
import { TokenHandler } from '@utils/tokenHandler';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

const tokenHandler = new TokenHandler();

export const axiosInstance = axios.create({
  baseURL: baseUrl.replace('/api/v1', ''),
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    indexes: null,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = tokenHandler.getToken();
    if (token?.idToken) {
      config.headers.Authorization = `Bearer ${token.idToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const { idToken, accessToken, refreshToken } = await store
          .dispatch(regenerateIdToken())
          .unwrap();

        tokenHandler.storeToken({ idToken, accessToken, refreshToken });

        axiosInstance.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${idToken}`;
        return axiosInstance(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export const createInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  return axiosInstance({
    ...config,
    ...options,
  }).then((r) => r.data);
};

export type BodyType<Data> = Data;

export type ErrorType<Error> = AxiosError<Error>;
