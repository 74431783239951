import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const ShelfEditorIcon: FC<SvgIconProps> = (props) => {
  const { htmlColor = '#0A40CA', ...otherProps } = props;

  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33301 3.33203H31.6663V10.832L25.833 16.6654H11.6663V23.332H19.9997V26.6654H11.6663V33.332H28.333V29.1654L31.6663 25.832V36.6654H8.33301V3.33203ZM28.333 13.332V6.66536H11.6663V13.332H28.333Z"
        fill={htmlColor}
      />
      <path
        d="M23.333 26.6693V23.8919L31.5245 15.7004L34.3019 18.4778L26.1104 26.6693H23.333Z"
        fill={htmlColor}
      />
      <path
        d="M36.4497 15.2857C36.7386 15.5745 36.7386 16.0411 36.4497 16.33L35.0943 17.6853L32.3169 14.9079L33.6723 13.5526C33.9612 13.2637 34.4278 13.2637 34.7166 13.5526L36.4497 15.2857Z"
        fill={htmlColor}
      />
    </SvgIcon>
  );
};
