import { PrivacyPolicyAgreement } from '@components/organisms/privacyPolicyAgreement/privacyPolicyAgreement';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Link, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { theme } from 'theme';
import { FormData } from '../login';
import { schema } from '../login.schema';

type Props = {
  signInUrl: string;
  handleChangeSelectEmailLogin: () => void;
};

export const SsoLogin: FC<Props> = ({
  signInUrl,
  handleChangeSelectEmailLogin,
}) => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const { watch } = methods;
  const privacyPolicy = watch('privacyPolicy');

  return (
    <FormProvider {...methods}>
      <Stack alignItems="center">
        <PrivacyPolicyAgreement />
        <Button
          component={Link}
          fullWidth
          variant="contained"
          href={signInUrl}
          disabled={!privacyPolicy}
        >
          SSOでログイン
        </Button>
      </Stack>
      <Divider sx={{ mb: 2, mt: 2, color: theme.palette.textBlack.secondary }}>
        <Typography>または</Typography>
      </Divider>
      <Box component="div" textAlign="center">
        <Link
          component="button"
          variant="body2"
          onClick={handleChangeSelectEmailLogin}
          textAlign="center"
        >
          メールアドレスでログイン
        </Link>
      </Box>
    </FormProvider>
  );
};
