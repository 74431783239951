import { planogramDirectoriesQueryKey } from '@api/hooks/usePlanogramDirectories';
import { planogramDirectoriesSearchQueryKey } from '@api/hooks/useSearchPlanogramDirectories';
import { queryClient } from '@api/query-client';
import { planogramsApi } from '@api/services/planogram';
import {
  PostPlanogramDirectory,
  PutPlanogramDirectory,
} from '@api/types/generated';
import { ModalInnerProps } from '@components/modal';
import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { Folder, Lock } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { selectUserRole } from '@reducers/auth/selectors';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { closeModal } from '@reducers/modal';
import {
  useGetUserQuery,
  useLazyListPlanogramDirectoriesQuery,
  useSearchPlanogramQuery,
} from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useMutation } from '@tanstack/react-query';
import { moveDirectoryLimit, paths, toastMessages } from '@utils/const';
import { canMoveOrCreateFolder } from '@utils/planogram';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import { DirectoryType } from 'types/common';
import { PlanogramDirectory } from 'types/planogram';
import { BaseModal } from '../baseModal/baseModal';
import { Footer } from './fragments/footer';
import { List } from './fragments/list';

const sideBarList = [
  {
    icon: <Folder />,
    label: 'すべて',
    value: 'all',
  },
  {
    icon: <StarIcon />,
    label: 'スター付き',
    value: 'favorite',
  },
];

export type MoveFolderFormProps = {
  sidebarValue: string;
  targetDirectory: PlanogramDirectory;
  displayFolder: () => void;
};
type Props = ModalInnerProps<MoveFolderFormProps>;

const newFolderName = '新規フォルダ';
export const MoveDirectoryModal: FC<Props> = ({
  data: { sidebarValue, targetDirectory, displayFolder },
  handleClose,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userRole = useAppSelector(selectUserRole);
  // const [createDirectory] = useCreatePlanogramDirectoryMutation();
  const { mutateAsync: updateDirectory } = useMutation({
    mutationFn: ({
      planogramDirectoryId,
      ...params
    }: PutPlanogramDirectory & { planogramDirectoryId: string }) =>
      planogramsApi.updateDirectory(planogramDirectoryId, params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [planogramDirectoriesSearchQueryKey],
      });
    },
  });

  const { mutateAsync: createDirectory } = useMutation({
    mutationFn: (params: PostPlanogramDirectory) =>
      planogramsApi.createPlanogramDirectory(params),
  });
  const [search, setSearch] = useState('');
  const [allOffset, setAllOffset] = useState(0);
  const [favoriteOffset, setFavoriteOffset] = useState(0);
  const [allScrollPos, setAllScrollPos] = useState(0);
  const [favoriteScrollPos, setFavoriteScrollPos] = useState(0);
  const [selectedSideBarValue, setSelectedSideBarValue] = useState(
    sidebarValue === 'viewed' ? 'all' : sidebarValue
  );
  const [selectedDirectoryId, setSelectedDirectoryId] = useState<
    string | undefined
  >(targetDirectory.parent_id);
  const [isCreateDirectory, setIsCreateDirectory] = useState<boolean>(false);
  const [createDirectoryCount, setCreateDirectoryCount] = useState(0);
  const scrollerRef = useRef<HTMLElement | Window | null>();
  const isFilteredByFavorite = selectedSideBarValue === sideBarList[1].value;
  const isSkipSearch = !isFilteredByFavorite && !search;

  const offset = isFilteredByFavorite ? favoriteOffset : allOffset;
  const setOffset = isFilteredByFavorite ? setFavoriteOffset : setAllOffset;
  const scrollPos = isFilteredByFavorite ? favoriteScrollPos : allScrollPos;
  const setScrollPos = isFilteredByFavorite
    ? setFavoriteScrollPos
    : setAllScrollPos;

  const [getListPlanogramDirectories, { isLoading, isFetching, data }] =
    useLazyListPlanogramDirectoriesQuery();
  const { data: userData } = useGetUserQuery({
    userId: 'me',
  });
  const fetchListPlanogram = useCallback(async () => {
    try {
      await getListPlanogramDirectories({
        id: selectedDirectoryId,
        directoryType: 'directory',
        offset: offset,
        limit: moveDirectoryLimit,
      }).unwrap();
    } catch (e) {
      console.error(e);
    }
  }, [getListPlanogramDirectories, selectedDirectoryId, offset]);

  useEffect(() => {
    if (isFilteredByFavorite || search || scrollPos) return;
    void fetchListPlanogram();
  }, [fetchListPlanogram, isFilteredByFavorite, search, scrollPos]);

  const {
    data: searchedData,
    isLoading: isSearchLoading,
    isFetching: isSearchFetching,
  } = useSearchPlanogramQuery(
    {
      offset: offset,
      limit: moveDirectoryLimit,
      name: search,
      favoriteOwnerId:
        isFilteredByFavorite && userData?.user.id
          ? [userData.user.id]
          : undefined,
      directoryType: 'directory',
    },
    {
      skip: isSkipSearch,
    }
  );
  const directoryParent = isSkipSearch ? data?.parent : undefined;
  const breadcrumbs = isSkipSearch ? data?.breadcrumb : undefined;
  const isRoot = isSkipSearch ? !data?.parent.parent_id : true;

  const isUnderRoot = !isRoot || isCreateDirectory;
  const directories =
    search || isFilteredByFavorite
      ? searchedData?.planogram_directories
      : data?.planogram_directories;
  const handleRowClick = (directory: PlanogramDirectory) => {
    setOffset(0);
    setScrollPos(0);
    if (search) {
      setSearch('');
    }
    if (isFilteredByFavorite) {
      setSelectedSideBarValue('all');
    }
    setSelectedDirectoryId(directory.id);
  };

  const handleCreateNewFolder = () => {
    setIsCreateDirectory(true);
    setCreateDirectoryCount(createDirectoryCount + 1);
  };

  const handleClickBack = () => {
    if (isCreateDirectory) {
      setCreateDirectoryCount(createDirectoryCount - 1);
      if (createDirectoryCount - 1 === 0) {
        setIsCreateDirectory(false);
      }
      return;
    }
    if (!directoryParent?.parent_id) return;
    setSelectedDirectoryId(directoryParent?.parent_id);
  };
  const title = () => {
    if (isCreateDirectory) {
      return '新規フォルダ';
    }
    if (isFilteredByFavorite) {
      return 'スター付き';
    }
    if (isRoot) {
      return '棚割計画';
    }
    return directoryParent?.name;
  };

  const handleSearchText = (text: string) => {
    setSelectedSideBarValue('all');
    if (text === '') return;
    setScrollPos(0);
    setOffset(0);
    setSearch(text);
  };

  const clearSearchText = () => {
    setSearch('');
    setScrollPos(0);
  };

  const handleClickBreadcrumbs = (directory: DirectoryType) => {
    const isNewFolder = directory.id.includes('newFolder');
    if (isCreateDirectory && isNewFolder) {
      //NOTE: get position index of newFolder
      //newFolder-${index}
      setSelectedDirectoryId(directory.id);

      const position = directory.id.split('-').at(1);
      if (position) {
        setCreateDirectoryCount(parseInt(position));
      }
      return;
    }

    setSelectedDirectoryId(directory.id);
    setCreateDirectoryCount(0);
    setIsCreateDirectory(false);
  };

  const handleClickSideBarChip = (value: string) => {
    if (search) {
      clearSearchText();
    }
    setSelectedDirectoryId(undefined);
    setSelectedSideBarValue(value);
  };
  const nestNewFolders = async (
    count: number,
    directoryParentId?: string
  ): Promise<string | undefined> => {
    try {
      if (count === 0) return directoryParentId;
      const res = await createDirectory({
        name: newFolderName,
        type: 'directory',
        parent_id: directoryParentId,
      });
      return nestNewFolders(count - 1, res.planogram_directory?.id);
    } catch (e) {
      console.log(e);
    }
  };

  const handleMove = async () => {
    dispatch(updateLoadingIndicatorState(true));
    const targetDirectoryType =
      targetDirectory.type === 'file' ? '棚割' : 'フォルダ';
    try {
      if (isCreateDirectory) {
        const newFolderId = await nestNewFolders(
          createDirectoryCount,
          directoryParent?.id
        );
        if (newFolderId) {
          const result = await updateDirectory({
            planogramDirectoryId: targetDirectory.id,
            parent_id: newFolderId,
          });
          dispatch(
            openToast({
              type: 'success',
              message: toastMessages.success.moveDirectoryName(
                newFolderName,
                directoryParent?.type
              ),
            })
          );
          dispatch(closeModal());
          dispatch(
            openToast({
              type: 'success',
              message: toastMessages.success.moveDirectoryName(
                newFolderName,
                directoryParent?.type
              ),
              renderProps: (
                <Typography
                  variant="buttonText2"
                  color={theme.palette.primary.main}
                  mx="12px"
                  onClick={() => {
                    displayFolder();
                    navigate(
                      paths.plans.foldersId(
                        result.planogram_directory?.parent_id ?? ''
                      )
                    );
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  フォルダに移動
                </Typography>
              ),
            })
          );
        } else {
          dispatch(
            openToast({
              type: 'error',
              message:
                toastMessages.error.moveDirectoryName(targetDirectoryType),
            })
          );
        }
        return;
      }
      const result = await updateDirectory({
        planogramDirectoryId: targetDirectory.id,
        parent_id: directoryParent?.id,
      });
      dispatch(closeModal());
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.moveDirectoryName(
            directoryParent?.name ?? '',
            directoryParent?.type
          ),
          renderProps: (
            <Typography
              variant="buttonText2"
              color={theme.palette.primary.main}
              mx="12px"
              onClick={() => {
                displayFolder();
                navigate(
                  paths.plans.foldersId(
                    result.planogram_directory?.parent_id ?? ''
                  )
                );
              }}
              sx={{ cursor: 'pointer' }}
            >
              フォルダを表示
            </Typography>
          ),
        })
      );
    } catch (e) {
      console.error(e);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.moveDirectoryName(targetDirectoryType),
        })
      );
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };
  const handleEndReached = () => {
    const listTotal = data?.pager.total ?? 0;
    const searchedTotal = searchedData?.pager?.total ?? 0;
    const shouldSkipSearch =
      !searchedData?.planogram_directories.length ||
      searchedData?.planogram_directories.length < moveDirectoryLimit ||
      offset + moveDirectoryLimit >= searchedTotal ||
      isSearchFetching ||
      isSearchLoading;
    const shouldSkipList =
      !data?.planogram_directories.length ||
      data?.planogram_directories.length < moveDirectoryLimit ||
      offset + moveDirectoryLimit >= listTotal ||
      isFetching ||
      isLoading;
    const shouldSkip =
      isFilteredByFavorite || !!search ? shouldSkipSearch : shouldSkipList;
    if (shouldSkip) return;
    setScrollPos(0);
    setOffset(offset + moveDirectoryLimit);
  };

  const isLoadingData = isLoading || isSearchLoading;
  const isLoadingOrFiltered =
    isFetching || isLoadingData || isFilteredByFavorite || !!search;
  const isFolderRestricted =
    !isLoadingData &&
    !isRoot &&
    (Boolean(
      !(
        userRole === 'admin' ||
        directoryParent?.current_user_role?.role === 'owner' ||
        directoryParent?.current_user_role?.role === 'editor'
      ) && selectedDirectoryId
    ) ||
      !canMoveOrCreateFolder(targetDirectory.scope, directoryParent?.scope));
  const isEmptyDirectory =
    !isLoadingData && (!directories?.length || isCreateDirectory);
  const userId = userData?.user.id;

  const isCreateFolderDisabled = isLoadingOrFiltered || isFolderRestricted;
  const isMoveDisabled =
    isFolderRestricted ||
    (!isCreateDirectory &&
      (isLoadingOrFiltered ||
        selectedDirectoryId === targetDirectory.parent_id));
  const handleScrollerRef = useCallback((ref: HTMLElement | Window | null) => {
    scrollerRef.current = ref;
  }, []);
  return (
    <BaseModal
      key={search}
      sideBarValues={sideBarList}
      sideBarValue={selectedSideBarValue}
      modalTitle={targetDirectory.name}
      modalSubtitle="を移動"
      handleClickSideBarChip={handleClickSideBarChip}
      search={search}
      searchPlaceholder="キーワードで検索"
      handleSearchText={handleSearchText}
      clearSearchText={clearSearchText}
      isUnderRoot={isUnderRoot && !isSearchFetching}
      handleClickBack={handleClickBack}
      listHeaderTitle={title()}
      listHeaderIcon={
        isFolderRestricted && (
          <Lock sx={{ fill: theme.palette.icons.secondary }} />
        )
      }
      footer={
        <Footer
          handleMove={handleMove}
          isCreateFolderDisabled={isCreateFolderDisabled}
          handleClose={handleClose}
          handleCreateNewFolder={handleCreateNewFolder}
          disabled={isMoveDisabled}
          actionBtnIcon={
            isFolderRestricted && (
              <Lock sx={{ fill: theme.palette.icons.secondary }} />
            )
          }
        />
      }
      list={
        <List
          key={selectedSideBarValue}
          isEmptyDirectory={isEmptyDirectory}
          isCreateDirectory={isCreateDirectory}
          isLoading={isLoadingData}
          directories={directories}
          handleRowClick={handleRowClick}
          breadcrumbs={breadcrumbs}
          parentDirectory={directoryParent}
          handleClickBreadcrumbs={handleClickBreadcrumbs}
          userId={userId}
          handleEndReached={handleEndReached}
          isFetching={isSearchFetching || isFetching}
          offset={offset}
          targetDirectory={targetDirectory}
          createDirectoryCount={createDirectoryCount}
          isCreateDirectoryUnderRoot={isCreateDirectory && isUnderRoot}
          textSearch={search}
          handleScrollerRef={handleScrollerRef}
          scrollPos={scrollPos}
        />
      }
    />
  );
};
