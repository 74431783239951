import { Box } from '@mui/material';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { ProductCategory } from 'types/productCategories';
import { SearchInput } from './searchInput';
import { RecentProductsTitle } from '../recentProducts/recentProductsTitle';
import { CategoryTitle } from './categoryTitle';
import { ActionButtons } from './actionButtons';

type Props = {
  displayActionButtons: boolean;
  displaySearchInput: boolean;
  control: Control<{ searchText: string }>;
  categoryIndex: number;
  productCategoryHistory: ProductCategory[];
  displayRecentProducts: boolean;
  onClearSearchClick: VoidFunction;
  onCategoryTitleClick: VoidFunction;
  onClickSearch: VoidFunction;
  onClickRecentProducts: VoidFunction;
};

export const ProductsHeader: FC<Props> = ({
  displayActionButtons,
  displaySearchInput,
  control,
  displayRecentProducts,
  categoryIndex,
  productCategoryHistory,
  onClearSearchClick,
  onClickSearch,
  onClickRecentProducts,
  onCategoryTitleClick,
}) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '48px',
      }}
    >
      {displaySearchInput && (
        <SearchInput
          isFocused={displaySearchInput}
          control={control}
          onClearClick={onClearSearchClick}
        />
      )}
      {displayRecentProducts && (
        <RecentProductsTitle onCloseClick={onClickRecentProducts} />
      )}
      {!displayRecentProducts && !displaySearchInput && (
        <CategoryTitle
          handleClick={onCategoryTitleClick}
          categoryIndex={categoryIndex}
          productCategoryHistory={productCategoryHistory}
        />
      )}
      {displayActionButtons && (
        <ActionButtons
          onClickSearch={onClickSearch}
          onClickRecentProducts={onClickRecentProducts}
        />
      )}
    </Box>
  );
};
