import { theme } from '../../../../theme';
import { Button, styled } from '@mui/material';

export const StyledButtonNavigate = styled(Button)(() => ({
  backgroundColor: `${theme.palette.white.primary}`,
  color: '#444444',
  border: 'none !important',
  height: '48px',
  width: '48px',
  '&:hover': {
    backgroundColor: `${theme.palette.white.primary}`,
  },
}));
