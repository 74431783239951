import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useListBayPlansQuery } from '@reducers/shelfAppsApi';
import { bayPlansLimit } from '@utils/const';
import { Controller, UseFormReturn } from 'react-hook-form';

type FormValue = {
  bayPlanId?: string;
};

type Props<T extends FormValue> = {
  methods: T extends FormValue ? UseFormReturn<T> : never;
  required?: boolean;
  onChange?: (category: string) => void;
};

export const BayPlansSelect = <T extends FormValue>({
  methods,
  required = false,
  onChange,
}: Props<T>) => {
  const { data } = useListBayPlansQuery({
    offset: 0,
    limit: bayPlansLimit,
  });
  const { control } = methods;

  return (
    <Controller
      control={control}
      name="bayPlanId"
      render={({ field, fieldState }) => (
        <FormControl size="small" fullWidth error={!!fieldState.error}>
          <InputLabel required={required}>ゴンドラテンプレート</InputLabel>
          <Select
            label="ゴンドラテンプレート"
            {...field}
            onChange={(e) => {
              field.onChange(e);
              onChange?.(e.target.value);
            }}
            name="bayPlanId"
          >
            {data?.bay_plans?.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
          {!!fieldState.error && (
            <FormHelperText>{fieldState.error.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
