import { Box, Typography } from '@mui/material';
import {
  convertMeterToPixel,
  shelfElevationToStepElevation,
  stepIndex,
} from '@utils/planogram';
import { FC, Fragment, useMemo } from 'react';
import { PlanogramPlan } from 'types/planogram';

type Props = {
  plan: PlanogramPlan;
};

export const BayPartsStepDiff: FC<Props> = ({ plan }) => {
  const { width, height } = plan.shelves_frame.detail.exterior;
  const steps = plan.shelves_frame.detail.shelf_steps.map(
    ({ elevation }) => elevation
  );

  const indicators = useMemo(() => {
    const s = plan.shelves
      .map((shelf) => {
        const e1 = shelfElevationToStepElevation(shelf) ?? -1;
        return shelf.type === 'hook_bar'
          ? {
              elevation:
                shelf.elevation +
                (shelf.detail.ui_helper?.display_space_2d.elevation ?? 0),
              shelfHeight:
                shelf.detail.exterior.height +
                (shelf.detail.exterior.padding.top ?? 0),
              // TODO: 昇順・降順の判定が必要
              stepTop: stepIndex(e1 + shelf.detail.exterior.height, steps),
              stepBottom: stepIndex(e1, steps),
              type: shelf.type,
            }
          : {
              elevation: shelf.elevation,
              shelfHeight:
                shelf.detail.exterior.height +
                (shelf.detail.exterior.padding.bottom ?? 0),
              // TODO: 昇順・降順の判定が必要
              stepTop: stepIndex(e1, steps),
              stepBottom: stepIndex(e1 - shelf.detail.exterior.height, steps),
              type: shelf.type,
            };
      })
      .filter(({ stepTop }) => stepTop <= steps.length);
    // eslint-disable-next-line no-magic-numbers -- 変数化する意味がないので許容する
    if (s.length < 2) {
      // 差分が取れない
      return [];
    }
    const i = [];
    for (let j = 0; j < s.length - 1; j++) {
      const current = s[j];
      const next = s[j + 1];
      const currentValue =
        current.type === 'hook_bar' ? current.stepBottom : current.stepTop;
      const nextValue =
        next.type === 'hook_bar' ? next.stepBottom : next.stepTop;
      i[j] = {
        diff: Math.abs(currentValue - nextValue),
        // eslint-disable-next-line no-magic-numbers -- 平均計算
        elevation: (next.elevation + current.elevation - next.shelfHeight) / 2,
        bottom: current.elevation,
        height: Math.abs(next.elevation - current.elevation),
        shelfHeight: next.shelfHeight,
      };
    }

    return i.reverse();
  }, [plan, steps]);

  return (
    <Box
      component="div"
      sx={{
        width: convertMeterToPixel(width),
        height: convertMeterToPixel(height),
        position: 'relative',
        paddingLeft: '50%',
        zIndex: 3,
      }}
    >
      {indicators.map(({ diff, elevation, bottom, height, shelfHeight }) => (
        <Fragment key={elevation}>
          <Box
            component="div"
            position="absolute"
            bottom={convertMeterToPixel(bottom)}
            sx={{
              backgroundColor: '#eb004e',
              width: '2px',
              height: convertMeterToPixel(height - (shelfHeight ?? 0)),
            }}
          />
          <Box
            component="div"
            position="absolute"
            bottom={convertMeterToPixel(elevation)}
            sx={{
              width: '20px',
              height: '20px',
              marginLeft: '4px',
              marginBottom: '-10px',
              borderRadius: '4px',
              backgroundColor: '#eb004e',
              color: '#fff',
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography sx={{ fontSize: '10px' }}>{diff}</Typography>
          </Box>
        </Fragment>
      ))}
    </Box>
  );
};
