import { FC } from 'react';
import { Product } from '../../../../types/common';
import {
  ProductCandidate,
  RealogramCandidateFace,
} from '../../../../types/realogram';
import { ListWrapper } from '@components/organisms/productCandidatesDrawer/fragments/listWrapper';
import { ProductCandidatesList } from '@components/organisms/productCandidatesList/productCandidatesList';

export const ProductCandidates: FC<{
  products?: Product[];
  selectedCandidate?: ProductCandidate;
  primaryCandidate?: ProductCandidate;
  productCandidates?: ProductCandidate[];
  handleProductClick: (productCandidate: ProductCandidate) => void;
  face?: RealogramCandidateFace;
}> = ({
  products,
  selectedCandidate,
  primaryCandidate,
  productCandidates,
  handleProductClick,
  face,
}) => {
  return (
    <ListWrapper title="訂正候補">
      <ProductCandidatesList
        //offset starts from 1 if product is unknown since there no highest score candidate
        offset={face?.is_unknown ? 1 : 2}
        products={products}
        selectedCandidate={selectedCandidate}
        primaryCandidate={primaryCandidate}
        productCandidates={productCandidates?.slice(face?.is_unknown ? 0 : 1)} //NOTE:render all candidates in list if product is unknown
        handleClick={handleProductClick}
        face={face}
      />
    </ListWrapper>
  );
};
