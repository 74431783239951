import {
  changeSidesByFaceFront,
  changeSidesByOrientation,
} from '@utils/planogram';
import { Product, ProductTag } from 'types/common';
import { ProductReport, ProductSalesReport } from 'types/products';
import { PlanogramProductCompartment } from '../types/planogram';
import {
  RealogramCandidateFace,
  RealogramShelfBoard,
} from '../types/realogram';
import { productTags } from './const';

export const itemsPerPage = 30;

const findProduct = (productId: number, products: Product[]) =>
  products.find((product) => product.id === productId);

export const getProductNameById = (
  productId: number,
  products: Product[] = [],
  defaultName = '名称不明'
): string => findProduct(productId, products)?.name ?? defaultName;

export const getEanCode = (
  productId: number,
  products: Product[] = [],
  defaultName = '-'
): string => findProduct(productId, products)?.detail?.ean_code ?? defaultName;

export const isValidProductTag = (tag: string): tag is ProductTag =>
  (productTags as readonly string[]).includes(tag);

/**
 * Convert values whose value display changes when the product is missing or unknown
 */
export const getProductDisplayValues = (
  face?: RealogramCandidateFace,
  product?: Product
): { name: string; eanCode: string; productCode: string } => {
  if (!face && !product) {
    return {
      name: '-',
      eanCode: '',
      productCode: '',
    };
  }
  if (face && !face.in_stock && !face.primary_candidate) {
    return { name: '欠品', eanCode: '', productCode: '' };
  }
  if (face && face?.is_unknown) {
    return { name: '不明な商品', eanCode: '', productCode: '' };
  }
  return {
    // '-' when fetch failed
    name: product?.name ?? '-',
    eanCode: product?.detail?.ean_code ?? '',
    productCode: product?.detail?.organization_product_id ?? '',
  };
};

export const isInferredAsProduct = (face?: RealogramCandidateFace): boolean =>
  !face?.is_unknown &&
  (!!face?.in_stock || (!face?.in_stock && !!face?.primary_candidate));

export const hasProductTag = (tab: ProductTag, tags?: string[]) => {
  switch (true) {
    case tab === 'new_products_first_week':
      return !!tags?.includes('新商品(1週目)');
    case tab === 'new_products_second_week':
      return !!tags?.includes('新商品(2週目)');
    case tab === 'base_product':
      return !!tags?.includes('基本商品');
    case tab === 'sales_ended':
      return !!tags?.includes('推奨取消');
    case tab === 'not_3D_scanned':
      return !!tags?.includes('未3Dスキャン');
    default:
      return false;
  }
};

export const isUnknownProduct = (face?: RealogramCandidateFace): boolean =>
  (!!face?.is_unknown && face.in_stock) ||
  (!!face?.is_unknown && !face.in_stock && !!face.primary_candidate);

export const findSameProductIds = (
  ids1: number[],
  ids2: number[]
): number[] => {
  return ids1.filter((id) => ids2.includes(id));
};

export const calculateReportTotal = (
  key: 'gross_sales_price' | 'sales_count' | 'gross_profit_price',
  reports: ProductSalesReport[],
  productId: number
) => {
  const total = reports.reduce<number | undefined>((acc, report) => {
    const product = report.products.find(
      (product) => product.product_id === productId
    );

    const value = product?.[key];
    return value != null ? (acc ?? 0) + value : acc;
  }, undefined);

  return total;
};

export const maxValueInReport = (
  key: 'gross_sales_price' | 'sales_count' | 'gross_profit_price',
  reports: ProductReport[]
) => {
  const reportValues = reports.map((report) => {
    return report[key] ?? 0;
  });
  return Math.max(...reportValues, 0); // reportValuesに値がなかった場合-Infinityになるのを防ぐ
};

export const getProductSize = (product: Product) =>
  product.shape?.size.display_size ??
  product.shape?.size.actual ?? { width: 0, height: 0, depth: 0 };

export const getProductDisplaySize = (
  product: Product,
  faceFront: PlanogramProductCompartment['face_front'],
  orientation: PlanogramProductCompartment['orientation']
) => {
  const size = getProductSize(product);

  const divideValue = 2;
  return changeSidesByOrientation(
    changeSidesByFaceFront(size, faceFront),
    orientation % divideValue === 1
  );
};

export const columnProductGridInCompareMode = 6;
export const columnProductGridDefault = 4;
export const gridProductSearchDefault = 12;
export const gridProductSearchInCompareMode = 8;

const four = 4;
const three = 3;
const two = 2;

export const getAnalyticsLevel = (value: number, dividedValue: number) => {
  if (value < dividedValue) {
    return '5';
  }
  if (value < dividedValue * two) {
    return '4';
  }
  if (value < dividedValue * three) {
    return '3';
  }
  if (value < dividedValue * four) {
    return '2';
  }
  return '1';
};

export const productDrawerHeight = 92;

export const faceDirection = {
  top: '上面',
  front: '正面',
};

export const findMostHighScore = (product: RealogramCandidateFace) => {
  if (!product.product_candidates) return;
  return product.product_candidates.reduce((maxObj, currentObj) => {
    return currentObj.score > maxObj.score ? currentObj : maxObj;
  }, product.product_candidates[0]);
};
// 不明な商品のfaceのみを抽出
export const getUnknownProductsFromShelfBoards = (
  shelfBoardsToDisplay: RealogramShelfBoard[]
) => {
  return shelfBoardsToDisplay
    .flatMap(({ compartments }) => compartments)
    .flatMap(({ faces }) => faces)
    .filter((item) => isUnknownProduct(item) && !!item.primary_candidate);
};
