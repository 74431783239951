import { InputAdornment, TextField } from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form';

export type ProductSizingFormData = {
  width: number;
  height: number;
  depth: number;
};

type Props<T extends keyof ProductSizingFormData> = {
  error: boolean;
  label: string;
  field: ControllerRenderProps<ProductSizingFormData, T>;
};

// TODO: サイズ調整フォームを共通化。六面体詳細でも同じフォームが使用されている。
export const SizeTextField = <T extends keyof ProductSizingFormData>({
  error,
  label,
  field,
}: Props<T>) => {
  return (
    <TextField
      {...field}
      required
      label={label}
      type="tel"
      sx={{ maxWidth: '100px' }}
      inputMode="numeric"
      InputProps={{
        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
      }}
      inputProps={{
        maxLength: 4,
        min: 0,
      }}
      error={!!error}
      size="small"
    />
  );
};
