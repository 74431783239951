import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, IconButton } from '@mui/material';
import { FC, useState } from 'react';
import {
  DirectoryBreadcrumbs,
  DirectoryType,
  ParentDirectory,
} from 'types/common';
import { AncestorDirectory } from './fragments/ancestorDirectory';
import { CurrentDirectory } from './fragments/currentDirectory';
import { OmitDirectoriesPopover } from './fragments/omitDirectoriesPopover';

type ScreenType = 'Main' | 'Modal';

// Todo: データ構造がわかり次第変更
type BreadcrumbListProps = {
  breadcrumbs: DirectoryBreadcrumbs;
  parentDirectory?: ParentDirectory;
  screenType: ScreenType;
  fontSize?: number | string;
  handleClickBreadcrumbs: (directory: DirectoryType) => void;
  directoryRootName: string;
  modalMaxWidth?: number;
  isCreateDirectory?: boolean;
  createDirectoryCount?: number;
};

const mainMaxDisplayNum = 5;
const mainMaxWidth = 160;

const modalMaxDisplayNum = 5;
// TODO: 抽象化
export const BreadcrumbList: FC<BreadcrumbListProps> = ({
  breadcrumbs,
  parentDirectory,
  screenType,
  fontSize = 'small',
  handleClickBreadcrumbs,
  directoryRootName = '',
  modalMaxWidth = 65,
  isCreateDirectory = false,
  createDirectoryCount = 0,
}) => {
  const displayDirectoryNum =
    screenType === 'Main' ? mainMaxDisplayNum : modalMaxDisplayNum;
  const maxWidth = screenType === 'Main' ? mainMaxWidth : modalMaxWidth;
  const directories = parentDirectory
    ? [...breadcrumbs, parentDirectory]
    : breadcrumbs
    ? breadcrumbs
    : [];
  const maxDisplayDirectories = directories.slice(-displayDirectoryNum);
  const omitDirectories = directories.slice(0, -displayDirectoryNum);
  const newFolders = Array.from(
    { length: createDirectoryCount },
    (_, index) =>
      ({
        name: '新規フォルダ',
        type: 'directory',
        id: `newFolder-${index + 1}`,
      } as DirectoryType)
  );
  const displayDirectories = isCreateDirectory
    ? [...maxDisplayDirectories, ...newFolders]
    : maxDisplayDirectories;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleMoreHorizIconClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isCurrentDirectory = (index: number) => {
    return index === displayDirectories.length - 1;
  };

  const displayName = (directory: DirectoryType) => {
    if (directory.type === 'root') {
      return directoryRootName;
    }
    return directory.name;
  };

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="inherit" />}
        itemsAfterCollapse={displayDirectoryNum}
        aria-label="breadcrumb"
        sx={{
          '& .MuiBreadcrumbs-separator': {
            mx: 0.5,
          },
          '& .MuiBreadcrumbs-ol': {
            flexWrap: 'nowrap',
          },
        }}
      >
        {omitDirectories.length && (
          <IconButton
            component="button"
            onClick={handleMoreHorizIconClick}
            aria-describedby={id}
            sx={{
              padding: 0,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <MoreHorizIcon />
          </IconButton>
        )}
        {displayDirectories.map((displayDirectory, index) =>
          isCurrentDirectory(index) ? (
            <CurrentDirectory
              key={displayDirectory.id}
              directory={displayName(displayDirectory)}
              maxWidth={maxWidth}
              fontSize={fontSize}
            />
          ) : (
            <AncestorDirectory
              key={displayDirectory.id}
              fontSize={fontSize}
              directory={displayName(displayDirectory)}
              maxWidth={maxWidth}
              handleClick={() => handleClickBreadcrumbs(displayDirectory)}
            />
          )
        )}
      </Breadcrumbs>
      {handleClickBreadcrumbs && (
        <OmitDirectoriesPopover
          directories={omitDirectories}
          directoryRootName={directoryRootName}
          anchorEl={anchorEl}
          id={id}
          open={open}
          handleClose={handleClose}
          handleClick={handleClickBreadcrumbs}
        />
      )}
    </>
  );
};
