import { styled, ToggleButtonGroup } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { theme } from 'theme';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ sx }) => ({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  padding: '4px',
  borderRadius: '28px',
  sx,
  '& .MuiToggleButtonGroup-grouped': {
    color: theme.palette.textBlack.primary,
    border: '0 !important',
    height: 32,
    fontWeight: 400,
    borderRadius: '28px !important',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '12px',
    paddingRight: '12px',
    flexWrap: 'wrap',
    whiteSpace: 'nowrap',
    '& svg': {
      color: '#999999',
    },
  },
  '& .Mui-selected': {
    fontWeight: 700,
    backgroundColor: theme.palette.backgroundBlack.active,
    '& svg': {
      color: CommonColors.black,
    },
    '&:hover': {
      backgroundColor: '#EBEBEB !important',
    },
  },
  '& .Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
  },
}));
