import { useLazyGetUsersQuery } from '@reducers/shelfAppsApi';
import { usersLimit } from '@utils/const';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { User } from 'types/user';

export const useUsersApi = () => {
  const [getUsersQuery] = useLazyGetUsersQuery();
  const [users, setUsers] = useState<User[]>();
  // HACK: 推奨しない実装。ローディングのステータスを初期描画で使用したいため作成しました。
  const [loading, setLoading] = useState(true);

  const fetchUsers = useCallback(async () => {
    try {
      const {
        pager: { total = 0 },
        users = [],
      } = await getUsersQuery({ offset: 0, limit: usersLimit }).unwrap();

      //100件以上の場合は、再度リクエストを投げる
      const restRequestTimes = Math.trunc(total / usersLimit);
      const restUsers = await Promise.all(
        _.range(restRequestTimes).map(async (i) => {
          const { users } = await getUsersQuery({
            offset: (i + 1) * usersLimit,
            limit: usersLimit,
          }).unwrap();
          return users ?? [];
        })
      );
      if (restRequestTimes > 0) {
        setUsers([...restUsers.flat()]);
      } else {
        setUsers([...users]);
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [getUsersQuery]);

  useEffect(() => {
    if (!users) {
      void fetchUsers();
    }
  }, [users, fetchUsers]);

  return {
    users,
    userApiLoading: loading,
  };
};
