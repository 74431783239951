import { Draggable } from '@components/organisms/draggable/draggable';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { FC, useRef } from 'react';
import {
  GridItemProps,
  VirtuosoGrid,
  VirtuosoGridHandle,
} from 'react-virtuoso';
import { theme } from 'theme';
import { Product } from 'types/common';
import { ItemTypes } from 'types/rack';
import { ListContainer } from '../planogramsGrid/fragments/listContainer';
import { ProductCard } from '../productCard/productCard';
import { ProductCategory } from 'types/productCategories';

type Props = {
  products?: Product[];
  handleClick: (product: Product) => void;
  isCompareMode?: boolean;
  isLoading: boolean;
  handleEndReached: (index: number) => void;
  isDraggable?: boolean;
  selectedItemId?: number;
  filteredCategories?: ProductCategory[];
  handleCategoryClick?: (category: ProductCategory) => void;
};

export const ProductsGrid: FC<Props> = ({
  products,
  handleClick,
  isCompareMode,
  isLoading,
  handleEndReached,
  isDraggable = true,
  selectedItemId,
  filteredCategories,
  handleCategoryClick,
}) => {
  const ref = useRef<VirtuosoGridHandle>(null);
  return (
    <VirtuosoGrid
      ref={ref}
      data={products}
      style={{ display: 'flex', flexWrap: 'wrap' }}
      endReached={(index) => {
        handleEndReached(index);
      }}
      components={{
        // eslint-disable-next-line @typescript-eslint/naming-convention -- ライブラリの仕様のため許容する
        List: ListContainer,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- ライブラリの仕様のため許容する
        Item: (props: GridItemProps) => (
          <Grid
            container
            p={0.5}
            flex="none"
            alignContent="stretch"
            width={{
              xs: '50%',
              // TODO: リファクタ
              sm: isCompareMode || isDraggable ? '50%' : '33%',
              md: isCompareMode || isDraggable ? '50%' : '33%',
            }}
            display="flex"
            {...props}
          />
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- ライブラリの仕様のため許容する
        Footer: () => {
          if (isLoading)
            return (
              <Box
                component="div"
                sx={{
                  mt: 1,
                  color: '#D9D9D9',
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: theme.palette.white.primary,
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            );
          return (
            <>
              {filteredCategories && (
                <Box component="div" pb={1} overflow="none">
                  <Box
                    component="div"
                    width="100%"
                    pt={2}
                    pb={1}
                    px={1}
                    gap={2}
                    display="flex"
                    color="#757575" // set color directly at this moment
                  >
                    <Typography>カテゴリ</Typography>
                    <Typography>{filteredCategories.length}件</Typography>
                  </Box>
                  <Box
                    component="div"
                    gap={1}
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignContent: 'flex-start',
                    }}
                  >
                    {filteredCategories.map((category) => {
                      return (
                        <Button
                          key={`${category.name}_${
                            category.product_division_code ?? ''
                          }`}
                          component="div"
                          onClick={() =>
                            handleCategoryClick && handleCategoryClick(category)
                          }
                          sx={{
                            color: '#000000',
                            borderColor: '#CCCCCC',
                            whiteSpace: 'nowrap',
                            padding: '4px 10px',
                            fontWeight: 400,
                          }}
                          variant="outlined"
                        >
                          {category.name}
                        </Button>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </>
          );
        },
      }}
      itemContent={(_, item) => {
        if (!item) return;
        return (
          <Grid
            onClick={() => {
              handleClick(item);
            }}
            item
            key={item.id}
            width="100%"
          >
            {isDraggable ? (
              <Draggable data={{ product: item }} itemType={ItemTypes.ITEM}>
                <ProductCard
                  isCompareMode={isCompareMode}
                  product={item}
                  isSelected={item.id === selectedItemId}
                />
              </Draggable>
            ) : (
              <ProductCard
                isCompareMode={isCompareMode}
                product={item}
                isSelected={item.id === selectedItemId}
              />
            )}
          </Grid>
        );
      }}
    />
  );
};
