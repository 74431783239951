import { ModalInnerProps } from '@components/modal';
import { RequiredText } from '@components/molecules/requiredText/requiredText';
import { ModalActions } from '@components/organisms/modalActions/modalActions';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import {
  useGetBayPlanQuery,
  useListBayPlansQuery,
} from '@reducers/shelfAppsApi';
import { bayPlansLimit, isOnlySpaces } from '@utils/const';
import { FC } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { PlanogramFormatType } from 'types/bayPlan';
import { InferType, number, object, string } from 'yup';

const schema = object({
  name: string()
    .required('ゴンドラ名を入力してください。')
    .matches(
      /^(?![\s\u3000]*$).*/,
      '半角スペースまたは全角スペースのみで登録することはできません。'
    ),
  bayPlanId: number().required('ゴンドラテンプレートを選択してください。'),
});
type FormData = InferType<typeof schema>;

export type ClonePlanogramModalProps = {
  name?: string;
  bayPlanId?: number;
  bayPlanCodeId?: number;
  onSubmit: (data: FormData) => void;
  type?: PlanogramFormatType;
};
type Props = ModalInnerProps<ClonePlanogramModalProps>;

export const ClonePlanogramForm: FC<Props> = ({
  handleClose,
  data: { onSubmit, name: defaultName, bayPlanId: defaultBayPlanId, type },
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: defaultName ?? '',
      bayPlanId: defaultBayPlanId,
    },
  });
  const { name, bayPlanId } = useWatch({ control });
  const { data: bayPlan } = useGetBayPlanQuery(
    { bayPlanId: bayPlanId ?? 0 },
    { skip: !!type || !bayPlanId }
  );

  // 一覧から取得した情報はtype情報を持たないため、bayPlanIdからtypeを判定する
  const bayPlanType = () => {
    if (type) return type;
    if (bayPlan?.bay_plan.type === 'buckets') {
      return 'buckets';
    } else if (bayPlan?.bay_plan.type === 'shelves') {
      return 'shelves';
    }
    return undefined;
  };

  const { data: bayPlans } = useListBayPlansQuery({
    offset: 0,
    limit: bayPlansLimit,
    type: bayPlanType(),
  });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      width={400}
      noValidate
    >
      <Stack direction="column" gap={2}>
        <RequiredText />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              fullWidth
              label="ゴンドラ名"
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          )}
        />
        <Controller
          name="bayPlanId"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth error={!!errors.bayPlanId} required>
              <InputLabel>ゴンドラテンプレート</InputLabel>
              <Select label="ゴンドラテンプレート" {...field}>
                {bayPlans?.bay_plans.map((bayPlan) => (
                  <MenuItem key={bayPlan.id} value={bayPlan.id}>
                    {bayPlan.name}
                  </MenuItem>
                ))}
              </Select>
              {!!errors.bayPlanId && (
                <FormHelperText>{errors.bayPlanId.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Stack>
      <ModalActions
        handleClose={handleClose}
        actionBtnTitle="作成"
        disabled={!name || !bayPlanId || isOnlySpaces(name)}
      />
    </Box>
  );
};
