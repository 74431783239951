import { Box, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { convertMeterToMilliMeter } from '@utils/planogram';
import { FC } from 'react';

type Props = {
  value: number;
};

export const XAxis: FC<Props> = ({ value }) => {
  return (
    <Box component="div" textAlign="center">
      <Typography
        sx={{
          fontSize: 12,
          color: CommonColors.grayAxis,
        }}
      >
        {value > 0 && convertMeterToMilliMeter(value)}
      </Typography>
    </Box>
  );
};
