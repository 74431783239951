import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const SpritHorizontally: FC<SvgIconProps> = ({ fill = '', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 13L2 11H4L4 13H2ZM5 13L5 11H7V13H5ZM8 11V13H10V11H8ZM11 13V11H13V13H11ZM14 11V13H16V11H14ZM17 13V11H19V13H17ZM20 11V13H22V11H20ZM5 9L5 1H19V9L5 9ZM5 15V23H19V15H5Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
