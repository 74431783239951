import { useSetInitialPasswordMutation } from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch } from '@store/index';
import { paths, toastMessages } from '@utils/const';
import { useLocation, useNavigate } from 'react-router-dom';

type State = {
  email: string;
  session: string;
};

export const useSetInitPassword = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const state = location.state as State;
  const email = state?.email;
  const session = state?.session;
  const navigate = useNavigate();
  const [
    setNewPassword,
    { isLoading: setNewPasswordLoading, error: setNewPasswordError },
  ] = useSetInitialPasswordMutation();

  const handleSetNewPassword = async (password: string) => {
    try {
      await setNewPassword({
        username: email,
        password,
        challengeName: 'NEW_PASSWORD_REQUIRED',
        session,
      }).unwrap();
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.setNewPassword,
        })
      );
      navigate(paths.home);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    setNewPasswordLoading,
    setNewPasswordError,
    handleSetNewPassword,
  };
};
