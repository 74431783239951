import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StorageHelper } from '@utils/storageHelper';

type SkipFlatRealogramStepThreeState = {
  isSkipFlatRealogramStepThreeOn: boolean;
};

const storageHelper = new StorageHelper();

const initialState: SkipFlatRealogramStepThreeState = {
  isSkipFlatRealogramStepThreeOn:
    storageHelper.get('isSkipFlatRealogramStepThreeOn') === 'true',
};

const skipFlatRealogramStepThreeStateSlice = createSlice({
  name: 'skipFlatRealogramStepThree-Reducer',
  initialState,
  reducers: {
    setSkipFlatRealogramStepThreeState: (
      state: SkipFlatRealogramStepThreeState,
      action: PayloadAction<
        SkipFlatRealogramStepThreeState['isSkipFlatRealogramStepThreeOn']
      >
    ) => {
      storageHelper.set(
        'isSkipFlatRealogramStepThreeOn',
        action.payload.toString()
      );
      state.isSkipFlatRealogramStepThreeOn = action.payload;
    },
    removeSkipFlatRealogramStepThreeState: () => {
      storageHelper.remove('isSkipFlatRealogramStepThreeOn');
    },
  },
});

export const {
  setSkipFlatRealogramStepThreeState,
  removeSkipFlatRealogramStepThreeState,
} = skipFlatRealogramStepThreeStateSlice.actions;
export const SkipFlatRealogramStepThreeReducer =
  skipFlatRealogramStepThreeStateSlice.reducer;
