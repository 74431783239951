import { Paper, SxProps, Theme, Typography } from '@mui/material';
import { emptyText } from '@utils/const';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  title: string;
  text: string;
  paperSx?: SxProps<Theme>;
};
const textMarginBottom = 0.4;
const emptyTextMarginBottom = 0.6;

export const TextPaper: FC<Props> = ({ title, text, paperSx }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: theme.palette.backgroundBlack.primary,
        p: 1,
        minWidth: '80px',
        maxWidth: '144px',
        ...paperSx,
      }}
    >
      <Typography
        variant="body3"
        color={theme.palette.textBlack.secondary}
        lineHeight="12px"
        marginBottom={text ? textMarginBottom : emptyTextMarginBottom}
      >
        {title}
      </Typography>
      {text ? (
        <Typography
          textAlign="end"
          fontSize={18}
          fontWeight={500}
          color={theme.palette.textBlack.primary}
        >
          {text}
        </Typography>
      ) : (
        <Typography
          textAlign="start"
          variant="body1"
          color={theme.palette.textBlack.disabled}
          lineHeight={1.5}
        >
          {emptyText}
        </Typography>
      )}
    </Paper>
  );
};
