import { FilterAlt } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import { PaletteColor } from '@mui/material/styles';
import { FC } from 'react';
import { theme } from 'theme';

type Props = ButtonProps & {
  iconColor?: string | PaletteColor;
  textColor?: string | PaletteColor;
};

export const FilterButton: FC<Props> = ({
  iconColor = theme.palette.icons.primary,
  textColor = theme.palette.textBlack.primary,
  ...props
}) => {
  return (
    <Button
      {...props}
      startIcon={<FilterAlt sx={{ color: iconColor }} />}
      sx={{
        color: textColor,
        fontWeight: 700,
        background: 'transparent',
        '& svg': { background: 'transparent' },
      }}
    >
      絞り込み
    </Button>
  );
};
