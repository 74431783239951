import {
  StopPropagationButton,
  StyledButtonGroup,
} from '@components/organisms';
import { Add, Remove } from '@mui/icons-material';
import { Box, SxProps, Typography } from '@mui/material';
import { maxZoomScale, minZoomScale } from '@utils/const';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  zoomScale: number;
  handleIncrementZoom: () => void;
  handleDecrementZoom: () => void;
  handleTriggerZoom?: () => void;
  orientation?: 'horizontal' | 'vertical';
  sxGroupBtnZoom?: SxProps;
  sxBtnIncrement?: SxProps;
  sxBtnDecrement?: SxProps;
  sxBoxText?: SxProps;
  sxTextPercent?: SxProps;
  isDisabled?: boolean;
};

const percentMultiplier = 100;

export const ZoomController: FC<Props> = ({
  orientation = 'vertical',
  zoomScale,
  handleIncrementZoom,
  handleDecrementZoom,
  handleTriggerZoom,
  sxGroupBtnZoom,
  sxBtnIncrement,
  sxBtnDecrement,
  sxBoxText,
  sxTextPercent,
  isDisabled = false,
}) => {
  const onClickTriggerZoom = () => {
    if (handleTriggerZoom) {
      handleTriggerZoom();
    }
  };
  return (
    <StyledButtonGroup orientation={orientation} sx={sxGroupBtnZoom}>
      <StopPropagationButton
        onClick={handleIncrementZoom}
        //feature of floating point numbers https://floating-point-gui.de/
        disabled={zoomScale.toFixed(1) >= maxZoomScale.toFixed(1) || isDisabled}
        sx={sxBtnIncrement}
      >
        <Add />
      </StopPropagationButton>
      <Box
        component="div"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 14,
          padding: '7px',
          height: 48,
          color: theme.palette.textBlack.primary,
          ...sxBoxText,
        }}
      >
        <Typography
          sx={{
            cursor: !handleTriggerZoom ? 'unset' : 'pointer',
            fontSize: 12,
            ...sxTextPercent,
          }}
          onClick={onClickTriggerZoom}
        >
          {(zoomScale * percentMultiplier).toFixed()}%
        </Typography>
      </Box>
      <StopPropagationButton
        onClick={handleDecrementZoom}
        disabled={zoomScale.toFixed(1) <= minZoomScale.toFixed(1) || isDisabled}
        sx={sxBtnDecrement}
      >
        <Remove />
      </StopPropagationButton>
    </StyledButtonGroup>
  );
};
