import { Box, Paper, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type Props = {
  title: string;
  tenant?: string;
};

export const FormPaper: FC<PropsWithChildren<Props>> = ({
  title,
  tenant,
  children,
}) => {
  return (
    <Paper
      sx={{
        padding: { xs: '40px 16px', sm: '40px 16px', md: '40px 32px' },
        maxWidth: 440,
        boxShadow: { xs: 0, sm: 0, md: 6 },
      }}
    >
      <Box component="div" textAlign="center">
        <Typography
          sx={{ fontSize: 32, fontWeight: 700, mb: tenant ? '16px' : '32px' }}
        >
          {title}
        </Typography>
        {tenant && <Typography sx={{ fontWeight: 700 }}>{tenant}</Typography>}
      </Box>
      {children}
    </Paper>
  );
};
