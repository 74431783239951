import { RealogramFaceImage } from '@components/molecules/realogramFaceImage/realogramFaceImage';
import { Box, Stack, Typography } from '@mui/material';
import { imageBoxSize } from '@utils/const';
import { theme } from 'theme';
import { Product, ShelfDetailView } from 'types/common';
import { RealogramSelectedItem } from 'types/realogram';
import { ReactNode } from 'react';
import { t } from 'i18next';
import { ProductFlagLabel } from '@components/organisms/realogramCandidatesList/fragments';
import { PlanogramProductCandidateOptionalItem } from './planogramProductCandidateOptionalItem';
import { convertSize } from '../utils';

type Props = {
  isBucketType: boolean;
  realogramDetailView: ShelfDetailView;
  product?: Product;
  selectedItem: RealogramSelectedItem;
  name: string;
  isShowJANAndCD: boolean;
  eanCodeValue: string;
  productCodeValue: string;
  isEdited: boolean;
  slideButtons: ReactNode;
  productCode: string;
};

export const HorizontalCandidate = ({
  isBucketType,
  realogramDetailView,
  product,
  selectedItem,
  name,
  isShowJANAndCD,
  eanCodeValue,
  productCodeValue,
  slideButtons,
  productCode,
  isEdited,
}: Props) => {
  return (
    <Box
      component="div"
      display="flex"
      flexGrow="1"
      flexDirection="row"
      position="relative"
      height="100%"
    >
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        mr="16px"
        gap={1}
      >
        <Box component="div" display="flex" justifyContent="flex-start" gap={1}>
          <Box
            component="div"
            sx={{
              position: 'relative',
              width: 120,
              height: 120,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              component="div"
              width={imageBoxSize}
              height={imageBoxSize}
              position="relative"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <RealogramFaceImage
                product={product}
                size={imageBoxSize}
                face={selectedItem.item}
                baseSize={imageBoxSize}
                fontSize={24}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Stack
        component="div"
        my={isBucketType ? 0 : { xs: 1, breakpoint: 0 }}
        mx={isBucketType ? 0 : { xs: 1, breakpoint: 0 }}
        width="100%"
      >
        {realogramDetailView === 'default' &&
          productCode &&
          product?.detail?.tags?.includes(t('sales_ended')) && (
            <Box component="div" sx={{ display: 'inline-flex', pb: 0.5 }}>
              <ProductFlagLabel name={t('sales_ended')} />
            </Box>
          )}
        <Box
          component="div"
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '4px',
          }}
        >
          <Typography fontWeight={500} lineHeight="24px">
            {name}
          </Typography>
        </Box>
        {realogramDetailView === 'default' && (
          <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
            {convertSize(product)}
            {isEdited && (
              <Box component="span" color={theme.palette.textBlack.label}>
                （編集済み）
              </Box>
            )}
          </Typography>
        )}
        {isShowJANAndCD && (
          <Box
            component="div"
            sx={{
              display: 'flex',
              columnGap: 5,
            }}
            mt={1}
          >
            <Typography
              sx={{
                fontSize: 12,
                color: theme.palette.textBlack.secondary,
              }}
            >
              JAN：{eanCodeValue}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: theme.palette.textBlack.secondary,
              }}
            >
              商品CD：{productCodeValue}
            </Typography>
          </Box>
        )}
        <PlanogramProductCandidateOptionalItem
          detailView={realogramDetailView}
          product={product}
        />
      </Stack>
      {slideButtons}
    </Box>
  );
};
