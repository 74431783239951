import { ListSubheader } from '@components/molecules/listSubheader/listSubheader';
import { Box, List, Typography } from '@mui/material';
import {
  profitTabGrossProfit,
  profitTabQuantity,
  profitTabSales,
} from '@utils/const';
import { MutableRefs, isCompartmentMode } from '@utils/realogram';
import { FC, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { Product, ProfitTab, ShelfDetailView } from 'types/common';
import {
  AnalyticsProductData,
  RealogramCandidateFace,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';
import { ShelfListHeader } from '../shelfListHeader/shelfListHeader';
import { EmptyCompartment, ListHeaderOptionalItem, ListRow } from './fragments';
import { theme } from 'theme';

type Props = {
  refs: MutableRefs;
  products?: Product[];
  shelfBoards?: RealogramShelfBoard[];
  realogramDetailView: ShelfDetailView;
  selectedItem?: RealogramSelectedItem;
  handleClick: (item: RealogramSelectedItem) => void;
  handleClickDeleteShelfBoard: (shelfBoardId: string) => void;
  handleCorrectDrawerOpen: (item: RealogramSelectedItem) => void;
  handleDivisionModalOpen: (face: RealogramCandidateFace) => void;
  handleClickDeleteFace: (item: RealogramSelectedItem) => void;
  profitTab: ProfitTab;
  productsSalesReport?: AnalyticsProductData[];
  handleSort: () => void;
  isAscending?: boolean;
  isCanEditFace?: boolean;
  isCanDeleteFace?: boolean;
  isCanDeleteBaypart?: boolean;
  isBucketType?: boolean;
  handleAddFaceMode: (shelfBoardIndex: number) => void;
};

export const widthList = {
  eanCodeWidth: 137,
  scoreWidth: 89,
  menuWidth: 64,
  face: 73,
  profit: 103,
};
const defaultSortValue = 1;

export const RealogramCandidatesList: FC<Props> = ({
  refs,
  products,
  shelfBoards,
  selectedItem,
  realogramDetailView,
  handleClick,
  handleClickDeleteShelfBoard,
  handleClickDeleteFace,
  handleCorrectDrawerOpen,
  handleDivisionModalOpen,
  profitTab,
  productsSalesReport,
  handleSort,
  isAscending = false,
  isCanEditFace = false,
  isCanDeleteFace = false,
  isCanDeleteBaypart = false,
  isBucketType = false,
  handleAddFaceMode,
}) => {
  const { pathname } = useLocation();
  const key = getProfitValueKey(profitTab);
  const sortedReports = productsSalesReport
    ?.filter((el) => {
      if (!key) return false;
      return !!el[key];
    })
    .sort((a, b) => {
      // return defaultSortValue so it doesn't change order
      if (!key) return defaultSortValue;
      const valueA = a[key] ?? 0;
      const valueB = b[key] ?? 0;
      return valueA - valueB;
    })
    .map((el) => el.product_id);
  const flatten = shelfBoards?.flatMap((shelf) => {
    return shelf.compartments.flatMap((el, compartmentIndex) => {
      return {
        ...el,
        compartmentId: el.id,
        compartmentIndex,
        shelfBoardId: shelf.id,
      };
    });
  });

  const reorderedShelfboards = flatten?.slice().sort((a, b) => {
    const indexA =
      sortedReports?.indexOf(a.faces[0].primary_candidate?.product_id ?? 0) ??
      0;
    const indexB =
      sortedReports?.indexOf(b.faces[0].primary_candidate?.product_id ?? 0) ??
      0;
    return isAscending ? indexA - indexB : indexB - indexA;
  });
  const subHeaderTitle = isBucketType ? 'カゴ目' : '段目';
  return (
    <>
      <ShelfListHeader>
        <ListHeaderOptionalItem
          type={realogramDetailView}
          profitTab={profitTab}
          handleClick={handleSort}
          isAscending={isAscending}
        />
      </ShelfListHeader>
      <List
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
        subheader={<Box component="li" />}
      >
        {shelfBoards && !shelfBoards.length && (
          <Typography sx={{ color: '#999999', mt: 5, textAlign: 'center' }}>
            該当する区画がありません
          </Typography>
        )}

        {realogramDetailView === 'profit' ? (
          <>
            {reorderedShelfboards?.map(
              ({ shelfBoardId, faces, compartmentId }, index) => {
                return (
                  <Box
                    component="ul"
                    key={`shelf-board-${index}`}
                    sx={{ padding: 0 }}
                  >
                    {faces.map((face, faceIndex) => {
                      if (
                        isCompartmentMode(realogramDetailView, pathname) &&
                        faceIndex > 0
                      ) {
                        //show each compartment
                        return <Fragment key={face.id} />;
                      }
                      const realogramItem: RealogramSelectedItem = {
                        shelfBoardId,
                        compartmentId,
                        item: face,
                      };
                      const productReport = productsSalesReport?.find(
                        (product) =>
                          product.product_id ===
                          face.primary_candidate?.product_id
                      );
                      return (
                        <ListRow
                          key={face.id}
                          realogramItem={realogramItem}
                          faces={faces}
                          products={products}
                          selectedItem={selectedItem}
                          handleClick={handleClick}
                          refs={refs}
                          handleClickDeleteFace={handleClickDeleteFace}
                          realogramDetailView={realogramDetailView}
                          handleCorrectDrawerOpen={handleCorrectDrawerOpen}
                          profitTab={profitTab}
                          productReport={productReport}
                          handleDivisionModalOpen={handleDivisionModalOpen}
                          isCanEditFace={isCanEditFace}
                        />
                      );
                    })}
                  </Box>
                );
              }
            )}
          </>
        ) : (
          <>
            {shelfBoards?.map(
              ({ id: shelfBoardId, compartments }, shelfBoardIndex) => {
                return (
                  <Box
                    component="ul"
                    key={`shelf-board-${shelfBoardIndex}`}
                    sx={{ padding: 0 }}
                  >
                    {realogramDetailView === 'default' && (
                      <ListSubheader
                        title={`${shelfBoardIndex + 1}${subHeaderTitle}`}
                        backgroundColor={theme.palette.backgroundBlack.active}
                        color={theme.palette.textBlack.primary}
                        sx={{
                          fontWeight: 400,
                          height: 30,
                          justifyContent: 'space-between',
                        }}
                        isRealogram
                        handleClick={() => handleAddFaceMode(shelfBoardIndex)}
                        isCanEditFace={isCanEditFace}
                      />
                    )}
                    {compartments.length === 0 &&
                    realogramDetailView === 'default' ? (
                      <EmptyCompartment
                        isCanDeleteBaypart={isCanDeleteBaypart}
                        handleClickDeleteShelfBoard={() =>
                          handleClickDeleteShelfBoard(shelfBoardId)
                        }
                        emptyText={
                          isBucketType
                            ? 'カゴに配置されている商品はありません'
                            : '棚に配置されている商品はありません'
                        }
                      />
                    ) : (
                      compartments.map(({ id: compartmentId, faces }) => {
                        return faces.map((face, faceIndex) => {
                          if (
                            isCompartmentMode(realogramDetailView, pathname) &&
                            faceIndex > 0
                          ) {
                            //show each compartment
                            return <Fragment key={face.id} />;
                          }
                          const realogramItem: RealogramSelectedItem = {
                            shelfBoardId,
                            compartmentId,
                            item: face,
                          };
                          const productReport = productsSalesReport?.find(
                            (product) =>
                              product.product_id ===
                              face.primary_candidate?.product_id
                          );
                          return (
                            <ListRow
                              key={face.id}
                              realogramItem={realogramItem}
                              faces={faces}
                              products={products}
                              selectedItem={selectedItem}
                              handleClick={handleClick}
                              refs={refs}
                              handleClickDeleteFace={handleClickDeleteFace}
                              realogramDetailView={realogramDetailView}
                              handleCorrectDrawerOpen={handleCorrectDrawerOpen}
                              profitTab={profitTab}
                              productReport={productReport}
                              handleDivisionModalOpen={handleDivisionModalOpen}
                              isCanEditFace={isCanEditFace}
                              isCanDeleteFace={isCanDeleteFace}
                            />
                          );
                        });
                      })
                    )}
                  </Box>
                );
              }
            )}{' '}
          </>
        )}
      </List>
    </>
  );
};

const getProfitValueKey = (profitTab: ProfitTab) => {
  switch (profitTab) {
    case profitTabSales:
      return 'gross_sales_price';
    case profitTabQuantity:
      return 'sales_count';
    case profitTabGrossProfit:
      return 'gross_profit_price';
    default:
      return undefined;
  }
};
