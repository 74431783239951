import { ScoreHighChip } from '@components/molecules/scoreHighChip/scoreHighChip';
import { ScoreLowChip } from '@components/molecules/scoreLowChip/scoreLowChip';
import { ScoreMiddleChip } from '@components/molecules/scoreMiddleChip/scoreMiddleChip';
import { FC } from 'react';
import { ConfidenceLevel } from 'types/realogram';

type Props = { confidenceLevel: ConfidenceLevel };

export const RealogramScoreChip: FC<Props> = ({ confidenceLevel }) => {
  if (confidenceLevel === 'high') return <ScoreHighChip />;
  if (confidenceLevel === 'medium') return <ScoreMiddleChip />;
  return <ScoreLowChip />;
};
