import { SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetParams } from '@api/types/generated';
import { realogramsApi } from '@api/services/realogramDirectories';
import { getNextPage, mapper } from '@api/utils/pagenation';
import { useInfiniteQuery } from '@tanstack/react-query';

export const realogramDirectorySearchQueryKey = 'search_realograms';

type Params =
  SearchRealogramDirectoryApiV1RealogramDirectoriesSearchGetParams & {
    enabled?: boolean;
  };

export const useRealogramDirectoriesSearch = ({
  enabled = false,
  ...params
}: Params) => {
  return useInfiniteQuery({
    enabled,
    queryKey: [realogramDirectorySearchQueryKey, params],
    queryFn: ({ pageParam }) => {
      return realogramsApi.getSearchRealogramDirectories({
        ...params,
        offset: pageParam,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => getNextPage(lastPage.pager),
    select: (data) => mapper(data?.pages, 'realogram_directories'),
    refetchOnWindowFocus: false,
  });
};
