import { FC } from 'react';

import { ListItem, ListItemButton, ListItemText } from '@mui/material';

import { ArrowForwardIos } from '@mui/icons-material';

type Props = {
  name: string;
  isSelected: boolean;
  hasArrowForward: boolean;
  onClick: () => void;
};

export const ListItemWithArrowForward: FC<Props> = ({
  name,
  isSelected,
  hasArrowForward,
  onClick,
}) => {
  return (
    <ListItem
      secondaryAction={
        hasArrowForward ? (
          <ArrowForwardIos sx={{ width: 12, height: 12 }} />
        ) : (
          <></>
        )
      }
      disablePadding
    >
      <ListItemButton divider selected={isSelected} onClick={onClick}>
        <ListItemText primary={name} />
      </ListItemButton>
    </ListItem>
  );
};
