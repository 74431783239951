import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const BowlFrontIcon: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 80 80"
      sx={{ height: props.height, width: props.width }}
      fill="none"
    >
      <path
        d="M5 29H75L66.2321 46.7699C65.5583 48.1355 64.1677 49 62.645 49H17.355C15.8323 49 14.4417 48.1355 13.7679 46.7699L5 29Z"
        fill="white"
      />
      <rect x="2" y="27" width="76" height="2" rx="1" fill="white" />
      <path
        d="M32 29H48L45.3271 32.408C44.0437 34.0443 42.0795 35 40 35C37.9205 35 35.9563 34.0443 34.673 32.408L32 29Z"
        fill="#E6E6E6"
      />
    </SvgIcon>
  );
};
