import { paths } from 'types/api';
import { baseApi } from '../baseApi';

type GetBayPartCategoriesResponse =
  paths['/api/v1/bay_part_categories']['get']['responses']['200']['content']['application/json'];

type GetBayPartResponse =
  paths['/api/v1/bay_parts/{bay_part_id}']['get']['responses']['200']['content']['application/json'];
type GetBayPartParams =
  paths['/api/v1/bay_parts/{bay_part_id}']['get']['parameters']['path'];

export const bayPartCategoriesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    listBayPartCategories: builder.query<GetBayPartCategoriesResponse, void>({
      query: () => ({
        url: 'bay_part_categories',
      }),
    }),
    getBayPart: builder.query<GetBayPartResponse, GetBayPartParams>({
      query: (params) => ({
        url: `bay_parts/${params.bay_part_id}`,
      }),
    }),
  }),
});

export const {
  useListBayPartCategoriesQuery,
  useGetBayPartQuery,
  useLazyGetBayPartQuery,
} = bayPartCategoriesApi;
