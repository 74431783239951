import { ErrorMessage, FullHeightBox } from '@components/molecules';
import { FormPaper } from '@components/molecules/formPaper/formPaper';
import { usePageTitle } from '@hooks/usePageTitle';
import { useSignInWithCode } from '@hooks/useSignInWithCode';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Link, Typography } from '@mui/material';
import { selectIsAuthenticated } from '@reducers/auth/selectors';
import { useSignInMutation } from '@reducers/shelfAppsApi';
import { useAppSelector } from '@store/index';
import { paths, tenantName } from '@utils/const';
import { featureFlags } from '@utils/featureFlags';
import { FC, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import { InferType } from 'yup';
import { EmailPasswordLogin } from './fragments/emailPasswordLogin';
import { MfaInputForm } from './fragments/mfaInputForm';
import { SsoLogin } from './fragments/ssoLogin';
import { schema } from './login.schema';
import { StorageHelper } from '@utils/storageHelper';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import httpStatus from 'http-status';

export type FormData = InferType<typeof schema>;
export type EmailPasswordFormData = Omit<FormData, 'privacyPolicy'>;

export const Login: FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [session, setSession] = useState<{ session: string; email: string }>();
  const [loginFailed, setLoginFailed] = useState(false);
  const [signIn, { error, isLoading }] = useSignInMutation();
  const {
    signInUrl,
    isLoading: isSignInUrlLoading,
    error: signInError,
  } = useSignInWithCode();

  const [selectEmailLogin, setSelectEmailLogin] = useState(false);
  const isSsoLogin = signInUrl ? true : false;
  const handleChangeSelectEmailLogin = () => {
    setSelectEmailLogin(!selectEmailLogin);
  };
  const storageHelper = new StorageHelper();
  const path = storageHelper.get('path') ?? '';
  const embed = storageHelper.get('embed') ?? '';
  const params = new URLSearchParams(window.location.search);
  const state = decodeURIComponent(params.get('state') ?? '');

  const handleEmailPasswordLogin: SubmitHandler<
    EmailPasswordFormData
  > = async ({ email, password }) => {
    setLoginFailed(false);
    try {
      const result = await signIn({
        username: email,
        password,
        authFlow: 'USER_PASSWORD_AUTH',
      }).unwrap();
      if ('challenge_name' in result) {
        setSession({
          session: result.session ?? '',
          email,
        });
        if (result.challenge_name === 'NEW_PASSWORD_REQUIRED') {
          navigate(paths.password.passwordInit, {
            state: { session: result.session ?? '', email },
          });
        }
        return;
      }
      navigate(paths.home);
    } catch (err) {
      console.log({ err });
      setLoginFailed(true);
    }
  };

  usePageTitle('ログイン');

  if (isAuthenticated) {
    return state ? (
      <Navigate to={state + '?embed=' + embed} />
    ) : (
      <Navigate to={paths.home} />
    );
  }

  if (isSignInUrlLoading) {
    // todo: loadingの表示を検討する
    return <></>;
  }

  // Todo: リファクタ
  const renderLoginCompornent = () => {
    if (signInUrl && !selectEmailLogin) {
      return (
        <SsoLogin
          signInUrl={
            signInUrl + (embed ? `&state=${encodeURIComponent(path)}` : '')
          }
          handleChangeSelectEmailLogin={handleChangeSelectEmailLogin}
        />
      );
    }
    if (featureFlags.emailPasswordLogin) {
      return (
        <EmailPasswordLogin
          isLoading={isLoading}
          handleLogin={handleEmailPasswordLogin}
          isSsoLogin={isSsoLogin}
          handleChangeSelectEmailLogin={handleChangeSelectEmailLogin}
        />
      );
    }
    return (
      <Box
        component="div"
        fontSize="14px"
        mt={2}
        p={2}
        borderRadius={0.5}
        bgcolor="#ffe5e5"
        display="flex"
        alignItems="center"
      >
        <ErrorOutlineIcon color="error" />
        <Typography component="div" ml={2}>
          問題が発生しました。
          <br />
          管理者にお問い合わせください。
        </Typography>
      </Box>
    );
  };

  if (session) {
    return (
      <FullHeightBox sx={{ height: { xs: 'auto', sm: 'auto', md: '100vh' } }}>
        <MfaInputForm
          session={session}
          navigateLogin={() => setSession(undefined)}
        />
      </FullHeightBox>
    );
  }

  return (
    <FullHeightBox
      display="flex"
      flexDirection="column"
      gap={3}
      sx={{ height: { xs: 'auto', sm: 'auto', md: '100vh' } }}
    >
      <FormPaper title="ログイン" tenant={tenantName}>
        {loginFailed && error && (
          <ErrorMessage message={toLoginErrorMessage(error)} />
        )}
        {signInError && (
          <ErrorMessage message="SSOログインできませんでした。管理者にお問い合わせください" />
        )}
        {renderLoginCompornent()}
      </FormPaper>
      <Box component="div" display="flex" justifyContent="center" gap={3}>
        <Typography>
          <Link
            href={process.env.NEXT_PUBLIC_FEEDBACK_FORM_URL}
            target="_blank"
          >
            お問い合わせ
          </Link>
        </Typography>
        <Typography>
          <Link href="licenses.txt" target="_blank">
            オープンソースライセンス
          </Link>
        </Typography>
      </Box>
    </FullHeightBox>
  );
};

const toLoginErrorMessage = (error: FetchBaseQueryError | SerializedError) => {
  switch ('status' in error && error.status) {
    case httpStatus.TOO_MANY_REQUESTS:
      return '試行制限を超過しました。時間をおいてもう一度お試しください。';
    default:
      return 'ログイン情報が間違っています。正しい情報を入力してください。';
  }
};
