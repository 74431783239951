import { useListBayPlanCodesQuery } from '@reducers/shelfAppsApi/injections/bayPlanCodesApi';

export const useBayPlanCodes = (id?: number) => {
  const { data, isLoading } = useListBayPlanCodesQuery();
  const bayPlanCode = data?.bay_plan_codes.find(
    (bayPlanCode) => bayPlanCode.id === id
  );
  return {
    bayPlanCode,
    bayPlanCodes: data?.bay_plan_codes,
    bayPlanCodeLoading: isLoading,
  };
};
