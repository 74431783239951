import { BoxWidthDivider, ProfitHeaderOptional } from '@components/organisms';
import { Typography } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { FC } from 'react';
import { widthList } from '@utils/const';
import { ProfitTab } from 'types/common';

type Props = { profitTab: ProfitTab };
export const HeaderOptional: FC<Props> = ({ profitTab }) => {
  const { detailView } = useAppSelector(selectPlanogramEditorState);
  switch (detailView) {
    case 'default':
      return (
        <BoxWidthDivider width={widthList.face}>
          <Typography variant="body2">フェイス</Typography>
        </BoxWidthDivider>
      );
    case 'profit':
      // TODO: add handleClick and isAscending
      return (
        <ProfitHeaderOptional
          profitTab={profitTab}
          handleClick={() => void 1}
          isAscending={false}
        />
      );
    default:
      return <></>;
  }
};
