import { OffsetPager } from '@api/types/generated';
import { rowsPerPage } from '@utils/const';

export const getNextPage = (pager?: OffsetPager) => {
  const limit = pager?.limit ?? 0;
  const total = pager?.total ?? 0;
  const offset = pager?.offset ?? 0;
  if (offset === total || limit >= total) return undefined;
  return offset + rowsPerPage;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- mapper Record
export const mapper = <T extends Record<string, any>, K extends keyof T>(
  pages: T[],
  key: K
): T[K] => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- using any
  return pages.reduce((acc, curr) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- using any
    acc.push(...curr[key]);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- using any
    return acc;
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter -- using any
  }, [] as T[K]);
};
