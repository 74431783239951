import { FC } from 'react';
import { ProcessingChip } from '@components/molecules/processingChip/processingChip';
import { ProcessedIcon } from '@components/molecules/processedIcon/processedIcon';
import { ErrorChip } from '@components/molecules/errorChip/errorChip';
import { Status } from '../../../types/common';

type Props = {
  prependedLabel?: string;
  status: Status;
};

export const StatusIcon: FC<Props> = ({ status, prependedLabel }) => {
  switch (status) {
    case 'initialized':
      return <ProcessingChip prependedLabel={prependedLabel} />;
    case 'processing':
      return <ProcessingChip prependedLabel={prependedLabel} />;
    case 'processed':
      return <ProcessedIcon />;
    case 'error':
      return <ErrorChip prependedLabel={prependedLabel} />;
    default:
      return <></>;
  }
};
