import {
  Box,
  Checkbox,
  FormControlLabel,
  List,
  Typography,
} from '@mui/material';
import { UnknownProduct } from 'types/realogram';
import { UnknownProductReviseCard } from './unknownProductReviseCard';
import { theme } from 'theme';

type Props = {
  realogramImageElement: HTMLImageElement | null;
  imageIsLoading: boolean;
  isCheckedRecommendedRevise: boolean;
  unknownProductsInfo: UnknownProduct[];
  hasRecommendProducts: boolean;
  handleCheckboxChange: (id: string) => void;
  handleToggleRecommendedRevise: () => void;
};
export const UnknownProductList = ({
  realogramImageElement,
  imageIsLoading,
  isCheckedRecommendedRevise,
  unknownProductsInfo,
  hasRecommendProducts,
  handleCheckboxChange,
  handleToggleRecommendedRevise,
}: Props) => {
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      p={2}
      gap={1}
      height="100%"
      overflow="hidden"
    >
      <Box component="div" height="100%" overflow="hidden">
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          alignItems="center"
          height="40px"
          px={1}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isCheckedRecommendedRevise}
                onChange={handleToggleRecommendedRevise}
                disabled={!hasRecommendProducts}
              />
            }
            label={
              <Typography
                variant="body1"
                color={
                  hasRecommendProducts
                    ? ''
                    : `${theme.palette.textBlack.disabled}`
                }
              >
                「訂正推奨」をすべて選択
              </Typography>
            }
          />
        </Box>

        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            pb: '50px',
          }}
        >
          {unknownProductsInfo.map((product) => (
            <UnknownProductReviseCard
              key={product.id}
              unknownProduct={product}
              realogramImageElement={realogramImageElement}
              imageIsLoading={imageIsLoading}
              handleCheckboxChange={handleCheckboxChange}
            />
          ))}
        </List>
      </Box>
    </Box>
  );
};
