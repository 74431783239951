import { setDemoState } from '@reducers/demo';
import { selectIsDemoOn } from '@reducers/demo/selector';
import { useAppDispatch, useAppSelector } from '@store/index';

export const useDemoToggleChange = () => {
  const dispatch = useAppDispatch();
  const isDemoOn = useAppSelector(selectIsDemoOn);
  const handleDemoToggleChange = () => dispatch(setDemoState(!isDemoOn));

  return {
    isDemoOn,
    handleDemoToggleChange,
  };
};
