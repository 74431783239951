import { BreadcrumbForSearch } from '@components/molecules/breadcrumbForSearch/breadcrumbForSearch';
import { BreadcrumbList } from '@components/molecules/breadcrumbList/breadcrumbList';
import { FileIcon } from '@components/molecules/fileIcon/fileIcon';
import { FolderIcon } from '@components/molecules/folderIcon/folderIcon';
import { SelectionModalResult } from '@components/molecules/selectionModalResult/selectionModalResult';
import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { useComparisonListRendering } from '@hooks/useComparisonListRendering';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, ListItemButton, ListItemText, Typography } from '@mui/material';
import { updateSelectedItemId } from '@reducers/comparisonItemModal';
import { selectComparisonItemModal } from '@reducers/comparisonItemModal/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { planogramSearchLimit } from '@utils/const';
import { FC } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { theme } from 'theme';
import { DirectoryType } from 'types/common';
import {
  PlanogramDirectory,
  PlanogramDirectoryBreadCrumbs,
  PlanogramDirectoryItem,
  PlanogramDirectoryParent,
  PlanogramDirectorySearch,
} from 'types/planogram';

type Props = {
  tabValue: number;
  isLoading: boolean;
  isFetching: boolean;
  searchedTotal: number;
  planogramOffset: number;
  planogramDirectories?: PlanogramDirectory[] | PlanogramDirectorySearch[];
  planogramBreadcrumbs?: PlanogramDirectoryBreadCrumbs;
  planogramDirectoryParent?: PlanogramDirectoryParent;
  originPlanogramId?: number;
  userId?: number;
  textSearch: string;
  scrollPos: number;
  isSelectedItemInDirectory: boolean;
  setPlanogramDirectoryId: (value: string) => void;
  setSelectedItem: (value: DirectoryType | undefined) => void;
  handleClickBreadcrumbs: (item: DirectoryType) => void;
  setSideBarValue: (value: string) => void;
  setSearch: (value: string) => void;
  handleScrollerRef: (ref: HTMLElement | Window | null) => void;
  setPlanogramOffset: (value: number) => void;
};

export const ComparisonPlanograms: FC<Props> = ({
  tabValue,
  isLoading,
  isFetching,
  searchedTotal,
  planogramOffset,
  planogramDirectories,
  planogramBreadcrumbs,
  planogramDirectoryParent,
  originPlanogramId,
  userId,
  textSearch,
  scrollPos,
  isSelectedItemInDirectory,
  setPlanogramDirectoryId,
  setSelectedItem,
  handleClickBreadcrumbs,
  setSideBarValue,
  setSearch,
  handleScrollerRef,
  setPlanogramOffset,
}) => {
  const dispatch = useAppDispatch();
  const { selectedItemId, currentTab } = useAppSelector(
    selectComparisonItemModal
  );
  const {
    isLoadingFirstPage,
    isShowList,
    selectedItemIndex,
    isLoadingSelectedItem,
    getNextPageData,
  } = useComparisonListRendering({
    isLoadingOrFetching: isFetching || isLoading,
    data: {
      items: planogramDirectories || [],
      selectedItemId,
    },
    pagination: {
      offset: planogramOffset,
      setOffset: setPlanogramOffset,
      limit: planogramSearchLimit,
      total: searchedTotal,
    },
    options: {
      iForceHideLoadingSelectedItem: !isSelectedItemInDirectory,
      getId: (item) => (item as PlanogramDirectoryItem).planogram_id,
    },
  });

  const handleListItemClick = (item: PlanogramDirectory) => {
    if (item.type === 'directory') {
      setSearch('');
      setPlanogramOffset(0);
      setSideBarValue('all');
      setPlanogramDirectoryId(item.id);
    } else if (item.type === 'file') {
      if (selectedItemId === item.planogram_id) return;
      dispatch(updateSelectedItemId(item.planogram_id));
      setSelectedItem(item);
    }
  };

  return (
    <>
      <Box component="div" flex={1}>
        {isShowList ? (
          <Virtuoso
            scrollerRef={handleScrollerRef}
            data={planogramDirectories}
            initialTopMostItemIndex={{
              index: selectedItemIndex || 0,
              align: 'center',
            }}
            initialScrollTop={scrollPos}
            firstItemIndex={20}
            endReached={getNextPageData}
            components={{
              // eslint-disable-next-line @typescript-eslint/naming-convention -- camelCaseにできない
              Footer: () => {
                if (isFetching || isLoading)
                  return (
                    <Box
                      component="div"
                      sx={{
                        color: '#D9D9D9',
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.white.primary,
                      }}
                    >
                      <SelectionModalResult
                        isLoading={isLoading || isFetching}
                        textSearch={textSearch}
                        data={planogramDirectories}
                      />
                    </Box>
                  );
                return <></>;
              },
            }}
            itemContent={(_, directory) => {
              const { name, type, planogram_id: planogramId } = directory;
              const isSelected =
                selectedItemId === planogramId && tabValue === currentTab;
              const isFavorite = directory.favorite?.owner_id === userId;
              return (
                <ListItemButton
                  component="div"
                  disabled={originPlanogramId === planogramId}
                  sx={{
                    height: '56px',
                    py: 0.5,
                    px: 2,
                    borderBottom: `1px solid ${theme.palette.dividerBlack.light}`,
                    display: 'flex',
                    backgroundColor: isSelected ? '#E5F0FF' : '',
                    '&:hover': {
                      backgroundColor: isSelected ? '#E5F0FF' : '',
                    },
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => handleListItemClick(directory)}
                >
                  <ListItemText
                    sx={{
                      overflow: 'hidden',
                      flex: 'unset',
                      '& .MuiListItemText-primary': {
                        display: 'block',
                      },
                    }}
                    primary={
                      <Box
                        component="div"
                        display="flex"
                        gap={1}
                        alignItems="center"
                      >
                        {type === 'directory' ? <FolderIcon /> : <FileIcon />}
                        <Box component="div">
                          <Box
                            component="div"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                overflow: 'hidden',
                              }}
                            >
                              {name}
                            </Typography>
                            {isFavorite && (
                              <StarIcon
                                htmlColor={theme.palette.confidence.middle}
                                sx={{
                                  marginLeft: '4px',
                                  width: '16px',
                                  height: '16px',
                                  verticalAlign: 'text-bottom',
                                  marginRight: 'auto',
                                }}
                              />
                            )}
                          </Box>
                          {(directory as PlanogramDirectorySearch)
                            .breadcrumb && (
                            <BreadcrumbForSearch
                              breadcrumbs={
                                (directory as PlanogramDirectorySearch)
                                  .breadcrumb
                              }
                              screenType="Main"
                              fontSize="12px"
                            />
                          )}
                        </Box>
                      </Box>
                    }
                    primaryTypographyProps={{
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  />

                  {type === 'directory' && (
                    <ChevronRightIcon
                      htmlColor={theme.palette.icons.secondary}
                      sx={{ marginLeft: 'auto' }}
                    />
                  )}
                  {isSelected && type === 'file' && (
                    <CheckCircleIcon
                      fontSize="small"
                      htmlColor="#0A40CA"
                      sx={{ alignItems: 'center' }}
                    />
                  )}
                </ListItemButton>
              );
            }}
          />
        ) : (
          <Box
            component="div"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="#BDBDBD"
          >
            <SelectionModalResult
              isLoading={isLoadingFirstPage || isLoadingSelectedItem}
              textSearch={textSearch}
              data={planogramDirectories}
              emptyResultBoxTitle="棚割・フォルダがありません"
            />
          </Box>
        )}
      </Box>
      {!!planogramBreadcrumbs?.length &&
        planogramDirectoryParent &&
        !textSearch && (
          <Box
            component="div"
            padding="8px 16px"
            borderTop={`1px solid ${theme.palette.dividerBlack.medium}`}
          >
            <BreadcrumbList
              breadcrumbs={planogramBreadcrumbs}
              parentDirectory={planogramDirectoryParent}
              screenType="Modal"
              fontSize="12px"
              directoryRootName="棚割計画"
              modalMaxWidth={80}
              handleClickBreadcrumbs={handleClickBreadcrumbs}
            />
          </Box>
        )}
    </>
  );
};
