import { App } from '@components/app/app';
import { initialize } from '@reducers/auth';
import { initDatadogRum } from '@utils/datadog';
import { useEffect } from 'react';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { initI18next } from '@utils/i18next';

export const AppContainer = () => {
  const dispatch = useAppDispatch();
  const { initialized } = useAppSelector((state: RootState) => state.Auth);

  // 認証の初期化
  useEffect(() => {
    if (!initialized) {
      void dispatch(initialize());
    }
  }, [initialized, dispatch]);

  // datadogの初期化
  useEffect(() => {
    initDatadogRum({
      enable: process.env.NEXT_PUBLIC_DATADOG_ENABLE === 'true',
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID ?? '',
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? '',
      env: process.env.NEXT_PUBLIC_APP_ENV ?? 'n/a',
      version: process.env.NEXT_PUBLIC_APP_VERSION ?? 'n/a',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
    });
    initI18next();
  }, []);

  return initialized ? <App /> : <></>;
};
