import { PrimaryCandidateScore } from '@components/molecules/primaryCandidateScore/primaryCandidateScore';
import { widthList } from '@components/organisms';
import { Box, Typography } from '@mui/material';
import {
  formatNumberToYen,
  profitTabGrossProfit,
  profitTabQuantity,
  profitTabSales,
} from '@utils/const';
import { FC } from 'react';
import { ProfitTab, ShelfDetailView } from 'types/common';
import { ProductReport } from 'types/products';
import {
  ProductCandidate,
  RealogramCandidateFace,
} from '../../../../types/realogram';
import { MenuButton } from '../../menuButton/menuButton';
import { FaceMenuList } from './faceMenuList';

type Props = {
  face: RealogramCandidateFace;
  faces: RealogramCandidateFace[];
  primaryCandidate?: ProductCandidate;
  realogramDetailView: ShelfDetailView;
  menuOpen: boolean;
  handleClick: () => void;
  handleMenuOpen: () => void;
  handleMenuClose: () => void;
  handleDialogOpen: () => void;
  handleCorrectDrawerOpen: () => void;
  handleDivisionModalOpen: (face: RealogramCandidateFace) => void;
  profitTab: ProfitTab;
  productReport?: ProductReport;
  isCanEditFace?: boolean;
  isCanDeleteFace?: boolean;
  isSelected: boolean;
};

export const ListRowOptionalItem: FC<Props> = ({
  face,
  faces,
  primaryCandidate,
  realogramDetailView,
  menuOpen,
  handleClick,
  handleMenuOpen,
  handleMenuClose,
  handleDialogOpen,
  handleCorrectDrawerOpen,
  handleDivisionModalOpen,
  profitTab,
  productReport,
  isCanEditFace,
  isCanDeleteFace,
  isSelected,
}) => {
  const isInStock = face.in_stock;
  switch (realogramDetailView) {
    case 'default':
      return (
        <>
          <Box
            component="div"
            minWidth={widthList.scoreWidth}
            display="flex"
            justifyContent="center"
            onClick={handleClick}
          >
            <PrimaryCandidateScore face={face} />
          </Box>
          <Box
            component="div"
            minWidth={widthList.menuWidth}
            display="flex"
            justifyContent="center"
          >
            <MenuButton
              handleMenuOpen={() => {
                !isSelected && handleClick();
                handleMenuOpen();
              }}
              handleMenuClose={handleMenuClose}
              menuOpen={menuOpen}
            >
              <FaceMenuList
                isDisplayDivisionModalMenu={!isInStock && !primaryCandidate}
                isCanEditFace={isCanEditFace}
                isCanDeleteFace={isCanDeleteFace}
                handleCorrectDrawerOpen={() => {
                  handleCorrectDrawerOpen();
                  handleMenuClose();
                }}
                handleDivisionModalOpen={() => {
                  handleDivisionModalOpen(face);
                  handleMenuClose();
                }}
                handleDeleteDialogOpen={() => {
                  handleDialogOpen();
                  handleMenuClose();
                }}
              />
            </MenuButton>
          </Box>
        </>
      );
    case 'profit':
      return (
        <Box
          component="div"
          display="flex"
          alignItems="center"
          onClick={handleClick}
        >
          <Typography
            sx={{ fontSize: 14 }}
            width={widthList.face}
            textAlign="right"
          >
            {faces.length}
          </Typography>
          <Typography
            sx={{ fontSize: 14 }}
            width={widthList.profit}
            textAlign="right"
            pr={1}
          >
            {getProfitValue(profitTab, productReport)}
          </Typography>
        </Box>
      );
    case 'rate':
    case 'productFlag':
      return (
        <Typography
          width={widthList.face}
          variant="subtitle2"
          textAlign="right"
        >
          {faces.length}
        </Typography>
      );
    default: {
      console.error('Unexpected value: ', realogramDetailView);
      return <></>;
    }
  }
};

//TODO: rename
const getProfitValue = (
  profitTab: ProfitTab,
  productReport?: ProductReport
) => {
  switch (profitTab) {
    case profitTabSales:
      return productReport?.gross_sales_price != null
        ? formatNumberToYen(productReport?.gross_sales_price)
        : '-';
    case profitTabQuantity:
      return productReport?.sales_count != null
        ? productReport?.sales_count
        : '-';
    case profitTabGrossProfit:
      return productReport?.gross_profit_price != null
        ? formatNumberToYen(productReport?.gross_profit_price)
        : '-';
    default:
      return undefined;
  }
};
