import { closeModal } from '@reducers/modal';
import {
  setCurrentTypeFile,
  setDirectoryId,
  setDirectoryType,
  setIsSuccess,
} from '@reducers/sharePermission';
import { selectSharePermission } from '@reducers/sharePermission/selectors';
import {
  useGetPlanogramDirectoryQuery,
  useGetRealogramDirectoryQuery,
  useUpdatePlanogramDirectoryMutation,
  usersApi,
} from '@reducers/shelfAppsApi';
import {
  useAddDirectoryRoleMutation,
  useDeleteDirectoryRoleMutation,
  useListDirectoryRolesQuery,
  useUpdateDirectoryRoleMutation,
} from '@reducers/shelfAppsApi/injections/directoryRolesApi';
import { useGetTenantsQuery } from '@reducers/shelfAppsApi/injections/tenantsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { defaultDirectoryType, isApiResponse, paths } from '@utils/const';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PlanogramDirectory,
  PlanogramDirectoryResponse,
} from 'types/planogram';
import {
  RealogramDirectory,
  RealogramDirectoryResponse,
} from 'types/realogram';
import {
  DirectoryFormType,
  DirectoryRoleUserInfo,
  DirectoryType,
  PostDirectoryRoleRequest,
  Scope,
  ShareRole,
} from 'types/sharePermission';
import { useModal } from './useModal';

type DirectoryResponse =
  | PlanogramDirectoryResponse
  | RealogramDirectoryResponse;

export const useSharePermissionModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user: userInfo } = useAppSelector((state) => state.Auth);
  const {
    directoryId,
    directoryType,
    isSuccess,
    planogramDirectoryParent,
    currentTypeFile,
  } = useAppSelector(selectSharePermission);
  const { selectedDirectoryId: selectedDirectoryIdOfPlanogram } =
    useAppSelector((state) => state.Planogram);
  const { selectedDirectoryId: selectedDirectoryIdOfRealogram } =
    useAppSelector((state) => state.Scanner);
  const { showModal: showLicenseModal } = useModal('licenseModal', '');
  const [currentDirectoryRole, setCurrentDirectoryRole] = useState<
    ShareRole | undefined
  >();
  const [currentDirectoryRoleModify, setCurrentDirectoryRoleModify] =
    useState<DirectoryRoleUserInfo>();
  const [currentScopeModify, setCurrentScopeModify] = useState<Scope>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [disabledChangeDialogOpen, setDisabledChangeDialogOpen] =
    useState(false);
  const [isOpenScopeDialog, setOpenScopeDialog] = useState<boolean>(false);
  const [isChangePermission, setChangePermission] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const {
    isLoading,
    isFetching,
    data: listDirectoryRoles,
    isError,
  } = useListDirectoryRolesQuery(
    {
      directoryId,
      directoryType,
    },
    {
      skip: !directoryId || !directoryType,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: tenantInfo } = useGetTenantsQuery();

  const handlePermissionError = useCallback(() => {
    dispatch(closeModal());
    const errorMessage =
      currentTypeFile === 'directory'
        ? `フォルダの権限がないため、共有設定を開けません。`
        : `ファイルの権限がないため、共有設定を開けません。`;

    dispatch(
      openToast({
        type: 'error',
        message: errorMessage,
      })
    );
    dispatch(setDirectoryId(''));
    dispatch(setDirectoryType(defaultDirectoryType));
    dispatch(setCurrentTypeFile(undefined));
  }, [currentTypeFile, dispatch]);

  const useGetDirectoryInfo = <T extends DirectoryResponse>(
    directoryId: string | undefined,
    directoryType: DirectoryType
  ): T | undefined => {
    let directoryData: T | undefined;
    const fetchPlanogram = useGetPlanogramDirectoryQuery;
    const fetchRealogram = useGetRealogramDirectoryQuery;
    if (directoryType === 'planogram' && !!useGetPlanogramDirectoryQuery) {
      const { data, isError } = fetchPlanogram(
        { planogramDirectoryId: directoryId ?? '' },
        { skip: !directoryId, refetchOnMountOrArgChange: true }
      );
      directoryData = data as T;
      if (isError) {
        handlePermissionError();
        return;
      }
    }
    if (directoryType === 'realogram' && !!useGetRealogramDirectoryQuery) {
      const { data, isError } = fetchRealogram(
        { realogramDirectoryId: directoryId ?? '' },
        { skip: !directoryId, refetchOnMountOrArgChange: true }
      );
      directoryData = data as T;
      if (isError) {
        handlePermissionError();
        return;
      }
    }
    return directoryData;
  };

  const planogramDirectoryInfo =
    useGetDirectoryInfo<PlanogramDirectoryResponse>(directoryId, directoryType);
  const realogramDirectoryInfo =
    useGetDirectoryInfo<RealogramDirectoryResponse>(directoryId, directoryType);

  const directoryRoles = listDirectoryRoles?.directory_roles?.filter(
    (d) => d.role !== 'owner'
  );

  const directoryOwnerInfo = listDirectoryRoles?.directory_roles?.find(
    (d) => d.role === 'owner'
  );

  const ancestorRoles = listDirectoryRoles?.ancestor_roles?.filter(
    (d) => d.role !== 'owner'
  );

  const getUserAncestorRole = (directoryRole: DirectoryRoleUserInfo) => {
    const userAncestorRoles = listDirectoryRoles?.ancestor_roles?.filter(
      (d) => d.user_id === directoryRole?.user_id
    );

    if (!userAncestorRoles?.length) return '';
    if (userAncestorRoles.some((role) => role.role === 'editor'))
      return 'editor';
    return 'viewer';
  };

  const shouldCloseScopeDialogAndRedirect = !(
    directoryOwnerInfo?.user_id === userInfo?.id || userInfo?.role === 'admin'
  );

  const latestScope = planogramDirectoryInfo?.planogram_directory?.scope;
  const currentUserRole =
    directoryType === 'planogram'
      ? (planogramDirectoryInfo?.planogram_directory as PlanogramDirectory)
          ?.current_user_role?.role
      : (realogramDirectoryInfo?.realogram_directory as RealogramDirectory)
          ?.current_user_role?.role;

  const [addDirectoryRole] = useAddDirectoryRoleMutation();
  const [updateDirectoryRole] = useUpdateDirectoryRoleMutation();
  const [deleteDirectoryRole] = useDeleteDirectoryRoleMutation();
  const [updatePlanogramDirectory] = useUpdatePlanogramDirectoryMutation();

  const findUserWithEmailAddress = useCallback(
    async (email: string) => {
      try {
        const results = await dispatch(
          usersApi.endpoints.getUsers.initiate({
            email: email,
            scope: 'all',
          })
        );
        return results?.data?.users[0];
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const handleAddDirectoryRole = async (
    params: Omit<PostDirectoryRoleRequest, 'user_id'> & {
      email: string;
    }
  ) => {
    const user = await findUserWithEmailAddress(params.email);
    if (!user?.id?.toString()) {
      setErrorMessage('ユーザーが存在しません。');
      return;
    }
    try {
      await addDirectoryRole({
        directoryId: params.directory_id,
        directoryType: params.directory_type,
        role: params.role,
        userId: user.id,
      }).unwrap();

      setErrorMessage('');
      dispatch(setIsSuccess(true));
    } catch (error) {
      if (isApiResponse(error)) {
        if (error.data.detail === 'Downgrading role is invalid') {
          setErrorMessage(
            '親フォルダで編集者になっているため、閲覧者には設定できません。'
          );
        } else if (error.data.detail === 'DirectoryRole is Already Exist') {
          setErrorMessage('すでに追加されているユーザーです。');
        }
      }

      console.error(error);
    }
  };

  const handleEditDirectoryRole = async (
    directoryRole: DirectoryRoleUserInfo,
    role: ShareRole,
    type: DirectoryFormType
  ) => {
    if (!directoryRole || !role) return;
    if (directoryRole && (role as string) === 'delete') {
      handleOpenDeleteDialog(directoryRole);
    } else if (
      directoryRole?.role !== role &&
      getUserAncestorRole(directoryRole) === 'editor' &&
      role === 'viewer' &&
      (planogramDirectoryParent?.scope === 'restricted' ||
        planogramDirectoryParent?.scope === 'public' ||
        directoryRole.directory_type === 'realogram')
    ) {
      setDisabledChangeDialogOpen(true);
      setCurrentDirectoryRoleModify(directoryRole);
    } else if (
      directoryRole?.role !== role &&
      role === 'viewer' &&
      directoryRole.user_id === userInfo?.id
    ) {
      handleOpenDeleteDialog(directoryRole);
      setChangePermission(true);
    } else if (
      directoryRole?.role !== role &&
      role === 'viewer' &&
      type === 'directory'
    ) {
      handleOpenDeleteDialog(directoryRole);
      setChangePermission(true);
    } else {
      try {
        await updateDirectoryRole({
          directoryRoleId: directoryRole.id,
          role,
        }).unwrap();
        if (directoryRole.user_id === userInfo?.id) {
          setCurrentDirectoryRole(role);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const changeSelfDirectoryRole = async () => {
    try {
      if (!currentDirectoryRoleModify?.id) {
        throw new Error('Current directory role ID is not available');
      }
      await updateDirectoryRole({
        directoryRoleId: currentDirectoryRoleModify?.id,
        role: 'viewer',
      }).unwrap();
      if (currentDirectoryRoleModify.user_id === userInfo?.id) {
        setCurrentDirectoryRole('viewer');
      }
    } catch (error) {
      console.error(error);
    } finally {
      reset();
    }
  };

  const handleOpenDeleteDialog = (directoryRole: DirectoryRoleUserInfo) => {
    setDeleteDialogOpen(true);
    setCurrentDirectoryRoleModify(directoryRole);
  };

  const handleDeleteDirectoryRole = async () => {
    try {
      if (!currentDirectoryRoleModify?.id) {
        throw new Error('Current directory role ID is not available');
      }

      await deleteDirectoryRole({
        directoryRoleId: currentDirectoryRoleModify.id,
      });
      setDeleteDialogOpen(false);

      if (currentDirectoryRoleModify?.user_id === userInfo?.id) {
        handleRedirect();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeScope = async (scope: Scope) => {
    if (scope === 'private') {
      handleOpenScopeDialog(scope);
    } else {
      try {
        await updatePlanogramDirectory({
          planogramDirectoryId: directoryId,
          scope,
        }).unwrap();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleChangeScopeToPrivate = async () => {
    try {
      if (!currentScopeModify) {
        throw new Error('Current scope is not available');
      }

      await updatePlanogramDirectory({
        planogramDirectoryId: directoryId,
        scope: currentScopeModify,
      }).unwrap();

      resetScopeDialog();
      if (shouldCloseScopeDialogAndRedirect) {
        handleRedirect();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenScopeDialog = (scope: Scope) => {
    setOpenScopeDialog(true);
    setCurrentScopeModify(scope);
  };

  const resetScopeDialog = () => {
    setOpenScopeDialog(false);
  };

  const handleRedirect = () => {
    dispatch(closeModal());

    let directoryId;
    let pathPrefix;

    if (directoryType === 'realogram') {
      directoryId = selectedDirectoryIdOfRealogram;
      pathPrefix = 'actuals';
    } else {
      directoryId = selectedDirectoryIdOfPlanogram;
      pathPrefix = 'plans';
    }

    if (directoryId) {
      navigate(paths.directoryTypeId(pathPrefix, directoryId));
    } else {
      navigate(paths.directoryType(pathPrefix));
    }
  };

  const reset = () => {
    setDeleteDialogOpen(false);
    setDisabledChangeDialogOpen(false);
    setCurrentDirectoryRoleModify(undefined);
    setChangePermission(false);
  };

  useEffect(() => {
    if (isError) {
      handlePermissionError();
    }
  }, [handlePermissionError, isError]);

  return {
    isLoading,
    isFetching,
    directoryRoles,
    ancestorRoles,
    deleteDialogOpen,
    directoryId,
    directoryType,
    isSuccess,
    errorMessage,
    currentDirectoryRole,
    currentDirectoryRoleModify,
    userInfo,
    isChangePermission,
    directoryOwnerInfo,
    isOpenScopeDialog,
    tenantInfo,
    disabledChangeDialogOpen,
    latestScope,
    currentUserRole,
    planogramDirectoryParent,
    setErrorMessage,
    showLicenseModal,
    handleAddDirectoryRole,
    handleEditDirectoryRole,
    handleDeleteDirectoryRole,
    reset,
    changeSelfDirectoryRole,
    handleChangeScope,
    resetScopeDialog,
    handleChangeScopeToPrivate,
    setCurrentDirectoryRole,
  };
};
