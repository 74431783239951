import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { theme } from 'theme';
import { InferType, number, object } from 'yup';

type Props = {
  marginLeft?: number;
  marginRight?: number;
};

const style = {
  width: '20px',
  height: '1px',
  color: theme.palette.backgroundBlack.primary,
  alignItems: 'center',
  margin: '0',
};

const maxLength = 99;
const minLength = -99;

const marginSchema = number()
  .max(maxLength)
  .min(minLength)
  .integer()
  .transform((value: number | string | undefined, originalValue) => {
    // 空白とマイナス符号を許容する
    if (
      (typeof originalValue === 'string' && originalValue.trim() === '') ||
      originalValue === '-'
    ) {
      return 0;
    }
    return value;
  });

const schema = object({
  marginLeft: marginSchema,
  marginRight: marginSchema,
});

type FormData = InferType<typeof schema>;

export const MarginEditor: FC<Props> = ({
  marginLeft: defaultMarginLeft,
  marginRight: defaultMarginRight,
}) => {
  const { control, reset, watch } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      marginLeft: defaultMarginLeft,
      marginRight: defaultMarginRight,
    },
  });

  const [marginLeft, marginRight] = watch(['marginLeft', 'marginRight']);

  const handleResetClick = () => {
    reset({
      marginLeft: defaultMarginLeft,
      marginRight: defaultMarginRight,
    });
  };

  const isValidMarginFormat = {
    marginRight: marginSchema.isValidSync(marginRight),
    marginLeft: marginSchema.isValidSync(marginLeft),
  };

  return (
    <Stack>
      <Box
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box component="div" display="flex" alignItems="center">
          <Controller
            name="marginLeft"
            control={control}
            render={({ field }) => (
              <MarginTextfield
                hasError={!isValidMarginFormat.marginLeft}
                label="左"
                value={marginLeft}
                field={field}
              />
            )}
          />
          <Divider style={style} />
          <Controller
            name="marginRight"
            control={control}
            render={({ field }) => (
              <MarginTextfield
                hasError={!isValidMarginFormat.marginRight}
                label="右"
                value={marginRight}
                field={field}
              />
            )}
          />
        </Box>
        <Button
          onClick={handleResetClick}
          sx={{ fontWeight: 'bold', color: theme.palette.textBlack.disabled }}
        >
          リセット
        </Button>
      </Box>
      {(!isValidMarginFormat.marginLeft ||
        !isValidMarginFormat.marginRight) && (
        <Typography color="error" variant="caption">
          半角数字を入力してください。
        </Typography>
      )}
    </Stack>
  );
};

type TextfieldProps<T extends keyof FormData> = {
  hasError: boolean;
  label: string;
  field: ControllerRenderProps<FormData, T>;
  value?: number;
};

const defaultPositiveNum = 1;

const positiveMaxLength = 2;
const negativeMaxLength = 3;
// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
const MarginTextfield = <T extends keyof FormData>({
  label,
  value,
  hasError,
  field,
}: TextfieldProps<T>) => {
  return (
    <TextField
      {...field}
      label={label}
      size="small"
      type="tel"
      sx={{ maxWidth: '96px' }}
      inputMode="numeric"
      inputProps={{
        maxLength:
          Math.sign(value ?? defaultPositiveNum) === -1
            ? negativeMaxLength
            : positiveMaxLength, // 負の数の場合、-分max値を増やす
        min: 0,
      }}
      error={hasError}
      InputProps={{
        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
      }}
    />
  );
};
