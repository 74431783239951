import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

export const RotateMinusZIcon: FC<SvgIconProps> = (props) => {
  const color = props.htmlColor || theme.palette.icons.primary;
  return (
    <SvgIcon
      {...props}
      sx={{
        width: '24px',
        height: '24px',
        viewBox: '0 0 24px 24px',
        fill: 'none',
      }}
    >
      <rect
        width="10"
        height="2"
        transform="matrix(1 0 0 -1 5 13)"
        fill={color}
      />
      <rect
        width="3"
        height="2"
        transform="matrix(1 0 0 -1 18 13)"
        fill={color}
      />
      <path
        d="M7.91483 16.6174C8.82044 18.664 10.3119 20 11.9985 20C14.7599 20 16.9985 16.4183 16.9985 12C16.9985 7.58172 14.7599 4 11.9985 4C10.0938 4 8.43796 5.70387 7.59375 8.21091"
        stroke={color}
        strokeWidth="2"
      />
      <path d="M7 20V16H11" stroke={color} strokeWidth="2" />
    </SvgIcon>
  );
};
