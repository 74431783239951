import { FormPaper } from '@components/molecules/formPaper/formPaper';
import { Button, CircularProgress, Typography } from '@mui/material';
import { paths } from '@utils/const';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMfaStatus } from '../../hooks/useMfaStatus';
import { CancelButton } from './cancelButton';

type Props = {
  next: () => void;
};

export const Initial: FC<Props> = ({ next }) => {
  const { mfaAvailable, isLoading } = useMfaStatus();
  const navigate = useNavigate();

  useEffect(() => {
    if (mfaAvailable) {
      navigate(paths.account.root);
    }
  }, [mfaAvailable, navigate]);

  return isLoading ? (
    <CircularProgress />
  ) : (
    <FormPaper title="二要素認証登録">
      <Typography mb={3}>
        ID とパスワードに加えて、スマートフォンを使って認証を行います。
      </Typography>
      <Typography>
        事前に対応アプリケーションを、このデバイスとは異なるデバイスにインストールしてください（このデバイスにはインストールしないでください）。
      </Typography>
      <Button variant="contained" sx={{ my: 3 }} fullWidth onClick={next}>
        設定を開始
      </Button>
      <CancelButton />
    </FormPaper>
  );
};
