import { TableRow } from '@mui/material';
import { rowHeight } from '@utils/const';
import { theme } from 'theme';
import { TableRowProps } from './table';

type Props<T> = TableRowProps<T> & {
  isNotCanRead?: boolean;
  handleRowClick: VoidFunction;
};

export const DefaultVirtuosoTableRow = <T,>({
  handleRowClick,
  ...props
}: Props<T>) => {
  return (
    <TableRow
      sx={{
        ':hover': {
          backgroundColor: theme.palette.backgroundBlack.hover,
        },
        cursor: 'pointer',
        '.MuiTableCell-root': {
          color: theme.palette.textBlack.table,
        },
        height: rowHeight,
      }}
      onClick={() => {
        handleRowClick();
      }}
      {...props}
    />
  );
};
