import { BayPartCard } from '@components/organisms/bayPartCard/bayPartCard';
import { Grid, Typography } from '@mui/material';
import {
  colBayPartCompareMode,
  colBayPartDefault,
  colGridBayPartDefault,
} from '@utils/bayPart';
import { fontSizeDefault, fontSizeInCompareMode } from '@utils/const';
import { FC } from 'react';
import { ChildBayPart } from 'types/bayPlan';

type Props = {
  parts?: ChildBayPart[];
  selectedBayPlanBayPartId?: number;
  handleClick: (part: ChildBayPart) => void;
  isCompareMode?: boolean;
  selectedFlatPlanogramBayPart?: number;
  prevBayPartId?: number;
  isSwappingBayPartMode?: boolean;
};

export const BayPartsGrid: FC<Props> = ({
  parts,
  selectedBayPlanBayPartId,
  handleClick,
  isCompareMode,
  prevBayPartId,
  isSwappingBayPartMode = false,
}) => {
  return (
    <Grid
      container
      columns={{
        xs: isCompareMode ? colBayPartCompareMode : colBayPartDefault,
      }}
      spacing={2}
      sx={{ marginTop: '0 !important' }}
    >
      {parts?.map((part) => {
        const isSelectedBayPlanBayPart =
          selectedBayPlanBayPartId === part.bay_part_id; //未配置の什器パーツを選択したかどうか
        //什器に配置されているパーツかどうか
        const isSelectedPlanogramBayPart =
          prevBayPartId === part.bay_part_id && isSwappingBayPartMode;
        return (
          <Grid
            item
            key={part.bay_part_id}
            xs={colGridBayPartDefault}
            sm={6}
            breakpoint={4}
          >
            <BayPartCard
              isCompareMode={isCompareMode}
              type={part.type}
              handleSelectedBayPart={() => handleClick(part)}
              isSelectedPlanogramBayPart={isSelectedPlanogramBayPart}
              isSelectedBayPlanBayPart={isSelectedBayPlanBayPart}
            >
              <Typography
                fontSize={
                  isCompareMode ? fontSizeInCompareMode : fontSizeDefault
                }
              >
                {part.name}
              </Typography>
            </BayPartCard>
          </Grid>
        );
      })}
    </Grid>
  );
};
