import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const ArrowPrevIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <g id="Start Icon">
        <path
          id="Union"
          d="M13.0881 13.825L9.27142 10L13.0881 6.175L11.9131 5L6.91309 10L11.9131 15L13.0881 13.825Z"
          fillOpacity="0.65"
        />
      </g>
    </SvgIcon>
  );
};
