import { Box, Typography } from '@mui/material';
import { FC } from 'react';

export const CorrectedChip: FC = () => (
  <Box
    component="div"
    sx={{
      minWidth: 45,
      height: 25,
      backgroundColor: '#F5F5F5',
      p: '4px',
      border: '1px solid #DDDDDD',
      borderRadius: '2px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Typography sx={{ fontSize: 12, color: '#757575' }}>訂正済</Typography>
  </Box>
);
