import { FC } from 'react';
import Image from 'next/legacy/image';
import { RealogramType } from 'types/realogram';
import { useImagePreview } from '@components/pages/realogramCreate/hooks/useImagePreview';

type Props = {
  file?: File;
  selectedRealogramType: RealogramType;
};

export const ImagePreview: FC<Props> = ({ file, selectedRealogramType }) => {
  const { src } = useImagePreview(file);
  if (!src) return <></>;
  else if (selectedRealogramType === 'flat')
    return (
      <Image alt="" src={src} objectFit="cover" width={360} height={270} />
    );
  else return <Image alt="" src={src} layout="fill" objectFit="contain" />;
};
