import { CircularProgress } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { FC } from 'react';
type Props = {
  isLoading: boolean;
  qrCodeValue?: string;
};

export const QRCodeContent: FC<Props> = ({ isLoading, qrCodeValue }) => {
  if (isLoading) return <CircularProgress />;
  if (!qrCodeValue) return <>データを表示できません。</>;
  return <QRCodeSVG width={120} height={120} level="H" value={qrCodeValue} />;
};
