import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const HorizontalTabletIconRotate: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      fill="none"
      sx={{ width: '175px', height: '240px' }}
      viewBox="0 0 200 200"
      {...props}
    >
      <svg
        width="175"
        height="240"
        viewBox="0 0 175 240"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        transform="rotate(90)"
      >
        <g clipPath="url(#clip0_38881_517943)">
          <path
            d="M31.2205 26.4217V31.4373L37.9078 24.7499L31.2205 18.0625V23.078C23.8309 23.078 17.8457 29.0632 17.8457 36.4528C17.8457 39.0776 18.6148 41.5185 19.9188 43.5748L22.3597 41.134C21.6074 39.7463 21.1894 38.1414 21.1894 36.4528C21.1894 30.919 25.6867 26.4217 31.2205 26.4217Z"
            fill="#0A40CA"
          />
        </g>
        <g clipPath="url(#clip1_38881_517943)">
          <rect
            y="175"
            width="122"
            height="175"
            rx="4"
            transform="rotate(-90 0 175)"
            fill="white"
            fillOpacity="0.5"
          />
          <rect
            x="8"
            y="167"
            width="106"
            height="159"
            rx="4"
            transform="rotate(-90 8 167)"
            fill="white"
            fillOpacity="0.5"
          />
          <rect
            x="10"
            y="165"
            width="102"
            height="155"
            rx="2"
            transform="rotate(-90 10 165)"
            stroke="#0A54CA"
            strokeOpacity="0.25"
            strokeWidth="4"
          />
          <rect
            x="159"
            y="94"
            width="40"
            height="4"
            rx="2"
            transform="rotate(90 159 94)"
            fill="#0A54CA"
            fillOpacity="0.25"
          />
          <rect
            x="158.72"
            y="94.28"
            width="39.44"
            height="3.44"
            rx="1.72"
            transform="rotate(90 158.72 94.28)"
            stroke="#0A54CA"
            strokeOpacity="0.25"
            strokeWidth="0.56"
          />
        </g>
        <rect
          x="2"
          y="173"
          width="118"
          height="171"
          rx="2"
          transform="rotate(-90 2 173)"
          stroke="#0A54CA"
          strokeOpacity="0.25"
          strokeWidth="4"
        />
        <rect
          x="173"
          y="173"
          width="118"
          height="171"
          rx="2"
          transform="rotate(-180 173 173)"
          fill="white"
          fillOpacity="0.5"
        />
        <rect
          x="173"
          y="173"
          width="118"
          height="171"
          rx="2"
          transform="rotate(-180 173 173)"
          stroke="#0A40CA"
          strokeWidth="4"
        />
        <rect
          x="165"
          y="165"
          width="102"
          height="155"
          rx="2"
          transform="rotate(-180 165 165)"
          fill="white"
          fillOpacity="0.5"
          stroke="#0A40CA"
          strokeWidth="4"
        />
        <rect
          x="134"
          y="158.996"
          width="40"
          height="4"
          rx="2"
          transform="rotate(-180 134 158.996)"
          fill="#0A40CA"
        />
        <defs>
          <clipPath id="clip0_38881_517943">
            <path
              d="M14.8438 19.5625C14.8438 18.3199 15.8511 17.3125 17.0938 17.3125H39.5938C40.8364 17.3125 41.8438 18.3199 41.8438 19.5625V42.0625C41.8438 43.3051 40.8364 44.3125 39.5938 44.3125H17.0937C15.8511 44.3125 14.8438 43.3051 14.8438 42.0625V19.5625Z"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip1_38881_517943">
            <rect
              y="175"
              width="122"
              height="175"
              rx="4"
              transform="rotate(-90 0 175)"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
