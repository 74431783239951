import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const ArrowUpwardDownwardIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      sx={{ height: props.height, width: props.width, color: '#B5B5B5' }}
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 12L10.1187 11.1187L6.625 14.6062V3H5.375V14.6062L1.8875 11.1125L1 12L6 17L11 12Z" />
      <path d="M9 8L9.88125 8.88125L13.375 5.39375L13.375 17L14.625 17L14.625 5.39375L18.1125 8.8875L19 8L14 3L9 8Z" />
    </SvgIcon>
  );
};
