import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const FileIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      fill="none"
      sx={{ width: '20px', height: '20px' }}
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16602 1.66663H15.8327V18.3333H4.16602V1.66663ZM14.166 16.6666V13.3333H5.83268V16.6666H14.166ZM14.166 11.6666V8.33329H5.83268V11.6666H14.166ZM14.166 6.66663V3.33329H5.83268V6.66663H14.166Z"
        fill="black"
        fillOpacity="0.28"
      />
    </SvgIcon>
  );
};
