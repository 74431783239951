import { RealogramFaceImage } from '@components/molecules/realogramFaceImage/realogramFaceImage';
import { ProductImage } from '@components/organisms/productImage/productImage';
import {
  ProductFlagLabel,
  ProductFlagView,
} from '@components/organisms/realogramCandidatesList/fragments';
import { StyledListItemButton } from '@components/organisms/styledListItemButton/styledListItemButton';
import { Box, Stack, Typography } from '@mui/material';
import { MutableRefs } from '@utils/realogram';
import { FC, PropsWithChildren } from 'react';
import { Product, ShelfDetailView } from 'types/common';
import { RealogramCandidateFace } from 'types/realogram';
import { ImageBox, NameBox, RequiredBox } from './fragments';
import { theme } from 'theme';
import { widthList } from '@utils/const';
import { t } from 'i18next';

type Props = {
  refs: MutableRefs;
  view: ShelfDetailView;
  face?: RealogramCandidateFace;
  refKey: string;
  product?: Product;
  isSelected: boolean;
  handleClick: () => void;
  name?: string;
  eanCode?: string;
  organizationProductId?: string;
  productCode?: string;
};

const productFlagViewHeight = 84;
const defaultHeight = 64;

export const ShelfListItem: FC<PropsWithChildren<Props>> = ({
  refs,
  view,
  face,
  refKey,
  product,
  isSelected,
  handleClick,
  name,
  eanCode,
  productCode,
  children,
  organizationProductId,
}) => {
  return (
    <StyledListItemButton
      ref={(i) => refs.current.set(refKey, i)} //自動スクロールに必要
      component="li"
      sx={{
        height: view === 'productFlag' ? productFlagViewHeight : defaultHeight,
        p: 0,
        pr: 2,
      }}
      selected={isSelected}
    >
      <RequiredBox onClick={handleClick} px={1} pl={2}>
        <Stack direction="column">
          <Typography
            variant="caption"
            minWidth={widthList.eanCodeWidth}
            fontSize="inherit"
            color={theme.palette.textBlack.table}
          >
            {eanCode}
          </Typography>
          <Typography
            variant="caption"
            minWidth={widthList.eanCodeWidth}
            fontSize="inherit"
            color={theme.palette.textBlack.table}
          >
            {productCode ?? organizationProductId ?? ''}
          </Typography>
        </Stack>
        <ImageBox>
          {face ? ( //スキャン詳細では欠品または不明な商品の時の表示がある
            <RealogramFaceImage face={face} product={product} baseSize={56} />
          ) : (
            <ProductImage product={product} layout="fill" objectFit="contain" />
          )}
        </ImageBox>
        <NameBox>
          {view === 'default' &&
            productCode &&
            product?.detail?.tags?.includes(t('sales_ended')) && (
              <Box component="div" sx={{ display: 'inline-flex', pb: 0.5 }}>
                <ProductFlagLabel name={t('sales_ended')} />
              </Box>
            )}
          {view === 'productFlag' && (
            <Box component="div" position="absolute" top="0">
              <ProductFlagView product={product} />
            </Box>
          )}
          <Typography noWrap fontSize="inherit" width="100%">
            {name}
          </Typography>
        </NameBox>
      </RequiredBox>
      {children}
    </StyledListItemButton>
  );
};
