import { Box, List, ListItem, Typography } from '@mui/material';
import { displayRoleName } from '@utils/const';
import { FC } from 'react';
import { theme } from 'theme';
import { AncestorRolesType } from 'types/sharePermission';
import { User } from 'types/user';

type Props = {
  ancestorRoles?: AncestorRolesType;
  userInfo?: User;
};

export const AncestorRoles: FC<Props> = ({ ancestorRoles, userInfo }) => {
  return (
    <Box component="div" mb={2.5}>
      <Typography
        color={theme.palette.textBlack.primary}
        noWrap
        fontSize={16}
        sx={{
          fontWeight: '700',
        }}
      >
        フォルダで権限を付与されたユーザー
      </Typography>
      <List
        component="div"
        sx={{
          padding: 0,
        }}
      >
        {ancestorRoles?.map((ancestorRole) => (
          <ListItem
            key={ancestorRole.id}
            sx={{
              paddingLeft: 0,
              paddingRight: 0,
              marginBottom: '6px',
            }}
          >
            <Box component="div" flex={1}>
              <Typography color={`${theme.palette.textBlack.primary}`}>
                {ancestorRole.user_id === userInfo?.id
                  ? `自分（${ancestorRole.user.fullname}）`
                  : ancestorRole.user.fullname}
              </Typography>
              <Typography
                fontSize={14}
                color={`${theme.palette.textBlack.secondary}`}
              >
                {ancestorRole.user.email}
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{
                flex: '0 1 136px',
              }}
            >
              <Typography
                color={`${theme.palette.textBlack.disabled}`}
                sx={{ textAlign: 'right' }}
              >
                {displayRoleName(ancestorRole.role)}
              </Typography>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
