import { Button, ButtonProps, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = ButtonProps & {
  onClickAction: () => void;
  title: string;
  icon: JSX.Element;
};

export const SecondaryNavigationButton: FC<Props> = ({
  onClickAction,
  title,
  icon,
  ...ButtonProps
}) => {
  return (
    <Button
      variant="outlined"
      onClick={onClickAction}
      sx={{
        padding: 0,
        borderColor: theme.palette.primary.lightBorder,
        ...ButtonProps.sx,
      }}
    >
      {icon}
      <Typography
        variant="subtitle1"
        color={theme.palette.primary.main}
        component="div"
        lineHeight={1.5}
      >
        {title}
      </Typography>
    </Button>
  );
};
