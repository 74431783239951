// TODOリファクタ: shelfのコンポーネントと統合する
import { StopPropagationButton } from '@components/organisms/stopPropagationButton/stopPropagationButton';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { ButtonGroup } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';
import { useFlatPlanogramPlan } from '@hooks/useFlatPlanogramPlan';
import { BucketArea, Position } from 'types/planogram';

type Props = {
  targetCompartment: BucketArea;
  bucketIndex: number;
  areaPath?: Position[];
};

export const ProductsButtonGroupVertical: FC<Props> = ({
  targetCompartment,
  bucketIndex,
  areaPath,
}) => {
  const disableMinus = targetCompartment?.count?.y === 1;
  const { incrementVerticalAmount, decrementVerticalAmount } =
    useFlatPlanogramPlan();
  return (
    <ButtonGroup
      orientation="vertical"
      size="large"
      sx={{
        width: 48,
        '& .MuiButtonGroup-grouped': {
          color: CommonColors.appBlue,
          border: 'unset',
          my: 1,
          '&:hover': {
            border: 'unset',
          },
          '&:disabled': {
            border: 'unset',
          },
        },
      }}
    >
      <StopPropagationButton
        onClick={() => incrementVerticalAmount(bucketIndex, areaPath)}
      >
        <AddCircle sx={{ fill: CommonColors.appBlue }} />
      </StopPropagationButton>
      <StopPropagationButton
        disabled={disableMinus}
        onClick={() => decrementVerticalAmount(bucketIndex, areaPath)}
      >
        <RemoveCircle />
      </StopPropagationButton>
    </ButtonGroup>
  );
};
