import { BayPartsGrid } from '@components/organisms/bayPartsList/fragments';
import { useFlatPlanogramPlan } from '@hooks/useFlatPlanogramPlan';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { FC } from 'react';
import { ChildBayPart } from 'types/bayPlan';

type Props = {
  handleClickBayPartCard: (part: ChildBayPart) => void;
  parts?: ChildBayPart[];
  isCompareMode?: boolean;
  prevBayPartId?: number;
};

export const BayPartsList: FC<Props> = ({
  handleClickBayPartCard,
  parts,
  isCompareMode,
  prevBayPartId,
}) => {
  const { selectedBayPartId, selectedBucketId, isSwappingBayPartMode } =
    useAppSelector(selectPlanogramEditorState);
  const { plan: flatPlan } = useFlatPlanogramPlan();

  const selectedFlatPlanogramBayPart =
    selectedBucketId &&
    flatPlan.frame.detail.buckets?.at(selectedBucketId)?.bay_part_id;

  return (
    <BayPartsGrid
      isCompareMode={isCompareMode}
      selectedBayPlanBayPartId={selectedBayPartId}
      selectedFlatPlanogramBayPart={selectedFlatPlanogramBayPart}
      parts={parts}
      handleClick={handleClickBayPartCard}
      prevBayPartId={prevBayPartId}
      isSwappingBayPartMode={isSwappingBayPartMode}
    />
  );
};
