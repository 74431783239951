import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  productDetailList: {
    label: string;
    detail: string | JSX.Element[] | JSX.Element | undefined;
  }[];
};

export const ProductDetailBody: FC<Props> = ({ productDetailList }) => {
  return (
    <Box component="div" mx={2}>
      {productDetailList.map((item, index) => (
        <Box key={index} component="div" display="flex" sx={{ my: 1 }}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.textBlack.label, width: '92px' }}
          >
            {item.label}
          </Typography>
          <Typography
            variant="body2"
            color={theme.palette.textBlack.table}
            sx={{ width: `calc(100% - 92px)` }}
          >
            {item.detail}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
