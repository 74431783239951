import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const BottleBottomIcon: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 80 80"
      sx={{ height: props.height, width: props.width }}
      fill="none"
    >
      <ellipse
        cx="40"
        cy="43"
        rx="33"
        ry="33"
        transform="rotate(-180 40 43)"
        fill="#F8F8F8"
      />
      <ellipse
        cx="40"
        cy="43"
        rx="33"
        ry="33"
        transform="rotate(-180 40 43)"
        fill="#F8F8F8"
      />
      <circle
        cx="40"
        cy="43"
        r="13"
        stroke="#D9D9D9"
        fill="white"
        strokeWidth="2"
      />
      <circle
        cx="40"
        cy="43"
        r="7"
        stroke="#D9D9D9"
        fill="white"
        strokeWidth="2"
      />
      <path d="M40 29.5V19" stroke="#D9D9D9" />
      <path
        d="M40 29.5V19"
        stroke="#D9D9D9"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M53.0139 39.2447L63 36"
        stroke="#D9D9D9"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M26.9861 39.2447L17 36"
        stroke="#D9D9D9"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M31.4644 53.7259L25 62"
        stroke="#D9D9D9"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M48.5356 53.7259L55 62"
        stroke="#D9D9D9"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
