import { Box, Button, Divider, Typography } from '@mui/material';
import { FC } from 'react';
type Props = {
  handleClose: () => void;
  handleClick?: () => void;
  actionBtnTitle: string;
  disabled?: boolean;
};
export const ModalActions: FC<Props> = ({
  handleClose,
  handleClick,
  actionBtnTitle,
  disabled,
}) => {
  return (
    <>
      <Divider sx={{ mt: '24px', mx: '-24px', mb: '12px' }} />
      <Box
        component="div"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        height="40px"
      >
        <Typography
          onClick={handleClose}
          variant="caption"
          fontWeight="700"
          sx={{ px: 2, cursor: 'pointer' }}
        >
          キャンセル
        </Typography>
        <Button disabled={disabled} variant="contained" onClick={handleClick}>
          {actionBtnTitle}
        </Button>
      </Box>
    </>
  );
};
