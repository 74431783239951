import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const BowlLeftIcon: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 80 80"
      sx={{ height: props.height, width: props.width }}
      fill="none"
    >
      <path
        d="M4 29H74L65.2321 46.7699C64.5583 48.1355 63.1677 49 61.645 49H16.355C14.8323 49 13.4417 48.1355 12.7679 46.7699L4 29Z"
        fill="white"
      />
      <path
        d="M76 28L79 34"
        stroke="#E6E6E6"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <rect x="1" y="27" width="76" height="2" rx="1" fill="white" />
    </SvgIcon>
  );
};
