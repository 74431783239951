import { DrawerHeader } from '@components/organisms/drawerHeader/drawerHeader';
import { ListWrapper } from '@components/organisms/productCandidatesDrawer/fragments/listWrapper';
import { FC } from 'react';
import { Product } from '../../../../types/common';
import { RealogramCandidateFace } from '../../../../types/realogram';

export const SelectedProduct: FC<{
  product?: Product;
  referenceImage: string;
  handleClose: () => void;
  handleOpenReferenceImagePreview: () => void;
  face: RealogramCandidateFace;
  isProductUnknown?: boolean;
  setSelectedProductZoomIn: (product?: Product) => void;
}> = ({
  product,
  referenceImage,
  handleClose,
  handleOpenReferenceImagePreview,
  face,
  isProductUnknown,
  setSelectedProductZoomIn,
}) => {
  return (
    <ListWrapper
      title="現在選択中の商品"
      hasCloseButton
      handleClose={handleClose}
    >
      <DrawerHeader
        product={product}
        referenceImage={referenceImage}
        handleOpenReferenceImagePreview={handleOpenReferenceImagePreview}
        face={face}
        isProductUnknown={isProductUnknown}
        setSelectedProductZoomIn={setSelectedProductZoomIn}
      />
    </ListWrapper>
  );
};
