import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const FlatRealogramRightEmpty: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      fill="none"
      sx={{ width: '200px', height: '100px' }}
      viewBox="0 0 200 100"
      {...props}
    >
      <svg
        width="190"
        height="98"
        viewBox="0 0 190 98"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M97 98L2 98C0.895432 98 7.8281e-08 97.1046 1.74846e-07 96L8.39259e-06 1.99999C8.48915e-06 0.895423 0.89544 -8.40173e-06 2.00001 -8.30516e-06L97 0L97 98Z"
          fill="#D1D1D1"
        />
        <rect
          x="93"
          y="94"
          width="27"
          height="43"
          rx="1"
          transform="rotate(-180 93 94)"
          fill="white"
        />
        <rect
          x="62"
          y="94"
          width="27"
          height="43"
          rx="1"
          transform="rotate(-180 62 94)"
          fill="white"
        />
        <rect
          x="31"
          y="94"
          width="27"
          height="43"
          rx="1"
          transform="rotate(-180 31 94)"
          fill="white"
        />
        <rect
          x="93"
          y="47"
          width="27"
          height="43"
          rx="1"
          transform="rotate(-180 93 47)"
          fill="white"
        />
        <rect
          x="62"
          y="47"
          width="27"
          height="43"
          rx="1"
          transform="rotate(-180 62 47)"
          fill="white"
        />
        <rect
          x="31"
          y="47"
          width="27"
          height="43"
          rx="1"
          transform="rotate(-180 31 47)"
          fill="white"
        />
        <path
          d="M190 96C190 97.1046 189.105 98 188 98L93 98L93 -8.48001e-06L188 -1.74846e-07C189.105 -7.8281e-08 190 0.895432 190 2L190 96Z"
          fill="#0A40CA"
        />
        <rect
          x="186"
          y="94"
          width="27"
          height="43"
          rx="1"
          transform="rotate(-180 186 94)"
          fill="#D3DFFF"
        />
        <rect
          x="186"
          y="47"
          width="27"
          height="43"
          rx="1"
          transform="rotate(-180 186 47)"
          fill="#D3DFFF"
        />
        <rect
          x="155"
          y="94"
          width="27"
          height="43"
          rx="1"
          transform="rotate(-180 155 94)"
          fill="#D3DFFF"
        />
        <rect
          x="155"
          y="47"
          width="27"
          height="43"
          rx="1"
          transform="rotate(-180 155 47)"
          fill="#D3DFFF"
        />
        <rect
          x="124"
          y="94"
          width="27"
          height="43"
          rx="1"
          transform="rotate(-180 124 94)"
          fill="#D3DFFF"
        />
        <rect
          x="124"
          y="47"
          width="27"
          height="43"
          rx="1"
          transform="rotate(-180 124 47)"
          fill="#D3DFFF"
        />
      </svg>
    </SvgIcon>
  );
};
