import { useZoomController } from '@hooks/useZoomController';
import { Box } from '@mui/material';
import { fullHeight } from '@utils/const';
import { isPlanogramBucketPlan, isPlanogramPlan } from '@utils/planogram';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc';
import { FC } from 'react';
import { Planogram, PreviewMode } from 'types/planogram';
import { AbsoluteMenuButtons } from './fragments/absoluteMenuButtons';
import { ThreeDPlanogram } from './fragments/threeDPlanogram';
import { TwoDFlatPlanogram } from './fragments/twoDFlatPlanogram';
import { TwoDPlanogram } from './fragments/twoDPlanogram';
import { usePreviewPlanogram } from '@hooks/usePreviewPlanogram';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  previewMode: PreviewMode;
  planogram: Planogram;
  previewUrl?: string;
  isError3dPreview: boolean;
  isLoading3dPreview: boolean;
  hiddenElement?: boolean;
  finishLoading: () => void;
  handleClose: () => void;
  handleCancelLoading3d: () => void;
  refetchThreeDPlanogram: () => Promise<void>;
  handleChangePreviewMode: (value: PreviewMode) => void;
};

export const PreviewPlanogram: FC<Props> = ({
  previewMode,
  isError3dPreview,
  isLoading3dPreview,
  previewUrl,
  planogram,
  finishLoading,
  handleClose,
  handleCancelLoading3d,
  refetchThreeDPlanogram,
  handleChangePreviewMode,
  hiddenElement,
}) => {
  const {
    isLoadingBayPlanViewSet,
    threeDViewParams,
    handleClickRotate,
    handleRotate,
    isTooltipOpen,
    closeTooltip,
    isViewPrint,
    handleViewPrint,
    handleReset3dView,
    isFlat,
  } = usePreviewPlanogram(planogram.bay_plan_id, previewMode);

  const { zoomScale, handleIncrementZoom, handleDecrementZoom } =
    useZoomController();

  const url = previewUrl ?? planogram.products_shelves_url;
  const isLoading3dPlanogram = isLoading3dPreview || isLoadingBayPlanViewSet;

  return (
    <Box component="div" sx={{ height: fullHeight }}>
      {previewMode === '3d' ? (
        <ThreeDPlanogram
          url={url}
          isError={isError3dPreview}
          isLoading={isLoading3dPlanogram}
          viewParams={threeDViewParams}
          finishLoading={finishLoading}
          handleClose={handleCancelLoading3d}
          refetchThreeDPlanogram={refetchThreeDPlanogram}
          isViewPrint={isViewPrint}
        />
      ) : (
        <>
          {isPlanogramPlan(planogram.plan) && (
            <TwoDPlanogram zoomScale={zoomScale} plan={planogram.plan} />
          )}
          {isPlanogramBucketPlan(planogram.plan) && (
            <TwoDFlatPlanogram zoomScale={zoomScale} plan={planogram.plan} />
          )}
        </>
      )}
      <AbsoluteMenuButtons
        hiddenElement={hiddenElement}
        previewMode={previewMode}
        planogram={planogram}
        zoomScale={zoomScale}
        isDisplay3dViewButton={!isLoading3dPlanogram}
        handleClose={handleClose}
        handleChangePreviewMode={handleChangePreviewMode}
        handleIncrementZoom={handleIncrementZoom}
        handleDecrementZoom={handleDecrementZoom}
        handleReset3dView={handleReset3dView}
        handleViewPrint={handleViewPrint}
        isViewPrint={isViewPrint}
        isFlat={isFlat}
        handleClickRotate={handleClickRotate}
        handleRotate={handleRotate}
        isTooltipOpen={isTooltipOpen}
        closeTooltip={closeTooltip}
      />
    </Box>
  );
};
