import { NotFound } from '@components/NotFound';
import { NotPermittedModal } from '@components/organisms/NotPermittedModal/NotPermittedModal';
import { PreviewPlanogram } from '@components/organisms/previewPlanogram/previewPlanogram';
import { usePageTitle } from '@hooks/usePageTitle';
import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import { Box } from '@mui/material';
import { useLoader } from '@react-three/fiber';
import {
  changeEditorMode,
  rotateGondola,
  rotateGondolaInCompare,
} from '@reducers/planogramEditor/reducer';
import { useGetPlanogramQuery } from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { baseUrl, embed, paths } from '@utils/const';
import httpStatus from 'http-status';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { PreviewMode } from 'types/planogram';

type Props = {
  previewMode: PreviewMode;
};

export const PlanogramPreview: FC<Props> = ({ previewMode }) => {
  const dispatch = useAppDispatch();
  // Local variables
  const { id = '0' } = useParams();
  const { data, isLoading, error, refetch } = useGetPlanogramQuery({
    planogramId: parseInt(id),
  });
  const isForbidden =
    !!error && 'status' in error && error.status === httpStatus.FORBIDDEN;
  const planogram = data?.planogram;
  const [isReFetching, setIsReFetching] = useState(true);
  const { selectedDirectoryId } = useAppSelector((state) => state.Planogram);
  const embedParam = new URLSearchParams(window.location.search).get('embed');
  usePageTitle(
    `${data?.planogram.name ?? ''} | ${previewMode.toLocaleUpperCase()}ビュー`
  );
  const navigate = useNavigate();
  const endpoint = baseUrl.replace('/api/v1', '');

  const handleGoBack = () => {
    dispatch(rotateGondola(0));
    dispatch(rotateGondolaInCompare(0));
    navigate(paths.plans.planogramId(Number(id)));
  };

  const handleCancelLoading3d = () => {
    navigate(`/plans/${id}/view/2d`);
  };

  const refetchThreeDPlanogram = async () => {
    try {
      await refetch();
    } catch (e) {
      console.error(e);
    }
  };

  usePlanogramPlan(planogram?.plan);

  useEffect(() => {
    dispatch(changeEditorMode('preview'));
    return () => {
      void dispatch(changeEditorMode('Viewer'));
      if (planogram?.id) {
        useLoader.clear(
          GLTFLoader,
          `${endpoint}${baseUrl}/media/planogram/${planogram.id}_main.gltf`
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This should be a mount and unmount effect
  }, [dispatch]);

  const handleChangePreviewMode = async (value: PreviewMode) => {
    if (value === '3d') {
      await refetchThreeDPlanogram();
    }
    if (embedParam === embed)
      navigate(`${paths.plans.preview(Number(id), value)}?embed=${embed}`);
    else navigate(paths.plans.preview(Number(id), value));
  };

  const finishLoading = useCallback(() => {
    setIsReFetching(false);
  }, []);

  if (
    Number.isNaN(Number(id)) ||
    (!!error && 'status' in error && error.status === httpStatus.NOT_FOUND)
  ) {
    return <NotFound title="棚割計画（棚エディタ）" />;
  }
  return (
    <Box component="div">
      {planogram && (
        <PreviewPlanogram
          planogram={planogram}
          previewMode={previewMode}
          previewUrl={`${baseUrl}/media/planogram/${planogram.id}_main.gltf`}
          isLoading3dPreview={isLoading || isReFetching}
          isError3dPreview={!!error || planogram.status === 'error'}
          finishLoading={finishLoading}
          refetchThreeDPlanogram={refetchThreeDPlanogram}
          handleClose={handleGoBack}
          handleCancelLoading3d={handleCancelLoading3d}
          handleChangePreviewMode={handleChangePreviewMode}
          hiddenElement={embedParam === embed}
        />
      )}
      <NotPermittedModal
        embedParam={embedParam ?? undefined}
        open={isForbidden}
        selectedDirectoryId={selectedDirectoryId}
        errorMessage="この棚割のアクセス権がありません"
        buttonMessage="棚割計画に戻る"
      />
    </Box>
  );
};
