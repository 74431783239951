import { RealogramFaceImage } from '@components/molecules/realogramFaceImage/realogramFaceImage';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, Stack, Typography } from '@mui/material';
import { imageBoxSize } from '@utils/const';
import { theme } from 'theme';
import { DynamicImage } from '@components/organisms/dynamicImage/dynamicImage';
import { Product, ProfitTab, ShelfDetailView } from 'types/common';
import { ProductCandidate, RealogramSelectedItem } from 'types/realogram';
import { ProfitInfo } from './profitInfo';
import { RealogramProductCandidateOptionalItem } from './realogramProductCandidateOptionalItem';
import { ProductSalesReport } from 'types/products';
import { ReactNode } from 'react';

type Props = {
  isBucketType: boolean;
  isProductUnknown?: boolean;
  realogramDetailView: ShelfDetailView;
  referenceImage: string;
  handleOpenReferenceImagePreview: VoidFunction;
  setSelectedProductZoomIn: (product?: Product) => void;
  product?: Product;
  selectedItem: RealogramSelectedItem;
  name: string;
  isShowJANAndCD: boolean;
  eanCodeValue: string;
  productCodeValue: string;
  isCanNotEditProductAndCompartment?: boolean;
  handleModalOpen: VoidFunction;
  productSalesReport?: ProductSalesReport[];
  profitTab?: ProfitTab;
  realogramCandidateId: number;
  handleOutOfStockModalOpen: VoidFunction;
  isTenantSalesAnalytics?: boolean;
  mostHighScoreProduct?: Product;
  mostHighScore?: ProductCandidate;
  handleChangeCandidate?: (mostHighScoreProduct: ProductCandidate) => void;
  isUnrevisedUnknownProduct?: boolean;
  slideButtons: ReactNode;
  displayAnalyticsDataIndex: number;
};

export const VerticalCandidate = ({
  isBucketType,
  isProductUnknown,
  realogramDetailView,
  referenceImage,
  product,
  selectedItem,
  name,
  isShowJANAndCD,
  eanCodeValue,
  productCodeValue,
  isCanNotEditProductAndCompartment,
  productSalesReport,
  profitTab,
  realogramCandidateId,
  isTenantSalesAnalytics,
  mostHighScoreProduct,
  mostHighScore,
  isUnrevisedUnknownProduct,
  slideButtons,
  displayAnalyticsDataIndex,
  handleOpenReferenceImagePreview,
  setSelectedProductZoomIn,
  handleModalOpen,
  handleOutOfStockModalOpen,
  handleChangeCandidate,
}: Props) => {
  return (
    <Box
      component="div"
      display="flex"
      flexGrow="1"
      flexDirection={!isBucketType ? 'column' : 'row'}
      position="relative"
      height={isBucketType ? '100%' : '250px'}
    >
      <Box component="div" display="flex" flexDirection="row" gap="2">
        <Box component="div" display="flex" justifyContent="flex-start" gap={1}>
          {!!referenceImage &&
            (realogramDetailView === 'default' ||
              realogramDetailView === 'rate') && (
              <Box
                component="div"
                mr={!isProductUnknown ? 0 : 1}
                sx={{
                  cursor: 'zoom-in',
                  borderRight: !isProductUnknown ? 'none' : '',
                  width: '80px',
                  height: '80px',
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  if (isProductUnknown) handleOpenReferenceImagePreview();
                  else {
                    if (isProductUnknown || !setSelectedProductZoomIn) return;
                    handleOpenReferenceImagePreview();
                    setSelectedProductZoomIn(product);
                  }
                }}
              >
                <DynamicImage
                  src={referenceImage}
                  alt=""
                  layout="fill"
                  objectFit="contain"
                />
                {isProductUnknown && (
                  <ZoomInIcon
                    sx={{
                      zIndex: 999,
                      fontSize: 24,
                      position: 'absolute',
                      right: '4px',
                      bottom: '4px',
                      color: '#838583',
                    }}
                  />
                )}
              </Box>
            )}
          {!isProductUnknown && (
            <Box
              component="div"
              sx={{
                cursor: !isProductUnknown ? 'zoom-in' : '',
                width: '80px',
                height: '80px',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => {
                if (!setSelectedProductZoomIn) return;
                handleOpenReferenceImagePreview();
                setSelectedProductZoomIn(product);
              }}
            >
              <Box
                component="div"
                width="69px"
                height="69px"
                position="relative"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <RealogramFaceImage
                  product={product}
                  size={imageBoxSize}
                  face={selectedItem.item}
                  baseSize={imageBoxSize}
                  fontSize={24}
                />
                {!isProductUnknown && (
                  <ZoomInIcon
                    sx={{
                      zIndex: 999,
                      fontSize: 24,
                      position: 'absolute',
                      right: selectedItem.item.is_unknown ? '-25px' : '-10px',
                      color: '#838583',
                      bottom: '-4px',
                    }}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
        {/* 分析タブの時の画像下の情報 */}
        {realogramDetailView === 'profit' && (
          <Box component="div">
            <Typography fontWeight={500}>{name}</Typography>
            <ProfitInfo eanCode={eanCodeValue} productCode={productCodeValue} />
          </Box>
        )}
      </Box>
      <Stack
        component="div"
        my={isBucketType ? 0 : { xs: 1, breakpoint: 0 }}
        mx={isBucketType ? 0 : { xs: 1, breakpoint: 0 }}
        width="100%"
      >
        {isShowJANAndCD && (
          <Box
            component="div"
            sx={{
              display: 'flex',
              columnGap: 5,
            }}
            mb={1}
          >
            <Typography
              sx={{
                fontSize: 12,
                color: theme.palette.textBlack.secondary,
              }}
            >
              JAN：{eanCodeValue}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: theme.palette.textBlack.secondary,
              }}
            >
              商品CD：{productCodeValue}
            </Typography>
          </Box>
        )}
        <RealogramProductCandidateOptionalItem
          isCanNotEditProductAndCompartment={isCanNotEditProductAndCompartment}
          detailView={realogramDetailView}
          product={product}
          selectedItem={selectedItem}
          handleModalOpen={handleModalOpen}
          productSalesReport={productSalesReport}
          profitTab={profitTab}
          realogramCandidateId={realogramCandidateId}
          handleOutOfStockModalOpen={handleOutOfStockModalOpen}
          isTenantSalesAnalytics={isTenantSalesAnalytics}
          mostHighScoreProduct={mostHighScoreProduct}
          mostHighScore={mostHighScore}
          handleChangeCandidate={handleChangeCandidate}
          isUnrevisedUnknownProduct={isUnrevisedUnknownProduct}
          slideButtons={slideButtons}
          displayAnalyticsDataIndex={displayAnalyticsDataIndex}
        />
      </Stack>
      {realogramDetailView !== 'profit' && !isUnrevisedUnknownProduct && (
        <>{slideButtons}</>
      )}
    </Box>
  );
};
