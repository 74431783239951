import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultDirectoryType } from '@utils/const';
import { PlanogramDirectoryParent } from 'types/planogram';
import { DirectoryType } from 'types/sharePermission';

export type CurrentSelectedType = 'actual' | 'plan';
export type FileType = 'root' | 'file' | 'directory' | 'trash';

type SharePermissionState = {
  directoryId: string;
  directoryType: DirectoryType;
  isSuccess: boolean;
  isViewFolder?: boolean;
  planogramDirectoryParent?: PlanogramDirectoryParent;
  currentTypeFile?: FileType;
};

const initialState: SharePermissionState = {
  directoryId: '',
  directoryType: defaultDirectoryType,
  isSuccess: false,
};

const sharePermissionSlice = createSlice({
  name: 'sharePermission',
  initialState,
  reducers: {
    setDirectoryId: (
      state: SharePermissionState,
      action: PayloadAction<string>
    ) => {
      state.directoryId = action.payload;
    },
    setDirectoryType: (
      state: SharePermissionState,
      action: PayloadAction<DirectoryType>
    ) => {
      state.directoryType = action.payload;
    },
    setIsSuccess: (
      state: SharePermissionState,
      action: PayloadAction<boolean>
    ) => {
      state.isSuccess = action.payload;
    },
    setIsViewFolder: (
      state: SharePermissionState,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.isViewFolder = action.payload;
    },
    setPlanogramDirectoryParent: (
      state: SharePermissionState,
      action: PayloadAction<PlanogramDirectoryParent | undefined>
    ) => {
      state.planogramDirectoryParent = action.payload;
    },
    setCurrentTypeFile: (
      state: SharePermissionState,
      action: PayloadAction<FileType | undefined>
    ) => {
      state.currentTypeFile = action.payload;
    },
  },
});

export const {
  setDirectoryId,
  setDirectoryType,
  setIsSuccess,
  setIsViewFolder,
  setPlanogramDirectoryParent,
  setCurrentTypeFile,
} = sharePermissionSlice.actions;
export const SharePermissionReducer = sharePermissionSlice.reducer;
