import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const DragBar = () => (
  <Box
    component="div"
    sx={{
      position: 'absolute',
      top: '0',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
      WebkitTransform: 'translate(-50%, -50%)',
      msTransform: 'translate(-50%, -50%)',
      zIndex: 3,
    }}
  >
    <StyledBox />
    <StyledBox />
  </Box>
);

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
const StyledBox = styled(Box)(() => ({
  margin: 4,
  height: 3,
  width: 30,
  backgroundColor: '#444444',
  borderRadius: 20,
}));
