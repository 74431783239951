import { FC, PropsWithChildren } from 'react';
import { Box, Paper, Typography } from '@mui/material';

type Props = {
  title: string;
};

export const FormPaper: FC<PropsWithChildren<Props>> = ({
  title,
  children,
}) => {
  return (
    <Paper
      sx={{
        padding: { xs: '32px 16px', sm: '32px 16px', md: '32px 32px' },
        width: 440,
        boxShadow: { xs: 0, sm: 0, md: 6 },
      }}
    >
      <Box component="div" textAlign="center">
        <Typography sx={{ fontSize: 32, fontWeight: 700 }}>{title}</Typography>
      </Box>
      {children}
    </Paper>
  );
};
