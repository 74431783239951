import { BayPartCard } from '@components/organisms/bayPartCard/bayPartCard';
import { headerHeight } from '@components/organisms/header/header';
import { Grid, Typography } from '@mui/material';
import {
  gridColBayPartDefault,
  gridColBayPartInCompareMode,
} from '@utils/bayPart';
import {
  fontSizeDefault,
  fontSizeInCompareMode,
  fullHeight,
} from '@utils/const';
import { FC } from 'react';
import { BayPartCategory } from 'types/bayPlan';

type Props = {
  categories?: BayPartCategory[];
  handleClick: (category: BayPartCategory) => void;
  selectedBayPartCategory?: BayPartCategory;
  isCompareMode?: boolean;
};

const tabAndCategoryNameHeight = 120;

export const BayPartCategoriesList: FC<Props> = ({
  categories,
  handleClick,
  selectedBayPartCategory,
  isCompareMode,
}) => {
  return (
    <Grid
      container
      spacing={{ xs: 1 }}
      columns={{ xs: 12 }}
      overflow="auto"
      maxHeight={`calc(${fullHeight} - ${headerHeight}px - ${tabAndCategoryNameHeight}px)`}
      sx={{ marginTop: '0 !important' }}
    >
      {categories?.map((category, index) => {
        return (
          //when its root categories it should display as 3 items per row, when children categories as 2 items per row
          <Grid
            item
            xs={
              isCompareMode
                ? gridColBayPartInCompareMode
                : !selectedBayPartCategory
                ? gridColBayPartDefault
                : gridColBayPartInCompareMode
            }
            key={index}
          >
            <BayPartCard
              isCompareMode={isCompareMode}
              type={category.type}
              handleSelectedBayPart={() => handleClick(category)}
              isSelectedBayPlanBayPart={false} //because category not bayPart
              isSelectedPlanogramBayPart={false} //because category not bayPart
              // only first categories shown as vertical items
              direction={!selectedBayPartCategory ? 'column' : 'row'}
            >
              <Typography
                sx={{ textAlign: 'left', width: '100%' }}
                fontSize={
                  isCompareMode ? fontSizeInCompareMode : fontSizeDefault
                }
              >
                {category.name}
              </Typography>
            </BayPartCard>
          </Grid>
        );
      })}
    </Grid>
  );
};
