import { FC } from 'react';
import { theme } from 'theme';
import { Button } from '@mui/material';
import { RealogramType, RealogramUploadStep } from 'types/realogram';
import { useSkipRealogramStepThree } from '../../hooks/useSkipRealogramStepThree';

type Props = {
  currentStep: RealogramUploadStep;
  selectedRealogramType: RealogramType;
  changeStep: (
    step: RealogramUploadStep,
    isNext: boolean,
    isSkipRealogramStepThreeOn?: boolean
  ) => void;
  onFirstFileSelect?: (file: File) => void;
  isFlat: boolean;
  disabled: boolean;
  baseButtonStyle: { [key: string]: string | number };
};

export const NextButton: FC<Props> = ({
  currentStep,
  selectedRealogramType,
  changeStep,
  onFirstFileSelect,
  isFlat,
  disabled,
  baseButtonStyle,
}) => {
  const isNotSelected = selectedRealogramType === '';
  const bgColor = isNotSelected
    ? theme.palette.backgroundBlack.disabled
    : theme.palette.primary.main;
  const textColor = isNotSelected
    ? theme.palette.textBlack.disabled
    : theme.palette.white.primary;
  const { isSkipRealogramStepThreeOn, isSkipFlatRealogramStepThreeOn } =
    useSkipRealogramStepThree();

  switch (currentStep) {
    case 'one':
      return (
        <Button
          variant="contained"
          aria-describedby="menuButton"
          component="label"
          disabled={isNotSelected}
          sx={{
            ...baseButtonStyle,
            width: '72px',
            backgroundColor: bgColor,
            color: textColor,
            '&:hover': {
              backgroundColor: theme.palette.primary.mainHover,
            },
          }}
          onClick={() => changeStep(currentStep, true)}
        >
          次へ
        </Button>
      );
    case 'two':
      // StepTwoの次へボタンは次回から表示しないがtrueになっているか、通常か平置きかで変わる
      switch (true) {
        case !isFlat && isSkipRealogramStepThreeOn:
          return (
            <Button
              variant="text"
              aria-describedby="menuButton"
              component="label"
              sx={{
                ...baseButtonStyle,
                width: '72px',
                backgroundColor: bgColor,
                color: textColor,
                '&:hover': {
                  backgroundColor: theme.palette.primary.mainHover,
                },
              }}
            >
              <input
                type="file"
                accept="image/jpeg"
                onChange={(e) => {
                  if (e.target.files && onFirstFileSelect) {
                    onFirstFileSelect(e.target.files[0]);
                    changeStep('two', true, isSkipRealogramStepThreeOn);
                  }
                }}
                hidden
              />
              撮影
            </Button>
          );
        case isFlat && isSkipFlatRealogramStepThreeOn:
          return (
            <Button
              variant="text"
              aria-describedby="menuButton"
              component="label"
              sx={{
                ...baseButtonStyle,
                width: '136px',
                backgroundColor: bgColor,
                color: textColor,
                '&:hover': {
                  backgroundColor: theme.palette.primary.mainHover,
                },
              }}
            >
              <input
                type="file"
                accept="image/jpeg"
                onChange={(e) => {
                  if (e.target.files && onFirstFileSelect) {
                    onFirstFileSelect(e.target.files[0]);
                    changeStep('two', true, isSkipFlatRealogramStepThreeOn);
                  }
                }}
                hidden
              />
              左半分を撮影
            </Button>
          );
        default:
          return (
            <Button
              variant="contained"
              aria-describedby="menuButton"
              component="label"
              sx={{
                ...baseButtonStyle,
                width: '72px',
                backgroundColor: bgColor,
                color: textColor,
                '&:hover': {
                  backgroundColor: theme.palette.primary.mainHover,
                },
              }}
              onClick={() => changeStep(currentStep, true)}
            >
              次へ
            </Button>
          );
      }
    case 'three':
      return (
        <Button
          variant="contained"
          aria-describedby="menuButton"
          component="label"
          sx={{
            ...baseButtonStyle,
            width: `${isFlat ? '136px' : '72px'}`,
            backgroundColor: bgColor,
            color: textColor,
            '&:hover': {
              backgroundColor: theme.palette.primary.mainHover,
            },
          }}
        >
          <input
            type="file"
            accept="image/jpeg"
            onChange={(e) => {
              if (e.target.files && onFirstFileSelect) {
                onFirstFileSelect(e.target.files[0]);
                changeStep(currentStep, true);
              }
            }}
            hidden
          />
          {isFlat ? '左半分を撮影' : '撮影'}
        </Button>
      );
    case 'four':
      return (
        <Button
          variant="contained"
          aria-describedby="menuButton"
          type="submit"
          disabled={disabled}
          sx={{
            ...baseButtonStyle,
            width: '135px',
            backgroundColor: bgColor,
            color: textColor,
            '&:hover': {
              backgroundColor: theme.palette.primary.mainHover,
            },
          }}
        >
          アップロード
        </Button>
      );
    case 'five':
      return <></>;
  }
};
