import { selectAccessToken } from '@reducers/auth/selectors';
import {
  useInitializeMfaMutation,
  useSignInMutation,
  useVerifyMfaDeviceMutation,
} from '@reducers/shelfAppsApi';
import { useAppSelector } from '@store/index';
import { isMfaRegistered as checkMfaRegistered } from '@utils/users';
import { useCallback, useState } from 'react';
import {
  Step,
  authAppAddition,
  authAppInstallation,
  mfaCompleted,
  oneTimePassword,
  passwordInput,
  userPasswordAuth,
} from '../utils/const';
import { OneTimePasswordFormData } from '../utils/oneTimePassword';

export const useMfaStep = (email: string) => {
  const [step, setStep] = useState<Step>('initial');
  const accessToken = useAppSelector(selectAccessToken);
  const [initializeMfa, { error: requestingError, isLoading: isRequesting }] =
    useInitializeMfaMutation();
  const [secretCode, setSecretCode] = useState<string>();
  const [isSignInFailed, setIsSignInFailed] = useState(false);
  const [isMfaRegistered, setIsMfaRegistered] = useState(false);
  const [login, { isLoading: isSigning }] = useSignInMutation();
  const [verifyMfaDevice, { isLoading: isVerifying, isError: verifyingError }] =
    useVerifyMfaDeviceMutation();

  const signIn = useCallback(
    async (password: string) => {
      setIsSignInFailed(false);
      try {
        const result = await login({
          username: email,
          password,
          authFlow: userPasswordAuth,
        }).unwrap();

        if (checkMfaRegistered(result)) {
          //NOTE: MFAを登録しているユーザーがAuthenticatorアプリのインストール画面に行くとエラーになる
          setIsMfaRegistered(true);
          return;
        }
        setStep(authAppInstallation);
      } catch (err) {
        console.error(err);
        setIsSignInFailed(true);
      }
    },
    [email, login]
  );

  const request = useCallback(async () => {
    try {
      const { secret_code: secretCode } = await initializeMfa({
        accessToken,
      }).unwrap();
      setSecretCode(secretCode);
      setStep(authAppAddition);
    } catch (err) {
      // TODO: エラーになった時の処理を検討する
      console.error(err);
    }
  }, [accessToken, initializeMfa]);

  const verify = useCallback(
    async (data: OneTimePasswordFormData) => {
      const { confirmCode } = data;
      const payload = { accessToken, mfaToken: confirmCode };
      try {
        await verifyMfaDevice(payload).unwrap();
        setStep(mfaCompleted);
      } catch (err) {
        // TODO: エラーになった時の処理を検討する
        console.error(err);
      }
    },
    [accessToken, verifyMfaDevice]
  );

  return {
    step,
    secretCode,

    isSignInFailed,
    isMfaRegistered,
    // 次のステップに進む
    goPasswordInputStep: () => setStep(passwordInput),
    goOneTimePasswordStep: () => setStep(oneTimePassword),
    // ログイン
    signIn,
    isSigning,
    // 初期化
    request,
    isRequesting,
    requestingError,
    // 検証
    verify,
    isVerifying,
    verifyingError,
  };
};
