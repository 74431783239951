import { useState } from 'react';
import { selectAccessToken } from '@reducers/auth/selectors';
import {
  useChangePasswordMutation,
  useGetMfaDeviceQuery,
} from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { Password } from 'types/user';
import { toastMessages } from '@utils/const';

export const useChangePassword = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(selectAccessToken);
  const { data: mfaDeviceData, isLoading: isMfaDeviceLoading } =
    useGetMfaDeviceQuery({
      accessToken,
    });
  const [isPasswordChangeMode, setIsPasswordChangeMode] = useState(false);
  const [
    changePassword,
    { error: changePasswordError, isLoading: isChangePasswordLoading },
  ] = useChangePasswordMutation();

  const handleChangePassword = async ({
    currentPassword,
    newPassword,
  }: {
    currentPassword: Password;
    newPassword: Password;
  }) => {
    try {
      await changePassword({
        accessToken,
        oldPassword: currentPassword,
        newPassword,
      }).unwrap();
      setIsPasswordChangeMode(false);
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.changePassword,
        })
      );
    } catch (err) {
      console.error(err);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.changePassword,
        })
      );
    }
  };

  return {
    mfaDeviceData,
    isMfaDeviceLoading,
    isPasswordChangeMode,
    changePasswordError,
    isChangePasswordLoading,
    setIsPasswordChangeMode,
    handleChangePassword,
  };
};
