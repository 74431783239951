import { useState } from 'react';
import { selectEmail } from '@reducers/auth/selectors';
import { useSignInMutation } from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { Password } from 'types/user';
import { authFlow } from '../utils/const';
import { openToast } from '@reducers/toast';
import { toastMessages } from '@utils/const';

export const useEmailPasswordLogin = (
  setDeleteMfaOpen: (deleteMfaOpen: boolean) => void
) => {
  const dispatch = useAppDispatch();
  const email = useAppSelector(selectEmail);
  const [signIn, { isLoading: isSignInLoading, isError: isSignInError }] =
    useSignInMutation();
  const [isPasswordInputOpen, setIsPasswordInputOpen] = useState(false);

  const handleEmailPasswordLogin = async (password: Password) => {
    try {
      await signIn({
        username: email ?? '',
        password,
        authFlow,
      }).unwrap();
      setIsPasswordInputOpen(false);
      setDeleteMfaOpen(true);
    } catch (err) {
      console.log(err);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.login,
        })
      );
    }
  };

  return {
    email,
    isPasswordInputOpen,
    setIsPasswordInputOpen,
    isSignInLoading,
    isSignInError,
    handleEmailPasswordLogin,
  };
};
