import { paths } from 'types/api';
import { baseApi } from '../baseApi';

type GetBayPlanCodesResponse =
  paths['/api/v1/bay_plan_codes']['get']['responses']['200']['content']['application/json'];

// see) https://staging.shelf.pfn.dev/docs#/bay_plans
export const bayPlanCodesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    listBayPlanCodes: builder.query<GetBayPlanCodesResponse, void>({
      query: () => ({
        url: 'bay_plan_codes',
      }),
    }),
  }),
});

export const { useListBayPlanCodesQuery } = bayPlanCodesApi;
