import { storeBaysApi } from '@api/services/storeBays';
import { TableHeader } from '@components/pages/realograms/fragments/realogramsDirectoryTable/tableHeader';
import { selectUser } from '@reducers/auth/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useQuery } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { createPortal } from 'react-dom';
import { RealogramSharePermissionProps } from 'types/sharePermission';
import { StoreBay } from 'types/storeBay';
import { useDeleteStoreBay } from '../hooks/useDeleteStoreBay';
import { useEditStoreBay } from '../hooks/useEditStoreBay';
import { DeleteStoreBayDialog } from './deleteStoreBayDialog';
import { StoreBayTableCells } from './storeBayTableCells';
import { getStoreBayColumns } from './storeBayTableHeaderData';
import { TableRowWrapper } from './tableRowWrapper';
import { DeleteStoreBayAlert } from './deleteStoreBayAlert';
import { openToast } from '@reducers/toast';
import { DataTable } from '@components/organisms';

type Props = {
  storeId: number;
  canEditStoreBay: boolean;
  canReadStoreBay: boolean;
  emptyTableMessage: string;
  emptyTableTitle: string;
  handleFavoriteClick: (id: string, isFavorite: boolean) => void;
  handleSharePermission: (item: RealogramSharePermissionProps) => void;
};

export const storeBaysQueryKey = 'store_bays';

export const StoreBaysTable: FC<Props> = ({
  storeId,
  canEditStoreBay,
  canReadStoreBay,
  emptyTableMessage,
  emptyTableTitle,
  handleFavoriteClick,
  handleSharePermission,
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteStoreBayAlert, setDeleteStoreBayAlert] = useState(false);
  const [storeBayIdToDelete, setStoreBayIdToDelete] = useState(0);

  const {
    isLoading,
    isFetching,
    data: storeBays,
  } = useQuery({
    queryKey: [storeBaysQueryKey, storeId],
    queryFn: () => storeBaysApi.getStoreBays(storeId),
    select: (data) => data.store_bays,
  });
  const { handleEditStoreBay } = useEditStoreBay();
  const { handleDeleteStoreBay } = useDeleteStoreBay({
    onSettled: () => setDeleteDialogOpen(false),
    onError: () => {
      setDeleteDialogOpen(false);
      setDeleteStoreBayAlert(true);
      dispatch(
        openToast({
          type: 'error',
          message: '什器の削除に失敗しました。',
        })
      );
    },
  });

  const isEmpty = !storeBays?.length && !isLoading;
  const isDisplayLoadingSkeleton = isLoading || isFetching;
  const columns = getStoreBayColumns();

  return (
    <>
      <DataTable<StoreBay>
        handleEndReached={() => void 0}
        isRefetching={false}
        isEmpty={isEmpty}
        isDisplayLoadingSkeleton={isDisplayLoadingSkeleton}
        data={storeBays}
        columns={columns}
        emptyTableMessage={emptyTableMessage}
        emptyTableTitle={emptyTableTitle}
        tableHeaderContent={<TableHeader columns={columns} />}
        itemContent={(_, item) => {
          const isStarred = user?.id === item.favorite?.owner_id;
          return (
            <StoreBayTableCells
              storeBay={item}
              canEditStoreBay={canEditStoreBay}
              canReadStoreBay={canReadStoreBay}
              handleDelete={() => {
                setDeleteDialogOpen(true);
                setStoreBayIdToDelete(item.id);
              }}
              handleEdit={() => handleEditStoreBay(item)}
              handleFavoriteClick={() =>
                handleFavoriteClick(item.directory_id, isStarred)
              }
              handleSharePermission={() =>
                handleSharePermission({
                  directoryId: item.directory_id,
                  type: 'directory',
                })
              }
              isStarred={isStarred}
            />
          );
        }}
        renderTableRow={(props) => {
          return (
            <TableRowWrapper
              // TODO: Add handler
              handleRowClick={() => void 0}
              {...props}
              item={{
                // eslint-disable-next-line react/prop-types -- prop types is outdated
                ...props.item,
                type: 'directory',
              }}
            />
          );
        }}
      />
      {createPortal(
        <DeleteStoreBayDialog
          open={deleteDialogOpen}
          handleClickCancelButton={() => setDeleteDialogOpen(false)}
          handleClickConfirmButton={() =>
            handleDeleteStoreBay(storeBayIdToDelete)
          }
        />,
        document.body
      )}

      {createPortal(
        <DeleteStoreBayAlert
          open={deleteStoreBayAlert}
          handleClickCancelButton={() => setDeleteStoreBayAlert(false)}
        />,
        document.body
      )}
    </>
  );
};
