import { CommonColors } from '@utils/colors';
import { Button } from '@mui/material';
import { FC } from 'react';

type Props = {
  handleClick: () => void;
};

export const DeleteButton: FC<Props> = ({ handleClick }) => {
  return (
    <Button
      sx={{
        color: CommonColors.red,
        ':hover': { bgcolor: CommonColors.lightGray },
      }}
      onClick={handleClick}
    >
      削除
    </Button>
  );
};
