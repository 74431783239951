import { TextPaper } from '@components/molecules';
import { Box, Divider } from '@mui/material';
import { FC } from 'react';
import { ProductTag, Rate, RateValue, ShelfDetailView } from 'types/common';
import { ProductCards } from '../productCards/productCards';
import { StyledTabs } from '../productFlagChips/productFlagChips';
import { RateCards } from '../rateCards/rateCards';
import { theme } from 'theme';
import { ProductTagValue } from 'types/statistics';

type Props = {
  score?: number;
  rateValue: Rate;
  productTag: ProductTag;
  rateValues: RateValue[];
  hasSelected?: boolean;
  isPlan?: boolean;
  productTagValues: ProductTagValue[];
  detailView: ShelfDetailView;
  handleChangeProductTag: (productTag: ProductTag) => void;
  handleChangeRateValue: (value: Rate) => void;
};

//changing the rendering logic does not depend on the tabs, it depends on children of tabs,
//so setting a value other than 0 doesn't make sense
const defaultTabValue = 0;

export const ShelfDetailTabs: FC<Props> = ({
  score,
  rateValue,
  productTag,
  rateValues,
  hasSelected,
  productTagValues,
  detailView,
  isPlan = false,
  handleChangeRateValue,
  handleChangeProductTag,
}) => {
  switch (detailView) {
    case 'productFlag':
      return (
        <>
          <Box
            component="div"
            p={2}
            borderTop={`1px solid ${theme.palette.dividerBlack.medium}`}
          >
            <StyledTabs
              value={defaultTabValue}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <ProductCards
                productTag={productTag}
                productTagValues={productTagValues}
                handleChangeProductTag={handleChangeProductTag}
              />
            </StyledTabs>
          </Box>
          {hasSelected && <Divider />}
        </>
      );
    case 'profit':
      return <></>;
    case 'rate': {
      return (
        <>
          <Box component="div" p={2}>
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', breakpoint: 'row' },
                columnGap: 2,
              }}
            >
              <TextPaper
                title="スコア"
                text={`${score || score === 0 ? `${score}点` : ''}`}
                paperSx={{
                  height: 54,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              />
              {!isPlan && (
                <>
                  <Divider
                    flexItem
                    sx={{
                      display: { breakpoint: 'none' },
                      my: { xs: 2 },
                      mx: { xs: -2 },
                    }}
                  />
                  <StyledTabs
                    value={defaultTabValue}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                  >
                    <RateCards
                      rateValue={rateValue}
                      rateValues={rateValues}
                      handleChangeRateValue={handleChangeRateValue}
                    />
                  </StyledTabs>
                </>
              )}
            </Box>
          </Box>
          {hasSelected && <Divider />}
        </>
      );
    }
    default:
      return <></>;
  }
};
