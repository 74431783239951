import {
  Box,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { displayRoleName } from '@utils/const';
import { FC } from 'react';
import { theme } from 'theme';
import {
  DirectoryFormType,
  DirectoryRoleUserInfo,
  ShareRole,
} from 'types/sharePermission';
import { User } from 'types/user';

type Props = {
  isOnlyViewed: boolean;
  directoryRoles?: DirectoryRoleUserInfo[];
  userInfo?: User;
  type: DirectoryFormType;
  handleEditDirectoryRole: (
    directoryRole: DirectoryRoleUserInfo,
    role: ShareRole,
    type: DirectoryFormType
  ) => void;
};

export const PermissionMembers: FC<Props> = ({
  isOnlyViewed,
  directoryRoles,
  userInfo,
  type,
  handleEditDirectoryRole,
}) => {
  return (
    <List
      component="div"
      sx={{
        marginBottom: '15px',
      }}
    >
      {directoryRoles?.map((directoryRole) => (
        <ListItem
          key={directoryRole.id}
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
            '&:not(:last-child)': {
              marginBottom: '10px',
            },
          }}
        >
          <Box component="div" flex={1}>
            <Typography color={theme.palette.textBlack.primary}>
              {directoryRole?.user_id === userInfo?.id
                ? `自分（${directoryRole?.user?.fullname}）`
                : directoryRole.user.fullname}
            </Typography>
            <Typography
              fontSize={14}
              color={`${theme.palette.textBlack.secondary}`}
            >
              {directoryRole.user.email}
            </Typography>
          </Box>

          {!isOnlyViewed ? (
            <FormControl
              size="small"
              sx={{
                flex: '0 1 136px',
              }}
            >
              <InputLabel id={`edit-role-label-${directoryRole.id}`}>
                権限
              </InputLabel>
              <Select
                label="権限"
                labelId={`edit-role-label-${directoryRole.id}`}
                value={directoryRole.role}
                onChange={(e) => {
                  handleEditDirectoryRole(
                    directoryRole,
                    e.target.value as ShareRole,
                    type
                  );
                }}
              >
                <MenuItem value="editor">編集者</MenuItem>
                <MenuItem value="viewer">閲覧者</MenuItem>
                <MenuItem value="delete">権限を削除</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <Typography
              color={`${theme.palette.textBlack.disabled}`}
              sx={{ textAlign: 'right' }}
            >
              {displayRoleName(directoryRole.role)}
            </Typography>
          )}
        </ListItem>
      ))}
    </List>
  );
};
