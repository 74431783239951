import { AlertColor } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

type ToastState = {
  toastProps: {
    open: boolean;
    type: AlertColor;
    message: string;
    renderProps?: ReactNode;
  };
};

type ToastPayload = Omit<ToastState['toastProps'], 'open'>;

const initialState: ToastState = {
  toastProps: {
    open: false,
    type: 'success',
    message: '',
  },
};

const toastSlice = createSlice({
  name: 'Toast-Reducer',
  initialState,
  reducers: {
    openToast: (state: ToastState, action: PayloadAction<ToastPayload>) => {
      state.toastProps = {
        ...action.payload,
        open: true,
      };
    },
    closeToast: (state: ToastState) => {
      state.toastProps = {
        ...state.toastProps,
        open: false,
        renderProps: undefined,
      };
    },
  },
});

export const { openToast, closeToast } = toastSlice.actions;

export const ToastReducer = toastSlice.reducer;
