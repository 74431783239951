import { ListItemWithArrowForward } from '@components/organisms/searchList/fragments/listItemWithArrowForward';
import { Search } from '@mui/icons-material';
import { Box, Divider, List, TextField, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { ChangeEvent, FC, useState } from 'react';
import { Store } from 'types/common';

type Props = {
  title: string;
  placeHolder: string;
  list: Store[];
  selectedItem?: number;
  handleClick: (id: number) => void;
  hasArrowForward?: boolean;
};

export const SearchList: FC<Props> = ({
  title,
  placeHolder,
  list,
  selectedItem,
  handleClick,
  hasArrowForward = false,
}) => {
  const [condition, setCondition] = useState('');
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCondition(e.target.value);
  };
  const conditionedList = list.filter((item) => item?.name.match(condition));
  return (
    <>
      <Typography
        variant="subtitle2"
        fontWeight="600"
        px={2}
        py={1.5}
        sx={{ backgroundColor: '#F5F5F5' }}
      >
        {title}
      </Typography>
      <Divider />
      <Box component="div" px={2} py={1}>
        <TextField
          fullWidth
          placeholder={placeHolder}
          type="search"
          InputProps={{
            startAdornment: (
              <Search sx={{ color: CommonColors.grayLight, mr: 1.5 }} />
            ),
          }}
          onChange={handleOnChange}
        />
      </Box>
      <Divider />
      <List
        disablePadding
        sx={{
          overflow: 'auto',
          maxHeight: 320,
          position: 'sticky',
        }}
      >
        {conditionedList.map((item, index) => {
          const isSelected = item.id === selectedItem;
          return (
            <ListItemWithArrowForward
              key={index}
              name={item.name}
              hasArrowForward={hasArrowForward}
              isSelected={isSelected}
              onClick={() => handleClick(item.id)}
            />
          );
        })}
      </List>
    </>
  );
};
