import yn from 'yn';

export const featureFlags: Record<string, boolean> = {
  planogramEditor: true,
  ptsImport: yn(process.env.NEXT_PUBLIC_FEATURE_PTS_IMPORT, {
    default: false,
  }),
  emailPasswordLogin: yn(process.env.NEXT_PUBLIC_FEATURE_EMAIL_PASSWORD_LOGIN, {
    default: true,
  }),
  ssoLogin: yn(process.env.NEXT_PUBLIC_FEATURE_SSO_LOGIN, {
    default: false,
  }),
};
