import { GoBackButton } from '@components/molecules/goBackButtonIcon/goBackButton';
import { Typography } from '@mui/material';
import { FC } from 'react';
import { ProductCategory } from 'types/productCategories';

type Props = {
  handleClick: VoidFunction;
  categoryIndex: number;
  productCategoryHistory: ProductCategory[];
};

export const CategoryTitle: FC<Props> = ({
  handleClick,
  categoryIndex,
  productCategoryHistory,
}) => {
  return (
    <Typography
      onClick={handleClick}
      component="h2"
      sx={{
        fontSize: '16px',
        fontWeight: 700,
        letterSpacing: '0.15px',
        cursor: categoryIndex > 0 ? 'pointer' : 'default',
        display: 'inline-flex',
        alignItems: 'center',
        lineHeight: '175%',
      }}
    >
      {categoryIndex > 0 && <GoBackButton width={32} height={32} />}
      {categoryIndex > 0
        ? productCategoryHistory[categoryIndex]?.name
        : 'カテゴリ'}
    </Typography>
  );
};
