import {
  Control,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormStateReturn,
} from 'react-hook-form';
import { UserRole } from 'types/user';
import { InferType, mixed, object, string } from 'yup';
import { MixedSchema } from 'yup/lib/mixed';
import { AnyObject, AssertsShape } from 'yup/lib/object';
import { RequiredStringSchema } from 'yup/lib/string';

export const schema = object({
  fullname: string()
    .required('名前を入力してください。')
    .matches(
      /^(?![\s\u3000]*$).*/,
      '半角スペースまたは全角スペースのみで登録することはできません。'
    ),
  email: string()
    .required('メールアドレスを入力してください')
    .email('メールアドレスを正しい形式で入力してください。'),
  role: mixed<UserRole>().oneOf(['user', 'admin']),
  isActive: mixed<'true' | 'false'>().oneOf(['true', 'false']),
});

export type UserFormData = InferType<typeof schema>;

type Shape = {
  fullname: RequiredStringSchema<string | undefined, AnyObject>;
  email: RequiredStringSchema<string | undefined, AnyObject>;
  role: MixedSchema<UserRole | undefined, AnyObject>;
  isActive: MixedSchema<'true' | 'false' | undefined, AnyObject>;
};

export type UserFormControl = Control<AssertsShape<Shape>>;
export type UserFormSubmit = UseFormHandleSubmit<AssertsShape<Shape>>;
export type UserFormReset = UseFormReset<AssertsShape<Shape>>;
export type UsetFormErrors = UseFormStateReturn<AssertsShape<Shape>>['errors'];
