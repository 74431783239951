import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

const initialSize = 20;

export const StarIcon: FC<SvgIconProps> = (props) => {
  const { htmlColor = 'rgba(0, 0, 0, 0.38)', ...otherProps } = props;
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      sx={{
        height: props.height ?? initialSize,
        width: props.width ?? initialSize,
      }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M9.99935 14.8084L15.1494 17.9167L13.7827 12.0584L18.3327 8.11671L12.341 7.60837L9.99935 2.08337L7.65768 7.60837L1.66602 8.11671L6.21602 12.0584L4.84935 17.9167L9.99935 14.8084Z"
        fill={htmlColor}
      />
    </SvgIcon>
  );
};
