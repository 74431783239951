import { ErrorOutlineRounded } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';

type Props = {
  title?: string;
  message?: string;
  handleClick: () => void;
};

//TODO: EmptyResult.tsxと共通化検討
export const Error: FC<Props> = ({
  title = 'エラーが発生しました',
  message,
  handleClick,
}) => {
  return (
    <Box
      component="div"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
      gap={1}
      sx={{ color: CommonColors.red }}
    >
      <ErrorOutlineRounded sx={{ width: '80px', height: '80px' }} />
      <Typography fontWeight="bold" sx={{ fontSize: 20 }}>
        {title}
      </Typography>
      {message && (
        <Typography sx={{ fontSize: 14, color: CommonColors.grayLight }}>
          {message}
        </Typography>
      )}
      <Button variant="outlined" sx={{ mt: 2 }} onClick={handleClick}>
        3Dビューを再作成
      </Button>
    </Box>
  );
};
