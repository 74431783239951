import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type GoBackButtonProps = {
  width: number;
  height: number;
  handleClick?: () => void;
};
export const GoBackButton: FC<GoBackButtonProps> = ({
  handleClick,
  width,
  height,
}) => {
  return (
    <IconButton
      edge="start"
      sx={{
        border: `1px solid ${theme.palette.dividerBlack.dark}`,
        borderRadius: 1,
        width: width,
        height: height,
        ml: 0,
        mr: 2,
      }}
      onClick={handleClick}
    >
      <ArrowBack fontSize="small" htmlColor={theme.palette.icons.primary} />
    </IconButton>
  );
};
