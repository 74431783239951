import {
  RotateMinusZIcon,
  RotatePlusZIcon,
  RotateYIcon,
} from '@components/organisms/compartmentOrientationMenu/fragments';
import { StopPropagationIconButton } from '@components/organisms/stopPropagationIconButton/stopPropagationIconButton';
import { StyledToggleButtonGroup } from '@components/organisms/viewModeToggle/fragments';
import { Box, Button, Slider, ToggleButton, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { ToggleButtonContent } from './ToggleButtonContent';
import { RotateHorizontalIcon } from './rotateHorizontalIcon';
import { RotateVerticalIcon } from './rotateVerticalIcon';

type ToggleValue = 'horizontal' | 'vertical';

type Props = {
  handleRotatePlus: () => void;
  handleRotateMinus: () => void;
  toggleValue: ToggleValue;
  handleToggleChange: (newValue: ToggleValue) => void;
};

const sliderWidth = 320;
const initialSliderValue = 0;
const minSliderValue = -45;
const maxSliderValue = 45;

// TODO: 全体的なロジックはデータ形式が分かり次第変更
export const RotationDirectionToggle: FC<Props> = ({
  handleRotatePlus,
  handleRotateMinus,
  toggleValue,
  handleToggleChange,
}) => {
  return (
    <>
      <StyledToggleButtonGroup
        orientation="horizontal"
        exclusive
        value={toggleValue}
        onChange={(_, newValue: ToggleValue) => {
          if (newValue !== null) {
            handleToggleChange(newValue);
          }
        }}
      >
        <ToggleButton
          value="horizontal"
          selected={toggleValue === 'horizontal'}
        >
          <ToggleButtonContent icon={<RotateHorizontalIcon />} label="よこ" />
        </ToggleButton>
        <ToggleButton value="vertical" selected={toggleValue === 'vertical'}>
          <ToggleButtonContent icon={<RotateVerticalIcon />} label="たて" />
        </ToggleButton>
      </StyledToggleButtonGroup>

      <Box
        component="div"
        sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}
      >
        <Box component="div" sx={{ display: 'flex' }}>
          <Box component="div" sx={{ textAlign: 'center' }}>
            <StopPropagationIconButton
              disableRipple
              onClick={handleRotateMinus}
              sx={{ padding: 0 }}
            >
              {toggleValue === 'horizontal' ? (
                <RotateYIcon />
              ) : (
                <RotateMinusZIcon />
              )}
            </StopPropagationIconButton>
            <Typography color={theme.palette.textBlack.secondary}>
              {minSliderValue}°
            </Typography>
          </Box>

          <Box component="div" width={sliderWidth} mx={0.5}>
            <Slider
              track={false}
              aria-labelledby="track-false-slider"
              defaultValue={initialSliderValue}
              getAriaValueText={(value) => `${value}°`}
              valueLabelDisplay="auto"
              step={15}
              marks
              min={minSliderValue}
              max={maxSliderValue}
              valueLabelFormat={(value) => `${value}°`}
            />
          </Box>

          <Box component="div" textAlign="center">
            <StopPropagationIconButton
              disableRipple
              onClick={handleRotatePlus}
              sx={{
                transform:
                  toggleValue === 'horizontal' ? 'scale(-1, 1)' : 'none',
                padding: 0,
              }}
            >
              {toggleValue === 'horizontal' ? (
                <RotateYIcon />
              ) : (
                <RotatePlusZIcon />
              )}
            </StopPropagationIconButton>
            <Typography color={theme.palette.textBlack.secondary}>
              +{maxSliderValue}°
            </Typography>
          </Box>
        </Box>

        <Button
          sx={{
            fontWeight: 'bold',
            color: 'lightgray',
          }}
        >
          リセット
        </Button>
      </Box>
    </>
  );
};
