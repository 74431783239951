import { BottleBackIcon } from '@components/molecules/bottleBackIcon/bottleBackIcon';
import { BottleBottomIcon } from '@components/molecules/bottleBottomIcon/bottleBottomIcon';
import { BottleFrontIcon } from '@components/molecules/bottleFrontIcon/bottleFrontIcon';
import { BottleLeftIcon } from '@components/molecules/bottleLeftIcon/bottleLeftIcon';
import { BottleRightIcon } from '@components/molecules/bottleRightIcon/bottleRightIcon';
import { BottleTopIcon } from '@components/molecules/bottleTopIcon/bottleTopIcon';
import { BowlBackIcon } from '@components/molecules/bowlBackIcon/bowlBackIcon';
import { BowlBottomIcon } from '@components/molecules/bowlBottomIcon/bowlBottomIcon';
import { BowlFrontIcon } from '@components/molecules/bowlFrontIcon/bowlFrontIcon';
import { BowlLeftIcon } from '@components/molecules/bowlLeftIcon/bowlLeftIcon';
import { BowlRightIcon } from '@components/molecules/bowlRightIcon/bowlRightIcon';
import { BowlTopIcon } from '@components/molecules/bowlTopIcon/bowlTopIcon';
import { Box, Grid } from '@mui/material';
import { faceFrontValues } from '@utils/const';
import { FC } from 'react';
import { Product } from 'types/common';
import { ProductFaceDirection, UploadedFiles } from 'types/products';
import { GridItemBox } from './gridItemBox';

type Props = {
  directionTabValue: ProductFaceDirection;
  size: number;
  svgSize: number;
  selectedFace?: ProductFaceDirection;
  uploadedFiles: UploadedFiles;
  handleClickFace: (face: ProductFaceDirection) => void;
  isEditor?: boolean;
  product: Product;
};

export const Hexahedron: FC<Props> = ({
  directionTabValue,
  size,
  svgSize,
  selectedFace,
  uploadedFiles,
  handleClickFace,
  isEditor = false,
  product,
}) => {
  const isSelectedTabFront = directionTabValue === 'front';
  return (
    <>
      <Grid container spacing={0.5}>
        <Grid container item spacing={0.5}>
          <Grid item xs={3}>
            <Box component="div" width={size} height={size} />
          </Grid>
          <Grid item xs={3}>
            <GridItemBox
              title="上面"
              isSelected={selectedFace === 'top'}
              handleClick={() => handleClickFace('top')}
              uploadedFile={uploadedFiles.top}
              angle={uploadedFiles.top.angle}
              size={size}
              isEditor={isEditor}
              product={product}
              faceFrontId={faceFrontValues.top}
            >
              {isSelectedTabFront ? (
                <BottleTopIcon width={svgSize} height={svgSize} />
              ) : (
                <BowlTopIcon width={svgSize} height={svgSize} />
              )}
            </GridItemBox>
          </Grid>
        </Grid>
        <Grid container item spacing={0.5}>
          <Grid item xs={3}>
            <GridItemBox
              title="左側面"
              isSelected={selectedFace === 'left'}
              handleClick={() => handleClickFace('left')}
              uploadedFile={uploadedFiles.left}
              angle={uploadedFiles.left.angle}
              size={size}
              isEditor={isEditor}
              product={product}
              faceFrontId={faceFrontValues.left}
            >
              {isSelectedTabFront ? (
                <BottleLeftIcon width={svgSize} height={svgSize} />
              ) : (
                <BowlLeftIcon width={svgSize} height={svgSize} />
              )}
            </GridItemBox>
          </Grid>
          <Grid item xs={3}>
            <GridItemBox
              title="正面"
              isSelected={selectedFace === 'front'}
              handleClick={() => handleClickFace('front')}
              uploadedFile={uploadedFiles.front}
              angle={uploadedFiles.front.angle}
              size={size}
              isEditor={isEditor}
              product={product}
              faceFrontId={faceFrontValues.front}
            >
              {isSelectedTabFront ? (
                <BottleFrontIcon width={svgSize} height={svgSize} />
              ) : (
                <BowlFrontIcon width={svgSize} height={svgSize} />
              )}
            </GridItemBox>
          </Grid>
          <Grid item xs={3}>
            <GridItemBox
              title="右側面"
              isSelected={selectedFace === 'right'}
              handleClick={() => handleClickFace('right')}
              uploadedFile={uploadedFiles.right}
              angle={uploadedFiles.right.angle}
              size={size}
              isEditor={isEditor}
              product={product}
              faceFrontId={faceFrontValues.right}
            >
              {isSelectedTabFront ? (
                <BottleRightIcon width={svgSize} height={svgSize} />
              ) : (
                <BowlRightIcon width={svgSize} height={svgSize} />
              )}
            </GridItemBox>
          </Grid>
          <Grid item xs={3}>
            <GridItemBox
              title="背面"
              isSelected={selectedFace === 'back'}
              handleClick={() => handleClickFace('back')}
              uploadedFile={uploadedFiles.back}
              angle={uploadedFiles.back.angle}
              size={size}
              isEditor={isEditor}
              product={product}
              faceFrontId={faceFrontValues.back}
            >
              {isSelectedTabFront ? (
                <BottleBackIcon width={svgSize} height={svgSize} />
              ) : (
                <BowlBackIcon width={svgSize} height={svgSize} />
              )}
            </GridItemBox>
          </Grid>
        </Grid>
        <Grid container item spacing={0.5}>
          <Grid item xs={3}>
            <Box component="div" width={size} height={size} />
          </Grid>
          <Grid item xs={3}>
            <GridItemBox
              title="底面"
              isSelected={selectedFace === 'bottom'}
              handleClick={() => handleClickFace('bottom')}
              uploadedFile={uploadedFiles.bottom}
              angle={uploadedFiles.bottom.angle}
              size={size}
              isEditor={isEditor}
              product={product}
              faceFrontId={faceFrontValues.bottom}
            >
              {isSelectedTabFront ? (
                <BottleBottomIcon width={svgSize} height={svgSize} />
              ) : (
                <BowlBottomIcon width={svgSize} height={svgSize} />
              )}
            </GridItemBox>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
