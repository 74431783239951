import { AlertDialog } from '@components/organisms/alertDialog/alertDialog';
import { Button, DialogContentText, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';

type Props = {
  open: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
};

export const GoBackDialog: FC<Props> = ({
  open,
  handleCancel,
  handleConfirm,
}) => {
  return (
    <AlertDialog
      open={open}
      title="編集を終了しますか？"
      content={
        <DialogContentText sx={{ color: CommonColors.black }}>
          保存していない変更がある場合、データは破棄されます。
          <Typography sx={{ fontSize: 'small' }} mt={1}>
            （今後、データが自動的に保持されるよう改善予定です。）
          </Typography>
        </DialogContentText>
      }
      cancelButton={
        <Button
          variant="text"
          color="error"
          sx={{
            ':hover': { bgcolor: CommonColors.lightGray },
          }}
          onClick={handleConfirm}
        >
          変更を破棄して編集を終了する
        </Button>
      }
      confirmButton={<Button onClick={handleCancel}>編集に戻る</Button>}
    />
  );
};
