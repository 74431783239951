import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { theme } from 'theme';

type Props = {
  isEnableReviseButton: boolean;
  isLarger: boolean;
  handleResetReviseMode: () => void;
  handleOnBulkRevise: () => void;
};
export const UnknownProductsReviseGuide = ({
  isEnableReviseButton,
  isLarger,
  handleResetReviseMode,
  handleOnBulkRevise,
}: Props) => {
  const guidePb = 2;
  return (
    <Box component="div">
      <Box
        component="div"
        height="64px"
        border={`1px solid ${theme.palette.dividerBlack.medium}`}
        p="8px 16px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          textAlign="center"
          alignItems="center"
          gap={1}
        >
          <Button
            sx={{
              minWidth: '28px',
              height: '28px',
              border: `1px solid ${theme.palette.dividerBlack.dark}`,
              borderRadius: '4px',
              p: 0,
            }}
            onClick={handleResetReviseMode}
          >
            <ChevronLeft
              sx={{
                width: '16px',
                height: '16px',
                color: `${theme.palette.icons.primary}`,
              }}
            />
          </Button>
          <Typography variant="subtitle1">不明な商品を一括で訂正</Typography>
        </Box>
        <Button
          variant="text"
          sx={{
            fontWeight: 700,
            height: '32px',
            px: '12px',
            borderRadius: '4px',
            backgroundColor: `${
              isEnableReviseButton
                ? theme.palette.primary.main
                : theme.palette.backgroundBlack.disabled
            }`,
            color: `${theme.palette.white.primary}`,
            ':hover': {
              backgroundColor: `${
                isEnableReviseButton
                  ? theme.palette.primary.mainHover || '#E9E9E9'
                  : theme.palette.backgroundBlack.disabled
              }`,
            },
          }}
          disabled={!isEnableReviseButton}
          onClick={handleOnBulkRevise}
        >
          商品を訂正
        </Button>
      </Box>
      <Box component="div" pt={2} px={2} pb={isLarger ? 0 : guidePb}>
        <Typography
          variant="body2"
          color={theme.palette.textBlack.secondary}
          lineHeight="21px"
        >
          商品ごとに、もっとも確信度の高い候補が表示されます。「訂正推奨」は特に確信度の高い候補です。
          <br />
          <span style={{ fontWeight: '700' }}>
            ＜適切な訂正対象が表示されていない場合＞
          </span>
          <br />
          この画面では訂正を行わず、スキャン結果画面から個別に訂正操作を行ってください。
        </Typography>
      </Box>
    </Box>
  );
};
