import { Search, History } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  onClickRecentProducts: VoidFunction;
  onClickSearch: VoidFunction;
};

export const ActionButtons: FC<Props> = ({
  onClickRecentProducts,
  onClickSearch,
}) => {
  return (
    <Box component="div" display="flex" alignItems="center" gap="4px">
      <IconButton onClick={onClickRecentProducts}>
        <History
          sx={{
            color: theme.palette.primary.main,
          }}
        />
      </IconButton>
      <IconButton onClick={onClickSearch}>
        <Search
          sx={{
            color: theme.palette.primary.main,
          }}
        />
      </IconButton>
    </Box>
  );
};
