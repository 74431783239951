import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const BottleAndCanIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.7768 3V5H5.99902H5.22125V3H6.7768ZM5.99902 12H8.96596C8.97727 12.0575 8.9883 12.1157 8.99902 12.1745V14H2.99902V12.1745C3.00975 12.1157 3.02077 12.0575 3.03208 12H5.99902ZM3.22125 3V5C3.07109 6.01013 2.70372 6.88813 2.29748 7.85905L2.29748 7.85905L2.29747 7.85906C1.82299 8.99307 1.29546 10.2538 0.999023 12V14V19V21V23C0.999023 23.5523 1.44674 24 1.99902 24H9.99902C10.5513 24 10.999 23.5523 10.999 23V21V19V14V12C10.7026 10.2538 10.1751 8.99306 9.70057 7.85905C9.29432 6.88813 8.92696 6.01014 8.7768 5V3V1C8.7768 0.447715 8.32909 0 7.7768 0H4.22125C3.66896 0 3.22125 0.447715 3.22125 1V3ZM2.99902 21V19H8.99902V21H2.99902ZM14.7351 9L15.2351 8H19.763L20.263 9H14.7351ZM13.999 11V21.382C13.999 21.4779 14.0214 21.5725 14.0643 21.6584C14.169 21.8677 14.383 22 14.6171 22H20.381C20.6151 22 20.8291 21.8677 20.9338 21.6584C20.9767 21.5725 20.999 21.4779 20.999 21.382V11H13.999ZM12.999 7H13.499L11.999 10V21.382C11.999 21.7884 12.0937 22.1893 12.2754 22.5528C12.7189 23.4397 13.6254 24 14.6171 24H20.381C21.3726 24 22.2792 23.4397 22.7226 22.5528C22.9044 22.1893 22.999 21.7884 22.999 21.382V10L21.499 7H21.999V5H12.999V7Z"
        fill="black"
        fillOpacity="0.65"
      />
    </SvgIcon>
  );
};
