import { StyledButton } from '@components/molecules/styledButton/styledButton';
import { Box, Button } from '@mui/material';
import { FC } from 'react';
import { RealogramSelectedItem } from 'types/realogram';
import { theme } from '../../../../theme';

export const bottomDrawerHeight = 72;

export const BottomDrawer: FC<{
  buttonText?: string;
  handleClose: () => void;
  handleChangeCandidate: () => void;
  handleReset?: () => void;
  isDisabled: boolean;
  face?: RealogramSelectedItem;
}> = ({
  buttonText = '変更',
  handleClose,
  handleChangeCandidate,
  handleReset,
  isDisabled,
  face,
}) => {
  return (
    <Box
      component="div"
      display="flex"
      justifyContent="space-between"
      sx={{
        width: '100%',
        position: 'absolute',
        left: 0,
        bottom: 0,
        height: bottomDrawerHeight,
        padding: '16px 16px 16px 24px',
        borderTop: '1px solid #E0E0E0',
        backgroundColor: theme.palette.white.primary,
      }}
    >
      <Box component="div" display="flex" justifyContent="flex-start">
        {face && !face.item.in_stock && (
          <Button
            disabled={!face.item.primary_candidate}
            sx={{ fontWeight: '700' }}
            onClick={handleReset}
          >
            選択解除
          </Button>
        )}
      </Box>
      <Box
        component="div"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          onClick={handleClose}
          sx={{
            color: theme.palette.textBlack.primary,
            ':hover': { bgcolor: theme.palette.backgroundBlack.hover },
          }}
        >
          キャンセル
        </Button>
        <StyledButton
          disabled={isDisabled}
          onClick={() => handleChangeCandidate()}
          sx={{ width: 60, minWidth: 'auto', marginLeft: '16px' }}
          type="contained"
        >
          {buttonText}
        </StyledButton>
      </Box>
    </Box>
  );
};
