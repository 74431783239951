import { Box, SxProps } from '@mui/material';
import { FaceAreaButton } from '@components/molecules/faceAreaControllerBox/fragments/faceAreaButton';
import { FC } from 'react';
import { StyledIconButton } from '../faceAreaControllerBox/fragments/createdAt';
import { BottleIconVisibilityDisabled } from '@components/molecules/bottleIconVisibilityDisabled/bottleIconVisibilityDisabled';
import { theme } from 'theme';

type Props = {
  bboxEnabled: boolean;
  setBboxEnabled: (value: boolean) => void;
  top: number;
  left: number;
  styleBox?: SxProps;
  styleBtn?: SxProps;
  isDisabledAction?: boolean;
};
export const ChangeBboxButton: FC<Props> = ({
  bboxEnabled,
  setBboxEnabled,
  top,
  left,
  styleBox,
  styleBtn,
  isDisabledAction,
}) => {
  return (
    <Box
      component="div"
      position="absolute"
      top={top}
      zIndex={5}
      sx={{ transform: `translate(${left}px, 38px)`, ...styleBox }}
      // NOTE: Prevent event bubbling
      onClick={(e) => e.stopPropagation()}
    >
      {!isDisabledAction ? (
        <FaceAreaButton
          enabled={bboxEnabled}
          onClick={() => setBboxEnabled(!bboxEnabled)}
          styleBtn={styleBtn}
        />
      ) : (
        <StyledIconButton
          sx={{
            width: '40px',
            height: '40px',
            backgroundColor: theme.palette.backgroundBlack.disabled,
            pointerEvents: 'none',
          }}
        >
          <BottleIconVisibilityDisabled />
        </StyledIconButton>
      )}
    </Box>
  );
};
