import { StyledToggleButtonGroup } from '@components/organisms/viewModeToggle/fragments';
import { Box, ToggleButton, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { ShelfDetailMode } from 'types/common';

type Props = {
  value: ShelfDetailMode;
  onChange: (value: ShelfDetailMode) => void;
  disabled?: boolean;
};

const modeToggleFontSize = '14px';

export const ModeToggle: FC<Props> = ({
  value,
  onChange,
  disabled = false,
}) => {
  return (
    <StyledToggleButtonGroup
      orientation="horizontal"
      value={value}
      exclusive
      onChange={(_, value: ShelfDetailMode) => onChange(value)}
      disabled={disabled}
      sx={{
        '& .Mui-disabled.Mui-selected ': {
          color: theme.palette.textBlack.disabled,
        },
      }}
    >
      <ToggleButton value="default">
        <Typography
          variant="caption"
          whiteSpace="nowrap"
          lineHeight={1}
          fontSize={modeToggleFontSize}
          mx={0.5}
          fontWeight="inherit"
        >
          通常
        </Typography>
      </ToggleButton>
      <ToggleButton value="comparison">
        <Box component="div" display="flex" alignItems="center">
          <Typography
            variant="caption"
            fontSize={modeToggleFontSize}
            whiteSpace="nowrap"
            lineHeight={1}
            mx={0.5}
            fontWeight="inherit"
          >
            比較
          </Typography>
        </Box>
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};
