import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const ShelfIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 18" width={12} height={18}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00390625 0.499199H1.67057H10.0039H10.8372H11.6706V17.1659H10.0039H1.67057H0.00390625V0.499199ZM10.0039 15.4992V12.1659H1.67057V15.4992H10.0039ZM10.0039 10.4992V7.16587H1.67057V10.4992H10.0039ZM10.0039 5.4992L10.0039 2.16587H1.67057V5.4992H10.0039Z"
        fill={props.fill ?? 'black'}
        fillOpacity={props.fillOpacity ?? '0.2'}
      />
    </SvgIcon>
  );
};
