import { FC, useEffect, useState } from 'react';
import { Product, ShelfDetailMode, ShelfDetailView } from 'types/common';
import { BboxColors, Bucket, BucketPlanogramPlan } from 'types/planogram';
// 以下のファイルをmoleculesに移動する

import { ArrowUpwardDownwardIcon } from '@components/molecules/arrowUpwardDownwardIcon/arrowUpwardDowneardIcon';
import { Box, Button, Typography } from '@mui/material';
import { addBayPart } from '@reducers/flatPlan';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useGetBayPartQuery } from '@reducers/shelfAppsApi/injections/bayPartCategoriesApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { convertMeterToPixel, getTopBottomBuckets } from '@utils/planogram';
import { theme } from 'theme';
import { ItemTypes } from 'types/rack';
import { AddIconVertical } from '../displayBuckets/fragments/addIconVertical';
import { Draggable } from '../draggable/draggable';
import { Buckets } from './fragments/buckets';

type Props = {
  plan: BucketPlanogramPlan;
  // TODO:使用しない場合削除
  diffColor?: string;
  bboxColors?: BboxColors;
  bboxEnabled: boolean;
  detailMode?: ShelfDetailMode;
  detailView?: ShelfDetailView;
  zoomScale: number;
  setZoomScale: (zoomScale: number) => void;
  setTranslatePosition: (value: number) => void;
  translatePosition: number;
  isEditor?: boolean;
  isCompared?: boolean;
  handleChangeFront?: () => void;
};

const half = 2;

export const Frame: FC<Props> = ({
  plan,
  zoomScale,
  setZoomScale,
  setTranslatePosition,
  translatePosition,
  isEditor = true,
  bboxEnabled,
  isCompared = false,
  handleChangeFront,
}) => {
  const dispatch = useAppDispatch();
  const [bottomBucketsNum, setBottomBucketsNum] = useState(0);
  const {
    rotateDeg,
    rotateDegInCompare,
    mode,
    selectedBayPartId,
    isSwappingBayPartMode,
    flatOverflows,
    isRotateTransitionFlatPlanogram,
  } = useAppSelector(selectPlanogramEditorState);
  const [movingBucketIndex, setMovingBucketIndex] = useState<
    number | undefined
  >();

  const {
    content_min: contentMin,
    content_max: contentMax,
    padding,
    buckets,
  } = plan.frame.detail;
  const [newBuckets, setNewBuckets] = useState(buckets);

  const contentZ = contentMax.z - contentMin.z;
  const contentX = contentMax.x - contentMin.x;
  const contentMinZ = convertMeterToPixel(contentMin.z);
  const contentMinX = convertMeterToPixel(contentMin.x);
  const rotateDegree = isEditor || !isCompared ? rotateDeg : rotateDegInCompare;
  const onDragStart = (index: number) => {
    setMovingBucketIndex(index);
  };
  const onDragEnd = () => {
    setMovingBucketIndex(undefined);
  };

  useEffect(() => {
    if (buckets?.length) {
      const bottomBuckets = getTopBottomBuckets(
        buckets,
        (plan.frame.detail.content_max.z - plan.frame.detail.content_min.z) /
          half
      );
      setBottomBucketsNum(bottomBuckets?.bottom.length);
    }
  }, [
    buckets,
    plan.frame.detail.content_max.z,
    plan.frame.detail.content_min.z,
    setBottomBucketsNum,
  ]);

  useEffect(() => {
    if (!newBuckets?.length) {
      setNewBuckets(buckets);
    }
  }, [buckets, newBuckets?.length]);

  // this useEffect only for Chrome browser
  // without this, drag end will fire immediately and drag event will not fire
  useEffect(() => {
    if (!movingBucketIndex && !buckets?.length) return;
    const timeoutId = setTimeout(() => {
      const newBuckets = getNewBuckets(
        buckets,
        movingBucketIndex,
        bottomBucketsNum
      );
      setNewBuckets(newBuckets);
    }, 0);
    return () => clearTimeout(timeoutId);
  }, [movingBucketIndex, bottomBucketsNum, buckets]);
  const contentMaxSize = contentMax.x - padding.right;
  const contentMinSize = contentMin.x - padding.left;
  const bucketsArr = movingBucketIndex?.toString() ? newBuckets : buckets;
  const topBottomBuckets: { top: Bucket[]; bottom: Bucket[] } | undefined =
    (() => {
      if (!bucketsArr) return undefined;
      return getTopBottomBuckets(
        bucketsArr,
        (plan.frame.detail.content_max.z - plan.frame.detail.content_min.z) /
          half
      );
    })();

  const { data, isLoading, isFetching } = useGetBayPartQuery(
    // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: 既存の問題なのであとで修正する
    { bay_part_id: selectedBayPartId ?? 0 },
    { skip: selectedBayPartId === undefined }
  );
  const isAddShow = !isSwappingBayPartMode && selectedBayPartId;

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
        transition:
          rotateDegree > 0 && isRotateTransitionFlatPlanogram
            ? 'transform 1s'
            : '',
        transform: `rotate(${rotateDegree}deg)`,
      }}
    >
      <Box
        component="div"
        sx={{
          position: 'relative',
          // margin is added so that the element is not pressed to the edges
          marginX: 2,
        }}
      >
        <Box
          component="div"
          position="absolute"
          top={0}
          left={0}
          sx={{
            width: '100%',
            height: '100%',
            borderLeft: `${convertMeterToPixel(padding.left)}px solid ${
              theme.palette.dividerBlack.dark
            }`,
            borderRight: `${convertMeterToPixel(padding.right)}px solid ${
              theme.palette.dividerBlack.dark
            }`,
            borderTop: `${convertMeterToPixel(padding.behind)}px solid ${
              theme.palette.dividerBlack.dark
            }`,
            borderBottom: `${convertMeterToPixel(padding.front)}px solid ${
              theme.palette.dividerBlack.dark
            }`,
          }}
        />
        <Box
          component="div"
          position="relative"
          sx={{
            width: convertMeterToPixel(contentX),
            height: convertMeterToPixel(contentZ),
            boxSizing: 'content-box',
            paddingLeft: `${convertMeterToPixel(padding.left)}px`,
            paddingRight: `${convertMeterToPixel(padding.right)}px`,
            paddingTop: `${convertMeterToPixel(padding.behind)}px`,
            paddingBottom: `${convertMeterToPixel(padding.front)}px`,
          }}
        >
          {bucketsArr?.map((bucket, index) => {
            const { content_min, padding, content_max } = bucket.detail;
            const top =
              convertMeterToPixel(content_min.z) -
              convertMeterToPixel(padding.front) +
              contentMinZ;

            const left =
              convertMeterToPixel(content_min.x) -
              convertMeterToPixel(padding.left) +
              contentMinX;
            const isExceedSize =
              content_min.x < contentMinSize || content_max.x > contentMaxSize;
            const isOverflown = flatOverflows.find(
              (el) => el.bucketIndex === index && el.overflow
            );
            return (
              <Draggable
                key={index}
                itemType={ItemTypes.BUCKET_BAY_PART}
                data={{ bucket, product: {} as Product, isExceedSize }}
                index={index}
                canDrag={
                  mode === 'BayEditor' && !isCompared && !isSwappingBayPartMode
                }
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
              >
                <Box
                  component="div"
                  position="absolute"
                  top={top}
                  left={left}
                  sx={{
                    opacity: movingBucketIndex === index ? 0 : 1,
                    // NOTE: Make overflown products clickable outside own bucket
                    zIndex: isOverflown ? '5' : 'unset',
                  }}
                >
                  <Buckets
                    bboxEnabled={bboxEnabled}
                    isEditor={isEditor}
                    index={index}
                    bucket={bucket}
                    hasBottomBuckets={!!topBottomBuckets?.bottom}
                    zoomScale={zoomScale}
                    setZoomScale={setZoomScale}
                    setTranslatePosition={setTranslatePosition}
                    translatePosition={translatePosition}
                    isCompared={isCompared}
                    isExceedSize={isExceedSize}
                    comparedBottomBucketsNum={topBottomBuckets?.bottom.length}
                    bottomBucketsNum={bottomBucketsNum}
                  />
                </Box>
              </Draggable>
            );
          })}
          {topBottomBuckets?.top.length === 0 && isAddShow && (
            <Box
              component="div"
              position="absolute"
              sx={{
                top: 2,
                right: 6,
              }}
            >
              <AddIconVertical
                disabled={isLoading || isFetching}
                sx={{
                  transform: `rotate(180deg)`,
                }}
                handleClick={() => {
                  dispatch(
                    addBayPart({
                      bayPart: data?.bay_part,
                      index: bucketsArr?.length ?? 0,
                      isLeftEdgeBottomRow: false,
                      isTopOnlyOne: true,
                    })
                  );
                }}
              />
            </Box>
          )}
          {topBottomBuckets?.bottom.length === 0 && isAddShow && (
            <Box
              component="div"
              position="absolute"
              sx={{
                bottom: 2,
                left: 6,
              }}
            >
              <AddIconVertical
                disabled={isLoading || isFetching}
                handleClick={() => {
                  addBayPart({
                    bayPart: data?.bay_part,
                    index: 0,
                    isLeftEdgeBottomRow: true,
                    isBottomOnlyOne: true,
                  });
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box component="div" display="flex" gap="8px" alignItems="center">
        <Typography variant="body2">正面</Typography>
        {mode === 'CompartmentEditor' && !isCompared && (
          <Button
            sx={{
              height: '28px',
              color: `${theme.palette.textBlack.primary}`,
              border: `1px solid ${theme.palette.dividerBlack.dark}`,
              borderRadius: '4px',
              px: '8px',
              backgroundColor: `${theme.palette.white.primary}`,
              gap: '4px',
            }}
            onClick={handleChangeFront}
          >
            <ArrowUpwardDownwardIcon
              sx={{
                color: `${theme.palette.icons.primary}`,
                height: '16px',
                width: '16px',
              }}
            />
            <Typography variant="buttonText2">切り替え</Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};

const getNewBuckets = (
  buckets?: Bucket[],
  currentIndex?: number,
  bottomBucketsNum?: number
) => {
  if (!buckets || !bottomBucketsNum) return;
  if (!currentIndex?.toString()) return buckets;
  const isBottomRow = currentIndex < bottomBucketsNum;

  const lastBottomRowIndex = bottomBucketsNum;
  let bottomBuckets = buckets?.slice(0, lastBottomRowIndex) ?? [];
  let topBuckets = buckets?.slice(lastBottomRowIndex) ?? [];
  const targetBayPart = buckets?.at(currentIndex);

  if (!targetBayPart?.detail.content_max || !targetBayPart.detail.content_min)
    return;
  const targetBayPartWidth =
    targetBayPart?.detail.content_max.x -
    targetBayPart?.detail.content_min.x +
    targetBayPart?.detail.padding.right +
    targetBayPart?.detail.padding.left;
  if (isBottomRow) {
    const leftSideItems = bottomBuckets?.slice(0, currentIndex + 1);
    const rightSideItems = bottomBuckets?.slice(currentIndex + 1).map((el) => {
      const max = { ...el?.detail.content_max };
      const min = { ...el?.detail.content_min };
      return {
        ...el,
        detail: {
          ...el.detail,
          content_max: {
            ...max,
            x: max.x - targetBayPartWidth,
          },
          content_min: {
            ...min,
            x: min.x - targetBayPartWidth,
          },
        },
      };
    });
    // TODO: find reason why on subtraction it doesn't work
    bottomBuckets = [...leftSideItems, ...rightSideItems];
  } else {
    const newIndex = currentIndex - lastBottomRowIndex;
    const leftSideItems = topBuckets?.slice(0, newIndex).map((el) => {
      const max = { ...el?.detail.content_max };
      const min = { ...el?.detail.content_min };
      return {
        ...el,
        detail: {
          ...el.detail,
          content_max: {
            ...max,
            x: max.x + targetBayPartWidth,
          },
          content_min: {
            ...min,
            x: min.x + targetBayPartWidth,
          },
        },
      };
    });
    const rightSideItems = topBuckets?.slice(newIndex);
    topBuckets = [...leftSideItems, ...rightSideItems];
  }
  return [...bottomBuckets, ...topBuckets];
};
