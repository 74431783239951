import { FC } from 'react';
import { theme } from '../../../../theme';
import { StyledButton } from '@components/molecules/styledButton/styledButton';
import { rotationAngleFlatPlanogram } from '@utils/const';

type Props = {
  handleReset3dView: () => void;
  handleViewPrint: () => void;
  isViewPrint: boolean;
  isFlat: boolean;
  handleRotate: (degree: number) => void;
};
export const ThreeDAbsoluteMenuButtons: FC<Props> = ({
  handleReset3dView,
  handleViewPrint,
  isViewPrint,
  isFlat,
  handleRotate,
}) => {
  return (
    <>
      {!isFlat && (
        <StyledButton
          type="submit"
          borderColor={theme.palette.dividerBlack.dark}
          textColor={theme.palette.textBlack.primary}
          sx={{
            backgroundColor: theme.palette.white.primary,
            ...theme.typography.subtitle2,
            '&:hover': {
              backgroundColor: theme.palette.backgroundBlack.light,
              borderColor: theme.palette.dividerBlack.dark,
            },
            lineHeight: 1.5,
          }}
          onClick={handleReset3dView}
        >
          初期視点
        </StyledButton>
      )}
      {isFlat && (
        <>
          <StyledButton
            type="submit"
            borderColor={theme.palette.dividerBlack.dark}
            textColor={theme.palette.textBlack.primary}
            sx={{
              backgroundColor: theme.palette.white.primary,
              ...theme.typography.subtitle2,
              '&:hover': {
                backgroundColor: theme.palette.backgroundBlack.light,
                borderColor: theme.palette.dividerBlack.dark,
              },
              lineHeight: 1.5,
            }}
            onClick={() => handleRotate(0)}
          >
            正面
          </StyledButton>
          <StyledButton
            type="submit"
            borderColor={theme.palette.dividerBlack.dark}
            textColor={theme.palette.textBlack.primary}
            sx={{
              backgroundColor: theme.palette.white.primary,
              ...theme.typography.subtitle2,
              '&:hover': {
                backgroundColor: theme.palette.backgroundBlack.light,
                borderColor: theme.palette.dividerBlack.dark,
              },
              lineHeight: 1.5,
            }}
            onClick={() => handleRotate(rotationAngleFlatPlanogram)}
          >
            反対面
          </StyledButton>
        </>
      )}

      <StyledButton
        type="submit"
        borderColor={theme.palette.dividerBlack.dark}
        textColor={
          isViewPrint
            ? theme.palette.white.primary
            : theme.palette.textBlack.primary
        }
        sx={{
          backgroundColor: isViewPrint
            ? theme.palette.primary.main
            : theme.palette.white.primary,
          ...theme.typography.subtitle2,
          '&:hover': {
            backgroundColor: isViewPrint
              ? theme.palette.primary.mainHover
              : theme.palette.backgroundBlack.light,
            borderColor: isViewPrint
              ? theme.palette.primary.main
              : theme.palette.dividerBlack.dark,
          },
          lineHeight: 1.5,
        }}
        onClick={handleViewPrint}
      >
        印刷用
      </StyledButton>
    </>
  );
};
