import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import httpStatus from 'http-status';
import { passwordErrorMessage } from './const';

//パスワードリセット
export const passwordResetToErrorMessage = (
  error: FetchBaseQueryError | SerializedError
) => {
  switch ('status' in error && error.status) {
    case httpStatus.FORBIDDEN:
      if ('data' in error) {
        const data = error.data as { type: string };
        if (data.type === 'ExpiredCodeException') {
          return passwordErrorMessage.expiredPassword;
        }
      }
      return passwordErrorMessage.invalidPassword;
    case httpStatus.NOT_ACCEPTABLE:
      return passwordErrorMessage.notCorrectPassword;
    default:
      return passwordErrorMessage.notSetNewPassword;
  }
};

//認証コード送信
export const forgotPasswordToErrorMessage = (
  error: FetchBaseQueryError | SerializedError
) => {
  switch ('status' in error && error.status) {
    case httpStatus.NOT_FOUND:
      return passwordErrorMessage.emailIncorrect;
    case httpStatus.TOO_MANY_REQUESTS:
      return passwordErrorMessage.tooManyRequests;
    default:
      return passwordErrorMessage.authenCodeFailed;
  }
};
