import { Stack, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';

type Props = {
  label: string;
  value: number;
  threshold: number;
};

export const ScoreTypography: FC<Props> = ({ value, label, threshold }) => {
  const isOk = value >= threshold;
  return (
    <Stack direction="row" alignItems="center" fontSize={12} gap={1}>
      <Typography fontSize="inherit">{label}</Typography>
      <Typography
        fontSize="inherit"
        color={isOk ? CommonColors.green : CommonColors.deepRed}
      >
        {isOk ? 'OK' : 'NG'}
      </Typography>
    </Stack>
  );
};
