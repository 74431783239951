import { paths } from 'types/api';
import { KeysToCamelcase, baseApi, PlanogramDirectoryTags } from '../baseApi';

type PostPlanogramDirectoryFavoriteParams = KeysToCamelcase<
  paths['/api/v1/planogram_directory_favorites/{directory_id}']['post']['parameters']['path']
>;
type PostPlanogramDirectoryFavoriteResponse =
  paths['/api/v1/planogram_directory_favorites/{directory_id}']['post']['responses']['200']['content']['application/json'];

type DeletePlanogramDirectoryFavoriteParams = KeysToCamelcase<
  paths['/api/v1/planogram_directory_favorites/{directory_id}']['delete']['parameters']['path']
>;
type DeletePlanogramDirectoryFavoriteResponse =
  paths['/api/v1/planogram_directory_favorites/{directory_id}']['delete']['responses']['200']['content']['application/json'];

export const planogramDirectoryFavoritesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    markPlanogramDirectoryFavorite: builder.mutation<
      PostPlanogramDirectoryFavoriteResponse,
      PostPlanogramDirectoryFavoriteParams
    >({
      query: ({ directoryId }) => ({
        url: `planogram_directory_favorites/${directoryId}`,
        method: 'POST',
      }),
      invalidatesTags: [PlanogramDirectoryTags.ListPlanogramDirectory],
    }),
    unmarkPlanogramDirectoryFavorite: builder.mutation<
      DeletePlanogramDirectoryFavoriteResponse,
      DeletePlanogramDirectoryFavoriteParams
    >({
      query: ({ directoryId }) => ({
        url: `planogram_directory_favorites/${directoryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [PlanogramDirectoryTags.ListPlanogramDirectory],
    }),
  }),
});

export const {
  useMarkPlanogramDirectoryFavoriteMutation,
  useUnmarkPlanogramDirectoryFavoriteMutation,
} = planogramDirectoryFavoritesApi;
