import { ScrollableTabs, TabPanel } from '@components/molecules';
import { BayPartDetail } from '@components/molecules/bayPartDetail/bayPartDetail';
import { BayPartDrawer } from '@components/organisms/BayPartDrawer/BayPartDrawer';
import { BayParts } from '@components/organisms/bayParts/bayParts';
import { PlanogramProductEditor } from '@components/organisms/planogramProductEditor/planogramProductEditor';
import { ProductDetailDrawer } from '@components/organisms/productDetailDrawer/productDetailDrawer';
import { useBayPartCategories } from '@hooks/useBayPartCategories';
import { useFlatPlanogramPlan } from '@hooks/useFlatPlanogramPlan';
import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import { useProductCategories } from '@hooks/useProductCategories';
import { useProductsSearch } from '@hooks/useProductsSearch';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { updateBucketProductPosition } from '@reducers/flatPlan';
import {
  changeEditorMode,
  getPlanogramSalesEnded,
  selectBayPartId,
  setSelectedBucketId,
  setTags,
  toggleIsSwappingBayPartMode,
  togglePlanogramSalesEndedChanged,
  updateInitialBayPart,
  updateInitialBucket,
  updateIsShowProductDetail,
  updateProductPosition,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import {
  selectPlanogramEditorState,
  selectRecentProducts,
} from '@reducers/planogramEditor/selectors';
import { useListProductsQuery } from '@reducers/shelfAppsApi';
import { useLazyGetBayPartQuery } from '@reducers/shelfAppsApi/injections/bayPartCategoriesApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { isPlanogramPtsDetail } from '@utils/planogram';
import { itemsPerPage } from '@utils/product';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { theme } from 'theme';
import { ChildBayPart } from 'types/bayPlan';
import { Product } from 'types/common';
import { Bucket, Planogram } from 'types/planogram';
import { InferType, object, string } from 'yup';
import { ProductTabPanel } from './productTabPanel';

const tabValues = {
  product: 0,
  bayPart: 1,
};
type Props = {
  bayPlanId: Planogram['bay_plan_id'];
  featureToggleNewDesign?: boolean;
  isProductDetailRow?: boolean;
};

const schema = object({
  searchText: string().required(),
});

type FormData = InferType<typeof schema>;

export const RightSideArea: FC<Props> = ({
  bayPlanId,
  featureToggleNewDesign = false,
  isProductDetailRow = false,
}) => {
  const dispatch = useAppDispatch();
  const [fetchBayPartData] = useLazyGetBayPartQuery();
  const {
    productCategoryHistory,
    categoryIndex,
    categoryType,
    filteredCategories,
    fetchFilteredCategories,
    handleBack,
    handleClick,
    handleClickProductCategory,
  } = useProductCategories();
  const { removeQueryParameter } = useUrlQueryParams();
  const [displayRecentProducts, setDisplayRecentProducts] = useState(false);
  const {
    mode,
    selectedProductCompartment,
    isShowProductDetail,
    isShowBayPartDetail,
    selectedBayPartId,
    productPosition,
    selectedBucketId,
    isSwappingBayPartMode,
    initialBayPart,
    initialBucket,
    detailMode,
  } = useAppSelector(selectPlanogramEditorState);
  const { plan, replaceBayPart, selectBayPartPosition, bayPartPosition } =
    usePlanogramPlan();
  const { replaceBayPart: replaceFlatBayPart } = useFlatPlanogramPlan();
  const [tabValue, setTabValue] = useState(tabValues.product);
  const isCompareMode = detailMode === 'comparison';
  const { reset: resetBayEditor, ...bayPartCategoriesController } =
    useBayPartCategories(bayPlanId);

  const [prevBayPartId, setPrevBayPartId] = useState<number | undefined>();
  const [isDrawerBtnDisabled, setIsDrawerBtnDisabled] = useState(false);
  const handleClickBayPartCard = async (part: ChildBayPart) => {
    if (selectedBayPartId === part.bay_part_id && isSwappingBayPartMode) return;
    dispatch(
      selectBayPartId(
        selectedBayPartId !== part.bay_part_id ? part.bay_part_id : undefined
      )
    );
    if (!isSwappingBayPartMode && bayPartPosition) {
      selectBayPartPosition(undefined);
    }
    if (!prevBayPartId) {
      const prevId =
        (initialBayPart &&
          'bay_part_id' in initialBayPart &&
          initialBayPart.bay_part_id) ??
        initialBucket?.bay_part_id;
      if (prevId) {
        setPrevBayPartId(prevId);
      }
    }
    if (isSwappingBayPartMode && selectedBayPartId) {
      setIsDrawerBtnDisabled(prevBayPartId === part.bay_part_id);
    }
    if (selectedBucketId?.toString() && !isSwappingBayPartMode) {
      dispatch(setSelectedBucketId(undefined));
    }
    //planogramで什器パーツを選択している時、replaceを行う
    if (bayPartPosition && isSwappingBayPartMode) {
      if (isPlanogramPtsDetail(plan)) {
        return;
      }
      const selectedPlanogramBayPart = plan.shelves.at(bayPartPosition.indexY);

      if (selectedPlanogramBayPart?.bay_part_id === part.bay_part_id) return;

      const res = await fetchBayPartData({ bay_part_id: part.bay_part_id });
      if (res.data?.bay_part) {
        replaceBayPart({
          at: bayPartPosition,
          bayPart: res.data.bay_part,
        });
      }
      dispatch(selectBayPartId(part.bay_part_id));
    }

    if (selectedBucketId?.toString() && isSwappingBayPartMode) {
      const res = await fetchBayPartData({ bay_part_id: part.bay_part_id });
      if (res.data?.bay_part) {
        const { name, type, id, detail } = res.data.bay_part;

        replaceFlatBayPart({
          bucketIndex: selectedBucketId,
          // TODO: fix
          bayPart: {
            name,
            type,
            bay_part_id: id,
            detail,
          } as Bucket,
        });
      }
    }
  };

  const changeMode = useCallback(
    (value: number) => {
      dispatch(
        changeEditorMode(
          value === tabValues.bayPart ? 'BayEditor' : 'CompartmentEditor'
        )
      );
    },
    [dispatch]
  );

  const handleChangeTab = (value: number) => {
    setTabValue(value);
    changeMode(value);
  };

  const resetCompartmentEditor = () => {
    dispatch(updateProductPosition(undefined));
    dispatch(updateSelectedProductCompartment(undefined));
    dispatch(updateIsShowProductDetail(false));
  };

  // NOTE: プレビューから戻った際に、什器パーツ編集に戻れるよう、タブの状態からモードを復元する
  useEffect(() => {
    if (mode !== 'preview') {
      changeMode(tabValue);
    }
  }, [tabValue, mode, dispatch, changeMode]);

  const [productDetailTabValues, setProductDetailTabValues] = useState(0);

  const handleProductDetailTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setProductDetailTabValues(newValue);
  };
  const reset = () => {
    dispatch(toggleIsSwappingBayPartMode());
    dispatch(selectBayPartId(undefined));
    setPrevBayPartId(undefined);
    if (bayPartPosition && initialBayPart && 'bay_part_id' in initialBayPart) {
      replaceBayPart({
        at: bayPartPosition,
        bayPart: {
          ...initialBayPart,
          id: initialBayPart.bay_part_id,
          created_at: '',
        },
      });
    }
    if (selectedBucketId && initialBucket) {
      replaceFlatBayPart({
        bucketIndex: selectedBucketId,
        bayPart: initialBucket,
      });
    }
  };
  const handleConfirm = () => {
    dispatch(toggleIsSwappingBayPartMode());
    dispatch(selectBayPartId(undefined));
    setPrevBayPartId(undefined);
    dispatch(updateInitialBayPart(undefined));
    dispatch(updateInitialBucket(undefined));
  };

  /** ↓ProductTabPanel↓ */
  const recentProducts = useAppSelector(selectRecentProducts);
  const [searchedOffset, setSearchedOffset] = useState(0);
  const [offset, setOffset] = useState(0);
  const {
    isFocused,
    control,
    searchWord,
    focus,
    search,
    reset: fetchFilteredCategoriesReset,
    handleSubmit,
  } = useProductsSearch(fetchFilteredCategories);
  const handleClickProduct = (product: Product) => {
    if (selectedProductCompartment?.id === product.id) {
      dispatch(updateSelectedProductCompartment(undefined));
    } else {
      dispatch(updateSelectedProductCompartment(product));
    }
  };

  const productsRef = useRef<HTMLDivElement>(null);
  const onSubmit = (data: FormData) => {
    setSearchedOffset(0);
    search(data);
    productsRef.current?.scrollTo(0, 0);
  };

  const onClickRecentProducts = () => {
    setDisplayRecentProducts(!displayRecentProducts);
  };
  const onClearSearchClick = () => {
    fetchFilteredCategoriesReset();
    setOffset(0);
  };
  const onCategoryTitleClick = () => {
    handleBack();
    setOffset(0);
  };
  const { tags, isPlanogramSalesEnded } = useAppSelector(
    selectPlanogramEditorState
  );
  const {
    data: searchedData,
    isLoading: isSearchedLoading,
    isFetching: isSearchFetching,
  } = useListProductsQuery(
    {
      offset: searchedOffset,
      limit: itemsPerPage,
      detail: true,
      shape: true,
      q: searchWord,
      salesEnded: !isPlanogramSalesEnded,
      firstOrder: 'sales_date_order',
    },
    {
      skip: !searchWord,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data, isLoading, isFetching } = useListProductsQuery(
    {
      offset: offset,
      limit: itemsPerPage,
      detail: true,
      shape: true,
      category: [productCategoryHistory[categoryIndex]?.name],
      tags,
      salesEnded: !isPlanogramSalesEnded,
      firstOrder: 'sales_date_order',
    },
    {
      skip: !!searchWord || categoryType === 'categories',
      refetchOnMountOrArgChange: true,
    }
  );
  const handleEndReached = (index: number) => {
    if (isLoading || isFetching) return;
    const offset = index + 1;
    if (data?.pager.total && offset >= data?.pager.total) return; // NOTE: 全件取得済みのときは何もしない
    setOffset(offset);
  };

  const handleSearchEndReached = (index: number) => {
    if (isSearchedLoading || isSearchFetching) return;
    const offset = index + 1;
    if (searchedData?.pager.total && offset >= searchedData?.pager.total) {
      return; // NOTE: 全件取得済みのときは何もしない
    }
    setSearchedOffset(offset);
  };

  useEffect(() => {
    dispatch(getPlanogramSalesEnded());
  }, [dispatch]);

  const handleChangeSalesEnded = () => {
    dispatch(togglePlanogramSalesEndedChanged());
    setOffset(0);
    setSearchedOffset(0);
  };
  /** ↑ProductTabPanel↑ */

  const handleTagClick = (tag?: string) => {
    if (!tag && tags.length === 0) return;
    setOffset(0);
    if (!tag) {
      dispatch(setTags([]));
    } else if (tags.includes(tag)) {
      dispatch(setTags(tags.filter((t) => t !== tag)));
    } else {
      dispatch(setTags([...tags, tag]));
    }
  };

  return (
    <>
      {isShowProductDetail &&
        selectedProductCompartment &&
        /**
         * NOTE: UI表示変更前のため、FeatureToggleを利用して出し分けを行います。
         * NOTE: 「情報・詳細編集」タブのUIは、棚にある商品を押下した時にだけ商品の位置情報を利用します
         */
        (productPosition && featureToggleNewDesign ? (
          <>
            <Tabs
              value={productDetailTabValues}
              onChange={handleProductDetailTabChange}
              variant="fullWidth"
              sx={{
                borderBottom: 1,
                color: theme.palette.dividerBlack.medium,
                margin: '0 -8px',
              }}
            >
              <Tab label="情報" value={0} />
              <Tab label="詳細編集" value={1} />
            </Tabs>
            <TabPanel index={0} value={productDetailTabValues}>
              <ProductDetailDrawer
                imageSize="m"
                selectedProduct={selectedProductCompartment}
                handleClick={() => {
                  dispatch(updateIsShowProductDetail(false));
                }}
              />
            </TabPanel>
            <TabPanel index={1} value={productDetailTabValues}>
              <PlanogramProductEditor
                selectedProduct={selectedProductCompartment}
                productPosition={productPosition}
              />
            </TabPanel>
          </>
        ) : (
          <ProductDetailDrawer
            imageSize="m"
            selectedProduct={selectedProductCompartment}
            hasBackButton
            handleClick={() => {
              dispatch(updateIsShowProductDetail(false));
              removeQueryParameter('item');
              dispatch(updateProductPosition(undefined));
              dispatch(updateBucketProductPosition(undefined));
              dispatch(setSelectedBucketId(undefined));
            }}
            isProductDetailRow={isProductDetailRow}
          />
        ))}
      {isShowBayPartDetail && selectedBayPartId && (
        <>
          <BayPartDetail selectedBayPartId={selectedBayPartId} />
          <BayPartDrawer
            isShowBayPartDetail
            selectedBayPartId={selectedBayPartId}
            reset={reset}
            isDisabled
            handleConfirm={handleConfirm}
            isSwappingBayPartMode={isSwappingBayPartMode}
            isCompareMode={isCompareMode}
          />
        </>
      )}
      {!isShowProductDetail && !isShowBayPartDetail && (
        <Box
          component="div"
          overflow="hidden"
          height="100%"
          display="flex"
          flexDirection="column"
        >
          {isSwappingBayPartMode ? (
            <Typography fontSize="20px" fontWeight={700} px={1} py={1}>
              什器を変更
            </Typography>
          ) : (
            <ScrollableTabs
              value={tabValue}
              variant="fullWidth"
              onChange={(_, value: number) => {
                handleChangeTab(value);
                if (mode === 'BayEditor') {
                  resetBayEditor();
                }
                if (mode === 'CompartmentEditor') {
                  resetCompartmentEditor();
                }
              }}
              sx={{
                margin: '0 -16px',
                '&.MuiTabs-root': {
                  borderBottom: 0,
                },
                '& .MuiTabs-flexContainer:after': {
                  content: '""',
                  backgroundColor: theme.palette.dividerBlack.medium,
                  width: '100%',
                  height: '1px',
                  position: 'absolute',
                  bottom: '0',
                },
              }}
            >
              <Tab label="商品" />
              <Tab label="什器" />
            </ScrollableTabs>
          )}
          <TabPanel
            value={tabValue}
            index={tabValues.product}
            sx={{
              flex: 1,
              height: '100%',
              overflow: 'auto',
              display: tabValue === tabValues.product ? 'flex' : 'none',
              flexDirection: 'column',
            }}
          >
            <ProductTabPanel
              isFocused={isFocused}
              displayRecentProducts={displayRecentProducts}
              control={control}
              focus={focus}
              onClearSearchClick={onClearSearchClick}
              onCategoryTitleClick={onCategoryTitleClick}
              categoryIndex={categoryIndex}
              productCategoryHistory={productCategoryHistory}
              onClickRecentProducts={onClickRecentProducts}
              recentProducts={recentProducts}
              isCompareMode={isCompareMode}
              handleClickProduct={handleClickProduct}
              selectedProductCompartment={selectedProductCompartment}
              searchWord={searchWord}
              categoryType={categoryType}
              handleClick={handleClick}
              productsRef={productsRef}
              handleEndReached={handleEndReached}
              isLoading={isLoading || isFetching}
              data={data}
              offset={offset}
              isPlanogramSalesEnded={isPlanogramSalesEnded}
              handleChangeSalesEnded={handleChangeSalesEnded}
              searchedData={searchedData}
              fetchFilteredCategoriesReset={fetchFilteredCategoriesReset}
              setSearchedOffset={setSearchedOffset}
              searchedOffset={searchedOffset}
              filteredCategories={filteredCategories}
              handleClickProductCategory={handleClickProductCategory}
              isSearchedLoading={isSearchedLoading || isSearchFetching}
              handleSearchEndReached={handleSearchEndReached}
              isShowProductDetail={isShowProductDetail}
              handleSubmit={handleSubmit(onSubmit)}
              handleTagClick={handleTagClick}
              tags={tags}
            />
          </TabPanel>
          <TabPanel
            value={tabValue}
            index={tabValues.bayPart}
            sx={{
              px: 0.5,
            }}
          >
            <BayParts
              {...bayPartCategoriesController}
              reset={resetBayEditor}
              handleClickBayPartCard={handleClickBayPartCard}
              prevBayPartId={prevBayPartId}
            />
            {selectedBayPartId && (
              <BayPartDrawer
                selectedBayPartId={selectedBayPartId}
                reset={reset}
                isDisabled={!prevBayPartId || isDrawerBtnDisabled}
                handleConfirm={handleConfirm}
                isSwappingBayPartMode={isSwappingBayPartMode}
                isCompareMode={isCompareMode}
              />
            )}
          </TabPanel>
        </Box>
      )}
    </>
  );
};
