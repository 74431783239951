import { Box, styled } from '@mui/material';

export const NameBox = styled(Box)(() => ({
  //要素がはみ出た時に、3点リーダーを表示する
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
}));
