import { api } from '..';

export const userApi = {
  getUsers: api.getListApiV1UsersGet,
  createUser: api.createApiV1UsersPost,
  deleteMfaDevice: api.adminDeleteMfaDeviceApiV1AdminUsersUserIdMfaDeviceDelete,
  updateUser: api.putUserApiV1UsersUserIdPut,
  reactiveUser: api.reactivateApiV1UsersUserIdActivatePut,
  deActiveUser: api.deleteApiV1UsersUserIdDelete,
  getAdminUsersIdMfaDevice:
    api.adminGetMfaDeviceApiV1AdminUsersUserIdMfaDeviceGet,
};
