import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const FlatRealogramScanIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      fill="none"
      sx={{ width: '190px', height: '240px' }}
      viewBox="0 0 190 244"
      {...props}
    >
      <svg
        width="190"
        height="240"
        viewBox="0 0 190 244"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M93 0L188 0C189.105 0 190 0.895431 190 2V96C190 97.1046 189.105 98 188 98H93V0Z"
          fill="#D1D1D1"
        />
        <rect x="97" y="4" width="27" height="43" rx="1" fill="white" />
        <rect x="128" y="4" width="27" height="43" rx="1" fill="white" />
        <rect x="159" y="4" width="27" height="43" rx="1" fill="white" />
        <rect x="97" y="51" width="27" height="43" rx="1" fill="white" />
        <rect x="128" y="51" width="27" height="43" rx="1" fill="white" />
        <rect x="159" y="51" width="27" height="43" rx="1" fill="white" />
        <path
          d="M0 2C0 0.89543 0.89543 0 2 0L97 0V98H2C0.895431 98 0 97.1046 0 96L0 2Z"
          fill="#0A40CA"
        />
        <rect x="4" y="4" width="27" height="43" rx="1" fill="#D3DFFF" />
        <rect x="4" y="51" width="27" height="43" rx="1" fill="#D3DFFF" />
        <rect x="35" y="4" width="27" height="43" rx="1" fill="#D3DFFF" />
        <rect x="35" y="51" width="27" height="43" rx="1" fill="#D3DFFF" />
        <rect x="66" y="4" width="27" height="43" rx="1" fill="#D3DFFF" />
        <rect x="66" y="51" width="27" height="43" rx="1" fill="#D3DFFF" />
        <path
          d="M103 122L101.59 120.59L96 126.17V114H94V126.17L88.42 120.58L87 122L95 130L103 122Z"
          fill="#0A40CA"
        />
        <path
          d="M93 146H188C189.105 146 190 146.895 190 148V242C190 243.105 189.105 244 188 244H93V146Z"
          fill="#0A40CA"
        />
        <rect x="97" y="150" width="27" height="43" rx="1" fill="#D3DFFF" />
        <rect x="128" y="150" width="27" height="43" rx="1" fill="#D3DFFF" />
        <rect x="159" y="150" width="27" height="43" rx="1" fill="#D3DFFF" />
        <rect x="97" y="197" width="27" height="43" rx="1" fill="#D3DFFF" />
        <rect x="128" y="197" width="27" height="43" rx="1" fill="#D3DFFF" />
        <rect x="159" y="197" width="27" height="43" rx="1" fill="#D3DFFF" />
        <path
          d="M0 148C0 146.895 0.895431 146 2 146H93.002V244H2C0.895432 244 0 243.105 0 242L0 148Z"
          fill="#D1D1D1"
        />
        <rect x="4" y="150" width="27" height="43" rx="1" fill="white" />
        <rect x="4" y="197" width="27" height="43" rx="1" fill="white" />
        <rect x="35" y="150" width="27" height="43" rx="1" fill="white" />
        <rect x="35" y="197" width="27" height="43" rx="1" fill="white" />
        <rect x="66" y="150" width="27" height="43" rx="1" fill="white" />
        <rect x="66" y="197" width="27" height="43" rx="1" fill="white" />
      </svg>
    </SvgIcon>
  );
};
