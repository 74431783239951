import { Button, styled } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { theme } from '../../../theme';

export const BayButton = styled(Button)(() => ({
  backgroundColor: theme.palette.white.primary,
  color: CommonColors.black,
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
  width: 40,
  maxWidth: 40,
  minWidth: 40,
  height: 48,
  '&:hover ': {
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
    backgroundColor: theme.palette.white.primary,
  },
}));
