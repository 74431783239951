// TODO:階層構造完成後削除する
import { gridComponents } from '@components/organisms';
import { Box, Button, CircularProgress } from '@mui/material';
import { useAppSelector } from '@store/index';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc';
import { FC, useEffect, useRef } from 'react';
import { VirtuosoGrid, VirtuosoGridHandle } from 'react-virtuoso';
import { theme } from 'theme';
import { Status } from 'types/common';
import { RealogramCandidate } from 'types/realogram';
import { RealogramCard } from '../realogramCard/realogramCard';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  isLoading: boolean;
  scrollableHeight: number;
  realogramCandidates?: RealogramCandidate[];
  handleEndReached: (index: number) => void;
  handleCardClick: (id: number, status: Status, index: number) => void;
  handleOpenDeleteDialog: (id: number) => void;
  total: number;
};

const imageHeight = 172;
const enterThreshold = 400;
const exitThreshold = 30;
export const gridCardHeight = 271; //TODO: get dynamic height
export const RealogramsGrid: FC<Props> = ({
  isLoading,
  scrollableHeight,
  realogramCandidates,
  handleEndReached,
  handleCardClick,
  handleOpenDeleteDialog,
  total,
}) => {
  const ref = useRef<VirtuosoGridHandle>(null); //NOTE: for typescript support
  const { selectedItemIndex } = useAppSelector((state) => state.Scanner);
  useEffect(() => {
    if (!selectedItemIndex || !ref.current) return;
    ref.current.scrollToIndex({ index: selectedItemIndex });
  });
  return (
    <VirtuosoGrid
      ref={ref}
      data={realogramCandidates}
      style={{ height: scrollableHeight }}
      components={{
        ...gridComponents,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        Footer: () => {
          if (isLoading)
            return (
              <Box
                component="div"
                sx={{
                  mt: 1,
                  color: '#D9D9D9',
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: theme.palette.white.primary,
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            );
          if (
            !isLoading &&
            realogramCandidates?.length &&
            total !== realogramCandidates.length
          )
            return (
              <Box
                component="div"
                sx={{
                  mt: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: theme.palette.white.primary,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() =>
                    handleEndReached(realogramCandidates.length + 1)
                  }
                >
                  さらに読み込む
                </Button>
              </Box>
            );
          return <></>;
        },
      }}
      scrollSeekConfiguration={{
        enter: (velocity) => Math.abs(velocity) > enterThreshold,
        exit: (velocity) => {
          const shouldExit = Math.abs(velocity) < exitThreshold;
          return shouldExit;
        },
      }}
      endReached={(index) => handleEndReached(index)}
      itemContent={(index, realogramCandidate) => {
        return (
          <Box component="div" width="100%">
            <RealogramCard
              imageHeight={imageHeight}
              realogramCandidate={realogramCandidate}
              handleOpenDeleteDialog={handleOpenDeleteDialog}
              handleClick={handleCardClick}
              index={index}
            />
          </Box>
        );
      }}
    />
  );
};
