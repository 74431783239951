import { ColumnProps } from '@components/organisms/dataTable/dataTable';
import { RealogramOrderBy } from 'types/realogram';

const headerSx = {
  padding: '0 0 0 16px',
};

export const columns: ColumnProps<RealogramOrderBy>[] = [
  {
    headerName: '店舗',
    headerSx: { ...headerSx, color: 'gray', flex: 1 },
  },
  {
    headerName: '商品カテゴリ',
    headerSx: { ...headerSx, flex: 1 },
    hasDivider: true,
  },
  {
    headerName: '作成者',
    headerSx: { ...headerSx, flex: 1 },
    hasDivider: true,
  },
  {
    headerName: 'スキャン日時',
    headerSx: { ...headerSx, flex: 1 },
    hasDivider: true,
  },
  {
    headerName: 'ステータス',
    headerSx: { ...headerSx, width: '130px' },
    hasDivider: true,
  },
  {
    headerName: '',
    headerSx: { width: '64px' },
  },
];
