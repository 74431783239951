import { StatisticType } from '@components/organisms/statisticBox/statisticBox';

export const getComparisonStatisticType = (
  diff: number | ''
): StatisticType => {
  if (typeof diff === 'number' && diff > 0) {
    return 'increase';
  } else if (diff === 0 || diff === '') {
    return 'neutral';
  } else {
    return 'decrease';
  }
};
