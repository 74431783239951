import { Button, ButtonProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type Props = ButtonProps & { onClick: () => void };

export const StopPropagationButton: FC<PropsWithChildren<Props>> = ({
  children,
  onClick,
  ...props
}) => (
  <Button
    {...props}
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
  >
    {children}
  </Button>
);
