import { FormPaper } from '@components/molecules/formPaper/formPaper';
import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { useQrCode } from '../../../hooks/useQrCode';
import { CancelButton } from '../cancelButton';
import { QRCodeContent } from './qrCodeContent';

type Props = {
  secretCode?: string;
  next: () => void;
};

export const AuthAppAddition: FC<Props> = ({ next, secretCode }) => {
  const { isLoading, qrCodeValue } = useQrCode(secretCode);

  return (
    <FormPaper title="Authenticatorの登録">
      <Typography mb={3}>
        インストールしたアプリケーションを起動し、＋ボタンを押して QR
        コードを読み取ってください。
      </Typography>
      <Box component="div" textAlign="center">
        <QRCodeContent isLoading={isLoading} qrCodeValue={qrCodeValue} />
      </Box>
      <Button variant="contained" sx={{ my: 3 }} fullWidth onClick={next}>
        次へ
      </Button>
      <CancelButton />
    </FormPaper>
  );
};
