import { ProductCategoryImage } from '@components/molecules/productCategoryImage/productCategoryImage';
import { headerHeight } from '@components/organisms/header/header';
import { StyledCardContent } from '@components/organisms/productCard/productCard';
import { Card, CardActionArea, Grid } from '@mui/material';
import { fullHeight } from '@utils/const';
import { FC } from 'react';
import { ProductCategory } from 'types/productCategories';
import { theme } from '../../../theme';

type Props = {
  productCategories?: ProductCategory[];
  handleClick: (category: ProductCategory) => void;
  isCompareMode?: boolean;
  isRealogram?: boolean;
};

const tabAndCategoryNameHeight = 120;

export const ProductCategories: FC<Props> = ({
  productCategories,
  handleClick,
  isCompareMode = false,
  isRealogram = false,
}) => {
  return (
    <Grid
      container
      spacing={{ xs: 1 }}
      columns={{ xs: 12 }}
      overflow="auto"
      maxHeight={`calc(${fullHeight} - ${headerHeight}px - ${tabAndCategoryNameHeight}px)`}
      sx={{ marginTop: '0 !important' }}
    >
      {productCategories?.map((productCategory, index) => {
        return (
          // TODO: リファクタ
          <Grid item xs={isRealogram ? 4 : 6} key={index}>
            <Card
              onClick={() => handleClick(productCategory)}
              variant="outlined"
              elevation={0}
              sx={{
                border: '1px solid #CCCCCC',
                background: theme.palette.white.primary,
                height: 79,
              }}
            >
              <CardActionArea
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '100%',
                  ...(isCompareMode && {
                    marginTop: '10px',
                    flexDirection: 'column',
                    marginBottom: '-10px',
                  }),
                }}
              >
                <ProductCategoryImage
                  isCompareMode={isCompareMode}
                  url={productCategory.example_product_image_url}
                />
                <StyledCardContent
                  isCompareMode={isCompareMode}
                  name={productCategory.name}
                />
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
