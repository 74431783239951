import { createSelector } from '@reduxjs/toolkit';
import { selectAppState } from '@store/index';

export const selectRealogramSelectedItem = createSelector(
  selectAppState,
  (state) => state.RealogramCandidate.selectedRealogramItem
);
export const selectRealogramCandidateRatio = createSelector(
  selectAppState,
  (state) => state.RealogramCandidate.ratio
);
