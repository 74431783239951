import { Tab, Tabs } from '@mui/material';
import { ComparisonTabModal } from '@utils/const';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  tabValue: number;
  handleTabChange: (tabValue: number) => void;
};

export const ComparisonTabs: FC<Props> = ({ tabValue, handleTabChange }) => {
  return (
    <>
      <Tabs
        value={tabValue}
        onChange={(_, value) => handleTabChange(value)}
        variant="fullWidth"
        sx={{
          borderBottom: 1,
          color: theme.palette.dividerBlack.medium,
          margin: '0 -20px',
        }}
      >
        <Tab
          label="棚割実績（棚スキャナ）"
          value={ComparisonTabModal.REALOGRAM_TAB}
        />
        <Tab
          label="棚割計画（棚エディタ）"
          value={ComparisonTabModal.PLANOGRAM_TAB}
        />
      </Tabs>
    </>
  );
};
