import { IconButton, IconButtonProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type Props = IconButtonProps & { onClick: () => void };

export const StopPropagationIconButton: FC<PropsWithChildren<Props>> = ({
  children,
  onClick,
  ...props
}) => (
  <IconButton
    {...props}
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
  >
    {children}
  </IconButton>
);
