import { RotateRight } from '@mui/icons-material';
import { Box, Button, ClickAwayListener } from '@mui/material';
import { FC } from 'react';
import { ZoomController } from '@components/organisms/zoomController/zoomController';
import { StyledButtonGroup } from '@components/organisms/styledButtonGroup/styledButtonGroup';
import { CustomWidthTooltip } from '@components/pages/planogramDetail/fragments/customWidthTooltip';

type Props = {
  zoomScale: number;
  handleIncrementZoom: () => void;
  handleDecrementZoom: () => void;
  handleClickRotate: () => void;
  isTooltipOpen: boolean;
  closeTooltip: () => void;
  isFlat: boolean;
};
export const TwoDAbsoluteMenuButtons: FC<Props> = ({
  zoomScale,
  handleIncrementZoom,
  handleDecrementZoom,
  handleClickRotate,
  isTooltipOpen,
  closeTooltip,
  isFlat,
}) => {
  return (
    <>
      <ZoomController
        zoomScale={zoomScale}
        handleIncrementZoom={handleIncrementZoom}
        handleDecrementZoom={handleDecrementZoom}
      />
      {isFlat && (
        <ClickAwayListener onClickAway={closeTooltip}>
          <Box component="div">
            <CustomWidthTooltip
              open={isTooltipOpen}
              title="反対側のカゴを編集するにはゴンドラを回転させてください"
              placement="right"
              arrow
              disableHoverListener
              disableTouchListener
            >
              <StyledButtonGroup orientation="vertical">
                <Button onClick={handleClickRotate}>
                  <RotateRight />
                </Button>
              </StyledButtonGroup>
            </CustomWidthTooltip>
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
};
