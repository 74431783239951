import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const BottleIconVisibility: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      fill="none"
      sx={{ width: '20', height: '24' }}
      viewBox="0 0 20 24"
      {...props}
    >
      <rect
        x="1"
        y="1"
        width="18"
        height="22"
        rx="1"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M7 18H13V19C13 19.5523 12.5523 20 12 20H8C7.44772 20 7 19.5523 7 19V18Z"
        fill="white"
      />
      <path d="M7 13H13V17H7V13Z" fill="white" />
      <path
        d="M8.5 5C8.5 4.44772 8.94772 4 9.5 4H10.5C11.0523 4 11.5 4.44772 11.5 5V6H8.5V5Z"
        fill="white"
      />
      <mask
        id="mask0_12317_164240"
        maskUnits="userSpaceOnUse"
        x="10"
        y="7"
        width="3"
        height="5"
      >
        <rect width="3" height="5" transform="translate(10 7)" fill="#F3F3F3" />
      </mask>
      <g mask="url(#mask0_12317_164240)" />
      <mask
        id="mask1_12317_164240"
        maskUnits="userSpaceOnUse"
        x="10"
        y="7"
        width="3"
        height="5"
      >
        <rect width="3" height="5" transform="translate(10 7)" fill="#F3F3F3" />
      </mask>
      <g mask="url(#mask1_12317_164240)">
        <path
          d="M11.5 7H8.5L7 12H13C12.6699 9.53053 11.6953 8.66854 11.5 7Z"
          fill="white"
        />
      </g>
      <mask
        id="mask2_12317_164240"
        maskUnits="userSpaceOnUse"
        x="7"
        y="7"
        width="3"
        height="5"
      >
        <rect
          width="3"
          height="5"
          transform="matrix(-1 0 0 1 10 7)"
          fill="#F3F3F3"
        />
      </mask>
      <g mask="url(#mask2_12317_164240)" />
      <mask
        id="mask3_12317_164240"
        maskUnits="userSpaceOnUse"
        x="7"
        y="7"
        width="3"
        height="5"
      >
        <rect
          width="3"
          height="5"
          transform="matrix(-1 0 0 1 10 7)"
          fill="#F3F3F3"
        />
      </mask>
      <g mask="url(#mask3_12317_164240)">
        <path
          d="M8.5 7H11.5L13 12H7C7.33014 9.53053 8.30468 8.66854 8.5 7Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
};
