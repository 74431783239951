import { StopPropagationButton } from '@components/organisms/stopPropagationButton/stopPropagationButton';
import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { ButtonGroup, Divider } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { getCompartmentSize } from '@utils/planogram';
import { FC } from 'react';
import { Product } from 'types/common';
import { PlanogramProductCompartment, ProductPosition } from 'types/planogram';
import { AutoFillIcon } from './autoFillIcon';

type Props = {
  targetProduct?: Product;
  targetCompartment: PlanogramProductCompartment;
  productPosition: ProductPosition;
  displayableHeight: number;
};

export const ProductsButtonGroupVertical: FC<Props> = ({
  targetProduct,
  targetCompartment,
  productPosition,
  displayableHeight,
}) => {
  const {
    incrementVerticalAmount,
    decrementVerticalAmount,
    changeProductsAmount,
  } = usePlanogramPlan();

  const { height: compartmentHeight, productCompartment } = getCompartmentSize(
    targetCompartment,
    targetProduct
  );

  const autoFillAmount = Math.trunc(
    (displayableHeight - compartmentHeight) / productCompartment.height
  );

  const incrementVerticalAmountAutoFill = () => {
    changeProductsAmount({
      at: productPosition,
      amount: {
        x: 0,
        y: autoFillAmount,
      },
    });
  };

  const disableMinus = targetCompartment?.count?.y === 1;
  const disableAutoFillAmount = autoFillAmount <= 0;

  return (
    <ButtonGroup
      // NOTE: Prevent closing menu if button is disabled.
      onClick={(e) => e.stopPropagation()}
      orientation="vertical"
      size="large"
      sx={{
        width: 48,
        '& .MuiButtonGroup-grouped': {
          color: CommonColors.appBlue,
          border: 'unset',
          my: 1,
          '&:hover': {
            border: 'unset',
          },
          '&:disabled': {
            border: 'unset',
          },
        },
      }}
    >
      <StopPropagationButton
        disabled={disableAutoFillAmount}
        onClick={incrementVerticalAmountAutoFill}
      >
        <AutoFillIcon />
      </StopPropagationButton>
      <Divider
        component="div"
        flexItem
        sx={{ mx: 1, mb: 1, color: '#D9D9D9' }}
      />
      <StopPropagationButton
        onClick={() => incrementVerticalAmount(productPosition)}
      >
        <AddCircle sx={{ fill: CommonColors.appBlue }} />
      </StopPropagationButton>
      <StopPropagationButton
        disabled={disableMinus}
        onClick={() => decrementVerticalAmount(productPosition)}
      >
        <RemoveCircle />
      </StopPropagationButton>
    </ButtonGroup>
  );
};
