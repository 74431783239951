import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { PasswordChangeForm } from './fragments/passwordChangeForm/passwordChangeForm';
import { useChangePassword } from './hooks/useChangePassword';
import { useEmailPasswordLogin } from './hooks/useEmailPasswordLogin';
import { useDemoToggleChange } from './hooks/useDemoToggleChange';
import { useDeleteMfa } from './hooks/useDeleteMfa';
import { useTenantsPublicInfo } from './hooks/useTenantsInfo';
import { AccountInfo } from './fragments/accountInfo';
import { usePageTitle } from '@hooks/usePageTitle';

export const Account = () => {
  usePageTitle('アカウント情報');
  const {
    mfaDeviceData,
    isMfaDeviceLoading,
    isPasswordChangeMode,
    changePasswordError,
    isChangePasswordLoading,
    setIsPasswordChangeMode,
    handleChangePassword,
  } = useChangePassword();
  const {
    isDeleteMfaOpen,
    isDeleteMfaLoading,
    setIsDeleteMfaOpen,
    handleDeleteMfa,
  } = useDeleteMfa();
  const {
    email,
    isSignInLoading,
    isSignInError,
    isPasswordInputOpen,
    setIsPasswordInputOpen,
    handleEmailPasswordLogin,
  } = useEmailPasswordLogin(setIsDeleteMfaOpen);
  const { isDemoOn, handleDemoToggleChange } = useDemoToggleChange();
  const {
    tenantsPublicInfo,
    tenantsPrivateInfo,
    isTenantsPublicInfoLoading,
    isTenantsPrivateInfoLoading,
  } = useTenantsPublicInfo();

  return (
    <>
      {isPasswordChangeMode ? (
        // パスワード変更フォーム
        <PasswordChangeForm
          error={changePasswordError as FetchBaseQueryError}
          isLoading={isChangePasswordLoading}
          onSubmit={handleChangePassword}
          handleClose={() => setIsPasswordChangeMode(false)}
        />
      ) : (
        // アカウント情報のページ
        <AccountInfo
          email={email}
          setIsPasswordChangeMode={() => setIsPasswordChangeMode(true)}
          isMfaDeviceLoading={isMfaDeviceLoading}
          preferredMfaSetting={mfaDeviceData?.preferred_mfa_setting}
          setIsPasswordInputOpen={setIsPasswordInputOpen}
          isDemoOn={isDemoOn}
          handleDemoToggleChange={handleDemoToggleChange}
          isPasswordInputOpen={isPasswordInputOpen}
          isSignInError={isSignInError}
          isSignInLoading={isSignInLoading}
          handleEmailPasswordLogin={handleEmailPasswordLogin}
          isDeleteMfaOpen={isDeleteMfaOpen}
          handleDeleteMfa={handleDeleteMfa}
          isDeleteMfaLoading={isDeleteMfaLoading}
          setDeleteMfaOpen={() => setIsDeleteMfaOpen(false)}
          isDemoModeEnabled={tenantsPrivateInfo?.features.demo_mode ?? false}
          isSAMLEnable={tenantsPublicInfo?.features.saml_enabled ?? false}
          isTenantsPrivateInfoLoading={isTenantsPrivateInfoLoading}
          isTenantsPublicInfoLoading={isTenantsPublicInfoLoading}
        />
      )}
    </>
  );
};
