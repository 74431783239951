import { SelectableCard } from '@components/organisms';
import { Box } from '@mui/material';
import Image from 'next/legacy/image';
import { FC, PropsWithChildren } from 'react';
import { BayPartCategory, ChildBayPart } from 'types/bayPlan';

type Props = {
  type?: ChildBayPart['type'] | BayPartCategory['type'];
  direction?: 'column' | 'row';
  handleSelectedBayPart: () => void;
  isSelectedPlanogramBayPart: boolean;
  isSelectedBayPlanBayPart: boolean;
  isCompareMode?: boolean;
};

export const BayPartCard: FC<PropsWithChildren<Props>> = ({
  children,
  type,
  direction = 'row',
  isSelectedPlanogramBayPart,
  isSelectedBayPlanBayPart,
  handleSelectedBayPart,
  isCompareMode,
}) => {
  return (
    <SelectableCard
      onClick={handleSelectedBayPart}
      selected={isSelectedBayPlanBayPart || isSelectedPlanogramBayPart}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: isCompareMode ? 'column' : direction,
        gap: 1,
        p: 1,
        alignItems: 'center',
        '&.Mui-selected': {
          ...getColorsBySelected(
            isSelectedBayPlanBayPart,
            isSelectedPlanogramBayPart
          ),
        },
      }}
    >
      <Box
        component="div"
        position="relative"
        maxWidth={
          isCompareMode ? '40px' : direction === 'row' ? '56px' : '120px'
        }
        minHeight={
          isCompareMode ? '40px' : direction === 'row' ? '56px' : '120px'
        }
        width="100%"
      >
        <Image
          src={getImageUrlByTypeOfBoard(type)}
          alt="baypart"
          layout="fill"
          objectFit="contain"
          draggable={false}
        />
      </Box>
      {children}
    </SelectableCard>
  );
};

const getImageUrlByTypeOfBoard = (type?: string) => {
  switch (type) {
    case 'shelf_board':
    case 'shelf_board_container':
      return '/shelf_board.svg';
    case 'hook_bar':
    case 'hook_bar_container':
      return '/hook_bar.svg';
    case 'bucket_container':
    case 'buckets_bucket':
      return '/bucket_container.svg';
    default:
      return '/shelf_board.svg';
  }
};

const getColorsBySelected = (
  isSelectedBayPlanBayPart: boolean,
  isSelectedPlanogramBayPart: boolean
) => {
  //the baypart selected in planogram - for replace baypart
  if (isSelectedPlanogramBayPart) {
    return { backgroundColor: '' };
  }
  //the baypart card seleted in bayPartsGrid - for add baypart
  if (isSelectedBayPlanBayPart) {
    return { backgroundColor: '#E5F0FF' };
  }
  return { backgroundColor: '' };
};
