import { useDnD } from '@hooks/useDnD';
import { Box } from '@mui/material';
import { FC, PropsWithChildren, useRef } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { DndData } from 'types/common';
import { Position } from 'types/planogram';
import { ItemTypes } from 'types/rack';

type DraggableProps = {
  itemType: ItemTypes;
  data: DndData;
  index?: number;
  rowIndex?: number;
  canDrag?: boolean;
  areaPath?: Position[];
  onDragStart?: (index: number) => void;
  onDragEnd?: () => void;
};

const opacityWhenDragging = 0.4;

export const Draggable: FC<PropsWithChildren<DraggableProps>> = ({
  children,
  data,
  itemType,
  index,
  rowIndex,
  canDrag,
  areaPath,
  onDragStart,
  onDragEnd,
}) => {
  const ref = useRef<HTMLDivElement>();
  const { dragRef, dropRef, handlerId, isDragging, preview } = useDnD({
    itemType,
    data,
    index,
    rowIndex,
    canDrag,
    areaPath,
    onDragStart,
    onDragEnd,
  });
  preview(getEmptyImage(), { captureDraggingState: true });
  dragRef(dropRef(ref));
  return (
    <Box
      data-handler-id={handlerId}
      ref={ref}
      component="div"
      sx={{
        opacity: isDragging ? opacityWhenDragging : 1,
      }}
    >
      {children}
    </Box>
  );
};
