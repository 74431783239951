import { StopPropagationIconButton } from '@components/organisms/stopPropagationIconButton/stopPropagationIconButton';
import { FC, PropsWithChildren } from 'react';

type Props = {
  handleRotatePlus: () => void;
  handleRotateMinus: () => void;
};
export const GroupOfOrientationButtons: FC<PropsWithChildren<Props>> = ({
  handleRotatePlus,
  handleRotateMinus,
  children,
}) => {
  return (
    <>
      <StopPropagationIconButton
        disableRipple
        onClick={handleRotateMinus}
        sx={{
          padding: 0,
        }}
      >
        {children}
      </StopPropagationIconButton>
      <StopPropagationIconButton
        disableRipple
        onClick={handleRotatePlus}
        sx={{
          transform: 'scale(-1, 1)',
          padding: 0,
        }}
      >
        {children}
      </StopPropagationIconButton>
    </>
  );
};
