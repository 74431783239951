import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  title: string;
  value: string;
};

export const StatisticCard: FC<Props> = ({ title, value }) => {
  return (
    <Box component="div" textAlign="end" width="fit-content">
      <Typography
        variant="caption"
        fontSize="12px"
        color={theme.palette.textBlack.label}
        textAlign="end"
      >
        {title}
      </Typography>
      <Typography
        variant="h6"
        fontSize="14px"
        fontWeight="bold"
        textAlign="end"
      >
        {value}
      </Typography>
    </Box>
  );
};
