import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ja';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('ja');

const tz = 'Asia/Tokyo';

export const Format = {
  date: 'YYYY/MM/DD',
  time: 'HH:mm',
  datetime: 'YYYY/MM/DD HH:mm',
  datetimeWithWeekday: 'YYYY/MM/DD (ddd) HH:mm',
  dateWithoutYear: 'MM/DD (ddd)',
};

export const dj = (date: dayjs.ConfigType) => dayjs(date).tz(tz);

export const format = (date: dayjs.ConfigType, format = Format.date) =>
  dj(date).format(format);

export const addWeeksToDate = (date: dayjs.ConfigType, weeks: number) =>
  dj(date).add(weeks, 'week');

export const getTextDateStatistic = (
  startDate: dayjs.ConfigType,
  endDate: dayjs.ConfigType
): string => {
  if (startDate && !endDate) {
    return `${format(startDate, Format.dateWithoutYear)}`;
  }
  if (!startDate && endDate) {
    return `${format(endDate, Format.dateWithoutYear)}`;
  }
  if (startDate && endDate) {
    return `${format(startDate, Format.dateWithoutYear)} ~ ${format(
      endDate,
      Format.dateWithoutYear
    )}`;
  }
  return '';
};
