import { FlatPlanogram } from '@components/organisms/flatPlanogram/flatPlanogram';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { PlanogramProvider } from '@hooks/usePlanogramProvider';
import { Box } from '@mui/material';
import { useListProductsBulkQuery } from '@reducers/shelfAppsApi';
import { getBucketsProductIds } from '@utils/planogram';
import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { BucketPlanogramPlan } from 'types/planogram';

type Props = {
  zoomScale: number;
  plan: BucketPlanogramPlan;
};

const zoomScaleMagnifier = 1.5;

export const TwoDFlatPlanogram: FC<Props> = ({ zoomScale, plan }) => {
  const { isLarger } = useBreakpoint();
  const productIds = plan.frame.detail.buckets
    ?.flatMap((bucket) => getBucketsProductIds(bucket.detail.area))
    .sort()
    .join(',');
  const { data: productsBulk } = useListProductsBulkQuery(
    { productIds: productIds, shape: true, detail: true },
    { skip: productIds === '' }
  );
  return (
    //NOTE: DnD判定を有効にするためにDndProviderでwrapする必要がある
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <PlanogramProvider value={{ products: productsBulk?.products }}>
        <Box
          component="div"
          sx={{
            height: '100%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: isLarger ? 'center' : 'flex-start',
            justifyContent: 'center',
          }}
        >
          <Box
            component="div"
            sx={{
              pb: 6,
              willChange: 'transform',
              transform: `scale(${zoomScale * zoomScaleMagnifier})`,
              transformOrigin: isLarger ? 'top center' : 'top left',
              pointerEvents: 'none',
            }}
          >
            <FlatPlanogram
              plan={plan}
              zoomScale={zoomScale}
              setZoomScale={() => void 0}
            />
          </Box>
        </Box>
      </PlanogramProvider>
    </DndProvider>
  );
};
