import { BreadcrumbForSearch } from '@components/molecules/breadcrumbForSearch/breadcrumbForSearch';
import { BreadcrumbList } from '@components/molecules/breadcrumbList/breadcrumbList';
import { SelectionModalResult } from '@components/molecules/selectionModalResult/selectionModalResult';
import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { getRealogramPermission } from '@hooks/useGetRealogramPermission';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LockIcon from '@mui/icons-material/Lock';
import PinDropIcon from '@mui/icons-material/PinDrop';
import StoreIcon from '@mui/icons-material/Store';
import { Box, ListItemButton, ListItemText, Typography } from '@mui/material';
import { selectionStoreModal } from '@reducers/selectionStoreModal/selector';
import { useAppSelector } from '@store/index';
import { AppAbility } from '@utils/abac';
import { rowsPerPage } from '@utils/const';
import { FC, Fragment, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { theme } from 'theme';
import { DirectoryType } from 'types/common';
import {
  RealogramDirectory,
  RealogramDirectoryBreadCrumbs,
  RealogramDirectoryParent,
  RealogramDirectorySearch,
} from 'types/realogram';

type Props = {
  isLoading: boolean;
  isFetching: boolean;
  searchedTotal: number;
  realogramOffset: number;
  realogramDirectories?: RealogramDirectory[] | RealogramDirectorySearch[];
  realogramBreadcrumbs?: RealogramDirectoryBreadCrumbs;
  realogramDirectoryParent?: RealogramDirectoryParent;
  disabledSelect?: boolean;
  userId?: number;
  textSearch?: string;
  ability: AppAbility;
  setRealogramOffset: (value: number) => void;
  handleClickBreadcrumbs: (item: DirectoryType) => void;
  handleClickListItem: (item: RealogramDirectory) => void;
};

export const SelectionRealograms: FC<Props> = ({
  isLoading,
  isFetching,
  searchedTotal,
  realogramOffset,
  realogramDirectories,
  realogramBreadcrumbs,
  realogramDirectoryParent,
  userId,
  textSearch,
  ability,
  setRealogramOffset,
  handleClickBreadcrumbs,
  handleClickListItem,
}) => {
  const { selectedItemId } = useAppSelector(selectionStoreModal);

  const handleEndReached = () => {
    const offset = realogramOffset + rowsPerPage;

    const shouldSkip = offset >= searchedTotal || isFetching || isLoading;

    if (shouldSkip) return;

    setRealogramOffset(offset);
  };

  const selectedItemIndex = useMemo(() => {
    const index = realogramDirectories?.findIndex(
      (d) => d.id === selectedItemId
    );

    if (index !== -1) return index;
  }, [selectedItemId, realogramDirectories]);

  return (
    <>
      <Box component="div" flex={1}>
        {realogramOffset === 0 &&
        (isFetching || isLoading || !realogramDirectories?.length) ? (
          <Box
            component="div"
            sx={{
              color: '#D9D9D9',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: theme.palette.white.primary,
              height: '100%',
            }}
          >
            <SelectionModalResult
              isLoading={isLoading || isFetching}
              textSearch={textSearch}
              data={realogramDirectories}
              type="store"
            />
          </Box>
        ) : (
          <Virtuoso
            data={realogramDirectories}
            initialTopMostItemIndex={{
              index: selectedItemIndex || 0,
            }}
            endReached={handleEndReached}
            components={{
              // eslint-disable-next-line @typescript-eslint/naming-convention -- ライブラリの仕様のため許容する
              Footer: () => {
                if (isFetching || isLoading)
                  return (
                    <Box
                      component="div"
                      sx={{
                        color: '#D9D9D9',
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.white.primary,
                      }}
                    >
                      <SelectionModalResult
                        isLoading={isLoading || isFetching}
                        textSearch={textSearch}
                        data={realogramDirectories}
                      />
                    </Box>
                  );
                return <></>;
              },
            }}
            itemContent={(_, directory) => {
              let isDisabled = false;
              const { name, type, store_area_id, store_id, store_bay_id, id } =
                directory;
              const isSelected = selectedItemId === id;
              const isFavorite = directory.favorite?.owner_id === userId;

              if (store_id) {
                isDisabled = getRealogramPermission(
                  {
                    action: 'create_file',
                    isCan: false,
                    isCandidate: false,
                    realogram: directory,
                  },
                  ability
                ).isNotEnable as boolean;
              }

              return (
                <ListItemButton
                  component="div"
                  sx={{
                    borderBottom: `1px solid ${theme.palette.dividerBlack.light}`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: isSelected
                      ? theme.palette.primary.selected
                      : '',
                    '&:hover': {
                      backgroundColor: isSelected
                        ? theme.palette.primary.selected
                        : '',
                    },
                    height: '56px',
                    cursor: isDisabled ? 'auto' : 'pointer',
                    width: '100%',
                  }}
                  onClick={() => {
                    if (isDisabled) return;
                    handleClickListItem(directory);
                  }}
                >
                  <Box
                    component="div"
                    display="flex"
                    gap={1}
                    alignItems="center"
                  >
                    {type === 'directory' && store_area_id && (
                      <PinDropIcon htmlColor={theme.palette.icons.secondary} />
                    )}
                    {type === 'directory' && (store_id || store_bay_id) && (
                      <StoreIcon htmlColor={theme.palette.icons.secondary} />
                    )}
                    <ListItemText
                      primary={
                        <Fragment>
                          <Box component="div">
                            <Typography variant="body2">
                              {name}
                              {isFavorite && (
                                <StarIcon
                                  htmlColor={theme.palette.confidence.middle}
                                  sx={{
                                    marginLeft: '4px',
                                    width: '16px',
                                    height: '16px',
                                    verticalAlign: 'text-bottom',
                                  }}
                                />
                              )}
                            </Typography>
                            {(directory as RealogramDirectorySearch)
                              .breadcrumb && (
                              <BreadcrumbForSearch
                                breadcrumbs={
                                  (directory as RealogramDirectorySearch)
                                    .breadcrumb
                                }
                                screenType="Main"
                                fontSize="12px"
                              />
                            )}
                          </Box>
                        </Fragment>
                      }
                      primaryTypographyProps={{
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        color: isDisabled
                          ? theme.palette.textBlack.disabled
                          : theme.palette.textBlack.primary,
                      }}
                    />
                  </Box>
                  {type === 'directory' && !store_id && (
                    <ChevronRightIcon
                      htmlColor={theme.palette.icons.secondary}
                    />
                  )}
                  {isSelected && type === 'directory' && store_id && (
                    <CheckCircleIcon
                      fontSize="small"
                      htmlColor={theme.palette.primary.main}
                      sx={{ alignItems: 'center' }}
                    />
                  )}
                  {isDisabled && (
                    <LockIcon
                      htmlColor={theme.palette.icons.secondary}
                      sx={{ width: '20px', height: '20px' }}
                    />
                  )}
                </ListItemButton>
              );
            }}
          />
        )}
      </Box>
      {!!realogramBreadcrumbs?.length &&
        realogramDirectoryParent &&
        !textSearch && (
          <Box
            component="div"
            padding="8px 16px"
            borderTop={`1px solid ${theme.palette.dividerBlack.medium}`}
          >
            <BreadcrumbList
              breadcrumbs={realogramBreadcrumbs}
              parentDirectory={realogramDirectoryParent}
              screenType="Modal"
              fontSize="12px"
              directoryRootName="店舗一覧"
              modalMaxWidth={80}
              handleClickBreadcrumbs={handleClickBreadcrumbs}
            />
          </Box>
        )}
    </>
  );
};
