import { yupResolver } from '@hookform/resolvers/yup';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { openToast } from '@reducers/toast';
import { useAppDispatch } from '@store/index';
import { isOnlySpaces, paths, toastMessages } from '@utils/const';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { User } from 'types/user';
import { UserFormData, schema } from '../types/userForm';
import { useMutation } from '@tanstack/react-query';
import { userApi } from '@api/services/users';
import { queryClient } from '@api/query-client';
import { userQueryKey } from '@api/hooks/useUsers';
import { OptionalUserRequest, UserResponse } from '@api/types/generated';

export const updateUserQueryKey = 'update_user';
export const useUpdateUser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [selectedUser, setSelectedUser] = useState<User>();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<UserFormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      fullname: selectedUser?.fullname ?? '',
      email: selectedUser?.email ?? '',
      role: selectedUser?.role ?? 'user',
      isActive: selectedUser?.deleted_at ? 'false' : 'true',
    },
  });

  const [fullname, role, isActive] = watch(['fullname', 'role', 'isActive']);

  const { mutateAsync: updateUser } = useMutation<
    UserResponse,
    Error,
    OptionalUserRequest & { id: number }
  >({
    mutationFn: ({ id, ...params }) => userApi.updateUser(id, params),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [userQueryKey],
      });
    },
    onError: () => {
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.updateUser,
        })
      );
    },
  });
  const { mutateAsync: activateUser } = useMutation({
    mutationFn: userApi.reactiveUser,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [userQueryKey],
      });
    },
    onError: () => {
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.updateUser,
        })
      );
    },
  });
  const { mutateAsync: deactivateUser } = useMutation({
    mutationFn: userApi.deActiveUser,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [userQueryKey],
      });
    },
    onError: () => {
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.updateUser,
        })
      );
    },
  });
  const isSelectedUserActive = selectedUser?.deleted_at ? 'false' : 'true';

  const isDisabled =
    !fullname ||
    !role ||
    (fullname === selectedUser?.fullname &&
      role === selectedUser?.role &&
      isActive === isSelectedUserActive) ||
    isOnlySpaces(fullname);

  const updateAccount = async (data: UserFormData) => {
    if (!selectedUser) return;
    dispatch(updateLoadingIndicatorState(true));
    try {
      const isActivePreviously = !selectedUser.deleted_at;

      if (data.isActive === 'false' && isActivePreviously) {
        await deactivateUser(selectedUser.id);
        await updateUser({
          id: Number(id),
          ...data,
        });
      } else if (data.isActive === 'true' && !isActivePreviously) {
        await activateUser(selectedUser.id);
        await updateUser({
          id: Number(id),
          ...data,
        });
      } else {
        await updateUser({
          id: Number(id),
          ...data,
        });
      }
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.updateUser,
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      resetSelectedUser();
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  const resetSelectedUser = () => {
    navigate(paths.users.root);
    clearErrors('fullname');
    setSelectedUser(undefined);
  };

  const selectUser = (user: User) => {
    setSelectedUser(user);
    navigate(paths.users.user(user.id));
  };

  useEffect(() => {
    setValue('fullname', selectedUser?.fullname ?? '');
    setValue('email', selectedUser?.email ?? '');
    setValue('role', selectedUser?.role ?? 'user');
    setValue('isActive', isSelectedUserActive);
  }, [setValue, selectedUser, isSelectedUserActive]);

  return {
    control,
    handleSubmit,
    errors,
    isDisabled,
    resetSelectedUser,
    selectUser,
    selectedUser,
    updateAccount,
  };
};
