import { Button, SxProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type Props = {
  type: string;
  onClick?: () => void;
  disabled?: boolean;
  borderColor?: string;
  textColor?: string;
  sx?: SxProps;
};

export const StyledButton: FC<PropsWithChildren<Props>> = ({
  type,
  children,
  onClick,
  disabled,
  borderColor,
  textColor,
  sx = {},
}) => {
  if (type === 'contained') {
    return (
      <Button
        disabled={disabled}
        type="submit"
        variant="contained"
        onClick={onClick}
        sx={{
          borderColor: '#0A40CA',
          backgroundColor: '#0A40CA',
          borderRadius: '4px',
          height: '40px',
          textTransform: 'none',
          px: 2,
          fontWeight: 600,
          whiteSpace: 'nowrap',
          ...sx,
        }}
      >
        {children}
      </Button>
    );
  }
  return (
    <Button
      disabled={disabled}
      variant="outlined"
      onClick={onClick}
      sx={{
        borderColor: borderColor ? borderColor : '#0A40CA',
        color: textColor ? textColor : '#0A40CA',
        borderRadius: '4px',
        height: '40px',
        textTransform: 'none',
        fontWeight: 600,
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};
