import { useListProductsBulkQuery } from '@reducers/shelfAppsApi';
import { filterShelfBoards } from '@utils/realogram';
import { useEffect, useMemo, useState } from 'react';
import { ProductTag, Rate, ShelfDetailView } from 'types/common';
import { ProductReport } from 'types/products';
import { RealogramShelfBoard } from 'types/realogram';

export const useExtractRealogramData = (
  view: ShelfDetailView,
  productTag: ProductTag,
  reports: ProductReport[],
  rateValue: Rate,
  shelfboards?: RealogramShelfBoard[]
) => {
  const [primaryCandidateIds, setPrimaryCandidateIds] = useState<string>();
  const { data: primaryCandidates } = useListProductsBulkQuery(
    { productIds: primaryCandidateIds, shape: false, detail: true },
    { skip: !primaryCandidateIds || !shelfboards }
  );

  const getFilterShelfBoardsArgs = useMemo(() => {
    switch (view) {
      case 'productFlag':
        return {
          realogramDetailView: view,
          productTag,
          products: primaryCandidates?.products ?? [],
        };
      case 'profit':
        return {
          realogramDetailView: view,
          report: reports ?? [],
        };
      case 'rate':
        return {
          realogramDetailView: view,
          rate: rateValue,
          products: primaryCandidates?.products ?? [],
        };
      default:
        return undefined;
    }
  }, [primaryCandidates?.products, productTag, rateValue, reports, view]);

  const filteredShelfBoards = useMemo(() => {
    return (
      shelfboards && filterShelfBoards(shelfboards, getFilterShelfBoardsArgs)
    );
  }, [getFilterShelfBoardsArgs, shelfboards]);

  useEffect(() => {
    if (!shelfboards) return;
    //primaryCandidatesの一括取得
    const ids = Array.from(
      new Set(
        shelfboards?.flatMap((board) =>
          board.compartments.flatMap((compartment) =>
            compartment.faces
              .map((face) => face.primary_candidate?.product_id)
              .filter((v) => v !== undefined)
          )
        )
      )
    );
    setPrimaryCandidateIds(ids.join(','));
  }, [shelfboards]);
  return {
    filteredShelfBoards,
    primaryCandidates,
  };
};
