import { TextPaper } from '@components/molecules';
import { Box } from '@mui/material';
import { FC } from 'react';

type Props = {
  rateScore: number;
  totalFaces: string;
};

export const RateTotalFaces: FC<Props> = ({ rateScore, totalFaces }) => {
  return (
    <Box component="div" display="flex" gap={2}>
      <TextPaper title="スコア" text={`${rateScore}点`} />
      <TextPaper title="商品数" text={totalFaces} />
    </Box>
  );
};
