import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';

type Props = {
  setIsPasswordChangeMode: () => void;
};

export const AccountPassword: FC<Props> = ({ setIsPasswordChangeMode }) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 3,
      }}
    >
      <Typography variant="h6" fontWeight="bold">
        パスワード
      </Typography>
      <Button variant="outlined" onClick={setIsPasswordChangeMode}>
        パスワードを変更
      </Button>
    </Box>
  );
};
