import { CircularSpinner } from '@components/molecules';
import { ProductsGrid } from '@components/organisms';
import { Box, Typography } from '@mui/material';
import { fullHeight } from '@utils/const';
import { FC, MutableRefObject } from 'react';
import { theme } from 'theme';
import { Product } from '../../../types/common';

type Props = {
  productsRef: MutableRefObject<HTMLDivElement | null>;
  isCompareMode?: boolean;
  handleEndReached: (index: number) => void;
  products?: Product[];
  isLoading: boolean;
  handleClickProduct: (product: Product) => void;
  isDraggable?: boolean;
  offset: number;
  selectedItemId?: number;
};
const heightCalcValue = '230px'; //TODO: do not use hard-code
const getStatus = ({
  isLoading,
  offset,
  hasProducts,
}: {
  isLoading: boolean;
  offset: number;
  hasProducts: boolean;
}) => {
  if (isLoading && offset === 0) return 'loading';
  if (!isLoading && !hasProducts) return 'empty';
  return 'products';
};

export const ProductsList: FC<Props> = ({
  productsRef,
  isCompareMode,
  handleEndReached,
  products,
  isLoading = false,
  handleClickProduct,
  isDraggable,
  offset,
  selectedItemId,
}) => {
  const componentsMap = {
    loading: (
      <Box component="div" p={2}>
        <CircularSpinner />
      </Box>
    ),
    empty: (
      <Box
        component="div"
        sx={{
          height: {
            xs: '100%',
            breakpoint: `calc(${fullHeight} - ${heightCalcValue})`,
          },
        }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.textBlack.disabled,
          }}
        >
          該当する商品はありません。
        </Typography>
      </Box>
    ),
    products: (
      <ProductsGrid
        isCompareMode={isCompareMode}
        isLoading={isLoading}
        products={products}
        handleClick={handleClickProduct}
        handleEndReached={handleEndReached}
        isDraggable={isDraggable}
        selectedItemId={selectedItemId}
      />
    ),
  };
  return (
    <>
      <Box
        ref={productsRef}
        component="div"
        sx={{
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          zIndex: 1,
          pb: selectedItemId ? '100px' : 0,
        }}
      >
        {
          componentsMap[
            getStatus({ isLoading, offset, hasProducts: !!products?.length })
          ]
        }
      </Box>
    </>
  );
};
