import { queryClient } from '@api/query-client';
import { storeBaysApi } from '@api/services/storeBays';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { useAppDispatch } from '@store/index';
import { useMutation } from '@tanstack/react-query';
import { storeBaysQueryKey } from '../fragments/storeBaysTable';
import { openToast } from '@reducers/toast';
import { AxiosError } from 'axios';
import httpStatus from 'http-status';

type Props = {
  onSettled?: VoidFunction;
  onError?: VoidFunction;
};
type CustomErrorResponse = {
  detail: string;
};
export const useDeleteStoreBay = ({ onSettled, onError }: Props) => {
  const dispatch = useAppDispatch();
  const { mutateAsync: deleteStoreBay } = useMutation({
    mutationFn: storeBaysApi.deleteStoreBay,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [storeBaysQueryKey],
      });
    },
  });
  const handleDeleteStoreBay = async (storeBayIdToDelete: number) => {
    dispatch(updateLoadingIndicatorState(true));
    try {
      await deleteStoreBay(storeBayIdToDelete);
      dispatch(
        openToast({
          type: 'success',
          message: '什器を削除しました。',
        })
      );
    } catch (error) {
      const axiosError = error as AxiosError<CustomErrorResponse>;
      if (
        axiosError.isAxiosError &&
        axiosError.status === httpStatus.FORBIDDEN
      ) {
        dispatch(
          openToast({
            type: 'error',
            message: 'この店舗の編集権限がないため、什器を削除できません。',
          })
        );
      } else if (
        axiosError.isAxiosError &&
        axiosError.status === httpStatus.BAD_REQUEST &&
        axiosError.response?.data?.detail ===
          'RealogramCandidate Exists Under StoreBay'
      ) {
        onError?.();
      } else {
        dispatch(
          openToast({ type: 'error', message: '什器の削除に失敗しました。' })
        );
      }
    } finally {
      onSettled?.();
      dispatch(updateLoadingIndicatorState(false));
    }
  };
  return {
    handleDeleteStoreBay,
  };
};
