import { Box } from '@mui/material';
import {
  convertMeterToPixel,
  createCompartment,
  getCompartmentSize,
} from '@utils/planogram';
import { FC } from 'react';
import { DndData } from 'types/common';

type Props = {
  dndData: DndData;
};

export const DisplayableHighlight: FC<Props> = ({
  dndData: { compartment, product },
}) => {
  const { width, height } = getCompartmentSize(
    compartment ?? createCompartment(product),
    product
  );
  return (
    <Box
      component="div"
      sx={{
        width: convertMeterToPixel(width),
        height: convertMeterToPixel(height),
        backgroundColor: '#CCE0FF',
        borderRadius: '2px',
        border: '1px solid #0188E952',
      }}
    />
  );
};
