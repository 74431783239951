import { Alert, Snackbar } from '@mui/material';
import { closeToast } from '@reducers/toast';
import { selectToast } from '@reducers/toast/selector';
import { useAppDispatch, useAppSelector } from '@store/index';
import { FC } from 'react';

const defaultAutoHideDuration = 3000;

export const Toast: FC = () => {
  const dispatch = useAppDispatch();
  const { open, type, message, renderProps } = useAppSelector(selectToast);
  const handleClose = () => dispatch(closeToast());

  /**
   *  If type is error, the toast does not close automatically.
   *  Otherwise closing after 3000ms
   */
  const autoHideDuration = type === 'error' ? null : defaultAutoHideDuration;

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      key={message}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      disableWindowBlurListener
    >
      <Alert onClose={() => dispatch(closeToast())} severity={type}>
        {message}
        {renderProps}
      </Alert>
    </Snackbar>
  );
};
