import { CorrectedChip } from '@components/molecules';
import { RealogramScoreLabel } from '@components/organisms/realogramScoreLabel/realogramScoreLabel';
import { FC } from 'react';
import { RealogramSelectedItem } from '../../../../types/realogram';

type Props = {
  selectedItem: RealogramSelectedItem;
};

export const RealogramCandidateLabel: FC<Props> = ({
  selectedItem: {
    item: {
      in_stock: inStock,
      is_unknown: isUnknown,
      revised,
      primary_candidate: primaryCandidate,
    },
  },
}) => {
  if (revised) return <CorrectedChip />;
  if (isUnknown || !inStock || !primaryCandidate) return <></>;
  return (
    <RealogramScoreLabel confidenceLevel={primaryCandidate.confidence_level} />
  );
};
