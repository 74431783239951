import { useGetStoreBayMutation } from '@reducers/shelfAppsApi';
import { useEffect, useState } from 'react';
import { Planogram } from 'types/planogram';

export const useStoreBayName = (planogram?: Planogram) => {
  const [storeBayName, setStoreBayName] = useState('-');
  const [getStoreBay] = useGetStoreBayMutation();
  const getStoreBayName = async () => {
    if (!planogram?.store_bay_id) return;
    try {
      const res = await getStoreBay(planogram?.store_bay_id);
      if ('error' in res || !res?.data?.store_bay?.name) {
        throw new Error('storebay data is not exist');
      } else {
        setStoreBayName(res?.data?.store_bay?.name);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!planogram) return;
    getStoreBayName();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- getStoreBayName is not needed in the dependency array
  }, [planogram]);

  return {
    storeBayName,
  };
};
