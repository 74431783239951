import { FC } from 'react';
import { Box } from '@mui/material';
import { ProductFlagLabel } from '@components/organisms/realogramCandidatesList/fragments';
import { Product } from '../../../../types/common';

type Props = {
  product?: Product;
};

export const ProductFlagView: FC<Props> = ({ product }) => {
  return (
    <Box component="div" display="inline-flex" alignItems="center" gap="4px">
      {product?.detail?.tags?.map((tag) => (
        <ProductFlagLabel key={tag} name={tag} />
      ))}
    </Box>
  );
};
