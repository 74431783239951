import { CategoryIconDivider } from '@components/molecules/categoryIconDivider/categoryIconDivider';
import { ProductImage } from '@components/organisms/productImage/productImage';
import { Box, TableCell, Typography } from '@mui/material';
import { Format, format } from '@utils/date';
import { FC } from 'react';
import { theme } from 'theme';
import { Product } from 'types/common';

type TableCellsProps = {
  product: Product;
};

export const TableCells: FC<TableCellsProps> = ({ product }) => {
  const overFlowSx = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0px',
  };

  return (
    <>
      <TableCell
        sx={{
          padding: '8px',
          width: '48px',
          height: '48px',
        }}
      >
        <Box
          component="div"
          display="flex"
          height={48}
          width={48}
          position="relative"
        >
          <ProductImage
            product={product}
            layout="fill"
            objectFit="contain"
            imageSizeType="tiny"
            baseSize={48}
          />
        </Box>
      </TableCell>
      <TableCell
        sx={{
          ...overFlowSx,
          fontWeight: 600,
        }}
      >
        <Box component="div">
          <Typography
            fontWeight="600"
            noWrap
            color={theme.palette.textBlack.table}
          >
            {product.name ? product.name : '-'}
          </Typography>
          <Typography
            noWrap
            color={theme.palette.text.secondary}
            display="inline-flex"
            alignItems="center"
          >
            <CategoryIconDivider categories={product.detail?.categories} />
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '16px' }}>
        <Box component="div">
          <Typography noWrap color={theme.palette.textBlack.table}>
            {product.detail?.ean_code ?? '-'}
          </Typography>
          <Typography noWrap color={theme.palette.textBlack.table}>
            {product.detail?.organization_product_id ?? '-'}
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '16px' }}>
        <Box component="div">
          <Typography noWrap color={theme.palette.textBlack.table}>
            {format(product.updated_at, Format.datetime)}
          </Typography>
        </Box>
      </TableCell>
    </>
  );
};
