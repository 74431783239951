import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type CurrentDirectoryProps = {
  directory: string;
  maxWidth: number;
  fontSize: number | string;
};

const currentDirectoryMagnification = 2;

export const CurrentDirectory: FC<CurrentDirectoryProps> = ({
  directory,
  maxWidth,
  fontSize,
}) => {
  return (
    <Box component="div" display="flex" alignItems="center">
      <Box
        component="div"
        maxWidth={maxWidth * currentDirectoryMagnification}
        textAlign="center"
      >
        <Typography
          noWrap
          fontSize={fontSize}
          fontWeight="bold"
          color={theme.palette.textBlack.primary}
        >
          {directory}
        </Typography>
      </Box>
    </Box>
  );
};
