import { Box, TableCell, Typography } from '@mui/material';
import { Format, format } from '@utils/date';
import { getUserRole } from '@utils/users';
import { FC } from 'react';
import { theme } from 'theme';
import { User } from 'types/user';
import { StatusLabel } from './statusLabel';

type TableCellsProps = {
  user: User;
};

export const TableCells: FC<TableCellsProps> = ({ user }) => {
  const overFlowSx = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0px',
  };

  return (
    <>
      <TableCell
        sx={{
          ...overFlowSx,
          fontWeight: 600,
          pl: '12px',
          height: '48px',
        }}
      >
        <Box component="div">
          <Typography
            color={theme.palette.textBlack.table}
            variant="body2"
            sx={{ ...overFlowSx }}
          >
            {user.fullname ? user.fullname : '-'}
          </Typography>
        </Box>
      </TableCell>
      <TableCell
        sx={{
          ...overFlowSx,
          fontWeight: 600,
        }}
      >
        <Box component="div">
          <Typography
            sx={{ ...overFlowSx, paddingLeft: '16px' }}
            color={theme.palette.textBlack.table}
            display="inline-flex"
            alignItems="center"
            variant="body2"
          >
            {user.email}
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '16px' }}>
        <Box component="div">
          <Typography
            noWrap
            color={theme.palette.textBlack.table}
            variant="body2"
          >
            {getUserRole(user.role)}
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={{ paddingLeft: '16px' }}>
        <Box component="div">
          <StatusLabel isDeleted={!!user?.deleted_at} />
        </Box>
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '16px' }}>
        <Box component="div">
          <Typography
            noWrap
            color={theme.palette.textBlack.table}
            variant="body2"
          >
            {format(user.created_at, Format.datetime)}
          </Typography>
        </Box>
      </TableCell>
    </>
  );
};
