import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const AutoFillIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <rect x="3" y="16" width="7" height="6" />
      <rect x="3" y="9" width="7" height="6" />
      <rect x="3" y="2" width="7" height="6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.47889L17 2L22 7.47889L18.1786 7.47889L18.1786 21.9998H15.8216L15.8216 7.47889L12 7.47889Z"
      />
    </SvgIcon>
  );
};
