import { TextPaper } from '@components/molecules';
import { Box } from '@mui/material';
import { FC } from 'react';

type Props = {
  totalShelfBoards: string;
  totalUniques: string;
  isFlat: boolean;
};

export const PlanogramStatistics: FC<Props> = ({
  totalShelfBoards,
  totalUniques,
  isFlat,
}) => {
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      gap={2}
    >
      <TextPaper
        title={isFlat ? 'カゴ数' : '段数'}
        text={totalShelfBoards}
        paperSx={{
          height: 54,
          width: '120px',
          display: 'flex',
          flexDirection: 'column',
        }}
      />
      <TextPaper
        title="アイテム数"
        text={totalUniques}
        paperSx={{
          height: 54,
          width: '120px',
          display: 'flex',
          flexDirection: 'column',
        }}
      />
    </Box>
  );
};
