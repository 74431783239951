import { AlertDialog } from '@components/organisms';
import { Button, DialogContentText } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  open: boolean;
  handleClickCancelButton: VoidFunction;
};

export const DeleteStoreBayAlert: FC<Props> = ({
  handleClickCancelButton,
  open,
}) => {
  return (
    <AlertDialog
      open={open}
      title="什器を削除できません。"
      pbDialogContent={1}
      content={
        <DialogContentText sx={{ color: theme.palette.textBlack.primary }}>
          この什器に紐づく棚割実績が存在しているため、什器を削除できません。什器を削除する場合は、棚割実績一覧から、この什器に紐づく棚割実績をすべて削除してください。
        </DialogContentText>
      }
      cancelButton={
        <Button
          variant="text"
          onClick={handleClickCancelButton}
          sx={{
            color: theme.palette.textBlack.primary,
            fontWeight: 700,
          }}
        >
          閉じる
        </Button>
      }
      confirmButton={<></>}
    />
  );
};
