import { StyledButton } from '@components/molecules/styledButton/styledButton';
import {
  Box,
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Format, format } from '@utils/date';
import { getUserRole, roles } from '@utils/users';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { theme } from 'theme';
import { User } from 'types/user';
import {
  UserFormControl,
  UserFormData,
  UserFormSubmit,
  UsetFormErrors,
} from '../types/userForm';
import { DeleteUserMfaDialog } from './deleteUserMfaDialog';

type Props = {
  open: boolean;
  user: User;
  handleClose: () => void;
  updateAccount: (data: UserFormData) => void;
  control: UserFormControl;
  handleSubmit: UserFormSubmit;
  errors: UsetFormErrors;
  isDisabled: boolean;
  isDeleteMfaDialogOpen: boolean;
  openDeleteMfaDialog: () => void;
  closeDeleteMfaDialog: () => void;
  handleDeleteMfa: () => void;
  isMfaActive: boolean;
};

export const UpdateUserDrawer: FC<Props> = ({
  open,
  user,
  handleClose,
  updateAccount,
  control,
  handleSubmit,
  errors,
  isDisabled,
  isDeleteMfaDialogOpen,
  openDeleteMfaDialog,
  closeDeleteMfaDialog,
  handleDeleteMfa,
  isMfaActive,
}) => {
  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        PaperProps={{
          sx: {
            overflowY: 'visible',
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          width={320}
          height="100%"
          position="relative"
          mt={2}
          component="form"
          onSubmit={handleSubmit(updateAccount)}
        >
          <Box
            component="div"
            sx={{ padding: '8px 16px 24px 16px' }}
            display="flex"
            flexDirection="column"
            gap={2}
            borderBottom={`1px solid ${theme.palette.dividerBlack.medium}`}
          >
            <Typography variant="subtitle1" fontWeight={700}>
              ユーザー編集
            </Typography>
            <Box component="div" display="flex" flexDirection="column" gap={2}>
              <Typography
                variant="body2"
                color={theme.palette.textBlack.secondary}
              >
                *は必須項目です。
              </Typography>
              <Controller
                name="fullname"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    label="名前"
                    defaultValue={user?.fullname}
                    error={!!errors.fullname}
                    helperText={errors.fullname?.message}
                    size="small"
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    disabled
                    fullWidth
                    defaultValue={user?.email}
                    label="メールアドレス"
                    size="small"
                    sx={{
                      backgroundColor: theme.palette.backgroundBlack.disabled,
                    }}
                  />
                )}
              />
              <Controller
                name="role"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth error={!!errors.role} required>
                    <InputLabel>アカウント権限</InputLabel>
                    <Select
                      {...field}
                      label="アカウント権限"
                      size="small"
                      defaultValue={user?.role}
                    >
                      {roles.map((role, index) => (
                        <MenuItem key={index} value={role}>
                          {getUserRole(role)}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errors.role && (
                      <FormHelperText>{errors.role.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <Box
                component="div"
                display="flex"
                gap={1}
                flexDirection="column"
              >
                <Typography
                  variant="body3"
                  color={theme.palette.textBlack.label}
                >
                  サービス利用
                </Typography>
                <Controller
                  name="isActive"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field} sx={{ mx: 1 }}>
                      <FormControlLabel
                        defaultChecked
                        value="true"
                        control={<Radio />}
                        label="利用可"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        value="false"
                        label="停止"
                      />
                    </RadioGroup>
                  )}
                />
                <Typography variant="body3">
                  このユーザーをログインさせない場合には、「停止」にしてください。
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            component="div"
            p={2}
            display="flex"
            flexDirection="column"
            gap={1}
            borderBottom={`1px solid ${theme.palette.dividerBlack.medium}`}
          >
            <Typography variant="body3" color={theme.palette.textBlack.label}>
              二要素認証
            </Typography>
            <Box component="div" gap={1} alignItems="center" display="flex">
              <Typography variant="body1">
                {' '}
                {isMfaActive ? '設定済み' : '未設定'}
              </Typography>
              <Button
                variant="text"
                sx={{ color: theme.palette.error.main, p: '0 8px' }}
                disabled={!isMfaActive}
              >
                <Typography
                  variant="buttonText2"
                  onClick={() => openDeleteMfaDialog()}
                >
                  二要素認証を削除
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box component="div" p={2}>
            <Box component="div" display="flex" gap="8px" alignItems="center">
              <Typography
                width="80px"
                variant="body3"
                color={theme.palette.textBlack.label}
              >
                追加日時
              </Typography>
              <Typography color={theme.palette.textBlack.table} variant="body3">
                {format(user.created_at, Format.datetime)}
              </Typography>
            </Box>
          </Box>
          <Box
            component="div"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              width: '100%',
              position: 'absolute',
              bottom: 0,
              height: '64px',
              padding: 2,
              borderTop: '1px solid #E0E0E0',
              backgroundColor: theme.palette.white.primary,
              gap: 2,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                color: theme.palette.textBlack.primary,
                ':hover': { bgcolor: theme.palette.backgroundBlack.hover },
              }}
            >
              キャンセル
            </Button>
            <StyledButton
              sx={{ width: 60, minWidth: 'auto' }}
              type="contained"
              disabled={isDisabled}
            >
              変更
            </StyledButton>
          </Box>
        </Box>
      </Drawer>
      <DeleteUserMfaDialog
        open={isDeleteMfaDialogOpen}
        user={user}
        handleClose={closeDeleteMfaDialog}
        handleDelete={handleDeleteMfa}
      />
    </>
  );
};
