import {
  BayPlanBayPart,
  PTSBoardBayPart,
  ShelvesHookBarBayPart,
  ShelvesShelfBoardBayPart,
} from 'types/bayPlan';
import {
  PlanogramBayPart,
  PlanogramHookBarPart,
  PlanogramPtsShelfBoard,
  PlanogramShelfBoardPart,
} from 'types/planogram';

export const createPlanogramBayPart = (
  bayPart: BayPlanBayPart,
  elevation: number
): PlanogramBayPart => {
  switch (bayPart.type) {
    case 'shelf_board':
      return createPlanogramShelfBoardPart(bayPart, elevation);
    case 'hook_bar':
      return createPlanogramHookBarPart(bayPart, elevation);
    default:
      return createPlanogramPtsShelfBoardFromBayPart(
        bayPart as PTSBoardBayPart,
        elevation
      );
  }
};

export const createPlanogramPtsShelfBoardFromBayPart = (
  board: PTSBoardBayPart,
  elevation: number
): PlanogramPtsShelfBoard => {
  return {
    type: board.type,
    width: board.width,
    height: board.height,
    depth: board.depth,
    elevation,
  };
};

export const createPlanogramShelfBoardPart = (
  shelfBoard: ShelvesShelfBoardBayPart,
  elevation: number
): PlanogramShelfBoardPart => {
  return {
    bay_part_id: shelfBoard.id,
    name: shelfBoard.name,
    type: shelfBoard.type,
    width: shelfBoard.width,
    height: shelfBoard.height,
    depth: shelfBoard.depth,
    detail: shelfBoard.detail,
    elevation,
  };
};

export const createPlanogramHookBarPart = (
  hookBar: ShelvesHookBarBayPart,
  elevation: number
): PlanogramHookBarPart => {
  return {
    bay_part_id: hookBar.id,
    name: hookBar.name,
    type: hookBar.type,
    width: hookBar.width,
    height: hookBar.height,
    depth: hookBar.depth,
    detail: hookBar.detail,
    elevation,
  };
};

export const colBayPartDefault = 36;
export const colBayPartCompareMode = 24;
export const colGridBayPartDefault = 12;
export const gridColBayPartInCompareMode = 6;
export const gridColBayPartDefault = 4;
