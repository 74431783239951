import { Header } from '@components/organisms/header/header';
import { Close, HelpOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { theme } from 'theme';
import { RealogramShelfBoard } from 'types/realogram';

type Props = {
  addFaceModeLabel: string;
  shelfBoards?: RealogramShelfBoard[];
  compartmentNumber?: number;
  isEnableSave: boolean;
  setCompartmentNumber: (compartmentNumber: number) => void;
  handleAddFaceMode: (compartmentNumber: number) => void;
  setIsOperationDialogOpen: () => void;
  handleSaveNewFace: () => void;
};
export const AddFaceModeHeader = ({
  addFaceModeLabel,
  shelfBoards,
  compartmentNumber,
  isEnableSave,
  setCompartmentNumber,
  handleAddFaceMode,
  setIsOperationDialogOpen,
  handleSaveNewFace,
}: Props) => {
  const saveButtonStyle = isEnableSave
    ? {
        color: theme.palette.white.primary,
        backgroundColor: theme.palette.primary.main,
        fontWeight: 700,
        minWidth: '60px',
        ':hover': {
          backgroundColor: theme.palette.primary.mainHover,
        },
      }
    : {
        color: theme.palette.textBlack.disabled,
        backgroundColor: theme.palette.backgroundBlack.disabled,
        fontWeight: 700,
        minWidth: '60px',
      };
  return (
    <Header
      sx={{
        px: 3,
        py: 2,
        zIndex: 10,
        position: 'fixed',
        background: '#fff',
        width: '100%',
      }}
    >
      <Box
        component="div"
        width="100%"
        display="flex"
        justifyContent="space-between"
      >
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <IconButton
            edge="start"
            sx={{
              width: 40,
              height: 40,
              border: `1px solid ${theme.palette.dividerBlack.dark}`,
              borderRadius: 1,
              ml: 0,
              mr: 2,
            }}
            onClick={() => handleAddFaceMode(0)}
          >
            <Close
              fontSize="medium"
              sx={{ color: theme.palette.icons.primary }}
            />
          </IconButton>
          <Box component="div" gap="4px" display="flex" alignItems="center">
            <Typography variant="headingS">区画を追加</Typography>
            <IconButton onClick={setIsOperationDialogOpen}>
              <HelpOutline sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            </IconButton>
          </Box>
          <TextField
            id="select-shelf-compartment-number"
            select
            size="small"
            label={addFaceModeLabel}
            value={compartmentNumber ?? 0}
            sx={{
              width: '200px',
              '.MuiSelect-select': {
                maxHeight: '20px',
              },
            }}
            onChange={(event) => setCompartmentNumber(+event.target.value)}
          >
            {shelfBoards?.map((board, index) => (
              <MenuItem
                sx={{
                  '.MuiTypography-root': {
                    marginTop: '5px',
                  },
                }}
                key={index}
                value={index}
              >
                <ListItemText>
                  <Typography
                    color={theme.palette.textBlack.primary}
                    noWrap
                    fontSize={16}
                    sx={{
                      fontWeight: '400',
                      lineHeight: '16px',
                      marginLeft: '5px',
                    }}
                  >
                    {`${index + 1}${addFaceModeLabel}`}
                  </Typography>
                </ListItemText>
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Button
          sx={saveButtonStyle}
          disabled={!isEnableSave}
          onClick={handleSaveNewFace}
        >
          完了
        </Button>
      </Box>
    </Header>
  );
};
