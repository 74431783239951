import httpStatus from 'http-status';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { object, ref, string } from 'yup';
import { passwordErrorMessage, passwordSchemaMessage } from './const';
import { isValidPassword } from '@utils/users';

// パスワード入力時のエラー文
export const passwordSchema = object({
  currentPassword: string().required(passwordSchemaMessage.currentPassword),
  newPassword: string()
    .required(passwordSchemaMessage.newPassword)
    .test(
      'is-valid-password',
      passwordSchemaMessage.typePasswordAgain,
      (value) => (value ? isValidPassword(value) : false)
    ),
  newPasswordConfirmation: string()
    .required(passwordSchemaMessage.newPasswordConfirmation)
    .oneOf([ref('newPassword')], passwordSchemaMessage.typePasswordAgain),
});

// パスワード送信時のエラー文
export const toErrorMessage = (error: FetchBaseQueryError) => {
  switch (error.status) {
    case httpStatus.UNAUTHORIZED:
      return passwordErrorMessage.wrongPassword;
    case httpStatus.NOT_ACCEPTABLE:
      return passwordErrorMessage.notAcceptable;
    default:
      return passwordErrorMessage.failedToChangePassword;
  }
};
