import { AuthFlow } from 'types/user';

export const authFlow: AuthFlow = 'USER_PASSWORD_AUTH';
export const mfaDeviceResponse = 'SOFTWARE_TOKEN_MFA';

export const passwordSchemaMessage = {
  currentPassword: '現在のパスワードを入力してください。',
  newPassword: '新しいパスワードを入力してください。',
  newPasswordConfirmation: '新しいパスワード（確認）を入力してください。',
  typePasswordAgain: 'パスワードが一致していません。もう一度入力してください。',
};

export const passwordErrorMessage = {
  wrongPassword: 'パスワードが間違っています。正しい情報を入力してください。',
  notAcceptable:
    'パスワードは英語大文字小文字、数字、記号をすべて含み、11 文字以上の長さが必要です。',
  failedToChangePassword: 'パスワードの変更に失敗しました。',
};
