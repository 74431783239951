import { Button, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from '../../../theme';
import { RoleIcon } from '@components/molecules/roleIcon/roleIcon';
import { Scope } from 'types/sharePermission';

export type Props = {
  handleClickButton?: () => void;
  scope?: Scope;
  disabled?: boolean;
};
export const SharePermissionButton: FC<Props> = ({
  scope,
  handleClickButton,
  disabled = false,
}) => {
  return (
    <Button
      onClick={handleClickButton}
      sx={{
        maxWidth: '116px',
        minWidth: '102px',
        '&.MuiButton-root': {
          border: `1px solid ${
            disabled
              ? theme.palette.backgroundBlack.active
              : theme.palette.dividerBlack.dark
          }`,
        },
        '&:hover': {
          backgroundColor: theme.palette.backgroundBlack.hover,
          border: '1px solid rgba(0, 0, 0, 0.23)',
        },
        ...{
          backgroundColor: disabled
            ? theme.palette.backgroundBlack.disabled
            : 'transparent',
        },
      }}
      variant="outlined"
      disabled={disabled}
    >
      <ListItemIcon
        sx={{
          color: theme.palette.textBlack.secondary,
          alignItems: 'center',
        }}
      >
        <RoleIcon
          option={scope}
          disabled={disabled}
          sx={{ width: '20px', height: '20px' }}
        />
        <ListItemText>
          <Typography
            variant="buttonText1"
            sx={{
              marginLeft: '5px',
            }}
            color={
              disabled
                ? theme.palette.textBlack.disabled
                : theme.palette.textBlack.primary
            }
            noWrap
          >
            {getScopeLabel(scope)}
          </Typography>
        </ListItemText>
      </ListItemIcon>
    </Button>
  );
};

const getScopeLabel = (scope?: Scope) => {
  switch (scope) {
    case 'private':
      return '非公開';
    case 'public':
      return '全体公開';
    case 'restricted':
    default:
      return '限定公開';
  }
};
