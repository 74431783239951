import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { useAppDispatch } from '@store/index';
import {
  getNextRealogramItem,
  getPrevRealogramItem,
  isCompartmentMode,
} from '@utils/realogram';
import { ShelfDetailView } from 'types/common';
import {
  RealogramCandidate,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';

export const useSlideButton = (
  isBucketType: boolean,
  realogramDetailView: ShelfDetailView,
  updateQueryParameter: (query: string, value: string) => void,
  shelfBoardIndex?: number,
  compartmentIndex?: number,
  faceIndex?: number,
  selectedItem?: RealogramSelectedItem,
  realogramCandidate?: RealogramCandidate,
  shelfBoards?: RealogramShelfBoard[]
) => {
  const dispatch = useAppDispatch();
  const hasSelectedItemIndex = !(
    shelfBoardIndex === undefined ||
    compartmentIndex === undefined ||
    faceIndex === undefined
  );

  const shouldButtonBeDisabled = (value: 'next' | 'prev'): boolean => {
    if (!selectedItem || !realogramCandidate?.detail || !shelfBoards?.length)
      return true;
    // Note: タブのフィルタリングで選択中の商品が除外されている場合、ボタンを無効化する
    if (!hasSelectedItemIndex) {
      return true;
    }
    if (value === 'prev') {
      // NOTE: 不正な棚情報でないか先読み
      const isIrregularPrev = shelfBoards.at(shelfBoardIndex + 1)
        ? !shelfBoards[shelfBoardIndex + 1].compartments.at(0)
        : false;
      const offset = 2; //emptyCompartment is prev and shelf is second from top
      if (isIrregularPrev && shelfBoardIndex === shelfBoards.length - offset) {
        return compartmentIndex === 0 && faceIndex === 0;
      }
      const shelfIndex = isBucketType ? 0 : shelfBoards.length - 1;

      return (
        shelfBoardIndex === shelfIndex &&
        compartmentIndex === 0 &&
        faceIndex === 0
      );
    } else {
      const remainingCompartments = isBucketType
        ? shelfBoards
            .slice(shelfBoardIndex + 1)
            .flatMap((board) => board.compartments)
        : shelfBoards
            .slice(0, shelfBoardIndex)
            .flatMap((board) => board.compartments);
      const currentShelfBoardCompartments =
        shelfBoards.at(shelfBoardIndex)?.compartments;

      const lastFaceIndex =
        (currentShelfBoardCompartments?.at(-1)?.faces.length ?? 0) - 1;

      const isLastCompartment =
        remainingCompartments.length === 0 &&
        compartmentIndex === (currentShelfBoardCompartments?.length ?? 0) - 1;
      return (
        (isCompartmentMode(realogramDetailView) && isLastCompartment) ||
        (isLastCompartment && faceIndex === lastFaceIndex)
      );
    }
  };

  const selectNext = () => {
    if (selectedItem && shelfBoards) {
      if (!hasSelectedItemIndex) return;
      const isLastCompartment =
        shelfBoards.at(shelfBoardIndex)?.compartments.at(-1)?.id ===
        selectedItem.compartmentId;
      const isLastFace =
        shelfBoards
          .at(shelfBoardIndex)
          ?.compartments.at(compartmentIndex)
          ?.faces.at(-1)?.id === selectedItem.item.id;
      //compartment
      if (isCompartmentMode(realogramDetailView)) {
        if (isLastCompartment) {
          const shelfIndex = isBucketType
            ? shelfBoardIndex + 1
            : shelfBoardIndex - 1;
          // 段の最後の区画の最後の商品の場合、次の棚の最初の区画の最初の商品を選択する
          const shelfboard = shelfBoards.at(shelfIndex);
          const compartment = shelfboard?.compartments.at(0);
          const item = compartment?.faces.at(0);
          if (shelfboard && compartment && item) {
            dispatch(
              updateSelectedRealogramItem({
                item,
                compartmentId: compartment.id,
                shelfBoardId: shelfboard.id,
              })
            );
            updateQueryParameter('item', compartment.id);
          }
          return;
        }
        const shelfboard = shelfBoards.at(shelfBoardIndex);
        const compartment = shelfboard?.compartments.at(compartmentIndex + 1);
        const item = compartment?.faces.at(0);
        if (shelfboard && compartment && item) {
          dispatch(
            updateSelectedRealogramItem({
              item,
              compartmentId: compartment.id,
              shelfBoardId: shelfboard.id,
            })
          );
          updateQueryParameter('item', compartment.id);
        }
        //face
      } else {
        // if the selectedItem is the compartment's last item and compartment is last
        const shelfboard = shelfBoards.at(shelfBoardIndex);
        if (isLastCompartment && isLastFace && shelfBoardIndex.toString()) {
          const shelfIndex = isBucketType
            ? shelfBoardIndex + 1
            : shelfBoardIndex;
          const index = isBucketType ? 0 : 1;
          dispatch(
            updateSelectedRealogramItem(
              getNextRealogramItem(shelfBoards, selectedItem, shelfIndex, index)
            )
          );
          updateQueryParameter('item', selectedItem.compartmentId);
          return;
        }
        if (isLastFace) {
          const compartment = shelfboard?.compartments.at(compartmentIndex + 1);
          const item = compartment?.faces.at(0);
          if (item && compartment && shelfboard) {
            dispatch(
              updateSelectedRealogramItem({
                item,
                compartmentId: compartment.id,
                shelfBoardId: shelfboard.id,
              })
            );
            updateQueryParameter('item', compartment.id);
          }
          return;
        }
        const compartment = shelfboard?.compartments.at(compartmentIndex);
        const item = compartment?.faces.at(faceIndex + 1);
        if (shelfboard && compartment && item) {
          dispatch(
            updateSelectedRealogramItem({
              item,
              compartmentId: compartment.id,
              shelfBoardId: shelfboard.id,
            })
          );
          updateQueryParameter('item', compartment.id);
        }
      }
    }
  };

  const selectPrevious = () => {
    if (selectedItem && shelfBoards) {
      if (!hasSelectedItemIndex) return;
      const shelfIndex = isBucketType
        ? shelfBoardIndex - 1
        : shelfBoardIndex + 1;
      const prevLastShelfBoard = shelfBoards.at(shelfIndex);
      const lastIndex = (prevLastShelfBoard?.compartments.length ?? 0) - 1;
      if (isCompartmentMode(realogramDetailView)) {
        if (compartmentIndex === 0) {
          const shelfboard = shelfBoards.at(shelfIndex);
          const compartment = shelfboard?.compartments.at(lastIndex);
          const item = compartment?.faces.at(0);
          if (item && compartment && shelfboard) {
            dispatch(
              updateSelectedRealogramItem({
                item,
                compartmentId: compartment.id,
                shelfBoardId: shelfboard.id,
              })
            );
            updateQueryParameter('item', compartment.id);
          }
        } else {
          const shelfboard = shelfBoards.at(shelfBoardIndex);
          const compartment = shelfboard?.compartments.at(compartmentIndex - 1);
          const item = shelfboard?.compartments
            .at(compartmentIndex - 1)
            ?.faces.at(0);
          if (shelfboard && compartment && item) {
            dispatch(
              updateSelectedRealogramItem({
                item,
                compartmentId: compartment.id,
                shelfBoardId: shelfboard.id,
              })
            );
          }
        }
      } else {
        // if the selectedItem is the compartment's first item and compartment is the first in shelf
        const shelfboard = shelfBoards.at(shelfBoardIndex);
        if (compartmentIndex === 0 && faceIndex === 0) {
          const shelfIndex = isBucketType
            ? shelfBoardIndex - 1
            : shelfBoardIndex;
          const index = isBucketType ? 0 : 1;
          dispatch(
            updateSelectedRealogramItem(
              getPrevRealogramItem(shelfBoards, selectedItem, shelfIndex, index)
            )
          );
          updateQueryParameter('item', selectedItem.compartmentId);
          return;
          //first item in compartment
        }
        if (faceIndex === 0) {
          const compartment = shelfboard?.compartments.at(compartmentIndex - 1);
          const item = compartment?.faces.at(-1);
          if (item && compartment && shelfboard) {
            dispatch(
              updateSelectedRealogramItem({
                item,
                compartmentId: compartment.id,
                shelfBoardId: shelfboard.id,
              })
            );
            updateQueryParameter('item', selectedItem.compartmentId);
          }
          return;
        }
        const compartment = shelfboard?.compartments.at(compartmentIndex);
        const item = compartment?.faces.at(faceIndex - 1);
        if (item && compartment && shelfboard) {
          dispatch(
            updateSelectedRealogramItem({
              //prev item in the same compartment
              item,
              compartmentId: compartment.id,
              shelfBoardId: shelfboard.id,
            })
          );
          updateQueryParameter('item', compartment.id);
        }
      }
    }
  };

  return {
    shouldButtonBeDisabled,
    selectPrevious,
    selectNext,
  };
};
