import { useBreakpoint } from '@hooks/useBreakpoint';
import { Box } from '@mui/material';
import { useGetProductAdjustersQuery } from '@reducers/shelfAppsApi';
import { getProductDisplayValues } from '@utils/product';
import { FC, useMemo } from 'react';
import { Product, ShelfDetailView } from 'types/common';
import { RealogramSelectedItem } from 'types/realogram';
import { SlideButtons } from '@components/molecules/slideButtons/SlideButtons';
import { HorizontalCandidate } from './fragments/horizontalCandidate';
import { VerticalCandidate } from './fragments/verticalCandidate';

type Props = {
  products?: Product[];
  selectedItem: RealogramSelectedItem;
  selectNext: () => void;
  selectPrevious: () => void;
  shouldButtonBeDisabled: (value: 'next' | 'prev') => boolean;
  realogramDetailView: ShelfDetailView;
  realogramCandidateId?: number;
  isBucketType?: boolean;
};

export const ProductCandidatePlanogram: FC<Props> = ({
  products = [],
  selectedItem,
  selectNext,
  selectPrevious,
  shouldButtonBeDisabled,
  realogramDetailView,
  isBucketType = false,
}) => {
  const { isLarger } = useBreakpoint();
  const product = useMemo(() => {
    return products?.find(
      (product) =>
        product.id === selectedItem.item?.primary_candidate?.product_id
    );
  }, [products, selectedItem.item?.primary_candidate?.product_id]);
  const { name, eanCode, productCode } = getProductDisplayValues(
    selectedItem.item,
    product
  );

  const { data } = useGetProductAdjustersQuery(
    {
      product_id: product?.id ?? 0,
    },
    {
      skip: !product?.id,
    }
  );

  const isEdited = !!data?.product_adjuster;
  const eanCodeValue = eanCode ? eanCode : '-';
  const productCodeValue = productCode ? productCode : '-';
  const isShowJANAndCD =
    realogramDetailView === 'profit' || realogramDetailView === 'default';

  return (
    <Box component="div" display="flex" flexDirection="column" py={0.5}>
      {/* separated the components for vertical and horizontal screens due to the numerous conditional branches in the styling. */}
      {isLarger ? (
        <HorizontalCandidate
          isBucketType={isBucketType}
          realogramDetailView={realogramDetailView}
          product={product}
          selectedItem={selectedItem}
          name={name}
          isShowJANAndCD={isShowJANAndCD}
          eanCodeValue={eanCodeValue}
          productCodeValue={productCodeValue}
          slideButtons={
            <SlideButtons
              selectNext={selectNext}
              selectPrevious={selectPrevious}
              shouldButtonBeDisabled={shouldButtonBeDisabled}
            />
          }
          productCode={productCode}
          isEdited={isEdited}
        />
      ) : (
        <VerticalCandidate
          isBucketType={isBucketType}
          realogramDetailView={realogramDetailView}
          product={product}
          selectedItem={selectedItem}
          name={name}
          isShowJANAndCD={isShowJANAndCD}
          eanCodeValue={eanCodeValue}
          productCodeValue={productCodeValue}
          slideButtons={
            <SlideButtons
              selectNext={selectNext}
              selectPrevious={selectPrevious}
              shouldButtonBeDisabled={shouldButtonBeDisabled}
            />
          }
          productCode={productCode}
          isEdited={isEdited}
        />
      )}
    </Box>
  );
};
