import { Button, SxProps } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

type StyledButtonProps = {
  type: string;
  onClick?: () => void;
  disabled?: boolean;
  borderColor?: string;
  textColor?: string;
  sx?: SxProps;
  width?: string;
  startIcon?: ReactNode;
};

export const StyledButton: FC<PropsWithChildren<StyledButtonProps>> = ({
  type,
  children,
  onClick,
  disabled,
  borderColor,
  textColor,
  sx = {},
  width = '105px',
  startIcon,
}) => {
  if (type === 'contained') {
    return (
      <Button
        disabled={disabled}
        type="submit"
        variant="contained"
        onClick={onClick}
        sx={{
          borderColor: '#0A40CA',
          backgroundColor: '#0A40CA',
          borderRadius: '4px',
          height: '40px',
          textTransform: 'none',
          width: width,
          fontWeight: 600,
          whiteSpace: 'nowrap',
          marginLeft: '8px',
          ...sx,
        }}
        startIcon={startIcon}
      >
        {children}
      </Button>
    );
  }
  return (
    <Button
      disabled={disabled}
      variant="outlined"
      onClick={onClick}
      sx={{
        borderColor: borderColor ? borderColor : '#0A40CA',
        color: textColor ? textColor : '#0A40CA',
        borderRadius: '4px',
        height: '40px',
        textTransform: 'none',
        fontWeight: 600,
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};
