import { Cached, Delete } from '@mui/icons-material';
import { Button, Divider, IconButton, Popper, Stack } from '@mui/material';
import { FC, MouseEvent } from 'react';
import { theme } from 'theme';

type Props = {
  anchorEl: HTMLElement | null;
  handleSwapMode: (e: MouseEvent<HTMLButtonElement>) => void;
  handleDeleteBucket: (e: MouseEvent<HTMLElement>) => void;
  open: boolean;
};

export const BucketBayPartMenu: FC<Props> = ({
  anchorEl,
  handleDeleteBucket,
  handleSwapMode,
  open,
}) => {
  return (
    <Popper placement="bottom-start" anchorEl={anchorEl} open={open}>
      <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
        sx={{
          background: 'white',
          p: 0.5,
          boxShadow: '0px 4px 8px 0px #00000026',
          border: '1px solid #DDDDDD',
          borderRadius: '4px',
        }}
      >
        <Button
          onClick={handleSwapMode}
          startIcon={<Cached htmlColor={theme.palette.icons.primary} />}
          sx={{
            width: '150px',
            color: theme.palette.textBlack.primary,
            fontWeight: 700,
          }}
        >
          什器を変更
        </Button>
        <Divider flexItem orientation="vertical" sx={{ margin: '4px' }} />
        <IconButton
          onClick={handleDeleteBucket}
          sx={{
            background: theme.palette.white.primary,
            boxShadow: 'none',
            '&:hover': {
              background: theme.palette.white.primary,
            },
          }}
        >
          <Delete htmlColor={theme.palette.icons.primary} />
        </IconButton>
      </Stack>
    </Popper>
  );
};
