import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu } from '@mui/material';
import { FC, PropsWithChildren, useRef } from 'react';

type Props = {
  menuOpen: boolean;
  handleMenuOpen: () => void;
  handleMenuClose: () => void;
};

export const MenuButton: FC<PropsWithChildren<Props>> = ({
  menuOpen,
  handleMenuOpen,
  handleMenuClose,
  children,
}) => {
  const element = useRef();
  return (
    <>
      <Box component="div" ref={element} mx={1}>
        <IconButton
          onClick={() => {
            handleMenuOpen();
          }}
        >
          <MoreVert />
        </IconButton>
      </Box>
      <Menu
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={element.current}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {children}
      </Menu>
    </>
  );
};
