import { Star, StarBorderOutlined } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FC } from 'react';

type FolderMenuProps = {
  isStarred: boolean;
  handleFavoriteClick: () => void;
};
export const FolderMenu: FC<FolderMenuProps> = ({
  isStarred,
  handleFavoriteClick,
}) => (
  <MenuItem onClick={handleFavoriteClick}>
    <ListItemIcon>{isStarred ? <Star /> : <StarBorderOutlined />}</ListItemIcon>
    <ListItemText>{isStarred ? 'スターを外す' : 'スターをつける'}</ListItemText>
  </MenuItem>
);
