import { createSelector } from '@reduxjs/toolkit';
import { selectAppState } from '@store/index';

export const selectPlanogramEditorState = createSelector(
  selectAppState,
  (state) => state.planogramEditor
);
export const selectRecentProducts = createSelector(
  selectAppState,
  (state) => state.planogramEditor.recentProducts
);
