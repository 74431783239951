import { FC } from 'react';
import { ChangeStep, RealogramType } from 'types/realogram';
import { HowToScanImage } from './howToScanImage';
import { StepLayout } from '../stepLayout';

type Props = {
  changeStep: ChangeStep;
  selectedRealogramType: RealogramType;
  onFirstFileSelect: (file: File) => void;
  isFlat: boolean;
};

export const StepTwo: FC<Props> = ({
  changeStep,
  selectedRealogramType,
  onFirstFileSelect,
  isFlat,
}) => {
  return (
    <StepLayout
      changeStep={changeStep}
      selectedRealogramType={selectedRealogramType}
      currentStep="two"
      onFirstFileSelect={onFirstFileSelect}
      isFlat={isFlat}
      title="新規スキャン 撮影方法"
    >
      <HowToScanImage isFlat={isFlat} />
    </StepLayout>
  );
};
