import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const GoParentDirectoryButtonIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" fill="none">
      <path
        d="M12.9168 8.75L11.7335 9.93333L8.75016 6.94167V14.5833H17.0835V16.25H7.0835V6.94167L4.10016 9.93333L2.91683 8.75L7.91683 3.75L12.9168 8.75Z"
        fill="black"
        fillOpacity="0.65"
      />
    </SvgIcon>
  );
};
