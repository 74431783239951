import { useGetPlanogramPermission } from '@hooks/useGetPlanogramPermission';
import { Comment, ContentCopy, Delete } from '@mui/icons-material';
import {
  MenuList as List,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { FC } from 'react';
import { Planogram } from 'types/planogram';

type Props = {
  planogram?: Planogram;
  handleClickMemoButton: () => void;
  handleClickDeleteButton: () => void;
  handleClickCloneButton: () => void;
};

export const MenuList: FC<Props> = ({
  planogram,
  handleClickMemoButton,
  handleClickDeleteButton,
  handleClickCloneButton,
}) => {
  const { isNotEnable: isNotCanDelete } = useGetPlanogramPermission({
    action: 'delete',
    planogram: planogram,
    isPlanogram: true,
    isCan: false,
  });
  const { isNotEnable: isNotCanCopy } = useGetPlanogramPermission({
    action: 'copy',
    planogram: planogram,
    isPlanogram: true,
    isCan: false,
  });
  return (
    <List>
      <MenuItem onClick={handleClickMemoButton} disabled>
        <ListItemIcon>
          <Comment />
        </ListItemIcon>
        <ListItemText>メモ</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={!planogram || isNotCanDelete}
        onClick={handleClickDeleteButton}
      >
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <ListItemText>削除</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={!planogram || isNotCanCopy}
        onClick={handleClickCloneButton}
      >
        <ListItemIcon>
          <ContentCopy />
        </ListItemIcon>
        <ListItemText>複製</ListItemText>
      </MenuItem>
    </List>
  );
};
