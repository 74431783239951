import { GetUsersQueryParams } from '@reducers/shelfAppsApi';
import { FC } from 'react';
import { User, UserOrder } from 'types/user';
import { TableCells } from './tableCells';
import { UsersTableHeader, columns } from './usersTableHeader';
import { DataTable } from './table';
import { TableRowWrapper } from './tableRowWrapper';

type UsersTableProps = {
  users?: User[];
  isEmpty: boolean;
  isDisplayLoadingSkeleton: boolean;
  condition: GetUsersQueryParams;
  handleClickRow: (user: User) => void;
  handleEndReached: (isAtBottom: boolean) => void;
  handleChangeOrder: (order: UserOrder) => void;
};

export const UsersTable: FC<UsersTableProps> = ({
  users,
  isEmpty,
  condition,
  isDisplayLoadingSkeleton,
  handleClickRow,
  handleEndReached,
  handleChangeOrder,
}) => {
  return (
    <DataTable<User>
      data={users}
      columns={columns}
      tableHeaderContent={
        <UsersTableHeader
          condition={condition}
          handleChangeOrder={handleChangeOrder}
        />
      }
      isDisplayLoadingSkeleton={isDisplayLoadingSkeleton}
      isEmpty={isEmpty}
      itemContent={(_, item) => {
        return <TableCells user={item} />;
      }}
      handleEndReached={handleEndReached}
      renderTableRow={(props) => {
        return (
          <TableRowWrapper
            // eslint-disable-next-line react/prop-types -- prop types is outdated
            handleRowClick={() => handleClickRow(props.item)}
            {...props}
          />
        );
      }}
    />
  );
};
