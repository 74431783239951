import { Button, styled } from '@mui/material';

export const SidebarItemButton = styled(Button)(() => ({
  width: '100%',
  height: '40px',
  padding: '0px 8px',
  justifyContent: 'initial',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
  [`& .MuiButton-startIcon`]: {
    marginRight: 0,
    marginLeft: 0,
  },
}));
