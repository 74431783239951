import { FC } from 'react';
import { Chip } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { theme } from '../../../theme';

export const ScoreMiddleLabel: FC = () => (
  <Chip
    size="small"
    label="確信度 : 中"
    sx={{
      fontWeight: 'bold',
      color: theme.palette.white.primary,
      backgroundColor: CommonColors.yellow,
    }}
  />
);
