import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import httpStatus from 'http-status';
import { mfaMessage } from './const';

// 二要素認証時のエラー文
export const toInitializeMfaErrorMessage = (
  error: FetchBaseQueryError | SerializedError,
  isMfaRegistered: boolean
) => {
  switch ('status' in error && error.status) {
    case httpStatus.UNAUTHORIZED:
      if (isMfaRegistered) {
        return mfaMessage.alreadyRegistered;
      }
      break;
    // Todo: defaultのエラーメッセージ文を検討する。
    default:
      return mfaMessage.error;
  }
};
