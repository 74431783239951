import { reset as resetProduct } from '@reducers/productCategory';
import { useAppDispatch } from '@store/index';
import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

export const Products: FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetProduct());
    };
  }, [dispatch]);

  return <Outlet />;
};
