import { userQueryKey } from '@api/hooks/useUsers';
import { queryClient } from '@api/query-client';
import { userApi } from '@api/services/users';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { openToast } from '@reducers/toast';
import { useAppDispatch } from '@store/index';
import { useMutation } from '@tanstack/react-query';
import { toastMessages } from '@utils/const';
import { useEffect, useState } from 'react';
import { User } from 'types/user';

export const userDeleteQueryKey = 'user_delete';
export const useDeleteMfa = (selectedUser?: User) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [isMfaActive, setIsMfaActive] = useState(false);
  const { mutateAsync: getMfaData } = useMutation({
    mutationFn: userApi.getAdminUsersIdMfaDevice,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [userQueryKey],
      });
    },
  });

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  const { mutateAsync: deleteMfaDevice } = useMutation({
    mutationFn: userApi.deleteMfaDevice,
    onError: () => {
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.deleteMfaByAdmin,
        })
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [userDeleteQueryKey],
      });
    },
  });

  const fetchMfa = async () => {
    try {
      const mfaData = await getMfaData(selectedUser?.id ?? 0);
      setIsMfaActive(!!mfaData?.preferred_mfa_setting);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id: number) => {
    dispatch(updateLoadingIndicatorState(true));
    try {
      await deleteMfaDevice(id);
      dispatch(
        openToast({
          message: toastMessages.success.deleteMfaByAdmin,
          type: 'success',
        })
      );
      closeDialog();
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(updateLoadingIndicatorState(false));
      fetchMfa();
    }
  };

  useEffect(() => {
    if (!selectedUser) return;
    fetchMfa();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- fetchMfaを追加するとユーザーの数分処理が走ってしまう
  }, [selectedUser]);

  return {
    isDialogOpen,
    openDialog,
    closeDialog,
    handleDelete,
    isMfaActive,
  };
};
