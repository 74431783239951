import { useAppDispatch, useAppSelector } from '@store/index';
import { useCallback, useEffect } from 'react';
import { RealogramShelfBoard } from 'types/realogram';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { useRerenderingDetails } from './rerenderingComponents/useRerenderingDetails';
import { BucketPlanogramPlan, PlanogramPlan } from 'types/planogram';
import { productsApi } from '@reducers/shelfAppsApi';
import {
  setSelectedBucketId,
  updateIsShowProductDetail,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import {
  getBucketCompartmentsByView,
  getBucketPosition,
} from '@components/pages/planogramDetail/flatPlanogramDetail/utils/index';
import { Product, ProductTag, ShelfDetailView } from 'types/common';
import {
  getBucketsProductIds,
  getProductsLayout,
  isPlanogramBucketPlan,
} from '@utils/planogram';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { updateBucketProductPosition } from '@reducers/flatPlan';

type Props = {
  shelfBoards?: RealogramShelfBoard[];
  planogramPlan?: PlanogramPlan | BucketPlanogramPlan;
  comparedPlanogramPlan?: PlanogramPlan | BucketPlanogramPlan;
  isSkipUpdateBucket?: boolean;
  initSelectedData: {
    isSkipInit?: boolean;
    view?: ShelfDetailView;
    productTag?: ProductTag;
    isEditMode?: boolean;
    onInitCompleted: (value: boolean) => void;
  };
};

export const useInitSelectedPlanogram = ({
  shelfBoards,
  planogramPlan,
  comparedPlanogramPlan,
  initSelectedData: {
    isSkipInit,
    view,
    productTag,
    isEditMode,
    onInitCompleted,
  },
}: Props) => {
  const dispatch = useAppDispatch();

  const { selectedProductCompartment } = useAppSelector(
    selectPlanogramEditorState
  );

  const {
    searchParams,
    operate: { getSelectedItemOfRealogram, getSelectedItemOfPlanogram },
  } = useRerenderingDetails();

  const getProductDetail = useCallback(
    async (productId: number) => {
      const { product } = await dispatch(
        productsApi.endpoints.getProduct.initiate({
          productId,
        })
      ).unwrap();
      return product;
    },
    [dispatch]
  );

  const initSelectedRealogram = useCallback(async () => {
    const { isEmpty, product } = getSelectedItemOfRealogram(shelfBoards);
    if (!isEmpty) {
      dispatch(
        updateSelectedRealogramItem({
          shelfBoardId: product.shelfBoardId,
          compartmentId: product.compartmentId,
          item: product.item,
        })
      );

      if (isEditMode && product.item?.primary_candidate?.product_id) {
        const productCompartment = await getProductDetail(
          product.item?.primary_candidate?.product_id
        );
        if (productCompartment) {
          dispatch(updateSelectedProductCompartment(productCompartment));
        }
      }
      dispatch(updateIsShowProductDetail(true));
    } else {
      dispatch(updateSelectedRealogramItem(undefined));
    }
  }, [
    dispatch,
    getProductDetail,
    getSelectedItemOfRealogram,
    isEditMode,
    shelfBoards,
  ]);

  const initSelectedShelfPlanogram = useCallback(
    async (itemId: number, planogramPlan: PlanogramPlan) => {
      const productsLayout = planogramPlan.products_layout || [];
      const selectedProduct = productsLayout.some((item) =>
        (item.row || []).some((rowItem) => rowItem.product_id === itemId)
      );
      if (selectedProduct) {
        const product = await getProductDetail(itemId);
        const { productsSelected } = getSelectedItemOfPlanogram(
          [product],
          productsLayout
        );
        if (productsSelected?.length) {
          dispatch(updateSelectedProductCompartment(productsSelected.at(0)));
          dispatch(updateIsShowProductDetail(true));
        }
        return;
      }
      dispatch(updateSelectedProductCompartment(undefined));
    },
    [dispatch, getProductDetail, getSelectedItemOfPlanogram]
  );

  const checkProductInFlatPlanogramPlan = (
    itemId: number,
    planogramPlan: BucketPlanogramPlan
  ) => {
    const buckets = planogramPlan.frame?.detail?.buckets || [];
    const productIds = buckets.flatMap((bucket) =>
      getBucketsProductIds(bucket.detail.area)
    );
    return productIds.includes(itemId);
  };

  const updateBucketOfFlatPlanogram = useCallback(
    (data: {
      view: ShelfDetailView;
      productTag: ProductTag;
      product: Product;
      planogramPlan: BucketPlanogramPlan;
    }) => {
      const positions = getBucketCompartmentsByView(
        data.view,
        data.productTag,
        data.planogramPlan,
        [data.product]
      );
      const bucketPosition = getBucketPosition([data.product], positions);
      if (bucketPosition) {
        dispatch(setSelectedBucketId(bucketPosition.bucketId));
        updateBucketProductPosition(bucketPosition.position);
      }
    },
    [dispatch]
  );

  const initSelectedFlatPlanogram = useCallback(
    async (itemId: number, planogramPlan: BucketPlanogramPlan) => {
      if (!view || !productTag) return;
      const hasProduct = checkProductInFlatPlanogramPlan(itemId, planogramPlan);
      if (hasProduct) {
        const product = await getProductDetail(itemId);
        const { productsSelected } = getSelectedItemOfPlanogram(
          [product],
          getProductsLayout(planogramPlan)
        );
        if (productsSelected?.length) {
          dispatch(updateSelectedProductCompartment(productsSelected.at(0)));
          dispatch(updateIsShowProductDetail(true));

          updateBucketOfFlatPlanogram({
            view,
            productTag,
            product,
            planogramPlan,
          });
        }
        return;
      }
      dispatch(updateSelectedProductCompartment(undefined));
      dispatch(setSelectedBucketId(undefined));
      dispatch(updateBucketProductPosition(undefined));
    },
    [
      dispatch,
      getProductDetail,
      getSelectedItemOfPlanogram,
      productTag,
      updateBucketOfFlatPlanogram,
      view,
    ]
  );

  const initSelectedPlanogram = useCallback(async () => {
    const itemId = searchParams.get('item');

    if (!itemId || !planogramPlan) return;

    const init = async (plan: PlanogramPlan | BucketPlanogramPlan) => {
      if (isPlanogramBucketPlan(plan)) {
        await initSelectedFlatPlanogram(+itemId, plan);
      } else {
        await initSelectedShelfPlanogram(+itemId, plan);
      }
    };
    const promises = [init(planogramPlan)];
    if (comparedPlanogramPlan) {
      promises.push(init(comparedPlanogramPlan));
    }
    await Promise.all(promises);
  }, [
    searchParams,
    planogramPlan,
    comparedPlanogramPlan,
    initSelectedShelfPlanogram,
    initSelectedFlatPlanogram,
  ]);

  // 初回描画：選択中のアイテム情報
  useEffect(() => {
    const initSelectedItem = async () => {
      try {
        if (isSkipInit) return;
        await Promise.all([initSelectedPlanogram(), initSelectedRealogram()]);
        onInitCompleted(true);
      } catch (e) {
        console.error(e);
      }
    };
    void initSelectedItem();
  }, [
    isSkipInit,
    initSelectedPlanogram,
    onInitCompleted,
    initSelectedRealogram,
  ]);

  useEffect(() => {
    if (
      !planogramPlan ||
      isPlanogramBucketPlan(planogramPlan) ||
      !comparedPlanogramPlan ||
      !isPlanogramBucketPlan(comparedPlanogramPlan) ||
      !selectedProductCompartment?.id ||
      !view ||
      !productTag
    )
      return;

    const hasProduct = checkProductInFlatPlanogramPlan(
      selectedProductCompartment.id,
      comparedPlanogramPlan
    );
    if (hasProduct) {
      // update bucketId, bucket position in compared flat program if select shelf planogram
      updateBucketOfFlatPlanogram({
        view,
        productTag,
        product: selectedProductCompartment,
        planogramPlan: comparedPlanogramPlan,
      });
    } else {
      dispatch(setSelectedBucketId(undefined));
      dispatch(updateBucketProductPosition(undefined));
    }
  }, [
    comparedPlanogramPlan,
    dispatch,
    planogramPlan,
    productTag,
    selectedProductCompartment,
    updateBucketOfFlatPlanogram,
    view,
  ]);

  return {
    initSelectedFlatPlanogram,
  };
};
