import { GetListApiV1UsersGetParams } from '@api/types/generated';
import { userApi } from '@api/services/users';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getNextPage, mapper } from '@api/utils/pagenation';

export const userQueryKey = 'user';

type Params = GetListApiV1UsersGetParams;

export const useUsers = ({ ...params }: Params) => {
  return useInfiniteQuery({
    queryKey: [userQueryKey, params],
    queryFn: ({ pageParam }) => {
      return userApi.getUsers({
        ...params,
        offset: pageParam,
        role: params.role,
        // falseだとうまく動作しなかったがundefinedで動作した
        deleted_user: params.deleted_user ? true : undefined,
        scope: params.scope,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => getNextPage(lastPage.pager),
    select: (data) => {
      return {
        users: mapper(data?.pages, 'users'),
        total: data?.pages?.at(0)?.pager.total,
      };
    },
    refetchOnWindowFocus: false,
  });
};
