import { FC } from 'react';
import { Button, DialogContentText } from '@mui/material';
import { AlertDialog } from '@components/organisms/alertDialog/alertDialog';
import { CommonColors } from '@utils/colors';

type Props = {
  open: boolean;
  disabledConfirm: boolean;
  handleClose: () => void;
  handleDeleteMfa: () => void;
};

export const DeleteMfaDialog: FC<Props> = ({
  open,
  disabledConfirm,
  handleClose,
  handleDeleteMfa,
}) => {
  return (
    <AlertDialog
      open={open}
      title="登録中の二要素認証デバイスを削除します。"
      content={
        <DialogContentText sx={{ color: CommonColors.black }}>
          二要素認証を再度利用するためには、もう一度デバイスの登録が必要になります。問題ない場合のみ削除を行ってください。
        </DialogContentText>
      }
      cancelButton={
        <Button onClick={handleClose} color="inherit">
          キャンセル
        </Button>
      }
      confirmButton={
        <Button
          color="error"
          onClick={handleDeleteMfa}
          disabled={disabledConfirm}
        >
          削除
        </Button>
      }
    />
  );
};
