import { createSlice, original, PayloadAction } from '@reduxjs/toolkit';
import { createPlanogramBayPart } from '@utils/bayPart';
import {
  createCompartment,
  isPlanogramHookBarPart,
  isPlanogramPlan,
  isPlanogramPtsDetail,
  isPlanogramPtsShelfBoard,
  isPlanogramShelfBoardPart,
  isPlanogramShelvesDetail,
  isSameElevation,
  shelfElevationToStepElevation,
  tidyCompartments,
  indicatorsUsedShelfSteps,
  newElevationShelfStepsRange,
  isWithinShelfStepsRange,
} from '@utils/planogram';
import { isEqual } from 'lodash';
import { BayPlanBayPart } from 'types/bayPlan';
import { Product } from 'types/common';
import {
  BayPartPosition,
  JustifyContent,
  Planogram,
  PlanogramBayPart,
  PlanogramPlan,
  PlanogramShelvesV2Compartment,
  Position,
  ProductPosition,
  RotationValues,
} from 'types/planogram';

type UndoableState<T> = {
  past: T[];
  present: T;
  future: T[];
  // サーバーに保存されている履歴を指し示す。undefined: 履歴に存在しない。0: presentが保存されている。 1以上: cursor - 1の履歴が保存されている
  cursor?: number;
  bayPartPosition?: Position;
};

type PlanState = UndoableState<PlanogramPlan>;

const initialState: PlanState = {
  past: [],
  present: {
    bay_size: {
      width: 0,
      height: 0,
      depth: 0,
    },
    format: 'shelves_v1',
    products_layout: [],
    shelves: [],
    shelves_frame: {
      detail: {
        shelf_steps: [],
        exterior: {
          width: 0,
          height: 0,
          depth: 0,
          ceiling: {
            closed: false,
            elevation: 0,
          },
          padding: {
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            front: 0,
            behind: 0,
          },
        },
      },
      bay_part_id: 0,
      name: '',
      type: '',
      width: 0,
      height: 0,
      depth: 0,
    },
  },
  future: [],
};

const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    undo: (state: PlanState) => {
      const previous = state.past.pop();
      const current = original(state.present);
      if (!previous || !current) return;

      state.future.unshift(current);
      state.present = previous;
    },
    redo: (state: PlanState) => {
      const next = state.future.shift();
      const current = original(state.present);
      if (!current || !next) return;

      state.present = next;
      state.past.push(current);
    },
    mark: (state: PlanState) => {
      state.cursor = state.past.length;
    },
    setPlan: (
      state: PlanState,
      action: PayloadAction<Planogram['plan'] | undefined>
    ) => {
      state.past = [];
      state.present = isPlanogramPlan(action.payload)
        ? action.payload
        : initialState.present;
      state.future = [];
      state.cursor = 0;
    },
    addProduct: (
      state: PlanState,
      action: PayloadAction<{ to: ProductPosition; product: Product }>
    ) => {
      const plan = state.present;
      const { to, product } = action.payload;
      const productsLayout = plan.products_layout;

      // 配置先の什器パーツの存在確認
      if (!productsLayout[to.indexY]) {
        // 存在しない什器パーツなので状態を変えない
        // Todo: 開発者に警告を出す
        return;
      }

      productsLayout[to.indexY].row = tidyCompartments([
        ...productsLayout[to.indexY].row.slice(0, to.subPosition.indexX),
        createCompartment(product),
        ...productsLayout[to.indexY].row.slice(to.subPosition.indexX),
      ]);

      makeHistory(state);
    },
    moveProducts: (
      state: PlanState,
      action: PayloadAction<{ from: ProductPosition; to: ProductPosition }>
    ) => {
      const plan = state.present;
      const { from, to } = action.payload;

      if (isEqual(from, to)) {
        // 同じ場所なので移動がない
        return;
      }

      const productsLayout = plan.products_layout;

      // 移動先の什器パーツの存在確認
      if (!productsLayout[to.indexY]) {
        // 存在しない什器パーツなので状態を変えない
        // Todo: 開発者に警告を出す
        return;
      }

      const fromProduct = productsLayout
        .at(from.indexY)
        ?.row.at(from.subPosition.indexX);
      if (!fromProduct) {
        // 存在しない区画を選択したので状態を変えない
        // Todo: 開発者に警告を出す
        return;
      }

      // todo: removeProductと同じ処理なので共通化
      productsLayout[from.indexY].row.splice(from.subPosition.indexX, 1);
      productsLayout[from.indexY].row = tidyCompartments(
        productsLayout[from.indexY].row
      );

      // todo: addProductと同じ処理なので共通化
      productsLayout[to.indexY].row = tidyCompartments([
        ...productsLayout[to.indexY].row.slice(0, to.subPosition.indexX),
        fromProduct,
        ...productsLayout[to.indexY].row.slice(to.subPosition.indexX),
      ]);

      makeHistory(state);
    },
    removeProducts: (
      state: PlanState,
      action: PayloadAction<{ at: ProductPosition }>
    ) => {
      const plan = state.present;
      const { at } = action.payload;
      const productsLayout = plan.products_layout;
      //Delete product you are moving

      const target = productsLayout
        .at(at.indexY)
        ?.row.at(at.subPosition.indexX);
      if (!target) {
        // 存在しない区画を選択したので状態を変えない
        // Todo: 開発者に警告を出す
        return;
      }

      state.past.push(state.present);

      productsLayout[at.indexY].row.splice(at.subPosition.indexX, 1);
      productsLayout[at.indexY].row = tidyCompartments(
        productsLayout[at.indexY].row
      );

      makeHistory(state);
    },
    rotateProducts: (
      state: PlanState,
      action: PayloadAction<{ at: ProductPosition; values: RotationValues }>
    ) => {
      const plan = state.present;
      const at = action.payload.at;
      const [faceFrontId, faceOrientationId] = action.payload.values;
      const productsLayout = plan.products_layout;

      const target = productsLayout
        .at(at.indexY)
        ?.row.at(at.subPosition.indexX);
      if (!target) {
        // 存在しない区画を選択したので状態を変えない
        // Todo: 開発者に警告を出す
        return;
      }
      target.face_front = faceFrontId;
      target.orientation = faceOrientationId;

      makeHistory(state);
    },
    changeProductsAmount: (
      state: PlanState,
      action: PayloadAction<{
        at: ProductPosition;
        amount: { x: number; y: number };
      }>
    ) => {
      const plan = state.present;
      const compartment = plan.products_layout
        .at(action.payload.at.indexY)
        ?.row.at(action.payload.at.subPosition.indexX);

      if (!compartment?.count) {
        // TODO: `count` が存在しないケースの対応
        return;
      }
      const count = {
        x: compartment.count.x + action.payload.amount.x,
        y: compartment.count.y + action.payload.amount.y,
      };
      plan.products_layout[action.payload.at.indexY].row[
        action.payload.at.subPosition.indexX
      ].count = count;
      plan.products_layout[action.payload.at.indexY].row[
        action.payload.at.subPosition.indexX
      ].face_count = count.x * count.y;

      makeHistory(state);
    },
    addBayPart: (state: PlanState, action: PayloadAction<PlanogramBayPart>) => {
      const plan = state.present;
      const bayPart = action.payload;

      const target = plan.shelves.findIndex(
        // 誤差を回避するため固定小数点で比較する
        (shelf) => isSameElevation(shelf.elevation, bayPart.elevation)
      );
      if (target !== -1) {
        // すでに什器パーツが存在するので配置できない
        // todo: 位置調整？
        return;
      }

      // todo: 組み合わせが間違っている時のエラー対応
      if (
        isPlanogramShelvesDetail(plan) &&
        (isPlanogramShelfBoardPart(bayPart) || isPlanogramHookBarPart(bayPart))
      ) {
        plan.shelves = [...plan.shelves, bayPart];
      }

      // elevation で並び替え
      plan.shelves.sort((a, b) => a.elevation - b.elevation);
      const index = plan.shelves.findIndex((shelf) =>
        isSameElevation(shelf.elevation, bayPart.elevation)
      );
      if (index === -1) {
        throw new Error('');
      }

      plan.products_layout = [
        ...plan.products_layout.slice(0, index),
        { row: [] },
        ...plan.products_layout.slice(index),
      ];

      makeHistory(state);
    },
    moveBayPart: (
      state: PlanState,
      action: PayloadAction<{ from: Position; to: BayPartPosition }>
    ) => {
      const plan = state.present;
      if (isPlanogramPtsDetail(plan)) {
        // todo: PTSフォーマットの対応
        return;
      }
      const { from, to } = action.payload;

      const target = plan.shelves.at(from.indexY);
      if (!target) {
        // 存在しない什器パーツなので変更なし
        return;
      }
      const targetShelfToStepElevation =
        shelfElevationToStepElevation(target) ?? 0;
      if (isSameElevation(targetShelfToStepElevation, to.elevation)) {
        // 同じ位置なので状態の変更なし
        return;
      }
      const newElevation = plan.shelves_frame.detail.shelf_steps
        .map(({ elevation }) => ({
          elevation,
          diff: Math.abs(elevation - to.elevation),
        }))
        .sort((a, b) => a.diff - b.diff)[0].elevation;
      if (
        !newElevation ||
        plan.shelves.find(({ elevation }) =>
          isSameElevation(elevation, newElevation)
        )
      ) {
        // 配置できない場所なので状態の変更なし
        return;
      }

      const steps = plan.shelves_frame.detail.shelf_steps.map(
        ({ elevation }) => elevation
      );

      const usedShelfSteps = indicatorsUsedShelfSteps(plan, from.indexY, steps);

      const newElevationSteps = newElevationShelfStepsRange(
        target,
        newElevation,
        steps
      );
      const isWithinRange = isWithinShelfStepsRange(
        newElevationSteps,
        usedShelfSteps
      );

      if (isWithinRange) {
        // 重複している
        // 配置できない場所なので状態の変更なし
        return;
      }

      target.elevation = newElevation;
      plan.shelves.sort((a, b) => a.elevation - b.elevation);

      // 移動後の位置を特定
      const index = plan.shelves.findIndex(({ elevation }) =>
        isSameElevation(elevation, newElevation)
      );
      if (index === -1) {
        throw new Error('');
      }
      const compartment = plan.products_layout.splice(from.indexY, 1);
      plan.products_layout = [
        ...plan.products_layout.slice(0, index),
        ...compartment,
        ...plan.products_layout.slice(index),
      ];
      state.bayPartPosition = { indexX: 0, indexY: index };
      makeHistory(state);
    },
    removeBayPart: (state: PlanState, action: PayloadAction<Position>) => {
      const plan = state.present;
      const at = action.payload;

      const target = plan.shelves.at(at.indexY);
      if (!target) {
        // 存在しない什器パーツなので変更なし
        return;
      }

      plan.shelves.splice(at.indexY, 1);
      plan.products_layout.splice(at.indexY, 1);

      makeHistory(state);
    },
    replaceBayPart: (
      state: PlanState,
      action: PayloadAction<{ at: Position; bayPart: BayPlanBayPart }>
    ) => {
      const plan = state.present;
      const { at, bayPart } = action.payload;

      const target = plan.shelves.at(at.indexY);
      if (!target) {
        // 存在しない什器パーツなので変更なし
        return;
      }

      const planogramBayPart = createPlanogramBayPart(
        bayPart,
        target.elevation
      );
      // todo: 組み合わせが間違っている時のエラー対応
      if (
        isPlanogramPtsDetail(plan) &&
        isPlanogramPtsShelfBoard(planogramBayPart)
      ) {
        return;
      }

      if (
        isPlanogramShelvesDetail(plan) &&
        (isPlanogramShelfBoardPart(planogramBayPart) ||
          isPlanogramHookBarPart(planogramBayPart))
      ) {
        plan.shelves.splice(at.indexY, 1, planogramBayPart);
      }

      makeHistory(state);
    },
    updateCompartmentAttributes: (
      state: PlanState,
      action: PayloadAction<{
        at: Position;
        attributes: { justifyContent: JustifyContent };
      }>
    ) => {
      const plan = state.present;
      const { at, attributes } = action.payload;

      // todo: 動作確認でキャストする
      const target = plan.products_layout.at(
        at.indexY
      ) as PlanogramShelvesV2Compartment;
      // if (!isPlanogramShelvesV2Compartment(target)) {
      //   return;
      // }
      target.justify_content = attributes.justifyContent;

      makeHistory(state);
    },
    selectBayPartPosition: (
      state: PlanState,
      action: PayloadAction<Position | undefined>
    ) => {
      state.bayPartPosition = action.payload;
    },
  },
});

const makeHistory = (state: PlanState) => {
  // 履歴の保存
  const plan = original(state.present);
  if (plan) {
    if ((state.cursor || 0) > state.past.length) {
      state.cursor = undefined;
    }
    state.past.push(plan);
    state.future = [];
  }
};

export const {
  setPlan,
  addProduct,
  moveProducts,
  removeProducts,
  changeProductsAmount,
  rotateProducts,
  addBayPart,
  moveBayPart,
  removeBayPart,
  undo,
  redo,
  mark,
  replaceBayPart,
  updateCompartmentAttributes,
  selectBayPartPosition,
} = planSlice.actions;

export const planReducer = planSlice.reducer;
