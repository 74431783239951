import { Button, ButtonProps } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

export const CancelButton: FC<ButtonProps> = (props) => {
  return (
    <Button
      {...props}
      sx={{
        fontWeight: 700,
        fontSize: '14px',
        color: theme.palette.textBlack.primary,
        width: 102,
      }}
    >
      キャンセル
    </Button>
  );
};
