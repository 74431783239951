import { Alert } from '@mui/material';
import { FC } from 'react';

type Props = {
  message: string;
};

export const ErrorMessage: FC<Props> = ({ message }) => {
  return (
    <Alert severity="error" sx={{ alignItems: 'center' }}>
      {message}
    </Alert>
  );
};
