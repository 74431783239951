import { ModalInnerProps } from '@components/modal';
import { RequiredText } from '@components/molecules/requiredText/requiredText';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBayPlanCodes } from '@hooks/useBayPlanCodes';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useListBayPlansQuery } from '@reducers/shelfAppsApi';
import { bayPlansLimit, isOnlySpaces } from '@utils/const';
import { FC } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { InferType, number, object, string } from 'yup';
import { ModalActions } from '../modalActions/modalActions';

const schema = object({
  name: string()
    .required('ゴンドラ名を入力してください。')
    .matches(
      /^(?![\s\u3000]*$).*/,
      '半角スペースまたは全角スペースのみで登録することはできません。'
    ),
  bayPlanId: number().required('ゴンドラテンプレートを選択してください。'),
  bayPlanCodeId: number().transform((_, originalValue: number) => {
    if (typeof originalValue === 'number' && originalValue >= 0) {
      return originalValue;
    }
    return undefined;
  }),
});

type FormData = InferType<typeof schema>;

export type CreatePlanogramModalProps = {
  name?: string;
  bayPlanId?: number;
  bayPlanCodeId?: number;
  onSubmit: (data: FormData) => void;
};
type Props = ModalInnerProps<CreatePlanogramModalProps>;

export const CreatePlanogramForm: FC<Props> = ({
  handleClose,
  data: { name: defaultName, bayPlanId: defaultBayPlanId, onSubmit },
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: defaultName,
      bayPlanId: defaultBayPlanId,
    },
  });
  const { name, bayPlanId } = useWatch({ control });
  const { data: bayPlans } = useListBayPlansQuery({
    offset: 0,
    limit: bayPlansLimit,
  });
  const { bayPlanCodes } = useBayPlanCodes();

  return (
    <Box
      component="form"
      width={400}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Stack direction="column" gap={2}>
        <RequiredText />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="ゴンドラ名"
              required
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          )}
        />
        <Controller
          name="bayPlanId"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth error={!!errors.bayPlanId} required>
              <InputLabel>ゴンドラテンプレート</InputLabel>
              <Select label="ゴンドラテンプレート" {...field}>
                {bayPlans?.bay_plans?.map((bayPlan) => {
                  return (
                    <MenuItem key={bayPlan.id} value={bayPlan.id}>
                      {bayPlan.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {!!errors.bayPlanId && (
                <FormHelperText>{errors.bayPlanId.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
        <Controller
          name="bayPlanCodeId"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth error={!!errors.bayPlanCodeId}>
              <InputLabel>ゴンドラ番号</InputLabel>
              <Select label="ゴンドラ番号" {...field}>
                <MenuItem value="未選択">未選択</MenuItem>
                {bayPlanCodes?.map((bayPlanCode) => (
                  <MenuItem key={bayPlanCode.id} value={bayPlanCode.id}>
                    {bayPlanCode.name}
                  </MenuItem>
                ))}
              </Select>
              {!!errors.bayPlanCodeId && (
                <FormHelperText>{errors.bayPlanCodeId.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Stack>
      <ModalActions
        handleClose={handleClose}
        actionBtnTitle="作成"
        disabled={!name || !bayPlanId || isOnlySpaces(name)}
      />
    </Box>
  );
};
