import { AppBar, AppBarProps, SxProps, Toolbar } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { theme } from 'theme';

export const Header: FC<
  PropsWithChildren<{ position?: AppBarProps['position'] }> & {
    sx?: SxProps;
  }
> = ({ position = 'static', children, sx }) => {
  return (
    <AppBar
      position={position}
      sx={{
        borderBottom: `1px solid ${theme.palette.backgroundBlack.light}`,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
        p: 0, //NOTE: cleanTheme設定によってappBarの上に要素が追加されてしまうため削除
        ':after': { display: 'none' },
      }}
    >
      <Toolbar sx={{ height: headerHeight, ...sx }}>{children}</Toolbar>
    </AppBar>
  );
};

export const headerHeight = 64;
