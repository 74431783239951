import { BreadcrumbForSearch } from '@components/molecules/breadcrumbForSearch/breadcrumbForSearch';
import { BreadcrumbList } from '@components/molecules/breadcrumbList/breadcrumbList';
import { EmptyResult } from '@components/molecules/emptyResult/emptyResult';
import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { ChevronRight, Folder } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { FC, Fragment } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { theme } from 'theme';
import {
  DirectoryBreadcrumbs,
  DirectoryType,
  ParentDirectory,
} from 'types/common';
import { PlanogramDirectory, PlanogramDirectorySearch } from 'types/planogram';

type Props = {
  isEmptyDirectory: boolean;
  isCreateDirectory: boolean;
  isLoading: boolean;
  directories?: PlanogramDirectory[] | PlanogramDirectorySearch[];
  handleRowClick: (directory: PlanogramDirectory) => void;
  breadcrumbs?: DirectoryBreadcrumbs;
  parentDirectory?: ParentDirectory;
  handleClickBreadcrumbs: (directory: DirectoryType) => void;
  userId?: number;
  handleEndReached: () => void;
  isFetching: boolean;
  offset: number;
  targetDirectory: PlanogramDirectory;
  createDirectoryCount: number;
  isCreateDirectoryUnderRoot: boolean;
  textSearch: string;
  handleScrollerRef: (ref: HTMLElement | Window | null) => void;
  scrollPos: number;
};

export const List: FC<Props> = ({
  isEmptyDirectory,
  isCreateDirectory,
  isLoading,
  isFetching,
  directories,
  breadcrumbs,
  parentDirectory,
  userId,
  offset,
  targetDirectory,
  createDirectoryCount,
  handleRowClick,
  handleClickBreadcrumbs,
  handleEndReached,
  handleScrollerRef,
  isCreateDirectoryUnderRoot,
  textSearch,
  scrollPos,
}) => {
  const isUpdatingList = isLoading || (offset === 0 && isFetching);
  return (
    <>
      {isEmptyDirectory && !isUpdatingList && (
        <Stack
          color={theme.palette.textBlack.disabled}
          width="100%"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          {textSearch ? (
            <EmptyResult
              title={`「${textSearch}」が含まれる結果が見つかりませんでした`}
              message=""
              titleStyle={{
                textAlign: 'center',
                padding: '0 20px',
              }}
            />
          ) : (
            <EmptyResult title="フォルダがありません" message="" />
          )}
        </Stack>
      )}
      {isUpdatingList ? (
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="#BDBDBD"
          height="100%"
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <Box component="div" flex={1} overflow="auto">
          {directories?.length && (
            <Virtuoso
              initialScrollTop={scrollPos}
              scrollerRef={handleScrollerRef}
              endReached={handleEndReached}
              data={directories}
              itemContent={(index, directory) => {
                const isCurrentDirectory = targetDirectory.id === directory.id;
                const isFavorite = directory.favorite?.owner_id === userId;
                return (
                  <Fragment key={directory.id}>
                    <Divider />
                    <ListItemButton
                      key={index}
                      onClick={() => handleRowClick(directory)}
                      disabled={isCurrentDirectory}
                      sx={{ gap: 1, height: '56px', py: '4px', px: '16px' }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Folder sx={{ color: theme.palette.icons.secondary }} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          overflow: 'hidden',
                          flex: 'unset',
                          '& .MuiListItemText-primary': {
                            display: 'block',
                          },
                        }}
                        primary={
                          <Box component="div">
                            <Box
                              component="div"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  overflow: 'hidden',
                                }}
                              >
                                {directory.name}
                              </Typography>
                              {isFavorite && (
                                <StarIcon
                                  htmlColor={theme.palette.confidence.middle}
                                  sx={{
                                    marginLeft: '4px',
                                    width: '16px',
                                    height: '16px',
                                    verticalAlign: 'text-bottom',
                                    marginRight: 'auto',
                                  }}
                                />
                              )}
                            </Box>
                            {(directory as PlanogramDirectorySearch)
                              .breadcrumb && (
                              <BreadcrumbForSearch
                                breadcrumbs={
                                  (directory as PlanogramDirectorySearch)
                                    .breadcrumb
                                }
                                screenType="Modal"
                                fontSize="12px"
                              />
                            )}
                          </Box>
                        }
                        primaryTypographyProps={{
                          style: {
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          },
                        }}
                      />
                      {!isCurrentDirectory && (
                        <ChevronRight
                          htmlColor={theme.palette.icons.secondary}
                          sx={{ marginLeft: 'auto' }}
                        />
                      )}
                    </ListItemButton>
                  </Fragment>
                );
              }}
              components={{
                // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
                Footer: () => {
                  return (
                    <>
                      {isFetching && (
                        <Box
                          component="div"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          color="#BDBDBD"
                          borderTop={`1px solid ${theme.palette.dividerBlack.light}`}
                        >
                          <CircularProgress color="inherit" />
                        </Box>
                      )}
                    </>
                  );
                },
              }}
            />
          )}
        </Box>
      )}

      {/* BREADCRUMBS */}
      {breadcrumbs &&
        parentDirectory &&
        (breadcrumbs.length >= 1 || isCreateDirectoryUnderRoot) && (
          <Box
            component="div"
            padding="8px 16px"
            borderTop={`1px solid ${theme.palette.dividerBlack.medium}`}
          >
            <BreadcrumbList
              breadcrumbs={breadcrumbs}
              parentDirectory={parentDirectory}
              screenType="Modal"
              handleClickBreadcrumbs={handleClickBreadcrumbs}
              fontSize="12px"
              isCreateDirectory={isCreateDirectory}
              createDirectoryCount={createDirectoryCount}
              directoryRootName="棚割計画"
            />
          </Box>
        )}
    </>
  );
};
