import { ErrorMessage } from '@components/molecules/errorMessage/errorMessage';
import { FormPaper } from '@components/molecules/formPaper/formPaper';
import { RequiredText } from '@components/molecules/requiredText/requiredText';
import { PasswordValidationList } from '@components/organisms';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField } from '@mui/material';
import { isValidPassword } from '@utils/users';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { InferType, ref, object, string } from 'yup';

const schema = object({
  password: string().required('新しいパスワードを入力してください。'),
  rePassword: string()
    .required('	新しいパスワード（確認）を入力してください。')
    .oneOf(
      [ref('password')],
      'パスワードが一致していません。もう一度入力してください。'
    ),
});
type FormData = InferType<typeof schema>;

type Props = {
  errorMessage?: string;
  isLoading: boolean;
  onSubmit: (data: FormData) => void;
  handleNavigateLogin: () => void;
};

export const InitialSetting: FC<Props> = ({
  errorMessage,
  isLoading,
  onSubmit,
  handleNavigateLogin,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const [password, rePassword] = watch(['password', 'rePassword']);

  return (
    <FormPaper title="新しいパスワードを設定">
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        gap={2}
        mt={0.5}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {errorMessage && <ErrorMessage message={errorMessage} />}
        <RequiredText />
        <TextField
          {...register('password')}
          required
          fullWidth
          size="small"
          error={!!errors.password}
          helperText={errors.password?.message}
          label="新しいパスワード"
          autoComplete="new-password"
          type="password"
        />
        <PasswordValidationList value={password} />
        <TextField
          {...register('rePassword')}
          required
          fullWidth
          size="small"
          error={!!errors.rePassword}
          helperText={errors.rePassword?.message}
          label="新しいパスワード（確認）"
          autoComplete="new-password"
          type="password"
        />
        <Button
          disabled={!isValidPassword(password) || isLoading || !rePassword}
          variant="contained"
          type="submit"
          sx={{ mt: 1 }}
        >
          パスワードを設定
        </Button>
        <Button onClick={handleNavigateLogin}>ログインに戻る</Button>
      </Box>
    </FormPaper>
  );
};
