import { Error } from '@mui/icons-material';
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import { theme } from 'theme';

type Props = {
  open: boolean;
  title: string;
  content: ReactNode;
  cancelButton: ReactNode;
  confirmButton: ReactNode;
  pbDialogContent?: number;
};
export const AlertDialog: FC<Props> = ({
  open,
  title,
  content,
  cancelButton,
  confirmButton,
  pbDialogContent,
}) => {
  return (
    <Dialog
      open={open}
      PaperProps={{ sx: { width: 600, m: 0, borderRadius: 2 } }}
    >
      <DialogTitle>
        <Alert
          severity="error"
          icon={<Error sx={{ color: theme.palette.error.light }} />}
          sx={{
            p: 0,
            fontSize: 20,
            backgroundColor: theme.palette.white.primary,
            color: theme.palette.textBlack.primary,
            alignItems: 'center',
          }}
        >
          {title}
        </Alert>
      </DialogTitle>
      <DialogContent sx={{ pb: pbDialogContent }}>{content}</DialogContent>
      <DialogActions>
        {cancelButton}
        {confirmButton}
      </DialogActions>
    </Dialog>
  );
};
