import { Check, Circle } from '@mui/icons-material';
import { Box } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';
import { theme } from '../../../theme';

export const ProcessedIcon: FC = () => (
  <Box
    component="div"
    sx={{
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'inherit',
      height: 'inherit',
    }}
  >
    <Circle
      sx={{
        height: 'inherit',
        border: `1px solid ${CommonColors.appGreen}`,
        borderRadius: '50%',
        color: theme.palette.white.primary,
        background: theme.palette.white.primary,
      }}
    />
    <Check
      sx={{
        position: 'absolute',
        color: CommonColors.appGreen,
        width: 12,
        height: 12,
      }}
    />
  </Box>
);
