import { ProductImage } from '@components/organisms/productImage/productImage';
import { DoNotDisturb, Help } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FC } from 'react';
import { Product } from '../../../types/common';
import { RealogramCandidateFace } from '../../../types/realogram';

type Props = {
  product?: Product;
  size?: number;
  face?: RealogramCandidateFace;
  baseSize?: number;
  fontSize?: number;
};

const style = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  color: '#B5B5B5',
};

export const RealogramFaceImage: FC<Props> = ({
  product,
  size = 24,
  face,
  baseSize,
  fontSize,
}) => {
  if (face && !face?.in_stock && !face.primary_candidate)
    return (
      <Box component="div" sx={style}>
        <DoNotDisturb sx={{ width: size, height: size }} />
      </Box>
    );
  if (face && face?.is_unknown)
    return (
      <Box component="div" sx={style}>
        <Help sx={{ width: size, height: size }} />
      </Box>
    );
  return (
    <ProductImage
      product={product}
      layout="fill"
      objectFit="contain"
      imageSizeType="tiny"
      baseSize={baseSize}
      fontSize={fontSize}
    />
  );
};
