import { Box, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { emptyText } from '@utils/const';
import { FC } from 'react';
import { theme } from 'theme';
import { Rate } from 'types/common';
import { SelectableCard } from '../selectableCard/selectableCard';

type Props = {
  rateValue: Rate;
  rateValues: { item: Rate; value: string }[];
  handleChangeRateValue: (value: Rate) => void;
};

export const RateCards: FC<Props> = ({
  rateValue,
  rateValues,
  handleChangeRateValue,
}) => {
  return (
    <>
      {rateValues.map(({ item, value }, index) => {
        const isSelected = item === rateValue;
        const isHasValue = !!value;
        return (
          <Box component="div" key={index}>
            <SelectableCard
              selected={isSelected}
              onClick={() => handleChangeRateValue(item)}
              sx={{
                alignItems: 'unset',
                flexDirection: 'column',
                minWidth: 80,
                height: 54,
                maxWidth: 142,
              }}
            >
              <Typography
                variant="caption"
                color={CommonColors.grayLight}
                textAlign="start"
                lineHeight={1}
                marginBottom={isHasValue ? 0.4 : 0.6}
              >
                {item}
              </Typography>
              {isHasValue ? (
                <Typography
                  textAlign="end"
                  fontSize={18}
                  fontWeight={500}
                  color={theme.palette.textBlack.primary}
                >
                  {value}
                </Typography>
              ) : (
                <Typography
                  variant="body1"
                  textAlign="end"
                  color={theme.palette.textBlack.disabled}
                  lineHeight={1.5}
                >
                  {emptyText}
                </Typography>
              )}
            </SelectableCard>
          </Box>
        );
      })}
    </>
  );
};
