import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

export const RotateVerticalIcon: FC<SvgIconProps> = (props) => {
  const color = props.htmlColor ?? theme.palette.icons.primary;

  return (
    <SvgIcon
      {...props}
      sx={{
        width: '24px',
        height: '24px',
        viewBox: '0 0 24 24',
        fill: 'none',
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4V5.2518C9.00286 3.91546 10.3696 3 11.9969 3C13.8469 3 15.3704 4.19695 16.3805 5.81315C17.2542 7.21108 17.8181 9.02055 17.961 11H21V13H17.961C17.8181 14.9795 17.2542 16.7889 16.3805 18.1869C15.3704 19.803 13.8469 21 11.9969 21C10.3699 21 9.0066 20.0928 8.00008 18.7473V20H6V15H11V17H9.22895C10.0199 18.3351 11.0517 19 11.9969 19C12.9085 19 13.885 18.4061 14.6845 17.1269C15.4752 15.8617 15.9969 14.0517 15.9969 12C15.9969 9.9483 15.4752 8.13829 14.6845 6.87314C13.885 5.59391 12.9085 5 11.9969 5C11.0538 5 10.029 5.65087 9.23082 7H11V9H6V4H8ZM5 11H15V13H5V11Z"
        fill={color}
        fillOpacity="0.65"
      />
    </SvgIcon>
  );
};
