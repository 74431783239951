import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductCategory } from 'types/productCategories';

type ProductCategoryState = {
  categoryHistory: ProductCategory[];
  categoryIndex: number;
};

const initialState: ProductCategoryState = {
  categoryHistory: [],
  categoryIndex: 0,
};

const productCategorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategoryHistory: (
      state: ProductCategoryState,
      action: PayloadAction<ProductCategory[]>
    ) => {
      state.categoryHistory = action.payload;
    },
    setCategoryIndex: (
      state: ProductCategoryState,
      action: PayloadAction<number>
    ) => {
      state.categoryIndex = action.payload;
    },
    reset: () => initialState,
  },
});

export const { setCategoryHistory, setCategoryIndex, reset } =
  productCategorySlice.actions;

export const ProductCategoryReducer = productCategorySlice.reducer;
