import { Divider } from '@mui/material';
import { useBreakpoint } from '@hooks/useBreakpoint';

export const ComparisonDivider = () => {
  const { isLarger } = useBreakpoint();
  return (
    <Divider
      orientation={isLarger ? 'horizontal' : 'vertical'}
      flexItem
      sx={{
        marginLeft: { xs: '8px', breakpoint: 0 },
        marginTop: { xs: 0, breakpoint: '8px' },
      }}
    />
  );
};
