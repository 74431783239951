import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const ChartIconSeparated: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2"
          y="8"
          width="4"
          height="12"
          rx="1"
          fill="black"
          fillOpacity="0.65"
        />
        <rect
          x="10"
          y="2"
          width="4"
          height="18"
          rx="1"
          fill="black"
          fillOpacity="0.65"
        />
        <rect
          x="18"
          y="13"
          width="4"
          height="7"
          rx="1"
          fill="black"
          fillOpacity="0.65"
        />
        <rect
          x="2"
          y="21"
          width="20"
          height="1"
          fill="black"
          fillOpacity="0.65"
        />
      </svg>
    </SvgIcon>
  );
};
