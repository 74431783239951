import { RoleIcon } from '@components/molecules/roleIcon/roleIcon';
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { Scope } from 'types/sharePermission';

type Props = {
  scopeOptions: Record<string, string>[];
  selectedScope: Scope;
  selectedScopeContent: string | undefined;
  setSelectedScope: (scope: Scope) => void;
  setPreviousSelectedScope: (scope: Scope) => void;
  handleChangeScope: (scope: Scope) => void;
  scope?: string;
  typeParent?: string;
};

export const ScopeSelect: FC<Props> = ({
  scopeOptions,
  selectedScope,
  selectedScopeContent,
  setSelectedScope,
  setPreviousSelectedScope,
  handleChangeScope,
  scope,
  typeParent,
}) => {
  const checkDisableOption = (option: string) => {
    if (typeParent === 'root') return false;
    else if (scope === 'public' && option !== 'public') return true;
    return scope === 'restricted' && option === 'private';
  };

  return (
    <Box component="div">
      <TextField
        id="select-access-permissions"
        select
        fullWidth
        size="small"
        label="公開範囲"
        value={selectedScope}
        sx={{
          '.MuiSelect-select': {
            maxHeight: '20px',
          },
        }}
        onChange={(event) => {
          const scope = event.target.value as Scope;
          if (scope === 'private') {
            setPreviousSelectedScope(selectedScope);
          }
          setSelectedScope(scope);
          handleChangeScope(scope);
        }}
      >
        {scopeOptions.map((option) => {
          const isOptionDisabled = checkDisableOption(option.value);

          if (!isOptionDisabled || option.value === selectedScope) {
            return (
              <MenuItem
                sx={{
                  '.MuiTypography-root': {
                    marginTop: '5px',
                  },
                }}
                key={option.value}
                value={option.value}
                disabled={isOptionDisabled}
              >
                <ListItemIcon sx={{ color: theme.palette.textBlack.primary }}>
                  <RoleIcon option={option.value as Scope} />
                  <ListItemText>
                    <Typography
                      color={theme.palette.textBlack.primary}
                      noWrap
                      fontSize={16}
                      sx={{
                        fontWeight: '400',
                        lineHeight: '16px',
                        marginLeft: '5px',
                      }}
                    >
                      {option.label}
                    </Typography>
                  </ListItemText>
                </ListItemIcon>
              </MenuItem>
            );
          }
        })}
      </TextField>
      <Box
        component="div"
        sx={{
          marginTop: '8px',
          marginBottom: '22px',
          maxWidth: '100%',
        }}
      >
        {selectedScopeContent && (
          <Typography
            color={theme.palette.textBlack.secondary}
            noWrap
            fontSize={14}
            sx={{
              fontWeight: '400',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'initial',
            }}
          >
            {selectedScopeContent}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
