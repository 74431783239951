import { AccountCircle, Feedback, Home, Logout } from '@mui/icons-material';
import {
  AppBar as MuiAppBar,
  AppBarProps,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  selectIsAuthenticated,
  selectRefreshToken,
} from '@reducers/auth/selectors';
import { setDemoState } from '@reducers/demo';
import { useSignOutMutation } from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { appBarHeight, homePageUrl, paths } from '@utils/const';
import { FC, MouseEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from '../../../theme';
import { useSkipRealogramStepThree } from '@components/pages/realogramCreate/hooks/useSkipRealogramStepThree';
import { removeIsRealogramSalesEnded } from '@reducers/scanner';
import { removeIsPlanogramSalesEnded } from '@reducers/planogramEditor/reducer';

type Props = {
  title: string;
  position?: AppBarProps['position'];
};

export const AppBar: FC<Props> = ({ title, position = 'static' }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isHomePage = location.pathname === homePageUrl;
  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const refreshToken = useAppSelector(selectRefreshToken);
  const [signOut, { isLoading }] = useSignOutMutation();
  const { handleResetSkipRealogramStepThree } = useSkipRealogramStepThree();
  const handleLogout = async (): Promise<void> => {
    await signOut({ refreshToken }).unwrap();
    setAnchorEl(null);
    navigate(paths.login);
    handleResetSkipRealogramStepThree();
    dispatch(setDemoState(false));
    dispatch(removeIsRealogramSalesEnded);
    dispatch(removeIsPlanogramSalesEnded);
  };

  const handleOpenLicensesJson = () => {
    window.open('licenses.txt', '_blank');
  };

  return (
    <MuiAppBar
      elevation={0}
      position={position}
      sx={{
        maxHeight: appBarHeight,
        p: 0, //NOTE: cleanTheme設定によってappBarの上に要素が追加されてしまうため削除
        ':after': { display: 'none' },
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          display: 'flex',
          minHeight: appBarHeight,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.backgroundBlack.light,
        }}
      >
        {isAuthenticated && !isHomePage && (
          <Button
            sx={{
              color: theme.palette.textBlack.primary,
              position: 'absolute',
              top: 0,
              left: 0,
              py: 1,
              px: 3,
              fontWeight: 700,
              '& .MuiButton-startIcon': {
                marginLeft: 0,
              },
              ':hover': { backgroundColor: 'transparent' }, //NOTE: themeでホバー中の背景色が設定されているが、このボタンのみスタイルを設定しない
            }}
            startIcon={
              <Home
                sx={{
                  fill: theme.palette.icons.primary,
                }}
              />
            }
            onClick={() => {
              navigate(paths.home);
            }}
          >
            ホーム
          </Button>
        )}
        <Typography
          sx={{
            color: theme.palette.textBlack.primary,
            fontSize: 14,
          }}
        >
          {title}
        </Typography>
        <Box
          component="div"
          sx={{ position: 'absolute', top: 0, right: 0, bottom: 0 }}
        >
          {isAuthenticated && (
            <>
              <IconButton
                onClick={(e) => handleMenuOpen(e)}
                size="large"
                sx={{
                  color: theme.palette.icons.primary,
                  py: 1,
                  px: 3,
                  ':hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <AccountCircle sx={{ fontSize: 24 }} />
              </IconButton>
              <Menu
                keepMounted
                open={anchorEl !== null}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    navigate(paths.account.root);
                  }}
                >
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  アカウント情報
                </MenuItem>
                {process.env.NEXT_PUBLIC_FEEDBACK_FORM_URL && (
                  <MenuItem
                    component="a"
                    href={process.env.NEXT_PUBLIC_FEEDBACK_FORM_URL}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemIcon>
                      <Feedback />
                    </ListItemIcon>
                    お問い合わせ
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    handleOpenLicensesJson();
                  }}
                >
                  <ListItemIcon />
                  オープンソースライセンス
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout} disabled={isLoading}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  ログアウト
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Toolbar>
      <Divider />
    </MuiAppBar>
  );
};
