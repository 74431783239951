import { FC, useState } from 'react';
import { useNavigateUploadPage } from './hooks/useNavigateUploadPage';
import { useRealogramFileUpload } from './hooks/useRealogramFileUpload';
import { usePageTitle } from '@hooks/usePageTitle';
import { RealogramType } from 'types/realogram';
import { useMediaQuery } from '@mui/material';
import { StepOne } from './fragments/stepOne/stepOne';
import { StepTwo } from './fragments/stepTwo/stepTwo';
import { StepThree } from './fragments/stepThree/stepThree';
import { StepFour } from './fragments/stepFour/stepFour';
import { useGetFormOptions } from './hooks/useGetFormOptions';
import { StepFive } from './fragments/stepFive/stepFive';
import { useSkipRealogramStepThree } from './hooks/useSkipRealogramStepThree';
import { useAbility } from '@casl/react';
import { AbilityContext } from '@utils/abac';
import { useSelectionStoreModal } from '@hooks/useSelectionStoreModal';
import { useNavigate } from 'react-router-dom';

export const RealogramCreate: FC = () => {
  usePageTitle('新規ゴンドラスキャン');
  const navigate = useNavigate();
  const [selectedRealogramType, isSetSelectedRealogramType] =
    useState<RealogramType>('');
  const isFlat = selectedRealogramType === 'flat';
  const isMd = useMediaQuery('(min-width: 900px)');
  const isXs = useMediaQuery('(max-width: 599px)');
  const { step, changeStep, prevNavigate } = useNavigateUploadPage();
  const {
    control,
    storeBayData,
    bayPlanCodes,
    disabled,
    handleSubmit,
    setFormValue,
  } = useGetFormOptions();
  const {
    isSkipStepThree,
    isFlatSkipStepThree,
    handleSetIsSkipStepThree,
    handleSetSkipRealogramStepThree,
    handleSetSkipFlatRealogramStepThree,
  } = useSkipRealogramStepThree(isFlat);

  const {
    onFirstFileSelect,
    firstFile,
    secondFile,
    onSecondFileSelect,
    onSwapFile,
    failedDialogOpen,
    failedDialogOpenForbidden,
    setFailedDialogOpen,
    setFailedDialogOpenForbidden,
    onSingleRealogramSubmit,
    onFlatRealogramSubmit,
    stepFiveInfo,
  } = useRealogramFileUpload(
    changeStep,
    handleSetSkipRealogramStepThree,
    isSkipStepThree,
    handleSetSkipFlatRealogramStepThree,
    isFlatSkipStepThree,
    bayPlanCodes,
    setFormValue
  );
  const ability = useAbility(AbilityContext);
  const { handleOpenModal } = useSelectionStoreModal(ability);

  const handleNavigate = (nextPath: string) => {
    navigate(nextPath);
  };

  switch (step) {
    case 'one':
      return (
        <StepOne
          changeStep={changeStep}
          selectedRealogramType={selectedRealogramType}
          isSetSelectedRealogramType={isSetSelectedRealogramType}
          isFlat={isFlat}
          isXs={isXs}
        />
      );
    case 'two':
      return (
        <StepTwo
          changeStep={changeStep}
          selectedRealogramType={selectedRealogramType}
          onFirstFileSelect={onFirstFileSelect}
          isFlat={isFlat}
        />
      );
    case 'three':
      return (
        <StepThree
          changeStep={changeStep}
          selectedRealogramType={selectedRealogramType}
          onFirstFileSelect={onFirstFileSelect}
          isFlat={isFlat}
          isMd={isMd}
          isSkipStepThree={isSkipStepThree}
          isFlatSkipStepThree={isFlatSkipStepThree}
          handleSetIsSkipStepThree={handleSetIsSkipStepThree}
          isXs={isXs}
        />
      );
    case 'four':
      return (
        <StepFour
          firstFile={firstFile}
          failedDialogOpen={failedDialogOpen}
          failedDialogOpenForbidden={failedDialogOpenForbidden}
          onSingleRealogramSubmit={onSingleRealogramSubmit}
          onFlatRealogramSubmit={onFlatRealogramSubmit}
          onFirstFileSelect={onFirstFileSelect}
          handleCloseFailedDialog={() => setFailedDialogOpen(false)}
          handleCloseFailedDialogForbidden={() =>
            setFailedDialogOpenForbidden(false)
          }
          setFailedDialogOpen={setFailedDialogOpen}
          changeStep={changeStep}
          selectedRealogramType={selectedRealogramType}
          prevNavigate={prevNavigate}
          secondFile={secondFile}
          onSecondFileSelect={onSecondFileSelect}
          onSwapFile={onSwapFile}
          isFlat={isFlat}
          isMd={isMd}
          control={control}
          storeBayData={storeBayData?.store_bays}
          bayPlanCodes={bayPlanCodes}
          disabled={disabled}
          handleSubmit={handleSubmit}
          handleOpenModal={handleOpenModal}
        />
      );
    case 'five':
      return (
        <StepFive
          changeStep={changeStep}
          isFlat={isFlat}
          onFirstFileSelect={onFirstFileSelect}
          onSecondFileSelect={onSecondFileSelect}
          stepFiveInfo={stepFiveInfo}
          handleNavigate={handleNavigate}
          isXs={isXs}
        />
      );
  }
};
