import { Typography } from '@mui/material';
import { theme } from 'theme';

export const ModalTitle = () => {
  return (
    <Typography
      color={theme.palette.textBlack.primary}
      noWrap
      fontSize={20}
      sx={{
        fontWeight: '700',
      }}
    >
      共有
    </Typography>
  );
};
