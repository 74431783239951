import { View } from '@components/organisms/previewPlanogram/fragments/view_set';
import {
  rotateGondola,
  rotateGondolaInCompare,
  setIsTooltipOpen,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useGetBayPlanViewSetQuery } from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { fullRotation, rotationAngleFlatPlanogram } from '@utils/const';
import { useEffect, useMemo, useState } from 'react';
import { PreviewMode } from 'types/planogram';

export const usePreviewPlanogram = (id: number, previewMode: PreviewMode) => {
  const dispatch = useAppDispatch();
  const {
    data: bayPlanViewSet,
    refetch: handleRefetch3dView,
    isLoading: isLoadingBayPlanViewSet,
  } = useGetBayPlanViewSetQuery({
    bayPlanId: id,
  });
  const [isViewPrint, setIsViewPrint] = useState(false);
  const { rotateDeg, rotateDegInCompare, isTooltipOpen } = useAppSelector(
    selectPlanogramEditorState
  );

  const threeDViewParams = useMemo(() => {
    const dataViewGroups =
      (isViewPrint
        ? bayPlanViewSet?.bay_plans_view_set?.view_groups.print
        : bayPlanViewSet?.bay_plans_view_set?.view_groups.default) || [];

    if (rotateDeg % fullRotation === 0 || isViewPrint) {
      return dataViewGroups.find((view) => view.name === 'front') as View;
    } else if (rotateDeg % fullRotation === rotationAngleFlatPlanogram) {
      return dataViewGroups.find((view) => view.name === 'behind') as View;
    }
  }, [bayPlanViewSet, isViewPrint, rotateDeg]);

  const handleClickRotate = () => {
    dispatch(
      rotateGondola(
        rotateDeg === 0
          ? rotationAngleFlatPlanogram
          : rotationAngleFlatPlanogram + rotateDeg
      )
    );
    dispatch(
      rotateGondolaInCompare(
        rotateDegInCompare === 0
          ? rotationAngleFlatPlanogram
          : rotationAngleFlatPlanogram + rotateDegInCompare
      )
    );
    setIsViewPrint(false);
  };

  const handleRotate = (degree: number) => {
    handleReset3dView();
    dispatch(rotateGondola(degree));
  };

  const closeTooltip = () => {
    if (isTooltipOpen) {
      dispatch(setIsTooltipOpen(false));
    }
  };

  useEffect(() => {
    if (previewMode === '2d') {
      setIsViewPrint(false);
    }
  }, [previewMode]);

  const handleViewPrint = () => {
    setIsViewPrint(!isViewPrint);
  };

  const handleReset3dView = () => {
    handleRefetch3dView().catch((e) => {
      console.log(e);
    });
    setIsViewPrint(false);
  };

  const isFlat = !!bayPlanViewSet?.bay_plans_view_set?.view_groups.default.find(
    (v) => v.name === 'behind'
  );

  return {
    isLoadingBayPlanViewSet,
    threeDViewParams,
    handleClickRotate,
    handleRotate,
    isTooltipOpen,
    closeTooltip,
    isViewPrint,
    handleViewPrint,
    handleReset3dView,
    isFlat,
  };
};
