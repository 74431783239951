import { StyledListItemButton } from '@components/organisms/productCandidatesList/productCandidatesList';
import { Search } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
  handleClick: () => void;
};

const StyledIconWrapper = styled(Box)(() => ({
  marginLeft: 16,
  marginRight: 4,
  width: 60,
  minHeight: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledBox = styled(Box)(() => ({
  minHeight: 84,
  display: 'flex',
  alignItems: 'center',
  padding: 8,
  width: '100%',
}));

export const ProductChoiceCard: FC<Props> = ({ handleClick }) => {
  return (
    <StyledListItemButton data-testid="ProductChoiceCard" onClick={handleClick}>
      <StyledBox>
        <StyledIconWrapper>
          <Search sx={{ width: 40, height: 40, color: '#B5B5B5' }} />
        </StyledIconWrapper>

        <Typography ml={0.5}>自分で商品を選ぶ</Typography>
      </StyledBox>
    </StyledListItemButton>
  );
};
