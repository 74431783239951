import { CreateNewFolderIcon } from '@components/molecules/createNewFolderIcon/createNewFolderIcon';
import { PostAddIcon } from '@components/molecules/postAddIcon/postAddIcon';
import { SidebarItemButton } from '@components/molecules/sidebarItemButton/sidebarItemButton';
import { Box, Drawer, Stack, Typography } from '@mui/material';
import { SidebarValue, drawerWidth } from '@utils/const';
import { FC } from 'react';
import { theme } from 'theme';
import { StackListSidebar } from '../stackListSidebar/stackListSidebar';

type Props = {
  sidebarValue: SidebarValue;
  handleCreatePlanogram: () => void;
  handleCreateDirectory: () => void;
  onClickTabAll: () => void;
  onClickTabFavorite: () => void;
  onClickTabViewed: () => void;
};

export const PlonogramsSidebar: FC<Props> = ({
  sidebarValue,
  handleCreatePlanogram,
  handleCreateDirectory,
  onClickTabAll,
  onClickTabFavorite,
  onClickTabViewed,
}) => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          position: 'relative',
          marginTop: '1px',
          background: theme.palette.backgroundBlack.light,
        },
      }}
    >
      <Box
        component="nav"
        display="flex"
        flexDirection="column"
        gap={5}
        padding={1.5}
      >
        <Stack
          direction="column"
          alignSelf="stretch"
          alignItems="center"
          gap="8px"
        >
          <SidebarItemButton
            variant="contained"
            startIcon={<PostAddIcon />}
            onClick={handleCreatePlanogram}
          >
            <Typography
              component="span"
              sx={{
                width: '100%',
                fontWeight: 700,
                fontSize: '0.875rem',
                textAlign: 'center',
                color: theme.palette.white.primary,
              }}
            >
              棚割作成
            </Typography>
          </SidebarItemButton>
          <SidebarItemButton
            variant="outlined"
            startIcon={<CreateNewFolderIcon />}
            onClick={handleCreateDirectory}
          >
            <Typography
              component="span"
              sx={{
                width: '100%',
                fontWeight: 700,
                fontSize: '0.875rem',
                textAlign: 'center',
                color: theme.palette.primary.main,
              }}
            >
              フォルダ作成
            </Typography>
          </SidebarItemButton>
        </Stack>
        <StackListSidebar
          sidebarValueItems={['all', 'favorite', 'viewed']}
          sidebarValue={sidebarValue}
          onClickTabAll={onClickTabAll}
          onClickTabFavorite={onClickTabFavorite}
          onClickTabViewed={onClickTabViewed}
        />
      </Box>
    </Drawer>
  );
};
