import {
  EmptyResult,
  planogramDirectoryEmptyMessage,
} from '@components/molecules/emptyResult/emptyResult';
import { gridComponents } from '@components/organisms';
import { useQueryParameterPresence } from '@hooks/useQueryParameterPresence';
import { Box, CircularProgress } from '@mui/material';
import { useAppSelector } from '@store/index';
import { FC, useEffect, useRef } from 'react';
import { VirtuosoGrid, VirtuosoGridHandle } from 'react-virtuoso';
import { PlanogramDirectory, PlanogramDirectoryParent } from 'types/planogram';
import { PlanogramSharePermissionProps } from 'types/sharePermission';
import { theme } from '../../../theme';
import { PlanogramCard } from '../planogramCard/planogramCard';
import { useLocation } from 'react-router-dom';

type Props = {
  isLoading: boolean;
  parent?: PlanogramDirectoryParent;
  planogramDirectories?: PlanogramDirectory[];
  handleCardClick: (item: PlanogramDirectory) => void;
  handleOpenDeleteDialog: (planogramDirecotry: PlanogramDirectory) => void;
  handleUpdatePlanogramName: (planogramDirecotry: PlanogramDirectory) => void;
  handleUpdateDirectoryName: (planogramDirecotry: PlanogramDirectory) => void;
  handleMoveDirectory: (planogramDirecotry: PlanogramDirectory) => void;
  handleClonePlanogram: (planogramDirecotry: PlanogramDirectory) => void;
  handleFavoriteClick: (id: string, isFavorite: boolean) => void;
  handleSharePermission: (item: PlanogramSharePermissionProps) => void;
  handleEndReached: (isAtBottom: boolean) => void;
  isRefetching?: boolean;

  isFilteredByViewed: boolean;
};

const imageHeight = 120;
const speedToEnter = 400;
const speedToExit = 30;
const queryList = ['bay_plan_code_id', 'owner_id', 'organization_status_id'];

// eslint-disable-next-line @typescript-eslint/naming-convention -- コンポーネントのため許可
const LoadingContent: FC<{ scrollableHeight: string }> = ({
  scrollableHeight,
}) => {
  return (
    <Box
      component="div"
      height={scrollableHeight}
      mt={1}
      color="#D9D9D9"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={theme.palette.white.primary}
    >
      <CircularProgress color="inherit" />
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- コンポーネントのため許可
const EmptyContent: FC<{
  scrollableHeight: string;
  emptyMessage: string;
}> = ({ scrollableHeight, emptyMessage }) => {
  return (
    <Box component="div" height={scrollableHeight}>
      <EmptyResult
        title="棚割・フォルダがありません"
        message={emptyMessage}
        isErrorIcon
      />
    </Box>
  );
};

export const PlanogramsGrid: FC<Props> = ({
  isLoading,
  parent,
  planogramDirectories,
  handleCardClick,
  handleOpenDeleteDialog,
  handleUpdatePlanogramName,
  handleUpdateDirectoryName,
  handleMoveDirectory,
  handleClonePlanogram,
  handleFavoriteClick,
  handleSharePermission,
  handleEndReached,
  isRefetching,
  isFilteredByViewed,
}) => {
  const ref = useRef<VirtuosoGridHandle>(null); //NOTE: for typescript support
  const { selectedItemIndex } = useAppSelector((state) => state.Planogram);
  const { hasQueryParameters } = useQueryParameterPresence();
  const location = useLocation();

  useEffect(() => {
    if (!selectedItemIndex || !ref.current) return;
    ref.current.scrollToIndex({ index: selectedItemIndex });
  });

  useEffect(() => {
    ref.current?.scrollToIndex({ index: 0 });
  }, [location, isLoading]);

  if (isLoading) {
    return <LoadingContent scrollableHeight="100%" />;
  }

  if (!planogramDirectories || planogramDirectories.length === 0) {
    return (
      <EmptyContent
        scrollableHeight="calc(100% - 90px)"
        emptyMessage={planogramDirectoryEmptyMessage(
          hasQueryParameters(queryList),
          parent?.type === 'directory'
        )}
      />
    );
  }

  return (
    <>
      <VirtuosoGrid
        ref={ref}
        data={planogramDirectories}
        style={{ height: '100%' }}
        atBottomStateChange={handleEndReached}
        components={{
          ...gridComponents,
          // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
          Footer: () => {
            if (isRefetching) return <LoadingContent scrollableHeight="auto" />;
            return <></>;
          },
        }}
        scrollSeekConfiguration={{
          enter: (velocity) => Math.abs(velocity) > speedToEnter,
          exit: (velocity) => {
            const shouldExit = Math.abs(velocity) < speedToExit;
            return shouldExit;
          },
        }}
        itemContent={(_, item) => (
          <Box component="div" width="100%">
            <PlanogramCard
              imageHeight={imageHeight}
              planogramDirectory={item}
              isFilteredByViewed={isFilteredByViewed}
              handleOpenDeleteDialog={handleOpenDeleteDialog}
              handleUpdatePlanogramName={handleUpdatePlanogramName}
              handleUpdateDirectoryName={handleUpdateDirectoryName}
              handleMoveDirectory={handleMoveDirectory}
              handleClonePlanogram={handleClonePlanogram}
              handleClick={handleCardClick}
              handleFavoriteClick={handleFavoriteClick}
              handleSharePermission={handleSharePermission}
            />
          </Box>
        )}
      />
    </>
  );
};
