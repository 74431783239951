import { Box } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { FC } from 'react';
import { ProfitTab } from 'types/common';
import { ProductSalesReport } from 'types/products';
import { ProfitStatistics } from '../profitStatistics/profitStatistics';
import { PlanogramStatistics } from './fragments/planogramStatistics';

type Props = {
  term: string;
  profitTab: ProfitTab;
  setProfitTab: (profitTab: ProfitTab) => void;
  isFlat: boolean;
  statistic?: {
    totalShelves: string;
    totalFaces: string;
    totalUniques: string;
    productFlag: {
      totalMainFaces: string;
      totalNewFacesFirstWeek: string;
      totalNewFacesSecondWeek: string;
      totalRecomendedToCancelationFaces: string;
      totalNot3DScannedFaces: string;
    };
  };
  displayAnalyticsData?: ProductSalesReport;
};

export const PlanogramOverview: FC<Props> = ({
  term,
  profitTab,
  setProfitTab,
  isFlat,
  statistic,
  displayAnalyticsData,
}) => {
  const { detailView: view } = useAppSelector(selectPlanogramEditorState);
  switch (view) {
    case 'default':
      return (
        <Box component="div" p={2}>
          <PlanogramStatistics
            isFlat={isFlat}
            totalShelfBoards={statistic?.totalShelves ?? ''}
            totalUniques={statistic?.totalUniques ?? ''}
          />
        </Box>
      );
    case 'productFlag':
      return <></>;
    case 'profit':
      return (
        <ProfitStatistics
          term={term}
          profitTab={profitTab}
          setProfitTab={setProfitTab}
          displayAnalyticsData={displayAnalyticsData}
        />
      );
    case 'rate':
      return <></>;
  }
};
