import { ListItemButton, styled } from '@mui/material';

export const SelectableCard = styled(ListItemButton)(({ sx }) => ({
  paddingLeft: '8px',
  paddingRight: '8px',
  border: `1px solid #CCCCCC`,
  borderRadius: '4px',
  sx,
  '&.Mui-selected': {
    //theme設定により背景が青になっているのでoverwrite
    backgroundColor: 'transparent',
  },
}));
