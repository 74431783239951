import { EmptyResult } from '@components/molecules/emptyResult/emptyResult';
import { Box } from '@mui/material';
import { FC } from 'react';
import { Product } from 'types/common';
import { RecentProductsList } from './recentProductsList';

type Props = {
  products: Product[];
  isCompareMode: boolean;
  selectedItemId: number;
  handleClickProduct: (product: Product) => void;
};

export const RecentProducts: FC<Props> = ({
  products,
  isCompareMode,
  selectedItemId,
  handleClickProduct,
}) => {
  if (!products.length) {
    return (
      <EmptyResult
        isErrorIcon
        width="32px"
        height="32px"
        title="最近操作した商品はありません"
        message="棚に配置した商品、または棚から削除した商品がここに表示されます。"
      />
    );
  }
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        flex: 1,
      }}
    >
      <Box component="div" overflow="auto">
        <RecentProductsList
          products={products}
          isCompareMode={isCompareMode}
          selectedItemId={selectedItemId}
          handleClick={handleClickProduct}
        />
      </Box>
    </Box>
  );
};
