import { FC } from 'react';
import { theme } from 'theme';
import { Button } from '@mui/material';

type Props = {
  onFileSelect: (file: File) => void;
  leftOrRight: 'left' | 'right';
  onSecondFileSelect?: (file: File) => void;
};

export const OtherImageUploadButton: FC<Props> = ({
  onFileSelect,
  leftOrRight,
  onSecondFileSelect,
}) => (
  <Button
    component="label"
    sx={{
      p: 0,
      color: theme.palette.white.primary,
      backgroundColor: theme.palette.primary.main,
      width: '136px',
      height: '48px',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: theme.palette.primary.mainHover,
      },
    }}
  >
    <input
      hidden
      type="file"
      accept="image/jpeg"
      onChange={({ target }) => {
        if (!target.files) {
          console.log('image upload failed');
          return;
        }
        if (leftOrRight === 'right' && onSecondFileSelect) {
          onSecondFileSelect(target.files[0]);
          return;
        }
        onFileSelect(target.files[0]);
      }}
    />
    {leftOrRight === 'left' ? '左' : '右'}半分を撮影
  </Button>
);
