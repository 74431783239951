import { Button, DialogContentText } from '@mui/material';
import { FC } from 'react';
import { AlertDialog } from '../alertDialog/alertDialog';
import { theme } from 'theme';

type Props = {
  open: boolean;
  handleCancel: () => void;
};

export const DevelopingFeatureDialog: FC<Props> = ({ open, handleCancel }) => {
  return (
    <AlertDialog
      open={open}
      title="デモモードについて"
      content={
        <DialogContentText color={theme.palette.textBlack.primary}>
          この機能は現在開発中です。今後、任意のゴンドラを選択して比較できるようにする予定です。
        </DialogContentText>
      }
      cancelButton={null}
      confirmButton={
        <Button
          onClick={handleCancel}
          sx={{ color: theme.palette.textBlack.primary, fontWeight: 700 }}
        >
          閉じる
        </Button>
      }
    />
  );
};
