import { schema } from '@components/login/login.schema';
import { PrivacyPolicyAgreement } from '@components/organisms/privacyPolicyAgreement/privacyPolicyAgreement';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Link, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { theme } from 'theme';
import { EmailPasswordFormData, FormData } from '../login';
import { EmailPasswordField } from './emailPasswordField';

type Props = {
  isLoading: boolean;
  handleLogin: SubmitHandler<EmailPasswordFormData>;
  isSsoLogin: boolean;
  handleChangeSelectEmailLogin: () => void;
};

export const EmailPasswordLogin: FC<Props> = ({
  isLoading,
  handleLogin,
  isSsoLogin,
  handleChangeSelectEmailLogin,
}) => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { handleSubmit, watch } = methods;
  const [email, password, privacyPolicy] = watch([
    'email',
    'password',
    'privacyPolicy',
  ]);

  return (
    <FormProvider {...methods}>
      <Stack
        component="form"
        onSubmit={handleSubmit(handleLogin)}
        alignItems="center"
        sx={{ mt: '24px' }}
        noValidate
      >
        <EmailPasswordField />
        <PrivacyPolicyAgreement />
        <Button
          variant="contained"
          type="submit"
          fullWidth
          disabled={!email || !password || !privacyPolicy || isLoading}
        >
          ログイン
        </Button>
      </Stack>
      {isSsoLogin && (
        <>
          <Divider
            sx={{ mt: 2, mb: 2, color: theme.palette.textBlack.secondary }}
          >
            <Typography>または</Typography>
          </Divider>
          <Box component="div" textAlign="center" mb={2}>
            <Link
              component="button"
              variant="body2"
              onClick={handleChangeSelectEmailLogin}
              textAlign="center"
            >
              SSOでログイン
            </Link>
          </Box>
        </>
      )}
    </FormProvider>
  );
};
