import { Close } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
  onCloseClick: VoidFunction;
};

export const RecentProductsTitle: FC<Props> = ({ onCloseClick }) => {
  return (
    <>
      <Typography
        component="h2"
        sx={{
          fontSize: '16px',
          fontWeight: 700,
          letterSpacing: '0.15px',
          display: 'inline-flex',
          alignItems: 'center',
          lineHeight: '175%',
          flex: 1,
        }}
      >
        最近操作した商品
      </Typography>
      <IconButton onClick={onCloseClick}>
        <Close />
      </IconButton>
    </>
  );
};
