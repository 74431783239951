import { Box, Collapse, Typography } from '@mui/material';
import { CommonColors, withOpacity } from '@utils/colors';
import { FC } from 'react';

type Props = {
  open: boolean;
  name?: string;
  isEditorInCompare?: boolean;
};

const opacity = 0.3;

export const BayPartName: FC<Props> = ({
  open,
  name,
  isEditorInCompare = true,
}) => {
  const color = withOpacity(
    CommonColors.grayLight,
    open && isEditorInCompare ? opacity : 1
  );
  return (
    <Box
      component="div"
      display="flex"
      alignItems="center"
      gap="8px"
      sx={{ pointerEvents: 'none' }}
    >
      {isEditorInCompare && (
        <Collapse orientation="horizontal" in={open}>
          <Box
            component="div"
            sx={{ width: 30, borderTop: `1px solid ${color}` }}
          />
        </Collapse>
      )}
      <Typography
        minWidth="88px"
        color={color}
        fontSize="10px"
        height="14px"
        display="flex"
        textAlign="left"
        alignItems="center"
        whiteSpace="nowrap"
      >
        {name}
      </Typography>
    </Box>
  );
};
