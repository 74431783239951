import { ParcelDivisionIcon } from '@components/molecules/parcelDivisionIcon/parcelDivisionIcon';
import { Delete, Edit } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import { FC } from 'react';

type Props = {
  handleDeleteDialogOpen: () => void;
  handleDivisionModalOpen: () => void;
  handleCorrectDrawerOpen: () => void;
  isCanEditFace?: boolean;
  isCanDeleteFace?: boolean;
  isDisplayDivisionModalMenu: boolean;
};

export const FaceMenuList: FC<Props> = ({
  handleDeleteDialogOpen,
  handleDivisionModalOpen,
  handleCorrectDrawerOpen,
  isCanEditFace,
  isCanDeleteFace,
  isDisplayDivisionModalMenu,
}) => {
  return (
    <MenuList disablePadding>
      <MenuItem disabled={!isCanEditFace} onClick={handleCorrectDrawerOpen}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText>商品を訂正</ListItemText>
      </MenuItem>
      {isDisplayDivisionModalMenu && (
        <MenuItem disabled={!isCanEditFace} onClick={handleDivisionModalOpen}>
          <ListItemIcon>
            <ParcelDivisionIcon />
          </ListItemIcon>
          <ListItemText>区画を分割</ListItemText>
        </MenuItem>
      )}
      <MenuItem disabled={!isCanDeleteFace} onClick={handleDeleteDialogOpen}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <ListItemText>区画を削除</ListItemText>
      </MenuItem>
    </MenuList>
  );
};
