/* eslint-disable @typescript-eslint/naming-convention -- テーマ設定で指定するコンポーネント名が大文字のため許容する */
import { cleanTheme } from '@design-system/core';
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { CommonColors } from '@utils/colors';

const customTheme = createTheme({
  palette: {
    white: {
      primary: '#FFFFFF', // CommonColors.white
      translucent: 'rgba(255, 255, 255, 0.85)',
    },
    textBlack: {
      primary: '#222222',
      table: '#444444',
      secondary: 'rgba(0, 0, 0, 0.6)',
      label: 'rgba(0, 0, 0, 0.5)',
      disabled: 'rgba(0, 0, 0, 0.35)',
      light: 'rgba(0, 0, 0, 0.87)',
    },
    dividerBlack: {
      light: 'rgba(0, 0, 0, 0.08)',
      medium: 'rgba(0, 0, 0, 0.12)',
      dark: 'rgba(0, 0, 0, 0.23)',
    },
    backgroundBlack: {
      primary: '#F5F5F5',
      primaryHover: '#E9E9E9',
      active: '#EEEEEE',
      hover: 'rgba(0, 0, 0, 0.04)',
      disabled: 'rgba(0, 0, 0, 0.1)',
      black: '#000000',
      light: '#FAFAFA',
    },
    overlay: {
      light: 'rgba(0, 0, 0, 0.4)',
      dark: 'rgba(0, 0, 0, 0.6)',
    },
    icons: {
      primary: 'rgba(0, 0, 0, 0.65)',
      secondary: 'rgba(0, 0, 0, 0.28)',
      disabled: 'rgba(0, 0, 0, 0.20)',
    },
    primary: {
      main: '#0A40CA', // CommonColors.appBlue
      mainHover: '#0F2E88',
      selected: '#E5F0FF', // CommonColors.appBlueLight
      selectedHover: '#CAE0FF',
      background: '#F6FAFF',
      lightBorder: '#0A40CA80',
    },
    system: {
      errorMain: '#EC3B3B',
      errorBg: '#FFEFEF',
      errorText: '#830C04',
      warningMain: '#ED6C02',
      warningBg: '#FDF0E6',
      warningText: '#714118',
      successMain: '#218C0D',
      successText: '#284A22',
      successBg: '#EDFAEB',
      infoMain: '#2B95B9',
      infoBg: '#EBFAFF',
      infoText: '#093C4D',
    },
    shelf: {
      backgroundTana: '#F0F1F5',
      backgroundTanaDark: '#E1E3EB',
      tanaPreview: '#B5B5B5',
      selectOutline: '#C2DAFF',
      selectOverlay: 'rgba(10, 64, 202, 0.4)',
      highlightOutline: 'rgb(1, 136, 233 ,0.32)',
      holeNumber: '#EB004E',
    },
    confidence: {
      low: '#D62A2A', // CommonColors.deepRed
      lowBg: '#FCEFEF',
      lowOverlay: 'rgba(255, 60, 60, 0.45)',
      middle: '#E8A200', // CommonColors.yellow
      high: '#2E992E', // CommonColors.green
      highBg: '#E5F2EB',
      highOverlay: 'rgba(84, 228, 84, 0.45)',
    },
    heatmap: {
      '01_line': '#B23B00', // HeatMapColors.darkRed
      '01_bg_scan': 'rgba(178, 59, 0, 0.65)',
      '01_bg_edit': 'rgba(178, 59, 0, 0.65)',
      '02_line': '#ED4F00', // HeatMapColors.red
      '02_bg_scan': 'rgba(237, 79, 0, 0.65)',
      '02_bg_edit': 'rgba(237, 79, 0, 0.65)',
      '03_line': '#FF8533', // HeatMapColors.orange
      '03_bg_edit': 'rgba(255, 133, 51, 0.5)',
      '03_bg_scan': 'rgba(255, 133, 51, 0.45)',
      '04_line': '#FFB978', // HeatMapColors.lightOrange
      '04_bg_edit': 'rgba(255, 185, 120, 0.5)',
      '04_bg_scan': 'rgba(255, 185, 120, 0.65)',
      '05_line': '#FFEFE5', // HeatMapColors.white
      '05_bg_edit': 'rgba(255, 239, 229, 0.5)',
      '05_bg_scan': 'rgba(255, 239, 229, 0.65)',
    },
    beforeAfter: {
      before: '#3D72C3',
      beforeOverlay: '#354C6F99',
      after: '#FF7E34',
      afterOverlay: '#D9591180',
    },
  },
  typography: {
    headingL: {
      fontSize: '2rem', // 32px
      fontWeight: 700, // bold
    },
    headingM: {
      fontSize: '1.5rem', // 24px
      fontWeight: 700, //bold
    },
    headingS: {
      fontSize: '1.25rem', // 20px
      fontWeight: 700, // bold
    },
    headingXS: {
      fontSize: '1.125rem', // 18px
      fontWeight: 700, // bold
    },
    subtitle1: {
      fontSize: '1rem', // 16px
      fontWeight: 700, //bold
    },
    subtitle2: {
      fontSize: '0.875rem', // 14px
      fontWeight: 700, // bold
    },
    body1: {
      fontSize: '1rem', // 16px
      fontWeight: 400, //regular
    },
    body2: {
      fontSize: '0.875rem', // 14px
      fontWeight: 400, //regular
    },
    body3: {
      fontSize: '0.750rem', // 12px
      fontWeight: 400, //regular
    },
    buttonText1: {
      fontSize: '0.875rem', // 14px
      fontWeight: 700, // bold
    },
    buttonText2: {
      fontSize: '0.750rem', // 12px
      fontWeight: 700, // bold
    },
    caption1: {
      fontSize: '0.6875rem', // 11px
      fontWeight: 400, //regular
    },
    caption2: {
      fontSize: '0.625rem', // 10px
      fontWeight: 400, //regular
    },
  },
  breakpoints: {
    values: {
      //NOTE: default breakpoints besides breakpoint
      xs: 0,
      sm: 600,
      md: 900,
      breakpoint: 1000,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'text' },
          style: {
            ':hover': { backgroundColor: CommonColors.lightGray },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            fontWeight: 'bold',
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            fontWeight: 'bold',
          },
        },
      ],
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#E5F0FF',
            borderColor: CommonColors.appBlue,
            borderTop: `1px solid ${CommonColors.appBlue}`,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(229, 240, 255, 1)',
          },
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { color: 'success' },
          style: {
            backgroundColor: 'rgba(214, 248, 198, 1)',
            color: 'rgba(1, 103, 5, 1)',
            ':hover': { backgroundColor: 'rgba(214, 238, 198, 1)' },
          },
        },
      ],
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          severity: {
            success: {
              backgroundColor: '#EDFAEB',
              color: '#284A22',
            },
            info: {
              backgroundColor: '#EBFAFF',
              color: '#093C4D',
            },
            error: {
              backgroundColor: '#FFEFEF',
              color: '#830C04',
            },
            warning: {
              backgroundColor: '#FDF0E6',
              color: '#714118',
            },
          },
        },
      },
    },
  },
});

// Create the final merged theme
export const theme = createTheme(deepmerge(cleanTheme, customTheme));
