import { FC } from 'react';
import { Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { CloseButton } from '@components/molecules/closeButton/closeButton';
import { Header } from '../header/header';

type Props = {
  name: string;
  handleClose: () => void;
};

export const ThreeDViewHeader: FC<Props> = ({ name, handleClose }) => {
  return (
    <Header>
      <CloseButton onClick={handleClose} />
      <Typography
        sx={{
          flexGrow: 1,
          fontSize: 24,
          fontWeight: 700,
          color: CommonColors.black,
          textAlign: 'center',
        }}
      >
        {name}
      </Typography>
    </Header>
  );
};
