import { StopPropagationButton } from '@components/organisms/stopPropagationButton/stopPropagationButton';
import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import {
  AddCircle,
  Delete,
  RemoveCircle,
  RotateRight,
} from '@mui/icons-material';
import { Button, ButtonGroup, Divider } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC, SyntheticEvent } from 'react';
import { PlanogramProductCompartment, ProductPosition } from 'types/planogram';

type Props = {
  productPosition: ProductPosition;
  target: PlanogramProductCompartment;
  handleClickRotate: (e: SyntheticEvent) => void;
  onDeleteProduct: () => void;
};

export const ProductsButtonGroup: FC<Props> = ({
  productPosition,
  target,
  handleClickRotate,
  onDeleteProduct,
}) => {
  const {
    decrementHorizontalAmount,
    incrementHorizontalAmount,
    removeProducts,
  } = usePlanogramPlan();
  const disableMinus = target?.count?.x === 1;
  return (
    <ButtonGroup
      // NOTE: Prevent closing menu if button is disabled.
      onClick={(e) => e.stopPropagation()}
      sx={{
        height: 48,
        '& .MuiButtonGroup-grouped': {
          color: CommonColors.appBlue,
          border: 0,
          '&:hover': {
            border: 0,
          },
        },
      }}
    >
      <StopPropagationButton
        onClick={() => decrementHorizontalAmount(productPosition)}
        disabled={disableMinus}
      >
        <RemoveCircle
          sx={
            disableMinus
              ? { opacity: 0.26, fill: CommonColors.black }
              : { opacity: 1, fill: CommonColors.appBlue }
          }
        />
      </StopPropagationButton>
      <StopPropagationButton
        onClick={() => incrementHorizontalAmount(productPosition)}
      >
        <AddCircle />
      </StopPropagationButton>
      <Divider
        orientation="vertical"
        component="div"
        flexItem
        sx={{ margin: '10px 0', color: '#D9D9D9' }}
      />
      <Button
        onClick={(e) => {
          e.stopPropagation(); // popperのcloseイベントを防ぐ
          handleClickRotate(e);
        }}
      >
        <RotateRight />
      </Button>
      <Divider
        orientation="vertical"
        component="div"
        flexItem
        sx={{ margin: '10px 0', color: '#D9D9D9' }}
      />
      <Button
        onClick={() => {
          onDeleteProduct();
          removeProducts({ at: productPosition });
        }}
      >
        <Delete />
      </Button>
    </ButtonGroup>
  );
};
