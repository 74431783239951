import { planogramScale } from '@utils/const';
import { useDrag, useDrop, XYCoord } from 'react-dnd';
import { DndData } from 'types/common';
import { Position } from 'types/planogram';
import { ItemTypes } from 'types/rack';
import { usePlanogramPlan } from './usePlanogramPlan';
import {
  closeToShelfStepElement,
  stepIndex as getStepIndex,
} from '@utils/planogram';

type Props = {
  itemType: ItemTypes;
  data?: DndData;
  index?: number;
  rowIndex?: number;
  canDrag?: boolean;
  areaPath?: Position[];
  onDragStart?: (index: number) => void;
  onDragEnd?: () => void;
};

export const useDnD = (props: Props) => {
  const [{ isDragging }, dragRef, preview] = useDrag({
    type: props.itemType,
    item: () => {
      if (props?.onDragStart && props.index?.toString()) {
        props.onDragStart(props.index);
      }
      return {
        data: props.data,
        index: props.index,
        rowIndex: props.rowIndex,
        areaPath: props.areaPath,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      if (props.onDragEnd) {
        props.onDragEnd();
      }
    },
    canDrag: props.canDrag,
  });

  const [{ handlerId }, dropRef] = useDrop({
    accept: [ItemTypes.ITEM, ItemTypes.ITEM_GROUP],
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    drop: () => {
      return { hoverIndex: props.index, rowIndex: props.rowIndex };
    },
  });
  return {
    handlerId,
    dropRef,
    dragRef,
    isDragging,
    preview,
  };
};

type BayPartDragItem = DndData['bayPart'] & {
  rowIndex: number;
  isCompared: boolean;
};

//TODO: 抽象化
export const useBayPartDnD = (arg?: BayPartDragItem) => {
  const { rowIndex, elevation } = arg ?? {};
  const { moveBayPart, plan } = usePlanogramPlan();
  const steps = plan.shelves_frame.detail.shelf_steps;
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.BAY_PART,
      item: { data: { bayPart: arg } },
      canDrag: !arg?.isCompared,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [rowIndex, elevation]
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BAY_PART,
      drop: (item: { data: { bayPart: BayPartDragItem } }, monitor) => {
        const { y } =
          monitor?.getDifferenceFromInitialOffset() ??
          ({ x: 0, y: 0 } as XYCoord);
        const newElevation =
          -y / planogramScale + (item?.data?.bayPart?.elevation ?? 0); //NOTE: Diff from drag start point + elevation of drag start point
        const shelfHeight =
          'detail' in item.data.bayPart &&
          item.data.bayPart.detail.exterior.height
            ? item.data.bayPart.detail.exterior.height
            : 0;

        const closestStepElevation = closeToShelfStepElement(
          newElevation,
          steps
        );
        const stepIndex = getStepIndex(
          closestStepElevation,
          steps.map((el) => el.elevation)
        );
        const stepElevation = steps.at(stepIndex)?.elevation;
        if (!stepElevation || stepElevation < shelfHeight) return;

        moveBayPart(
          { indexX: 0, indexY: item?.data?.bayPart?.rowIndex ?? 0 },
          { elevation: newElevation ?? 0 }
        );
      },
    }),
    [plan]
  );

  return {
    drag,
    drop,
    preview,
    isDragging,
  };
};
