import {
  profitTabGrossProfit,
  profitTabQuantity,
  profitTabSales,
} from '@utils/const';
import { maxValueInReport } from '@utils/product';
import { ProfitTab } from 'types/common';
import { ProductReport } from 'types/products';

export const getMaxValueInReport = (
  profitTab?: ProfitTab,
  productReports?: ProductReport[]
) => {
  if (profitTab === undefined || !productReports) return 0;
  switch (profitTab) {
    case profitTabSales:
      return maxValueInReport('gross_sales_price', productReports);
    case profitTabQuantity:
      return maxValueInReport('sales_count', productReports);
    case profitTabGrossProfit:
      return maxValueInReport('gross_profit_price', productReports);
    default:
      return 0;
  }
};

export const timer = 5000;
export const refetchImageTime = 500;
export const firstShot = 1;
export const secondShot = 2;
