import { paths } from 'types/api';
import { baseApi } from '../baseApi';

export type GetTenantsPublicResponse =
  paths['/api/v1/tenants/public/me']['get']['responses']['200']['content']['application/json'];

export type GetTenantsResponse =
  paths['/api/v1/tenants/private/me']['get']['responses']['200']['content']['application/json'];

export type GetTenantsLocaleResponse =
  paths['/api/v1/tenants/private/locale']['get']['responses']['200']['content']['application/json'];

// see) https://staging.shelf.pfn.dev/docs#/tenants
export const tenantsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTenantsPublic: builder.query<GetTenantsPublicResponse, void>({
      query: () => ({
        url: 'tenants/public/me',
        method: 'GET',
      }),
    }),
    getTenants: builder.query<GetTenantsResponse, void>({
      query: () => ({
        url: 'tenants/private/me',
        method: 'GET',
      }),
    }),
    getTenantsLocale: builder.query<GetTenantsLocaleResponse, void>({
      query: () => ({
        url: 'tenants/private/locale',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetTenantsPublicQuery,
  useGetTenantsQuery,
  useGetTenantsLocaleQuery,
} = tenantsApi;
