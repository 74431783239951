import { Box, Button, Typography } from '@mui/material';
import { updateIsShowBayPartDetail } from '@reducers/planogramEditor/reducer';
import { useGetBayPartQuery } from '@reducers/shelfAppsApi/injections/bayPartCategoriesApi';
import { useAppDispatch } from '@store/index';
import { FC } from 'react';
import { theme } from '../../../theme';

type Props = {
  selectedBayPartId?: number;
  reset: () => void;
  isDisabled?: boolean;
  handleConfirm: () => void;
  isSwappingBayPartMode?: boolean;
  isShowBayPartDetail?: boolean;
  isCompareMode?: boolean;
};

export const BayPartDrawer: FC<Props> = ({
  selectedBayPartId,
  reset,
  isDisabled = false,
  handleConfirm,
  isSwappingBayPartMode,
  isShowBayPartDetail = false,
  isCompareMode = false,
}) => {
  const dispatch = useAppDispatch();

  const { bayPart, isLoading } = useGetBayPartQuery(
    { bay_part_id: selectedBayPartId ?? 0 },
    {
      selectFromResult: ({ data, isFetching, isLoading }) => ({
        bayPart: data?.bay_part,
        isLoading: isLoading || isFetching,
      }),
      skip: !selectedBayPartId,
    }
  );

  return (
    <Box
      component="div"
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        background: theme.palette.white.primary,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
        width: '100%',
      }}
    >
      {isCompareMode && isSwappingBayPartMode && (
        <Box
          component="div"
          display="flex"
          gap={1}
          alignItems="center"
          borderBottom="1px solid #0000001F"
          padding="8px 16px"
        >
          <Typography>{bayPart?.name ?? '-'}</Typography>
          {!isShowBayPartDetail && (
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(updateIsShowBayPartDetail(true));
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                詳細
              </Typography>
            </Button>
          )}
        </Box>
      )}
      <Box
        component="div"
        sx={{
          height: 68,
          px: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {!(isCompareMode && isSwappingBayPartMode) && (
          <Box component="div" display="flex" gap={1} alignItems="center">
            <Typography>{bayPart?.name ?? '-'}</Typography>
            {!isShowBayPartDetail && (
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(updateIsShowBayPartDetail(true));
                }}
              >
                <Typography variant="body2" fontWeight="bold">
                  詳細
                </Typography>
              </Button>
            )}
          </Box>
        )}
        {isSwappingBayPartMode && (
          <Box
            component="div"
            display="flex"
            alignItems="center"
            gap={1}
            marginLeft="auto"
          >
            <Button
              sx={{ color: theme.palette.textBlack.primary, fontWeight: 700 }}
              onClick={reset}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              sx={{ fontWeight: 700 }}
              disabled={isDisabled || isLoading}
              onClick={handleConfirm}
            >
              決定
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
