import { XAxis } from '@components/molecules/xAxis/xAxis';
import { YAxis } from '@components/molecules/yAxis/yAxis';
import { Box } from '@mui/material';
import { convertMeterToPixel, isPlanogramPtsDetail } from '@utils/planogram';
import { FC } from 'react';
import { ShelfDetailMode, ShelfDetailView } from 'types/common';
import { BboxColors, PlanogramPlan } from 'types/planogram';
import { ProductsBay } from '../productsBay/productsBay';

type Props = {
  plan: PlanogramPlan;
  diffColor?: string;
  bboxEnabled?: boolean;
  bboxColors?: BboxColors;
  detailView?: ShelfDetailView;
  detailMode?: ShelfDetailMode;
  isEditor?: boolean;
  isCompared?: boolean;
};

export const Planogram: FC<Props> = ({
  plan,
  diffColor,
  bboxEnabled,
  bboxColors,
  detailMode,
  detailView,
  isEditor,
  isCompared = false,
}) => {
  if (isPlanogramPtsDetail(plan)) {
    console.error('unsupported planogram type');
    return <></>;
  }

  const { width, height } = plan.shelves_frame.detail.exterior;

  return (
    <Box component="div">
      <Box component="div" display="flex">
        <Box component="div" mr={2}>
          <YAxis value={height} />
        </Box>
        <ProductsBay
          plan={plan}
          diffColor={diffColor}
          bboxColors={bboxColors}
          bboxEnabled={bboxEnabled}
          detailMode={detailMode}
          detailView={detailView}
          isEditor={isEditor}
          isCompared={isCompared}
        />
      </Box>
      <Box component="div" display="flex" justifyContent="center">
        <Box component="div" mt={1} width={convertMeterToPixel(width)}>
          <XAxis value={width} />
        </Box>
      </Box>
    </Box>
  );
};
