import { convertMeterToMilliMeter } from '@utils/planogram';
import { Product } from 'types/common';

export const convertSize = (product?: Product) => {
  if (!product?.shape) {
    return '';
  }
  const { width, height, depth } =
    product.shape.size.display_size ?? product.shape.size.actual;
  return `寸法：W${convertMeterToMilliMeter(width)}
× H${convertMeterToMilliMeter(height)}
× D${convertMeterToMilliMeter(depth)}`;
};
