import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const BottleIconVisibilityDisabled: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      fill="none"
      sx={{ width: '20', height: '24' }}
      viewBox="0 0 20 24"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2H2V22H18V2ZM2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.895431 24 2 24H18C19.1046 24 20 23.1046 20 22V2C20 0.895431 19.1046 0 18 0H2ZM7 18H13V19C13 19.5523 12.5523 20 12 20H8C7.44772 20 7 19.5523 7 19V18ZM13 13H7V17H13V13ZM8.5 5C8.5 4.44772 8.94772 4 9.5 4H10.5C11.0523 4 11.5 4.44772 11.5 5V6H8.5V5ZM7.87799 9.01705C8.1576 8.35927 8.41054 7.76424 8.5 7H11.5C11.5895 7.76424 11.8424 8.35927 12.122 9.01706C12.4529 9.7954 12.8211 10.6616 13 12H7C7.17893 10.6616 7.54714 9.79541 7.87799 9.01707L7.87799 9.01706L7.87799 9.01705Z"
        fill="black"
        fillOpacity="0.2"
      />
    </SvgIcon>
  );
};
