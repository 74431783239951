import { RequiredText } from '@components/molecules/requiredText/requiredText';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
} from '@mui/material';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { theme } from 'theme';
import { BayPlanCode } from 'types/bayPlan';
import {
  ChangeStep,
  RealogramFormControl,
  RealogramStoreBayData,
  RealogramType,
} from 'types/realogram';
import { FlatUploadRealogramAlert } from '../alerts/flatUploadRealogramAlert';
import { selectionStoreModal } from '@reducers/selectionStoreModal/selector';
import { useSelector } from 'react-redux';
import { FlatRealogramImages } from '../realogramImage/flatRealogramImages';
import { RealogramFormData } from '@components/pages/realogramCreate/types/realogram';

type Props = {
  firstFile?: File;
  secondFile?: File;
  onSecondFileSelect: (file: File) => void;
  failedDialogOpen: boolean;
  failedDialogOpenForbidden: boolean;
  onSubmit: (data: RealogramFormData) => Promise<void>;
  onFirstFileSelect: (file: File) => void;
  handleCloseFailedDialog: () => void;
  handleCloseFailedDialogForbidden: () => void;
  changeStep: ChangeStep;
  selectedRealogramType: RealogramType;
  onSwapFile: (file: File | undefined, secondFile: File | undefined) => void;
  isFlat: boolean;
  isMd: boolean;
  memoRow: number;
  control: RealogramFormControl;
  storeBayData?: RealogramStoreBayData;
  bayPlanCodes?: BayPlanCode[];
  handleOpenModal: () => void;
};

export const FlatRealogramForm: FC<Props> = ({
  firstFile,
  secondFile,
  onSecondFileSelect,
  onFirstFileSelect,
  onSwapFile,
  control,
  storeBayData,
  handleOpenModal,
}) => {
  const { currentSelectedItem } = useSelector(selectionStoreModal);

  return (
    <Box
      component="div"
      justifyContent="space-between"
      mb={5}
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <FlatRealogramImages
        file={firstFile}
        secondFile={secondFile}
        onSecondFileSelect={onSecondFileSelect}
        onFirstFileSelect={onFirstFileSelect}
        onSwapFile={onSwapFile}
      />
      <Box
        component="div"
        display="flex"
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        justifyContent="space-between"
        px={3}
        gap={4}
      >
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          gap={2}
          flex={1}
        >
          <RequiredText />
          <Controller
            name="storeId"
            control={control}
            render={() => (
              <Box
                component="div"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <InputLabel sx={{ width: '160px', gap: '10px' }}>
                  <Typography variant="body2">店舗 *</Typography>
                </InputLabel>
                <FormControl sx={{ flex: 1 }} size="small">
                  <Box component="div" display="flex" alignItems="center">
                    <Typography
                      sx={{ fontSize: '16px' }}
                      color={theme.palette.textBlack.primary}
                    >
                      {currentSelectedItem?.name || ''}
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={() => handleOpenModal()}
                      sx={{
                        p: '0 8px',
                        width: '102px',
                        height: '40px',
                        fontSize: '14px',
                        ml: `${currentSelectedItem?.name ? '10px' : 0}`,
                        border: `1px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      店舗を選択
                    </Button>
                  </Box>
                </FormControl>
              </Box>
            )}
          />
          <Controller
            control={control}
            name="storeBayId"
            render={({ field }) => (
              <Box
                component="div"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <InputLabel sx={{ width: '160px', gap: '10px' }}>
                  <Typography variant="body2">什器 *</Typography>
                </InputLabel>
                <FormControl sx={{ flex: 1 }} size="small" required>
                  <InputLabel sx={{ fontSize: '0.875rem' }}>什器</InputLabel>
                  <Select label="什器" {...field} sx={{ fontSize: '0.875rem' }}>
                    {storeBayData?.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          />
          {/* 仕様再検討のためコメントアウト */}
          {/* <Controller
            control={control}
            name="bayPlanId"
            render={({ field }) => (
              <Box
                component="div"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <InputLabel sx={{ width: '160px', gap: '10px' }}>
                  <Typography variant="body2">ゴンドラ番号</Typography>
                </InputLabel>
                <FormControl sx={{ flex: 1 }} size="small">
                  <InputLabel sx={{ fontSize: '0.875rem' }}>
                    ゴンドラ番号
                  </InputLabel>
                  <Select label="ゴンドラ番号" {...field}>
                    {bayPlanCodes?.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          />
          <Box
            component="div"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="top"
            gap={2}
          >
            <InputLabel sx={{ width: '160px', gap: '10px', py: '10px' }}>
              <Typography variant="body2">メモ</Typography>
            </InputLabel>
            <Controller
              control={control}
              name="memo"
              render={({ field }) => (
                <TextField
                  sx={{ flex: 1 }}
                  rows={isMd ? memoRow : 1}
                  label="メモ"
                  multiline
                  {...field}
                />
              )}
            />
          </Box> */}
        </Box>
        <FlatUploadRealogramAlert />
      </Box>
    </Box>
  );
};
