import { findProductInSales } from '@utils/planogram';
import { hasProductTag } from '@utils/product';
import { isEqual } from 'lodash';
import { Product, ProductTag, ShelfDetailView } from 'types/common';
import {
  BucketArea,
  BucketPlanogramPlan,
  Position,
  CompartmentBucketPosition,
  PlanogramProductCompartment,
} from 'types/planogram';
import { ProductReportProduct } from 'types/products';
import { RealogramProductCompartment } from 'types/realogram';

export const getBucketCompartmentsByView = (
  view: ShelfDetailView,
  productTag: ProductTag,
  plan?: BucketPlanogramPlan,
  products?: Product[],
  productSales?: ProductReportProduct[]
) => {
  const positions = plan?.frame.detail.buckets?.map((bucket) => {
    const positions: {
      compartment: BucketArea;
      position: Position[];
    }[] = [];
    const getProductsPosition = (area: BucketArea, position: Position[]) => {
      if (area.product_id) {
        positions.push({
          compartment: area,
          position: position.length ? position : [{ indexX: 0, indexY: 0 }],
        });
      }
      if (area.children) {
        area.children.forEach((child, index) => {
          const newPosition = {
            indexX: area.split_axis === 'compartment_y' ? 0 : index,
            indexY: area.split_axis === 'compartment_x' ? 0 : index,
          };
          const newPath =
            child.type !== 'compartment'
              ? [...position, newPosition]
              : position;
          getProductsPosition(child, newPath);
        });
      }
    };
    getProductsPosition(bucket.detail.area, []);
    return positions;
  });
  if (!positions?.length) return;
  if (view === 'productFlag') {
    return positions?.map((position) => {
      return position.filter((el) => {
        const product = products?.find(
          (p) => p.id === el.compartment.product_id
        );
        return hasProductTag(productTag, product?.detail?.tags);
      });
    });
  }
  if (view === 'profit') {
    return positions?.map((position) => {
      return position.filter((el) => {
        const product = products?.find(
          (p) => p.id === el.compartment.product_id
        );
        return !!findProductInSales(product?.id ?? 0, productSales);
      });
    });
  }
  return positions;
};

export const getBucketPosition = (
  products?: Product[],
  positions?: {
    compartment: BucketArea;
    position: Position[];
  }[][]
) => {
  if (!products || !positions) return;

  const productId = products?.at(0)?.id;

  const bucketId = positions.findIndex((positionBucket) =>
    positionBucket.some(
      ({ compartment }) => compartment?.product_id === productId
    )
  );

  if (bucketId === -1) return;

  const positionBucketProduct = positions[bucketId].find(
    ({ compartment }) => compartment?.product_id === productId
  )?.position;

  if (!positionBucketProduct?.length) return;

  return {
    position: positionBucketProduct,
    bucketId,
  };
};

export const getCurrentCompartmentIndex = (
  position: CompartmentBucketPosition[],
  bucketProductPosition: Position[]
) => {
  return position.findIndex((el) => {
    return isEqual(el.position, bucketProductPosition);
  });
};

export const getNewPosition = (
  positions: CompartmentBucketPosition[][],
  parentIndex: number,
  childIndex: number
) => {
  return positions.at(parentIndex)?.at(childIndex);
};

export const countFacesInRow = (
  row: PlanogramProductCompartment[],
  productId?: number
) =>
  row.reduce(
    (rowCount, x) =>
      rowCount + (x.product_id === productId ? x?.face_count ?? 0 : 0),
    0
  );

export const countFacesInCompartment = (
  compartment: RealogramProductCompartment,
  productId?: number
) =>
  compartment.faces.reduce(
    (faceCount, face) =>
      faceCount +
      (!face.is_unknown && face.primary_candidate?.product_id === productId
        ? 1
        : 0),
    0
  );

export const countFacesInCompartments = (
  compartments: RealogramProductCompartment[],
  productId?: number
) =>
  compartments.reduce(
    (compartmentCount, x) =>
      compartmentCount + countFacesInCompartment(x, productId),
    0
  );
