import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  title?: string;
  message?: string;
};

export const ProductEmptyResult: FC<Props> = ({
  title = '結果がありません',
  message = '条件を変更してください。',
}) => {
  return (
    <Box
      component="div"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
      sx={{
        color: '#B5BBBB',
        background: '#E3E3E3',
        border: '1px solid #CCCCCC',
        gap: 1,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          color: '#999999',
          letterSpacing: '0.15px',
        }}
      >
        {title}
      </Typography>
      {message && (
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.textBlack.disabled,
            letterSpacing: '0.15px',
          }}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
};
