import { BayPartCategories } from '@components/organisms/bayParts/fragments/bayPartCategories';
import { ArrowBack } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { BayPartCategory, BayPlanCategory, ChildBayPart } from 'types/bayPlan';

type Props = {
  handleClickBayPartCard: (part: ChildBayPart) => void;
  reset: () => void;
  categoryType: 'categories' | 'list';
  handleClick: (category: BayPartCategory) => void;
  rootCategories?: BayPlanCategory;
  selectedBayPartCategory?: BayPartCategory;
  prevBayPartId?: number;
};

export const BayParts: FC<Props> = ({
  handleClickBayPartCard,
  categoryType,
  handleClick,
  reset,
  rootCategories,
  selectedBayPartCategory,
  prevBayPartId,
}) => {
  return (
    <>
      <Box
        component="div"
        sx={{
          py: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          onClick={reset}
          component="h2"
          sx={{
            fontSize: '16px',
            fontWeight: 700,
            letterSpacing: '0.15px',
            cursor: selectedBayPartCategory ? 'pointer' : 'default',
            display: 'inline-flex',
            alignItems: 'center',
            lineHeight: '175%',
          }}
        >
          {selectedBayPartCategory && (
            <IconButton
              sx={{
                border: `1px solid ${theme.palette.dividerBlack.dark}`,
                borderRadius: '4px',
                width: '32px',
                height: '32px',
                mr: 1,
              }}
            >
              <ArrowBack htmlColor={theme.palette.icons.primary} />
            </IconButton>
          )}
          {selectedBayPartCategory
            ? selectedBayPartCategory.name
            : '什器カテゴリ'}
        </Typography>
      </Box>
      <Divider sx={{ mx: -2 }} />
      <BayPartCategories
        rootCategories={rootCategories}
        categoryType={categoryType}
        handleClick={handleClick}
        handleClickBayPartCard={handleClickBayPartCard}
        selectedBayPartCategory={selectedBayPartCategory}
        prevBayPartId={prevBayPartId}
      />
    </>
  );
};
