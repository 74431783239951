import { FC } from 'react';
import { AlertDialog } from '@components/organisms/alertDialog/alertDialog';
import { Button, DialogContentText } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { Scope } from 'types/sharePermission';

type Props = {
  open: boolean;
  previousSelectedScope: Scope | undefined;
  handleCloseDialog: () => void;
  changeScopeToPrivate: () => void;
  setSelectedScope: (scope: Scope) => void;
};

export const ChangeScopeModal: FC<Props> = ({
  open,
  previousSelectedScope,
  handleCloseDialog,
  changeScopeToPrivate,
  setSelectedScope,
}) => {
  const getContent = () => {
    return (
      <DialogContentText sx={{ color: CommonColors.black }}>
        非公開にすると、権限を付与されたユーザーは全員、棚割を閲覧できなくなります。非公開のファイルにアクセスできるのは、作成者のみです。
      </DialogContentText>
    );
  };

  return (
    <AlertDialog
      open={open}
      title="公開範囲を非公開にします。"
      content={getContent()}
      cancelButton={
        <Button
          onClick={() => {
            if (previousSelectedScope) {
              setSelectedScope(previousSelectedScope);
            }
            handleCloseDialog();
          }}
          sx={{
            color: CommonColors.black,
            ':hover': { bgcolor: CommonColors.lightGray },
          }}
        >
          キャンセル
        </Button>
      }
      confirmButton={
        <Button
          onClick={changeScopeToPrivate}
          sx={{
            color: CommonColors.appRed,
            ':hover': { bgcolor: CommonColors.lightGray },
          }}
        >
          変更
        </Button>
      }
    />
  );
};
