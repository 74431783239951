import { createSelector } from '@reduxjs/toolkit';
import { selectAppState } from '@store/index';

export const selectPlan = createSelector(
  selectAppState,
  (state) => state.FlatPlan.present
);

export const selectHistories = createSelector(selectAppState, (state) => ({
  past: state.FlatPlan.past,
  future: state.FlatPlan.future,
}));

export const selectFlat = createSelector(
  selectPlan,
  (flatPlan) => flatPlan.frame
);

export const selectIsDirty = createSelector(
  selectAppState,
  (state) => state.FlatPlan.cursor !== state.FlatPlan.past.length
);

export const selectBottomBucketsNum = createSelector(
  selectAppState,
  (state) => state.FlatPlan.bottomBucketsNum
);

export const selectIsRotated = createSelector(
  selectAppState,
  (state) => state.FlatPlan.isRotated
);

export const selectBucketProductPosition = createSelector(
  selectAppState,
  (state) => state.FlatPlan.bucketProductPosition
);

export const selectCanSplitVertical = createSelector(
  selectAppState,
  (state) => state.FlatPlan.canSplitVertical
);

export const selectCanSplitHorizontal = createSelector(
  selectAppState,
  (state) => state.FlatPlan.canSplitHorizontal
);
