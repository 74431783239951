import { FullHeightBox } from '@components/molecules';
import { MfaStep as MfaStepComponent } from '@components/pages/mfa/fragments/steps';
import { Box } from '@mui/material';
import { selectEmail } from '@reducers/auth/selectors';
import { useAppSelector } from '@store/index';
import { paths } from '@utils/const';
import { Navigate, useLocation } from 'react-router-dom';

export const Mfa = () => {
  const location = useLocation();
  const locationState = location.state as { email: string };
  const email = locationState?.email;
  const signedInEmail = useAppSelector(selectEmail);
  if (!signedInEmail && !email) return <Navigate to={paths.login} />;

  if (signedInEmail && !email) return <Navigate to={paths.account.root} />;

  return (
    <FullHeightBox flexDirection="column">
      <Box component="div" flex={1} display="flex" alignItems="center">
        <MfaStepComponent email={locationState.email} />
      </Box>
    </FullHeightBox>
  );
};
