import { ErrorRounded } from '@mui/icons-material';
import { Box, Button, Stack, SxProps, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  handleOpenModal: () => void;
  isForbidden?: boolean;
  emptyTopBoxSx?: SxProps;
  emptyBottomBoxSx?: SxProps;
  isDisabled?: boolean;
};

export const SelectComparison: FC<Props> = ({
  handleOpenModal,
  isForbidden = false,
  emptyTopBoxSx = {},
  emptyBottomBoxSx = {},
  isDisabled = false,
}) => {
  return (
    <Box
      component="div"
      flex={1}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Box component="div" sx={{ height: '50px', ...emptyTopBoxSx }} />
      <Box
        component="div"
        sx={{
          flex: 1,
          backgroundColor: theme.palette.shelf.backgroundTanaDark,
        }}
      >
        <Box
          component="div"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Stack gap={1.5} alignItems="center">
            <ErrorRounded
              sx={{
                width: 32,
                height: 32,
                fill: theme.palette.icons.disabled,
              }}
            />
            <Typography
              color={theme.palette.textBlack.disabled}
              variant="subtitle1"
            >
              {isForbidden
                ? 'この棚割のアクセス権がありません'
                : '比較したい棚割を選択してください'}
            </Typography>
            <Button
              sx={{
                maxWidth: '102px',
                whiteSpace: 'nowrap',
                height: '40px',
              }}
              onClick={handleOpenModal}
              variant="contained"
              disabled={isDisabled}
            >
              棚割を選択
            </Button>
          </Stack>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: theme.palette.white.primary,
          height: 56,
          ...emptyBottomBoxSx,
        }}
      />
    </Box>
  );
};
