import { StopPropagationButton } from '@components/organisms/stopPropagationButton/stopPropagationButton';
import { AddCircle, ArrowLeft } from '@mui/icons-material';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';

export const AddIcon: FC<{
  handleClick: () => void;
}> = ({ handleClick }) => {
  return (
    <StopPropagationButton
      sx={{
        color: CommonColors.dark,
        p: 0,
        minWidth: 26,
        '&:hover': { backgroundColor: 'transparent' },
      }}
      onClick={handleClick}
    >
      <ArrowLeft fontSize="small" />
      <AddCircle sx={{ transform: 'translateX(-8px)' }} />
    </StopPropagationButton>
  );
};
