/* eslint-disable react/no-unknown-property -- next lintではエラーになるので抑制 */
import {
  BakeShadows,
  OrbitControls,
  PerspectiveCamera,
} from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import { FC, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import * as THREE from 'three';
import { Model } from './model';
import * as view_set from './view_set';

type Props = {
  idToken: string;
  gltfUrl: string;
  isLoading: boolean;
  finishLoading: () => void;
  viewParams: view_set.View;
  isViewPrint: boolean;
};

type LightProps = {
  params: view_set.Light;
};

type CameraProps = {
  params: view_set.Camera;
};

type BackgroundProps = {
  fog: view_set.Fog;
  isViewPrint: boolean;
};

const semicircleAngle = 180.0;
const toRadian = semicircleAngle * Math.PI;

export const Camera: FC<CameraProps> = ({ params }) => {
  const cameraRef = useRef(null);
  const cameraPosition = new THREE.Vector3(
    params.position.x,
    params.position.y,
    params.position.z
  );
  const cameraRotation = new THREE.Euler(
    toRadian * params.rotation.x,
    toRadian * params.rotation.y,
    toRadian * params.rotation.z,
    'XYZ'
  );
  const orbitControllerParams = params.controller as view_set.OrbitController;
  const orbitTarget = new THREE.Vector3(
    orbitControllerParams.target.x,
    orbitControllerParams.target.y,
    orbitControllerParams.target.z
  );
  return (
    <group>
      <PerspectiveCamera
        makeDefault
        fov={params.fov}
        near={params.near}
        far={params.far}
        position={cameraPosition}
        rotation={cameraRotation}
        ref={cameraRef}
      />
      <OrbitControls
        target={orbitTarget}
        enablePan
        enableZoom
        enableRotate={orbitControllerParams.rotate_enabled}
        maxDistance={orbitControllerParams.max_distance}
        enableDamping={false}
      />
    </group>
  );
};

export const Lights: FC<LightProps> = ({ params }) => {
  const ambientColor = 0xffffff;
  const ambientIntensity = 0.1;
  const shadowMapSize = 2048;
  const spotLightIntensity = 2.0;
  const spotLightAngle = 0.3;
  const directionalLightIntensity = 0.5;
  const spotLightPositionX = 1;
  const spotLightPositionY = 6;
  const spotLightPositionZ = 6;
  const directionalLightPositionX = 8;
  const directionalLightPositionY = 5;
  const directionalLightPositionZ = 5;

  return (
    <group>
      <ambientLight args={[ambientColor, ambientIntensity]} />
      <spotLight
        intensity={spotLightIntensity}
        angle={spotLightAngle}
        position={[spotLightPositionX, spotLightPositionY, spotLightPositionZ]}
        castShadow={params.shadow.enabled}
        shadow-mapSize={[shadowMapSize, shadowMapSize]}
      />
      <directionalLight
        intensity={directionalLightIntensity}
        position={[
          -directionalLightPositionX,
          directionalLightPositionY,
          directionalLightPositionZ,
        ]}
        castShadow={false}
      />
      <directionalLight
        intensity={directionalLightIntensity}
        position={[
          directionalLightPositionX,
          directionalLightPositionY,
          directionalLightPositionZ,
        ]}
        castShadow={false}
      />
    </group>
  );
};

export const Background: FC<BackgroundProps> = ({ fog, isViewPrint }) => {
  const { scene } = useThree();
  const backgroundColor = 'white';
  // react fiberのfogは特殊なタグで、一度設定すると削除するには、直接scene.fogをnullにする必要がある
  if (!fog.enabled) {
    scene.fog = null;
  }
  const gridSize = 50;
  const gridDivision = 50;
  const colorCenterLine = 0x888888;
  const colorGrid = 0xaaaaaa;
  return (
    <>
      <color attach="background" args={[backgroundColor]} />
      {fog.enabled && (
        <fog
          attach="fog"
          color={backgroundColor}
          near={fog.near}
          far={fog.far}
        />
      )}
      {!isViewPrint && (
        <gridHelper
          args={[gridSize, gridDivision, colorCenterLine, colorGrid]}
        />
      )}
    </>
  );
};

export const ThreeCanvas: FC<Props> = ({
  idToken,
  gltfUrl,
  finishLoading,
  isLoading,
  viewParams,
  isViewPrint,
}) => {
  const enableShadow = viewParams.light.shadow.enabled;

  return (
    <Canvas
      shadows={enableShadow}
      frameloop="demand"
      resize={{ polyfill: ResizeObserver }}
      performance={{ min: 0.5 }}
    >
      <Camera params={viewParams.camera} />
      <Model url={gltfUrl} token={idToken} finishLoading={finishLoading} />
      <Background fog={viewParams.fog} isViewPrint={isViewPrint} />
      <Lights params={viewParams.light} />
      {!isLoading && enableShadow && <BakeShadows />}
    </Canvas>
  );
};
