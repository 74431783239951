import { Navigate, useLocation } from 'react-router-dom';
import { FC } from 'react';

export const RemoveTrailingSlash: FC = () => {
  const location = useLocation();
  const regex = new RegExp('/.*/$');

  // If the last character of the url is '/'
  if (regex.test(location.pathname)) {
    return (
      <Navigate
        replace
        to={{
          pathname: location.pathname.replace(/\/+$/, ''),
        }}
      />
    );
  }
  return null;
};
