import { Box, List, ListItemButton, Popover, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { DirectoryType, ParentDirectory } from 'types/common';

type OmitDirectoriesPopoverProps = {
  directories: ParentDirectory[];
  anchorEl: HTMLButtonElement | null;
  id: 'simple-popover' | undefined;
  open: boolean;
  handleClose: () => void;
  handleClick: (directory: DirectoryType) => void;
  directoryRootName?: string;
};

export const OmitDirectoriesPopover: FC<OmitDirectoriesPopoverProps> = ({
  directories,
  id,
  open,
  anchorEl,
  handleClose,
  handleClick,
  directoryRootName = '棚割計画',
}) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <List disablePadding sx={{ width: '240px' }}>
        {directories.map((directory) => (
          <ListItemButton
            key={directory.id}
            divider
            onClick={() => {
              handleClick(directory);
              handleClose();
            }}
          >
            <Box component="div" width="100%">
              <Typography noWrap color={theme.palette.textBlack.primary}>
                {directory.type == 'root' ? directoryRootName : directory.name}
              </Typography>
            </Box>
          </ListItemButton>
        ))}
      </List>
    </Popover>
  );
};
