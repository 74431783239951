import { FC } from 'react';
import { EmptyResult } from '@components/molecules/emptyResult/emptyResult';
import { CircularProgress } from '@mui/material';

type Props = {
  isLoading?: boolean;
  data?: unknown[];
  textSearch?: string;
  type?: string;
  emptyResultBoxTitle?: string;
};

export const SelectionModalResult: FC<Props> = ({
  isLoading,
  textSearch,
  data,
  type,
  emptyResultBoxTitle = 'フォルダが空です',
}) => {
  if (isLoading) {
    return <CircularProgress color="inherit" />;
  }

  if (!data?.length) {
    if (textSearch) {
      return (
        <EmptyResult
          title={`「${textSearch}」が含まれる結果が見つかりませんでした`}
          titleStyle={{
            fontSize: 16,
            fontWeight: 700,
            mb: 0,
            textAlign: 'center',
            padding: '0 20px',
          }}
          message=""
        />
      );
    }

    if (type === 'store') {
      return <EmptyResult title="店舗がありません" message="" />;
    }

    return <EmptyResult title={emptyResultBoxTitle} message="" />;
  }

  return null;
};
