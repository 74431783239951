import {
  BottomDrawer,
  bottomDrawerHeight,
} from '@components/organisms/productCandidatesDrawer/fragments';
import { ProductsSelectOnly } from '@components/organisms/productsSelectOnly/productsSelectOnly';
import { useProductCategories } from '@hooks/useProductCategories';
import { Box, Drawer, Typography } from '@mui/material';
import { FC, useEffect } from 'react';

import { updateResetData } from '@reducers/loadingIndicator';
import { useAppDispatch } from '@store/index';
import type { Product } from 'types/common';
import type {
  ProductCandidateWithName,
  RealogramSelectedItem,
} from 'types/realogram';
import { ProductDetailDrawer } from './productDetailDrawer';

type Props = {
  open: boolean;
  setCloseDrawer: () => void;
  handleProductClick: (productCandidate: ProductCandidateWithName) => void;
  handleChangeCandidate: () => void;
  handleReset?: () => void;
  face?: RealogramSelectedItem;
  isResetData: boolean;
  selectedItemId?: number;
  candidateItem?: ProductCandidateWithName;
  isDisplayProductDetail: boolean;
  setIsDisplayProductDetail: (isDisplayProductDetail: boolean) => void;
  setSelectedCandidate: (candidate?: ProductCandidateWithName) => void;
  isLarger: boolean;
};

export const ProductChoiceDrawer: FC<Props> = ({
  open = false,
  setCloseDrawer,
  handleProductClick,
  handleChangeCandidate,
  handleReset,
  face,
  isResetData,
  selectedItemId,
  candidateItem,
  isDisplayProductDetail,
  setIsDisplayProductDetail,
  setSelectedCandidate,
  isLarger,
}) => {
  const dispatch = useAppDispatch();
  const productCategoriesController = useProductCategories();
  const candidateItemId = candidateItem?.product_id;
  const handleClose = () => {
    setCloseDrawer();
    productCategoriesController.handleBackResetRoot();
    setIsDisplayProductDetail(false);
  };
  const clearProductState = () => {
    setSelectedCandidate(undefined);
    setIsDisplayProductDetail(false);
  };
  // note: handleChangeCandidate時に状態が残る対応
  useEffect(() => {
    if (isResetData) {
      productCategoriesController.handleBackResetRoot();
      dispatch(updateResetData(false));
    }
  }, [dispatch, isResetData, productCategoriesController]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflowY: 'visible',
          width: isLarger ? '55%' : '720px',
          minWidth: '720px',
        },
      }}
    >
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        width={isLarger ? '100%' : '720px'}
        minWidth="720px"
        height="100%"
        position="relative"
        paddingBottom={`${bottomDrawerHeight}px`}
      >
        <Box
          component="div"
          data-testid="drawerHeader"
          display="flex"
          width="100%"
          gap="10px"
          alignSelf="stretch"
          padding={2}
          alignItems="flex-start"
          sx={{
            boxSizing: 'border-box',
            borderBottom: '1px solid #DDDDDD',
          }}
        >
          <Typography
            component="h3"
            color="#222"
            fontSize="20px"
            lineHeight={1}
            fontWeight={700}
          >
            訂正後の商品を選択
          </Typography>
        </Box>
        <Box
          component="div"
          sx={{
            flex: 1,
            display: 'flex',
            position: 'sticky',
            pb: 2,
          }}
        >
          <ProductsSelectOnly
            {...productCategoriesController}
            drawerOpen={open}
            handleSelectProductClick={(product: Product) => {
              handleProductClick({
                product_id: product.id,
                score: 0,
                confidence_level: 'low',
                name: product.name,
              });
              setIsDisplayProductDetail(candidateItemId !== product.id);
            }}
            handleBack={() => {
              clearProductState();
              productCategoriesController.handleBack();
            }}
            clearProductState={clearProductState}
            candidateItemId={candidateItemId}
          />
        </Box>
      </Box>
      {isDisplayProductDetail && candidateItem && (
        <ProductDetailDrawer candidateItem={candidateItem} />
      )}
      <BottomDrawer
        buttonText="選択"
        handleClose={handleClose}
        handleChangeCandidate={handleChangeCandidate}
        isDisabled={!candidateItemId || selectedItemId === candidateItemId}
        face={face}
        handleReset={handleReset}
      />
    </Drawer>
  );
};
