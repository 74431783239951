import { Box } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { FC } from 'react';
import { PlanogramPlan } from 'types/planogram';
import { Planogram } from '../planogram/planogram';
import { useBreakpoint } from '@hooks/useBreakpoint';

type Props = {
  plan: PlanogramPlan;
  scale: number;
  handleClickAway: () => void;
  isEditor?: boolean;
  bboxEnabled?: boolean;
};

export const ZoomablePlanogram: FC<Props> = ({
  plan,
  scale,
  handleClickAway,
  isEditor,
  bboxEnabled,
}) => {
  const { detailMode, detailView } = useAppSelector(selectPlanogramEditorState);
  const { isLarger } = useBreakpoint();
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        transform: isLarger
          ? `scale(${scale},${scale})`
          : `scale(${scale - 0.1},${scale - 0.1})`,

        minHeight: isLarger ? '100%' : '110%', //NOTE: 110% because scale - 0.1
        transformOrigin: 'top center',
        alignItems: 'center',
        py: 3,
      }}
      onClick={handleClickAway}
    >
      <Planogram
        bboxEnabled={bboxEnabled}
        plan={plan}
        detailMode={detailMode}
        detailView={detailView}
        isEditor={isEditor}
      />
    </Box>
  );
};
