import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const DemoIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 12 12"
      sx={{
        width: '12px',
        height: '12px',
      }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99982 1H7.99982V5.19722L10.5727 9.05662C11.1265 9.88732 10.5311 11 9.53269 11H2.46695C1.46857 11 0.873088 9.88732 1.42688 9.05662L3.99982 5.19722L3.99982 1ZM4.99982 5.5L2.25893 9.61132C2.14817 9.77746 2.26727 10 2.46695 10H9.53269C9.73236 10 9.85146 9.77746 9.7407 9.61132L6.99982 5.5V2H4.99982L4.99982 5.5Z"
      />
      <path d="M8.5 6.5H3.5L1.5 10H10.5L8.5 6.5Z" />
      <rect x="3.5" y="1" width="5" height="1" rx="0.5" />
    </SvgIcon>
  );
};
