import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const CreateNewFolderIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.666 4.99992H9.99935L8.33268 3.33325H3.33268C2.40768 3.33325 1.67435 4.07492 1.67435 4.99992L1.66602 14.9999C1.66602 15.9249 2.40768 16.6666 3.33268 16.6666H16.666C17.591 16.6666 18.3327 15.9249 18.3327 14.9999V6.66659C18.3327 5.74159 17.591 4.99992 16.666 4.99992ZM16.666 14.9999H3.33268V4.99992H7.64102L9.30768 6.66659H16.666V14.9999ZM9.99935 11.6666H11.666V13.3333H13.3327V11.6666H14.9993V9.99992H13.3327V8.33325H11.666V9.99992H9.99935V11.6666Z"
          fill="#0A40CA"
        />
      </svg>
    </SvgIcon>
  );
};
