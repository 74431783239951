import { PrimaryCandidateScore } from '@components/molecules/primaryCandidateScore/primaryCandidateScore';
import { RealogramFaceImage } from '@components/molecules/realogramFaceImage/realogramFaceImage';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { getProductDisplayValues, isInferredAsProduct } from '@utils/product';
import { FC } from 'react';
import { Product } from '../../../types/common';
import { RealogramCandidateFace } from '../../../types/realogram';
import { DynamicImage } from '../dynamicImage/dynamicImage';

type Props = {
  referenceImage: string;
  product?: Product;
  handleOpenReferenceImagePreview: () => void;
  face: RealogramCandidateFace;
  isProductUnknown?: boolean;
  setSelectedProductZoomIn: (product?: Product) => void;
};

export const DrawerHeader: FC<Props> = ({
  referenceImage,
  product,
  handleOpenReferenceImagePreview,
  face,
  isProductUnknown,
  setSelectedProductZoomIn,
}) => {
  const { name, eanCode } = getProductDisplayValues(face, product);

  return (
    <Box component="div" display="flex">
      <Box
        component="div"
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
        marginRight="10px"
      >
        <Box
          component="div"
          width={60}
          height={60}
          position="relative"
          sx={{
            border: '1px solid #CCCCCC',
            borderRadius: isProductUnknown ? '4' : '0',
            marginRight: !isProductUnknown ? '-1px' : '4px',
            cursor: 'zoom-in',
          }}
        >
          {!!referenceImage && (
            <DynamicImage
              src={referenceImage}
              layout="fill"
              objectFit="contain"
              onClick={() => {
                handleOpenReferenceImagePreview();
                if (!isProductUnknown) setSelectedProductZoomIn(product);
              }}
            />
          )}
          {isProductUnknown && (
            <ZoomInIcon
              sx={{
                zIndex: 999,
                fontSize: 12,
                position: 'absolute',
                right: '4px',
                bottom: '4px',
                color: '#838583',
              }}
            />
          )}
        </Box>
        <Box
          component="div"
          width={60}
          height={60}
          sx={{
            border: '1px solid #CCCCCC',
            borderRadius: isProductUnknown ? '4' : '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: !isProductUnknown ? 'zoom-in' : '',
          }}
          onClick={() => {
            if (isProductUnknown) return;
            handleOpenReferenceImagePreview();
            setSelectedProductZoomIn(product);
          }}
        >
          <Box
            component="div"
            sx={{
              width: 56,
              height: 56,
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <RealogramFaceImage product={product} face={face} baseSize={56} />
            {!isProductUnknown && (
              <ZoomInIcon
                sx={{
                  color: '#838583',
                  zIndex: 999,
                  fontSize: 12,
                  position: 'absolute',
                  right: '4px',
                  bottom: '4px',
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box component="div" width="100%">
        <Typography
          variant="body2"
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
            WebkitBoxOrient: 'vertical',
          }}
        >
          {name}
        </Typography>
        {isInferredAsProduct(face) && (
          <Box
            component="div"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              noWrap
              variant="caption"
              color={CommonColors.grayLight}
              mr="9px"
            >
              JAN：{eanCode}
            </Typography>
            <PrimaryCandidateScore face={face} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
