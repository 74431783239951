import { HelpOutline, Refresh } from '@mui/icons-material';
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { emptyText } from '@utils/const';
import { FC, useState } from 'react';
import { theme } from 'theme';
import { Pointer, StoreArea } from 'types/products';
import { WeekSwitcher } from '../weekSwitcher/weekSwitcher';
import { ShelfDetailMode } from 'types/common';

type Props = {
  value: string;
  profits: string[];
  pointers: Pointer[];
  selectedProfitType: string;
  selectedPointerType: StoreArea;
  category: string;
  term: string;
  hasRefreshButton?: boolean;
  handleChangeProfitValue: (profit: string) => void;
  handleRefreshValue?: () => void;
  isPlans?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  analyticsDataTerm?: string;
  disableChangeToPreviousWeek?: boolean;
  disableChangeToNextWeek?: boolean;
  isLoadingAnalyticsData?: boolean;
  handleNextWeekTerm?: () => void;
  handlePreviousWeekTerm?: () => void;
  detailMode?: ShelfDetailMode;
};

export const StatisticsValueSelector: FC<Props> = ({
  value,
  profits,
  pointers,
  selectedPointerType,
  selectedProfitType,
  category,
  term,
  hasRefreshButton = false,
  handleChangeProfitValue,
  handleRefreshValue,
  //remove after backend fixed type store for planograms
  isPlans = true,
  disabled = false,
  isLoading = false,
  analyticsDataTerm,
  disableChangeToPreviousWeek,
  disableChangeToNextWeek,
  handleNextWeekTerm,
  handlePreviousWeekTerm,
  isLoadingAnalyticsData = false,
  detailMode,
}) => {
  const [infoOpen, setInfoOpen] = useState(false);
  return (
    <Box
      component="div"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      minHeight="32px"
      flex={1}
      position="relative"
    >
      <Box component="div" display="flex" alignItems="center">
        <FormControl
          disabled={disabled}
          sx={{
            '&:hover': {
              backgroundColor: theme.palette.backgroundBlack.hover,
              borderRadius: '4px',
            },
          }}
        >
          <Select
            size="small"
            variant="standard"
            disableUnderline
            value={selectedProfitType}
            onChange={({ target: { value } }) => {
              handleChangeProfitValue(value);
            }}
            sx={{
              py: '6px',
              fontSize: '14px',
              textAlign: 'left',
              color: theme.palette.textBlack.primary,
              pb: 0,
              pl: 1,
              '& .MuiSelect-icon': {
                width: '20px',
                height: '20px',
                top: '50%',
                transform: 'translateY(-40%)',
              },
              '& .MuiSelect-select': {
                backgroundColor: 'transparent !important',
              },
            }}
          >
            {profits.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  value={item}
                  sx={{ color: theme.palette.textBlack.primary }}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Divider
          orientation="vertical"
          sx={{
            borderRightWidth: '2px',
            color: theme.palette.dividerBlack.medium,
            height: '16px',
          }}
        />
        <FormControl
          disabled={disabled}
          sx={{
            '&:hover': {
              backgroundColor: theme.palette.backgroundBlack.hover,
              borderRadius: '4px',
            },
          }}
        >
          <Select
            size="small"
            variant="standard"
            disableUnderline
            value={selectedPointerType}
            sx={{
              py: '6px',
              fontSize: '14px',
              textAlign: 'left',
              color: theme.palette.textBlack.primary,
              pb: 0,
              pl: 1,
              '& .MuiSelect-icon': {
                width: '20px',
                height: '20px',
                top: '50%',
                transform: 'translateY(-40%)',
              },
              '& .MuiSelect-select': {
                backgroundColor: 'transparent !important',
              },
            }}
          >
            {pointers.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  value={item.value}
                  sx={{
                    color: theme.palette.textBlack.primary,
                  }}
                >
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {!isPlans && (
          <>
            <Divider
              orientation="vertical"
              sx={{
                borderRightWidth: '2px',
                color: theme.palette.dividerBlack.medium,
                height: '16px',
              }}
            />
            <WeekSwitcher
              disableChangeToNextWeek={disableChangeToNextWeek}
              disableChangeToPreviousWeek={disableChangeToPreviousWeek}
              term={analyticsDataTerm}
              handleNextWeekTerm={handleNextWeekTerm}
              handlePreviousWeekTerm={handlePreviousWeekTerm}
              isLoadingAnalyticsData={isLoadingAnalyticsData}
            />
          </>
        )}
      </Box>
      <Box
        component="div"
        display="flex"
        gap="4px"
        alignItems="center"
        whiteSpace="nowrap"
      >
        <Box component="div" display="flex" flexDirection="column" gap="4px">
          {!isLoading && (
            <>
              <Box
                component="div"
                display="flex"
                gap="4px"
                alignItems="center"
                justifyContent="end"
                textAlign="center"
              >
                <Box
                  component="div"
                  display="flex"
                  flexDirection="column"
                  gap="4px"
                  justifyContent="space-between"
                >
                  <Box
                    component="div"
                    display="flex"
                    flexDirection="row"
                    gap="4px"
                    alignItems="center"
                    justifyContent="flex-end"
                    textAlign="center"
                    flexWrap="nowrap"
                  >
                    <Typography
                      padding="4px"
                      sx={{
                        ...theme.typography.caption2,
                        lineHeight: '100%',
                        color: isPlans
                          ? '#046E46'
                          : theme.palette.textBlack.secondary,
                        background: isPlans ? '#0AA06A1A' : '#0000001A',
                      }}
                    >
                      {category}
                    </Typography>
                    {value && term ? (
                      <Typography
                        sx={{
                          ...theme.typography.subtitle1,
                          lineHeight: '100%',
                          color: isPlans
                            ? '#0AA06A'
                            : theme.palette.textBlack.primary,
                        }}
                      >
                        {value}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          ...theme.typography.body3,
                          lineHeight: '100%',
                          color: theme.palette.textBlack.disabled,
                        }}
                      >
                        {emptyText}
                      </Typography>
                    )}
                  </Box>
                  {isPlans && detailMode === 'default' && (
                    <Typography
                      variant="body3"
                      sx={{
                        lineHeight: '12px',
                        color: theme.palette.textBlack.secondary,
                      }}
                    >
                      {term}
                    </Typography>
                  )}
                </Box>
                <Box
                  component="div"
                  onMouseEnter={() => setInfoOpen(true)}
                  onMouseLeave={() => setInfoOpen(false)}
                  mt="1px"
                >
                  <HelpOutline
                    sx={{
                      fontSize: '14px',
                      color: isPlans
                        ? '#0AA06A'
                        : theme.palette.textBlack.secondary,
                    }}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
        {hasRefreshButton && (
          <IconButton
            disabled={disabled}
            onClick={handleRefreshValue}
            sx={{
              border: `1px solid ${theme.palette.dividerBlack.dark}`,
              borderRadius: '4px',
              width: '32px',
              height: '32px',
              ml: 1,
            }}
          >
            <Refresh
              sx={{
                color: disabled
                  ? theme.palette.icons.disabled
                  : theme.palette.icons.primary,
              }}
            />
          </IconButton>
        )}
        {infoOpen && (
          <Box
            component="div"
            sx={{
              color: '#fff',
              backgroundColor: theme.palette.overlay.dark,
              width: '240px',
              height: isPlans ? '48px' : '68px',
              position: 'absolute',
              top: '30px',
              right: '0px',
              px: '8px',
              py: '4px',
              borderRadius: '4px',
              zIndex: 10,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                textAlign: 'start',
              }}
            >
              {isPlans
                ? '最新のデータをもとに予測された、棚割計画の売上金額です。'
                : '設定した期間のデータをもとに算出された、画像内棚割の売上金額です。'}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
