import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

export const RotateHorizontalIcon: FC<SvgIconProps> = (props) => {
  const color = props.htmlColor ?? theme.palette.icons.primary;

  return (
    <SvgIcon
      {...props}
      sx={{
        width: '24px',
        height: '24px',
        viewBox: '0 0 24 24',
        fill: 'none',
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4V14.5H11V4H13ZM13 17.3673V21H11V17.3673C9.02055 17.2243 7.21108 16.6604 5.81315 15.7867C4.19695 14.7766 3 13.2531 3 11.4032C3 9.544 4.19495 8.02487 5.84728 7H4L4 5H9V10H7V8.63707C5.65087 9.43521 5 10.46 5 11.4032C5 12.3147 5.59391 13.2912 6.87314 14.0907C8.13829 14.8814 9.9483 15.4032 12 15.4032C14.0517 15.4032 15.8617 14.8814 17.1269 14.0907C18.4061 13.2912 19 12.3147 19 11.4032C19 10.4579 18.3351 9.42613 17 8.63517V10H15V5H20V7H18.1499C19.8173 8.03158 21 9.54543 21 11.4032C21 13.2531 19.803 14.7766 18.1869 15.7867C16.7889 16.6604 14.9795 17.2243 13 17.3673Z"
        fill={color}
        fillOpacity="0.65"
      />
    </SvgIcon>
  );
};
