import { ArrowUpwardDownwardIcon } from '@components/molecules/arrowUpwardDownwardIcon/arrowUpwardDowneardIcon';
import {
  Box,
  Divider,
  SxProps,
  TableCell,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import {
  GetProductsParamsExtended,
  ProductOrder,
  ProductOrderBy,
} from 'types/products';

export type ProductsColumnProps = {
  headerName: string;
  headerSubName?: string;
  orderBy?: ProductOrderBy;
  headerSx?: SxProps;
  hasDivider?: boolean;
};

type Props = {
  columns: ProductsColumnProps[];
  handleChangeOrder?: (order: ProductOrder) => void;
  // condition will be replaced to Redux state value, after it will be added. It is only for Stories purpose
  condition: GetProductsParamsExtended;
};

export const ProductsTableHeader: FC<Props> = ({
  columns,
  condition,
  handleChangeOrder = () => {
    return;
  },
}) => {
  return (
    <TableRow
      sx={{
        backgroundColor: '#F6FAFF',
        width: '100%',
        height: '48px',
      }}
    >
      {columns.map((item, index) => {
        const orderBy = item.orderBy;
        const isCurrentOrderBy = condition?.firstOrder?.startsWith(
          orderBy ?? ''
        );
        // 現在のソートカテゴリと異なる時は必ずascとなる。
        const currentOrder =
          isCurrentOrderBy && condition?.firstOrder?.endsWith('desc')
            ? 'desc'
            : 'asc';
        const nextOrder =
          isCurrentOrderBy && condition?.firstOrder?.endsWith('asc')
            ? 'desc'
            : 'asc';
        return (
          <TableCell
            key={index}
            sx={item.headerSx}
            sortDirection={currentOrder}
          >
            <Box component="div" display="flex" gap="16px" alignItems="center">
              {item.hasDivider && (
                <Box component="div" display="flex" width="2px" height="14px">
                  <Divider orientation="vertical" flexItem />
                </Box>
              )}
              <Box component="div">
                {orderBy ? (
                  <TableSortLabel
                    hideSortIcon={!isCurrentOrderBy}
                    active={isCurrentOrderBy}
                    direction={currentOrder}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      '& .MuiTableSortLabel-icon': {
                        width: 20,
                        height: 20,
                      },
                    }}
                    onClick={() => {
                      handleChangeOrder(`${orderBy}_${nextOrder}`);
                    }}
                  >
                    <Typography color="gray" fontSize="14px">
                      {item.headerName}
                    </Typography>
                    {!isCurrentOrderBy && (
                      <Box component="div" width={20} height={20}>
                        <ArrowUpwardDownwardIcon />
                      </Box>
                    )}
                  </TableSortLabel>
                ) : (
                  <Typography color="gray" fontSize="14px">
                    {item.headerName}
                  </Typography>
                )}
                {item.headerSubName && (
                  <Typography color="gray" fontSize="14px">
                    {item.headerSubName}
                  </Typography>
                )}
              </Box>
            </Box>
          </TableCell>
        );
      })}
    </TableRow>
  );
};
