import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const RotateYIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      sx={{
        width: '24px',
        height: '24px',
        viewBox: '0 0 24px 24px',
        fill: 'none',
      }}
    >
      <rect
        width="10"
        height="2"
        transform="matrix(4.37114e-08 1 1 -4.37114e-08 11 4)"
        fill="#444444"
      />
      <rect
        width="3"
        height="2"
        transform="matrix(4.37114e-08 1 1 -4.37114e-08 11 17)"
        fill="#444444"
      />
      <path
        d="M7.38264 6.91483C5.33601 7.82044 4 9.31193 4 10.9985C4 13.7599 7.58172 15.9985 12 15.9985C16.4183 15.9985 20 13.7599 20 10.9985C20 9.09384 18.2961 7.43796 15.7891 6.59375"
        stroke="#444444"
        strokeWidth="2"
      />
      <path d="M4 6L8 6L8 10" stroke="#444444" strokeWidth="2" />
    </SvgIcon>
  );
};
