import { CancelButton } from '@components/molecules/cancelButton/cancelButton';
import { StyledButton } from '@components/molecules/styledButton/styledButton';
import { CreateNewFolder } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { FC, ReactNode } from 'react';
import { theme } from 'theme';

type Props = {
  handleCreateNewFolder: () => void;
  handleClose: () => void;
  handleMove: () => void;
  disabled?: boolean;
  actionBtnIcon?: ReactNode;
  isCreateFolderDisabled?: boolean;
};

export const Footer: FC<Props> = ({
  handleCreateNewFolder,
  handleClose,
  handleMove,
  disabled = false,
  actionBtnIcon,
  isCreateFolderDisabled = false,
}) => {
  return (
    <>
      <Button
        sx={{
          cursor: 'pointer',
          color: theme.palette.action.active,
          width: 40,
          height: 40,
          minWidth: 0,
          border: `solid 1px ${theme.palette.dividerBlack.dark}`,
          borderRadius: '4px',
          background: theme.palette.white.primary,
        }}
        onClick={handleCreateNewFolder}
        disabled={isCreateFolderDisabled}
      >
        <CreateNewFolder />
      </Button>
      <Box component="div" display="flex" gap={1} alignItems="center">
        <CancelButton onClick={handleClose} />
        <StyledButton
          width="auto"
          startIcon={actionBtnIcon}
          disabled={disabled}
          type="contained"
          onClick={handleMove}
        >
          ここに移動
        </StyledButton>
      </Box>
    </>
  );
};
