//TODO; 正規表現のみを定数し、引数にregexを受け取るようにするか検討する

import { UserRole } from 'types/user';
import { string } from 'yup';
import { userRoleTags } from './const';

const regexps = {
  alphabet: /[a-z]/i,
  lowercase: /[a-z]/,
  uppercase: /[A-Z]/,
  numeric: /[0-9]/,
  symbol: /[!-/:-@¥[-`{-~]/,
};

const alnumAndSynbolsSchema = string()
  .required()
  .matches(regexps.alphabet)
  .matches(regexps.numeric)
  .matches(regexps.symbol);

const uppercaseAndLowercaseSchema = string()
  .required()
  .matches(regexps.lowercase)
  .matches(regexps.uppercase);

export const hasHalfWidthAlphanumericAndSymbols = (value: string): boolean =>
  alnumAndSynbolsSchema.isValidSync(value);

export const hasUpperAndLowerCase = (value: string): boolean =>
  uppercaseAndLowercaseSchema.isValidSync(value);

export const isValidLength = (value: string, length = 11) => {
  const schema = string().required().min(length);
  return schema.isValidSync(value);
};

export const isValidPassword = (value: string) =>
  [
    hasHalfWidthAlphanumericAndSymbols(value),
    hasUpperAndLowerCase(value),
    isValidLength(value),
  ].every((e) => e);

export const isMfaRegistered = (
  value: Partial<{
    id_token: string;
    refresh_token: string;
    access_token: string;
  }> &
    Partial<{
      challenge_name: string;
      session: string;
    }>
) => 'challenge_name' in value;

export const roles: UserRole[] = ['user', 'admin'];

export const getUserRole = (role: UserRole) => {
  const userRole = userRoleTags.find((tag) => tag.key === role);

  return userRole?.value;
};
