import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const TriangleIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M2 22 L11 2 L20 22" />
    </SvgIcon>
  );
};
