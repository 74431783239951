import { useState } from 'react';
import {
  defaultScale,
  maxZoomScale,
  minZoomScale,
  zoomScaleStep,
} from '@utils/const';

export const useZoomController = () => {
  const [zoomScale, setZoomScale] = useState(defaultScale);
  const handleIncrementZoom = () => {
    if (zoomScale.toFixed(1) >= maxZoomScale.toFixed(1)) return;
    setZoomScale(zoomScale + zoomScaleStep);
  };
  const handleDecrementZoom = () => {
    if (zoomScale.toFixed(1) <= minZoomScale.toFixed(1)) return;
    setZoomScale(zoomScale - zoomScaleStep);
  };
  const handleTriggerZoom = () => {
    if (zoomScale !== defaultScale) {
      setZoomScale(defaultScale);
    } else {
      setZoomScale(maxZoomScale);
    }
  };
  return {
    handleDecrementZoom,
    handleIncrementZoom,
    handleTriggerZoom,
    zoomScale,
    setZoomScale,
  };
};
