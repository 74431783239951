import { FC } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Box, Button, TextField } from '@mui/material';
import { InferType } from 'yup';
import { RequiredText } from '@components/molecules/requiredText/requiredText';
import { FullHeightBox, ErrorMessage } from '@components/molecules';
import { PasswordValidationList, AppBar } from '@components/organisms';
import { FormPaper } from './formPaper';
import { passwordSchema, toErrorMessage } from '../../utils/password';
import { usePasswordChangeForm } from '../../hooks/usePasswordChangeForm';

type Props = {
  error: FetchBaseQueryError;
  isLoading: boolean;
  onSubmit: (data: InferType<typeof passwordSchema>) => void;
  handleClose: () => void;
};

export const PasswordChangeForm: FC<Props> = ({
  error,
  isLoading,
  onSubmit,
  handleClose,
}) => {
  const { register, handleSubmit, errors, isValid, newPassword } =
    usePasswordChangeForm();

  return (
    <FullHeightBox flexDirection="column">
      <AppBar title="棚アプリ" />
      <Box component="div" flex={1} display="flex" alignItems="center">
        <FormPaper title="パスワードを変更">
          {error && <ErrorMessage message={toErrorMessage(error)} />}
          <RequiredText />
          <Box
            component="form"
            display="flex"
            flexDirection="column"
            gap={3}
            mt={0.5}
            pt={2}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <TextField
              {...register('currentPassword')}
              required
              fullWidth
              label="現在のパスワード"
              size="small"
              type="password"
              autoComplete="current-password"
              error={!!errors.currentPassword}
              helperText={errors.currentPassword?.message}
              inputProps={{ sx: { px: '12px', py: '7px' } }}
            />
            <Box component="div" display="flex" flexDirection="column" gap={1}>
              <TextField
                {...register('newPassword')}
                required
                fullWidth
                label="新しいパスワード"
                size="small"
                type="password"
                autoComplete="new-password"
                error={!!errors.newPassword}
                helperText={errors.newPassword?.message}
                inputProps={{ sx: { px: '12px', py: '7px' } }}
              />
              <PasswordValidationList value={newPassword} />
            </Box>
            <TextField
              {...register('newPasswordConfirmation')}
              required
              fullWidth
              label="新しいパスワード（確認）"
              size="small"
              type="password"
              autoComplete="new-password"
              error={!!errors.newPasswordConfirmation}
              helperText={errors.newPasswordConfirmation?.message}
              inputProps={{ sx: { px: '12px', py: '7px' } }}
            />
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading || !isValid}
            >
              パスワードを設定
            </Button>
            <Button sx={{ mt: 1 }} onClick={handleClose}>
              キャンセル
            </Button>
          </Box>
        </FormPaper>
      </Box>
    </FullHeightBox>
  );
};
