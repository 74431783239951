import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const BottleTopIcon: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 80 80"
      sx={{ height: props.height, width: props.width }}
      fill="none"
    >
      <rect x="7" y="7" width="66" height="66" rx="33" fill="white" />
      <rect x="27" y="27" width="26" height="26" rx="13" fill="#DCDCDC" />
      <rect x="29" y="29" width="22" height="22" rx="11" fill="#E8E8E8" />
    </SvgIcon>
  );
};
