import { SidebarValue, getQueryParams } from '@utils/const';
import { useCallback } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

type Props<T> = {
  allowedFirstOrder?: string[];
  allowedFirstOrderInViewedTab?: string[];
  paths: {
    folders: string;
    foldersStarred: string;
    foldersRecentlyScanned: string;
    foldersRecentlyViewed: string;
  };
  defaultFirstOrderBySideBar?: Record<SidebarValue, T | undefined>;
};

export const useRerenderingSidebar = <T>({
  allowedFirstOrder = [],
  allowedFirstOrderInViewedTab = [],
  paths,
  defaultFirstOrderBySideBar = {} as Record<SidebarValue, T | undefined>,
}: Props<T>) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;

  const sidebarValue: SidebarValue = (() => {
    switch (pathName) {
      case paths.foldersStarred:
        return 'favorite';
      case paths.foldersRecentlyScanned:
        return 'latest';
      case paths.foldersRecentlyViewed:
        return 'viewed';
      default:
        return 'all';
    }
  })();

  const isFilteredByFavorite = sidebarValue === 'favorite';
  const isFilteredByLatest = sidebarValue === 'latest';
  const isFilteredByViewed = sidebarValue === 'viewed';

  const navigateBySidebarValue = (
    value: SidebarValue,
    urlSearchParams?: URLSearchParams
  ) => {
    const pathsMap: Record<SidebarValue, string> = {
      all: paths.folders,
      favorite: paths.foldersStarred,
      latest: paths.foldersRecentlyScanned,
      viewed: paths.foldersRecentlyViewed,
    };

    const path = pathsMap[value];
    if (path) {
      const queryParams = getQueryParams(urlSearchParams || searchParams);
      navigate(`${path}${queryParams}`);
    }
  };

  const defaultFirstOrder = defaultFirstOrderBySideBar[sidebarValue];

  // 昇降順を取得
  const getFirstOrderQueryParamsForCurrentSideBar = useCallback(() => {
    const firstOrder = searchParams.get('first_order');

    const isInvalidFirstOrder =
      !firstOrder ||
      ![...allowedFirstOrder, ...allowedFirstOrderInViewedTab].includes(
        firstOrder
      );
    const isInvalidFirstOrderInViewedTab =
      isFilteredByViewed &&
      !allowedFirstOrderInViewedTab.includes(firstOrder as string);

    const isInvalidFirstOrderInOtherTabs =
      !isFilteredByViewed &&
      allowedFirstOrderInViewedTab.includes(firstOrder as string);

    if (
      isInvalidFirstOrder ||
      isInvalidFirstOrderInViewedTab ||
      isInvalidFirstOrderInOtherTabs
    ) {
      return defaultFirstOrder;
    }
    return firstOrder as T;
  }, [
    allowedFirstOrder,
    allowedFirstOrderInViewedTab,
    defaultFirstOrder,
    isFilteredByViewed,
    searchParams,
  ]);

  return {
    sidebarValue,
    isFilteredByFavorite,
    isFilteredByLatest,
    isFilteredByViewed,
    defaultFirstOrderBySideBar,
    navigateBySidebarValue,
    getFirstOrderQueryParamsForCurrentSideBar,
  };
};
