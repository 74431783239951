import { Button } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

export const CancelButton: FC<{ handleClose: () => void }> = ({
  handleClose,
}) => {
  return (
    <Button
      onClick={handleClose}
      sx={{
        color: theme.palette.textBlack.primary,
        ':hover': { bgcolor: theme.palette.backgroundBlack.hover },
      }}
    >
      キャンセル
    </Button>
  );
};
