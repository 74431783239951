import { CircularSpinner } from '@components/molecules';
import { ComparisonProduct } from '@components/molecules/comparisonProduct/comparisonProduct';
import { ComparisonStatistics } from '@components/organisms/comparisonStatistics/comparisonStatistics';
import { SearchModal } from '@components/organisms/searchModal/searchModal';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useComparisonItemModal } from '@hooks/useComparisonItemModal';
import { useExtractRealogramData } from '@hooks/useExtractRealogramData';
import { useInitSelectedPlanogram } from '@hooks/useInitSelectedPlanogram';
import { useRealogramAnalyticsData } from '@hooks/useRealogramAnalyticsData';
import { useUpdateUrlQueryParamsOfDetailPages } from '@hooks/useUpdateUrlQueryParamsOfDetailPages';
import { ArrowBackIos } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import {
  CurrentSelectedType,
  setCurrentTab,
  updateCurrentSelectedItemId,
  updateCurrentSelectedType,
  updateSelectedItemId,
} from '@reducers/comparisonItemModal';
import { selectComparisonItemModal } from '@reducers/comparisonItemModal/selectors';
import { selectModalProps } from '@reducers/modal/selector';
import {
  updateProductPosition,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { selectRealogramSelectedItem } from '@reducers/realogramCandidate/selector';
import {
  useGetProductQuery,
  useListProductsBulkQuery,
} from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { ComparisonTabModal, planogramRightSideHeight } from '@utils/const';
import {
  calcPlanStatistics,
  getBucketsProductIds,
  getProductsLayout,
  isPlanogramBucketPlan,
  isPlanogramShelvesDetail,
} from '@utils/planogram';
import { isInferredAsProduct } from '@utils/product';
import { calculateRealogramStatistics, isSelectedItem } from '@utils/realogram';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { theme } from 'theme';
import { Product, ShelfDetailView } from 'types/common';
import { Planogram, PlanogramPlan } from 'types/planogram';
import { RealogramSelectedItem } from 'types/realogram';
import { RealogramOrPlanogramImages } from './realogramOrPlanogramImages';
import { PlanogramProvider } from '@hooks/usePlanogramProvider';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';

type Props = {
  planogram?: Planogram;
  plan: PlanogramPlan;
  products?: Product[];
  isTenantSalesAnalytics?: boolean;
  initRerenderingSelectedProduct?: boolean;
  setInitRerenderingSelectedProduct: (value: boolean) => void;
  compareQueryParams?: {
    id: number;
    type: CurrentSelectedType;
  };
};

export const Comparison: FC<Props> = ({
  planogram,
  products,
  plan,
  isTenantSalesAnalytics,
  initRerenderingSelectedProduct,
  setInitRerenderingSelectedProduct,
  compareQueryParams,
}) => {
  const dispatch = useAppDispatch();
  const { isLarger } = useBreakpoint();
  const {
    productPosition,
    selectedProductCompartment,
    detailView,
    detailMode,
    rateValue,
    productTag,
    profitTab,
  } = useAppSelector(selectPlanogramEditorState);
  const { open: isOpenModal } = useSelector(selectModalProps);
  const { currentSelectedItemId, currentSelectedType } = useAppSelector(
    selectComparisonItemModal
  );
  const selectedRealogramItem = useAppSelector(selectRealogramSelectedItem);
  const { data: selectedRealogramProduct } = useGetProductQuery(
    {
      productId: selectedRealogramItem?.item.primary_candidate?.product_id ?? 0,
    },
    { skip: !selectedRealogramItem?.item.primary_candidate?.product_id }
  );
  const handleClickBack = () => {
    dispatch(updateProductPosition(undefined));
    dispatch(updateSelectedProductCompartment(undefined));
    dispatch(updateSelectedRealogramItem(undefined));
  };
  const handleClickRealogramBbox = (item: RealogramSelectedItem) => {
    dispatch(updateProductPosition(undefined));
    dispatch(updateSelectedProductCompartment(undefined));
    if (isSelectedItem(detailView, item, selectedRealogramItem)) {
      dispatch(updateSelectedRealogramItem(undefined));
      return;
    }
    dispatch(updateSelectedRealogramItem(item));
  };
  const { updateModeQueryParams } = useUpdateUrlQueryParamsOfDetailPages();
  const [modalOpen, setModalOpen] = useState(false);
  const hasDivider = (isLarger: boolean, view: ShelfDetailView) => {
    if (!isLarger) return false;
    if (view === 'default') return true;
    return false;
  };
  const originRealogramCandidateId =
    planogram?.link?.origin_realogram_candidate_id;
  const originPlanogramId = planogram?.link?.origin_planogram_id;
  const {
    isLoading,
    comparedPlanogram,
    isForbidden,
    fetchGetPlanogram,
    comparedRealogram,
  } = useComparisonItemModal(
    currentSelectedType === 'actual' ||
      (originRealogramCandidateId && !currentSelectedItemId?.toString())
      ? 'actual'
      : ''
  );
  const {
    analyticsDataTerm: comparedAnalyticsDataTerm,
    displayAnalyticsData: displayComparedAnalyticsData,
    disableChangeToPreviousWeek: disableChangeToComparedPreviousWeek,
    disableChangeToNextWeek: disableChangeToComparedNextWeek,
    isLoadingAnalyticsData: isLoadingComparedAnalyticsData,
    handleNextWeekTerm: handleComparedNextWeekTerm,
    handlePreviousWeekTerm: handleComparedPreviousWeekTerm,
  } = useRealogramAnalyticsData(
    comparedRealogram?.id ?? 0,
    !comparedRealogram || !isTenantSalesAnalytics || comparedRealogram?.id === 0
  );

  const comparedRealogramShelfboards =
    comparedRealogram?.detail?.products_shelves.shelf_boards;

  useEffect(() => {
    if (originRealogramCandidateId && !currentSelectedItemId?.toString()) {
      dispatch(updateSelectedItemId(originRealogramCandidateId));
      dispatch(updateCurrentSelectedItemId(originRealogramCandidateId));
      dispatch(updateCurrentSelectedType('actual'));
      dispatch(setCurrentTab(ComparisonTabModal.REALOGRAM_TAB));
      updateModeQueryParams(
        detailMode,
        detailView,
        productTag,
        profitTab,
        rateValue,
        {
          selectedItem: `actual-${originRealogramCandidateId}`,
        }
      );
      void fetchGetPlanogram(originRealogramCandidateId, 'actual');
    } else if (originPlanogramId && !currentSelectedItemId?.toString()) {
      dispatch(updateSelectedItemId(originPlanogramId));
      dispatch(updateCurrentSelectedItemId(originPlanogramId));
      dispatch(updateCurrentSelectedType('plan'));
      dispatch(setCurrentTab(ComparisonTabModal.PLANOGRAM_TAB));
      updateModeQueryParams(
        detailMode,
        detailView,
        productTag,
        profitTab,
        rateValue,
        {
          selectedItem: `plan-${originPlanogramId}`,
        }
      );
      void fetchGetPlanogram(originPlanogramId, 'plan');
    } else if (currentSelectedItemId?.toString() && currentSelectedType) {
      void fetchGetPlanogram(currentSelectedItemId, currentSelectedType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- updateModeQueryParams only updates queryParams the first time the page loads, Avoid falling into an infinite loop
  }, [
    currentSelectedItemId,
    currentSelectedType,
    detailMode,
    detailView,
    dispatch,
    fetchGetPlanogram,
    originPlanogramId,
    originRealogramCandidateId,
    productTag,
    profitTab,
    rateValue,
  ]);

  // First drawing: Item information is compared
  useEffect(() => {
    const { id: queryId, type: queryType } = compareQueryParams || {};
    const shouldSkip = !queryId || !queryType || isOpenModal;
    if (shouldSkip) return;

    if (queryType === 'plan') {
      dispatch(setCurrentTab(ComparisonTabModal.PLANOGRAM_TAB));
    } else if (queryType === 'actual') {
      dispatch(setCurrentTab(ComparisonTabModal.REALOGRAM_TAB));
    }
    dispatch(updateSelectedItemId(queryId));
    dispatch(updateCurrentSelectedItemId(queryId));
    dispatch(updateCurrentSelectedType(queryType));
  }, [compareQueryParams, isOpenModal, dispatch]);

  const comparisonProductIds = () => {
    if (comparedPlanogram) {
      if (isPlanogramBucketPlan(comparedPlanogram.plan)) {
        return (comparedPlanogram.plan.frame.detail.buckets ?? [])
          .flatMap((bucket) => getBucketsProductIds(bucket.detail.area))
          .sort()
          .join(',');
      }
      if (isPlanogramShelvesDetail(comparedPlanogram.plan)) {
        return getProductsLayout(comparedPlanogram.plan)
          .flatMap(({ row }) => row.map(({ product_id }) => product_id))
          .sort()
          .join(',');
      }
    }

    if (comparedRealogramShelfboards) {
      return comparedRealogramShelfboards
        ?.flatMap((shelfboard) =>
          shelfboard.compartments.flatMap((compartment) =>
            compartment.faces
              .filter((face) => isInferredAsProduct(face))
              .map((face) => face.primary_candidate?.product_id)
          )
        )
        .sort()
        .join(',');
    }
    return '';
  };

  const { filteredShelfBoards: comparedShelfBoards } = useExtractRealogramData(
    detailView,
    productTag,
    displayComparedAnalyticsData?.products ?? [],
    rateValue,
    comparedRealogramShelfboards
  );
  const { planogramEstimatedData } = useEstimatedProfit({ planogram });
  const { planogramEstimatedData: comparedPlanogramEstimatedData } =
    useEstimatedProfit({ planogram: comparedPlanogram });
  const { data: comparisonProductsBulk } = useListProductsBulkQuery(
    { productIds: comparisonProductIds(), shape: true, detail: true },
    { skip: comparisonProductIds() === '' }
  );

  const statistics = useMemo(
    () => calcPlanStatistics(plan, detailView, productTag, products),
    [plan, productTag, detailView, products]
  );

  const comparisonStatistics = useMemo(() => {
    if (comparedPlanogram) {
      return calcPlanStatistics(
        comparedPlanogram?.plan,
        detailView,
        productTag,
        comparisonProductsBulk?.products
      );
    }
    if (comparedRealogramShelfboards) {
      return calculateRealogramStatistics(
        comparedRealogramShelfboards,
        comparisonProductsBulk?.products
      );
    }
  }, [
    comparedPlanogram,
    comparedRealogramShelfboards,
    detailView,
    productTag,
    comparisonProductsBulk?.products,
  ]);

  const comparedReport = useMemo(() => {
    if (comparedPlanogram) {
      return comparedPlanogramEstimatedData?.estimate;
    }
    if (comparedRealogram) {
      return displayComparedAnalyticsData;
    }
  }, [
    comparedPlanogram,
    comparedRealogram,
    displayComparedAnalyticsData,
    comparedPlanogramEstimatedData?.estimate,
  ]);

  const comparedAnalytics = useMemo(() => {
    if (comparedRealogram) {
      return displayComparedAnalyticsData?.products.find(
        (product) =>
          product.product_id ===
            selectedRealogramItem?.item.primary_candidate?.product_id ||
          product.product_id === selectedProductCompartment?.id
      );
    }
    // TODO: add planogram data after api is ready
    if (comparedPlanogram) {
      return;
    }
  }, [
    displayComparedAnalyticsData,
    selectedRealogramItem,
    selectedProductCompartment,
    comparedRealogram,
    comparedPlanogram,
  ]);

  useInitSelectedPlanogram({
    shelfBoards: comparedShelfBoards,
    planogramPlan: plan,
    comparedPlanogramPlan: comparedPlanogram?.plan as PlanogramPlan,
    initSelectedData: {
      isSkipInit:
        !plan.products_layout?.length ||
        initRerenderingSelectedProduct ||
        isLoading ||
        isLoadingComparedAnalyticsData,
      view: detailView,
      productTag,
      onInitCompleted: setInitRerenderingSelectedProduct,
    },
  });

  return (
    <>
      <Divider orientation="horizontal" flexItem />
      <Box
        component="div"
        flex={1}
        display="flex"
        flexDirection={{ xs: 'column', breakpoint: 'row' }}
        overflow="hidden"
      >
        <Box component="div" flex={1} overflow="hidden" display="flex">
          <PlanogramProvider
            value={{
              products,
              comparisonProducts: comparisonProductsBulk?.products,
              comparisonProductSalesReport:
                comparedPlanogramEstimatedData?.estimate,
              productSalesReport: planogramEstimatedData?.estimate,
            }}
          >
            <RealogramOrPlanogramImages
              planogram={planogram}
              currentSelectedType={currentSelectedType}
              comparedPlanogram={comparedPlanogram}
              comparedRealogram={comparedRealogram}
              comparedShelfboards={comparedShelfBoards}
              isLoading={isLoading}
              isForbidden={isForbidden}
              handleClickBbox={handleClickRealogramBbox}
              comparisonProductIds={comparisonProductIds()}
              isBucketType={false} // To make header style direction is always 'column'
              isTenantSalesAnalytics={isTenantSalesAnalytics}
              displayComparedAnalyticsData={displayComparedAnalyticsData}
              comparedAnalyticsDataTerm={comparedAnalyticsDataTerm}
              disableChangeToComparedPreviousWeek={
                disableChangeToComparedPreviousWeek
              }
              disableChangeToComparedNextWeek={disableChangeToComparedNextWeek}
              handleComparedNextWeekTerm={handleComparedNextWeekTerm}
              handleComparedPreviousWeekTerm={handleComparedPreviousWeekTerm}
              isLoadingComparedAnalyticsData={isLoadingComparedAnalyticsData}
            />
          </PlanogramProvider>
        </Box>
        <Divider orientation={isLarger ? 'vertical' : 'horizontal'} flexItem />
        <Box
          component="div"
          p={2}
          minWidth={{ xs: 'unset', breakpoint: '254px' }}
          maxWidth={{ xs: 'unset', breakpoint: '254px' }}
          overflow="auto"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          maxHeight={{
            xs: planogramRightSideHeight,
            breakpoint: '100%',
          }}
          height="100%"
        >
          {productPosition ||
          selectedRealogramItem ||
          selectedProductCompartment ? (
            <Box component="div">
              <Button
                variant="outlined"
                sx={{
                  width: '72px',
                  height: '32px',
                  padding: '0 12px',
                  border: `1px solid ${theme.palette.primary.main}`,
                  '& .MuiButton-startIcon': { margin: '0' },
                }}
                startIcon={
                  <ArrowBackIos
                    sx={{
                      color: theme.palette.primary.main,
                      width: '1rem',
                      height: '1rem',
                    }}
                    fontSize="small"
                  />
                }
                onClick={handleClickBack}
              >
                戻る
              </Button>
              {(selectedProductCompartment ||
                selectedRealogramProduct ||
                selectedRealogramItem) && (
                <>
                  <ComparisonProduct
                    view={detailView}
                    product={
                      selectedProductCompartment ||
                      selectedRealogramProduct?.product
                    }
                    selectedRealogramItem={selectedRealogramItem}
                    planogram={planogram}
                    comparisonPlanogram={comparedPlanogram}
                    comparisonRealogram={comparedRealogramShelfboards}
                    comparedAnalyticsData={comparedAnalytics}
                  />
                  {hasDivider(isLarger, detailView) && (
                    <Divider sx={{ mx: -2, mt: 2 }} />
                  )}
                </>
              )}
            </Box>
          ) : (
            <Box component="div" height="100%">
              {isLoading && (
                <CircularSpinner
                  sx={{ display: 'flex', alignItems: 'center' }}
                />
              )}
              {!isLoading && (
                <ComparisonStatistics
                  statistics={statistics}
                  comparisonStatistics={comparisonStatistics}
                  isComparedWithPlanogram={!comparedRealogram}
                  comparedReport={comparedReport}
                  report={planogramEstimatedData?.estimate}
                  isPlanPage
                />
              )}
            </Box>
          )}
          {detailMode === 'comparison' &&
            (detailView === 'default' || detailView === 'productFlag') && (
              <Box
                component="div"
                display="flex"
                gap="4px"
                alignItems="center"
                justifyContent={isLarger ? 'flex-start' : 'flex-end'}
              >
                <Box
                  component="div"
                  borderRadius="2px"
                  width={16}
                  height={16}
                  sx={{
                    backgroundColor: theme.palette.confidence.low,
                  }}
                />
                <Typography
                  color={theme.palette.textBlack.secondary}
                  fontSize={12}
                >
                  片方のみにあるアイテム
                </Typography>
              </Box>
            )}
        </Box>
      </Box>

      <SearchModal open={modalOpen} handleClose={() => setModalOpen(false)} />
    </>
  );
};
