import { CircularSpinner } from '@components/molecules';
import NextImage, { ImageProps } from 'next/legacy/image';
import { FC } from 'react';

//NOTE: to eliminate type Static Import of src
type Props = Omit<ImageProps, 'src'> & {
  src?: string;
  isLoading?: boolean;
  isDisableToucCallout?: boolean;
  error?: string;
};

export const DynamicImage: FC<Props> = ({
  src,
  isLoading,
  error,
  isDisableToucCallout,
  ...props
}) => {
  if (error) {
    return <NextImage {...props} src="noImage.png" alt="noImage" />;
  }
  if (isLoading || !src) {
    return <CircularSpinner />;
  }
  return (
    <NextImage
      {...props}
      src={src}
      style={{
        // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
        WebkitBackfaceVisibility: 'hidden',
        // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
        WebkitTransform: 'translateZ(0)',
        transform: 'translateZ(0)',
        // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
        WebkitTouchCallout: isDisableToucCallout ? 'none' : undefined,
      }}
    />
  );
};
