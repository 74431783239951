import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { setIsSuccess } from '@reducers/sharePermission';
import { useAppDispatch } from '@store/index';
import { CommonColors } from '@utils/colors';
import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { theme } from 'theme';
import {
  DirectoryRoleUserInfo,
  DirectoryType,
  PostDirectoryRoleRequest,
  ShareRole,
} from 'types/sharePermission';
import { User } from 'types/user';
import { InferType, object, string } from 'yup';

type Props = {
  directoryId: string;
  directoryType: DirectoryType;
  isSuccess: boolean;
  errorMessage: string;
  setErrorMessage: (message: string) => void;
  handleAddDirectoryRole: (
    params: Omit<PostDirectoryRoleRequest, 'user_id'> & {
      email: string;
    }
  ) => void;
  userInfo?: User;
  directoryOwnerInfo?: DirectoryRoleUserInfo;
  userEmailsHasPermission: string[];
};

const schema = object({
  email: string().email().required(),
  role: string().required(),
});

type FormData = InferType<typeof schema>;

export const PermissionForm: FC<Props> = ({
  directoryId,
  directoryType,
  isSuccess,
  errorMessage,
  setErrorMessage,
  handleAddDirectoryRole,
  directoryOwnerInfo,
  userEmailsHasPermission,
}) => {
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isValid },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      role: '',
    },
  });

  const [email, role] = watch(['email', 'role']);

  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [currentRole, setCurrentRole] = useState<string>('');

  const onSubmit: SubmitHandler<FormData> = ({ email, role }) => {
    if (!email || !role) return;

    setCurrentEmail(email);
    setCurrentRole(role);

    if (email === directoryOwnerInfo?.user.email) {
      return setErrorMessage('作成者のため、権限を変更できません。');
    } else if (userEmailsHasPermission.includes(email)) {
      return setErrorMessage('すでに追加されているユーザーです。');
    }
    handleAddDirectoryRole({
      directory_id: directoryId,
      directory_type: directoryType,
      role: role as ShareRole,
      email: email,
    });
  };

  const isDisabledButton = !isValid || !!errorMessage;
  const borderButtonColor = isDisabledButton
    ? theme.palette.backgroundBlack.disabled
    : theme.palette.primary.main;

  const backgroundButtonColor = isDisabledButton
    ? theme.palette.backgroundBlack.disabled
    : theme.palette.white.primary;

  useEffect(() => {
    if ((email !== currentEmail || role !== currentRole) && errorMessage) {
      setErrorMessage('');
      setCurrentRole('');
      setCurrentEmail('');
    }

    if (!errorMessage && currentEmail && currentRole && isSuccess) {
      dispatch(setIsSuccess(false));
      setCurrentEmail('');
      reset();
    }
  }, [
    email,
    currentEmail,
    errorMessage,
    isSuccess,
    setErrorMessage,
    reset,
    dispatch,
    role,
    currentRole,
  ]);

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        marginBottom: '14px',
      }}
    >
      <FormControl sx={{ flex: '0 1 256px' }} size="small">
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <>
              <TextField
                {...field}
                label="メールアドレス"
                type="email"
                inputMode="email"
                size="small"
                error={!!errorMessage}
                sx={{
                  '& .MuiFormHelperText-root': {
                    color: CommonColors.appRed,
                  },
                  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${CommonColors.appRed} !important`,
                  },
                  '& .Mui-error .MuiInputBase-input': {
                    backgroundColor: `${theme.palette.system.errorBg}`,
                  },
                }}
              />
              {errorMessage && (
                <FormHelperText
                  error={!!errorMessage}
                  sx={{ marginLeft: '12px', marginRight: '12px' }}
                >
                  {errorMessage}
                </FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>

      <FormControl
        sx={{
          flex: '1 0 120px',
          marginLeft: '13px',
          '& .Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: `${CommonColors.appRed} !important`,
          },
          '& .Mui-error .MuiInputBase-input': {
            backgroundColor: `${theme.palette.system.errorBg}`,
          },
        }}
        size="small"
      >
        <InputLabel
          id="role-label"
          sx={{ color: errorMessage ? CommonColors.appRed : undefined }}
        >
          権限
        </InputLabel>
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              label="権限"
              labelId="role-label"
              error={!!errorMessage}
            >
              <MenuItem value="editor">編集者</MenuItem>
              <MenuItem value="viewer">閲覧者</MenuItem>
            </Select>
          )}
        />
      </FormControl>

      <Button
        type="submit"
        variant="outlined"
        disabled={isDisabledButton}
        sx={{
          marginLeft: '13px',
          height: '40px',
          p: 1,
          borderColor: borderButtonColor,
          backgroundColor: backgroundButtonColor,
        }}
      >
        追加
      </Button>
    </Box>
  );
};
