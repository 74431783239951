import { useState } from 'react';
import { openToast } from '@reducers/toast';
import { selectAccessToken } from '@reducers/auth/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useDeleteMfaDeviceMutation } from '@reducers/shelfAppsApi';
import { toastMessages } from '@utils/const';

export const useDeleteMfa = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(selectAccessToken);
  const [isDeleteMfaOpen, setIsDeleteMfaOpen] = useState(false);
  const [deleteMfaDevice, { isLoading: isDeleteMfaLoading }] =
    useDeleteMfaDeviceMutation();

  const handleDeleteMfa = async () => {
    try {
      await deleteMfaDevice({ accessToken }).unwrap();
      setIsDeleteMfaOpen(false);
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.deleteMfa,
        })
      );
    } catch (err) {
      console.error(err);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.deleteMfa,
        })
      );
    }
  };

  return {
    isDeleteMfaOpen,
    isDeleteMfaLoading,
    setIsDeleteMfaOpen,
    handleDeleteMfa,
  };
};
