import { subject } from '@casl/ability';
import { NotFound } from '@components/NotFound';
import { usePageTitle } from '@hooks/usePageTitle';
import { forget } from '@reducers/planogramEditor/reducer';
import { useGetPlanogramQuery } from '@reducers/shelfAppsApi';
import { embed, paths } from '@utils/const';
import { isPlanogramBucketPlan } from '@utils/planogram';
import httpStatus from 'http-status';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Planogram } from '../../../types/planogram';
import { FlatPlanogramDetail } from './flatPlanogramDetail/flatPlanogramDetail';
import { ShelfPlanogramDetail } from './shelfPlanogramDetail/shelfPlanogramDetail';
import { setComparisonSameIds } from '@reducers/product';

export const PlanogramDetail: FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const planogramId = id ? parseInt(id) : 0;
  const { data, error } = useGetPlanogramQuery(
    { planogramId },
    { skip: typeof id === 'undefined', refetchOnMountOrArgChange: true }
  );
  const planogram = useMemo(
    () =>
      data?.planogram &&
      subject('Planogram', Object.assign({} as Planogram, data.planogram)), // reduxから取り出した値がfreezeされているので、新しいオブジェクトを作成する
    [data?.planogram]
  );

  const pageTitle = `${planogram?.name ?? ''} | 棚割計画詳細`;
  usePageTitle(pageTitle);

  const isForbidden =
    !!error && 'status' in error && error.status === httpStatus.FORBIDDEN;
  const embedParam = new URLSearchParams(window.location.search).get('embed');

  const handleNavigateView = () => {
    if (!planogramId) return;
    if (embedParam === embed) {
      navigate(`${paths.plans.preview(planogramId, '2d')}?embed=${embed}`);
    } else navigate(paths.plans.navigateView(planogramId));
  };

  useEffect(() => {
    return () => {
      dispatch(forget());
      dispatch(setComparisonSameIds([]));
    };
  }, [dispatch]);

  if (
    Number.isNaN(Number(id)) ||
    (!!error && 'status' in error && error.status === httpStatus.NOT_FOUND)
  ) {
    return <NotFound title="棚割計画（棚エディタ）" />;
  }

  if (isPlanogramBucketPlan(planogram?.plan)) {
    return (
      <FlatPlanogramDetail
        embedParam={embedParam ?? ''}
        planogram={planogram}
        handleNavigateView={handleNavigateView}
        isForbidden={isForbidden}
      />
    );
  }

  return (
    <ShelfPlanogramDetail
      embedParam={embedParam ?? ''}
      planogram={planogram}
      handleNavigateView={handleNavigateView}
      isForbidden={isForbidden}
    />
  );
};
