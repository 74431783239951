import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { theme } from 'theme';

type TableHead = {
  name: string;
};

type TableRows = {
  name: string;
  value: string;
  targetValue: string;
  diffValue: string;
};

type Props = {
  tableHeads: TableHead[];
  tableRows: TableRows[];
};

export const ProfitAnalysisTable: React.FC<Props> = ({
  tableHeads,
  tableRows,
}) => {
  const getColorFromValue = (val: string) => {
    const num = Number(val.replace(/[^0-9.-]+/g, ''));

    if (num < 0) {
      return theme.palette.confidence.low;
    } else if (num > 0) {
      return theme.palette.confidence.high;
    } else if (num === 0) {
      return theme.palette.textBlack.label;
    } else {
      return theme.palette.textBlack.table;
    }
  };

  return (
    <TableContainer
      sx={{
        borderRadius: '4px',
        border: `1px solid ${theme.palette.dividerBlack.medium}`,
      }}
    >
      <Table sx={{}}>
        <TableHead>
          <TableRow
            sx={{
              '.MuiTableCell-root': {
                padding: '2px 8px',
                fontSize: '12px',
                color: theme.palette.textBlack.table,
                background: theme.palette.backgroundBlack.primary,
                '&:not(:last-child)': {
                  borderRight: `1px solid ${theme.palette.dividerBlack.medium}`,
                },
              },
            }}
          >
            {tableHeads?.map((tableHead) => (
              <TableCell key={tableHead.name} align="right">
                {tableHead.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows?.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                '.MuiTableCell-root': {
                  padding: '4px 8px',
                  fontSize: '12px',

                  '&:not(:last-child)': {
                    borderRight: `1px solid ${theme.palette.dividerBlack.medium}`,
                  },
                  '&:not(:first-of-type)': {
                    fontSize: '14px',
                    fontWeight: 700,
                    color: theme.palette.textBlack.table,
                    width: 'calc(80% / 3)',
                  },
                  '&:last-child': {
                    color: getColorFromValue(row.diffValue),
                  },
                },
                '&:last-child': {
                  '.MuiTableCell-root': {
                    borderBottom: 'none',
                  },
                },
                '&:nth-child(odd)': {
                  background: theme.palette.backgroundBlack.light,
                },
              }}
            >
              <TableCell sx={{ width: '15%', minWidth: '104px' }}>
                {row.name}
              </TableCell>
              <TableCell align="right">{row.value}</TableCell>
              <TableCell align="right">{row.targetValue}</TableCell>
              <TableCell align="right">
                {+row.diffValue > 0 ? `+${row.diffValue}` : row.diffValue}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
