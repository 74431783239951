import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const FolderIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      fill="none"
      sx={{ width: '20px', height: '20px' }}
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        d="M8.33268 3.33325H3.33268C2.41602 3.33325 1.67435 4.08325 1.67435 4.99992L1.66602 14.9999C1.66602 15.9166 2.41602 16.6666 3.33268 16.6666H16.666C17.5827 16.6666 18.3327 15.9166 18.3327 14.9999V6.66659C18.3327 5.74992 17.5827 4.99992 16.666 4.99992H9.99935L8.33268 3.33325Z"
        fill="black"
        fillOpacity="0.28"
      />
    </SvgIcon>
  );
};
