import { toggleModal } from '@reducers/modal';
import { useAppDispatch } from '@store/index';

type ModalProps = ReturnType<typeof toggleModal>['payload']['modalProps'];
type Data = ModalProps['data'] | void;

export const useModal = <T extends Data>(
  type: ModalProps['type'],
  title: ModalProps['title']
) => {
  const dispatch = useAppDispatch();
  return {
    showModal: (data: T) =>
      dispatch(
        toggleModal({
          modalProps: {
            open: true,
            title,
            type,
            data: data ?? undefined,
          },
        })
      ),
  };
};
