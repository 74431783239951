import { RequiredText } from '@components/molecules/requiredText/requiredText';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { theme } from 'theme';
import { schema } from './schema';
import { ProductSizingFormData, SizeTextField } from './sizeTextField';

export type AccordionBodySizingProps = {
  display_size: ProductSizingFormData;
};

// NOTE: 細かい仕様が固まっていないため、バリデーション等は未完全
export const AccordionBodySizing = ({
  display_size: { width, height, depth },
}: AccordionBodySizingProps) => {
  const {
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      width: width,
      height: height,
      depth: depth,
    },
  });

  return (
    <>
      <Box
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Box component="div">
          <RequiredText fontSize="11px" lineHeight="11px" />
          <Box component="div" display="flex" alignItems="center" mt={2}>
            <Controller
              name="width"
              control={control}
              render={({ field }) => (
                <SizeTextField<'width'>
                  error={!!errors.width}
                  label="幅"
                  field={field}
                />
              )}
            />
            <Typography mx={1}>×</Typography>
            <Controller
              name="height"
              control={control}
              render={({ field }) => (
                <SizeTextField<'height'>
                  error={!!errors.height}
                  label="高さ"
                  field={field}
                />
              )}
            />
            <Typography mx={1}>×</Typography>
            <Controller
              name="depth"
              control={control}
              render={({ field }) => (
                <SizeTextField<'depth'>
                  error={!!errors.depth}
                  label="奥行き"
                  field={field}
                />
              )}
            />
            {/* TODO: エラーの挙動について検討 */}
          </Box>
          {(errors.width || errors.height || errors.depth) && (
            <Typography color="error" variant="caption">
              半角数字を入力してください。
            </Typography>
          )}
          {/* TODO: 編集前サイズの単位をミリメートル単位に変換する */}
          <Typography
            fontSize="14px"
            color={theme.palette.textBlack.secondary}
            mt={2}
            width="340px"
          >
            編集前サイズ：幅{width}mm × 高さ{height}mm × 奥行き{depth}mm
          </Typography>
        </Box>
        <Button
          sx={{
            fontWeight: 'bold',
            color: 'lightgray',
          }}
          onClick={() => {
            reset({
              width: width,
              height: height,
              depth: depth,
            });
          }}
        >
          リセット
        </Button>
      </Box>
    </>
  );
};
