import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const BowlBottomIcon: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 80 80"
      sx={{ height: props.height, width: props.width }}
      fill="none"
    >
      <path
        d="M48 7L32 7L34.6729 3.59199C35.9563 1.9557 37.9205 0.999999 40 0.999999C42.0795 0.999999 44.0437 1.9557 45.3271 3.59199L48 7Z"
        fill="#EEEEEE"
      />
      <circle
        cx="40"
        cy="43"
        r="37"
        transform="rotate(-180 40 43)"
        fill="white"
      />
      <circle cx="40" cy="43" r="33.5" fill="#EEEEEE" stroke="#E6E6E6" />
      <circle cx="40" cy="43" r="25" fill="white" />
      <circle
        cx="40"
        cy="43"
        r="13"
        stroke="#E6E6E6"
        fill="white"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};
