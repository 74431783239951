import { Box } from '@mui/material';
import Image from 'next/legacy/image';
import { FC } from 'react';
import {
  ProfitTab,
  Rate,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import { ProductReport } from 'types/products';
import {
  BboxColors,
  RealogramSelectedItem,
  RealogramShelfBoard,
  UnknownProduct,
} from 'types/realogram';
import { Bboxes } from './bboxes';

type Props = {
  markerEnabled: boolean;
  defaultBboxColor?: BboxColors;
  view: ShelfDetailView;
  mode: ShelfDetailMode;
  handleClickBbox: (selectedItem: RealogramSelectedItem) => void;
  handleChangeLoading: () => void;
  rate?: Rate;
  profitTab?: ProfitTab;
  productReports?: ProductReport[];
  comparedProductReports?: ProductReport[];
  comparisonSameIdsSet?: Set<number>;
  isUnknownProductsReviseMode: boolean;
  unknownProductsInfo?: UnknownProduct[];
  height: number;
  width: number;
  realogramImage?: string;
  dataImage: 'comparison-image' | 'original-image';
  setWidth: (width: number) => void;
  setHeight: (height: number) => void;
  imageElement: HTMLImageElement | null;
  ratio: number;
  imageShotBboxes?: RealogramShelfBoard[];
  bboxEnabled: boolean;
  maxThreshold: number;
};

export const ImageAndBbox: FC<Props> = ({
  markerEnabled,
  defaultBboxColor,
  view,
  mode,
  handleClickBbox,
  handleChangeLoading,
  rate,
  profitTab,
  productReports,
  comparisonSameIdsSet,
  isUnknownProductsReviseMode,
  unknownProductsInfo,
  height,
  width,
  realogramImage,
  dataImage,
  setWidth,
  setHeight,
  imageElement,
  ratio,
  imageShotBboxes,
  bboxEnabled,
  maxThreshold,
}) => {
  return (
    <Box
      flex={1}
      component="div"
      height={height}
      width={width}
      position="relative"
    >
      <Image
        data-image={dataImage}
        src={realogramImage ?? ''}
        alt=""
        loading="lazy"
        layout="fill"
        onLoadingComplete={handleChangeLoading}
        objectFit="contain"
        onLoad={() => {
          if (width == 0 || height === 0) {
            setWidth((imageElement?.naturalWidth ?? 0) * ratio);
            setHeight((imageElement?.naturalHeight ?? 0) * ratio);
          }
        }}
      />
      {imageShotBboxes && (
        <Bboxes
          markerEnabled={markerEnabled}
          ratio={ratio}
          bboxEnabled={bboxEnabled}
          shelfBoards={imageShotBboxes}
          defaultBboxColor={defaultBboxColor}
          view={view}
          mode={mode}
          handleClickBbox={handleClickBbox}
          rate={rate}
          maxThreshold={maxThreshold}
          productReports={productReports}
          profitTab={profitTab}
          comparisonSameIdsSet={comparisonSameIdsSet}
          unknownProductsInfo={unknownProductsInfo}
          isUnknownProductsReviseMode={isUnknownProductsReviseMode}
        />
      )}
    </Box>
  );
};
