import { ProductImage } from '@components/organisms/productImage/productImage';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { Product } from 'types/common';
import { ProductCandidateWithName } from 'types/realogram';

type Props = {
  candidateItem: ProductCandidateWithName;
};

export const ProductDetailDrawer: FC<Props> = ({ candidateItem }) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        position: 'absolute',
        bottom: 72,
        left: 0,
        p: 2,
        boxShadow: '0px 2px 8px 0px #00000040',
        background: theme.palette.white.primary,
      }}
    >
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Box
          component="div"
          sx={{
            borderRadius: 2,
            border: `1px solid ${theme.palette.dividerBlack.dark}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px',
            width: 60,
            height: 60,
          }}
        >
          <ProductImage
            product={{ id: candidateItem?.product_id } as Product}
            layout="fixed"
            objectFit="contain"
            width={52}
            height={52}
          />
        </Box>
        <Typography
          variant="body2"
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
            WebkitLineClamp: 2,
            // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
            WebkitBoxOrient: 'vertical',
            mx: 2,
          }}
        >
          {candidateItem?.name ?? '-'}
        </Typography>
      </Box>
    </Box>
  );
};
