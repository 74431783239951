import { CircularSpinner } from '@components/molecules';
import { usePfnMedia } from '@hooks/useImage';
import { CardMedia } from '@mui/material';
import {
  baseSizeDefault,
  baseSizeInCompareMode,
  pathPrefix,
} from '@utils/const';
import { FC } from 'react';

type Props = {
  url?: string;
  isCompareMode?: boolean;
};

export const ProductCategoryImage: FC<Props> = ({ url, isCompareMode }) => {
  const { image, isLoading, error } = usePfnMedia(
    url?.replace(`${pathPrefix}/media`, '') ?? '',
    {
      skip: !url,
    }
  );

  if (isLoading) {
    return <CircularSpinner sx={{ width: 54, height: 54, mr: 1 }} />;
  }
  return (
    <CardMedia
      component="img"
      draggable={false}
      sx={{
        height: isCompareMode ? baseSizeInCompareMode : baseSizeDefault,
        minWidth: 54,
        maxWidth: isCompareMode ? baseSizeInCompareMode : baseSizeDefault,
        objectFit: 'contain',
        mr: 1,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
        WebkitTouchCallout: 'none',
        ...(isCompareMode && { marginRight: '0' }),
      }}
      image={(!image && !isLoading) || !!error ? '/noImage.png' : image}
    />
  );
};
