import { ProductEmptyResult } from '@components/molecules';
import {
  ComparisonGridContainer,
  ComparisonGridItem,
  SelectableCard,
} from '@components/organisms';
import { ComparisonDivider } from '@components/organisms/comparisonDivider/comparisonDivider';
import { HeatMapBar } from '@components/organisms/heatMapBar/heatMapBar';
import { StatisticBox } from '@components/organisms/statisticBox/statisticBox';
import { useUpdateUrlQueryParamsOfDetailPages } from '@hooks/useUpdateUrlQueryParamsOfDetailPages';
import { Box, Divider, Typography } from '@mui/material';
import {
  updateProductTagTab,
  updateProfitTab,
  updateRateValue,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { getComparisonStatisticType } from '@utils/comparison';
import {
  createComparisonProfitValue,
  createDefaultValue,
  createProductTagInformation,
  createRateValues,
} from '@utils/statistics';
import { emptyText } from '@utils/const';
import { FC } from 'react';
import { ProductTag, ProfitTab, Rate, ShelfDetailView } from 'types/common';
import { Statistic } from 'types/statistics';
import { ProductSalesReport } from 'types/products';

type Props = {
  statistics?: Statistic;
  comparisonStatistics?: Statistic;
  isFlatPlanogram?: boolean;
  isComparedWithPlanogram?: boolean;
  comparedReport?: ProductSalesReport;
  view?: ShelfDetailView;
  productTag?: ProductTag;
  rate?: Rate;
  handleChangeProductTag?: (productTag: ProductTag) => void;
  handleChangeRate?: (rate: Rate) => void;
  comparisonProfitTabValue?: ProfitTab;
  setComparisonProfitTabValue?: (profitTab: ProfitTab) => void;
  report?: ProductSalesReport;
  isPlanPage: boolean;
};

export const ComparisonStatistics: FC<Props> = ({
  statistics,
  comparisonStatistics,
  comparedReport,
  isFlatPlanogram = false,
  isComparedWithPlanogram = false,
  view,
  productTag,
  rate,
  report,
  handleChangeProductTag,
  handleChangeRate,
  comparisonProfitTabValue,
  setComparisonProfitTabValue,
  isPlanPage = false,
}) => {
  const dispatch = useAppDispatch();
  const {
    detailView,
    profitTab,
    productTag: planogramProductTag,
    rateValue,
  } = useAppSelector(selectPlanogramEditorState);

  const { updateAttributeQueryParams, updateAnalyticsQueryParams } =
    useUpdateUrlQueryParamsOfDetailPages();

  const defaultValue = createDefaultValue(
    isFlatPlanogram,
    statistics,
    comparisonStatistics,
    isComparedWithPlanogram
  );
  const productTagInformation = createProductTagInformation(
    statistics,
    comparisonStatistics
  );

  const comparisonProfitValue = createComparisonProfitValue(
    report,
    comparedReport
  );

  // change when api is ready for planogram
  const rateValues = createRateValues(
    comparisonStatistics,
    statistics,
    isComparedWithPlanogram
  );
  const scoreDiff = comparisonStatistics?.score?.toString()
    ? (statistics?.score ?? 0) - (comparisonStatistics.score ?? 0)
    : '';
  const scoreStatisticType = getComparisonStatisticType(scoreDiff);

  switch (view || detailView) {
    case 'default':
      return (
        <>
          <Typography variant="subtitle2" fontWeight="700" mb={2}>
            各データの比較
          </Typography>
          <ComparisonGridContainer sx={{ flexWrap: 'nowrap', mb: { sm: 1 } }}>
            {defaultValue.map((defaultInfo, index) => {
              // 計画のページでは欠品区画数と不明な商品フェイス数は表示しない
              if (
                isPlanPage &&
                (defaultInfo.title === '欠品区画数' ||
                  defaultInfo.title === '不明な商品フェイス数')
              )
                return;
              return (
                <ComparisonGridItem
                  key={index}
                  display={{ xs: 'flex', breakpoint: 'block' }}
                >
                  <StatisticBox
                    title={defaultInfo.title}
                    columnType={defaultInfo.columnType}
                    statisticType={defaultInfo.statisticType}
                    prevValue={defaultInfo.prevValue}
                    value={defaultInfo.value}
                    diffValue={defaultInfo.diffValue}
                    isPrevValueEmpty={defaultInfo.isPrevValueEmpty}
                    isValueEmpty={defaultInfo.isValueEmpty}
                  />
                  <ComparisonDivider />
                </ComparisonGridItem>
              );
            })}
          </ComparisonGridContainer>
        </>
      );
    case 'productFlag':
      return (
        <>
          <Typography variant="subtitle2" fontWeight="700" mb={1}>
            各属性のフェイス数
          </Typography>
          <ComparisonGridContainer>
            {productTagInformation.map((productTagInfo, index) => {
              const isSelected = isPlanPage
                ? productTagInfo.title === planogramProductTag
                : productTagInfo.title === productTag;
              return (
                <ComparisonGridItem key={index}>
                  <SelectableCard
                    selected={isSelected}
                    onClick={() => {
                      if (isSelected) return;
                      if (isPlanPage) {
                        updateAttributeQueryParams(
                          productTagInformation[index].title
                        );
                        dispatch(
                          updateProductTagTab(
                            productTagInformation[index].title
                          )
                        );
                      } else {
                        handleChangeProductTag &&
                          handleChangeProductTag(productTagInfo.title);
                      }
                    }}
                  >
                    <StatisticBox {...productTagInfo} />
                  </SelectableCard>
                </ComparisonGridItem>
              );
            })}
          </ComparisonGridContainer>
        </>
      );
    case 'profit':
      return (
        <>
          <ComparisonGridContainer>
            {comparisonProfitValue.map((profit, index) => {
              const isSelected = isPlanPage
                ? profitTab === profit.key
                : comparisonProfitTabValue === profit.key;
              return (
                <ComparisonGridItem key={index}>
                  <SelectableCard
                    onClick={() => {
                      if (isSelected) return;
                      if (isPlanPage) {
                        updateAnalyticsQueryParams(profit.key);
                        dispatch(updateProfitTab(profit.key));
                      } else {
                        setComparisonProfitTabValue &&
                          setComparisonProfitTabValue(profit.key);
                        updateAnalyticsQueryParams(profit.key);
                      }
                    }}
                    selected={isSelected}
                    sx={{ display: 'block', maxWidth: '250px' }}
                  >
                    <StatisticBox
                      title={profit.title}
                      columnType="positive"
                      statisticType={profit.statisticType}
                      percent={profit.percent}
                      prevValue={profit.prevValue || emptyText}
                      value={profit.value || emptyText}
                      diffValue={profit.diff}
                      isPrevValueEmpty={!profit.prevValue}
                      isValueEmpty={!profit.value}
                    />
                    {isSelected && (
                      <Box component="div" mt={1}>
                        <HeatMapBar
                          title="分布指標"
                          min={profit.min}
                          max={profit.max}
                        />
                      </Box>
                    )}
                  </SelectableCard>
                </ComparisonGridItem>
              );
            })}
          </ComparisonGridContainer>
        </>
      );
    case 'rate':
      return (
        <>
          {(rateValue.length !== 0 || rate?.length !== 0) &&
          !isComparedWithPlanogram ? (
            <>
              <ComparisonGridContainer>
                <ComparisonGridItem>
                  <Box
                    component="div"
                    display={{ xs: 'flex', breakpoint: 'block' }}
                    mt={1}
                    mb={2}
                  >
                    <StatisticBox
                      title="スコア"
                      columnType="positive"
                      statisticType={scoreStatisticType}
                      prevValue={
                        comparisonStatistics?.score?.toString() || emptyText
                      }
                      value={statistics?.score?.toString() || emptyText}
                      diffValue={scoreDiff.toString()}
                      isPrevValueEmpty={
                        !comparisonStatistics?.score?.toString()
                      }
                      isValueEmpty={!statistics?.score?.toString()}
                    />
                  </Box>
                  <Divider
                    sx={{
                      mx: -2,
                      my: 2,
                      display: { xs: 'none', breakpoint: 'block' },
                    }}
                  />
                </ComparisonGridItem>
              </ComparisonGridContainer>
              <Typography variant="subtitle2" fontWeight="700" mb={1}>
                各評価のアイテム数
              </Typography>
              <ComparisonGridContainer>
                {rateValues.map(
                  (
                    {
                      item,
                      prevValue,
                      value,
                      columnType,
                      statisticType,
                      isPrevValueEmpty,
                      isValueEmpty,
                    },
                    index
                  ) => {
                    const isSelected = item === rateValue || item === rate;
                    return (
                      <ComparisonGridItem key={index}>
                        <SelectableCard
                          selected={isSelected}
                          onClick={() => {
                            if (isSelected) return;
                            if (isPlanPage) {
                              dispatch(updateRateValue(item));
                            } else {
                              handleChangeRate && handleChangeRate(item);
                            }
                          }}
                        >
                          <StatisticBox
                            title={item}
                            columnType={columnType}
                            statisticType={statisticType}
                            prevValue={prevValue}
                            value={value}
                            diffValue=""
                            isPrevValueEmpty={isPrevValueEmpty}
                            isValueEmpty={isValueEmpty}
                          />
                        </SelectableCard>
                      </ComparisonGridItem>
                    );
                  }
                )}
              </ComparisonGridContainer>
            </>
          ) : (
            <ProductEmptyResult title="" message="表示するものがありません" />
          )}
        </>
      );
    default:
      return <></>;
  }
};
