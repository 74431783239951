import { ActionVisible } from '@components/molecules/actionVisible/actionVisible';
import { BreadcrumbForSearch } from '@components/molecules/breadcrumbForSearch/breadcrumbForSearch';
import { FileIcon } from '@components/molecules/fileIcon/fileIcon';
import { FolderIcon } from '@components/molecules/folderIcon/folderIcon';
import { PlanogramsTableMenu } from '@components/molecules/planogramsTableMenu/planogramsTableMenu';
import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { useBayPlanCodes } from '@hooks/useBayPlanCodes';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useGetPlanogramPermission } from '@hooks/useGetPlanogramPermission';
import { useOrganizationStatuses } from '@hooks/useOrganizationStatuses';
import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, TableCell } from '@mui/material';
import { useAppSelector } from '@store/index';
import { Format, format } from '@utils/date';
import { FC, useState } from 'react';
import { PlanogramDirectory, PlanogramDirectorySearch } from 'types/planogram';
import { PlanogramSharePermissionProps } from 'types/sharePermission';
import { theme } from '../../../../theme';

type Props = {
  isFilteredByViewed: boolean;
  planogramDirectory: PlanogramDirectory | PlanogramDirectorySearch;
  handleOpenDeleteDialog: (planogramDirecotry: PlanogramDirectory) => void;
  handleClonePlanogram: (planogramDirecotry: PlanogramDirectory) => void;
  handleUpdatePlanogramName: (planogramDirectory?: PlanogramDirectory) => void;
  handleUpdateDirectoryName: (planogramDirectory?: PlanogramDirectory) => void;
  handleMoveDirectory: (directory?: PlanogramDirectory) => void;
  handleFavoriteClick: (id: string, isFavorite: boolean) => void;
  handleSharePermission: (item: PlanogramSharePermissionProps) => void;
};

export const TableCells: FC<Props> = ({
  isFilteredByViewed,
  planogramDirectory,
  handleClonePlanogram,
  handleOpenDeleteDialog,
  handleUpdatePlanogramName,
  handleUpdateDirectoryName,
  handleMoveDirectory,
  handleFavoriteClick,
  handleSharePermission,
}) => {
  const { name, updated_at, planogram, type } = planogramDirectory;
  const overFlowSx = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0px ',
  };
  const { bayPlanCode } = useBayPlanCodes(planogram?.bay_plan_code_id);
  const { organizationStatus } = useOrganizationStatuses(
    planogram?.organization_status_id
  );
  const { user: me } = useAppSelector((state) => state.Auth);
  const isStarred = planogramDirectory.favorite?.owner_id === me?.id;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDirectory = planogramDirectory.type === 'directory';
  const { isLarger } = useBreakpoint();
  const { isNotEnable: isNotCanUpdate } = useGetPlanogramPermission({
    action: 'update',
    planogram: planogramDirectory,
    isPlanogram: false,
    isCan: false,
  });
  const { isNotEnable: isNotCanCopy } = useGetPlanogramPermission({
    action: 'copy',
    planogram: planogramDirectory,
    isPlanogram: false,
    isCan: false,
  });
  const { isNotEnable: isNotCanDelete } = useGetPlanogramPermission({
    action: 'delete',
    planogram: planogramDirectory,
    isPlanogram: false,
    isCan: false,
  });
  const { isNotEnable: isNotCanMove } = useGetPlanogramPermission({
    action: 'move',
    planogram: planogramDirectory,
    isPlanogram: false,
    isCan: false,
  });
  const { isNotEnable: isNotCanReadRole } = useGetPlanogramPermission({
    action: 'read_role',
    planogram: planogramDirectory,
    isPlanogram: false,
    isCan: false,
  });
  const { isEnable: isCanUpdate } = useGetPlanogramPermission({
    action: 'update',
    planogram: planogramDirectory,
    isPlanogram: false,
    isCan: true,
  });
  const { isEnable: isCanRead } = useGetPlanogramPermission({
    action: 'read',
    planogram: planogramDirectory,
    isPlanogram: false,
    isCan: true,
  });

  return (
    <>
      <TableCell
        sx={{
          ...overFlowSx,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '56px',
          width: '40px',
          padding: '0px 8px',
        }}
      >
        {type === 'directory' ? <FolderIcon /> : <FileIcon />}
      </TableCell>
      <TableCell
        sx={{
          ...overFlowSx,
          width: '100%',
          padding: '8px',
          fontWeight: 600,
          height: '56px',
        }}
      >
        {name}
        {isStarred && (
          <StarIcon
            htmlColor={theme.palette.confidence.middle}
            sx={{
              verticalAlign: 'text-bottom',
              width: 16,
              height: 16,
              ml: 0.5,
            }}
          />
        )}
        {(planogramDirectory as PlanogramDirectorySearch).breadcrumb && (
          <BreadcrumbForSearch
            breadcrumbs={
              (planogramDirectory as PlanogramDirectorySearch).breadcrumb
            }
            screenType="Main"
            fontSize="12px"
            isCanRead={isCanRead}
          />
        )}
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '32px' }}>
        {bayPlanCode?.name ?? '-'}
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '32px' }}>
        {planogram?.owner.fullname ?? planogramDirectory?.owner?.fullname}
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '32px' }}>
        {format(
          isFilteredByViewed
            ? (planogramDirectory as PlanogramDirectorySearch)
                ?.current_user_accessed_at
            : updated_at,
          Format.datetime
        )}
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '32px' }}>
        {organizationStatus?.label ?? '-'}
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '32px' }}>
        <ActionVisible
          isCanUpdate={isCanUpdate}
          isCanRead={isCanRead}
          isDirectory={isDirectory}
        />
      </TableCell>
      <TableCell
        sx={{
          padding: '0px',
          position: 'sticky',
          right: 0,
          background: theme.palette.white.primary,
          boxShadow: isLarger ? '' : '0px 2px 8px 0px #0000000D',
        }}
        onClick={(e) => {
          // 個別にクリックイベントを設定するため
          e.stopPropagation();
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
            sx={{ width: 24, height: 24 }}
          >
            <MoreVert />
          </IconButton>
        </Box>
        <PlanogramsTableMenu
          handleClonePlanogram={() => {
            setAnchorEl(null);
            handleClonePlanogram(planogramDirectory);
          }}
          handleOpenDeleteDialog={() => {
            setAnchorEl(null);
            handleOpenDeleteDialog(planogramDirectory);
          }}
          handleUpdatePlanogramName={() => {
            setAnchorEl(null);
            handleUpdatePlanogramName(planogramDirectory);
          }}
          handleUpdateDirectoryName={() => {
            setAnchorEl(null);
            handleUpdateDirectoryName(planogramDirectory);
          }}
          handleMoveDirectory={() => {
            setAnchorEl(null);
            handleMoveDirectory(planogramDirectory);
          }}
          handleFavoriteClick={() => {
            handleFavoriteClick(planogramDirectory.id, isStarred);
          }}
          handleClose={() => setAnchorEl(null)}
          handleSharePermission={() => {
            setAnchorEl(null);
            handleSharePermission({
              planogramDirectory: planogramDirectory,
              type: planogramDirectory.type,
            });
          }}
          anchorEl={anchorEl}
          status={planogram?.status}
          isDirectory={isDirectory}
          isStarred={isStarred}
          isDisabledSharePermission={isNotCanReadRole}
          isDisabledDeleteButton={isNotCanDelete}
          isDisabledMoveButton={isNotCanMove}
          isDisabledEditButton={isNotCanUpdate}
          isDisabledDuplicateButton={isNotCanCopy}
        />
      </TableCell>
    </>
  );
};
