import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const BottleFrontIcon: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 80 80"
      sx={{ height: props.height, width: props.width }}
      fill="none"
    >
      <path
        d="M29 54H51L52 56V76C52 78.2091 50.2091 80 48 80H32C29.7909 80 28 78.2091 28 76V56L29 54Z"
        fill="white"
      />
      <path
        d="M35 8C35 7.44772 35.4477 7 36 7H44C44.5523 7 45 7.44772 45 8V14H35V8Z"
        fill="#E8E8E8"
      />
      <path d="M34.5 13H45.5V14H34.5V13Z" fill="#E8E8E8" />
      <mask
        id="mask0_22741_310009"
        maskUnits="userSpaceOnUse"
        x="40"
        y="15"
        width="13"
        height="15"
      >
        <rect
          width="13"
          height="14.9922"
          transform="translate(40 15.0078)"
          fill="#F3F3F3"
        />
      </mask>
      <g mask="url(#mask0_22741_310009)" />
      <mask
        id="mask1_22741_310009"
        maskUnits="userSpaceOnUse"
        x="40"
        y="15"
        width="13"
        height="15"
      >
        <rect
          width="13"
          height="14.9922"
          transform="translate(40 15.0078)"
          fill="#F3F3F3"
        />
      </mask>
      <g mask="url(#mask1_22741_310009)">
        <path
          d="M44 15.0078H35.5L27 30H52C50.5694 22.5954 44.8464 20.0108 44 15.0078Z"
          fill="white"
        />
      </g>
      <mask
        id="mask2_22741_310009"
        maskUnits="userSpaceOnUse"
        x="27"
        y="15"
        width="13"
        height="15"
      >
        <rect
          width="13"
          height="14.9922"
          transform="matrix(-1 0 0 1 40 15.0078)"
          fill="#F3F3F3"
        />
      </mask>
      <g mask="url(#mask2_22741_310009)" />
      <mask
        id="mask3_22741_310009"
        maskUnits="userSpaceOnUse"
        x="27"
        y="15"
        width="13"
        height="15"
      >
        <rect
          width="13"
          height="14.9922"
          transform="matrix(-1 0 0 1 40 15.0078)"
          fill="#F3F3F3"
        />
      </mask>
      <g mask="url(#mask3_22741_310009)">
        <path
          d="M36 15.0078H46.5L53 30H28C29.4306 22.5954 35.1536 20.0108 36 15.0078Z"
          fill="white"
        />
      </g>
      <path d="M52 29H28V52H52V29Z" fill="#D9D9D9" />
      <path d="M30 37H40H50V46H30V37Z" fill="white" />
      <path d="M28 52H52L51 54H29L28 52Z" fill="white" />
    </SvgIcon>
  );
};
