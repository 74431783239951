import { Error } from '@mui/icons-material';
import { Box, Button, Modal, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from '../../../theme';
import { embed } from '@utils/const';

type Props = {
  id?: number;
  open: boolean;
  isScan?: boolean;
  selectedDirectoryId?: string;
  embedParam?: string;
  errorMessage: string;
  buttonMessage: string;
};

export const NotPermittedModal: FC<Props> = ({
  id,
  open,
  isScan,
  selectedDirectoryId,
  errorMessage,
  buttonMessage,
  embedParam,
}) => {
  const navigate = useNavigate();
  const link = isScan
    ? selectedDirectoryId
      ? '/actuals/folders/' + selectedDirectoryId
      : '/actuals/folders'
    : selectedDirectoryId
    ? id
      ? '/plans/' + id.toString()
      : '/plans/folders/' + selectedDirectoryId
    : id
    ? '/plans/' + id.toString()
    : '/plans/folders/';

  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        component="div"
        sx={{
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Error
          sx={{ width: 66, height: 66, color: theme.palette.white.primary }}
        />
        <Typography
          fontSize="20px"
          fontWeight="bold"
          sx={{ color: theme.palette.white.primary }}
        >
          {errorMessage}
        </Typography>
        {embedParam !== embed && (
          <Button variant="contained" onClick={() => navigate(link)}>
            {buttonMessage}
          </Button>
        )}
      </Box>
    </Modal>
  );
};
