import { Grid, GridProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export const ComparisonGridItem: FC<PropsWithChildren<GridProps>> = ({
  children,
  ...props
}) => {
  return (
    <Grid item xs={4} md={3} breakpoint={12} {...props}>
      {children}
    </Grid>
  );
};
