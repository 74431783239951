import { ErrorMessage } from '@components/molecules';
import { FormPaper } from '@components/molecules/formPaper/formPaper';
import { MfaHelp } from '@components/organisms';
import { yupResolver } from '@hookform/resolvers/yup';
import { Help } from '@mui/icons-material';
import { Box, Button, TextField, Typography } from '@mui/material';
import { usePostUserMfaAuthMutation } from '@reducers/shelfAppsApi';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InferType, object, string } from 'yup';

const schema = object({
  mfaToken: string()
    .required('確認コードを入力してください。')
    .matches(/^[0-9]{6}$/, '6桁の数字を入力してください。'),
});

type FormData = InferType<typeof schema>;

export const MfaInputForm: FC<{
  session: { session: string; email: string };
  navigateLogin: () => void;
}> = ({ session, navigateLogin }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const [showHelp, setShowHelp] = useState(false);
  const [mfaAuth, { isLoading, isError }] = usePostUserMfaAuthMutation();

  const onSubmit = async (data: FormData) => {
    const { mfaToken } = data;
    try {
      await mfaAuth({
        challengeName: 'SOFTWARE_TOKEN_MFA',
        session: session.session,
        username: session.email,
        mfaToken,
      }).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  const mfaToken = watch('mfaToken');

  if (showHelp)
    return (
      <MfaHelp
        isInitial
        prev={() => setShowHelp(false)}
        navigateLogin={navigateLogin}
      />
    );

  return (
    <FormPaper title="確認コード入力">
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        onSubmit={handleSubmit(onSubmit)}
        gap={3}
        noValidate
      >
        <Typography>
          登録済みの二要素認証のアプリケーションで表示された確認コードを入力してください。
        </Typography>
        {isError && (
          <ErrorMessage message="無効な確認コードです。もう一度お試しください。" />
        )}
        <TextField
          label="確認コード"
          size="small"
          type="text"
          inputProps={{
            inputMode: 'numeric',
          }}
          autoComplete="one-time-code"
          {...register('mfaToken')}
          error={!!errors.mfaToken}
          helperText={errors.mfaToken?.message}
        />
        <Button
          variant="contained"
          type="submit"
          disabled={isLoading || !mfaToken}
        >
          次へ
        </Button>
        <Button
          fullWidth
          startIcon={<Help />}
          onClick={() => setShowHelp(true)}
        >
          お困りの場合
        </Button>
      </Box>
    </FormPaper>
  );
};
