import { CancelButton } from '@components/molecules/cancelButton/cancelButton';
import { StyledButton } from '@components/molecules/styledButton/styledButton';
import { Box } from '@mui/material';
import { FC } from 'react';

type Props = {
  disabledSelect: boolean;
  handleClose: () => void;
  handleClick: () => void;
};

export const SelectionModalActions: FC<Props> = ({
  disabledSelect,
  handleClose,
  handleClick,
}) => {
  return (
    <Box component="div" marginLeft="auto ">
      <CancelButton onClick={handleClose} />
      <StyledButton
        disabled={disabledSelect}
        type="contained"
        onClick={handleClick}
        width="60px"
      >
        決定
      </StyledButton>
    </Box>
  );
};
