import { ModalType } from '@components/modal';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ModalState = {
  modalProps: {
    open: boolean;
    title: string;
    type?: ModalType;
    data?: {
      [key: string]: unknown; //unknown is used because it is not known what the type will be
    };
    errorMessage?: string;
  };
};

const initialState: ModalState = {
  modalProps: {
    open: false,
    title: '',
    errorMessage: '',
  },
};

const modalSlice = createSlice({
  name: 'Modal-Reducer',
  initialState,
  reducers: {
    toggleModal: (state: ModalState, action: PayloadAction<ModalState>) => {
      state.modalProps = action.payload.modalProps;
    },
    closeModal: (state: ModalState) => {
      state.modalProps.open = false;
    },
    updateErrorMessage: (
      state: ModalState,
      action: PayloadAction<ModalState['modalProps']['errorMessage']>
    ) => {
      state.modalProps.errorMessage = action.payload;
    },
  },
});

export const { toggleModal, closeModal, updateErrorMessage } =
  modalSlice.actions;

export const ModalReducer = modalSlice.reducer;
