import { RealogramsTableMenu } from '@components/molecules/realogramsTableMenu/realogramsTableMenu';
import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { ActionVisible } from '@components/molecules/actionVisible/actionVisible';
import { StatusIcon } from '@components/organisms/statusIcon/statusIcon';
import { StartIcon } from '@components/molecules/startIcon/startIcon';
import { useRealogramCandidateImage } from '@hooks/useImage';
import { useGetRealogramPermission } from '@hooks/useGetRealogramPermission';
import { MoreVert } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from '@mui/material';
import { Format, format } from '@utils/date';
import { FC, useState } from 'react';
import { theme } from 'theme';
import { RealogramDirectory, RealogramDirectorySearch } from 'types/realogram';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { ShelvesViewMode } from 'types/common';

type Props = {
  imageHeight: number;
  realogramDirectory: RealogramDirectory | RealogramDirectorySearch;
  handleOpenDeleteDialog: (id: number) => void;
  handleClick: (index: number, item: RealogramDirectory) => void;
  index: number;
  handleFavoriteClick: () => void;
  handleSharePermission: () => void;
  isFolder?: boolean;
  isStarred?: boolean;
  viewMode?: ShelvesViewMode;
  isFilteredByViewed: boolean;
};

export const RealogramDirectoryCard: FC<Props> = ({
  imageHeight,
  realogramDirectory,
  handleOpenDeleteDialog,
  handleClick,
  index,
  handleFavoriteClick,
  handleSharePermission,
  isFolder = false,
  isStarred = false,
  viewMode,
  isFilteredByViewed,
}) => {
  const { image, error: imageError } = useRealogramCandidateImage({
    realogramCandidateId: realogramDirectory?.realogram_candidate_id ?? 0,
    size: 'medium',
  });
  const { created_at, current_user_accessed_at } =
    realogramDirectory as RealogramDirectorySearch;
  const realogramCandidate = realogramDirectory?.realogram_candidate;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { isNotEnable: isNotCanRead } = useGetRealogramPermission({
    action: 'read',
    realogram: realogramDirectory,
    isCandidate: false,
    isCan: false,
  });
  const { isNotEnable: isNotCanDelete } = useGetRealogramPermission({
    action: 'delete',
    realogram: realogramDirectory,
    isCandidate: false,
    isCan: false,
  });
  const { isNotEnable: isNotCanReadShare } = useGetRealogramPermission({
    action: 'revise_face',
    realogram: realogramDirectory,
    isCandidate: false,
    isCan: false,
  });
  const { isEnable: isCanUpdate } = useGetRealogramPermission({
    action: 'update',
    realogram: realogramDirectory,
    isCandidate: false,
    isCan: true,
  });
  const { isEnable: isCanRead } = useGetRealogramPermission({
    action: 'read',
    realogram: realogramDirectory,
    isCandidate: false,
    isCan: true,
  });
  return (
    <>
      <Card
        sx={{
          border: `1px solid ${theme.palette.dividerBlack.dark}`,
          position: 'relative',
          height: '100%',
          display: 'flex',
        }}
        elevation={0}
        onClick={() => {
          if (isNotCanRead) return;
          handleClick(index, realogramDirectory);
        }}
      >
        {isStarred && (
          <Box
            component="div"
            sx={{
              position: 'absolute',
              zIndex: 100,
              margin: 1,
              left: '0px',
            }}
          >
            <StarIcon htmlColor={theme.palette.confidence.middle} />
          </Box>
        )}
        {realogramCandidate?.status && (
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              zIndex: 100,
              margin: 1,
              top: 0,
              right: 0,
            }}
          >
            <StatusIcon status={realogramCandidate.status} />
          </Box>
        )}
        <CardActionArea
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            ...(isNotCanRead && {
              '&:hover': {
                cursor: 'default',
              },
            }),
          }}
        >
          {realogramDirectory?.realogram_candidate_id ? (
            <>
              {isNotCanRead || !image || !!imageError ? (
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderBottom: `1px solid ${theme.palette.dividerBlack.medium}`,
                    background: isNotCanRead
                      ? theme.palette.backgroundBlack.primary
                      : theme.palette.backgroundBlack.disabled,
                    width: '100%',
                  }}
                  height={imageHeight}
                >
                  {isNotCanRead && (
                    <StartIcon
                      sx={{
                        fontSize: '56px',
                      }}
                      pathFill={theme.palette.textBlack.disabled}
                    />
                  )}
                </Box>
              ) : (
                <CardMedia
                  image={image}
                  height={imageHeight}
                  component="img"
                  sx={{
                    borderBottom: `1px solid ${theme.palette.dividerBlack.medium}`,
                    backgroundColor: theme.palette.white.primary,
                    objectFit: 'contain',
                  }}
                />
              )}
            </>
          ) : (
            <Box
              component="div"
              sx={{ background: theme.palette.backgroundBlack.primary }}
              height={imageHeight}
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <PinDropIcon
                sx={{ width: '56px', height: '57px' }}
                htmlColor={theme.palette.icons.secondary}
              />
            </Box>
          )}
          <CardContent
            component="div"
            sx={{
              width: '100%',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              p: 1,
              height: '143px',
            }}
          >
            <Box
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                mb: '4px',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: isNotCanRead
                    ? theme.palette.textBlack.disabled
                    : theme.palette.textBlack.primary,
                  overflow: 'hidden',
                  display: '-webkit-box',
                  // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                  WebkitLineClamp: 2,
                  // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {realogramDirectory?.name}
              </Typography>
              <Box component="div">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                  }}
                  sx={{
                    p: 0,
                    width: '32px',
                    height: '32px',
                  }}
                >
                  <MoreVert />
                </IconButton>
              </Box>
            </Box>
            {realogramCandidate?.store_bay?.name && (
              <Typography
                variant="caption1"
                sx={{
                  lineHeight: '12px',
                  justifyContent: 'start',
                  alignItems: 'center',
                  mb: '8px',
                  color: isNotCanRead
                    ? theme.palette.textBlack.disabled
                    : theme.palette.textBlack.primary,
                  overflow: 'hidden',
                  display: '-webkit-box',
                  // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                  WebkitLineClamp: 2,
                  // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {realogramCandidate?.store_bay.name}
              </Typography>
            )}
            <Box
              component="div"
              mb="8px"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {realogramCandidate?.store_bay?.category && (
                <Typography
                  variant="caption1"
                  sx={{
                    lineHeight: '11px',
                    justifyContent: 'start',
                    alignItems: 'center',
                    backgroundColor: theme.palette.backgroundBlack.active,
                    width: 'fit-content',
                    px: '8px',
                    py: '4px',
                    borderRadius: '2px',
                    color: isNotCanRead
                      ? theme.palette.textBlack.disabled
                      : theme.palette.textBlack.primary,
                  }}
                >
                  {realogramCandidate?.store_bay.category}
                </Typography>
              )}
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  ml: 'auto',
                }}
                width={28}
                height={28}
              >
                <ActionVisible
                  isCanUpdate={isCanUpdate}
                  isCanRead={isCanRead}
                />
              </Box>
            </Box>
            <Box
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="caption1"
                sx={{
                  lineHeight: '11px',
                  color: isNotCanRead
                    ? theme.palette.textBlack.disabled
                    : theme.palette.textBlack.secondary,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {realogramDirectory?.owner?.fullname ?? '-'}
              </Typography>
              <Typography
                variant="caption1"
                sx={{
                  lineHeight: '11px',
                  color: isNotCanRead
                    ? theme.palette.textBlack.disabled
                    : theme.palette.textBlack.secondary,
                  whiteSpace: 'nowrap',
                  pl: '4px',
                }}
              >
                {format(
                  isFilteredByViewed ? current_user_accessed_at : created_at,
                  Format.datetime
                )}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
      <RealogramsTableMenu
        anchorEl={anchorEl}
        isCanReadShare={isNotCanReadShare}
        handleClose={() => setAnchorEl(null)}
        handleOpenDeleteDialog={() => {
          setAnchorEl(null);
          if (!realogramDirectory?.realogram_candidate_id) return;
          handleOpenDeleteDialog(realogramDirectory.realogram_candidate_id);
        }}
        handleFavoriteClick={() => {
          setAnchorEl(null);
          handleFavoriteClick();
        }}
        isCanDelete={isNotCanDelete}
        isFolder={isFolder}
        isStarred={isStarred}
        viewMode={viewMode}
        handleSharePermission={() => {
          setAnchorEl(null);
          handleSharePermission();
        }}
      />
    </>
  );
};
