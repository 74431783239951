import { useEffect } from 'react';

export const useBrowserOperate = (callback: () => void) => {
  useEffect(() => {
    window.addEventListener('popstate', () => callback());
    return () => {
      window.removeEventListener('popstate', () => callback());
    };
  }, [callback]);
};
