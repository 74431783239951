import { Stack, SxProps, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  title: string;
  value: string;
  sx?: SxProps;
  subInfoStyle?: SxProps;
};

export const InfoRow: FC<Props> = ({ title, value, sx, subInfoStyle }) => {
  return (
    <Stack direction="row" gap={1} p={1} sx={sx}>
      <Typography
        minWidth={80}
        variant="caption"
        fontSize="12px"
        color={theme.palette.textBlack.label}
      >
        {title}
      </Typography>
      <Typography
        variant="caption"
        fontSize="12px"
        color={theme.palette.textBlack.table}
        sx={subInfoStyle}
      >
        {value}
      </Typography>
    </Stack>
  );
};
