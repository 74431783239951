import { GroupOfOrientationButtons } from '@components/organisms/compartmentOrientationMenu/fragments';
import { StopPropagationIconButton } from '@components/organisms/stopPropagationIconButton/stopPropagationIconButton';
import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import { Box, Button, Divider, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { rotationMap } from '@utils/planogram';
import { isEqual } from 'lodash';
import { FC, useState } from 'react';
import { theme } from 'theme';
import {
  OrientationDirectionId,
  PlanogramProductCompartment,
  ProductPosition,
} from 'types/planogram';
import { RotateMinusZIcon } from './rotateMinusZIcon';
import { RotatePlusZIcon } from './rotatePlusZIcon';
import { RotateXIcon } from './rotateXIcon';
import { RotateYIcon } from './rotateYIcon';
import { RotationDirectionToggle } from './rotationDirectionToggle';

type Props = {
  productPosition: ProductPosition;
};

enum Orientation {
  verticalLeft,
  verticalRight,
  horizontalLeft,
  horizontalRight,
  top,
  bottom,
}

export const AccordionBodyRotation: FC<Props> = ({ productPosition }) => {
  const { plan, rotateProducts } = usePlanogramPlan();
  const target =
    plan.products_layout[productPosition.indexY]?.row[
      productPosition.subPosition.indexX
    ];

  const [toggleValue, setToggleValue] = useState<'horizontal' | 'vertical'>(
    'horizontal'
  );

  const handleToggleChange = (newValue: 'horizontal' | 'vertical') => {
    setToggleValue(newValue);
  };

  const rotate = (direction: OrientationDirectionId) => {
    const orientation = target?.orientation ?? 0;
    const faceFront = target?.face_front ?? 1;

    const nextPosition = calculateNextPosition({
      orientation,
      face_front: faceFront,
      direction,
    });

    rotateProducts(productPosition, {
      faceFront: nextPosition.faceFrontId,
      orientation: nextPosition.orientation,
    });
  };

  return (
    <>
      <Typography color={theme.palette.textBlack.secondary}>90°回転</Typography>
      <Box
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        color={CommonColors.appBlue}
        my={2}
      >
        <Box
          component="div"
          display="flex"
          justifyContent="space-between"
          width="243px"
          color={theme.palette.icons.primary}
        >
          <GroupOfOrientationButtons
            handleRotateMinus={() => rotate(Orientation.verticalLeft)}
            handleRotatePlus={() => rotate(Orientation.verticalRight)}
          >
            <RotateXIcon htmlColor={theme.palette.primary.main} />
          </GroupOfOrientationButtons>
          <Divider orientation="vertical" component="div" flexItem />
          <GroupOfOrientationButtons
            handleRotateMinus={() => rotate(Orientation.horizontalLeft)}
            handleRotatePlus={() => rotate(Orientation.horizontalRight)}
          >
            <RotateYIcon htmlColor={theme.palette.primary.main} />
          </GroupOfOrientationButtons>
          <Divider orientation="vertical" component="div" flexItem />
          <StopPropagationIconButton
            disableRipple
            onClick={() => rotate(Orientation.top)}
            sx={{
              padding: 0,
            }}
          >
            <RotateMinusZIcon htmlColor={theme.palette.primary.main} />
          </StopPropagationIconButton>
          <StopPropagationIconButton
            disableRipple
            onClick={() => rotate(Orientation.bottom)}
            sx={{
              padding: 0,
            }}
          >
            <RotatePlusZIcon htmlColor={theme.palette.primary.main} />
          </StopPropagationIconButton>
        </Box>
        <Button
          sx={{
            fontWeight: 'bold',
            color: 'lightgray',
          }}
        >
          リセット
        </Button>
      </Box>
      <Divider />

      <Typography color={theme.palette.textBlack.secondary} my={2}>
        回転微調整
      </Typography>

      <RotationDirectionToggle
        handleRotateMinus={() => rotate(Orientation.horizontalLeft)}
        handleRotatePlus={() => rotate(Orientation.horizontalRight)}
        toggleValue={toggleValue}
        handleToggleChange={handleToggleChange}
      />
    </>
  );
};

const calculateNextPosition = ({
  orientation,
  face_front: faceFront,
  direction,
}: Pick<PlanogramProductCompartment, 'orientation' | 'face_front'> & {
  direction: OrientationDirectionId;
}): (typeof rotationMap)[keyof typeof rotationMap] => {
  for (const key in rotationMap) {
    if (
      isEqual(key.split(','), [
        faceFront.toString(),
        orientation.toString(),
        direction.toString(),
      ])
    ) {
      return rotationMap[key as keyof typeof rotationMap];
    }
  }

  return { faceFrontId: 1, orientation: 0 };
};
