import { ModalInnerProps } from '@components/modal';
import { CircularSpinner } from '@components/molecules';
import { AuthorField } from '@components/organisms/sharePermissionModal/fragments/authorField';
import { ModalFooter } from '@components/organisms/sharePermissionModal/fragments/modalFooter';
import { ModalTitle } from '@components/organisms/sharePermissionModal/fragments/modalTitle';
import { NoticeScopePublic } from '@components/organisms/sharePermissionModal/fragments/noticeScopePublic';
import { useSharePermissionModal } from '@hooks/useSharePermissionModal';
import { Alert, Box, Typography } from '@mui/material';
import { isRoleAllowedForCrud } from '@utils/const';
import { FC, useCallback, useEffect, useState } from 'react';
import { theme } from 'theme';
import { DirectoryFormType, Scope } from 'types/sharePermission';
import { AncestorRoles } from './fragments/ancestorRoles';
import { ChangeOrDeletePermissionModal } from './fragments/changeOrDeletePermissionModal';
import { ChangeScopeModal } from './fragments/changeScopeModal';
import { DisabledChangePermissionModal } from './fragments/disabledChangePermissionModal';
import { PermissionForm } from './fragments/permissionForm';
import { PermissionMembers } from './fragments/permissionMembers';
import { ScopeInfo } from './fragments/scopeInfo';
import { ScopeSelect } from './fragments/scopeSelect';

type LicenseModalProps = {
  type: DirectoryFormType;
};

type Props = ModalInnerProps<LicenseModalProps>;

export const LicenseModal: FC<Props> = ({ data: { type } }) => {
  const {
    isFetching,
    directoryRoles,
    ancestorRoles,
    deleteDialogOpen,
    directoryId,
    directoryType,
    isSuccess,
    errorMessage,
    currentDirectoryRole,
    currentDirectoryRoleModify,
    userInfo,
    isChangePermission,
    directoryOwnerInfo,
    isOpenScopeDialog,
    tenantInfo,
    disabledChangeDialogOpen,
    latestScope,
    currentUserRole,
    planogramDirectoryParent,
    setErrorMessage,
    handleAddDirectoryRole,
    handleEditDirectoryRole,
    reset,
    handleDeleteDirectoryRole,
    changeSelfDirectoryRole,
    handleChangeScope,
    resetScopeDialog,
    handleChangeScopeToPrivate,
    setCurrentDirectoryRole,
  } = useSharePermissionModal();

  const [selectedScope, setSelectedScope] = useState<Scope | undefined>();
  const [previousSelectedScope, setPreviousSelectedScope] = useState<
    Scope | undefined
  >();

  const scopeOptions = [
    {
      value: 'private',
      label: '非公開',
      content: '自分以外のユーザーは閲覧・編集できません。',
    },
    {
      value: 'restricted',
      label: '権限付与されたユーザーのみに公開',
      content: '権限を付与されたユーザーのみ閲覧・編集可能です。',
    },
    {
      value: 'public',
      label: 'ユーザー全員に公開',
      content: `[${
        tenantInfo?.tenant.name ?? ''
      }]ユーザー全員が閲覧可能です。編集権限を付与されたユーザーのみ編集可能です。`,
    },
  ];

  const findScopeInfo = scopeOptions?.find(
    (scopeOption) => scopeOption?.value === selectedScope
  );

  const isAdmin = userInfo?.role === 'admin';
  const isDirectoryOwner = directoryOwnerInfo?.user_id === userInfo?.id;
  const canEdit =
    isAdmin || isDirectoryOwner || isRoleAllowedForCrud(currentDirectoryRole);
  const isPrivateScope = selectedScope === 'private';
  const isRealogramDirectoryAccessControl =
    tenantInfo?.features.realogram_directory_acl_by_user;
  const canShare =
    (!isPrivateScope && canEdit && directoryType === 'planogram') ||
    ((isAdmin ||
      (isRoleAllowedForCrud(currentDirectoryRole) &&
        isRealogramDirectoryAccessControl)) &&
      directoryType === 'realogram');

  useEffect(() => {
    if (currentUserRole) {
      setCurrentDirectoryRole(currentUserRole);
    }
  }, [currentUserRole, setCurrentDirectoryRole]);

  useEffect(() => {
    if (latestScope) {
      setSelectedScope(latestScope);
    }
  }, [latestScope]);

  const userEmailsHasPermission = useCallback(() => {
    if (!directoryRoles || directoryRoles.length === 0) return [];
    return directoryRoles.map((user) => {
      return user.user.email;
    });
  }, [directoryRoles]);

  return (
    <>
      <Box
        component="div"
        sx={{
          maxWidth: '460px',
          minWidth: '460px',
          padding: '0',
        }}
      >
        <ModalTitle />
        {!canEdit && isRealogramDirectoryAccessControl && (
          <Alert
            severity="info"
            sx={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            閲覧者は権限設定を編集できません。
          </Alert>
        )}
        <Box component="div" sx={{ marginTop: !canEdit ? '24px' : '18px' }}>
          {directoryType === 'planogram' && selectedScope && (
            <>
              {!canEdit ? (
                <ScopeInfo
                  selectedScopeTitle={findScopeInfo?.label}
                  selectedScopeContent={findScopeInfo?.content}
                  selectedScope={selectedScope}
                />
              ) : planogramDirectoryParent?.scope === 'public' &&
                planogramDirectoryParent?.type !== 'root' ? (
                <NoticeScopePublic name={tenantInfo?.tenant?.name} />
              ) : (
                <ScopeSelect
                  typeParent={planogramDirectoryParent?.type}
                  scope={planogramDirectoryParent?.scope}
                  scopeOptions={scopeOptions}
                  selectedScope={selectedScope}
                  selectedScopeContent={findScopeInfo?.content}
                  setSelectedScope={setSelectedScope}
                  setPreviousSelectedScope={setPreviousSelectedScope}
                  handleChangeScope={handleChangeScope}
                />
              )}
            </>
          )}
          {planogramDirectoryParent?.type !== 'root' &&
            (planogramDirectoryParent?.scope === 'public' ? (
              <Alert
                severity="info"
                sx={{
                  marginTop: '24p',
                  marginBottom: '24px',
                  height: '66px',
                  fontSize: '14px',
                  backgroundColor: '#EBFAFF',
                  '.MuiAlert-message': {
                    overflow: 'hidden',
                  },
                }}
              >
                公開範囲を編集する場合は、上位フォルダの公開範囲を変更してください。
              </Alert>
            ) : planogramDirectoryParent?.scope === 'restricted' ? (
              <Alert
                severity="info"
                sx={{
                  marginTop: '24p',
                  marginBottom: '24px',
                  height: '66px',
                  fontSize: '14px',
                  backgroundColor: '#EBFAFF',
                  '.MuiAlert-message': {
                    overflow: 'hidden',
                  },
                }}
              >
                公開範囲を「非公開」にする場合は、上位フォルダの公開範囲を『非公開』にしてください。
              </Alert>
            ) : (
              <></>
            ))}
          <Box component="div" sx={{ maxHeight: '415px', overflowY: 'auto' }}>
            {((!!directoryRoles?.length && !isPrivateScope) || canShare) && (
              <Typography
                color={theme.palette.textBlack.primary}
                noWrap
                fontSize={16}
                sx={{
                  fontWeight: '700',
                  marginBottom: canShare ? '14px' : '0',
                }}
              >
                権限付与されたユーザー
              </Typography>
            )}
            {canShare && (
              <PermissionForm
                directoryId={directoryId}
                directoryType={directoryType}
                isSuccess={isSuccess}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                handleAddDirectoryRole={handleAddDirectoryRole}
                userInfo={userInfo}
                directoryOwnerInfo={directoryOwnerInfo}
                userEmailsHasPermission={userEmailsHasPermission()}
              />
            )}
            {isFetching ? (
              <Box
                component="div"
                height="200px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularSpinner />
              </Box>
            ) : (
              <>
                {!isPrivateScope && !!directoryRoles?.length && (
                  <PermissionMembers
                    isOnlyViewed={!canShare}
                    userInfo={userInfo}
                    directoryRoles={directoryRoles}
                    type={type}
                    handleEditDirectoryRole={handleEditDirectoryRole}
                  />
                )}

                {directoryOwnerInfo && (
                  <AuthorField
                    author={directoryOwnerInfo.user}
                    userInfo={userInfo}
                  />
                )}

                {!isPrivateScope && !!ancestorRoles?.length && (
                  <AncestorRoles
                    ancestorRoles={ancestorRoles}
                    userInfo={userInfo}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
        <ModalFooter />
      </Box>
      <ChangeOrDeletePermissionModal
        open={deleteDialogOpen}
        isChangePermission={isChangePermission}
        currentDirectoryRoleModify={currentDirectoryRoleModify}
        userInfo={userInfo}
        handleCloseDialog={reset}
        handleDeleteDirectoryRole={handleDeleteDirectoryRole}
        changeSelfDirectoryRole={changeSelfDirectoryRole}
        type={type}
        scope={selectedScope}
        ancestorRoles={ancestorRoles}
      />
      <DisabledChangePermissionModal
        open={disabledChangeDialogOpen}
        currentDirectoryRoleModify={currentDirectoryRoleModify}
        userInfo={userInfo}
        handleCloseDialog={reset}
      />
      <ChangeScopeModal
        open={isOpenScopeDialog}
        previousSelectedScope={previousSelectedScope}
        changeScopeToPrivate={handleChangeScopeToPrivate}
        setSelectedScope={setSelectedScope}
        handleCloseDialog={resetScopeDialog}
      />
    </>
  );
};
