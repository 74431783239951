import {
  useGetTenantsPublicQuery,
  useGetTenantsQuery,
} from '@reducers/shelfAppsApi/injections/tenantsApi';

export const useTenantsPublicInfo = () => {
  const { data: tenantsPublicInfo, isLoading: isTenantsPublicInfoLoading } =
    useGetTenantsPublicQuery();
  const { data: tenantsPrivateInfo, isLoading: isTenantsPrivateInfoLoading } =
    useGetTenantsQuery();

  return {
    tenantsPublicInfo,
    tenantsPrivateInfo,
    isTenantsPublicInfoLoading,
    isTenantsPrivateInfoLoading,
  };
};
