import { subject } from '@casl/ability';
import { usePageTitle } from '@hooks/usePageTitle';
import { useGetPlanogramQuery } from '@reducers/shelfAppsApi';
import { isPlanogramBucketPlan } from '@utils/planogram';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FlatPlanogram } from './flatPlanogram/flatPlanogram';
import { ShelfPlanogram } from './shelfPlanogram/shelfPlanogram';
import httpStatus from 'http-status';

export const PlanogramEditor: FC = () => {
  const { id = '0', delay } = useParams();
  const planogramId = parseInt(id);
  const { data, error, isLoading, isFetching } = useGetPlanogramQuery(
    { planogramId },
    { skip: !planogramId, refetchOnMountOrArgChange: true }
  );

  const planogram = useMemo(
    () =>
      data?.planogram &&
      subject('Planogram', Object.assign({}, data.planogram)), // reduxから取り出した値がfreezeされているので、新しいオブジェクトを作成する
    [data?.planogram]
  );

  const isForbidden =
    !!error && 'status' in error && error.status === httpStatus.FORBIDDEN;

  const pageTitle = `${planogram?.name ?? ''} | 棚割計画編集`;
  usePageTitle(pageTitle);

  if (isPlanogramBucketPlan(planogram?.plan)) {
    return (
      <FlatPlanogram
        planogram={planogram}
        planogramId={planogramId}
        isLoading={isLoading}
        isFetching={isFetching}
        delay={delay}
        error={error}
        isForbidden={isForbidden}
      />
    );
  }

  return (
    <ShelfPlanogram
      planogram={planogram}
      planogramId={planogramId}
      isLoading={isLoading}
      isFetching={isFetching}
      delay={delay}
      error={error}
      isForbidden={isForbidden}
    />
  );
};
