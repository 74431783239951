import { InfoRow } from '@components/organisms/productCandidateRealogram/fragments/infoRow';
import { Box, Divider, Typography } from '@mui/material';
import { getProductsLayout } from '@utils/planogram';
import { getProductDisplayValues } from '@utils/product';
import { FC } from 'react';
import { Planogram, PlanogramProductCompartment } from 'types/planogram';
import { ProductSales } from 'types/products';
import {
  RealogramProductCompartment,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';
import { Product, ShelfDetailView } from '../../../types/common';
import { ComparisonProductOptional } from '../comparisonProductOptional/comparisonProductOptional';
import { RealogramFaceImage } from '../realogramFaceImage/realogramFaceImage';

type Props = {
  product?: Product;
  view: ShelfDetailView;
  planogram?: Planogram;
  realograms?: RealogramShelfBoard[];
  comparisonPlanogram?: Planogram;
  comparisonRealogram?: RealogramShelfBoard[];
  selectedRealogramItem?: RealogramSelectedItem;
  comparedAnalyticsData?: ProductSales;
  analyticsData?: ProductSales;
};

const countFacesInRow = (
  row: PlanogramProductCompartment[],
  productId?: number
) =>
  row.reduce(
    (rowCount, x) =>
      rowCount + (x.product_id === productId ? x?.face_count ?? 0 : 0),
    0
  );

const countFacesInCompartment = (
  compartment: RealogramProductCompartment,
  productId?: number
) =>
  compartment.faces.reduce(
    (faceCount, face) =>
      faceCount +
      (!face.is_unknown && face.primary_candidate?.product_id === productId
        ? 1
        : 0),
    0
  );

const countFacesInCompartments = (
  compartments: RealogramProductCompartment[],
  productId?: number
) =>
  compartments.reduce(
    (compartmentCount, x) =>
      compartmentCount + countFacesInCompartment(x, productId),
    0
  );

export const ComparisonProduct: FC<Props> = ({
  product,
  view,
  planogram,
  realograms,
  comparisonPlanogram,
  comparisonRealogram,
  selectedRealogramItem,
  comparedAnalyticsData,
  analyticsData,
}) => {
  const { name, eanCode, productCode } = getProductDisplayValues(
    selectedRealogramItem?.item,
    product
  );
  const isUnknown = selectedRealogramItem?.item.is_unknown;
  const notInStock = !selectedRealogramItem?.item.in_stock;
  const noPrimaryCandidate =
    selectedRealogramItem?.item.primary_candidate === null;

  const isFaceCountHidden = isUnknown || (notInStock && noPrimaryCandidate);
  const comparisonFaceCount = () => {
    const productId = product?.id;

    if (comparisonPlanogram) {
      return getProductsLayout(comparisonPlanogram.plan).reduce(
        (count, y) => count + countFacesInRow(y.row, productId),
        0
      );
    }

    if (comparisonRealogram) {
      return (
        comparisonRealogram.reduce(
          (count, y) =>
            count + countFacesInCompartments(y.compartments, productId),
          0
        ) ?? 0
      );
    }

    return '';
  };

  const faceCount = () => {
    if (planogram) {
      return getProductsLayout(planogram.plan).reduce(
        (count, y) => count + countFacesInRow(y.row, product?.id),
        0
      );
    }
    if (realograms) {
      return (
        realograms.reduce(
          (count, y) =>
            count + countFacesInCompartments(y.compartments, product?.id),
          0
        ) ?? 0
      );
    }
    return '';
  };

  const eanCodeValue = eanCode ? eanCode : '-';
  const productCodeValue = productCode ? productCode : '-';

  return (
    <Box component="div">
      <Box
        component="div"
        display="flex"
        my={2}
        position="relative"
        minHeight={100}
        maxWidth={254}
        justifyContent="center"
        alignItems="center"
      >
        <RealogramFaceImage
          product={product}
          baseSize={254}
          fontSize={24}
          face={selectedRealogramItem?.item}
        />
      </Box>
      <Typography variant="subtitle2" mt={2}>
        {name}
      </Typography>
      <InfoRow title="JAN" value={eanCodeValue} sx={{ p: 'unset' }} />
      <InfoRow title="商品CD" value={productCodeValue} sx={{ p: 'unset' }} />
      {product && (
        <>
          {!isFaceCountHidden && <Divider sx={{ my: 2 }} />}
          <ComparisonProductOptional
            product={product}
            view={view}
            faceCount={faceCount()}
            comparisonFaceCount={comparisonFaceCount()}
            comparedAnalyticsData={comparedAnalyticsData}
            analyticsData={analyticsData}
            isFaceCountHidden={isFaceCountHidden}
          />
        </>
      )}
    </Box>
  );
};
