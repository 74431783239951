import { ModalInnerProps } from '@components/modal';
import { RequiredText } from '@components/molecules/requiredText/requiredText';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBayPlanCodes } from '@hooks/useBayPlanCodes';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { isOnlySpaces } from '@utils/const';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InferType, number, object, string } from 'yup';
import { ModalActions } from '../modalActions/modalActions';

export type EditPlanogramModalProps = {
  name?: string;
  bayPlanCodeId?: number;
  onSubmit: (data: FormData) => void;
};

const schema = object({
  name: string()
    .required('ゴンドラ名を入力してください')
    .matches(
      /^(?![\s\u3000]*$).*/,
      '半角スペースまたは全角スペースのみで登録することはできません。'
    ),
  bayPlanCodeId: number().transform((_, originalValue: number) => {
    if (typeof originalValue === 'number' && originalValue >= 0) {
      return originalValue;
    }
    return undefined;
  }),
});
type FormData = InferType<typeof schema>;

type Props = ModalInnerProps<EditPlanogramModalProps>;
export const EditPlanogramForm: FC<Props> = ({
  handleClose,
  data: { name: defaultName, bayPlanCodeId: defaultBayPlanCodeId, onSubmit },
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: defaultName,
      bayPlanCodeId: defaultBayPlanCodeId,
    },
  });
  const { bayPlanCodes } = useBayPlanCodes();
  const name = watch('name');
  return (
    <Box
      component="form"
      width={400}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <RequiredText mb={3} />
      <Box component="div" mb={2}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              required
              label="ゴンドラ名"
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
            />
          )}
        />
      </Box>
      <Controller
        name="bayPlanCodeId"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth error={!!errors.bayPlanCodeId}>
            <InputLabel>ゴンドラ番号</InputLabel>
            <Select label="ゴンドラ番号" {...field}>
              <MenuItem value="未選択">未選択</MenuItem>
              {bayPlanCodes?.map((bayPlanCode) => (
                <MenuItem key={bayPlanCode.id} value={bayPlanCode.id}>
                  {bayPlanCode.name}
                </MenuItem>
              ))}
            </Select>
            {!!errors.bayPlanCodeId && (
              <FormHelperText>{errors.bayPlanCodeId.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
      <ModalActions
        handleClose={handleClose}
        actionBtnTitle="変更"
        disabled={!name || isOnlySpaces(name)}
      />
    </Box>
  );
};
