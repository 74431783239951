import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const ProductIcon: FC<SvgIconProps> = (props) => {
  const { htmlColor = '#0A40CA', ...otherProps } = props;

  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1818 4H3.81818C2.40909 4 1 5.44444 1 6.88889V27.1111C1 28.7 2.26818 30 3.81818 30H6V11.8889C6 10.5335 6.64214 9.33336 7.44906 8.50619C8.25294 7.68214 9.44545 7 10.8182 7H32V6.88889C32 5.44444 30.5909 4 29.1818 4ZM36.1818 9H10.8182C9.40909 9 8 10.4444 8 11.8889V32.1111C8 33.7 9.26818 35 10.8182 35H36.1818C37.5909 35 39 33.5556 39 32.1111V11.8889C39 10.4444 37.5909 9 36.1818 9ZM27 11.5C27 11.2239 27.2239 11 27.5 11H30.5C30.7761 11 31 11.2239 31 11.5V13H27V11.5ZM32 31H36V33H11V31H15.8477C15.1454 30.9729 14.5112 30.5637 14.1985 29.9299C14.0679 29.6654 14 29.3743 14 29.0793V20L15.5 18H14.5V17H21.5V18H20.5L22 20V29.0793C22 29.3743 21.9321 29.6654 21.8015 29.9299C21.4888 30.5637 20.8546 30.9729 20.1523 31H26C25.4477 31 25 30.5523 25 30V20.0017H33V30C33 30.5523 32.5523 31 32 31ZM27 13.9982C26.8803 14.8435 26.5411 15.6043 26.1667 16.444C25.7266 17.4312 25.2379 18.5275 25 19.9982H33C32.7621 18.5275 32.2734 17.4312 31.8333 16.444C31.4589 15.6043 31.1197 14.8435 31 13.9982H27Z"
        fill={htmlColor}
      />
    </SvgIcon>
  );
};
