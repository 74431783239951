import { FC } from 'react';
import { Circle } from '@mui/icons-material/';
import { SvgIconProps } from '@mui/material/';
import { TriangleIcon } from '@components/molecules/triangleIcon/triangleIcon';
import { RateMarker } from 'types/common';

type Props = SvgIconProps & {
  type: RateMarker;
};

export const Marker: FC<Props> = ({ type, ...props }) => {
  if (type === 'turned') {
    return <TriangleIcon {...props} />;
  }
  return <Circle {...props} />;
};
