import { api } from '..';

export const planogramsApi = {
  getPlanogramDirectories:
    api.getPlanogramDirectoryApiV1PlanogramDirectoriesGet,
  getSearchPlanogramDirectories:
    api.searchPlanogramDirectoryApiV1PlanogramDirectoriesSearchGet,
  createPlanogram: api.postApiV1PlanogramsPost,
  clonePlanogram: api.cloneApiV1PlanogramsClonePost,
  updateDirectory:
    api.putPlanogramDirectoryApiV1PlanogramDirectoriesPlanogramDirectoryIdPut,
  putPlanogram: api.updateApiV1PlanogramsPlanogramIdPut,
  deletePlanogramDirectory:
    api.deletePlanogramDirectoryApiV1PlanogramDirectoriesPlanogramDirectoryIdDelete,
  addToFavorite:
    api.postPlanogramDirectoryFavoritesApiV1PlanogramDirectoryFavoritesDirectoryIdPost,
  removeFromFavorite:
    api.deletePlanogramDirectoryFavoriteApiV1PlanogramDirectoryFavoritesDirectoryIdDelete,
  createPlanogramDirectory:
    api.postPlanogramDirectoryApiV1PlanogramDirectoriesPost,
};
