import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import { usePlanogramContext } from '@hooks/usePlanogramProvider';
import { Box, Popper, styled } from '@mui/material';
import {
  addRecentProduct,
  updateIsShowProductDetail,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { useAppDispatch } from '@store/index';
import { isPlanogramPtsDetail } from '@utils/planogram';
import { FC } from 'react';
import { Product } from 'types/common';
import { PlanogramPlan, ProductPosition } from 'types/planogram';
import { theme } from '../../../../theme';
import { ProductsButtonGroup } from './productsButtonGroup';
import { ProductsButtonGroupVertical } from './productsButtonGroupVertical';

type Props = {
  open: boolean;
  products?: Map<number, Product[]>;
  anchorEl: HTMLElement | null;
  productPosition: ProductPosition;
  displayableHeight: number;
  handleClose: () => void;
  handleClickRotate: () => void;
};

const marginTop = 2;
const marginLeft = -1;

const getStackable = (
  plan: PlanogramPlan,
  productPosition: ProductPosition
) => {
  if (isPlanogramPtsDetail(plan)) return true;

  const product = plan.shelves[productPosition.indexY]?.detail.product;

  if (!product) return true; //product of old data has null

  return product.stackable;
};

export const CompartmentMenu: FC<Props> = ({
  open,
  products,
  anchorEl,
  productPosition,
  displayableHeight,
  handleClose,
  handleClickRotate,
}) => {
  const data = usePlanogramContext();

  const dispatch = useAppDispatch();
  const { plan } = usePlanogramPlan();
  const targetCompartment =
    plan.products_layout[productPosition.indexY]?.row[
      productPosition.subPosition.indexX
    ];
  const targetProduct = products?.get(targetCompartment?.product_id)?.at(0);

  const stackable = getStackable(plan, productPosition);
  const onDeleteProduct = () => {
    dispatch(updateSelectedProductCompartment(undefined));
    dispatch(updateIsShowProductDetail(false));
    dispatch(addRecentProduct(targetProduct));
    data?.removeProductFromBulk?.(targetProduct as Product);
    handleClose();
  };
  return (
    <>
      <Popper
        anchorEl={anchorEl}
        open={open}
        placement="left-end"
        sx={{
          overflow: 'visible',
          position: 'relative',
          width: 0,
          height: 0,
          minWidth: 0,
          minHeight: 0,
          background: 'unset',
          boxShadow: 'unset',
          display: 'flex',
          marginTop: marginTop + precisePosition(anchorEl).marginTop, //it needs because <Popper /> logic rounding the position
          marginLeft: marginLeft + precisePosition(anchorEl).marginLeft,
          '& .MuiMenu-list': {
            padding: 0,
            width: 'unset',
            height: 'unset',
          },
        }}
      >
        {stackable && (
          // Popperのwidth（50px) + border(1px)分ずらす
          <StyledButtonGroupBox sx={{ bottom: 0, left: '-51px' }}>
            <ProductsButtonGroupVertical
              productPosition={productPosition}
              displayableHeight={displayableHeight}
              targetCompartment={targetCompartment}
              targetProduct={targetProduct}
            />
          </StyledButtonGroupBox>
        )}
        {/* Popperのwidth（50px) + border(1px)分ずらす */}
        <StyledButtonGroupBox sx={{ bottom: '-51px' }}>
          <ProductsButtonGroup
            productPosition={productPosition}
            target={targetCompartment}
            handleClickRotate={handleClickRotate}
            onDeleteProduct={onDeleteProduct}
          />
        </StyledButtonGroupBox>
      </Popper>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
const StyledButtonGroupBox = styled(Box)(() => ({
  position: 'absolute',
  borderRadius: '4px',
  border: 'solid 1px #DDDDDD',
  backdropFilter: 'blur(6px)',
  // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
  WebkitBackdropFilter: 'blur(6px)',
  backgroundColor: theme.palette.white.translucent,
}));

export const precisePosition = (
  node: HTMLElement | null
): { marginTop: number; marginLeft: number } => {
  const rect = node?.getBoundingClientRect();
  const rectTop = rect?.top ?? 0;
  const rectHeight = rect?.height ?? 0;
  const rectLeft = rect?.left ?? 0;
  const marginTop = rectTop + rectHeight - Math.round(rectTop + rectHeight);
  const marginLeft = rectLeft - Math.round(rectLeft);
  return { marginTop, marginLeft };
};
