import { passwordSchema } from '../utils/password';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InferType } from 'yup';

type FormData = InferType<typeof passwordSchema>;

export const usePasswordChangeForm = () => {
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: 'all',
    resolver: yupResolver(passwordSchema),
  });

  const { currentPassword, newPassword, newPasswordConfirmation } = watch();

  return {
    register,
    handleSubmit,
    setValue,
    errors,
    isValid,
    currentPassword,
    newPassword,
    newPasswordConfirmation,
  };
};
