import { Backdrop, CircularProgress } from '@mui/material';
import { CommonColors, withOpacity } from '@utils/colors';
import { RootState, useAppSelector } from 'store';

const opacity = 0.1;
export const LoadingIndicator = () => {
  const { isLoading } = useAppSelector(
    (state: RootState) => state.LoadingIndicator
  );

  return (
    <Backdrop
      sx={{
        backgroundColor: 'rgba(255,255,255, 0.4)',
        color: '#fff',
        //TODO: fix zIndex
        zIndex: 10000,
      }}
      open={isLoading}
    >
      <svg width="130" height="130" style={{ position: 'absolute' }}>
        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
          <stop offset="20%" stopColor={CommonColors.appBlue} />
          <stop offset="100%" stopColor={withOpacity('#00AFAA', opacity)} />
        </linearGradient>
      </svg>
      <CircularProgress
        size={130}
        thickness={5}
        sx={{
          circle: {
            stroke: 'url(#linearColors)',
          },
        }}
      />
    </Backdrop>
  );
};
