import { AlertDialog } from '@components/organisms/alertDialog/alertDialog';
import { Button, DialogContentText, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';
import { theme } from 'theme';
import {
  AncestorRolesType,
  DirectoryFormType,
  DirectoryRoleUserInfo,
  Scope,
} from 'types/sharePermission';
import { User } from 'types/user';

type Props = {
  open: boolean;
  isChangePermission?: boolean;
  userInfo?: User;
  currentDirectoryRoleModify?: DirectoryRoleUserInfo;
  type: DirectoryFormType;
  scope?: Scope;
  handleCloseDialog: () => void;
  handleDeleteDirectoryRole: () => void;
  changeSelfDirectoryRole: () => void;
  ancestorRoles?: AncestorRolesType;
};

export const ChangeOrDeletePermissionModal: FC<Props> = ({
  open,
  isChangePermission,
  userInfo,
  currentDirectoryRoleModify,
  type,
  scope,
  handleCloseDialog,
  handleDeleteDirectoryRole,
  changeSelfDirectoryRole,
  ancestorRoles,
}) => {
  const isMe = currentDirectoryRoleModify?.user_id === userInfo?.id;
  const parentRole = ancestorRoles?.filter(
    (ancestorRole) =>
      ancestorRole.user_id === currentDirectoryRoleModify?.user_id
  );
  const isEditorInParentRole = parentRole?.find(
    (role) => role.role === 'editor'
  );

  const name = isMe
    ? 'あなた'
    : `${currentDirectoryRoleModify?.user.fullname ?? ''}さん`;

  const getTitle = () => {
    if (
      (isChangePermission && type === 'directory') ||
      (isChangePermission && isMe)
    ) {
      return `${name}を閲覧者に変更します。`;
    } else {
      return `${name}の権限を削除します。`;
    }
  };

  const texts = getContents({
    dialogType: isChangePermission ? 'change' : 'delete',
    isMe,
    isEditorInParentRole: !!isEditorInParentRole,
    type,
    scope,
    parentRole,
  });

  return (
    <AlertDialog
      open={open}
      title={getTitle()}
      content={
        <DialogContentText
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            color: theme.palette.textBlack.primary,
          }}
        >
          {texts.map((text) => (
            <Typography key={text}>{text}</Typography>
          ))}
        </DialogContentText>
      }
      cancelButton={
        <Button
          onClick={handleCloseDialog}
          sx={{
            color: CommonColors.black,
            ':hover': { bgcolor: CommonColors.lightGray },
          }}
        >
          キャンセル
        </Button>
      }
      confirmButton={
        <Button
          onClick={
            isChangePermission
              ? changeSelfDirectoryRole
              : handleDeleteDirectoryRole
          }
          sx={{
            color: CommonColors.appRed,
            ':hover': { bgcolor: CommonColors.lightGray },
          }}
        >
          変更
        </Button>
      }
    />
  );
};

const deletePermissionButStillCanEditMessage =
  '権限を削除しても、ユーザーは親フォルダに編集権限があるため、引き続き編集可能です。';
const deletePermissionButStillCanReadMessage =
  '権限を削除しても、ユーザーは親フォルダに閲覧権限があるため、引き続き閲覧可能です。';
const deletePermissionMessage =
  '権限を削除するとユーザーがこの棚割を閲覧・編集できなくなります。';
const deletePermissionButScopeIsPublicMessage =
  '権限を削除するとユーザーがこの棚割の閲覧・編集できなくなります。公開範囲が全体公開のため、引き続き閲覧可能です。';
const changeOwnPermissionMessage =
  '閲覧者になると、この棚割を編集できなくなります。また、権限の設定も変更できなくなります。';
const deleteOwnDirectoryPermissionMessages = [
  '権限を削除すると、このフォルダを編集できなくなります。また、権限の設定もできなくなります。',
  '子フォルダ・ファイルで個別に付与された権限は削除されません。変更する場合は、対象のフォルダ・ファイルの共有設定を編集してください。',
];
const changeOwnDirectoryPermissionMessages = [
  '閲覧者になると、このフォルダを編集できなくなります。また、権限も設定できなくなります。',
  '子フォルダ・ファイルで個別に付与された権限は削除されません。変更する場合は、対象のフォルダ・ファイルの共有設定を編集してください。',
];
const changeDirectoryPermissionMessage =
  'このフォルダの権限を閲覧者に変更しても、子フォルダ・ファイルで個別に付与された編集者権限は変更されません。変更する場合は、対象のフォルダ・ファイルの共有設定を編集してください。';
const deleteDirectoryPermissionMessage =
  'このフォルダの権限を削除しても、子フォルダ・ファイルで個別に付与された権限は削除されません。変更する場合は、対象のフォルダ・ファイルの共有設定を編集してください。';

type MessagesProps = {
  dialogType: 'delete' | 'change';
  isMe: boolean;
  isEditorInParentRole: boolean;
  type: DirectoryFormType;
  scope?: Scope;
  parentRole?: AncestorRolesType;
};

const getContents = ({
  dialogType,
  isMe,
  isEditorInParentRole,
  type,
  scope,
  parentRole,
}: MessagesProps) => {
  const texts: string[] = [];
  if (dialogType === 'delete') {
    if (type === 'file') {
      if (parentRole?.length && isEditorInParentRole) {
        texts.push(deletePermissionButStillCanEditMessage);
      } else if (parentRole?.length && !isEditorInParentRole) {
        texts.push(deletePermissionButStillCanReadMessage);
      } else if (scope === 'public') {
        texts.push(deletePermissionButScopeIsPublicMessage);
      } else {
        texts.push(deletePermissionMessage);
      }
    }
    if (type === 'directory') {
      if (parentRole?.length && isEditorInParentRole) {
        texts.push(deletePermissionButStillCanEditMessage);
      }
      if (isMe && !isEditorInParentRole) {
        texts.push(...deleteOwnDirectoryPermissionMessages);
      } else {
        texts.push(deleteDirectoryPermissionMessage);
      }
    }
  }

  if (dialogType === 'change') {
    if (type === 'file') {
      if (isMe) {
        texts.push(changeOwnPermissionMessage);
      }
    }
    if (type === 'directory') {
      if (isMe && !isEditorInParentRole) {
        texts.push(...changeOwnDirectoryPermissionMessages);
      } else {
        texts.push(changeDirectoryPermissionMessage);
      }
    }
  }

  return texts;
};
