import { Typography } from '@mui/material';
import { useOrganizationStatuses } from '@hooks/useOrganizationStatuses';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  id?: number;
  isDisabledCard?: boolean;
};

export const OrganizationStatusLabel: FC<Props> = ({ id, isDisabledCard }) => {
  const { organizationStatus } = useOrganizationStatuses(id);
  return (
    <Typography
      variant="caption1"
      sx={{
        color: theme.palette.textBlack.secondary,
        backgroundColor: theme.palette.backgroundBlack.active,
        width: 'fit-content',
        px: '8px',
        py: '4px',
        whiteSpace: 'nowrap',
        borderRadius: '2px',
        opacity: isDisabledCard ? '0.4' : 'unset',
      }}
    >
      {organizationStatus?.label ?? '-'}
    </Typography>
  );
};
