import { StyledButton } from '@components/molecules/styledButton/styledButton';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import styles from './loadanimation.module.css';

type Props = {
  handleClose: () => void;
};

export const LoadAnimation: FC<Props> = ({ handleClose }) => (
  <Box
    component="div"
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'white',
    }}
  >
    <Box
      component="div"
      sx={{
        position: 'relative',
        width: '95px',
        height: '85px',
      }}
    >
      <Box component="div" className={styles.boxfirst} />
      <Box component="div" className={styles.boxsecond} />
      <Box component="div" className={styles.boxthird} />
      <Box component="div" className={styles.boxfour} />
    </Box>
    <Typography
      sx={{
        margin: '30px 0 0 0',
        height: '42px',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '34px',
        lineHeight: '123.5%',
        letterSpacing: '0.25px',
        color: '#0A40CA',
        flex: 'none',
        flexGrow: 0,
        whiteSpace: 'nowrap',
      }}
    >
      3Dモデルを生成中
    </Typography>

    <StyledButton type="outlined" sx={{ mt: 5 }} onClick={handleClose}>
      キャンセル
    </StyledButton>
  </Box>
);
