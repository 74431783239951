import { Box, styled } from '@mui/material';

export const ImageBox = styled(Box)(() => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 40,
  minHeight: 40,
  position: 'relative',
  marginLeft: 16,
  marginRight: 16,
}));
