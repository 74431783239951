import { selectBayPartId } from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useListBayPartCategoriesQuery } from '@reducers/shelfAppsApi/injections/bayPartCategoriesApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useState } from 'react';
import { BayPartCategory, BayPlan } from '../types/bayPlan';
import { usePlanogramPlan } from './usePlanogramPlan';

export const useBayPartCategories = (bayPlanId?: BayPlan['id']) => {
  const { isSwappingBayPartMode } = useAppSelector(selectPlanogramEditorState);
  const { data } = useListBayPartCategoriesQuery();
  const { selectBayPartPosition } = usePlanogramPlan();
  const dispatch = useAppDispatch();
  const [selectedBayPartCategory, setSelectedBayPartCategory] =
    useState<BayPartCategory>();

  const [categoryType, setCategoryType] = useState<'categories' | 'list'>(
    'categories'
  );
  const rootCategories = data?.bay_part_category?.children.find(
    (value) => value.bay_plan_id === bayPlanId
  );
  const handleClick = (category: BayPartCategory) => {
    setSelectedBayPartCategory(category);
    setCategoryType('list');
  };
  const reset = () => {
    setSelectedBayPartCategory(undefined);
    setCategoryType('categories');
    if (!isSwappingBayPartMode) {
      dispatch(selectBayPartId(undefined));
      selectBayPartPosition(undefined);
    }
  };
  return {
    categoryType,
    reset,
    handleClick,
    rootCategories,
    selectedBayPartCategory,
  };
};
