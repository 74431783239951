import { datadogRum } from '@datadog/browser-rum';

type Props = {
  enable: boolean;
  applicationId: string; // datadog の発行するID
  clientToken: string; // datadog の発行するトークン
  env: string; // staging, production などの動作環境を指定する
  version: string; // アプリケーションのバージョン
  sessionSampleRate: number; // 指定した割合のセッションを記録する。ユーザ数に応じて減らして調整したい
  sessionReplaySampleRate: number; // ユーザ操作（datadogリプレイ）を記録する割合
};

// datadog の初期化を行う関数
// shelf-apps-frontend は SAP のため datadog は一連のユーザ操作を１つのセッションとして記録する
// また、1つのセッション内には複数のビューイベントが記録され、これは react-router のビューが１つのビューイベントに対応する
export const initDatadogRum = (props: Props) => {
  if (
    !props.enable ||
    props.applicationId.length === 0 ||
    props.clientToken.length === 0
  ) {
    return;
  }
  console.debug(`datadog RUM initialized - ${props.env} ${props.version}`);
  datadogRum.init({
    site: 'datadoghq.com',
    service: 'shelf-apps-frontend',
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    silentMultipleInit: true,
    allowedTracingUrls: [
      {
        match: window.location.origin + '/api',
        propagatorTypes: ['tracecontext', 'datadog'],
      },
    ],
    traceSampleRate: 100,
    ...props,
  });
  datadogRum.startSessionReplayRecording();
};
