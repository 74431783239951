import { ErrorMessage } from '@components/molecules';
import { FormPaper } from '@components/molecules/formPaper/formPaper';
import { MfaHelp } from '@components/organisms';
import { yupResolver } from '@hookform/resolvers/yup';
import { Help } from '@mui/icons-material';
import { Box, Button, TextField, Typography } from '@mui/material';
import { paths } from '@utils/const';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { InferType } from 'yup';
import {
  OneTimePasswordFormData,
  oneTimePasswordSchema,
} from '../../utils/oneTimePassword';

type Props = {
  isRequesting: boolean;
  prev: () => void;
  isVerifying: boolean;
  isVerifyingError: boolean;
  onSubmit: (data: InferType<typeof oneTimePasswordSchema>) => void;
};

export const OneTimePassword: FC<Props> = ({
  isRequesting,
  prev,
  isVerifying,
  isVerifyingError,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const [isHelp, setIsHelp] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<OneTimePasswordFormData>({
    resolver: yupResolver(oneTimePasswordSchema),
    defaultValues: { confirmCode: '' },
  });

  if (isHelp)
    return (
      <MfaHelp
        prev={() => setIsHelp(false)}
        navigateLogin={() => navigate(paths.home)}
      />
    );

  return (
    <FormPaper title="確認コード入力">
      <Typography mb={2}>
        アプリケーションに表示されている確認コードを入力してください。
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        gap={3}
        noValidate
      >
        {isVerifyingError && (
          <ErrorMessage message="無効な確認コードです。もう一度お試しください。" />
        )}
        <Controller
          name="confirmCode"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="確認コード"
              value={field.value}
              error={Boolean(errors.confirmCode)}
              helperText={errors.confirmCode?.message}
              type="text"
              inputProps={{
                inputMode: 'numeric',
              }}
              autoComplete="one-time-code"
              size="small"
            />
          )}
        />
        <Button
          variant="contained"
          fullWidth
          type="submit"
          disabled={!isValid || isVerifying}
        >
          次へ
        </Button>
        <Button fullWidth onClick={prev} disabled={isRequesting}>
          登録をやり直す
        </Button>
        <Button fullWidth startIcon={<Help />} onClick={() => setIsHelp(true)}>
          お困りの場合
        </Button>
      </Box>
    </FormPaper>
  );
};
