import { Grid, GridProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export const ComparisonGridContainer: FC<PropsWithChildren<GridProps>> = ({
  children,
  ...props
}) => {
  return (
    <Grid
      container
      spacing={1}
      flexDirection={{ xs: 'row', breakpoint: 'column' }}
      {...props}
    >
      {children}
    </Grid>
  );
};
