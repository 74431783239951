import { FC } from 'react';
import { AlertDialog } from '../alertDialog/alertDialog';
import { Box, Button, DialogContentText } from '@mui/material';
import { theme } from 'theme';

type Props = {
  open: boolean;
  handleClickCancelButton: () => void;
  handleClickConfirmButton: () => void;
};
export const DeleteRealogramDialog: FC<Props> = ({
  open,
  handleClickCancelButton,
  handleClickConfirmButton,
}) => {
  return (
    <AlertDialog
      open={open}
      title="次の場合にのみ、削除を行なってください。"
      pbDialogContent={1}
      content={
        <Box component="div" pt={1}>
          <DialogContentText
            component="li"
            sx={{ color: theme.palette.textBlack.primary }}
            ml={1.2}
          >
            棚ではない写真をアップロードしてしまった（完全な手違いの場合）
          </DialogContentText>
          <DialogContentText
            component="li"
            sx={{ color: theme.palette.textBlack.primary }}
            ml={1.2}
          >
            人や機密情報が写り込んでしまった
          </DialogContentText>
          <DialogContentText
            mt={2}
            sx={{ color: theme.palette.textBlack.primary }}
          >
            今後の検証のため、棚が完全に入っていなかったり、ピンボケなどの撮影ミスの場合は、削除は行わないでください。
          </DialogContentText>
          <DialogContentText
            mt={2}
            sx={{ color: theme.palette.textBlack.primary }}
          >
            この操作は取り消せません。問題がない場合のみ削除を行なってください。
          </DialogContentText>
        </Box>
      }
      cancelButton={
        <Button
          variant="text"
          onClick={handleClickCancelButton}
          sx={{
            color: theme.palette.textBlack.primary,
            fontWeight: 700,
          }}
        >
          キャンセル
        </Button>
      }
      confirmButton={
        <Button
          variant="text"
          onClick={handleClickConfirmButton}
          sx={{
            color: theme.palette.system.errorMain,
            fontWeight: 700,
          }}
        >
          削除
        </Button>
      }
    />
  );
};
