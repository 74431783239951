import { SelectedItemType } from 'types/common';
import { useModal } from './useModal';
import {
  setCurrentRealogramDirectoryId,
  setCurrentSelectedItem,
  updateCurrentSelectedItemId,
} from '@reducers/selectionStoreModal';
import { useAppDispatch } from '@store/index';
import { closeModal } from '@reducers/modal';
import { AppAbility } from '@utils/abac';

export const useSelectionStoreModal = (ability: AppAbility) => {
  const { showModal } = useModal('selectionStoreModal', '');
  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    showModal({
      handleChangeSelectedStoreModal: (selectedItem?: SelectedItemType) => {
        dispatch(setCurrentRealogramDirectoryId(selectedItem?.parent_id));
        dispatch(updateCurrentSelectedItemId(selectedItem?.id));
        dispatch(setCurrentSelectedItem(selectedItem));
        dispatch(closeModal());
      },
      ability,
    });
  };

  return {
    handleOpenModal,
  };
};
