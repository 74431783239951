import * as yup from 'yup';
const maxLength = 9999;
const minLength = 1;

const sizeSchema = yup.number().max(maxLength).min(minLength).integer();

export const schema = yup.object().shape({
  width: sizeSchema,
  height: sizeSchema,
  depth: sizeSchema,
});
