// StyledComponentでcomponentのプロパティを参照するとエラーとなるので、

import { ListItemButton, styled } from '@mui/material';
import { defaultHeight } from '@components/organisms/realogramCandidatesList/fragments';
import { theme } from 'theme';

// 型定義を追加してTypeScriptのチェックをすり抜けさせます
type ComponentLi = { component: 'li' };

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
export const StyledListItemButton = styled(ListItemButton)<ComponentLi>(() => ({
  height: defaultHeight,
  py: 1,
  fontSize: 14,
  borderBottom: `1px solid ${theme.palette.dividerBlack.light}`,
  '&.Mui-selected': {
    backgroundColor: '#E5F0FF',
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#E5F0FF',
  },
  '&:hover': {
    backgroundColor: theme.palette.backgroundBlack.hover,
    borderColor: 'none',
  },
}));
