import { FC } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import GroupsIcon from '@mui/icons-material/Groups';
import { Scope } from 'types/sharePermission';
import { theme } from 'theme';
import { SxProps } from '@mui/material';

type Props = { option?: Scope; disabled?: boolean; sx?: SxProps };

export const RoleIcon: FC<Props> = ({ option, disabled, sx }) => {
  const htmlColor = disabled
    ? theme.palette.icons.disabled
    : theme.palette.icons.primary;

  switch (option) {
    case 'private':
      return <LockIcon sx={{ ...sx }} htmlColor={htmlColor} />;
    case 'restricted':
      return <LockPersonIcon sx={{ ...sx }} htmlColor={htmlColor} />;
    case 'public':
      return <GroupsIcon sx={{ ...sx }} htmlColor={htmlColor} />;
    default:
      return <LockIcon sx={{ ...sx }} htmlColor={htmlColor} />;
  }
};
