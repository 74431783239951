import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';
import { theme } from '../../../theme';

export const RequiredText: FC<TypographyProps> = (props) => {
  return (
    <Typography color={theme.palette.textBlack.secondary} {...props}>
      *は必須項目です。
    </Typography>
  );
};
