import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { Product } from 'types/common';
import { theme } from '../../../../theme';

type Props = {
  product?: Product;
  width: number;
  height: number;
  fontSize: number;
};

const lineHeight = 1.5;
const padding = 2;

export const DummyProduct: FC<Props> = ({
  product,
  fontSize,
  height,
  width,
  ...props
}) => {
  return (
    <Box
      component="div"
      width="100%"
      height="100%"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box
        component="div"
        width={width}
        height={height}
        sx={{
          backgroundColor: '#737680',
          padding: '2px',
        }}
        {...props}
      >
        <Typography
          sx={{
            display: '-webkit-box',
            // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
            WebkitLineClamp: Math.trunc(
              // 高さからpaddingを引いた値を、fontSizeとlineHeightで割り、小数点以下切り捨て、行数を算出
              (height - padding) / (fontSize * lineHeight + padding)
            ),
            maxHeight: height,
          }}
          color={theme.palette.white.primary}
          fontSize={fontSize}
        >
          {product?.name}
        </Typography>
      </Box>
    </Box>
  );
};
