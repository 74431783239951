import { useOrganizationStatusesQuery } from '@reducers/shelfAppsApi';

export const useOrganizationStatuses = (id?: number) => {
  const { data } = useOrganizationStatusesQuery();
  const organizationStatus = data?.planogram_organization_statuses?.find(
    (status) => status.id === id
  );
  return {
    organizationStatus,
    organizationStatuses: data?.planogram_organization_statuses,
  };
};
