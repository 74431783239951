import { Box, Typography } from '@mui/material';
import { FC } from 'react';

export const ToggleButtonContent: FC<{
  icon: React.ReactNode;
  label: string;
}> = ({ icon, label }) => (
  <Box component="div" display="flex" alignItems="center">
    {icon}
    <Typography
      variant="caption"
      whiteSpace="nowrap"
      lineHeight={1}
      mx="2px"
      fontWeight="inherit"
    >
      {label}
    </Typography>
  </Box>
);
