import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const ParcelDivisionIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2H13V4H11V2ZM11 5H13V7H11V5ZM13 8H11V10H13V8ZM11 11H13V13H11V11ZM13 14H11V16H13V14ZM11 17H13V19H11V17ZM13 20H11V22H13V20ZM3 7V17H7V7H3ZM2 5H8C8.55228 5 9 5.44772 9 6V18C9 18.5523 8.55228 19 8 19H2C1.44772 19 1 18.5523 1 18V6C1 5.44772 1.44772 5 2 5ZM16.9998 17V7.00002H20.9998V17H16.9998ZM21.9998 5.00002H15.9998C15.4476 5.00002 14.9998 5.44774 14.9998 6.00002V18C14.9998 18.5523 15.4476 19 15.9998 19H21.9998C22.5521 19 22.9998 18.5523 22.9998 18V6.00002C22.9998 5.44774 22.5521 5.00002 21.9998 5.00002Z"
        fill="black"
        fillOpacity="0.65"
      />
    </SvgIcon>
  );
};
