import { FC } from 'react';
import { RealogramUploadStep, StepFiveInfo } from 'types/realogram';
import { Box, Button, Typography } from '@mui/material';
import { Header } from '@components/organisms/header/header';
import { theme } from 'theme';
import { RealogramDetail } from './realogramDetail';
import { paths } from '@utils/const';

type Props = {
  changeStep: (step: RealogramUploadStep, isNext: boolean) => void;
  isFlat: boolean;
  onFirstFileSelect: (file: File) => void;
  onSecondFileSelect: (file?: File) => void;
  stepFiveInfo?: StepFiveInfo;
  handleNavigate: (nextPath: string) => void;
  isXs?: boolean;
};

export const StepFive: FC<Props> = ({
  changeStep,
  isFlat,
  onFirstFileSelect,
  onSecondFileSelect,
  stepFiveInfo,
  handleNavigate,
  isXs = false,
}) => {
  return (
    <Box
      component="div"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: theme.palette.white.primary,
      }}
      gap={4}
    >
      <Header>
        <Typography variant="headingS">
          新規スキャン アップロード完了
        </Typography>
      </Header>
      <Box component="div" py={7} px={2}>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          gap={4}
        >
          <Box component="div">
            <Typography variant="headingM">
              アップロードが完了しました
            </Typography>
          </Box>
          <RealogramDetail
            isFlat={isFlat}
            stepFiveInfo={stepFiveInfo}
            handleNavigate={handleNavigate}
            isXs={isXs}
          />
          <Box
            component="div"
            gap={3}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box component="div" gap={1}>
              <Button
                sx={{
                  height: '48px',
                  borderRadius: '4px',
                  px: '20px',
                  fontWeight: 700,
                  color: theme.palette.textBlack.primary,
                }}
                onClick={() => handleNavigate(paths.home)}
              >
                ホームに戻る
              </Button>
            </Box>
            <Box component="div" gap={1}>
              <Button
                variant="contained"
                aria-describedby="menuButton"
                component="label"
                sx={{
                  height: '48px',
                  borderRadius: '4px',
                  px: '20px',
                  fontWeight: 700,
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.white.primary,
                }}
              >
                <input
                  type="file"
                  accept="image/jpeg"
                  onChange={(e) => {
                    if (e.target.files) {
                      // 平置きのsecondFileのファイルのみ初期化。firstFileは上書きされるので問題ない
                      onSecondFileSelect(undefined);
                      onFirstFileSelect(e.target.files[0]);
                      changeStep('three', true);
                    }
                  }}
                  hidden
                />
                続けてスキャン
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
