import { regenerateIdToken } from '@reducers/auth';
import { selectToken } from '@reducers/auth/selectors';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '@store/index';
import { baseUrl } from '@utils/const';
import httpStatus from 'http-status';

export enum PlanogramTags {
  CreatePlanogram = 'create_planogram',
  PtsUploadTag = 'pts_upload',
  DeletePlanogram = 'delete_planogram',
  UpdatePlanogram = 'update_planogram',
}

export enum PlanogramDirectoryTags {
  CreatePlanogramDirectory = 'create_planogram_directory',
  UpdatePlanogramDirectory = 'update_planogram_directory',
  ListPlanogramDirectory = 'list_planogram_directory',
  GetPlanogramDirectory = 'get_planogram_directory',
}
export enum RealogramDirectoryTags {
  ListRealogramDirectory = 'list_realogram_directory',
}
export enum RealogramsTags {
  ListRealogram = 'list_realogram',
  DeleteRealogram = 'delete_realogram',
  UpdateFace = 'update_face',
  DeleteFace = 'delete_face',
  DeleteShelfBoard = 'delete_shelf_board',
  UpdateProductLists = 'update_product_lists',
}

export enum UsersTags {
  VerifyMfaDevice = 'verify_mfa_device',
  DeleteMfaDevice = 'delete_mfa_device',
}

export enum DirectoryRolesTags {
  ListDirectoryRole = 'list_directory_role',
  CreateDirectoryRole = 'create_directory_role',
  UpdateDirectoryRole = 'update_directory_role',
  DeleteDirectoryRole = 'delete_directory_role',
}

export enum UserTags {
  GetUsers = 'get_users',
  GetUser = 'get_user',
  UpdateUser = 'update_user',
  CreateUser = 'create_user',
  DeleteMfaDeviceByAdmin = 'delete_mfa_device_by_admin',
  GetIfUserMfaRegistered = 'get_if_user_mfa_registered',
}

export enum AnalyticsTags {
  GetRealogramAnalytics = 'get_realogram_analytics',
}

export enum StoreBayTags {
  ListStoreBays = 'list_store_bays',
  DeleteStoreBay = 'delete_store_bay',
}

type Camelcase<S extends string> = S extends `${infer X}_${infer Y}${infer Z}`
  ? `${Lowercase<X>}${Uppercase<Y>}${Camelcase<Z>}`
  : Lowercase<S>;

// MEMO: 配列に対して適応できないので注意が必要
export type KeysToCamelcase<T> = {
  [K in keyof T as Camelcase<string & K>]: T[K] extends Record<string, unknown>
    ? KeysToCamelcase<T[K]>
    : T[K];
};
//指定されたkeyの型を配列に変換する
export type Arrayable<T, K extends keyof T> = {
  [key in keyof T]: key extends K ? NonNullable<T[key]>[] : T[key];
};

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const idToken = selectToken(getState() as RootState);
    if (idToken) {
      headers.set('authorization', `Bearer ${idToken}`);
    }

    return headers;
  },
  paramsSerializer: (params) => {
    return Object.keys(params)
      .filter((k) => {
        if (typeof params[k] === 'number' && params[k] >= 0) return true;
        return params[k];
      })
      .flatMap((k) => {
        if (params[k] instanceof Array) {
          return params[k].map(
            (v: string) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
          );
        }
        return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`;
      })
      .join('&');
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === httpStatus.UNAUTHORIZED) {
    const res = await api.dispatch(regenerateIdToken());
    if (res.meta.requestStatus === 'fulfilled') {
      // retry after refresh succeeded
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const baseApi = createApi({
  reducerPath: 'shelfAppsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: [
    PlanogramTags.CreatePlanogram,
    PlanogramTags.PtsUploadTag,
    PlanogramTags.UpdatePlanogram,
    PlanogramDirectoryTags.CreatePlanogramDirectory,
    PlanogramDirectoryTags.UpdatePlanogramDirectory,
    RealogramsTags.DeleteRealogram,
    PlanogramTags.DeletePlanogram,
    RealogramsTags.UpdateFace,
    RealogramsTags.DeleteFace,
    RealogramsTags.DeleteShelfBoard,
    RealogramsTags.UpdateProductLists,
    RealogramsTags.ListRealogram,
    UsersTags.VerifyMfaDevice,
    UsersTags.DeleteMfaDevice,
    PlanogramDirectoryTags.ListPlanogramDirectory,
    PlanogramDirectoryTags.GetPlanogramDirectory,
    RealogramDirectoryTags.ListRealogramDirectory,
    DirectoryRolesTags.ListDirectoryRole,
    DirectoryRolesTags.CreateDirectoryRole,
    DirectoryRolesTags.UpdateDirectoryRole,
    DirectoryRolesTags.DeleteDirectoryRole,
    UserTags.GetUsers,
    UserTags.GetUser,
    UserTags.UpdateUser,
    UserTags.CreateUser,
    UserTags.DeleteMfaDeviceByAdmin,
    UserTags.GetIfUserMfaRegistered,
    AnalyticsTags.GetRealogramAnalytics,
    StoreBayTags.ListStoreBays,
    StoreBayTags.DeleteStoreBay,
  ],
});

export const isFetchBaseQueryError = (
  error: unknown
): error is FetchBaseQueryError => {
  return typeof error === 'object' && error != null && 'status' in error;
};
