import { FC } from 'react';
import { RealogramDirectory } from 'types/realogram';
import PinDropIcon from '@mui/icons-material/PinDrop';
import StoreIcon from '@mui/icons-material/Store';
import CameraAlt from '@mui/icons-material/CameraAlt';
import { theme } from 'theme';
import { ShelfIcon } from '@components/molecules/shelfIcon/shelfIcon';

type Props = {
  realogramDirectory: RealogramDirectory;
};

export const DisplayIcon: FC<Props> = ({ realogramDirectory }) => {
  const { type, store_area_id, store_id, store_bay_id } = realogramDirectory;

  return (
    <>
      {type === 'directory' && store_area_id && (
        <PinDropIcon htmlColor={theme.palette.icons.secondary} />
      )}
      {type === 'directory' && store_id && (
        <StoreIcon htmlColor={theme.palette.icons.secondary} />
      )}
      {type === 'file' && (
        <CameraAlt htmlColor={theme.palette.icons.secondary} />
      )}
      {type === 'directory' && store_bay_id && <ShelfIcon />}
    </>
  );
};
