import { Stack } from '@mui/material';
import {
  hasHalfWidthAlphanumericAndSymbols,
  hasUpperAndLowerCase,
  isValidLength,
} from '@utils/users';
import { FC } from 'react';
import { Item } from './fragments/Item';

type Props = {
  value: string;
};

export const PasswordValidationList: FC<Props> = ({ value }) => {
  return (
    <Stack direction="column" rowGap={1}>
      <Item
        isValid={hasHalfWidthAlphanumericAndSymbols(value)}
        text="半角英数字、記号を含む"
      />
      <Item isValid={hasUpperAndLowerCase(value)} text="大文字小文字を含む" />
      <Item isValid={isValidLength(value)} text="11文字以上" />
    </Stack>
  );
};
