import { FormPaper } from '@components/molecules/formPaper/formPaper';
import { Button, Typography } from '@mui/material';
import { paths } from '@utils/const';
import { useNavigate } from 'react-router-dom';

export const MfaCompleted = () => {
  const navigate = useNavigate();
  return (
    <FormPaper title="二要素認証登録完了">
      <Typography mb={4}>
        登録が完了しました。ログインの際には、Authenticator
        アプリケーションに表示された確認コードを入力してください。
      </Typography>
      <Button
        fullWidth
        onClick={() => {
          navigate(paths.home);
        }}
      >
        ホーム
      </Button>
    </FormPaper>
  );
};
