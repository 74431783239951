import { Box } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { FC } from 'react';
import { PlanogramBayPart } from 'types/planogram';
import { DragBar } from './dragBar';

type Props = PlanogramBayPart & {
  image?: string;
  isEditor?: boolean;
  isCompared?: boolean;
};
export const BayPartContent: FC<Props> = ({
  type,
  isEditor = true,
  isCompared,
}) => {
  const { mode } = useAppSelector(selectPlanogramEditorState);
  const isEditingBay = mode === 'BayEditor';
  //TODO: テクスチャに合わせるために無理やり位置調整を行なっている
  switch (type) {
    case 'shelf_board':
      return (
        <>
          {isEditingBay && !isCompared && (
            <Box
              component="div"
              sx={{ width: '100%', position: 'absolute', top: '3px' }}
            >
              {isEditor && <DragBar />}
            </Box>
          )}
          <Box
            component="div"
            sx={{ height: '100%', width: '100%', backgroundColor: '#ddd' }}
          />
          {/*<ShelfBoard image={image} />*/}
        </>
      );
    case 'hook_bar':
      return (
        <>
          {isEditingBay && !isCompared && (
            <Box
              component="div"
              sx={{ width: '100%', position: 'absolute', top: '8px' }}
            >
              {isEditor && <DragBar />}
            </Box>
          )}
          <Box
            component="div"
            sx={{ height: '100%', width: '100%', backgroundColor: 'blue' }}
          />

          {/*<HookBar image={image} />*/}
        </>
      );
    default:
      return null;
  }
};
