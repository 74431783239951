import { ProductsBay } from '@components/organisms/productsBay/productsBay';
import { PlanogramProvider } from '@hooks/usePlanogramProvider';
import { Box } from '@mui/material';
import { useListProductsBulkQuery } from '@reducers/shelfAppsApi';
import { getProductsLayout, isPlanogramPtsDetail } from '@utils/planogram';
import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { PlanogramPlan } from 'types/planogram';

type Props = {
  zoomScale: number;
  plan: PlanogramPlan;
};

export const TwoDPlanogram: FC<Props> = ({ zoomScale, plan }) => {
  const productIds = getProductsLayout(plan)
    .flatMap(({ row }) => row.map(({ product_id }) => product_id))
    .join(',');

  const { data: productsBulk } = useListProductsBulkQuery(
    { productIds: productIds, shape: true, detail: true },
    { skip: !productIds }
  );
  if (isPlanogramPtsDetail(plan)) {
    console.error('unsupported planogram type');
    return <></>;
  }

  return (
    //NOTE: DnD判定を有効にするためにDndProviderでwrapする必要がある
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <PlanogramProvider
        value={{
          products: productsBulk?.products,
        }}
      >
        <Box
          component="div"
          sx={{
            height: '100%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            component="div"
            sx={{
              willChange: 'transform',
              transform: `scale(${zoomScale})`,
              transformOrigin: 'top center ',
            }}
          >
            <ProductsBay plan={plan} />
          </Box>
        </Box>
      </PlanogramProvider>
    </DndProvider>
  );
};
