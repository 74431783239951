import { Box, Button, Divider } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { closeModal } from '@reducers/modal';
import { useAppDispatch } from '@store/index';
import { setDirectoryId, setDirectoryType } from '@reducers/sharePermission';
import { defaultDirectoryType } from '@utils/const';

export const ModalFooter = () => {
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(setDirectoryId(''));
    dispatch(setDirectoryType(defaultDirectoryType));
    dispatch(closeModal());
  };

  return (
    <>
      <Divider sx={{ my: 2, ml: 2, margin: '0 -25px' }} />
      <Box
        component="div"
        sx={{
          display: 'flex',
          float: 'right',
          marginTop: '10px',
          marginBottom: '-10px',
        }}
      >
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{
            height: '40px',
            minWidth: '60px',
            p: 1,
            borderColor: CommonColors.appBlue,
          }}
        >
          完了
        </Button>
      </Box>
    </>
  );
};
