import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const ShelfIconPlus: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.999 0H16.999V3H13.999V5H16.999V8H18.999V5H21.999V3H18.999V0ZM5.99902 15V12H13.999V15H5.99902ZM13.999 17H5.99902V20H13.999V17ZM13.999 22H5.99902H4.99902H3.99902V5H4.99902H5.99902H12.999V7H5.99902V10H13.999V8H15.999V22H14.999H13.999Z"
        fill="#0A40CA"
      />
    </SvgIcon>
  );
};
