import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, IconButton, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { theme } from 'theme';
import { BreadcrumbSearch } from 'types/common';

type ScreenType = 'Main' | 'Modal';

// Todo: データ構造がわかり次第変更
type BreadcrumbListProps = {
  breadcrumbs: BreadcrumbSearch[];
  screenType: ScreenType;
  fontSize?: number | string;
  isCanRead?: boolean;
};

const mainMaxDisplayNum = 5;
const maxWidth = 160;

const modalMaxDisplayNum = 5;
// TODO: 抽象化
export const BreadcrumbForSearch: FC<BreadcrumbListProps> = ({
  breadcrumbs,
  screenType,
  fontSize = 'small',
  isCanRead = true,
}) => {
  const displayDirectoryNum =
    screenType === 'Main' ? mainMaxDisplayNum : modalMaxDisplayNum;
  const maxDisplayDirectories = breadcrumbs.slice(-displayDirectoryNum);
  const omitDirectories = breadcrumbs.slice(0, -displayDirectoryNum);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleMoreHorizIconClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="inherit" />}
        itemsAfterCollapse={displayDirectoryNum}
        aria-label="breadcrumb"
        sx={{
          '& .MuiBreadcrumbs-separator': {
            mx: 0.5,
          },
          '& .MuiBreadcrumbs-ol': {
            flexWrap: 'nowrap',
          },
          cursor: isCanRead ? 'pointer' : 'default',
          color: isCanRead
            ? theme.palette.textBlack.secondary
            : theme.palette.textBlack.disabled,
          height: `${breadcrumbs.length > 0 ? '16px' : '0px'}`,
        }}
      >
        {omitDirectories.length && (
          <IconButton
            component="button"
            onClick={handleMoreHorizIconClick}
            aria-describedby={id}
            sx={{
              padding: 0,
              color: isCanRead
                ? theme.palette.textBlack.secondary
                : theme.palette.textBlack.disabled,
              cursor: isCanRead ? 'pointer' : 'default',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <MoreHorizIcon />
          </IconButton>
        )}
        {maxDisplayDirectories.map((displayDirectory) => (
          <Box
            key={displayDirectory.id}
            component="div"
            maxWidth={maxWidth}
            padding={0}
            sx={{
              '&:hover': {
                cursor: isCanRead ? 'pointer' : 'default',
              },
            }}
          >
            <Typography
              noWrap
              fontSize={fontSize}
              color={
                isCanRead
                  ? theme.palette.textBlack.secondary
                  : theme.palette.textBlack.disabled
              }
            >
              {displayDirectory.name}
            </Typography>
          </Box>
        ))}
      </Breadcrumbs>
    </>
  );
};
