import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  Box,
  IconButton,
  IconButtonProps,
  Typography,
  styled,
  SxProps,
} from '@mui/material';
import { FC } from 'react';
import { theme } from '../../../../theme';

type Props = {
  onClickNext?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickPrev?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disableNext: boolean;
  disablePrev: boolean;
  createdAt: string;
  sx?: SxProps;
};

export const CreatedAt: FC<Props> = ({
  onClickNext,
  onClickPrev,
  disableNext,
  disablePrev,
  createdAt,
  sx,
}) => {
  return (
    <Box component="div" display="flex" alignItems="center">
      {onClickPrev && (
        <StyledIconButton disabled={disablePrev} onClick={onClickPrev}>
          <KeyboardArrowLeft fontSize="small" />
        </StyledIconButton>
      )}
      <Typography sx={sx} mx={1}>
        {createdAt}
      </Typography>
      {onClickNext && (
        <StyledIconButton disabled={disableNext} onClick={onClickNext}>
          <KeyboardArrowRight fontSize="small" />
        </StyledIconButton>
      )}
    </Box>
  );
};

export const StyledIconButton = styled(IconButton)<IconButtonProps>(() => ({
  width: 32,
  height: 32,
  borderRadius: 4,
  padding: 0,
  margin: 0,
  color: theme.palette.textBlack.primary,
}));
