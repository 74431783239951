import { Box, Divider, MenuItem, TextField, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ChangeEvent, FC, useMemo } from 'react';
import { PlanogramListOrder } from 'types/planogram';
import { RealogramListOrder } from 'types/realogram';
import { theme } from 'theme';
import { standardSortOption } from '@utils/const';

export type ListOrder = RealogramListOrder | PlanogramListOrder;

type SortOption = {
  name: string;
  value: string;
};
type Props = {
  firstOrder?: ListOrder;
  sortOptions: SortOption[];
  handleChangeOrder: (order?: ListOrder) => void;
  isShowOrderSelection?: boolean;
  isLoadingData: boolean;
};

export const GridModeSort: FC<Props> = ({
  sortOptions,
  firstOrder,
  handleChangeOrder,
  isShowOrderSelection = true,
  isLoadingData,
}) => {
  const hasStandardOption = sortOptions.some(
    (option) => option.value === standardSortOption.value
  );
  const currentOrder = firstOrder?.endsWith('desc') ? 'desc' : 'asc';
  const currentOrderBy = firstOrder?.replace(`_${currentOrder}`, '');

  const isSortByStandard =
    (hasStandardOption && !currentOrderBy) || isLoadingData; // sort value is empty

  const currentOrderBySelectionValue = useMemo(() => {
    if (!isSortByStandard || currentOrderBy) return currentOrderBy;
    return standardSortOption.value;
  }, [currentOrderBy, isSortByStandard]);

  const changeNextOrder = () => {
    if (isSortByStandard || !currentOrderBy) return;
    const newOrder = currentOrder === 'asc' ? 'desc' : 'asc';
    handleChangeOrder(`${currentOrderBy}_${newOrder}` as ListOrder);
  };

  const handleOrderByChange = (orderBy: string) => {
    if (orderBy === standardSortOption.value) {
      handleChangeOrder(undefined);
    } else {
      handleChangeOrder(`${orderBy}_${currentOrder}` as ListOrder);
    }
  };

  const sortOrderLabel = currentOrder === 'desc' ? '降順' : '昇順';

  return (
    <Box component="div" display="flex" alignItems="center">
      {isShowOrderSelection && (
        <>
          <TextField
            select
            value={currentOrderBySelectionValue}
            variant="standard"
            disabled={
              isSortByStandard &&
              currentOrderBySelectionValue !== standardSortOption.value
            }
            sx={{
              '& .MuiInputBase-root': {
                fontSize: '14px',
              },
              '& .MuiInput-underline:before, & .MuiInput-underline:after,& .MuiInput-underline:hover':
                {
                  borderBottom: '0 !important',
                },
              '& .MuiSelect-select:focus': {
                backgroundColor: 'transparent',
              },
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleOrderByChange(event.target.value);
            }}
          >
            {sortOptions.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              borderWidth: '1px',
              height: '16px',
              marginRight: '24px',
              marginLeft: '16px',
            }}
          />
        </>
      )}
      <Box
        component="div"
        display="flex"
        alignItems="center"
        sx={{
          cursor: isSortByStandard ? '' : 'pointer',
          opacity: isSortByStandard ? '0.5' : '1',
        }}
        onClick={changeNextOrder}
      >
        <Typography
          color={theme.palette.textBlack.primary}
          fontSize="14px"
          marginRight="8px"
        >
          {sortOrderLabel}
        </Typography>
        <>
          {currentOrder === 'desc' ? (
            <ArrowDownwardIcon
              fontSize="small"
              htmlColor={theme.palette.icons.primary}
            />
          ) : (
            <ArrowUpwardIcon
              fontSize="small"
              htmlColor={theme.palette.icons.primary}
            />
          )}
        </>
      </Box>
    </Box>
  );
};
