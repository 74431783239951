import { AlertDialog } from '@components/organisms';
import { Button, DialogContentText } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  open: boolean;
  handleClickCancelButton: () => void;
  handleClickConfirmButton: () => void;
};
export const DeleteStoreBayDialog: FC<Props> = ({
  open,
  handleClickCancelButton,
  handleClickConfirmButton,
}) => {
  return (
    <AlertDialog
      open={open}
      title="次の場合にのみ、削除を行なってください。"
      pbDialogContent={1}
      content={
        <DialogContentText sx={{ color: theme.palette.textBlack.primary }}>
          この操作は取り消せません。問題がない場合のみ削除を行なってください。
        </DialogContentText>
      }
      cancelButton={
        <Button
          variant="text"
          onClick={handleClickCancelButton}
          sx={{
            color: theme.palette.textBlack.primary,
            fontWeight: 700,
          }}
        >
          キャンセル
        </Button>
      }
      confirmButton={
        <Button
          variant="text"
          onClick={handleClickConfirmButton}
          sx={{
            color: theme.palette.system.errorMain,
            fontWeight: 700,
          }}
        >
          削除
        </Button>
      }
    />
  );
};
