import { ButtonGroup, Divider, Button } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { StopPropagationButton } from '@components/organisms';
import { SpritVertically } from '../spritVerticallyIcon/spritVertically';
import { SpritHorizontally } from '../spritHorizontallyIcon/spritHorizontally';

import { theme } from 'theme';
import { FC } from 'react';
import { SplitAreaType } from 'types/planogram';

type Props = {
  isDisabledDeleteButton?: boolean;
  isDisabledSplitVerticalButton?: boolean;
  isDisabledSplitHorizontalButton?: boolean;
  handleDelete: () => void;
  handleSplitArea: (type: SplitAreaType) => void;
};

export const AreaMenuButtonGroup: FC<Props> = ({
  isDisabledSplitVerticalButton,
  isDisabledSplitHorizontalButton,
  handleDelete,
  handleSplitArea,
  isDisabledDeleteButton = true,
}) => {
  return (
    <>
      <ButtonGroup
        sx={{
          height: 48,
          borderRadius: '4px',
          border: '1px solid #DDDDDD',
          backdropFilter: 'blur(6px)',
          backgroundColor: theme.palette.white.translucent,
          boxShadow: '0px 4px 8px 0px #00000026',

          '& .MuiButtonGroup-grouped': {
            border: 0,
            maxWidth: 45,
            '&:hover': {
              border: 0,
            },

            '&.Mui-disabled': {
              border: 0,
            },
          },
        }}
      >
        <StopPropagationButton
          onClick={() => handleSplitArea(SplitAreaType.VERTICAL)}
          disabled={isDisabledSplitVerticalButton}
        >
          <SpritVertically />
        </StopPropagationButton>
        <Divider
          orientation="vertical"
          component="div"
          flexItem
          sx={{ margin: '10px 0', color: '#D9D9D9' }}
        />
        <StopPropagationButton
          onClick={() => handleSplitArea(SplitAreaType.HORIZONTAL)}
          disabled={isDisabledSplitHorizontalButton}
        >
          <SpritHorizontally />
        </StopPropagationButton>
        <Divider
          orientation="vertical"
          component="div"
          flexItem
          sx={{ margin: '10px 0', color: '#D9D9D9' }}
        />
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
          disabled={isDisabledDeleteButton}
        >
          <Delete />
        </Button>
      </ButtonGroup>
    </>
  );
};
