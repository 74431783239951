import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import httpStatus from 'http-status';
import { passwordErrorMessage } from './const';

export const toPasswordInitErrorMessage = (
  error?: FetchBaseQueryError | SerializedError
) => {
  if (!error) return passwordErrorMessage.notSetNewPassword;
  switch ('status' in error && error.status) {
    case httpStatus.NOT_ACCEPTABLE:
      return passwordErrorMessage.notCorrectPassword;
    default:
      return passwordErrorMessage.notSetNewPassword;
  }
};
