import { useCallback, useLayoutEffect, useRef, useState } from 'react';

/**
 * 画面サイズを動的に取得する
 */
export const useWindowSize = () => {
  const ref = useRef<NodeJS.Timeout | number>();
  const delay = 500;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const updateSize = useCallback(() => {
    clearTimeout(Number(ref.current));
    ref.current = setTimeout(() => {
      //When resizing the screen on the desktop, a delay of 500 ms is provided
      //because the setState event is fired every time.
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }, delay);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);
  return { width, height };
};
