import { FC } from 'react';
import {
  Pagination as MuiPagination,
  PaginationProps,
  styled,
} from '@mui/material';
import { CommonColors } from '@utils/colors';

export const Pagination: FC<PaginationProps> = (props) => {
  return <StyledMuiPagination variant="outlined" {...props} />;
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
const StyledMuiPagination = styled(MuiPagination)<PaginationProps>(() => ({
  '& .Mui-disabled': {
    backgroundColor: '#E0E0E0',
  },
  '& .MuiPaginationItem-outlined.Mui-selected': {
    backgroundColor: 'transparent',
    borderColor: CommonColors.appBlue,
    color: CommonColors.appBlue,
    '&:hover ': {
      backgroundColor: CommonColors.lightGray,
    },
  },
  '& .MuiPaginationItem-outlined': {
    borderColor: 'transparent',
  },
}));
