import { widthList } from '@components/organisms';
import { Box, Divider, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC, PropsWithChildren } from 'react';

export const ShelfListHeader: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      component="div"
      display="flex"
      alignItems="center"
      minHeight={56}
      py={1}
      color={CommonColors.grayLight}
      sx={{
        borderTop: 1,
        borderBottom: 1,
        borderTopColor: CommonColors.light,
        borderBottomColor: CommonColors.light,
      }}
    >
      <Box component="div" height="inherit" pl={2}>
        <Typography variant="body2" width={widthList.eanCodeWidth}>
          JAN
        </Typography>
        <Typography variant="body2" width={widthList.eanCodeWidth}>
          商品CD
        </Typography>
      </Box>
      <Divider
        flexItem
        orientation="vertical"
        sx={{ my: '3px', borderWidth: '1px' }}
      />
      <Typography variant="body2" flex={1} pl={2} width={widthList.menuWidth}>
        商品
      </Typography>
      {children}
    </Box>
  );
};
