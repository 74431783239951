import { FC } from 'react';
import { Box, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { theme } from 'theme';
import { RoleIcon } from '@components/molecules/roleIcon/roleIcon';
import { Scope } from 'types/sharePermission';

type Props = {
  selectedScopeTitle: string | undefined;
  selectedScopeContent: string | undefined;
  selectedScope: Scope;
};

export const ScopeInfo: FC<Props> = ({
  selectedScopeTitle,
  selectedScopeContent,
  selectedScope,
}) => {
  return (
    <Box component="div">
      <Typography
        color={theme.palette.textBlack.primary}
        noWrap
        fontSize={16}
        sx={{
          fontWeight: '700',
          marginBottom: '8px',
        }}
      >
        公開範囲
      </Typography>

      <ListItemIcon sx={{ color: theme.palette.textBlack.primary, padding: 0 }}>
        <RoleIcon option={selectedScope} />
        <ListItemText>
          <Typography
            color={theme.palette.textBlack.primary}
            noWrap
            fontSize={16}
            sx={{
              fontWeight: '400',
              lineHeight: '16px',
              marginLeft: '5px',
            }}
          >
            {selectedScopeTitle}
          </Typography>
        </ListItemText>
      </ListItemIcon>

      <Box
        component="div"
        sx={{
          marginTop: '4px',
          marginBottom: '22px',
          maxWidth: '100%',
        }}
      >
        {selectedScopeContent && (
          <Typography
            color={theme.palette.textBlack.secondary}
            noWrap
            fontSize={14}
            sx={{
              fontWeight: '400',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'initial',
            }}
          >
            {selectedScopeContent}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
