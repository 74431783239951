import { usePageTitle } from '@hooks/usePageTitle';
import { Error } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { selectIsAuthenticated } from '@reducers/auth/selectors';
import { useAppSelector } from '@store/index';
import { CommonColors } from '@utils/colors';
import { fullHeight, paths } from '@utils/const';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar } from './organisms';

type Props = {
  title?: string;
};

export const NotFound: FC<Props> = ({ title }) => {
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  usePageTitle();
  const buttonText = isAuthenticated ? 'ホームに戻る' : 'ログイン';
  const handleClick = () => {
    if (isAuthenticated) {
      navigate(paths.home);
    }
    navigate(paths.login);
  };
  return (
    <Box component="div" height={fullHeight}>
      {isAuthenticated && <AppBar title={title ? title : '棚アプリ'} />}
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100%"
        sx={{ color: '#9E9E9E' }}
      >
        <Error
          sx={{
            width: '80px',
            height: '80px',
            mb: '14px',
            color: '#B5B5B5',
          }}
        />
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 'bold',
            letterSpacing: '0.15px',
            mb: 1,
            color: '#999999',
          }}
        >
          指定されたURLのページは存在しません
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            letterSpacing: '0.15px',
            mb: '36px',
            color: '#999999',
          }}
        >
          ご指定いただいたURLが間違っているか、ページが移動または削除された可能性があります。
        </Typography>
        <Button
          variant="outlined"
          onClick={handleClick}
          sx={{
            p: 0,
            borderColor: CommonColors.appBlue,
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              lineHeight: '16px',
              fontWeight: 'bold',
              m: '12px 16px',
            }}
          >
            {buttonText}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
