import { Close } from '@mui/icons-material';
import { IconButton, List, ListItem, ListItemText } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC, PropsWithChildren } from 'react';

export const ListWrapper: FC<
  PropsWithChildren<{
    title: string;
    hasCloseButton?: boolean;
    handleClose?: () => void;
  }>
> = ({ title, hasCloseButton = false, handleClose, children }) => {
  return (
    <List
      disablePadding
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
      }}
    >
      <ListItem
        disableGutters
        secondaryAction={
          hasCloseButton && (
            <IconButton sx={{ width: 24, height: 24 }} onClick={handleClose}>
              <Close sx={{ color: CommonColors.black }} />
            </IconButton>
          )
        }
      >
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            fontWeight: 700,
            color: CommonColors.black,
            fontSize: 14,
            letterSpacing: '0.16px',
          }}
        />
      </ListItem>
      {children}
    </List>
  );
};
