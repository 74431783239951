import { ListContainer } from '@components/organisms/planogramsGrid/fragments/listContainer';
import { Grid, Skeleton } from '@mui/material';
import { GridItemProps, GridScrollSeekPlaceholderProps } from 'react-virtuoso';
import { SkeletonContainer } from '@components/organisms/planogramsGrid/fragments/skeletonContainer';

export const gridComponents = {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
  List: ListContainer,
  // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
  Item: (props: GridItemProps) => (
    <Grid
      p={1}
      display="flex"
      justifyContent="center"
      width={{ xs: '50%', sm: '33%', md: '20%' }}
      {...props}
    />
  ),
  // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
  ScrollSeekPlaceholder: (props: GridScrollSeekPlaceholderProps) => (
    <SkeletonContainer width={{ xs: '50%', sm: '33%', md: '20%' }}>
      <Skeleton animation="wave" height={props.height} width="100%" />
    </SkeletonContainer>
  ),
};
