import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from '../../../../theme';

type Props = {
  name: string;
};

export const ProductFlagLabel: FC<Props> = ({ name }) => {
  return (
    <Box
      component="div"
      sx={{
        height: 18,
        padding: '4px 8px',
        backgroundColor: theme.palette.backgroundBlack.active,
        borderRadius: '2px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography sx={{ fontSize: 10, color: '#444444' }}>{name}</Typography>
    </Box>
  );
};
