import { useCallback } from 'react';
import {
  NavigateOptions,
  URLSearchParamsInit,
  useSearchParams,
} from 'react-router-dom';

export const usePersistStateSearchParam = (
  navigateOptions?: NavigateOptions
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setSearchParamsWithState = useCallback(
    (
      params:
        | URLSearchParamsInit
        | ((prev: URLSearchParams) => URLSearchParamsInit),
      navOptions?: NavigateOptions
    ) => {
      setSearchParams(params, { ...navigateOptions, ...navOptions });
    },
    [setSearchParams, navigateOptions]
  );
  return {
    searchParams,
    setSearchParamsWithState,
  };
};
