import { ActionVisible } from '@components/molecules/actionVisible/actionVisible';
import { BreadcrumbForSearch } from '@components/molecules/breadcrumbForSearch/breadcrumbForSearch';
import { RealogramsTableMenu } from '@components/molecules/realogramsTableMenu/realogramsTableMenu';
import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { StatusIcon } from '@components/organisms/statusIcon/statusIcon';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useGetRealogramPermission } from '@hooks/useGetRealogramPermission';
import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, TableCell } from '@mui/material';
import { useAppSelector } from '@store/index';
import { Format, format } from '@utils/date';
import { FC, useState } from 'react';
import { theme } from 'theme';
import { ShelvesViewMode } from 'types/common';
import { RealogramDirectory, RealogramDirectorySearch } from 'types/realogram';
import { DisplayIcon } from './displayIcon';

type Props = {
  isFilteredByViewed: boolean;
  realogramDirectory: RealogramDirectory | RealogramDirectorySearch;
  viewMode?: ShelvesViewMode;
  handleOpenDeleteDialog: (id: number) => void;
  handleFavoriteClick: () => void;
  handleSharePermission: () => void;
};

export const TableCells: FC<Props> = ({
  isFilteredByViewed,
  realogramDirectory,
  viewMode,
  handleOpenDeleteDialog,
  handleFavoriteClick,
  handleSharePermission,
}) => {
  const overFlowSx = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0px',
  };
  const { user: me } = useAppSelector((state) => state.Auth);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { name, owner, created_at, realogram_candidate_id } =
    realogramDirectory;
  const isStarred = realogramDirectory.favorite?.owner_id === me?.id;
  const { isLarger } = useBreakpoint();
  const status = realogramDirectory?.realogram_candidate?.status;
  const { isNotEnable: isNotCanReadRole } = useGetRealogramPermission({
    action: 'read_role',
    realogram: realogramDirectory,
    isCandidate: false,
    isCan: false,
  });
  const { isNotEnable: isNotCanDelete } = useGetRealogramPermission({
    action: 'delete',
    realogram: realogramDirectory,
    isCandidate: false,
    isCan: false,
  });
  const { isEnable: isCanUpdate } = useGetRealogramPermission({
    action: 'update',
    realogram: realogramDirectory,
    isCandidate: false,
    isCan: true,
  });
  const { isEnable: isCanRead } = useGetRealogramPermission({
    action: 'read',
    realogram: realogramDirectory,
    isCandidate: false,
    isCan: true,
  });
  return (
    <>
      <TableCell
        sx={{
          ...overFlowSx,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '56px',
          width: '40px',
          padding: '0px 8px',
        }}
      >
        <DisplayIcon realogramDirectory={realogramDirectory} />
      </TableCell>
      <TableCell
        sx={{
          ...overFlowSx,
          width: '100%',
          padding: '8px',
          fontWeight: 600,
        }}
      >
        {name}
        {isStarred && (
          <StarIcon
            htmlColor={theme.palette.confidence.middle}
            sx={{
              verticalAlign: 'text-bottom',
              width: 16,
              height: 16,
              ml: 0.5,
            }}
          />
        )}
        {(realogramDirectory as RealogramDirectorySearch).breadcrumb && (
          <BreadcrumbForSearch
            breadcrumbs={
              (realogramDirectory as RealogramDirectorySearch).breadcrumb
            }
            screenType="Main"
            fontSize="12px"
            isCanRead={isCanRead}
          />
        )}
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '32px' }}>
        {owner?.fullname ?? '-'}
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '32px' }}>
        {format(
          isFilteredByViewed
            ? (realogramDirectory as RealogramDirectorySearch)
                ?.current_user_accessed_at
            : created_at,
          Format.datetime
        )}
      </TableCell>
      <TableCell sx={{ padding: '0px' }}>
        <Box component="div" display="flex" justifyContent="center">
          {status ? <StatusIcon status={status} /> : '-'}
        </Box>
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '32px' }}>
        <ActionVisible isCanUpdate={isCanUpdate} isCanRead={isCanRead} />
      </TableCell>
      {/* 個別にクリックイベントを設定するため */}
      <TableCell
        onClick={(e) => e.stopPropagation()}
        sx={{
          padding: '0px',
          position: 'sticky',
          right: 0,
          boxShadow: isLarger ? '' : '0px 2px 8px 0px #0000000D',
          background: theme.palette.white.primary,
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            sx={{ width: 24, height: 24 }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreVert />
          </IconButton>
        </Box>
        <RealogramsTableMenu
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
          handleOpenDeleteDialog={() => {
            if (!realogram_candidate_id) return;
            handleOpenDeleteDialog(realogram_candidate_id);
          }}
          isFolder={realogramDirectory.type === 'directory'}
          isStarred={isStarred}
          handleFavoriteClick={handleFavoriteClick}
          handleSharePermission={() => {
            setAnchorEl(null);
            handleSharePermission();
          }}
          isCanReadShare={isNotCanReadRole}
          isCanDelete={isNotCanDelete}
          viewMode={viewMode}
        />
      </TableCell>
    </>
  );
};
