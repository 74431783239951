import {
  CategorySelectionModal,
  CreatePlanogramForm,
  EditPlanogramForm,
  MemoForm,
} from '@components/organisms';
import { ClonePlanogramForm } from '@components/organisms/clonePlanogramForm/clonePlanogramForm';
import { ComparisonSelectionModal } from '@components/organisms/comparisonSelectionModal/comparisonSelectionModal';
import { CreateOrRenameDirectoryForm } from '@components/organisms/createOrRenameDirectoryForm/createOrRenameDirectoryForm';
import { MoveDirectoryModal } from '@components/organisms/moveDirectoryModal/moveDirectoryModal';
import { SelectionStoreModal } from '@components/organisms/selectionStoreModal/selectionStoreModal';
import { LicenseModal } from '@components/organisms/sharePermissionModal/licenseModal';
import { CreateUserForm } from '@components/pages/users/fragments/createUserForm';
import { Box, Dialog, Typography } from '@mui/material';
import { selectModalProps } from '@reducers/modal/selector';
import { FC, createElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'reducers/modal';
import styles from './modal.module.css';
import { StoreBayModal } from '@components/organisms/storeBayModal/storeBayModal';

const modals = {
  memoForm: MemoForm,
  editPlanogram: EditPlanogramForm,
  createPlanogram: CreatePlanogramForm,
  clonePlanogram: ClonePlanogramForm,
  createOrRenameDirectoryForm: CreateOrRenameDirectoryForm,
  moveDirectory: MoveDirectoryModal,
  categorySelectionModal: CategorySelectionModal,
  comparisonSelectionModal: ComparisonSelectionModal,
  licenseModal: LicenseModal,
  selectionStoreModal: SelectionStoreModal,
  createUserModal: CreateUserForm,
  storeBayModal: StoreBayModal,
};

type Data = Record<string, unknown> | undefined;
export type ModalType = keyof typeof modals;
export type ModalInnerProps<T extends Data = Data> = {
  handleClose: () => void;
  data: T;
};

const noPaddingModals = [
  'moveDirectory',
  'categorySelectionModal',
  'comparisonSelectionModal',
  'selectionStoreModal',
];

export const AppModal = () => {
  const dispatch = useDispatch();
  const { type, title, data, open } = useSelector(selectModalProps);
  const handleClose = () => dispatch(closeModal());
  const hasPadding = !noPaddingModals.includes(type ?? '');
  return (
    <Dialog
      open={open}
      classes={{
        paper: styles.paper,
      }}
    >
      <Box
        component="div"
        sx={{
          padding: hasPadding ? '20px' : 0,
          minWidth: 300,
          maxWidth: 1200,
          overflowX: 'hidden',
        }}
      >
        <Box component="div" display="flex" alignItems="center">
          {title && (
            <Typography
              component="h2"
              sx={{
                fontWeight: 700,
                fontSize: '20px',
                marginBottom: '24px',
              }}
            >
              {title}
            </Typography>
          )}
        </Box>
        {type &&
          createElement(modals[type] as FC<ModalInnerProps>, {
            data,
            handleClose,
          })}
      </Box>
    </Dialog>
  );
};
