import { AreaMenuButtonGroup } from '@components/molecules/areaMenuButtonGroup/areaMenuButtonGroup';
import { Popper } from '@mui/material';
import { FC } from 'react';
import { SplitAreaType } from 'types/planogram';

type Props = {
  anchorEl: HTMLElement | null;
  open: boolean;
  isDisabledDeleteButton?: boolean;
  isDisabledSplitVerticalButton?: boolean;
  isDisabledSplitHorizontalButton?: boolean;
  handleClose: () => void;
  handleSplitArea: (type: SplitAreaType) => void;
};

export const SplitAreaPopper: FC<Props> = ({
  anchorEl,
  open,
  isDisabledDeleteButton,
  isDisabledSplitVerticalButton,
  isDisabledSplitHorizontalButton,
  handleClose,
  handleSplitArea,
}) => {
  return (
    <>
      <Popper
        anchorEl={anchorEl}
        open={open}
        placement="left-end"
        sx={{
          overflow: 'visible',
          position: 'relative',
          width: 0,
          height: 0,
          minWidth: 0,
          minHeight: 0,
          background: 'unset',
          boxShadow: 'unset',
          display: 'flex',
          '& .MuiMenu-list': {
            padding: 0,
            width: 'unset',
            height: 'unset',
          },
        }}
      >
        <AreaMenuButtonGroup
          isDisabledDeleteButton={isDisabledDeleteButton}
          isDisabledSplitVerticalButton={isDisabledSplitVerticalButton}
          isDisabledSplitHorizontalButton={isDisabledSplitHorizontalButton}
          handleDelete={handleClose}
          handleSplitArea={handleSplitArea}
        />
      </Popper>
    </>
  );
};
