// TODO: スキャナ階層構造完成後削除する
import { RealogramsTableMenu } from '@components/molecules/realogramsTableMenu/realogramsTableMenu';
import { StatusIcon } from '@components/organisms/statusIcon/statusIcon';
import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, TableCell } from '@mui/material';
import { Format, format } from '@utils/date';
import { useGetRealogramPermission } from '@hooks/useGetRealogramPermission';
import { FC, useState } from 'react';
import { RealogramCandidate } from 'types/realogram';

type Props = {
  realogram: RealogramCandidate;
  handleOpenDeleteDialog: (id: number) => void;
};

export const TableCells: FC<Props> = ({
  realogram,
  handleOpenDeleteDialog,
}) => {
  const overFlowSx = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0px',
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isNotEnable: isNotCanReadShare } = useGetRealogramPermission({
    action: 'read_role',
    realogram: realogram,
    isCandidate: false,
    isCan: false,
  });
  const { isNotEnable: isNotCanDelete } = useGetRealogramPermission({
    action: 'delete',
    realogram: realogram,
    isCandidate: false,
    isCan: false,
  });

  return (
    <>
      <TableCell
        sx={{
          ...overFlowSx,
          maxWidth: '203px',
          paddingLeft: '16px',
        }}
      >
        {realogram.store_bay.store.name}
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '32px' }}>
        {realogram.store_bay.name}
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '32px' }}>
        {realogram.owner.fullname}
      </TableCell>
      <TableCell sx={{ ...overFlowSx, maxWidth: '203px', paddingLeft: '32px' }}>
        {format(realogram.created_at, Format.datetime)}
      </TableCell>
      <TableCell sx={{ padding: '0px' }}>
        <Box component="div" display="flex" justifyContent="center">
          <StatusIcon status={realogram.status} />
        </Box>
      </TableCell>
      {/* 個別にクリックイベントを設定するため */}
      <TableCell
        onClick={(e) => e.stopPropagation()}
        width="64px"
        sx={{
          padding: '0px',
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            sx={{ width: 24, height: 24 }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreVert />
          </IconButton>
        </Box>
        <RealogramsTableMenu
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
          handleOpenDeleteDialog={() => handleOpenDeleteDialog(realogram.id)}
          handleFavoriteClick={() => console.log('placeholder')}
          isCanReadShare={isNotCanReadShare}
          isCanDelete={isNotCanDelete}
        />
      </TableCell>
    </>
  );
};
