import { Box, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import {
  convertMeterToMilliMeter,
  convertMeterToPixel,
} from '@utils/planogram';
import { FC } from 'react';

const divisionHeight = 18;
const offset = divisionHeight / 2;

type Props = {
  value: number;
  step?: number;
};

const calcValues = (bayHeight: number, step: number) => {
  const values: number[] = [];
  for (let i = 1; i <= bayHeight / step; i++) {
    values.push(i * step);
  }
  return values;
};

export const YAxis: FC<Props> = ({ value, step = 0.2 }) => {
  const values = calcValues(value, step);
  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        width: '100%',
        position: 'relative',
        top: offset,
      }}
    >
      {values.map((value) => (
        <Box
          component="div"
          key={value}
          sx={{
            position: 'absolute',
            bottom: convertMeterToPixel(value),
            display: 'flex',
            alignItems: 'center',
            left: -60,
            height: divisionHeight,
          }}
        >
          <Typography
            sx={{
              minWidth: 27,
              fontSize: 12,
              color: CommonColors.grayAxis,
              mr: 1,
              textAlign: 'right',
            }}
          >
            {convertMeterToMilliMeter(value)}
          </Typography>
          <Box
            component="div"
            sx={{
              width: 16,
              height: '1px',
              backgroundColor: CommonColors.grayAxis,
            }}
          />
        </Box>
      ))}
    </Box>
  );
};
