import { useCallback, useEffect, useState } from 'react';
import { PlanogramDirectoryItem } from 'types/planogram';
import { RealogramDirectoryItem } from 'types/realogram';

type Directory = PlanogramDirectoryItem | RealogramDirectoryItem;

type Props = {
  isLoadingOrFetching: boolean;
  data: {
    items: Directory[];
    selectedItemId?: number;
  };
  pagination: {
    offset: number;
    setOffset: (value: number) => void;
    limit: number;
    total: number;
  };
  options: {
    iForceHideLoadingSelectedItem: boolean;
    getId: (item: Directory) => number | undefined;
  };
};

export const useComparisonListRendering = ({
  isLoadingOrFetching,
  data,
  pagination,
  options,
}: Props) => {
  const { items, selectedItemId } = data;
  const { offset, setOffset, limit, total } = pagination;
  const { iForceHideLoadingSelectedItem, getId } = options;

  const [isLoadingSelectedItem, setIsLoadingSelectedItem] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>();

  const itemsLength = items.length;

  const isLoadingFirstPage = offset === 0 && isLoadingOrFetching;
  const isShowList =
    !isLoadingFirstPage && !!itemsLength && !isLoadingSelectedItem;

  const getNextPageData = useCallback(() => {
    const nextOffset = offset + limit;
    const shouldSkip = !items || nextOffset >= total || isLoadingOrFetching;
    if (shouldSkip) return;
    setOffset(nextOffset);
  }, [items, isLoadingOrFetching, limit, offset, setOffset, total]);

  /**
   * If the current page doesn't have the selected item, it will fetch the next page.
   */
  useEffect(() => {
    if (!selectedItemId || !itemsLength || isLoadingOrFetching) {
      return;
    }

    if (iForceHideLoadingSelectedItem) {
      setIsLoadingSelectedItem(false);
      return;
    }

    const foundIndex = (items || [])?.findIndex(
      (item) => getId(item) === selectedItemId
    );
    if (foundIndex < 0) {
      // not found selectedItem
      if (itemsLength === offset + limit) {
        // get data from next page
        getNextPageData();
      }
      // display list if all data is ready
      setIsLoadingSelectedItem(itemsLength !== total);
    } else {
      // display the page having selectedIndex
      setSelectedItemIndex(foundIndex);
      setIsLoadingSelectedItem(false);
    }
  }, [
    getId,
    getNextPageData,
    iForceHideLoadingSelectedItem,
    isLoadingOrFetching,
    items,
    itemsLength,
    limit,
    offset,
    selectedItemId,
    total,
  ]);

  return {
    isLoadingFirstPage,
    isShowList,
    selectedItemIndex,
    isLoadingSelectedItem,
    getNextPageData,
  };
};
