import { TextPaper } from '@components/molecules';
import { ProductFlagLabel } from '@components/organisms/realogramCandidatesList/fragments';
import { Stack } from '@mui/material';
import { FC } from 'react';
import { Product, ShelfDetailView } from '../../../../types/common';

type Props = {
  detailView: ShelfDetailView;
  product?: Product;
};

export const PlanogramProductCandidateOptionalItem: FC<Props> = ({
  detailView,
  product,
}) => {
  switch (detailView) {
    case 'default':
      return <></>;
    case 'productFlag':
      return (
        <Stack direction="row" spacing={0.5}>
          {product?.detail?.tags?.map((tag) => (
            <ProductFlagLabel key={tag} name={tag} />
          ))}
        </Stack>
      );
    case 'profit':
      return (
        <Stack direction="row" spacing={1} minHeight="44px">
          <TextPaper title="売上" text="" />
          <TextPaper title="販売個数" text="" />
          <TextPaper title="商品の導入率" text="" />
        </Stack>
      );
    default:
      return <></>;
  }
};
