import { queryClient } from '@api/query-client';
import { storeBaysApi } from '@api/services/storeBays';
import { PutStoreBayRequest, StoreBayResponse } from '@api/types/generated';
import {
  StoreBayFormData,
  StoreBayModalProps,
} from '@components/organisms/storeBayModal/storeBayModal';
import { useModal } from '@hooks/useModal';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { openToast } from '@reducers/toast';
import { useAppDispatch } from '@store/index';
import { useMutation } from '@tanstack/react-query';
import { objectToSnake } from 'ts-case-convert';
import { StoreBay } from 'types/storeBay';
import { storeBaysQueryKey } from '../fragments/storeBaysTable';
import { AxiosError } from 'axios';
import httpStatus from 'http-status';

export const useEditStoreBay = () => {
  const dispatch = useAppDispatch();
  const { showModal: showStoreBayModal } = useModal<StoreBayModalProps>(
    'storeBayModal',
    '什器編集'
  );
  const { mutateAsync: updateStoreBay } = useMutation<
    StoreBayResponse,
    Error,
    PutStoreBayRequest & { id: number }
  >({
    mutationFn: ({ id, ...params }) => storeBaysApi.updateStoreBay(id, params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [storeBaysQueryKey],
      });
    },
  });
  const handleEditStoreBay = (storeBay: StoreBay) => {
    const onSubmit = async (
      { sortOrder, storeSectionMasterId, ...props }: StoreBayFormData,
      onSuccess: VoidFunction
    ) => {
      dispatch(updateLoadingIndicatorState(true));
      try {
        await updateStoreBay({
          ...objectToSnake(props),
          sort_order: +sortOrder,
          store_section_master_id: storeSectionMasterId
            ? +storeSectionMasterId
            : undefined,
          // eslint-disable-next-line react/prop-types -- prop types is outdated
          bay_plan_id: parseInt(props.bayPlanId ?? ''),
          id: storeBay.id,
        });
        onSuccess();
        dispatch(
          openToast({
            type: 'success',
            message: '什器を変更しました。',
          })
        );
      } catch (error) {
        const axiosError = error as AxiosError;
        if (
          axiosError.isAxiosError &&
          axiosError.status === httpStatus.FORBIDDEN
        ) {
          dispatch(
            openToast({
              type: 'error',
              message: 'この店舗の編集権限がないため、什器を編集できません。',
            })
          );
        } else {
          dispatch(
            openToast({ type: 'error', message: '什器の変更に失敗しました。' })
          );
        }
        return Promise.reject(error);
      } finally {
        dispatch(updateLoadingIndicatorState(false));
      }
    };

    showStoreBayModal({
      storeBay,
      onSubmit,
      isEdit: true,
    });
  };
  return {
    handleEditStoreBay,
  };
};
