import { styled, Tabs } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { theme } from '../../../theme';

export const ScrollableTabs = styled(Tabs)(() => ({
  borderBottom: `1px solid ${CommonColors.light}`,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  '& .MuiTabScrollButton-root': {
    position: 'absolute',
    top: 0,
    width: 48,
    height: '100%',
    background: theme.palette.white.primary,
    boxShadow: '-2px 0px 8px rgba(0, 0, 0, 0.1)',
    opacity: 1,
    zIndex: 2,
    '&:first-of-type': {
      left: '-8px',
    },
    '&:last-child': {
      right: '-8px',
    },
    '&.Mui-disabled': {
      opacity: 0,
    },
  },
  '& .Mui-selected': {
    color: `${CommonColors.appBlue} !important`,
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-between',
  },
}));
