import { ModalInnerProps } from '@components/modal';
import { ModalActions } from '@components/organisms/modalActions/modalActions';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { updateErrorMessage } from '@reducers/modal';
import { selectModalProps } from '@reducers/modal/selector';
import { useAppDispatch, useAppSelector } from '@store/index';
import { isOnlySpaces } from '@utils/const';
import { getUserRole, roles } from '@utils/users';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { theme } from 'theme';
import { UserRole } from 'types/user';
import { UserFormData, schema } from '../types/userForm';

const textFieldErrorSx = {
  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: `${theme.palette.system.errorMain} !important`,
  },
  '& .Mui-error .MuiInputBase-input': {
    backgroundColor: `${theme.palette.system.errorBg}`,
  },
  '& input:-webkit-autofill': {
    // eslint-disable-next-line @typescript-eslint/naming-convention -- webkit
    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.system.errorBg} inset`,
  },
};

export type CreateUserFormProps = {
  onSubmit: (data: UserFormData) => void;
  errorMessage?: string;
};
type Props = ModalInnerProps<CreateUserFormProps>;

export const CreateUserForm: FC<Props> = ({
  data: { onSubmit },
  handleClose,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
  } = useForm<UserFormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      fullname: '',
      email: '',
    },
  });
  const { errorMessage } = useAppSelector(selectModalProps);

  const dispatch = useAppDispatch();

  const [fullname, email, role] = watch(['fullname', 'email', 'role']);

  const isDisabled =
    !fullname || !email || !role || !isValid || isOnlySpaces(fullname);

  useEffect(() => {
    if (errorMessage !== undefined && errors.email) {
      dispatch(updateErrorMessage(undefined));
    }
  }, [dispatch, errorMessage, errors.email]);
  return (
    <Box component="form" width={500} onSubmit={handleSubmit(onSubmit)}>
      <Typography
        mb={3}
        variant="body1"
        color={theme.palette.textBlack.primary}
      >
        入力いただいたメールアドレスに、仮パスワードとURLを記載したメールを送信します。
      </Typography>
      <Box component="div" mb={3}>
        <Controller
          name="fullname"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              fullWidth
              label="名前"
              size="small"
              error={!!errors.fullname}
              helperText={errors.fullname?.message}
            />
          )}
        />
      </Box>
      <Box component="div" mb={3}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              fullWidth
              label="メールアドレス"
              size="small"
              error={!!errors.email || !!errorMessage}
              helperText={
                (errors.email?.message || errorMessage) ?? errorMessage
              }
              sx={errors.email?.message || errorMessage ? textFieldErrorSx : {}}
            />
          )}
        />
      </Box>
      <Box component="div" mb={3}>
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <FormControl
              {...field}
              fullWidth
              error={!!errors.role}
              required
              size="small"
            >
              <InputLabel>アカウント権限</InputLabel>
              <Select
                label="アカウント権限"
                value={role}
                onChange={({ target: { value } }) => {
                  setValue('role', value as UserRole);
                }}
              >
                {roles.map((role, index) => (
                  <MenuItem key={index} value={role}>
                    {getUserRole(role)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Box>
      <ModalActions
        handleClose={handleClose}
        actionBtnTitle="追加"
        disabled={isDisabled}
      />
    </Box>
  );
};
