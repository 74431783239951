import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RealogramSelectedItem } from 'types/realogram';

type RealogramCandidateState = {
  selectedRealogramItem?: RealogramSelectedItem;
  ratio: number;
};

const initialState: RealogramCandidateState = {
  selectedRealogramItem: undefined,
  ratio: 0,
};

const realogramCandidateSlice = createSlice({
  name: 'realogramCandidate',
  initialState,
  reducers: {
    reset: () => initialState,
    updateSelectedRealogramItem: (
      state: RealogramCandidateState,
      action: PayloadAction<RealogramSelectedItem | undefined>
    ) => {
      state.selectedRealogramItem = action.payload;
    },
    updateRatio: (
      state: RealogramCandidateState,
      action: PayloadAction<number>
    ) => {
      state.ratio = action.payload;
    },
  },
});

export const { updateSelectedRealogramItem, reset, updateRatio } =
  realogramCandidateSlice.actions;

export const RealogramCandidateReducer = realogramCandidateSlice.reducer;
