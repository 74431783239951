import { FC } from 'react';

export const CropRectangle: FC = () => {
  return (
    <svg width="500" height="200" style={{ border: '1px solid #000000;' }}>
      <rect
        x="40"
        y="40"
        width="320"
        height="80"
        fill="white"
        fillOpacity={0.4}
        stroke="white"
      />

      <circle cx="40" cy="40" r="5" fill="blue" />
      <circle cx="360" cy="40" r="5" fill="blue" />
      <circle cx="40" cy="120" r="5" fill="blue" />
      <circle cx="360" cy="120" r="5" fill="blue" />

      <circle cx="200" cy="40" r="5" fill="blue" />
      <circle cx="200" cy="120" r="5" fill="blue" />
      <circle cx="40" cy="80" r="5" fill="blue" />
      <circle cx="360" cy="80" r="5" fill="blue" />
    </svg>
  );
};
