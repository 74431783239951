import { queryClient } from '@api/query-client';
import { storeBaysApi } from '@api/services/storeBays';
import {
  StoreBayFormData,
  StoreBayModalProps,
} from '@components/organisms/storeBayModal/storeBayModal';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { useAppDispatch } from '@store/index';
import { useMutation } from '@tanstack/react-query';
import { storeBaysQueryKey } from '../fragments/storeBaysTable';
import { openToast } from '@reducers/toast';
import { useModal } from '@hooks/useModal';
import { AxiosError } from 'axios';
import httpStatus from 'http-status';

export const useCreateStoreBay = () => {
  const dispatch = useAppDispatch();

  const { mutateAsync: createStoreBay } = useMutation({
    mutationFn: storeBaysApi.createStoreBay,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [storeBaysQueryKey],
      });
    },
  });
  const { showModal: showStoreBayModal } = useModal<StoreBayModalProps>(
    'storeBayModal',
    '什器追加'
  );
  const handleCreateStoreBay = (storeId?: number) => {
    const onSubmit = async (
      data: StoreBayFormData,
      onSuccess: VoidFunction
    ) => {
      const {
        productDivisionCode,
        sortOrder,
        storeSectionMasterId,
        name,
        bayPlanId,
      } = data;

      if (!storeId) return;
      dispatch(updateLoadingIndicatorState(true));
      try {
        await createStoreBay({
          store_id: storeId,
          product_division_code: productDivisionCode,
          name,
          sort_order: parseInt(sortOrder),
          store_section_master_id: storeSectionMasterId
            ? parseInt(storeSectionMasterId)
            : undefined,
          bay_plan_id: parseInt(bayPlanId ?? ''),
        });
        onSuccess();
        dispatch(
          openToast({
            type: 'success',
            message: '什器を追加しました。',
          })
        );
      } catch (error) {
        const axiosError = error as AxiosError;
        if (
          axiosError.isAxiosError &&
          axiosError.status === httpStatus.FORBIDDEN
        ) {
          dispatch(
            openToast({
              type: 'error',
              message: 'この店舗の編集権限がないため、什器を作成できません。',
            })
          );
        } else {
          dispatch(
            openToast({ type: 'error', message: '什器の追加に失敗しました。' })
          );
        }

        return Promise.reject(error);
      } finally {
        dispatch(updateLoadingIndicatorState(false));
      }
    };
    showStoreBayModal({
      onSubmit,
    });
  };
  return { handleCreateStoreBay };
};
