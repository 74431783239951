import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const RotatePlusZIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      sx={{
        width: '24px',
        height: '24px',
        viewBox: '0 0 24px 24px',
        fill: 'none',
      }}
    >
      <rect x="5" y="11" width="10" height="2" fill="#444444" />
      <rect x="18" y="11" width="3" height="2" fill="#444444" />
      <path
        d="M7.91483 7.38264C8.82044 5.33601 10.3119 4 11.9985 4C14.7599 4 16.9985 7.58172 16.9985 12C16.9985 16.4183 14.7599 20 11.9985 20C10.0938 20 8.43796 18.2961 7.59375 15.7891"
        stroke="#444444"
        strokeWidth="2"
      />
      <path d="M7 4V8H11" stroke="#444444" strokeWidth="2" />
    </SvgIcon>
  );
};
