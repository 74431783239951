import { subject } from '@casl/ability';
import { objectToSnake } from 'ts-case-convert';
import { paths } from 'types/api';
import {
  baseApi,
  DirectoryRolesTags,
  KeysToCamelcase,
  PlanogramTags,
} from '../baseApi';

export type GetPlanogramsParams = KeysToCamelcase<
  paths['/api/v1/planograms']['get']['parameters']['query']
>;
type GetPlanogramsResponse =
  paths['/api/v1/planograms']['get']['responses']['200']['content']['application/json'];
type GetPlanogramParams = KeysToCamelcase<
  paths['/api/v1/planograms/{planogram_id}']['get']['parameters']['path']
>;
type GetPlanogramResponse =
  paths['/api/v1/planograms/{planogram_id}']['get']['responses']['200']['content']['application/json'];
type PutPlanogramResponse =
  paths['/api/v1/planograms/{planogram_id}']['put']['responses']['200']['content']['application/json'];

export type PutPlanogramRequest =
  paths['/api/v1/planograms/{planogram_id}']['put']['requestBody']['content']['application/json'];

type PostPlanogramResponse =
  paths['/api/v1/planograms']['post']['responses']['200']['content']['application/json'];
type PostPlanogramParams =
  paths['/api/v1/planograms']['post']['requestBody']['content']['application/json'];

type PreviewPlanogramResponse =
  paths['/api/v1/planograms/preview']['post']['responses']['200']['content']['application/json'];
type PreviewPlanogramParams =
  paths['/api/v1/planograms/preview']['post']['requestBody']['content']['application/json'];

type DeletePlanogramParams = KeysToCamelcase<
  paths['/api/v1/planograms/{planogram_id}']['delete']['parameters']['path']
>;
type DeletePlanogramResponse =
  paths['/api/v1/planograms/{planogram_id}']['delete']['responses']['200']['content']['application/json'];

type RealToPlanParams = KeysToCamelcase<
  paths['/api/v1/planograms/real_to_plan']['post']['requestBody']['content']['application/json']
>;
type RealToPlanResponse =
  paths['/api/v1/planograms/real_to_plan']['post']['responses']['200']['content']['application/json'];

type ClosePlanogramParams = KeysToCamelcase<
  paths['/api/v1/planograms/clone']['post']['requestBody']['content']['application/json']
>;

type ClosePlanogramResponse =
  paths['/api/v1/planograms/clone']['post']['responses']['200']['content']['application/json'];

type OrganizationStatusesResponse =
  paths['/api/v1/planograms/organization_statuses']['get']['responses']['200']['content']['application/json'];

// see) https://staging.shelf.pfn.dev/docs#/planograms
export const planogramsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    listPlanograms: builder.query<GetPlanogramsResponse, GetPlanogramsParams>({
      query: (params) => ({
        url: 'planograms',
        params: params ? objectToSnake(params) : undefined,
      }),
      transformResponse: (response: GetPlanogramsResponse) => {
        return {
          ...response,
          planograms: response.planograms?.map((planogram) =>
            subject('Planogram', planogram)
          ),
        };
      },
      providesTags: [PlanogramTags.PtsUploadTag],
    }),
    getPlanogram: builder.query<GetPlanogramResponse, GetPlanogramParams>({
      query: (params) => ({
        url: `planograms/${params.planogramId}`,
      }),
      transformResponse: (response: GetPlanogramResponse) => ({
        planogram: subject('Planogram', response.planogram),
      }),
      providesTags: [
        PlanogramTags.UpdatePlanogram,
        DirectoryRolesTags.UpdateDirectoryRole,
      ], //NOTE:編集後にrefetchが行われないためタグを追加
    }),
    putPlanogram: builder.mutation<
      PutPlanogramResponse,
      { id: number; body: PutPlanogramRequest }
    >({
      query: ({ id, body }) => ({
        url: `planograms/${id}`,
        method: 'PUT',
        body: {
          ...body,
          bay_plan_code_id: body.bay_plan_code_id
            ? body.bay_plan_code_id
            : null, //undefinedをリクエストした時payloadに入らないためnullを入れる
        },
      }),
      invalidatesTags: [PlanogramTags.UpdatePlanogram],
    }),
    createPlanogram: builder.mutation<
      PostPlanogramResponse,
      PostPlanogramParams
    >({
      query: (params) => ({
        url: 'planograms',
        method: 'POST',
        body: {
          name: params.name,
          bay_plan_id: params.bay_plan_id,
          store_bay_id: params.store_bay_id,
          plan: params.plan,
          custom_field: params.custom_field,
          bay_plan_code_id: params.bay_plan_code_id,
          directory_parent_id: params.directory_parent_id,
        },
      }),
      invalidatesTags: [PlanogramTags.CreatePlanogram],
    }),
    previewPlanogram: builder.mutation<
      PreviewPlanogramResponse,
      PreviewPlanogramParams
    >({
      query: (body) => ({
        url: 'planograms/preview',
        method: 'POST',
        body: body,
      }),
    }),
    deletePlanogram: builder.mutation<
      DeletePlanogramResponse,
      DeletePlanogramParams
    >({
      query: (params) => ({
        url: `planograms/${params.planogramId}`,
        method: 'DELETE',
      }),
    }),
    realToPlan: builder.mutation<RealToPlanResponse, RealToPlanParams>({
      query: (body) => ({
        url: 'planograms/real_to_plan',
        method: 'POST',
        body: {
          realogram_candidate_id: body.realogramCandidateId,
          name: body.name,
          bay_plan_id: body.bayPlanId,
        },
      }),
    }),
    closePlanogram: builder.mutation<
      ClosePlanogramResponse,
      ClosePlanogramParams
    >({
      query: ({ originPlanogramId, name, bayPlanId, directoryParentId }) => ({
        url: 'planograms/clone',
        method: 'POST',
        body: {
          name,
          origin_planogram_id: originPlanogramId,
          bay_plan_id: bayPlanId,
          directory_parent_id: directoryParentId,
        },
      }),
      invalidatesTags: [PlanogramTags.CreatePlanogram],
    }),
    organizationStatuses: builder.query<OrganizationStatusesResponse, void>({
      query: () => ({
        url: 'planograms/organization_statuses',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useListPlanogramsQuery,
  useGetPlanogramQuery,
  usePutPlanogramMutation,
  useCreatePlanogramMutation,
  usePreviewPlanogramMutation,
  useDeletePlanogramMutation,
  useRealToPlanMutation,
  useClosePlanogramMutation,
  useOrganizationStatusesQuery,
} = planogramsApi;
