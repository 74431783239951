import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ProductState = {
  comparisonSameIds: number[];
};

const initialState: ProductState = {
  comparisonSameIds: [],
};

const productSlice = createSlice({
  name: 'scanner',
  initialState,
  reducers: {
    setComparisonSameIds: (
      state: ProductState,
      action: PayloadAction<number[]>
    ) => {
      state.comparisonSameIds = action.payload;
    },
    reset: () => initialState,
  },
});

export const { setComparisonSameIds, reset } = productSlice.actions;

export const ProductReducer = productSlice.reducer;
