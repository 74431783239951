import { Box, Stack, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { faceFrontThreshold, foregroundThreshold } from '@utils/const';
import { isFaceUpOk } from '@utils/realogram';
import { FC } from 'react';
import { ProductFaceEvaluation } from 'types/realogram';
import { ScoreTypography } from './scoreTypography';

type Props = {
  evaluation: Required<ProductFaceEvaluation>;
};

export const EvaluationScore: FC<Props> = ({ evaluation }) => {
  const isOk = isFaceUpOk(evaluation);
  const color = isOk ? CommonColors.green : CommonColors.deepRed;
  return (
    <Stack direction="row" gap={1.5}>
      <Box
        component={Typography}
        sx={{
          width: 33,
          height: 40,
          borderRadius: '4px',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 14,
          fontWeight: 700,
          backgroundColor: isOk ? '#E5F2EB' : '#FCEFEF',
          color,
        }}
      >
        {isOk ? 'OK' : 'NG'}
      </Box>
      <Stack gap={0.5}>
        <ScoreTypography
          label="正面"
          value={evaluation.face_front_score}
          threshold={faceFrontThreshold}
        />
        <ScoreTypography
          label="最前列"
          value={evaluation.foreground_score}
          threshold={foregroundThreshold}
        />
      </Stack>
    </Stack>
  );
};
