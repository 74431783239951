import { FC } from 'react';
import { Dialog } from '@mui/material';
import { PasswordInput } from '@components/organisms/passwordInput/passwordInput';

type Props = {
  open: boolean;
  isLoading: boolean;
  isError: boolean;
  next: (password: string) => void;
  handleClose: (isPasswordInputOpen: boolean) => void;
};

// 二要素認証削除時のパスワード入力ダイアログ
export const PasswordInputDialog: FC<Props> = ({
  open,
  isLoading,
  isError,
  next,
  handleClose,
}) => {
  return (
    <Dialog open={open}>
      <PasswordInput
        isLoading={isLoading}
        passwordFailed={isError}
        next={next}
        handleClose={() => handleClose(false)}
      />
    </Dialog>
  );
};
