import { FormPaper } from '@components/molecules/formPaper/formPaper';
import { Button, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
  isInitial?: boolean;
  prev: () => void;
  navigateLogin: () => void;
};

export const MfaHelp: FC<Props> = ({
  isInitial = false,
  prev,
  navigateLogin,
}) => {
  return (
    <FormPaper title="確認コード入力">
      <Typography mb={3}>
        デバイスを変更、紛失したり、アプリケーションを削除した場合や、正しい確認コードがわからない場合には、システム側での再設定が必要となります。
      </Typography>
      <Typography mb={4}>
        再設定の方法については、管理者にお問い合わせください。
      </Typography>
      <Button fullWidth onClick={prev}>
        前の画面に戻る
      </Button>
      <Button fullWidth onClick={navigateLogin}>
        {isInitial ? 'ログインに戻る' : 'ホームに戻る'}
      </Button>
    </FormPaper>
  );
};
