import { FC } from 'react';
import { theme } from 'theme';
import { Box, Button, Typography } from '@mui/material';
import CalendarViewMonthOutlinedIcon from '@mui/icons-material/CalendarViewMonthOutlined';
import { RealogramType } from 'types/realogram';
import { ShelfIconPrimary } from '@components/molecules/shelfIconPrimary/shelfIconprimary';

type Props = {
  realogramType: RealogramType;
  isSameTypeSelected: boolean;
  isSetSelectedRealogramType: (realogarmType: RealogramType) => void;
};

export const RealogramTypeSelectButton: FC<Props> = ({
  realogramType,
  isSameTypeSelected,
  isSetSelectedRealogramType,
}) => {
  const isFlatBtn = realogramType === 'flat';
  return (
    <Box component="div" textAlign="center" gap={3} borderRadius={1}>
      <Button
        variant="outlined"
        sx={{
          p: 3,
          gap: 3,
          backgroundColor: isSameTypeSelected
            ? `${theme.palette.primary.selected} !important`
            : 'white',
          borderColor: isSameTypeSelected
            ? theme.palette.primary.main
            : theme.palette.dividerBlack.dark,
          height: '100%',
        }}
        onClick={() => isSetSelectedRealogramType(realogramType)}
      >
        <Box
          component="div"
          flexDirection="column"
          alignItems="center"
          display="flex"
          gap={2}
        >
          {isFlatBtn ? (
            <CalendarViewMonthOutlinedIcon
              sx={{
                fontSize: 45,
                pt: 1,
                color: isSameTypeSelected
                  ? theme.palette.primary.main
                  : theme.palette.grey[400],
              }}
            />
          ) : (
            <ShelfIconPrimary
              sx={{
                fontSize: 45,
                pt: 1,
                color: isSameTypeSelected
                  ? theme.palette.primary.main
                  : theme.palette.grey[400],
              }}
            />
          )}
          <Typography
            variant="subtitle1"
            color={
              isSameTypeSelected
                ? theme.palette.primary.main
                : theme.palette.textBlack.primary
            }
          >
            {isFlatBtn ? '平置きゴンドラ' : '通常ゴンドラ'}
          </Typography>
          <Typography
            variant="body2"
            color={
              isSameTypeSelected
                ? theme.palette.primary.main
                : theme.palette.textBlack.primary
            }
            width="253px"
          >
            {isFlatBtn ? (
              <>
                商品を平置きするゴンドラです。
                <br />
                冷凍食品やアイスなどに使用されます。
              </>
            ) : (
              <>
                一般的なゴンドラです。
                <br />
                飲料水やお菓子などに使用されます。
              </>
            )}
          </Typography>
        </Box>
      </Button>
    </Box>
  );
};
