import { ErrorMessage } from '@components/molecules';
import { FormPaper } from '@components/molecules/formPaper/formPaper';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { paths } from '@utils/const';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { InferType, object, string } from 'yup';
import { CancelButton } from '../../pages/mfa/fragments/steps/cancelButton';

const schema = object({
  password: string().required('パスワードを入力してください。'),
});

type FormData = InferType<typeof schema>;

type Props = {
  passwordFailed: boolean;
  isLoading: boolean;
  next: (password: FormData['password']) => void;
  handleClose?: () => void;
};

export const PasswordInput: FC<Props> = ({
  passwordFailed,
  isLoading,
  next,
  handleClose,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: { password: '' },
  });

  const onSubmit = (data: FormData) => next(data.password);

  return (
    <FormPaper title="パスワード入力">
      {passwordFailed && (
        <ErrorMessage message="正しいパスワードを入力してください。" />
      )}
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Typography mb={2}>確認のためパスワードを入力してください。</Typography>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="パスワード"
              value={field.value}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              type="password"
              size="small"
              autoComplete="current-password"
            />
          )}
        />
        <Typography>
          <Link
            component={RouterLink}
            to={paths.password.reset}
            textAlign="center"
            display="block"
            mt={2}
          >
            パスワードをお忘れの方
          </Link>
        </Typography>
        <Button
          variant="contained"
          sx={{ my: 3 }}
          fullWidth
          type="submit"
          disabled={!isValid || isLoading}
        >
          次へ
        </Button>
      </Box>
      {handleClose ? (
        //NOTE: passwordModalの時のみ関数が異なる
        <Button fullWidth onClick={handleClose}>
          キャンセル
        </Button>
      ) : (
        <CancelButton />
      )}
    </FormPaper>
  );
};
