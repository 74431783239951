import { CancelButton } from '@components/molecules/cancelButton/cancelButton';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  updateProductMockupImage,
  useProductMockupImages,
} from '@hooks/useProductMockupImages';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { selectToken } from '@reducers/auth/selectors';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import {
  useDeleteProductMockupImageMutation,
  useUpdateProductAdjustersMutation,
} from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  convertMilliMeterToMeter,
  productFaceDirection,
  productTags,
  toastMessages,
} from '@utils/const';
import { convertMeterToMilliMeter } from '@utils/planogram';
import { hasProductTag } from '@utils/product';
import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Product } from 'types/common';
import { ProductFaceDirection } from 'types/products';
import { boolean, number, object, string } from 'yup';
import { ProductInfoDetailEditor } from './fragments/productInfoDetailEditor';

type Props = {
  open: boolean;
  handleClose: () => void;
  product: Product;
  refetchProduct: () => void;
};

const maxLength = 9999;
const minLength = 1;
const modalMarginTopAndBottom = 64;

const sizeSchema = number().max(maxLength).min(minLength).integer();

const maxRetries = 2;
const retryDelayMs = 1000;

export const schema = object({
  isHookOn: boolean().required(),
  width: sizeSchema,
  height: sizeSchema,
  depth: sizeSchema,
  memo: string(),
});

export type ProductFormData = {
  isHookOn: boolean;
  width: number;
  height: number;
  depth: number;
  memo: string;
  productTagsArray: boolean[];
  faceDirection: ProductFaceDirection;
};

export const ProductInformationModal: FC<Props> = ({
  open,
  handleClose,
  product,
  refetchProduct,
}) => {
  // TODO: リファクタ
  const topRef = useRef<HTMLInputElement>(null);
  const leftRef = useRef<HTMLInputElement>(null);
  const frontRef = useRef<HTMLInputElement>(null);
  const rightRef = useRef<HTMLInputElement>(null);
  const backRef = useRef<HTMLInputElement>(null);
  const bottomRef = useRef<HTMLInputElement>(null);
  const refs = {
    top: topRef,
    left: leftRef,
    front: frontRef,
    right: rightRef,
    back: backRef,
    bottom: bottomRef,
  };
  const isNot3DScanned = hasProductTag('not_3D_scanned', product.detail?.tags);
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken) ?? '';
  const { uploadedFiles, deleteImage, changeImage, rotateImage, resetImage } =
    useProductMockupImages(product.id, token, isNot3DScanned);
  const [updateProductAdjuster] = useUpdateProductAdjustersMutation();
  const [deleteProductMockupImage] = useDeleteProductMockupImageMutation();
  const methods = useForm<ProductFormData>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      isHookOn: false,
    },
  });

  const defaultValues = useMemo(() => {
    const productSizes =
      product.shape?.size.display_size ?? product.shape?.size.actual;

    return {
      width: productSizes && convertMeterToMilliMeter(productSizes.width),
      height: productSizes && convertMeterToMilliMeter(productSizes.height),
      depth: productSizes && convertMeterToMilliMeter(productSizes.depth),
      productTagsArray: productTags.map(
        (tag) => !!product.detail?.tags?.includes(tag)
      ),
      faceDirection: product.detail?.face_direction ?? 'front',
    };
  }, [product]);
  const { setValue, watch } = methods;
  const [width, height, depth, faceDirection] = watch([
    'width',
    'height',
    'depth',
    'faceDirection',
  ]);

  const resetValues = useCallback(() => {
    if (defaultValues.width) setValue('width', defaultValues.width);
    if (defaultValues.height) setValue('height', defaultValues.height);
    if (defaultValues.depth) setValue('depth', defaultValues.depth);
    setValue('productTagsArray', defaultValues.productTagsArray);
    setValue('faceDirection', defaultValues.faceDirection);
  }, [
    defaultValues.width,
    defaultValues.height,
    defaultValues.depth,
    defaultValues.productTagsArray,
    defaultValues.faceDirection,
    setValue,
  ]);

  useEffect(() => {
    resetValues();
  }, [resetValues]);

  const updateMockupState = async (face: ProductFaceDirection) => {
    const file = uploadedFiles[face]?.fileObj;
    if (file) {
      const res = await updateProductMockupImage(token, product.id, face, file);
      if (!res.ok) {
        return Promise.reject(new Error('Update failed'));
      }
    } else if (
      uploadedFiles[face].hasOriginalFile &&
      !uploadedFiles[face].originalFile
    ) {
      const res = await deleteProductMockupImage({
        productId: product.id,
        faceFront: face,
      }).unwrap();
      // Note: レスポンスのnull(空)のみ許可
      if (res !== null) {
        return Promise.reject(new Error('Delete failed'));
      }
    }
  };

  const retryRefetchProduct = async (
    retries: number = maxRetries,
    delay: number = retryDelayMs,
    currentTry = 0
  ): Promise<void> => {
    try {
      refetchProduct();
    } catch (e) {
      if (currentTry >= retries - 1) {
        throw new Error('Max retries reached for refetchProduct');
      }
      await new Promise((resolve) => setTimeout(resolve, delay));
      return retryRefetchProduct(retries, delay, currentTry + 1);
    }
  };

  // todo: retryRefetchProductのエラーハンドリング検討
  const onSubmit = async (data: ProductFormData) => {
    dispatch(updateLoadingIndicatorState(true));
    let uploadSuccessOccurred = false;
    try {
      await updateProductAdjuster({
        product_id: product.id,
        width: convertMilliMeterToMeter(data.width),
        height: convertMilliMeterToMeter(data.height),
        depth: convertMilliMeterToMeter(data.depth),
        face_direction: data.faceDirection,
      }).unwrap();
      await Promise.all(
        productFaceDirection.map((face) => updateMockupState(face))
      );
      uploadSuccessOccurred = true;
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.saveProduct,
        })
      );
      handleClose();
    } catch (e) {
      console.log(e);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.saveProduct,
        })
      );
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }

    if (uploadSuccessOccurred) {
      try {
        await retryRefetchProduct(maxRetries, retryDelayMs, 0);
        reset();
      } catch (e) {
        console.log('retryRefetchProduct after max retries:', e);
      }
    }
  };
  const [selectedFace, setSelectedFace] = useState<ProductFaceDirection>();

  const handleClickFace = (face: ProductFaceDirection) => {
    setSelectedFace(face);
    if (
      uploadedFiles[face].file ||
      uploadedFiles[face].originalFile ||
      !refs[face]
    )
      return;
    refs[face].current?.click();
  };

  const handleDeleteImage = () => {
    if (!selectedFace) return;
    deleteImage(selectedFace);
    setSelectedFace(undefined);
  };

  const handleChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (!selectedFace || !event.target.files) return;
    changeImage(selectedFace, event.target.files);
  };

  const handleClickChange = () => {
    if (!selectedFace || !refs[selectedFace]) return;
    refs[selectedFace].current?.click();
  };

  const handleClickRotate = () => {
    if (!selectedFace) return;
    rotateImage(selectedFace);
  };

  const reset = () => {
    void resetImage();
    setSelectedFace(undefined);
    setValue('faceDirection', defaultValues.faceDirection);
    resetValues();
  };

  const isValidSizeFormat = {
    width: sizeSchema.isValidSync(width),
    height: sizeSchema.isValidSync(height),
    depth: sizeSchema.isValidSync(depth),
  };

  const disabled =
    !isValidSizeFormat.width ||
    !isValidSizeFormat.height ||
    !isValidSizeFormat.depth;
  const onDisableKeyEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') e.preventDefault();
  };
  return (
    <FormProvider {...methods}>
      <Dialog
        open={open}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            maxWidth: 1114,
            maxHeight: `calc(100vh-${modalMarginTopAndBottom}px)`,
          },
        }}
      >
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          onKeyDown={onDisableKeyEnter}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            overflowY: 'hidden',
          }}
        >
          <DialogContent
            sx={{
              p: 0,
              pt: 1,
            }}
            dividers
          >
            <DialogTitle fontWeight="bold">商品編集</DialogTitle>
            <Box component="div">
              {/* TODO inputの場所について検討 */}
              {productFaceDirection.map((face) => (
                <input
                  key={face}
                  type="file"
                  accept="image/png, image/jpeg"
                  ref={refs[face]}
                  onChange={(e) => handleChangeImage(e)}
                  onClick={(e) => {
                    e.currentTarget.value = '';
                  }}
                  style={{ display: 'none' }}
                />
              ))}
              <ProductInfoDetailEditor
                product={product}
                uploadedFiles={uploadedFiles}
                directionTabValue={faceDirection}
                handleChangeDirectionTabValue={(tab) =>
                  setValue('faceDirection', tab)
                }
                handleClickFace={handleClickFace}
                handleClickChange={handleClickChange}
                handleDeleteImage={handleDeleteImage}
                selectedFace={selectedFace}
                handleClickRotate={handleClickRotate}
                isValidSizeFormat={isValidSizeFormat}
              />
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              alignItems: 'right',
              padding: 2,
            }}
          >
            <CancelButton
              onClick={() => {
                reset();
                handleClose();
              }}
            />
            <Button variant="contained" type="submit" disabled={disabled}>
              保存
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
};
