import { ScoreHighLabel } from '@components/molecules/scoreHighLabel/scoreHighLabel';
import { ScoreLowLabel } from '@components/molecules/scoreLowLabel/scoreLowLabel';
import { ScoreMiddleLabel } from '@components/molecules/scoreMiddleLabel/scoreMiddleLabel';
import { FC } from 'react';
import { ConfidenceLevel } from 'types/realogram';

type Props = { confidenceLevel: ConfidenceLevel };

export const RealogramScoreLabel: FC<Props> = ({ confidenceLevel }) => {
  if (confidenceLevel === 'high') return <ScoreHighLabel />;
  if (confidenceLevel === 'medium') return <ScoreMiddleLabel />;
  return <ScoreLowLabel />;
};
