import {
  formatNumberToYen,
  profitTabGrossProfit,
  profitTabQuantity,
  profitTabSales,
} from '@utils/const';
import { Format, format } from '@utils/date';
import { calculateReportTotal } from '@utils/product';
import { ProfitTab } from 'types/common';
import { ProductSalesReport } from 'types/products';

export const profitComparisonTab = 'area_lv2';
export const secondWeekIndex = 1;
export const thirdWeekIndex = 2;
export const fourthWeekIndex = 3;
export const maxReportIndex = 4;
export const getSalesDataByWeek = (
  weekNumber: number,
  reports?: ProductSalesReport[],
  profitTab?: ProfitTab,
  productId?: number
) => {
  // profitTab can be 0 so we check by string
  if (!reports || !profitTab?.toString() || !productId) return;
  const product = reports[weekNumber]?.products.find(
    (product) => product.product_id === productId
  );
  switch (profitTab) {
    case profitTabSales:
      return product?.gross_sales_price != null
        ? formatNumberToYen(product?.gross_sales_price)
        : '-';
    case profitTabQuantity:
      return product?.sales_count != null
        ? product?.sales_count.toString()
        : '-';
    case profitTabGrossProfit:
      return product?.gross_profit_price != null
        ? formatNumberToYen(product?.gross_profit_price)
        : '-';
    default:
      break;
  }
};

export const getSalesDataSummary = (
  reports?: ProductSalesReport[],
  profitTab?: ProfitTab,
  productId?: number
) => {
  // profitTab can be 0 so we check by string
  if (!reports || !profitTab?.toString() || !productId) return;
  switch (profitTab) {
    case profitTabSales: {
      const grossSalesPrice = calculateReportTotal(
        'gross_sales_price',
        reports,
        productId
      );
      return !grossSalesPrice ? '-' : formatNumberToYen(grossSalesPrice);
    }
    case profitTabQuantity: {
      const salesCount = calculateReportTotal(
        'sales_count',
        reports,
        productId
      );
      return !salesCount ? '-' : salesCount.toString();
    }
    case profitTabGrossProfit: {
      const grossProfitPrice = calculateReportTotal(
        'gross_profit_price',
        reports,
        productId
      );
      return !grossProfitPrice ? '-' : formatNumberToYen(grossProfitPrice);
    }
    default:
      break;
  }
};

export const salesDataDateFrom = (report?: ProductSalesReport) => {
  if (!report) return '';
  return `${format(
    report.summary.aggregation_period.start_date,
    Format.dateWithoutYear
  )}~`;
};

export const getComparisonLabel = (profitComparisonTab: string) => {
  switch (profitComparisonTab) {
    case 'area_lv2':
      return 'DO平均';
    case 'area_lv1':
      return 'ZO平均';
    default:
      return '全国';
  }
};
