import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const DirectoryIcon: FC<SvgIconProps> = (props) => {
  const { width = 56, height = 57 } = props;
  return (
    <SvgIcon
      viewBox={`0 0 ${width} ${height}`}
      sx={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      {...props}
    >
      <path
        d="M23.5319 9.3335H9.5319C6.96523 9.3335 4.88857 11.4335 4.88857 14.0002L4.86523 42.0002C4.86523 44.5668 6.96523 46.6668 9.5319 46.6668H46.8652C49.4319 46.6668 51.5319 44.5668 51.5319 42.0002V18.6668C51.5319 16.1002 49.4319 14.0002 46.8652 14.0002H28.1986L23.5319 9.3335Z"
        fill="black"
        fillOpacity="0.28"
      />
    </SvgIcon>
  );
};
