import { paths } from 'types/api';
import { AnalyticsTags, KeysToCamelcase, baseApi } from '../baseApi';

export type GetRealogramAnalyticsResponse =
  paths['/api/v1/analytics/products/sales/timeseries_for_realogram']['get']['responses']['200']['content']['application/json'];

type GetRealogramAnalyticsParams = KeysToCamelcase<
  paths['/api/v1/analytics/products/sales/timeseries_for_realogram']['get']['parameters']['query']
>;

export type EstimateForPlanogramResponse =
  paths['/api/v1/analytics/products/sales/estimate_for_planogram']['post']['responses']['200']['content']['application/json'];
type EstimateForPlanogramParams = KeysToCamelcase<
  paths['/api/v1/analytics/products/sales/estimate_for_planogram']['post']['requestBody']['content']['application/json']
>;
export type EstimateForRealogramResponse =
  paths['/api/v1/analytics/products/sales/estimate_for_realogram']['post']['responses']['200']['content']['application/json'];
type EstimateForRealogramParams = KeysToCamelcase<
  paths['/api/v1/analytics/products/sales/estimate_for_realogram']['post']['requestBody']['content']['application/json']
>;

export const analyticsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRealogramAnalytics: builder.query<
      GetRealogramAnalyticsResponse,
      GetRealogramAnalyticsParams
    >({
      query: (params) => ({
        url: 'analytics/products/sales/timeseries_for_realogram',
        params: {
          time_window: params.timeWindow,
          limit: params.limit,
          realogram_candidate_id: params.realogramCandidateId,
          store_area_type: params.storeAreaType,
        },
      }),
      providesTags: [AnalyticsTags.GetRealogramAnalytics],
    }),
    estimateForPlanogram: builder.query<
      EstimateForPlanogramResponse,
      EstimateForPlanogramParams
    >({
      query: (params) => ({
        url: 'analytics/products/sales/estimate_for_planogram',
        method: 'POST',
        body: {
          planogram: params.planogram,
          store_area_type: params.storeAreaType,
          store_area_id: params.storeAreaId,
          start_from_including: params.startFromIncluding,
          time_window: params.timeWindow,
        },
      }),
    }),
    estimateForRealogram: builder.query<
      EstimateForRealogramResponse,
      EstimateForRealogramParams
    >({
      query: (params) => ({
        url: 'analytics/products/sales/estimate_for_realogram',
        method: 'POST',
        body: {
          realogram_candidate: params.realogramCandidate,
          store_area_type: params.storeAreaType,
          store_area_id: params.storeAreaId,
          start_from_including: params.startFromIncluding,
          time_window: params.timeWindow,
        },
      }),
    }),
  }),
});

export const {
  useGetRealogramAnalyticsQuery,
  useEstimateForPlanogramQuery,
  useEstimateForRealogramQuery,
  useLazyEstimateForPlanogramQuery,
  useLazyEstimateForRealogramQuery,
} = analyticsApi;
