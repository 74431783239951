import { FC } from 'react';
import { Chip } from '@mui/material';
import { theme } from 'theme';

type Props = {
  label: string;
  isOverflowShown?: boolean;
};

export const MainProductChip: FC<Props> = ({
  label,
  isOverflowShown = false,
}) => {
  return (
    <Chip
      size="small"
      label={label}
      sx={{
        maxWidth: isOverflowShown ? 'initial' : '100%',
        fontWeight: '400',
        color: theme.palette.textBlack.primary,
        backgroundColor: '#EEEEEE',
        borderRadius: '2px',
        mr: '4px',
      }}
    />
  );
};
