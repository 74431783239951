import { yupResolver } from '@hookform/resolvers/yup';
import {
  updateProductsListScrollPos,
  updateSearchWord,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { removeFirstLastSpaces } from '@utils/const';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InferType, object, string } from 'yup';

type FetchFilteredCategories = (text: string) => void;

const schema = object({
  searchText: string().required(),
});

type FormData = InferType<typeof schema>;

/**
 * - orgamisms/products
 * @param fetchFilteredCategories
 * @returns
 */
export const useProductsSearch = (
  fetchFilteredCategories: FetchFilteredCategories
) => {
  const dispatch = useAppDispatch();

  const { control, handleSubmit, setValue } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      searchText: '',
    },
  });

  const { searchWord } = useAppSelector(selectPlanogramEditorState);

  const { selectedProductCompartment, isShowProductDetail } = useAppSelector(
    selectPlanogramEditorState
  );

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const focus = () => {
    setIsFocused(true);
  };

  const search = useCallback(
    (data: FormData) => {
      const text = removeFirstLastSpaces(data.searchText);
      if (text === '') return;
      dispatch(updateSelectedProductCompartment(undefined));
      dispatch(updateSearchWord(text));
    },
    [dispatch]
  );

  const reset = useCallback(() => {
    dispatch(updateSearchWord(''));
    setValue('searchText', '');
    setIsFocused(false);
    dispatch(updateProductsListScrollPos(0));
    dispatch(updateSelectedProductCompartment(undefined));
  }, [dispatch, setValue]);

  // 検索画面に戻った時に、直前の検索結果を表示する
  useEffect(() => {
    if (searchWord === '') {
      return;
    }
    const text = removeFirstLastSpaces(searchWord);
    setIsFocused(true);
    setValue('searchText', text);
    void fetchFilteredCategories(text);
  }, [searchWord, setValue, fetchFilteredCategories]);

  return {
    isFocused,
    control,
    handleSubmit,
    setValue,
    searchWord,
    selectedProductCompartment,
    isShowProductDetail,
    focus,
    search,
    reset,
  };
};
