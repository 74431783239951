import { EmptyResult } from '@components/molecules/emptyResult/emptyResult';
import { AppBar, ImageModal } from '@components/organisms';
import { CategorySelectionModalProps } from '@components/organisms/categorySelectionModal/categorySelectionModal';
import { ProductDetailDrawer } from '@components/organisms/productDetailDrawer/productDetailDrawer';
import { ProductInformationModal } from '@components/organisms/productInformationModal/productInformationModal';
import { ProductsConditions } from '@components/organisms/productsConditions/productsConditions';
import { ProductsTable } from '@components/organisms/productsTable/productsTable';
import { yupResolver } from '@hookform/resolvers/yup';
import { useProductImage } from '@hooks/useImage';
import { useModal } from '@hooks/useModal';
import { usePageTitle } from '@hooks/usePageTitle';
import { Search } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { closeModal } from '@reducers/modal';
import {
  setCategoryHistory,
  setCategoryIndex,
} from '@reducers/productCategory';
import {
  GetProductsParams,
  useGetProductQuery,
  useListProductsQuery,
} from '@reducers/shelfAppsApi';
import { useAppDispatch } from '@store/index';
import {
  faceIdFront,
  faceIdTop,
  paths,
  removeFirstLastSpaces,
  rowsPerPage,
} from '@utils/const';
import { getFaceFrontById } from '@utils/planogram';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { theme } from 'theme';
import { FaceFrontId, Product } from 'types/common';
import { ProductCategory } from 'types/productCategories';
import { ProductOrder } from 'types/products';
import { InferType, object, string } from 'yup';

const schema = object({
  searchText: string().required(),
});

type FormData = InferType<typeof schema>;

export const ProductList: FC = () => {
  usePageTitle('商品一覧');
  const dispatch = useAppDispatch();
  const [isFocused, setIsFocused] = useState(false);
  const [condition, setCondition] = useState<GetProductsParams>({
    firstOrder: 'updated_at_desc',
  });
  const {
    isLoading,
    isFetching,
    data: products,
  } = useListProductsQuery(
    {
      detail: true,
      shape: true,
      limit: rowsPerPage,
      ...condition,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [selectedProductTags, setSelectedProductTags] = useState<string[]>([]);
  const [referenceImagePreview, setReferenceImagePreview] = useState(false);
  const [selectedProductCategories, setSelectedProductCategories] =
    useState<ProductCategory>();

  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [editOpen, setEditOpen] = useState(false);
  const [faceFrontId, setFaceFrontId] = useState<FaceFrontId>(1);
  useEffect(() => {
    if (!selectedProduct?.detail) return;
    if (selectedProduct.detail.face_direction === 'front')
      setFaceFrontId(faceIdFront);
    if (selectedProduct.detail.face_direction === 'top')
      setFaceFrontId(faceIdTop);
  }, [selectedProduct]);
  const { image } = useProductImage(
    {
      productId: selectedProduct?.id ?? 0,
      faceFront: getFaceFrontById(faceFrontId),
      size: 'large',
    },
    {
      skip: !selectedProduct?.id,
      assetVersion: selectedProduct?.asset_version,
    }
  );

  const { control, handleSubmit, setValue } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      searchText: '',
    },
  });

  const { id } = useParams();
  const navigate = useNavigate();

  const { data, refetch: refetchProduct } = useGetProductQuery(
    {
      productId: parseInt(id ?? ''),
    },
    {
      skip: !id,
    }
  );

  useEffect(() => {
    setSelectedProduct(data?.product);
  }, [data]);

  const handleRowClick = (product: Product) => {
    setSelectedProduct(product);
    navigate(paths.products.product(product.id));
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const reset = () => {
    setIsFocused(false);
    setValue('searchText', '');
    setCondition({
      ...condition,
      q: undefined,
      offset: 0,
    });
  };

  const clearConditions = () => {
    setCondition({
      ...condition,
      category: undefined,
      tags: undefined,
      offset: 0,
    });
    setSelectedProductTags([]);
    setSelectedProductCategories(undefined);
    dispatch(setCategoryHistory([]));
    dispatch(setCategoryIndex(0));
  };

  const handleEndReached = (index: number) => {
    if (isLoading || isFetching || !products?.pager.total) return;
    const offset = index + 1;
    if (offset >= products.pager.total) return;
    setCondition({ ...condition, offset: offset });
  };

  const handleChangeSelectedProductTags = (tags?: string[]) => {
    setSelectedProductTags(tags ?? []);
    setCondition({ ...condition, tags, offset: 0 });
  };

  const handleChangeSelectedProductCategory = (category?: ProductCategory) => {
    setSelectedProductCategories(category ?? undefined);
    setCondition({
      ...condition,
      category: category ? [category.name] : undefined,
      offset: 0,
    });
    dispatch(closeModal());
  };

  const handleChangeOrder = (firstOrder: ProductOrder) => {
    setCondition({ ...condition, firstOrder, offset: 0 });
  };

  const onSubmit = (data: FormData) => {
    const text = removeFirstLastSpaces(data.searchText);
    if (text === '') return;
    setCondition({
      ...condition,
      q: text,
      offset: 0,
    });
  };

  const { showModal } = useModal<CategorySelectionModalProps>(
    'categorySelectionModal',
    ''
  );

  const handleSelectCategory = () => {
    showModal({
      name: 'カテゴリ',
      handleChangeSelectedProductCategory: handleChangeSelectedProductCategory,
      selectedProductCategories: selectedProductCategories,
    });
  };

  const handleRemoveSelectedCategory = () => {
    setSelectedProductCategories(undefined);
    setCondition({ ...condition, category: undefined, offset: 0 });
    dispatch(setCategoryHistory([]));
    dispatch(setCategoryIndex(0));
  };

  const handleClickBack = () => {
    setSelectedProduct(undefined);
    navigate(paths.products.root);
  };
  return (
    <>
      <AppBar title="商品一覧" />
      <Box component="div" display="flex" mx="auto">
        {/*56px = 40 AppBar + 16px margin*/}
        <Box
          component="div"
          px={3}
          width={selectedProduct ? '75%' : '100%'}
          sx={{
            transition: 'all 0.1s linear',
          }}
        >
          <Box
            component="div"
            height="calc(100vh - 56px)"
            display="flex"
            flexDirection="column"
          >
            <Box
              component="div"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={3}
              marginTop="16px"
            >
              <Box component="div" display="flex" alignItems="center" gap={2}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  color={theme.palette.textBlack.primary}
                >
                  商品一覧
                </Typography>
                <Typography color={theme.palette.textBlack.secondary}>
                  {`全${products?.pager.total ?? 0}件`}
                </Typography>
              </Box>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                display="flex"
              >
                <Controller
                  name="searchText"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="商品を検索"
                      onFocus={handleFocus}
                      size="small"
                      sx={{
                        width: '254px',
                        'input::placeholder': {
                          fontSize: '14px',
                        },
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: '40px',
                          backgroundColor: '#F7F7F7',
                        },
                        startAdornment: (
                          <Search
                            sx={{
                              color: theme.palette.icons.primary,
                              mr: '4px',
                            }}
                          />
                        ),
                        endAdornment: isFocused && (
                          <IconButton
                            onClick={() => {
                              reset();
                            }}
                          >
                            <CloseIcon
                              sx={{
                                width: '18px',
                                height: '18px',
                                cursor: 'pointer',
                                color: theme.palette.icons.primary,
                              }}
                            />
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box component="div" my={2}>
              <Box component="div">
                <ProductsConditions
                  selectedProductTags={selectedProductTags}
                  handleChangeSelectedProductTags={
                    handleChangeSelectedProductTags
                  }
                  clearConditions={clearConditions}
                  selectedProductCategories={selectedProductCategories}
                  handleRemoveSelectedCategory={handleRemoveSelectedCategory}
                  handleSelectCategory={handleSelectCategory}
                />
              </Box>
            </Box>
            {!products?.products.length && !isLoading && !isFetching ? (
              <Box component="div" sx={{ height: '100%' }}>
                <EmptyResult title="商品がありません" isErrorIcon />
              </Box>
            ) : (
              <Box component="div" height="100%">
                <ProductsTable
                  handleRowClick={handleRowClick}
                  handleEndReached={handleEndReached}
                  isLoading={isLoading || isFetching}
                  products={products?.products}
                  condition={condition}
                  handleChangeOrder={handleChangeOrder}
                  offset={condition?.offset ?? 0}
                />
              </Box>
            )}
          </Box>
        </Box>
        {selectedProduct && (
          <Box
            component="div"
            display="flex"
            width="25%"
            borderLeft="1px solid #EDEDED"
            position="relative"
            overflow="hidden"
            flexDirection="column"
            boxSizing="border-box"
          >
            <ProductDetailDrawer
              selectedProduct={selectedProduct}
              handleClick={handleClickBack}
              hasEditButton
              imageSize="s"
              showDetail
              handleEditOpen={() => setEditOpen(true)}
              onProductImageClick={() => setReferenceImagePreview(true)}
              faceFrontId={faceFrontId}
              onFaceFrontIdChange={setFaceFrontId}
              hasFaceFrontSwitcher
              hasMakerInfo
            />
          </Box>
        )}
      </Box>
      {/* editOpenがtrueになってから、productInformationModal内のAPIを呼ぶ */}
      {selectedProduct && editOpen && (
        <ProductInformationModal
          open={editOpen}
          handleClose={() => setEditOpen(false)}
          product={selectedProduct}
          refetchProduct={() => refetchProduct()}
        />
      )}
      <ImageModal
        open={referenceImagePreview}
        image={{
          src: image ?? '/noimage.png',
          width: window.innerWidth,
          height: window.innerHeight,
        }}
        handleClose={() => setReferenceImagePreview(false)}
      />
    </>
  );
};
