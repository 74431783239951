import { BayPartsList } from '@components/organisms';
import { BayPartCategoriesList } from '@components/organisms/bayParts/fragments/bayPartCategoriesList';
import { FC } from 'react';
import { BayPartCategory, BayPlanCategory, ChildBayPart } from 'types/bayPlan';
import { useAppSelector } from '@store/index';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';

type Props = {
  categoryType: 'categories' | 'list';
  handleClick: (category: BayPartCategory) => void;
  handleClickBayPartCard: (part: ChildBayPart) => void;
  rootCategories?: BayPlanCategory;
  selectedBayPartCategory?: BayPartCategory;
  prevBayPartId?: number;
};

export const BayPartCategories: FC<Props> = ({
  categoryType,
  handleClick,
  handleClickBayPartCard,
  rootCategories,
  selectedBayPartCategory,
  prevBayPartId,
}) => {
  const { detailMode } = useAppSelector(selectPlanogramEditorState);
  const isCompareMode = detailMode === 'comparison';

  switch (categoryType) {
    case 'categories':
      return (
        <BayPartCategoriesList
          isCompareMode={isCompareMode}
          categories={rootCategories?.children}
          handleClick={handleClick}
        />
      );
    case 'list':
      return (
        <BayPartsList
          isCompareMode={isCompareMode}
          parts={selectedBayPartCategory?.children}
          handleClickBayPartCard={handleClickBayPartCard}
          prevBayPartId={prevBayPartId}
        />
      );
    default:
      return null;
  }
};
