import { Box, Typography } from '@mui/material';
import { theme } from '../../../../theme';
import { FC } from 'react';
import { AuthorType } from 'types/sharePermission';
import { User } from 'types/user';

type Props = {
  userInfo?: User;
  author: AuthorType | undefined;
};

export const AuthorField: FC<Props> = ({ userInfo, author }) => {
  return (
    <Box
      component="div"
      sx={{
        marginBottom: '30px',
      }}
    >
      <Typography
        color={theme.palette.textBlack.primary}
        noWrap
        fontSize={16}
        sx={{
          fontWeight: '700',
          marginBottom: '12px',
        }}
      >
        作成者
      </Typography>

      <Typography
        color={theme.palette.textBlack.primary}
        noWrap
        fontSize={14}
        sx={{
          fontSize: '16px',
          fontWeight: '400',
        }}
      >
        {author?.id === userInfo?.id
          ? `自分（${author?.fullname}）`
          : author?.fullname}
      </Typography>

      <Typography
        color={theme.palette.textBlack.secondary}
        noWrap
        fontSize={14}
        sx={{
          fontSize: '14px',
          fontWeight: '400',
        }}
      >
        {author?.email}
      </Typography>
    </Box>
  );
};
