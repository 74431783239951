import { Box, styled } from '@mui/material';
import { theme } from 'theme';

export const BoxWidthDivider = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderLeft: `2px solid`,
  height: '32px',
  borderLeftColor: theme.palette.divider,
}));
