import { TableRowProps } from '@components/organisms';
import { DefaultVirtuosoTableRow } from '@components/organisms/dataTable/fragments/defaultVirtuosoTableRow';
import { useGetPlanogramPermission } from '@hooks/useGetPlanogramPermission';
import { PlanogramDirectory } from 'types/planogram';

type Props<T> = TableRowProps<T> & {
  handleRowClick: VoidFunction;
};

export const TableRowWrapper = <T,>({ handleRowClick, ...props }: Props<T>) => {
  const { isNotEnable: isNotCanRead } = useGetPlanogramPermission({
    action: 'read',
    planogram: props.item as PlanogramDirectory,
    isPlanogram: false,
    isCan: false,
  });

  return (
    <DefaultVirtuosoTableRow
      isNotCanRead={isNotCanRead}
      handleRowClick={handleRowClick}
      {...props}
    />
  );
};
