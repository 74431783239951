import { useGetRealogramAnalyticsQuery } from '@reducers/shelfAppsApi';
import { Format, format } from '@utils/date';
import { useEffect, useState } from 'react';
import { ProductSalesReport } from 'types/products';

const maxReportIndex = 3;
export const useRealogramAnalyticsData = (
  realogramCandidateId: number,
  isSkip: boolean
) => {
  const {
    data: analyticsData,
    refetch: refetchRealogramAnalytics,
    isLoading: isLoadingAnalyticsData,
  } = useGetRealogramAnalyticsQuery(
    {
      timeWindow: 'weekly',
      limit: 9,
      realogramCandidateId,
    },
    {
      skip: isSkip,
    }
  );

  const [displayAnalyticsData, setDisplayAnalyticsData] =
    useState<ProductSalesReport>();
  const [displayAnalyticsDataIndex, setDisplayAnalyticsDataIndex] = useState(0);
  const [analyticsDataTerm, setAnalyticsDataTerm] = useState('データなし');

  useEffect(() => {
    if (!analyticsData || analyticsData.reports.length === 0) return;
    setDisplayAnalyticsData(analyticsData.reports[displayAnalyticsDataIndex]);
    setAnalyticsDataTerm(
      createTerm(
        analyticsData.reports.at(displayAnalyticsDataIndex)?.summary
          .aggregation_period.start_date,
        analyticsData.reports.at(displayAnalyticsDataIndex)?.summary
          .aggregation_period.end_date
      )
    );
  }, [analyticsData, displayAnalyticsDataIndex]);

  const createTerm = (startDate?: string, endDate?: string) => {
    if (!startDate || !endDate) return 'データなし';
    return `${format(startDate, Format.dateWithoutYear)} ~ ${format(
      endDate,
      Format.dateWithoutYear
    )}`;
  };

  const disableChangeToPreviousWeek =
    analyticsDataTerm === 'データなし' ||
    displayAnalyticsDataIndex === maxReportIndex;
  const disableChangeToNextWeek =
    analyticsDataTerm === 'データなし' || displayAnalyticsDataIndex === 0;

  const handleNextWeekTerm = () => {
    if (displayAnalyticsDataIndex === 0) return;
    setDisplayAnalyticsDataIndex(displayAnalyticsDataIndex - 1);
  };
  const handlePreviousWeekTerm = () => {
    if (displayAnalyticsDataIndex === maxReportIndex) return;
    setDisplayAnalyticsDataIndex(displayAnalyticsDataIndex + 1);
  };

  return {
    analyticsData,
    refetchRealogramAnalytics,
    isLoadingAnalyticsData,
    analyticsDataTerm,
    displayAnalyticsData,
    disableChangeToPreviousWeek,
    disableChangeToNextWeek,
    displayAnalyticsDataIndex,
    handleNextWeekTerm,
    handlePreviousWeekTerm,
  };
};
