import { Box, ToggleButton } from '@mui/material';
import { FC } from 'react';
import { PreviewMode } from 'types/planogram';
import { StyledToggleButtonGroup } from '../viewModeToggle/fragments';
import { theme } from '../../../theme';

type Props = {
  value: PreviewMode;
  handleChange: (value: PreviewMode) => void;
};

export const PlanogramPreviewModeToggle: FC<Props> = ({
  value,
  handleChange,
}) => {
  return (
    <Box component="div" position="relative">
      <StyledToggleButtonGroup
        value={value}
        exclusive
        onChange={(_, value?: PreviewMode) => {
          if (!value) return; //NOTE: 未選択の状態は許容しないためreturn
          handleChange(value);
        }}
        sx={{ background: theme.palette.white.primary }}
      >
        <ToggleButton value="2d">2D</ToggleButton>
        <ToggleButton value="3d">3D</ToggleButton>
      </StyledToggleButtonGroup>
    </Box>
  );
};
