import { BreadcrumbForSearch } from '@components/molecules/breadcrumbForSearch/breadcrumbForSearch';
import { BreadcrumbList } from '@components/molecules/breadcrumbList/breadcrumbList';
import { SelectionModalResult } from '@components/molecules/selectionModalResult/selectionModalResult';
import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { useComparisonListRendering } from '@hooks/useComparisonListRendering';
import { CameraAlt } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PinDropIcon from '@mui/icons-material/PinDrop';
import StoreIcon from '@mui/icons-material/Store';
import { Box, ListItemButton, ListItemText, Typography } from '@mui/material';
import {
  updateRealogramSearch,
  updateSelectedItemId,
} from '@reducers/comparisonItemModal';
import { selectComparisonItemModal } from '@reducers/comparisonItemModal/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { rowsPerPage } from '@utils/const';
import { FC } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { theme } from 'theme';
import { DirectoryType } from 'types/common';
import {
  RealogramDirectory,
  RealogramDirectoryBreadCrumbs,
  RealogramDirectoryItem,
  RealogramDirectoryParent,
  RealogramDirectorySearch,
} from 'types/realogram';

type Props = {
  tabValue: number;
  isLoading: boolean;
  isFetching: boolean;
  searchedTotal: number;
  realogramOffset: number;
  realogramDirectories?: RealogramDirectory[] | RealogramDirectorySearch[];
  realogramBreadcrumbs?: RealogramDirectoryBreadCrumbs;
  realogramDirectoryParent?: RealogramDirectoryParent;
  disabledSelect?: boolean;
  userId?: number;
  textSearch?: string;
  scrollPos: number;
  setRealogramDirectoryId: (value: string) => void;
  setSelectedItem: (value: DirectoryType | undefined) => void;
  handleClickBreadcrumbs: (item: DirectoryType) => void;
  setSideBarValue: (value: string) => void;
  setSearch: (value: string) => void;
  handleScrollerRef: (ref: HTMLElement | Window | null) => void;
  setRealogramOffset: (value: number) => void;
  isSelectedItemInDirectory: boolean;
  originRealogramId?: number;
};

export const ComparisonRealograms: FC<Props> = ({
  tabValue,
  isLoading,
  isFetching,
  searchedTotal,
  realogramOffset,
  realogramDirectories,
  realogramBreadcrumbs,
  realogramDirectoryParent,
  userId,
  textSearch,
  scrollPos,
  setRealogramDirectoryId,
  setSelectedItem,
  handleClickBreadcrumbs,
  setSideBarValue,
  setSearch,
  handleScrollerRef,
  setRealogramOffset,
  isSelectedItemInDirectory,
  originRealogramId,
}) => {
  const dispatch = useAppDispatch();
  const { selectedItemId, currentTab } = useAppSelector(
    selectComparisonItemModal
  );

  const {
    isLoadingFirstPage,
    isShowList,
    selectedItemIndex,
    isLoadingSelectedItem,
    getNextPageData,
  } = useComparisonListRendering({
    isLoadingOrFetching: isFetching || isLoading,
    data: {
      items: realogramDirectories || [],
      selectedItemId,
    },
    pagination: {
      offset: realogramOffset,
      setOffset: setRealogramOffset,
      limit: rowsPerPage,
      total: searchedTotal,
    },
    options: {
      iForceHideLoadingSelectedItem: !isSelectedItemInDirectory,
      getId: (item) => (item as RealogramDirectoryItem).realogram_candidate_id,
    },
  });

  const handleListItemClick = (item: RealogramDirectory) => {
    if (item.type === 'directory') {
      setSearch('');
      setRealogramOffset(0);
      setSideBarValue('all');
      setRealogramDirectoryId(item.id);
      dispatch(updateRealogramSearch(undefined));
    } else if (item.type === 'file') {
      if (selectedItemId === item.realogram_candidate_id) return;
      dispatch(updateSelectedItemId(item.realogram_candidate_id));
      setSelectedItem(item);
    }
  };

  return (
    <>
      <Box component="div" flex={1}>
        {isShowList ? (
          <Virtuoso
            data={realogramDirectories}
            initialTopMostItemIndex={{
              index: selectedItemIndex || 0,
              align: 'center',
            }}
            scrollerRef={handleScrollerRef}
            initialScrollTop={scrollPos}
            endReached={getNextPageData}
            components={{
              // eslint-disable-next-line @typescript-eslint/naming-convention -- camelCaseにできない
              Footer: () => {
                if (isFetching || isLoading)
                  return (
                    <Box
                      component="div"
                      sx={{
                        color: '#D9D9D9',
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.white.primary,
                      }}
                    >
                      <SelectionModalResult
                        isLoading={isLoading || isFetching}
                        textSearch={textSearch}
                        data={realogramDirectories}
                      />
                    </Box>
                  );
                return <></>;
              },
            }}
            itemContent={(_, directory) => {
              const {
                name,
                type,
                store_area_id,
                store_id,
                store_bay_id,
                realogram_candidate_id,
              } = directory;

              const isSelected =
                selectedItemId === realogram_candidate_id &&
                tabValue === currentTab;
              const isFavorite = directory.favorite?.owner_id === userId;
              return (
                <ListItemButton
                  disabled={
                    originRealogramId === directory.realogram_candidate_id
                  }
                  component="div"
                  sx={{
                    height: '56px',
                    padding: '4px 16px',
                    borderBottom: `1px solid ${theme.palette.dividerBlack.light}`,
                    display: 'flex',
                    backgroundColor: isSelected ? '#E5F0FF' : '',
                    '&:hover': {
                      backgroundColor: isSelected ? '#E5F0FF' : '',
                    },
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                  onClick={() => handleListItemClick(directory)}
                >
                  <ListItemText
                    sx={{
                      overflow: 'hidden',
                      flex: 'unset',
                      '& .MuiListItemText-primary': {
                        display: 'block',
                      },
                    }}
                    primary={
                      <Box
                        component="div"
                        display="flex"
                        gap={1}
                        alignItems="center"
                      >
                        {type === 'directory' && store_area_id && (
                          <PinDropIcon
                            htmlColor={theme.palette.icons.secondary}
                          />
                        )}
                        {type === 'directory' && (store_id || store_bay_id) && (
                          <StoreIcon
                            htmlColor={theme.palette.icons.secondary}
                          />
                        )}
                        {type === 'file' && (
                          <CameraAlt
                            htmlColor={theme.palette.icons.secondary}
                          />
                        )}
                        <Box component="div">
                          <Box
                            component="div"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                overflow: 'hidden',
                              }}
                            >
                              {name}
                            </Typography>
                            {isFavorite && (
                              <StarIcon
                                htmlColor={theme.palette.confidence.middle}
                                sx={{
                                  marginLeft: '4px',
                                  width: '16px',
                                  height: '16px',
                                  verticalAlign: 'text-bottom',
                                  marginRight: 'auto',
                                }}
                              />
                            )}
                          </Box>
                          {(directory as RealogramDirectorySearch)
                            .breadcrumb && (
                            <BreadcrumbForSearch
                              breadcrumbs={
                                (directory as RealogramDirectorySearch)
                                  .breadcrumb
                              }
                              screenType="Main"
                              fontSize="12px"
                            />
                          )}
                        </Box>
                      </Box>
                    }
                    primaryTypographyProps={{
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  />
                  {type === 'directory' && (
                    <ChevronRightIcon
                      htmlColor={theme.palette.icons.secondary}
                      sx={{ marginLeft: 'auto' }}
                    />
                  )}
                  {isSelected && type === 'file' && (
                    <CheckCircleIcon
                      fontSize="small"
                      htmlColor="#0A40CA"
                      sx={{ alignItems: 'center' }}
                    />
                  )}
                </ListItemButton>
              );
            }}
          />
        ) : (
          <Box
            component="div"
            sx={{
              color: '#D9D9D9',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: theme.palette.white.primary,
              height: '100%',
            }}
          >
            <SelectionModalResult
              isLoading={isLoadingFirstPage || isLoadingSelectedItem}
              textSearch={textSearch}
              data={realogramDirectories}
              emptyResultBoxTitle="スキャン結果がありません"
            />
          </Box>
        )}
      </Box>
      {!!realogramBreadcrumbs?.length &&
        realogramDirectoryParent &&
        !textSearch && (
          <Box
            component="div"
            padding="8px 16px"
            borderTop={`1px solid ${theme.palette.dividerBlack.medium}`}
          >
            <BreadcrumbList
              breadcrumbs={realogramBreadcrumbs}
              parentDirectory={realogramDirectoryParent}
              screenType="Modal"
              fontSize="12px"
              directoryRootName="スキャン結果"
              modalMaxWidth={80}
              handleClickBreadcrumbs={handleClickBreadcrumbs}
            />
          </Box>
        )}
    </>
  );
};
