import { PersonAdd } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FC } from 'react';

type Props = {
  handleSharePermission?: () => void;
  isDisabled?: boolean;
};
export const SharePermissionMenu: FC<Props> = ({
  isDisabled = false,
  handleSharePermission,
}) => (
  <MenuItem onClick={handleSharePermission} disabled={isDisabled}>
    <ListItemIcon>
      <PersonAdd />
    </ListItemIcon>
    <ListItemText>共有</ListItemText>
  </MenuItem>
);
