import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { FC } from 'react';
import { BayPlanCode } from 'types/bayPlan';
import { OrganizationStatus } from 'types/planogram';
import { User } from 'types/user';
import { array, number } from 'yup';

type Props = {
  me?: User;
  users?: User[];
  bayPlanCodes?: BayPlanCode[];
  organizationStatuses?: OrganizationStatus[];
  selectedBayPlanCodes: number[];
  handleChangeSelectedBayPlanCodes: (bayPlanCodeId: number[]) => void;
  selectedOwnerId?: string;
  handleChangeSelectedOwnerId: (ownerId?: number[], meId?: number) => void;
  selectedOrganizationStatus: string;
  handleChangeSelectedOrganizationStatus: (status?: number[]) => void;
  clearConditions: () => void;
};

export const PlanogramsConditions: FC<Props> = ({
  me,
  users,
  bayPlanCodes,
  organizationStatuses,
  selectedBayPlanCodes,
  handleChangeSelectedBayPlanCodes,
  selectedOwnerId,
  handleChangeSelectedOwnerId,
  selectedOrganizationStatus,
  handleChangeSelectedOrganizationStatus,
  clearConditions,
}) => {
  return (
    <Box component="div" display="flex" gap={2} mb={2}>
      <FormControl sx={{ flex: 1, maxWidth: 320 }}>
        <InputLabel size="small">ゴンドラ番号</InputLabel>
        <Select
          size="small"
          label="ゴンドラ番号"
          multiple
          value={selectedBayPlanCodes}
          onChange={({ target: { value } }) => {
            handleChangeSelectedBayPlanCodes(
              array(number().required()).validateSync(value) ?? []
            );
          }}
          renderValue={(item) =>
            item.length === 1
              ? bayPlanCodes?.find(({ id }) => id === item[0])?.name
              : `${item.length}件選択中`
          }
        >
          <MenuItem
            divider
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              '&:hover': { backgroundColor: 'transparent' },
            }}
          >
            <Button
              disabled={bayPlanCodes?.length === selectedBayPlanCodes?.length}
              onClick={() =>
                handleChangeSelectedBayPlanCodes(
                  bayPlanCodes?.map(({ id }) => id) ?? []
                )
              }
            >
              すべて選択
            </Button>
            <Button
              disabled={selectedBayPlanCodes?.length === 0}
              onClick={() => handleChangeSelectedBayPlanCodes([])}
            >
              すべて解除
            </Button>
          </MenuItem>
          {bayPlanCodes?.map(({ id, name }, index) => {
            const checked = selectedBayPlanCodes?.includes(id);
            return (
              <MenuItem divider key={index} value={id}>
                <Checkbox checked={checked} />
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl sx={{ flex: 1, maxWidth: 320 }}>
        <InputLabel size="small">作成者</InputLabel>
        <Select
          size="small"
          label="作成者"
          value={selectedOwnerId}
          onChange={({ target: { value } }) => {
            if (value === '') {
              handleChangeSelectedOwnerId(undefined);
              return;
            }
            const v = number().cast(value);
            handleChangeSelectedOwnerId(
              typeof v === 'number' ? [v] : undefined,
              me?.id
            );
          }}
        >
          <MenuItem divider value="">
            絞り込みなし
          </MenuItem>
          {me && (
            <MenuItem divider value={me?.id}>
              {`自分（${me.fullname}）`}
            </MenuItem>
          )}
          {users?.map(({ id, fullname }, index) => (
            <MenuItem key={index} divider value={id}>
              {fullname}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ flex: 1, maxWidth: 240 }}>
        <InputLabel size="small">ステータス</InputLabel>
        <Select
          size="small"
          label="ステータス"
          value={selectedOrganizationStatus}
          onChange={({ target: { value } }) => {
            if (value === '') {
              handleChangeSelectedOrganizationStatus(undefined);
              return;
            }
            const v = number().cast(value);
            handleChangeSelectedOrganizationStatus(
              typeof v === 'number' ? [v] : undefined
            );
          }}
        >
          <MenuItem divider value="">
            絞り込みなし
          </MenuItem>
          {organizationStatuses?.map(({ id, label }) => (
            <MenuItem key={id} divider value={id}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        disabled={
          !selectedBayPlanCodes?.length &&
          !selectedOwnerId &&
          !selectedOrganizationStatus
        }
        onClick={clearConditions}
      >
        すべて解除
      </Button>
    </Box>
  );
};
