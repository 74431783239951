import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const SpritVertically: FC<SvgIconProps> = ({ fill = '', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2H13V4H11V2ZM11 5H13V7H11V5ZM13 8H11V10H13V8ZM11 11H13V13H11V11ZM13 14H11V16H13V14ZM11 17H13V19H11V17ZM13 20H11V22H13V20ZM15 5H23V19H15V5ZM9 5H1V19H9V5Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
