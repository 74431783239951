import { ProductImage } from '@components/organisms/productImage/productImage';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { Product } from 'types/common';

type Props = {
  product: Product;
  note?: string;
  boxSize?: number;
  imgSize?: number;
};

export const ProductTitleAndImage: FC<Props> = ({
  product,
  note,
  boxSize = 48,
  imgSize = 48,
}) => {
  return (
    <Box component="div" display="flex" alignItems="center" gap={2}>
      <Box
        component="div"
        width={boxSize}
        height={boxSize}
        borderRadius={2}
        border={`1px solid ${theme.palette.dividerBlack.dark}`}
        display="flex"
        alignItems="center"
        justifyContent="center"
        px={1}
      >
        <ProductImage
          product={product}
          width={imgSize}
          height={imgSize}
          layout="fixed"
          objectFit="contain"
          baseSize={imgSize}
        />
      </Box>
      <Box component="div" alignItems="center">
        {note && (
          <Typography variant="subtitle2" color={theme.palette.textBlack.label}>
            {note}
          </Typography>
        )}
        <Typography variant="body1" color={theme.palette.textBlack.primary}>
          {product.name}
        </Typography>
      </Box>
    </Box>
  );
};
