import { FC } from 'react';
import { Chip } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { theme } from '../../../theme';

type Props = {
  prependedLabel?: string;
};

export const ErrorChip: FC<Props> = ({ prependedLabel }) => (
  <Chip
    label={prependedLabel ? prependedLabel + 'エラー' : 'エラー'}
    variant="outlined"
    sx={{
      fontSize: '0.7rem',
      height: 'inherit',
      color: CommonColors.appRed,
      borderColor: CommonColors.appRed,
      backgroundColor: theme.palette.white.primary,
      '& .MuiChip-label': {
        pl: 1,
        pr: 1,
      },
    }}
  />
);
