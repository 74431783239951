import { createSearchParams, useSearchParams } from 'react-router-dom';
import { RealogramCandidate } from 'types/realogram';

export const useSelectPrevNextRealogram = (
  realogramCandidate?: RealogramCandidate
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const onClickPrev = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSearchParams(
      createSearchParams({
        ...Object.fromEntries(searchParams),
        compare: `actual-${realogramCandidate?.link?.prev_created_realogram_candidate_id}`,
      }).toString()
    );
  };
  const onClickNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSearchParams(
      createSearchParams({
        ...Object.fromEntries(searchParams),
        compare: `actual-${realogramCandidate?.link?.next_created_realogram_candidate_id}`,
      }).toString()
    );
  };
  return { onClickNext, onClickPrev };
};
