import { AppBar } from '@components/organisms';
import { usePageTitle } from '@hooks/usePageTitle';
import { NotFound } from '@components/NotFound';
import { useAppSelector } from '@store/index';
import { FC } from 'react';
import { UpdateUserDrawer } from './fragments/updateUserDrawer';
import { useDeleteMfa } from './hooks/useDeleteMfa';
import { Box, CircularProgress } from '@mui/material';
import { fullHeight } from '@utils/const';
import { UsersList } from './fragments/usersList';
import { useCreateUser } from './hooks/useCreateUser';
import { useListUsers } from './hooks/useListUsers';
import { useUpdateUser } from './hooks/useUpdateUser';

export const Users: FC = () => {
  usePageTitle('ユーザー管理');

  const {
    control,
    handleSubmit,
    data,
    isFocused,
    isLoading,
    selectedUserRoleTags,
    selectedUserStatusTags,
    condition,
    isDisplayLoadingSkeleton,
    reset,
    resetFiltering,
    onSubmit,
    handleFocus,
    handleEndReached,
    handleChangeOrder,
    handleChangeSelectedUserRoleTags,
    handleChangeSelectedUserStatusTags,
  } = useListUsers();

  const { handleCreateUser } = useCreateUser();

  const {
    control: updateControl,
    handleSubmit: updateHandleSubmit,
    errors,
    isDisabled,
    resetSelectedUser,
    selectUser,
    selectedUser,
    updateAccount,
  } = useUpdateUser();

  const { isDialogOpen, isMfaActive, openDialog, closeDialog, handleDelete } =
    useDeleteMfa(selectedUser);

  const { user } = useAppSelector((state) => state.Auth);
  const isEmpty = !data?.users?.length && !isLoading;

  if (!user) {
    return (
      <Box
        component="div"
        width="100%"
        height={fullHeight}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  if (user?.role !== 'admin') {
    return <NotFound title="ユーザー管理" />;
  }

  return (
    <>
      <AppBar title="ユーザー管理" />
      <UsersList
        selectUser={selectUser}
        users={data?.users}
        total={data?.total}
        control={control}
        handleSubmit={handleSubmit}
        isFocused={isFocused}
        selectedUserRoleTags={selectedUserRoleTags}
        selectedUserStatusTags={selectedUserStatusTags}
        condition={condition}
        reset={reset}
        resetFiltering={resetFiltering}
        onSubmit={onSubmit}
        handleFocus={handleFocus}
        handleEndReached={handleEndReached}
        handleChangeOrder={handleChangeOrder}
        handleChangeSelectedUserRoleTags={handleChangeSelectedUserRoleTags}
        handleChangeSelectedUserStatusTags={handleChangeSelectedUserStatusTags}
        handleCreateUser={handleCreateUser}
        isEmpty={isEmpty}
        isDisplayLoadingSkeleton={isDisplayLoadingSkeleton}
      />
      {selectedUser && (
        <UpdateUserDrawer
          user={selectedUser}
          open={!!selectedUser}
          handleClose={() => {
            resetSelectedUser();
          }}
          updateAccount={updateAccount}
          isDeleteMfaDialogOpen={isDialogOpen}
          openDeleteMfaDialog={openDialog}
          closeDeleteMfaDialog={closeDialog}
          control={updateControl}
          handleSubmit={updateHandleSubmit}
          errors={errors}
          isDisabled={isDisabled}
          handleDeleteMfa={() => handleDelete(selectedUser.id)}
          isMfaActive={isMfaActive}
        />
      )}
    </>
  );
};
