import { FC } from 'react';
import { theme } from 'theme';
import { Box, Typography } from '@mui/material';
import { HorizontalTabletIcon } from '@components/molecules/horizontalTabletIcon/horizontalTabletIcon';
import { HorizontalTabletIconRotate } from '@components/molecules/horizontalTabletIconRotate/horizontalTabletIconRotate';
import { FlatRealogramScanIcon } from '@components/molecules/flatRealogramScanIcon/flatRealogramScanIcon';

type Props = {
  isFlat: boolean;
};

export const HowToScanImage: FC<Props> = ({ isFlat }) => {
  return (
    <>
      {isFlat ? (
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          borderRadius={1}
          textAlign="center"
          justifyContent="center"
          py={2}
          sx={{
            backgroundColor: theme.palette.primary.selected,
          }}
        >
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            pb={7}
            gap={3}
            flex={1}
            borderRight={`1px solid ${theme.palette.dividerBlack.medium}`}
          >
            <Typography
              variant="headingS"
              py={2}
              gap="10px"
              color={theme.palette.primary.main}
            >
              画面の向き
            </Typography>
            <HorizontalTabletIconRotate
              sx={{
                width: '175px',
                height: '240px',
                transform: 'rotate(90deg)',
              }}
            />
            <Typography variant="body1">
              画面を横向きにして撮影します
            </Typography>
          </Box>
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            pb={4}
            gap={3}
            flex={1}
          >
            <Typography
              variant="headingS"
              py={2}
              gap="10px"
              color={theme.palette.primary.main}
            >
              撮影する順番
            </Typography>
            <FlatRealogramScanIcon sx={{ width: 190, height: 240, gap: 3 }} />
            <Typography variant="body1" textAlign="left" px={5}>
              はじめにゴンドラの左半分を撮影して、 次に右半分を撮影します
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          component="div"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-around"
          display="flex"
          width={{
            xs: '100%',
            md: '560px',
          }}
          pt={3}
          pb={7}
          gap={5}
          sx={{
            backgroundColor: theme.palette.primary.selected,
            borderRadius: 1,
            margin: {
              xs: '24px 16px',
              md: 0,
            },
          }}
        >
          <Box component="div" py={2} gap="10px">
            <Typography color={theme.palette.primary.main} variant="headingS">
              画面の向き
            </Typography>
          </Box>
          <HorizontalTabletIcon />
          <Typography variant="body1" color={theme.palette.textBlack.primary}>
            画面を縦向きにして撮影します
          </Typography>
        </Box>
      )}
    </>
  );
};
