import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

export const RotateXIcon: FC<SvgIconProps> = (props) => {
  const color = props.htmlColor || theme.palette.icons.primary;

  return (
    <SvgIcon
      {...props}
      sx={{
        width: '24px',
        height: '24px',
        viewBox: '0 0 24px 24px',
        fill: 'none',
      }}
    >
      <path d="M3.99902 5L3.99902 9L7.99902 9" stroke={color} strokeWidth="2" />
      <path
        d="M4.58105 9C5.76783 6.06817 8.64216 4 11.9995 4C16.4178 4 19.9995 7.58172 19.9995 12C19.9995 16.4183 16.4178 20 11.9995 20C8.83642 20 6.10206 18.1642 4.80377 15.5"
        stroke={color}
        strokeWidth="2"
      />
      <circle r="1.5" transform="matrix(-1 0 0 1 12 12)" fill={color} />
    </SvgIcon>
  );
};
