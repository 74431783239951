import { components } from 'types/api';
import { FaceFrontId, FaceOrientationId } from './common';
import { Scope, ShareRole } from './sharePermission';

//Extend for planogram
export type Planogram = components['schemas']['Planogram'] & {
  current_user_role?: {
    role?: ShareRole;
  };
  scope?: Scope;
  store_id?: number;
};

export type PlanogramPlan =
  | components['schemas']['PlanogramShelvesDetail']
  | components['schemas']['PlanogramShelvesV2Detail'];
export type BucketPlanogramPlan =
  components['schemas']['PlanogramBucketsDetail'];

export type PlanogramShelvesV2Compartment =
  components['schemas']['ShelvesV2Compartment'];
export type PlanogramProductCompartmentList =
  components['schemas']['PlanogramProductCompartmentList'];
export type PlanogramProductCompartment =
  components['schemas']['PlanogramProductCompartment'];
export type PlanogramProductCompartmentCount =
  components['schemas']['PlanogramProductCompartmentCount'];

export type PlanogramShelvesDetail =
  components['schemas']['PlanogramShelvesDetail'];
export type PlanogramShelvesV2Detail =
  components['schemas']['PlanogramShelvesV2Detail'];
export type PlanogramPtsDetail = components['schemas']['PlanogramPtsDetail'];

export type JustifyContent = components['schemas']['JustifyContent'];

/**
 * @deprecated
 */
export type PlanogramRow =
  components['schemas']['PlanogramProductCompartment'][];

export type PlanogramBayPart =
  | PlanogramHookBarPart
  | PlanogramShelfBoardPart
  | PlanogramPtsShelfBoard;

export type PlanogramHookBarPart = components['schemas']['HookBarPart'];
export type PlanogramShelfBoardPart = components['schemas']['ShelfBoardPart'];
export type PlanogramPtsShelfBoard = components['schemas']['PtsShelfBoard'];

export type PlanogramOrder = components['schemas']['PlanogramOrder'];
export type PlanogramDirectoryOrder =
  components['schemas']['PlanogramDirectoryOrder'];
export type PlanogramDirectorySearchOrder =
  components['schemas']['PlanogramDirectorySearchOrder'];
export type PlanogramListOrder = PlanogramOrder | PlanogramDirectorySearchOrder;
//NOTE: schemaが提供されていない
export type PlanogramOrderBy =
  | 'created_at'
  | 'updated_at'
  | 'name'
  | 'bay_plan_code'
  | 'current_user_accessed_at';

export type Position = {
  indexX: number;
  indexY: number;
};

/**
 * 棚に配置された商品の位置 / Position of products on shelves
 * 配列のインデックスを指定する / set the array index
 */
export type ProductPosition = Position & {
  subPosition: Position;
};

/**
 * 平置きゴンドラに配置された商品の位置 / Position of products on flatPlanogram
 * TODO: optional を許容できるようにProductPositionを変更し、shelfとflatを共通化する / set the indexes of the area from top to where containes the certain compartment
 */
export type OptionalProductPosition = Position & {
  subPosition?: Position;
};

export type BucketProductPosition = OptionalProductPosition;

/**
 * 棚に配置された什器パーツの位置 / bayPart position on shelves
 * 数値を指定する / set numerical value(m)
 */
export type BayPartPosition = {
  /**
   * 縦 / y axis value
   */
  elevation: number;
  /**
   * 横 / x axis value
   */
  shift?: number;
};

export type Degree = 0 | 90 | 180 | 270;
export type RotationValues = [FaceFrontId, FaceOrientationId];

//NOTE: schemaが提供されていない
export type ProductOrigin = 'top' | 'bottom';

export type OrganizationStatus = components['schemas']['OrganizationStatus'];

export type PreviewMode = '2d' | '3d';

/**
 * OrientationDirectionID
 * 0:左ロール 1:右ロール 2:右ヨー 3:左ヨー 4:上ピッチ 5:下ピッチ
 */
export type OrientationDirectionId = 0 | 1 | 2 | 3 | 4 | 5;

export type BboxColors = {
  borderColor: string;
  backgroundColor: string;
};

export type PlanogramDirectories =
  components['schemas']['PlanogramDirectoriesPageResponse'];

export type PlanogramDirectoryBreadCrumbs =
  components['schemas']['PlanogramDirectoriesPageResponse']['breadcrumb'];

export type PlanogramDirectory = components['schemas']['PlanogramDirectory'];
export type PlanogramDirectorySearch =
  components['schemas']['PlanogramDirectoryForSearch'];
export type PlanogramDirectoryItem =
  | PlanogramDirectory
  | PlanogramDirectorySearch;

export type PlanogramDirectoryParent =
  components['schemas']['PlanogramDirectoriesPageResponse']['parent'];

export type PlanogramDirectoryResponse =
  components['schemas']['GetPlanogramDirectoryResponse'];

/*
 * 必要な部分だけを取り出す
 */
export type PartialPlanogramDirectoryParent = Pick<
  PlanogramDirectoryParent,
  'type'
>;

export type BucketMode = 'area' | 'compartment';

export enum SplitAreaType {
  VERTICAL = 'compartment_x',
  HORIZONTAL = 'compartment_y',
}

export type SplitArea = {
  type: SplitAreaType;
  selectedBucketId?: number;
  bucketProductPosition?: Position[];
};

export type RemoveArea = Omit<SplitArea, 'type'>;

export type PlanogramBucketsDetail =
  components['schemas']['PlanogramBucketsDetail'];

//todo: buckendの型修正後に対応
export type BucketArea = {
  type?: string;
  split_axis?: string | null;
  product_id?: number;
  orientation?: 0 | 1 | 2 | 3;
  count?: {
    x: number;
    y: number;
  };
  face_front?: number;
  face_count?: number;
  children?: BucketArea[];
  width?: number;
  height?: number;
};

// export type BucketArea = components['schemas']['PlanogramBucketsArea'];

export type BucketCompartment =
  components['schemas']['PlanogramBucketsCompartment'];

export type Bucket = components['schemas']['PlanogramBucketsBucketBayPlan'];

export type BucketAreaDimensions = {
  width: number;
  height: number;
};

export type CompartmentBucketPosition = {
  compartment: BucketArea;
  position: Position[];
};

export type BucketsBucketDetail =
  components['schemas']['PlanogramBucketsBucketDetail'] & {
    width?: number;
    height?: number;
  };
export type PlanogramBucketsFrameBayPlanDetail =
  components['schemas']['PlanogramBucketsFrameBayPlanDetail'];

export type PostPlanogram = components['schemas']['PostPlanogram'];
export type EstimatedPlanogram =
  | components['schemas']['PlanogramShelvesDetail']
  | components['schemas']['PlanogramShelvesV2Detail']
  | components['schemas']['PlanogramPtsDetail']
  | components['schemas']['PlanogramBucketsDetail'];
export type BucketsPoint = components['schemas']['BucketsPoint'];

export type PlanogramScope =
  components['schemas']['PlanogramDirectory']['scope'];

export type PlanogramBucketsArea =
  components['schemas']['PlanogramBucketsArea'];
export type Estimate = components['schemas']['Estimate'];
