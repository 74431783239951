import { Box, Typography } from '@mui/material';
import { HeatMapColors, heatMapColors } from '@utils/colors';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  title: string;
  min: string;
  max: string;
};

export const HeatMapBar: FC<Props> = ({ min, max, title }) => {
  return (
    <Box component="div" display="flex" flexDirection="column">
      <Typography
        color={theme.palette.textBlack.secondary}
        variant="caption"
        pb={0.5}
      >
        {title}
      </Typography>
      <Box component="div" display="flex" borderRadius="2px" overflow="hidden">
        {heatMapColors.map((color) => (
          <Box
            key={color}
            component="div"
            sx={{
              width: '100%',
              height: '7px',
              backgroundColor: HeatMapColors[color],
            }}
          />
        ))}
      </Box>
      <Box component="div" display="flex" justifyContent="space-between">
        <Typography fontSize="10px">{min}</Typography>
        <Typography fontSize="10px">{max}</Typography>
      </Box>
    </Box>
  );
};
