import { Menu } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc';
import { FC } from 'react';
import { FolderMenu } from './fragments/folderMenu';
import { ScanResultMenu } from './fragments/scanResultMenu';
import { SharePermissionMenu } from './fragments/sharePermissionMenu';
import { ShelvesViewMode } from 'types/common';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  handleOpenDeleteDialog: () => void;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  // Todo: データ構造に合わせる
  isFolder?: boolean;
  isStarred?: boolean;
  isCanDelete?: boolean;
  isCanReadShare?: boolean;
  viewMode?: ShelvesViewMode;
  handleFavoriteClick: () => void;
  handleSharePermission?: () => void;
};

export const RealogramsTableMenu: FC<Props> = ({
  handleOpenDeleteDialog,
  anchorEl,
  handleClose,
  isFolder = false,
  isStarred = false,
  isCanDelete = false,
  isCanReadShare = false,
  viewMode,
  handleFavoriteClick,
  handleSharePermission,
}) => {
  const martinTopTable = 9.8;
  const martinTopNotTable = 16;
  const martinLeftTable = 16;
  const martinLeftNotTable = 8;

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          display: 'flex',
          alignItems: 'center',
          marginTop: viewMode === 'table' ? martinTopTable : martinTopNotTable,
          marginLeft:
            viewMode === 'table' ? martinLeftTable : martinLeftNotTable,
        },
      }}
    >
      {isFolder ? (
        <FolderMenu
          isStarred={isStarred}
          handleFavoriteClick={handleFavoriteClick}
        />
      ) : (
        <ScanResultMenu
          isCanDelete={isCanDelete}
          isStarred={isStarred}
          handleOpenDeleteDialog={handleOpenDeleteDialog}
          handleFavoriteClick={handleFavoriteClick}
        />
      )}
      <SharePermissionMenu
        isDisabled={isCanReadShare}
        handleSharePermission={handleSharePermission}
      />
    </Menu>
  );
};
