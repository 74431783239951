import { Box, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { emptyText, getProfitTagName } from '@utils/const';
import { FC } from 'react';
import { theme } from 'theme';
import { ProductTag } from 'types/common';
import { SelectableCard } from '../selectableCard/selectableCard';

type Props = {
  productTag: ProductTag;
  productTagValues: { item: ProductTag; value: string }[];
  handleChangeProductTag: (productTag: ProductTag) => void;
};

const isHasProductValueMarginBottom = 0.4;
const isNotHasProductValueMarginBottom = 0.6;
export const ProductCards: FC<Props> = ({
  productTag,
  productTagValues,
  handleChangeProductTag,
}) => {
  return (
    <>
      {productTagValues.map((product, index) => {
        const isSelected = product.item === productTag;
        const isHasProductValue = !!product.value;
        return (
          //NOTE: Boxを囲わない場合、Selectable Cardのスタイルが適用されない
          <Box component="div" key={index}>
            <SelectableCard
              selected={isSelected}
              onClick={() => handleChangeProductTag(product.item)}
              sx={{
                pl: 1,
                alignItems: 'unset',
                flexDirection: 'column',
                minWidth: 80,
                height: 54,
              }}
            >
              <Typography
                variant="caption"
                color={CommonColors.grayLight}
                textAlign="start"
                lineHeight={1}
                marginBottom={
                  isHasProductValue
                    ? isHasProductValueMarginBottom
                    : isNotHasProductValueMarginBottom
                }
              >
                {getProfitTagName(product.item)}
              </Typography>
              {isHasProductValue ? (
                <Typography
                  textAlign="end"
                  fontSize={18}
                  fontWeight={500}
                  color={theme.palette.textBlack.primary}
                >
                  {product.value}
                </Typography>
              ) : (
                <Typography
                  textAlign="end"
                  variant="body1"
                  color={theme.palette.textBlack.disabled}
                  lineHeight={1.5}
                >
                  {emptyText}
                </Typography>
              )}
            </SelectableCard>
          </Box>
        );
      })}
    </>
  );
};
