import { yupResolver } from '@hookform/resolvers/yup';
import { Close, Search } from '@mui/icons-material';
import { Box, IconButton, TextField, TextFieldProps } from '@mui/material';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { theme } from 'theme';
import { InferType, object, string } from 'yup';

const schema = object({
  searchText: string().required(),
});

type FormData = InferType<typeof schema>;

type Props = TextFieldProps & {
  searchText: string | undefined;
  handleSearch: (value: string) => void;
  clearSearch: () => void;
};

export const SearchText: FC<Props> = ({
  searchText,
  handleSearch,
  clearSearch,
  placeholder = '棚割・フォルダを検索',
  ...textFieldProps
}) => {
  const { control, getValues, handleSubmit, setValue } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      searchText: searchText ?? undefined,
    },
  });
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const reset = () => {
    setIsFocused(false);
    setValue('searchText', '');
    clearSearch();
  };

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit((data) => handleSearch(data.searchText))}
      noValidate
      display="flex"
    >
      <Controller
        name="searchText"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            placeholder={placeholder}
            onFocus={handleFocus}
            size="small"
            sx={{
              width: textFieldProps.fullWidth ? '100%' : '320px',
              'input::placeholder': {
                fontSize: '14px',
              },
            }}
            InputProps={{
              sx: {
                borderRadius: '40px',
                backgroundColor: '#F7F7F7',
              },
              startAdornment: (
                <Search
                  sx={{
                    color: theme.palette.icons.primary,
                    mr: '4px',
                  }}
                />
              ),
              endAdornment: (isFocused || !!getValues('searchText')) && (
                <IconButton
                  onClick={() => {
                    reset();
                  }}
                >
                  <Close
                    sx={{
                      width: '18px',
                      height: '18px',
                      cursor: 'pointer',
                      color: theme.palette.icons.primary,
                    }}
                  />
                </IconButton>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};
