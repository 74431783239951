import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StorageHelper } from '@utils/storageHelper';

type DemoState = {
  isDemoOn: boolean;
};

const storageHelper = new StorageHelper();

const initialState: DemoState = {
  isDemoOn: storageHelper.get('isDemoOn') === 'true',
};

const demoSlice = createSlice({
  name: 'demo-Reducer',
  initialState,
  reducers: {
    setDemoState: (
      state: DemoState,
      action: PayloadAction<DemoState['isDemoOn']>
    ) => {
      storageHelper.set('isDemoOn', action.payload.toString());
      state.isDemoOn = action.payload;
    },
  },
});

export const { setDemoState } = demoSlice.actions;
export const DemoReducer = demoSlice.reducer;
