import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const BowlBackIcon: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 80 80"
      sx={{ height: props.height, width: props.width }}
      fill="none"
    >
      <path
        d="M5 29H75L66.2321 46.7699C65.5583 48.1355 64.1677 49 62.645 49H17.355C15.8323 49 14.4417 48.1355 13.7679 46.7699L5 29Z"
        fill="white"
      />
      <rect x="2" y="27" width="76" height="2" rx="1" fill="white" />
    </SvgIcon>
  );
};
