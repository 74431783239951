import { paths } from 'types/api';
import { baseApi, KeysToCamelcase } from '../baseApi';

type GetBayPlansParams = KeysToCamelcase<
  paths['/api/v1/bay_plans']['get']['parameters']['query']
>;
type GetBayPlansResponse =
  paths['/api/v1/bay_plans']['get']['responses']['200']['content']['application/json'];

type GetBayPlanParams = KeysToCamelcase<
  paths['/api/v1/bay_plans/{bay_plan_id}']['get']['parameters']['path']
>;
type GetBayPlanResponse =
  paths['/api/v1/bay_plans/{bay_plan_id}']['get']['responses']['200']['content']['application/json'];

type GetBayPartsParams = KeysToCamelcase<
  paths['/api/v1/bay_plans/{bay_plan_id}/bay_parts']['get']['parameters']['path']
>;
type GetBayPartsResponse =
  paths['/api/v1/bay_plans/{bay_plan_id}/bay_parts']['get']['responses']['200']['content']['application/json'];

type GetBayPlanViewSetParams = KeysToCamelcase<
  paths['/api/v1/bay_plans/{bay_plan_id}/view_set']['get']['parameters']['path']
>;
type GetBayPlanViewResponse =
  paths['/api/v1/bay_plans/{bay_plan_id}/view_set']['get']['responses']['200']['content']['application/json'];

// see) https://staging.shelf.pfn.dev/docs#/bay_plans
export const bayPlansApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    listBayPlans: builder.query<GetBayPlansResponse, GetBayPlansParams>({
      query: (params) => ({
        url: 'bay_plans',
        params: {
          // camelcase to snakecase
          offset: params?.offset,
          limit: params?.limit,
          type: params?.type,
        },
      }),
    }),
    getBayPlan: builder.query<GetBayPlanResponse, GetBayPlanParams>({
      query: (params) => ({
        url: `bay_plans/${params.bayPlanId}`,
      }),
    }),
    listBayParts: builder.query<GetBayPartsResponse, GetBayPartsParams>({
      query: (params) => ({
        url: `bay_plans/${params.bayPlanId}/bay_parts`,
      }),
    }),
    getBayPlanViewSet: builder.query<
      GetBayPlanViewResponse,
      GetBayPlanViewSetParams
    >({
      query: (params) => ({
        url: `bay_plans/${params.bayPlanId}/view_set`,
      }),
    }),
  }),
});

export const {
  useGetBayPlanQuery,
  useListBayPlansQuery,
  useListBayPartsQuery,
  useGetBayPlanViewSetQuery,
} = bayPlansApi;
