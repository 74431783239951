export const initial = 'initial';
export const passwordInput = 'passwordInput';
export const authAppInstallation = 'authAppInstallation';
export const authAppAddition = 'authAppAddition';
export const oneTimePassword = 'oneTimePassword';
export const mfaCompleted = 'mfaCompleted';

export type Step =
  | typeof initial
  | typeof passwordInput
  | typeof authAppInstallation
  | typeof authAppAddition
  | typeof oneTimePassword
  | typeof mfaCompleted;

export const userPasswordAuth = 'USER_PASSWORD_AUTH';

export const mfaMessage = {
  alreadyRegistered:
    'このアカウントは既に二要素認証登録済みです。お手数ですが、「キャンセル」ボタンからログイン画面に戻ってください。ログイン後、アプリを使用できます。',
  error: 'エラーが発生しました。',
};
