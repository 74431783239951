import { selectEmail } from '@reducers/auth/selectors';
import { useGetTenantsQuery } from '@reducers/shelfAppsApi/injections/tenantsApi';
import { useAppSelector } from '@store/index';
import { useMemo } from 'react';

export const useQrCode = (secretCode?: string) => {
  const email = useAppSelector(selectEmail);
  const { data, isLoading } = useGetTenantsQuery();
  const qrCodeValue = useMemo(() => {
    if (!data || !email || !secretCode) {
      return;
    }
    const tenantKey = data.tenant.tenant_key;
    const environment = data.environment;

    let tenantAndEnv = '';
    if (environment === 'dev') {
      tenantAndEnv = `[${tenantKey}:dev]`;
    } else if (environment === 'staging') {
      tenantAndEnv = `[${tenantKey}:stg]`;
    } else {
      tenantAndEnv = `[${tenantKey}]`;
    }

    return `otpauth://totp/Shelf%20Apps%20${encodeURIComponent(
      tenantAndEnv
    )}%20%28${email}%29?secret=${secretCode}`;
  }, [email, secretCode, data]);

  return {
    isLoading,
    qrCodeValue,
  };
};
