import { ErrorMessage } from '@components/molecules';
import { FormPaper } from '@components/molecules/formPaper/formPaper';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField, Typography } from '@mui/material';
import { paths, tenantName } from '@utils/const';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { InferType, object, string } from 'yup';

const schema = object({
  email: string()
    .required('メールアドレスを入力してください。')
    .email('メールアドレスを正しい形式で入力してください。'),
});

type FormData = InferType<typeof schema>;

type Props = {
  errorMessage?: string;
  isAuthenticated: boolean;
  isLoading: boolean;
  onSubmit: (data: FormData) => void;
};

//TODO: PasswordReminderとInitialSettingを共通化する
export const PasswordReminder: FC<Props> = ({
  errorMessage,
  isAuthenticated,
  isLoading,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const email = watch('email');
  return (
    <FormPaper title="パスワードをリセット" tenant={tenantName}>
      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        mt={4}
      >
        {errorMessage && <ErrorMessage message={errorMessage} />}
        <TextField
          {...register('email')}
          fullWidth
          size="small"
          error={!!errors.email}
          helperText={errors.email?.message}
          label="メールアドレス"
          type="email"
          autoComplete="username"
          inputProps={{
            inputMode: 'email',
          }}
        />
        <Button
          variant="contained"
          type="submit"
          disabled={isLoading || !email}
        >
          認証コード取得
        </Button>
        <Typography>
          登録済みのメールアドレスを入力してください。メールが届かない場合は、管理者にお問い合わせください。
        </Typography>
        <Button
          onClick={() => {
            navigate(isAuthenticated ? paths.home : paths.login);
          }}
        >
          {isAuthenticated ? 'ホームに戻る' : 'ログインに戻る'}
        </Button>
      </Box>
    </FormPaper>
  );
};
