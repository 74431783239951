import { ActionVisible } from '@components/molecules/actionVisible/actionVisible';
import { ShelfIcon } from '@components/molecules/shelfIcon/shelfIcon';
import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, TableCell, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { theme } from 'theme';
import { StoreBay } from 'types/storeBay';
import { StoreBayTableMenu } from './storeBayTableMenu';

type Props = {
  storeBay: StoreBay;
  isStarred: boolean;
  canReadStoreBay: boolean;
  canEditStoreBay: boolean;
  handleEdit: VoidFunction;
  handleDelete: VoidFunction;
  handleFavoriteClick: VoidFunction;
  handleSharePermission: VoidFunction;
};
const overFlowSx = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  padding: '0px',
};
export const StoreBayTableCells: FC<Props> = ({
  storeBay,
  isStarred,
  canReadStoreBay,
  canEditStoreBay,
  handleEdit,
  handleDelete,
  handleFavoriteClick,
  handleSharePermission,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { name, sort_order, product_division, store_section } = storeBay;
  const { isLarger } = useBreakpoint();

  return (
    <>
      <TableCell
        sx={{
          ...overFlowSx,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '56px',
          width: '40px',
          padding: '0px 8px',
        }}
      >
        <ShelfIcon />
      </TableCell>
      <TableCell
        sx={{
          ...overFlowSx,
          paddingLeft: '16px',
        }}
      >
        {sort_order}
      </TableCell>
      <TableCell
        sx={{
          ...overFlowSx,
          paddingLeft: '32px',
          width: '100%',
          fontWeight: 600,
        }}
      >
        {name ?? '-'}
        {isStarred && (
          <StarIcon
            htmlColor={theme.palette.confidence.middle}
            sx={{
              verticalAlign: 'text-bottom',
              width: 16,
              height: 16,
              ml: 0.5,
            }}
          />
        )}
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '32px' }}>
        <Typography sx={{ ...overFlowSx, width: 176 }}>
          {product_division.name}
        </Typography>
      </TableCell>
      <TableCell sx={{ paddingLeft: '32px' }}>
        <Typography sx={{ ...overFlowSx, width: 176 }}>
          {store_section?.name ?? '-'}
        </Typography>
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '32px' }}>
        <ActionVisible
          isCanUpdate={canEditStoreBay}
          isCanRead={canReadStoreBay}
        />
      </TableCell>
      {/* 個別にクリックイベントを設定するため */}
      <TableCell
        onClick={(e) => e.stopPropagation()}
        sx={{
          padding: '0px',
          position: 'sticky',
          right: 0,
          boxShadow: isLarger ? '' : '0px 2px 8px 0px #0000000D',
          background: theme.palette.white.primary,
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            sx={{ width: 24, height: 24 }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreVert />
          </IconButton>
        </Box>
        <StoreBayTableMenu
          isStarred={isStarred}
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleSharePermission={handleSharePermission}
          handleFavoriteClick={handleFavoriteClick}
          isActionsAllowed={canEditStoreBay ?? false}
        />
      </TableCell>
    </>
  );
};
