import { ModalInnerProps } from '@components/modal';
import { StyledButton } from '@components/molecules/styledButton/styledButton';
import { Box, Divider, TextField } from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';

export type MemoModalProps = {
  value?: string;
};

type Props = ModalInnerProps<MemoModalProps>;

export const MemoForm: FC<Props> = ({
  handleClose,
  data: { value: defaultValue },
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  return (
    <Box component="div" width={400}>
      <Box component="div" mb={2}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="メモ"
          multiline
          rows={8}
          value={value}
          onChange={handleChange}
        />
      </Box>
      <Divider sx={{ margin: '16px -20px' }} />
      <Box component="div" textAlign="right">
        <StyledButton
          type="outlined"
          onClick={handleClose}
          sx={{ width: '94px' }}
        >
          とじる
        </StyledButton>
      </Box>
    </Box>
  );
};
