import { FC } from 'react';
import { theme } from 'theme';
import { CameraAlt } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

type Props = {
  onFileSelect: (file: File) => void;
  onSecondFileSelect?: (file: File) => void;
};

export const ImageUploadButton: FC<Props> = ({
  onFileSelect,
  onSecondFileSelect,
}) => (
  <Box component="div" display="flex" justifyContent="center">
    <Button
      component="label"
      startIcon={<CameraAlt sx={{ color: theme.palette.textBlack.primary }} />}
      sx={{
        color: theme.palette.textBlack.primary,
        backgroundColor: theme.palette.white.primary,
        width: '86px',
        height: 32,
        fontWeight: 'bold',
      }}
    >
      <input
        hidden
        type="file"
        accept="image/jpeg"
        onChange={({ target }) => {
          if (!target.files) {
            console.log('image upload failed');
            return;
          }
          if (onSecondFileSelect) {
            onSecondFileSelect(target.files[0]);
            return;
          }
          onFileSelect(target.files[0]);
        }}
      />
      再撮影
    </Button>
  </Box>
);
