import { ChartIcon } from '@components/molecules/chartIcon/chartIcon';
import { ChartIconSeparated } from '@components/molecules/chartIconSeparated/chartIconSeparated';
import { StyledToggleButtonGroup } from '@components/organisms/viewModeToggle/fragments';
import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import { Cached, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Popper,
  ToggleButton,
  styled,
} from '@mui/material';
import { FC, MouseEvent } from 'react';
import { theme } from 'theme';
import { Position } from 'types/planogram';

type Props = {
  open: boolean;
  anchorEl: HTMLElement | null;
  value: string;
  position: Position;
  onChange: (e: MouseEvent<HTMLElement>, value: string) => void;
  handleDeleteClick: (e: MouseEvent<HTMLButtonElement>) => void;
  height: number;
  handleSwapMode: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const BayPartMenu: FC<Props> = ({
  open,
  anchorEl,
  onChange,
  value,
  position,
  handleDeleteClick,
  height,
  handleSwapMode,
}) => {
  const { setJustifyContent } = usePlanogramPlan();
  return (
    <Popper
      anchorEl={anchorEl}
      open={open}
      placement="bottom-start"
      id="1"
      sx={{
        overflow: 'visible',
        width: 0,
        height: 0,
        minWidth: 0,
        minHeight: 0,
        background: 'unset',
        boxShadow: 'unset',
        display: 'flex',
        '& .MuiMenu-list': {
          padding: 0,
          width: 'unset',
          height: 'unset',
        },
      }}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, height],
          },
        },
      ]}
    >
      <StyledButtonGroupBox display="flex" alignItems="center">
        <StyledToggleButtonGroup
          orientation="horizontal"
          value={value}
          exclusive
          onChange={(e: MouseEvent<HTMLElement>, value: string) =>
            onChange(e, value)
          }
        >
          <ToggleButton
            sx={{ maxWidth: '40px' }}
            value="chart"
            onClick={() => {
              setJustifyContent(position, 'start');
            }}
          >
            <ChartIcon />
          </ToggleButton>
          <ToggleButton
            sx={{ maxWidth: '40px' }}
            value="chartSeparated"
            onClick={() => {
              setJustifyContent(position, 'space_evenly');
            }}
          >
            <ChartIconSeparated />
          </ToggleButton>
        </StyledToggleButtonGroup>
        <Divider flexItem orientation="vertical" sx={{ margin: '4px' }} />
        <Button
          onClick={handleSwapMode}
          startIcon={<Cached htmlColor={theme.palette.icons.primary} />}
          sx={{
            width: '150px',
            color: theme.palette.textBlack.primary,
            fontWeight: 700,
          }}
        >
          什器を変更
        </Button>
        <Divider flexItem orientation="vertical" sx={{ margin: '4px' }} />
        <IconButton onClick={handleDeleteClick}>
          <Delete />
        </IconButton>
      </StyledButtonGroupBox>
    </Popper>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
const StyledButtonGroupBox = styled(Box)(() => ({
  position: 'absolute',
  borderRadius: '4px',
  border: `solid 1px ${theme.palette.dividerBlack.light}`,
  boxShadow: '0px 4px 8px 0px #00000026',
  backgroundColor: theme.palette.white.translucent,
  padding: '6px 4px',
  marginTop: '8px',
}));
