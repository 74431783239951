import { Box, Typography } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC, useEffect, useState } from 'react';
import { Product } from 'types/common';
import { ProductCandidate } from 'types/realogram';
import { ProductImage } from '../productImage/productImage';
import { RealogramScoreChip } from '../realogramScoreChip/realogramScoreChip';

type Props = {
  products?: Product[];
  candidateIndex: number;
  productCandidate: ProductCandidate;
};

export const ProductCandidatesListItem: FC<Props> = ({
  products,
  candidateIndex,
  productCandidate,
}) => {
  const [product, setProduct] = useState<Product>();

  useEffect(() => {
    const product = products?.find((p) => p.id === productCandidate.product_id);
    setProduct(product);
  }, [productCandidate, products]);

  return (
    <Box
      component="div"
      p={1}
      pr={1.5}
      width="100%"
      display="flex"
      minHeight={89}
      justifyContent="space-between"
    >
      <Typography position="absolute">{candidateIndex}</Typography>
      <Box
        component="div"
        ml={2}
        mr={0.5}
        maxWidth={60}
        minHeight={60}
        display="flex"
        justifyContent="center"
      >
        <ProductImage
          product={product}
          height="100"
          width="100"
          objectFit="contain"
          baseSize={60}
        />
      </Box>

      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        ml={0.5}
        width={225}
      >
        <Typography
          variant="body2"
          mb={0.5}
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
            WebkitLineClamp: 2,
            // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
            WebkitBoxOrient: 'vertical',
          }}
        >
          {product?.name}
        </Typography>
        <Box
          component="div"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            noWrap
            variant="caption"
            color={CommonColors.grayLight}
            mr={3.5}
          >
            JAN：{product?.detail?.ean_code}
          </Typography>
          <RealogramScoreChip
            confidenceLevel={productCandidate.confidence_level}
          />
        </Box>
      </Box>
    </Box>
  );
};
