import { GoParentDirectoryButton } from '@components/molecules/goParentDirectoryButtonIcon/goParentDirectoryButton';
import { SidebarItemChips } from '@components/molecules/sidebarItemChips/sidebarItemChips';
import { SearchText } from '@components/pages/planograms/fragments/searchText';
import { Box, Stack, Typography } from '@mui/material';
import { getSidebarSx } from '@utils/const';
import { FC, Fragment, ReactElement, ReactNode, cloneElement } from 'react';
import { theme } from 'theme';

type SideBarValue = {
  label: string;
  value: string;
  icon?: ReactElement;
};

type Props = {
  sideBarValues: SideBarValue[];
  sideBarValue: string;
  modalTitle: string;
  search: string;
  modalSubtitle: string;
  listHeaderTitle?: string;
  isUnderRoot?: boolean;
  listHeaderIcon?: ReactNode;
  footer: ReactNode;
  list: ReactNode;
  tabs?: ReactNode;
  handleClickSideBarChip: (value: string) => void;
  handleSearchText: (text: string) => void;
  handleClickBack: () => void;
  clearSearchText: () => void;
  searchPlaceholder: string;
  sideBarHiddenValue?: string;
};

export const BaseModal: FC<Props> = ({
  sideBarValues,
  sideBarValue,
  modalTitle,
  search,
  modalSubtitle,
  listHeaderTitle = '',
  isUnderRoot = false,
  searchPlaceholder,
  sideBarHiddenValue = '',
  // Render props
  list,
  footer,
  listHeaderIcon,
  tabs,
  // Interaction Handler
  handleClickSideBarChip,
  handleSearchText,
  handleClickBack,
  clearSearchText,
}) => {
  return (
    <Box
      component="div"
      sx={{
        width: '664px',
        maxWidth: '100%',
        height: '560px',
        borderRadius: '4px',
        background: theme.palette.white.primary,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
      whiteSpace="nowrap"
    >
      {/* HEADER */}
      <Box
        component="div"
        sx={{
          background: theme.palette.backgroundBlack.active,
          height: '44px',
          p: 2,
          display: 'flex',
          alignItems: 'center',
          color: theme.palette.textBlack.secondary,
          gap: 1,
        }}
      >
        <Typography variant="subtitle2">{modalTitle}</Typography>
        <Typography variant="body2">{modalSubtitle}</Typography>
      </Box>
      {tabs}
      {/* SEARCH */}
      <Box
        component="div"
        sx={{
          p: '8px 16px',
          borderTop: `1px solid ${theme.palette.dividerBlack.medium}`,
          borderBottom: `1px solid ${theme.palette.dividerBlack.medium}`,
        }}
      >
        <SearchText
          key={search}
          fullWidth
          searchText={search}
          handleSearch={handleSearchText}
          clearSearch={clearSearchText}
          placeholder={searchPlaceholder}
        />
      </Box>
      {/* MAIN CONTENT */}
      <Box component="div" display="flex" flex={1} overflow="hidden">
        {/* SIDEBAR */}
        <Box
          component="div"
          sx={{
            width: '184px',
            backgroundColor: theme.palette.backgroundBlack.light,
          }}
        >
          <Stack
            direction="column"
            alignSelf="stretch"
            alignItems="center"
            gap="8px"
            padding="12px"
            sx={{
              borderRight: `1px solid ${theme.palette.dividerBlack.medium}`,
              height: '100%',
            }}
          >
            {sideBarValues?.map((el) => {
              if (el.value === sideBarHiddenValue)
                return <Fragment key={el.value} />;
              const isSelected = sideBarValue === el.value;
              return (
                <SidebarItemChips
                  key={el.value}
                  onClick={() => {
                    if (isSelected) return;
                    handleClickSideBarChip(el.value);
                  }}
                  label={el.label}
                  icon={
                    el.icon &&
                    cloneElement(el.icon, {
                      htmlColor: isSelected
                        ? theme.palette.icons.primary
                        : theme.palette.icons.secondary,
                      style: isSelected
                        ? { fill: theme.palette.icons.primary }
                        : { fill: theme.palette.icons.secondary },
                    })
                  }
                  sx={getSidebarSx(isSelected)}
                />
              );
            })}
          </Stack>
        </Box>
        {/* SIDEBAR END */}

        {/* LIST */}
        <Box
          component="div"
          flex={1}
          display="flex"
          flexDirection="column"
          overflow="hidden"
        >
          {/* LIST HEADER */}
          <Box
            component="div"
            sx={{
              padding: '18px 16px',
              display: 'flex',
              alignItems: 'center',
              borderBottom: `1px solid ${theme.palette.dividerBlack.medium}`,
            }}
          >
            <Typography
              variant="headingS"
              display="flex"
              alignItems="center"
              gap={1}
            >
              {isUnderRoot && (
                <GoParentDirectoryButton
                  width={32}
                  height={32}
                  handleClick={handleClickBack}
                />
              )}
              {listHeaderTitle}
              {listHeaderIcon}
            </Typography>
          </Box>

          {/* LIST */}
          {list}
          {/* LIST END */}
        </Box>
      </Box>
      {/* MAIN CONTENT END */}
      {/* FOOTER */}
      <Box
        component="div"
        sx={{
          height: '64px',
          borderTop: `1px solid ${theme.palette.dividerBlack.medium}`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 16px',
        }}
      >
        {footer}
      </Box>
    </Box>
  );
};
