import { Box, SxProps } from '@mui/material';
import { FC } from 'react';
import { CreatedAt } from './fragments/createdAt';

type Props = {
  onClickNext?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickPrev?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  createdAt: string;
  sx?: SxProps;
  isNextDisabled?: boolean;
  isPrevDisabled?: boolean;
};

export const FaceAreaControllerBox: FC<Props> = ({
  onClickPrev,
  onClickNext,
  createdAt,
  sx,
  isNextDisabled = false,
  isPrevDisabled = false,
}) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <CreatedAt
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
        disableNext={isNextDisabled}
        disablePrev={isPrevDisabled}
        createdAt={createdAt}
        sx={sx}
      />
    </Box>
  );
};
