import { FormData } from '@components/login/login';
import { Checkbox, FormControlLabel, Link } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const PrivacyPolicyAgreement: FC = () => {
  const { control } = useFormContext<FormData>();
  return (
    <Controller
      name="privacyPolicy"
      control={control}
      render={({ field }) => (
        <FormControlLabel
          sx={{ justifyContent: 'center', margin: '24px 0' }}
          control={<Checkbox {...field} />}
          label={
            <>
              <Link
                href={process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL}
                target="_blank"
              >
                プライバシーポリシー
              </Link>
              および
              <Link href="/docs/use_of_analytics_tools.pdf" target="_blank">
                情報収集・解析モジュール等の利用について
              </Link>
              に同意
            </>
          }
        />
      )}
    />
  );
};
