import { CircularSpinner } from '@components/molecules/circularSpinner/circularSpinner';
import { useProductImage } from '@hooks/useImage';
import { Close } from '@mui/icons-material';
import { Box, Divider, IconButton, Modal } from '@mui/material';
import { CommonColors } from '@utils/colors';
import Image from 'next/legacy/image';
import { FC } from 'react';
import { ImageSizeType, Product } from 'types/common';
import { theme } from '../../../theme';

type Props = {
  open: boolean;
  image: { src: string; width: number; height: number };
  product?: Product;
  imageSizeType?: ImageSizeType;
  handleClose: () => void;
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
const ImageDisplay: FC<{ src: string }> = ({ src }) => (
  <Box
    component="div"
    position="relative"
    sx={{
      minWidth: '340px',
      minHeight: '340px',
      '& > span img': {
        padding: '12px !important',
      },
    }}
  >
    <Image alt="" src={src} layout="fill" objectFit="contain" />
  </Box>
);

export const ImageModal: FC<Props> = ({
  open,
  image,
  product,
  handleClose,
  imageSizeType = 'small',
}) => {
  const faceFront = product?.detail?.face_direction ?? 'front';
  const { image: productImage, isLoading } = useProductImage(
    {
      productId: product?.id ?? 0,
      faceFront,
      size: imageSizeType,
    },
    {
      skip: !product,
      assetVersion: product?.asset_version,
    }
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        component="div"
        display="flex"
        sx={{
          outline: 'none',
          minWidth: '340px',
          minHeight: '340px',
          backgroundColor: '#fff',
          position: 'relative',
          borderRadius: '8px',
        }}
      >
        <ImageDisplay src={image.src} />

        {product && productImage && !isLoading && (
          <>
            <Divider orientation="vertical" flexItem />
            <ImageDisplay src={productImage} />
          </>
        )}

        {product && isLoading && (
          <>
            <Divider orientation="vertical" flexItem />
            <Box
              component="div"
              position="relative"
              sx={{
                minWidth: '340px',
                minHeight: '340px',
                '& > span img': {
                  padding: '12px !important',
                },
              }}
            >
              <CircularSpinner sx={{ display: 'flex', alignItems: 'center' }} />
            </Box>
          </>
        )}

        <Box
          component="div"
          position="relative"
          sx={{
            position: 'absolute',
            right: '0',
            top: '-48px',
            width: '40px',
            height: '40px',
            borderRadius: '100%',
            backgroundColor: '#fff',
          }}
        >
          <IconButton
            sx={{
              backgroundColor: theme.palette.white.primary,
              '&:hover': {
                backgroundColor: CommonColors.light,
              },
            }}
            onClick={handleClose}
          >
            <Close sx={{ color: CommonColors.dark }} />
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
};
