import { PasswordInput } from '@components/organisms';
import {
  AuthAppAddition,
  AuthAppInstallation,
  Initial,
  MfaCompleted,
  OneTimePassword,
} from '@components/pages/mfa/fragments/steps';
import { FC } from 'react';
import { useMfaStep } from '../hooks/useMfaStep';
import {
  authAppAddition,
  authAppInstallation,
  initial,
  mfaCompleted,
  oneTimePassword,
  passwordInput,
} from '../utils/const';
import { toInitializeMfaErrorMessage } from '../utils/mfa';

type Props = {
  email: string;
};

export const MfaStep: FC<Props> = ({ email }) => {
  const {
    step,
    isSignInFailed,
    goPasswordInputStep,
    goOneTimePasswordStep,
    signIn,
    isSigning,
    request,
    isRequesting,
    requestingError,
    isMfaRegistered,
    secretCode,
    verify,
    isVerifying,
    verifyingError,
  } = useMfaStep(email);

  switch (step) {
    case initial:
      return <Initial next={goPasswordInputStep} />;
    case passwordInput:
      return (
        <PasswordInput
          passwordFailed={isSignInFailed}
          isLoading={isSigning}
          next={signIn}
        />
      );
    case authAppInstallation:
      return (
        <AuthAppInstallation
          isLoading={isRequesting}
          next={request}
          errorMessage={
            requestingError &&
            toInitializeMfaErrorMessage(requestingError, isMfaRegistered)
          }
        />
      );
    case authAppAddition:
      return (
        <AuthAppAddition secretCode={secretCode} next={goOneTimePasswordStep} />
      );
    case oneTimePassword:
      return (
        <OneTimePassword
          isRequesting={isRequesting}
          isVerifying={isVerifying}
          isVerifyingError={verifyingError}
          onSubmit={verify}
          prev={request}
        />
      );
    case mfaCompleted:
      return <MfaCompleted />;
  }
};
