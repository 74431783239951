import { GridView, List } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FC } from 'react';
import { ShelvesViewMode } from '../../../types/common';

type Props = {
  mode: ShelvesViewMode;
  onChange: (mode: ShelvesViewMode) => void;
};
export const ShelvesViewToggle: FC<Props> = ({ mode, onChange }) => {
  return (
    <ToggleButtonGroup
      exclusive
      value={mode}
      onChange={(_, mode: ShelvesViewMode) => onChange(mode)}
      sx={{
        height: 40,
        borderRadius: '40px',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        padding: '4px',
        '& .MuiToggleButtonGroup-grouped': {
          border: 0,
          borderRadius: '30px !important',
          paddingTop: '11px',
          paddingBottom: '11px',
          paddingLeft: '18px',
          paddingRight: '18px',
          '&:not(:first-of-type)': {
            marginLeft: 0,
            borderLeft: 0,
          },
          '&.Mui-disabled': {
            border: 0,
          },
        },
      }}
    >
      <ToggleButton
        value="table"
        disabled={mode === 'table'}
        sx={{ width: 40 }}
      >
        <List fontSize="small" />
      </ToggleButton>
      <ToggleButton value="grid" disabled={mode === 'grid'} sx={{ width: 40 }}>
        <GridView fontSize="small" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
