import { StorageHelper } from '@utils/storageHelper';
import { useState } from 'react';

export const useSessionStorage = <T>(
  keyName: string,
  defaultValue?: T
): [T, (value: T) => void] => {
  const storage = new StorageHelper(sessionStorage);
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return defaultValue;
    }
    try {
      const value = storage.get(keyName);
      if (value) {
        return JSON.parse(value);
      } else {
        storage.set(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue: T) => {
    try {
      storage.set(keyName, JSON.stringify(newValue));
    } catch (err) {
      console.error(err);
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};
