import { Box, CircularProgress, TableCell, TableRow } from '@mui/material';
import { rowHeight } from '@utils/const';
import { FC } from 'react';
import { theme } from '../../../theme';

type Props = {
  columnsLength: number;
  height?: number;
};

//NOTE: カラム数が異なると表示が崩れるので、カラム数を合わせるために空のTAbleCellを定義している
export const GramTableFooter: FC<Props> = ({
  columnsLength,
  height = rowHeight,
}) => {
  return (
    <TableRow
      sx={{ backgroundColor: theme.palette.white.primary, height: height }}
    >
      <TableCell
        colSpan={columnsLength}
        sx={{
          p: 0,
          color: '#D9D9D9',
          textAlign: 'center',
          borderBottom: 0,
        }}
      >
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="inherit" />
        </Box>
      </TableCell>
    </TableRow>
  );
};
