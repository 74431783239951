import { ColumnProps } from '@components/organisms/dataTable/dataTable';
import { RealogramOrderBy } from 'types/realogram';

const headerSx = {
  padding: '0 0 0 16px',
};

export const getStoreBayColumns = (): ColumnProps<RealogramOrderBy>[] => [
  {
    headerName: '',
    headerSx: { width: 40 },
  },
  {
    headerName: 'ソート番号',
    headerSx: { ...headerSx, color: 'gray', maxWidth: 120, minWidth: 120 },
  },
  {
    headerName: '名前',
    headerSx: { ...headerSx, width: '100%' },
    hasDivider: true,
  },
  {
    headerName: '商品部門',
    headerSx: { ...headerSx, maxWidth: 176, minWidth: 176 },
    hasDivider: true,
  },
  {
    headerName: '売り場',
    headerSx: { ...headerSx, maxWidth: 176, minWidth: 176 },
    hasDivider: true,
  },
  {
    headerName: '権限',
    headerSx: { ...headerSx, maxWidth: 72, minWidth: 72 },
    hasDivider: true,
  },
  {
    headerName: '',
    headerSx: {
      maxWidth: '56px',
      minWidth: '56px',
      position: 'sticky',
      right: 0,
      backgroundColor: '#F6FAFF',
    },
  },
];
