import { FullHeightBox } from '@components/molecules';
import { usePageTitle } from '@hooks/usePageTitle';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { InitialSetting } from './fragments/initialSetting';
import { paths } from '@utils/const';
import { useSetInitPassword } from './hooks/useSetInitPassword';
import { toPasswordInitErrorMessage } from './utils/password';

export const PasswordInit: FC = () => {
  usePageTitle('新しいパスワードを設定');
  const navigate = useNavigate();
  const { setNewPasswordLoading, setNewPasswordError, handleSetNewPassword } =
    useSetInitPassword();

  return (
    <FullHeightBox sx={{ height: { xs: 'auto', sm: 'auto', md: '100vh' } }}>
      <InitialSetting
        errorMessage={
          setNewPasswordError && toPasswordInitErrorMessage(setNewPasswordError)
        }
        handleNavigateLogin={() => navigate(paths.login)}
        isLoading={setNewPasswordLoading}
        onSubmit={async ({ password }) => handleSetNewPassword(password)}
      />
    </FullHeightBox>
  );
};
