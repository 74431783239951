// TODO: useFlatPlanogramPlanに集約できるようにリファクタ
import {
  addBayPart,
  addProduct,
  changeProductsAmount,
  mark,
  moveBayPart,
  moveProducts,
  redo,
  removeArea,
  removeBayPart,
  removeProducts,
  replaceBayPart,
  rotateProducts,
  setPlan,
  splitArea,
  undo,
  updateBottomBucketsNum,
  updateBucketProductPosition,
  updateCanSplitHorizontal,
  updateCanSplitVertical,
  changeFront,
  resetIsRotated,
} from '@reducers/flatPlan';
import {
  selectBottomBucketsNum,
  selectHistories,
  selectIsDirty,
  selectIsRotated,
  selectPlan,
} from '@reducers/flatPlan/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useCallback, useEffect } from 'react';
import { BucketPlanogramPlan, Position } from 'types/planogram';
import { FaceFrontId, FaceOrientationId } from '../types/common';

type AddProductParams = Parameters<typeof addProduct>;
type MoveProductsParams = Parameters<typeof moveProducts>;
type RemoveProductsParams = Parameters<typeof removeProducts>;
type ChangeProductsAmount = Parameters<typeof changeProductsAmount>;

type ReplaceBayPartParams = Parameters<typeof replaceBayPart>;
type SplitAreaParams = Parameters<typeof splitArea>;
type RemoveAreaParams = Parameters<typeof removeArea>;
type UpdateBucketProductPositionParams = Parameters<
  typeof updateBucketProductPosition
>;
type AddBayPartParams = Parameters<typeof addBayPart>;
type MoveBayPartParams = Parameters<typeof moveBayPart>;

export const useFlatPlanogramPlan = (initialPlan?: BucketPlanogramPlan) => {
  const isDirty = useAppSelector(selectIsDirty);
  const plan = useAppSelector(selectPlan);
  const histories = useAppSelector(selectHistories);
  const isRotated = useAppSelector(selectIsRotated);
  const dispatch = useAppDispatch();
  const bottomBucketsNum = useAppSelector(selectBottomBucketsNum);
  const { canSplitHorizontal, canSplitVertical, bucketProductPosition } =
    useAppSelector((state) => state.FlatPlan);

  useEffect(() => {
    if (initialPlan) {
      dispatch(setPlan(initialPlan));
    }
  }, [dispatch, initialPlan]);

  const functions = {
    forget: useCallback(() => void dispatch(setPlan()), [dispatch]),
    undoFlat: useCallback(() => void dispatch(undo()), [dispatch]),
    redoFlat: useCallback(() => void dispatch(redo()), [dispatch]),
    setPlan: useCallback(
      (plan: BucketPlanogramPlan) => void dispatch(setPlan(plan)),
      [dispatch]
    ),
    // Manipulate Product
    addProduct: useCallback(
      (...args: AddProductParams) => void dispatch(addProduct(...args)),
      [dispatch]
    ),
    moveProducts: useCallback(
      (...args: MoveProductsParams) => void dispatch(moveProducts(...args)),
      [dispatch]
    ),
    changeProductsAmount: useCallback(
      (...args: ChangeProductsAmount) =>
        void dispatch(changeProductsAmount(...args)),
      [dispatch]
    ),
    incrementHorizontalAmount: useCallback(
      (bucketIndex: number, areaPath?: Position[]) =>
        void dispatch(
          changeProductsAmount({
            bucketIndex,
            areaPath,
            amount: { x: 1, y: 0 },
          })
        ),
      [dispatch]
    ),
    decrementHorizontalAmount: useCallback(
      (bucketIndex: number, areaPath?: Position[]) =>
        void dispatch(
          changeProductsAmount({
            bucketIndex,
            areaPath,
            amount: { x: -1, y: 0 },
          })
        ),
      [dispatch]
    ),
    incrementVerticalAmount: useCallback(
      (bucketIndex: number, areaPath?: Position[]) =>
        void dispatch(
          changeProductsAmount({
            bucketIndex,
            areaPath,
            amount: { x: 0, y: 1 },
          })
        ),
      [dispatch]
    ),
    decrementVerticalAmount: useCallback(
      (bucketIndex: number, areaPath?: Position[]) =>
        void dispatch(
          changeProductsAmount({
            bucketIndex,
            areaPath,
            amount: { x: 0, y: -1 },
          })
        ),
      [dispatch]
    ),
    removeProducts: useCallback(
      (...args: RemoveProductsParams) => void dispatch(removeProducts(...args)),
      [dispatch]
    ),
    replaceBayPart: useCallback(
      (...args: ReplaceBayPartParams) => {
        dispatch(replaceBayPart(...args));
      },
      [dispatch]
    ),
    mark: useCallback(() => void dispatch(mark()), [dispatch]),
    splitArea: useCallback(
      (...args: SplitAreaParams) => void dispatch(splitArea(...args)),
      [dispatch]
    ),
    removeArea: useCallback(
      (...args: RemoveAreaParams) => void dispatch(removeArea(...args)),
      [dispatch]
    ),
    rotateProducts: useCallback(
      (
        bucketIndex: number,
        areaPath: Position[],
        values: { faceFront: FaceFrontId; orientation: FaceOrientationId }
      ) =>
        void dispatch(
          rotateProducts({
            bucketIndex,
            areaPath,
            values: [values.faceFront, values.orientation],
          })
        ),
      [dispatch]
    ),
    updateBucketProductPosition: useCallback(
      (...args: UpdateBucketProductPositionParams) =>
        void dispatch(updateBucketProductPosition(...args)),
      [dispatch]
    ),
    updateCanSplitHorizontal: useCallback(
      (value: boolean) => void dispatch(updateCanSplitHorizontal(value)),
      [dispatch]
    ),
    updateCanSplitVertical: useCallback(
      (value: boolean) => void dispatch(updateCanSplitVertical(value)),
      [dispatch]
    ),
    addBayPart: useCallback(
      (...args: AddBayPartParams) => {
        dispatch(addBayPart(...args));
      },
      [dispatch]
    ),
    updateBottomBucketsNum: useCallback(
      (value: number) => void dispatch(updateBottomBucketsNum(value)),
      [dispatch]
    ),
    removeBayPart: useCallback(
      (value: number) => void dispatch(removeBayPart(value)),
      [dispatch]
    ),
    moveBayPart: useCallback(
      (...args: MoveBayPartParams) => void dispatch(moveBayPart(...args)),
      [dispatch]
    ),
    changeFront: useCallback(() => void dispatch(changeFront()), [dispatch]),
    resetIsRotated: useCallback(
      () => void dispatch(resetIsRotated()),
      [dispatch]
    ),
  };

  return {
    ...functions,
    plan,
    isDirty,
    hasNextFlat: histories.future.length !== 0,
    hasPrevFlat: histories.past.length !== 0,
    bucketProductPosition,
    canSplitHorizontal,
    canSplitVertical,
    bottomBucketsNum,
    isRotated,
  };
};
