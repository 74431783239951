import { ProductDrawer } from '@components/organisms';
import { Categories } from '@components/organisms/categories/categories';
import { ProductsHeader } from '@components/pages/planogramEditor/fragments/productsHeader/productsHeader';
import { ProductsSearchResults } from '@components/organisms/productsSearchResults/productsSearchResults';
import { Box, Divider } from '@mui/material';
import { Dispatch, FC, RefObject, SetStateAction } from 'react';
import { Control } from 'react-hook-form';
import { theme } from 'theme';
import { Product } from 'types/common';
import { ProductCategory } from 'types/productCategories';
import { RecentProducts } from './recentProducts/recentProducts';
import { FormValues, SearchedData } from 'types/products';
import { ProductFlagChips } from '@components/organisms/productFlagChips/productFlagChips';

type Props = {
  isFocused: boolean;
  displayRecentProducts: boolean;
  control: Control<FormValues>;
  categoryIndex: number;
  productCategoryHistory: ProductCategory[];
  recentProducts: Product[];
  isCompareMode: boolean;
  selectedProductCompartment?: Product;
  searchWord: string;
  categoryType: 'categories' | 'list';
  productsRef: RefObject<HTMLDivElement>;
  isLoading: boolean;
  isSearchedLoading: boolean;
  data: SearchedData;
  offset: number;
  isPlanogramSalesEnded: boolean;
  searchedData: SearchedData;
  searchedOffset: number;
  filteredCategories: ProductCategory[];
  isShowProductDetail: boolean;
  tags: string[];
  focus: VoidFunction;
  onClearSearchClick: VoidFunction;
  onCategoryTitleClick: VoidFunction;
  onClickRecentProducts: VoidFunction;
  handleClickProduct: (product: Product) => void;
  handleClick: (category: ProductCategory) => void;
  handleEndReached: (index: number) => void;
  handleChangeSalesEnded: VoidFunction;
  fetchFilteredCategoriesReset: VoidFunction;
  setSearchedOffset: Dispatch<SetStateAction<number>>;
  handleClickProductCategory: (category: ProductCategory) => void;
  handleSearchEndReached: (index: number) => void;
  handleSubmit: VoidFunction;
  handleTagClick: (tag?: string) => void;
};

export const ProductTabPanel: FC<Props> = ({
  isFocused,
  displayRecentProducts,
  control,
  focus,
  onClearSearchClick,
  onCategoryTitleClick,
  categoryIndex,
  productCategoryHistory,
  onClickRecentProducts,
  recentProducts,
  isCompareMode,
  handleClickProduct,
  selectedProductCompartment,
  searchWord,
  categoryType,
  handleClick,
  productsRef,
  handleEndReached,
  isLoading,
  isSearchedLoading,
  data,
  offset,
  isPlanogramSalesEnded,
  handleChangeSalesEnded,
  searchedData,
  fetchFilteredCategoriesReset,
  setSearchedOffset,
  searchedOffset,
  filteredCategories,
  handleClickProductCategory,
  handleSearchEndReached,
  isShowProductDetail,
  handleSubmit,
  handleTagClick,
  tags,
}) => {
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      display="flex"
      flexDirection="column"
      height="100%"
      overflow="hidden"
      px={1}
    >
      {categoryType === 'list' && (
        <>
          <Box component="div">
            <ProductFlagChips
              handleClick={handleTagClick}
              tags={tags}
              isDisabled={isLoading}
              isSalesEnded={isPlanogramSalesEnded}
              handleChangeSalesEnded={handleChangeSalesEnded}
              isLoading={isLoading}
            />
          </Box>
          <Divider sx={{ mb: 1, mx: -1 }} />
        </>
      )}
      <ProductsHeader
        displaySearchInput={isFocused}
        displayActionButtons={!isFocused && !displayRecentProducts}
        control={control}
        onClearSearchClick={onClearSearchClick}
        onCategoryTitleClick={onCategoryTitleClick}
        categoryIndex={categoryIndex}
        productCategoryHistory={productCategoryHistory}
        onClickSearch={focus}
        onClickRecentProducts={onClickRecentProducts}
        displayRecentProducts={displayRecentProducts}
      />
      <Divider sx={{ mb: 1, mx: -1 }} />
      {displayRecentProducts ? (
        <RecentProducts
          products={recentProducts}
          isCompareMode={isCompareMode}
          handleClickProduct={handleClickProduct}
          selectedItemId={selectedProductCompartment?.id ?? 0}
        />
      ) : (
        <>
          {!searchWord ? (
            <Categories
              categoryName={productCategoryHistory[categoryIndex]?.name}
              categoryType={categoryType}
              productCategories={
                productCategoryHistory[categoryIndex]?.sub_categories
              }
              handleClick={handleClick}
              productsRef={productsRef}
              isCompareMode={isCompareMode}
              handleEndReached={handleEndReached}
              isLoading={isLoading}
              products={data?.products}
              handleClickProduct={handleClickProduct}
              offset={offset}
              selectedItemId={selectedProductCompartment?.id ?? 0}
            />
          ) : (
            <ProductsSearchResults
              products={searchedData?.products}
              reset={() => {
                fetchFilteredCategoriesReset();
                setSearchedOffset(0);
              }}
              offset={searchedOffset}
              filteredCategories={filteredCategories}
              handleClickProductCategory={(category: ProductCategory) => {
                handleClickProductCategory(category);
                setSearchedOffset(0);
              }}
              isLoading={isSearchedLoading}
              productsRef={productsRef}
              isCompareMode={isCompareMode}
              handleEndReached={handleSearchEndReached}
              total={searchedData?.pager.total}
              handleClickProduct={handleClickProduct}
              selectedItemId={selectedProductCompartment?.id ?? 0}
              handleChangeSalesEnded={handleChangeSalesEnded}
              isSalesEnded={isPlanogramSalesEnded}
              isSalesEndSwitchBorderTop
            />
          )}
        </>
      )}
      {selectedProductCompartment && !isShowProductDetail && (
        <Box
          component="div"
          sx={{
            p: 2,
            position: 'absolute',
            zIndex: 2,
            bottom: 0,
            width: '100%',
            boxShadow: '5px 2px 8px rgba(0, 0, 0, 0.25)',
            backgroundColor: theme.palette.white.primary,
            ...(isCompareMode && {
              right: 0,
              height: '80px',
            }),
            ...(!isCompareMode && {
              left: 0,
            }),
          }}
        >
          <ProductDrawer
            isCompareMode={isCompareMode}
            product={selectedProductCompartment}
            productsRef={productsRef}
          />
        </Box>
      )}
    </Box>
  );
};
