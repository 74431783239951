import { ProductCategory } from 'types/productCategories';

// 再帰的に対象のカテゴリへのpathを返す
export const findCategoryPath = (
  categories: ProductCategory[],
  needle: ProductCategory
): ProductCategory[] | undefined => {
  const category = categories.find((category) =>
    isSameCategory(category, needle)
  );
  if (category) {
    return [category];
  }

  // 見つからないのでサブカテゴリに対して再帰的に探す
  for (const category of categories) {
    const result = findCategoryPath(category.sub_categories, needle);
    if (result) {
      return [category, ...result];
    }
  }

  return undefined;
};

/**
 * @deprecated use findCategoryPath
 */
export const findTargetCategoryPath = findCategoryPath;

const isSameCategory = (
  categoryA: ProductCategory,
  categoryB: ProductCategory
) =>
  categoryA.name === categoryB.name &&
  categoryA.product_division_code === categoryB.product_division_code;

export const extractCategories = (
  categories: ProductCategory[]
): ProductCategory[] =>
  categories.flatMap((category) => [
    category,
    ...extractCategories(category.sub_categories),
  ]);
