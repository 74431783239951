import { AlertDialog } from '@components/organisms/alertDialog/alertDialog';
import { Button, DialogContentText } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  open: boolean;
  handleCancel: () => void;
};

export const ScanPermissionDialog: FC<Props> = ({ open, handleCancel }) => {
  return (
    <AlertDialog
      open={open}
      title="スキャンできません"
      content={
        <DialogContentText color={theme.palette.textBlack.primary}>
          編集権限のある店舗が存在しないため、新規スキャンを開始できません。
        </DialogContentText>
      }
      cancelButton={null}
      confirmButton={
        <Button
          onClick={handleCancel}
          sx={{ color: theme.palette.textBlack.primary, fontWeight: 700 }}
        >
          閉じる
        </Button>
      }
    />
  );
};
