import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from '@utils/const';
import { RealogramUploadStep } from 'types/realogram';
import { useState } from 'react';

type LocationState = {
  from?: string;
};

export const useNavigateUploadPage = () => {
  const navigate = useNavigate();
  const location: { state?: LocationState } = useLocation();
  const [step, setStep] = useState<RealogramUploadStep>('one');
  const prevPage = location.state?.from;

  const prevNavigate = () => {
    if (prevPage === paths.home) {
      navigate(paths.home);
    } else {
      navigate(paths.actuals.viewList);
    }
  };

  // isNextがtrueなら次、falseなら一つもどる
  const changeStep = (
    currentStep: RealogramUploadStep,
    isNext: boolean,
    isDisplayNextTimeOn?: boolean
  ) => {
    if (isNext) {
      switch (currentStep) {
        case 'one':
          setStep('two');
          break;
        case 'two':
          // 次回から表示しないにチェックが入っているかによって次のステップが変わる
          isDisplayNextTimeOn ? setStep('four') : setStep('three');
          break;
        case 'three':
          setStep('four');
          break;
        case 'four':
          setStep('five');
          break;
      }
    } else {
      switch (currentStep) {
        case 'one':
          prevNavigate();
          break;
        case 'two':
          setStep('one');
          break;
        case 'three':
          setStep('two');
          break;
      }
    }
  };

  return {
    step,
    setStep,
    prevNavigate,
    changeStep,
  };
};
