import { Box, Typography } from '@mui/material';
import { HeatMapColors, heatMapColors } from '@utils/colors';
import { FC } from 'react';
import { theme } from '../../../theme';

type Props = {
  start: string;
  end: string;
};

export const HeatMap: FC<Props> = ({ start, end }) => {
  return (
    <Box
      component="div"
      sx={{
        padding: '6px',
        backgroundColor: theme.palette.white.translucent,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography fontSize="10px" mb={0.5}>
        {end}
      </Typography>
      <Box
        component="div"
        sx={{
          border: `2px solid ${theme.palette.white.primary}`,
          display: 'flex',
          flexDirection: 'column-reverse',
          alignItems: 'center',
        }}
      >
        {heatMapColors.map((color) => {
          return (
            <Box
              key={color}
              component="div"
              sx={{
                width: 10,
                height: 24,
                backgroundColor: HeatMapColors[color],
              }}
            />
          );
        })}
      </Box>
      <Typography fontSize="10px" mt={0.5}>
        {start}
      </Typography>
    </Box>
  );
};
