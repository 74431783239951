import { paths } from 'types/api';
import { baseApi } from '../baseApi';

type GetProductCategoriesResponse =
  paths['/api/v1/product_categories']['get']['responses']['200']['content']['application/json'];

// see) https://staging.shelf.pfn.dev/docs#/product_categories
const productCategoriesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    productCategories: builder.query<GetProductCategoriesResponse, void>({
      query: () => ({
        url: '/product_categories',
      }),
    }),
  }),
});

export const { useProductCategoriesQuery } = productCategoriesApi;
