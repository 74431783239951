import { useEffect, useState } from 'react';

export const useImagePreview = (file?: File) => {
  const [src, setSrc] = useState<string>();
  useEffect(() => {
    if (file) {
      const newUrl = URL.createObjectURL(file);
      setSrc(newUrl);
    } else {
      setSrc('');
    }
  }, [file]);

  return { src };
};
