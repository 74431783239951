import {
  EstimateForPlanogramResponse,
  EstimateForRealogramResponse,
  useLazyEstimateForPlanogramQuery,
  useLazyEstimateForRealogramQuery,
} from '@reducers/shelfAppsApi';
import { pointersDummy, getProfitsMenu } from '@utils/const';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EstimatedPlanogram, Planogram, PostPlanogram } from 'types/planogram';
import { StoreArea } from 'types/products';
import { RealogramCandidate } from 'types/realogram';
import { t } from 'i18next';

type Props = {
  planogram?: Planogram;
  realogram?: RealogramCandidate;
  editedPlan?: EstimatedPlanogram;
};

const storeAreaType = pointersDummy[0].value;

export const useEstimatedProfit = ({
  planogram,
  realogram,
  editedPlan,
}: Props) => {
  const [planogramEstimatedData, setPlanogramEstimatedData] = useState<
    EstimateForPlanogramResponse | undefined
  >();
  const [realogramEstimatedData, setRealogramEstimatedData] = useState<
    EstimateForRealogramResponse | undefined
  >();
  const [profit, setProfit] = useState<string>(
    getProfitsMenu(t('gross_profit'))[0]
  );
  const [isLoadingEstimate, setIsLoadingEstimate] = useState<boolean>(true);
  const [getPlanogramData, { isError: isPlanogramError }] =
    useLazyEstimateForPlanogramQuery();
  const [getRealogramData, { isError: isRealogramError }] =
    useLazyEstimateForRealogramQuery();
  const preparedPlanogram: PostPlanogram = useMemo(() => {
    return {
      name: planogram?.name ?? '',
      bay_plan_id: planogram?.bay_plan_id ?? 0,
      store_bay_id: planogram?.store_bay_id,
      bay_plan_code_id: planogram?.bay_plan_code_id,
      organization_status_id: planogram?.organization_status_id,
      plan: planogram?.plan,
      custom_field: planogram?.custom_field,
      directory_parent_id: planogram?.directory_parent_id,
    };
  }, [planogram]);

  const handleChangeProfit = (value: string) => {
    setProfit(value);
  };

  const handleRecalculate = async () => {
    try {
      await fetchPlanogramData(
        { ...preparedPlanogram, plan: editedPlan },
        storeAreaType,
        planogram?.store_id
      );
    } catch (error) {
      console.log(error);
    }
  };
  const fetchPlanogramData = useCallback(
    async (
      planogram: PostPlanogram,
      storeAreaType: StoreArea,
      storeAreaId?: number
    ) => {
      if (!storeAreaId) return;
      try {
        // TODO:fix ts error with KeysToCamelCase
        setIsLoadingEstimate(true);
        const result = await getPlanogramData({
          planogram: { ...planogram, bayPlanId: planogram.bay_plan_id },
          storeAreaType,
          storeAreaId,
        }).unwrap();
        setPlanogramEstimatedData(result);
        return result;
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingEstimate(false);
      }
    },
    [getPlanogramData]
  );
  const fetchRealogramData = useCallback(
    async (
      realogramCandidate: RealogramCandidate,
      storeAreaType: StoreArea
    ) => {
      try {
        // TODO:fix ts error with KeysToCamelCase
        setIsLoadingEstimate(true);
        const result = await getRealogramData({
          realogramCandidate: {
            ...realogramCandidate,
            createdAt: realogramCandidate.created_at,
            status: realogramCandidate.status,
            storeBay: {
              ...realogramCandidate.store_bay,
              storeId: realogramCandidate.store_bay.store_id,
            },
            storeBayId: realogramCandidate.store_bay_id,
            owner: {
              ...realogramCandidate.owner,
              createdAt: realogramCandidate.owner.created_at,
              updatedAt: realogramCandidate.owner.updated_at,
            },
          },
          storeAreaType,
        }).unwrap();
        setRealogramEstimatedData(result);
        return result;
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingEstimate(false);
      }
    },
    [getRealogramData]
  );
  useEffect(() => {
    if (planogram) {
      void fetchPlanogramData(
        preparedPlanogram,
        storeAreaType,
        planogram?.store_id
      );
    }
  }, [planogram, fetchPlanogramData, preparedPlanogram]);
  useEffect(() => {
    if (realogram) {
      void fetchRealogramData(realogram, storeAreaType);
    }
  }, [realogram, fetchRealogramData]);
  return {
    profit,
    storeAreaType,
    handleChangeProfit,
    handleRecalculate,
    planogramEstimatedData: isPlanogramError
      ? undefined
      : planogramEstimatedData,
    realogramEstimatedData: isRealogramError
      ? undefined
      : realogramEstimatedData,
    isLoadingEstimate,
  };
};
