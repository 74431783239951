import { FC } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import { theme } from 'theme';

type Props = {
  isCanCreate?: boolean;
  isCanUpdate?: boolean;
  isCanRead?: boolean;
  isDirectory?: boolean;
  isGridView?: boolean;
  disabled?: boolean;
};

export const ActionVisible: FC<Props> = ({
  isCanCreate,
  isCanUpdate,
  isCanRead,
  isDirectory,
  isGridView,
  disabled,
}) => {
  const htmlColor = disabled
    ? theme.palette.icons.disabled
    : theme.palette.icons.secondary;

  const iconStyle = {
    width: '20px',
    height: '20px',
    ...(isGridView && { bottom: '0px', marginRight: '5px' }),
  };

  if (isCanUpdate || isCanCreate) {
    return <EditIcon htmlColor={htmlColor} sx={iconStyle} />;
  }

  if (isCanRead || isDirectory) {
    return <VisibilityIcon htmlColor={htmlColor} sx={iconStyle} />;
  }

  return <LockIcon htmlColor={htmlColor} sx={iconStyle} />;
};
