import { Product, ProductTag, ShelfDetailView } from 'types/common';
import {
  PlanogramPlan,
  PlanogramProductCompartment,
  PlanogramProductCompartmentList,
  ProductPosition,
} from 'types/planogram';
import { ProductReportProduct } from 'types/products';
import { PlanogramProductCompartmentExpand } from '../types';
import { hasProductTag } from '@utils/product';

export const getProductsLayoutByView = (
  plan: PlanogramPlan,
  view: ShelfDetailView,
  productTag: ProductTag,
  products?: Product[],
  productSales?: ProductReportProduct[]
) => {
  const productsLayout = plan.products_layout.slice().reverse();

  const productMap = products?.reduce((acc, product) => {
    acc[product.id] = product;
    return acc;
  }, {} as Record<string, Product>);

  const productSalesMap = productSales?.reduce((acc, product) => {
    acc[product.product_id] = product;
    return acc;
  }, {} as Record<string, ProductReportProduct>);

  const mapCompartment = (
    compartment: PlanogramProductCompartment,
    shelfIndex: number,
    index: number
  ): PlanogramProductCompartmentExpand => {
    const position = {
      indexX: 0,
      indexY: productsLayout.length - shelfIndex - 1,
      subPosition: {
        indexX: index,
        indexY: 0,
      },
    };
    return {
      ...compartment,
      position,
    };
  };

  const filterCompartments = (
    row: PlanogramProductCompartmentList['row'],
    shelfIndex: number
  ) => {
    return row.reduce((filtered, compartment, index) => {
      const product = productMap?.[compartment.product_id];
      const isTagged = hasProductTag(productTag, product?.detail?.tags);
      if (isTagged) {
        const data = mapCompartment(compartment, shelfIndex, index);
        filtered.push(data);
      }

      return filtered;
    }, [] as PlanogramProductCompartmentExpand[]);
  };

  const filterProfitCompartments = (
    row: PlanogramProductCompartmentList['row'],
    shelfIndex: number
  ) => {
    return row.reduce((filtered, compartment, index) => {
      const product = productMap?.[compartment.product_id];
      const salesProduct = productSalesMap?.[compartment.product_id];
      const isSameProduct = product?.id === salesProduct?.product_id;
      if (isSameProduct) {
        const data = mapCompartment(compartment, shelfIndex, index);
        filtered.push(data);
      }

      return filtered;
    }, [] as PlanogramProductCompartmentExpand[]);
  };
  const getNewRow = (
    row: PlanogramProductCompartmentList['row'],
    shelfIndex: number,
    view: ShelfDetailView
  ) => {
    switch (view) {
      case 'productFlag':
        return filterCompartments(row, shelfIndex);
      case 'profit':
        return filterProfitCompartments(row, shelfIndex);
      default:
        return row.map((compartment, index) =>
          mapCompartment(compartment, shelfIndex, index)
        );
    }
  };

  return productsLayout.map(({ row, ...rest }, shelfIndex) => {
    const newRow = getNewRow(row, shelfIndex, view);
    return {
      ...rest,
      row: newRow,
    };
  });
};

export const getCurrentCompartmentPosition = (
  productsLayout: ReturnType<typeof getProductsLayoutByView>,
  productPosition: ProductPosition,
  productId?: number
): ProductPosition | undefined => {
  const { indexY, subPosition } = productPosition;
  const shelf = productsLayout.find(({ row }) =>
    row.some(
      (compartment) =>
        compartment.product_id === productId &&
        compartment.position.indexY === indexY &&
        compartment.position.subPosition.indexX === subPosition.indexX
    )
  );

  if (!shelf) return undefined;

  const shelfIndex = productsLayout.reverse().indexOf(shelf);

  const compartmentIndex = shelf.row.findIndex(
    (compartment) =>
      compartment.product_id === productId &&
      compartment.position.subPosition.indexX === subPosition.indexX
  );

  return {
    indexX: 0,
    indexY: shelfIndex,
    subPosition: {
      indexX: compartmentIndex,
      indexY: 0,
    },
  };
};
