import { setPageTitle } from '@reducers/pageTitle';
import { useAppDispatch } from '@store/index';
import { useEffect } from 'react';

export const usePageTitle = (title = '') => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setPageTitle(title));
  }, [title, dispatch]);
};
