import {
  addBayPart,
  addProduct,
  changeProductsAmount,
  mark,
  moveBayPart,
  moveProducts,
  redo,
  removeBayPart,
  removeProducts,
  replaceBayPart,
  rotateProducts,
  setPlan,
  undo,
  updateCompartmentAttributes,
  selectBayPartPosition as selectBayPartPositionAction,
} from '@reducers/plan';
import {
  selectBayPartPosition,
  selectHistories,
  selectIsDirty,
  selectPlan,
} from '@reducers/plan/selectors';
import { selectBayPartId } from '@reducers/planogramEditor/reducer';
import { useAppDispatch, useAppSelector } from '@store/index';
import { createPlanogramBayPart } from '@utils/bayPart';
import { isPlanogramPlan } from '@utils/planogram';
import { useCallback, useEffect } from 'react';
import { BayPlanBayPart } from 'types/bayPlan';
import { FaceFrontId, FaceOrientationId } from 'types/common';
import {
  BayPartPosition,
  JustifyContent,
  Planogram,
  PlanogramPlan,
  Position,
  ProductPosition,
} from 'types/planogram';

type AddProductParams = Parameters<typeof addProduct>;
type MoveProductsParams = Parameters<typeof moveProducts>;
type RemoveProductsParams = Parameters<typeof removeProducts>;
type ChangeProductsAmount = Parameters<typeof changeProductsAmount>;

type ReplaceBayPartParams = Parameters<typeof replaceBayPart>;
type SelectBayPartPositionParams = Parameters<
  typeof selectBayPartPositionAction
>;

export const usePlanogramPlan = (initialPlan?: Planogram['plan']) => {
  const isDirty = useAppSelector(selectIsDirty);
  const plan = useAppSelector(selectPlan);
  const histories = useAppSelector(selectHistories);
  const bayPartPosition = useAppSelector(selectBayPartPosition);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (initialPlan && isPlanogramPlan(initialPlan)) {
      dispatch(setPlan(initialPlan));
    }
  }, [dispatch, initialPlan]);

  const functions = {
    forget: useCallback(() => void dispatch(setPlan()), [dispatch]),
    undo: useCallback(() => void dispatch(undo()), [dispatch]),
    redo: useCallback(() => void dispatch(redo()), [dispatch]),
    setPlan: useCallback(
      (plan: PlanogramPlan) => void dispatch(setPlan(plan)),
      [dispatch]
    ),
    // Manipulate Product
    addProduct: useCallback(
      (...args: AddProductParams) => void dispatch(addProduct(...args)),
      [dispatch]
    ),
    moveProducts: useCallback(
      (...args: MoveProductsParams) => void dispatch(moveProducts(...args)),
      [dispatch]
    ),
    rotateProducts: useCallback(
      (
        at: ProductPosition,
        values: { faceFront: FaceFrontId; orientation: FaceOrientationId }
      ) =>
        void dispatch(
          rotateProducts({
            at,
            values: [values.faceFront, values.orientation],
          })
        ),
      [dispatch]
    ),
    changeProductsAmount: useCallback(
      (...args: ChangeProductsAmount) =>
        void dispatch(changeProductsAmount(...args)),
      [dispatch]
    ),
    incrementHorizontalAmount: useCallback(
      (at: ProductPosition) =>
        void dispatch(changeProductsAmount({ at, amount: { x: 1, y: 0 } })),
      [dispatch]
    ),
    decrementHorizontalAmount: useCallback(
      (at: ProductPosition) =>
        void dispatch(changeProductsAmount({ at, amount: { x: -1, y: 0 } })),
      [dispatch]
    ),
    incrementVerticalAmount: useCallback(
      (at: ProductPosition) =>
        void dispatch(changeProductsAmount({ at, amount: { x: 0, y: 1 } })),
      [dispatch]
    ),
    decrementVerticalAmount: useCallback(
      (at: ProductPosition) =>
        void dispatch(changeProductsAmount({ at, amount: { x: 0, y: -1 } })),
      [dispatch]
    ),
    removeProducts: useCallback(
      (...args: RemoveProductsParams) => void dispatch(removeProducts(...args)),
      [dispatch]
    ),
    // Manipulate BayPart
    addBayPart: useCallback(
      (bayPart: BayPlanBayPart, to: BayPartPosition) =>
        void dispatch(
          addBayPart(createPlanogramBayPart(bayPart, to.elevation))
        ),
      [dispatch]
    ),
    moveBayPart: useCallback(
      (from: Position, to: BayPartPosition) => {
        dispatch(
          moveBayPart({
            from,
            to,
          })
        );
      },
      [dispatch]
    ),
    removeBayPart: useCallback(
      (at: Position) => {
        dispatch(removeBayPart(at));
        dispatch(selectBayPartId(undefined));
      },
      [dispatch]
    ),
    replaceBayPart: useCallback(
      (...args: ReplaceBayPartParams) => {
        dispatch(replaceBayPart(...args));
      },
      [dispatch]
    ),
    setJustifyContent: useCallback(
      (at: Position, justifyContent: JustifyContent) => {
        dispatch(
          updateCompartmentAttributes({ at, attributes: { justifyContent } })
        );
      },
      [dispatch]
    ),
    mark: useCallback(() => void dispatch(mark()), [dispatch]),
    selectBayPartPosition: useCallback(
      (...args: SelectBayPartPositionParams) => {
        dispatch(selectBayPartPositionAction(...args));
      },
      [dispatch]
    ),
  };

  return {
    ...functions,
    plan,
    isDirty,
    hasNext: histories.future.length !== 0,
    hasPrev: histories.past.length !== 0,
    bayPartPosition,
  };
};
