import { ModalInnerProps } from '@components/modal';
import { RequiredText } from '@components/molecules/requiredText/requiredText';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField } from '@mui/material';
import { isOnlySpaces } from '@utils/const';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InferType, mixed, number, object, string } from 'yup';
import { ModalActions } from '../modalActions/modalActions';

type DirectoryType = 'directory' | 'file';

const schema = object({
  name: string()
    .required('フォルダ名を入力してください。')
    .matches(
      /^(?![\s\u3000]*$).*/,
      '半角スペースまたは全角スペースのみで登録することはできません。'
    ),
  parentId: string(),
  type: mixed<'directory' | 'file'>().required().oneOf(['directory', 'file']),
  id: string(),
  planogramId: number(),
});
type FormData = InferType<typeof schema>;

export type CreateOrRenameDirectoryFormProps = {
  name?: string;
  parentId?: string;
  type: DirectoryType;
  planogramId?: number;
  actionBtnTitle?: string;
  id?: number;
  onSubmit: (data: FormData) => void;
};
type Props = ModalInnerProps<CreateOrRenameDirectoryFormProps>;

export const CreateOrRenameDirectoryForm: FC<Props> = ({
  // typeはdirectoryで固定
  data: {
    name: defaultName,
    actionBtnTitle = '作成',
    type: defaultType = 'directory',
    parentId,
    planogramId,
    onSubmit,
  },
  handleClose,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: defaultName,
      type: defaultType,
      parentId,
      planogramId,
    },
  });

  const name = watch('name');
  return (
    <Box component="form" width={500} onSubmit={handleSubmit(onSubmit)}>
      <RequiredText mb={3} />
      <Box component="div" mb={3}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              fullWidth
              size="small"
              label="フォルダ名"
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
            />
          )}
        />
      </Box>
      <ModalActions
        handleClose={handleClose}
        actionBtnTitle={actionBtnTitle}
        disabled={!name || isOnlySpaces(name)}
      />
    </Box>
  );
};
