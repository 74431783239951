import { ProductTag, ShelfDetailMode, ShelfDetailView } from 'types/common';

type QueryParamsObject = Record<string, string>;

export const filteringQueryParams = (
  allowKeys: string[],
  existingParams: QueryParamsObject
) => {
  return Object.fromEntries(
    Object.entries(existingParams).filter(([key]) => allowKeys.includes(key))
  );
};

export const getViewQueryParams = (
  searchParams: URLSearchParams
): ShelfDetailView => {
  const params = searchParams.get('view');

  switch (params) {
    case 'attribute':
      return 'productFlag';

    case 'analytics':
      return 'profit';

    case 'evaluation':
      return 'rate';

    case 'layout':
    default:
      return 'default';
  }
};
export const getModeQueryParams = (
  searchParams: URLSearchParams
): ShelfDetailMode => {
  const params = searchParams.has('compare');
  return params ? 'comparison' : 'default';
};
export const getAttributeQueryParams = (
  searchParams: URLSearchParams
): ProductTag => {
  const params = searchParams.get('attribute');
  switch (params) {
    case 'new_products_first_week':
    case 'new_products_second_week':
    case 'base_product':
    case 'sales_ended':
    case 'not_3D_scanned':
      return params;

    default:
      return 'new_products_first_week';
  }
};
