import { useFlatPlanogramPlan } from '@hooks/useFlatPlanogramPlan';
import { Box } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { isEqual } from 'lodash';
import { FC, MouseEvent, ReactNode, useEffect, useState } from 'react';
import { theme } from 'theme';
import { BucketArea as BucketAreaType, Position } from 'types/planogram';

type Props = {
  bucketArea: BucketAreaType;
  index: number;
  selectedBucketId?: number;
  bucketIndex: number;
  splitAxis?: string | null;
  onHandleClickArea: (
    e: MouseEvent<HTMLElement>,
    bucketIndex?: number,
    path?: Position[],
    productId?: number | undefined
  ) => void;
  children: ReactNode;
  path?: Position[];
  width: string;
  height: string;
  hightLightAreaPosition?: Position[];
  isCompared?: boolean;
};

export const BucketAreaBox: FC<Props> = ({
  bucketArea,
  index,
  selectedBucketId,
  bucketIndex,
  splitAxis,
  children,
  onHandleClickArea,
  path,
  width,
  height,
  hightLightAreaPosition,
  isCompared = false,
}) => {
  const { bucketMode, mode, selectedBucketIdClone, isDragProduct } =
    useAppSelector(selectPlanogramEditorState);
  const { bucketProductPosition } = useFlatPlanogramPlan();
  const [isHightLightBox, setHightLightBox] = useState(false);
  const areaStyle = () => {
    const isSelected =
      isEqual(bucketProductPosition, path) &&
      bucketIndex === selectedBucketId &&
      bucketMode === 'area' &&
      mode === 'CompartmentEditor' &&
      bucketArea.type !== 'compartment' &&
      !isDragProduct;
    return {
      backgroundColor:
        isSelected || (isHightLightBox && !bucketArea.children?.length)
          ? '#E5F0FF'
          : '',
      border:
        isSelected || (isHightLightBox && !bucketArea.children?.length)
          ? '2px solid #0A40CA'
          : '',
    };
  };
  const hasBorder =
    (bucketArea.type === 'area' &&
      selectedBucketId === bucketIndex &&
      mode === 'CompartmentEditor') ||
    (bucketArea.type === 'area' &&
      selectedBucketIdClone === bucketIndex &&
      mode === 'CompartmentEditor');

  const sx = {
    borderTop:
      splitAxis === 'compartment_y' &&
      index === 0 &&
      (hasBorder || isDragProduct)
        ? `1px solid ${theme.palette.dividerBlack.dark}`
        : '',
    borderRight:
      splitAxis === 'compartment_x' &&
      index === 0 &&
      (hasBorder || isDragProduct)
        ? `1px solid ${theme.palette.dividerBlack.dark}`
        : '',
  };

  const areaAttributeKey = path
    ?.map((el) => {
      if (el.indexX === 0 && el.indexY === 0) return 0;
      return 1;
    })
    .join('-');

  const hightLightKey = hightLightAreaPosition
    ?.map((el) => {
      if (el.indexX === 0 && el.indexY === 0) return 0;
      return 1;
    })
    .join('-');

  useEffect(() => {
    if (!hightLightAreaPosition || !isDragProduct) {
      setHightLightBox(false);
    }
  }, [hightLightAreaPosition, isDragProduct]);

  return (
    <>
      <Box
        component="div"
        key={index}
        width={width}
        height={height}
        sx={{
          ...sx,
        }}
        style={areaStyle()}
        onClick={(e) => {
          onHandleClickArea(e, bucketIndex, path, bucketArea.product_id);
        }}
        data-area-popper={
          isCompared
            ? undefined
            : `area-popper-${bucketIndex ?? ''}-${areaAttributeKey ?? ''}`
        }
        onDragOver={(e) => {
          e.preventDefault();
          if (
            hightLightKey &&
            areaAttributeKey &&
            hightLightKey === areaAttributeKey
          ) {
            setHightLightBox(true);
          }
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          setHightLightBox(false);
        }}
      >
        {children}
      </Box>
    </>
  );
};
