import { AlertDialog } from '@components/organisms/alertDialog/alertDialog';
import { Button } from '@mui/material';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';
import { DirectoryRoleUserInfo } from 'types/sharePermission';
import { User } from 'types/user';

type Props = {
  open: boolean;
  userInfo?: User;
  currentDirectoryRoleModify?: DirectoryRoleUserInfo;
  handleCloseDialog: () => void;
};

export const DisabledChangePermissionModal: FC<Props> = ({
  open,
  userInfo,
  currentDirectoryRoleModify,

  handleCloseDialog,
}) => {
  const isMe = currentDirectoryRoleModify?.user_id === userInfo?.id;
  const name = isMe
    ? 'あなた'
    : `${currentDirectoryRoleModify?.user.fullname}さん`;

  return (
    <AlertDialog
      open={open}
      title={`${name}の権限を閲覧者に変更できません。`}
      content="閲覧者に変更する場合は、親フォルダの共有設定を編集してください。"
      cancelButton={
        <Button
          onClick={handleCloseDialog}
          sx={{
            color: CommonColors.black,
            ':hover': { bgcolor: CommonColors.lightGray },
          }}
        >
          閉じる
        </Button>
      }
      confirmButton={<></>}
    />
  );
};
