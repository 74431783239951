import { LoadAnimation } from '@components/molecules';
import { selectToken } from '@reducers/auth/selectors';
import { useAppSelector } from '@store/index';
import { FC } from 'react';
import { Error } from './error';
import { ThreeCanvas } from './canvas';
import { View as ViewParams } from './view_set';

type Props = {
  url?: string;
  isLoading: boolean;
  isError: boolean;
  viewParams?: ViewParams;
  finishLoading: () => void;
  handleClose: () => void;
  refetchThreeDPlanogram: () => Promise<void>;
  isViewPrint: boolean;
};

export const ThreeDPlanogram: FC<Props> = ({
  url,
  isLoading,
  isError,
  viewParams,
  finishLoading,
  handleClose,
  refetchThreeDPlanogram,
  isViewPrint,
}) => {
  const token = useAppSelector(selectToken);
  if (isError) {
    return (
      <Error
        message="3Dビューの生成時にエラーが発生しました。"
        handleClick={refetchThreeDPlanogram}
      />
    );
  }

  return (
    <>
      {url && token && viewParams && (
        <ThreeCanvas
          isLoading={isLoading}
          idToken={token}
          gltfUrl={url}
          finishLoading={finishLoading}
          viewParams={viewParams}
          isViewPrint={isViewPrint}
        />
      )}
      {isLoading && <LoadAnimation handleClose={handleClose} />}
    </>
  );
};
