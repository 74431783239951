import { objectToSnake } from 'ts-case-convert';
import { paths } from 'types/api';
import {
  baseApi,
  KeysToCamelcase,
  DirectoryRolesTags,
  PlanogramDirectoryTags,
  RealogramDirectoryTags,
} from '../baseApi';

export type GetDirectoryRolesParams = KeysToCamelcase<
  paths['/api/v1/directory_roles']['get']['parameters']['query']
>;
type GetDirectoryRolesResponse =
  paths['/api/v1/directory_roles']['get']['responses']['200']['content']['application/json'];

type AddDirectoryRolesResponse =
  paths['/api/v1/directory_roles']['post']['responses']['200']['content']['application/json'];
type AddDirectoryRolesParams = KeysToCamelcase<
  paths['/api/v1/directory_roles']['post']['requestBody']['content']['application/json']
>;

type UpdateDirectoryRolesResponse =
  paths['/api/v1/directory_roles/{directory_role_id}']['put']['responses']['200']['content']['application/json'];
type UpdateDirectoryRolesParams = KeysToCamelcase<
  paths['/api/v1/directory_roles/{directory_role_id}']['put']['parameters']['path'] &
    paths['/api/v1/directory_roles/{directory_role_id}']['put']['requestBody']['content']['application/json']
>;

type DeleteDirectoryRolesResponse =
  paths['/api/v1/directory_roles/{directory_role_id}']['delete']['responses']['200']['content']['application/json'];
type DeleteDirectoryRolesParams = KeysToCamelcase<
  paths['/api/v1/directory_roles/{directory_role_id}']['delete']['parameters']['path']
>;

export const directoryRolesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    listDirectoryRoles: builder.query<
      GetDirectoryRolesResponse,
      GetDirectoryRolesParams
    >({
      query: (params) => ({
        url: 'directory_roles',
        params: params ? objectToSnake(params) : undefined,
      }),
      providesTags: [DirectoryRolesTags.ListDirectoryRole],
    }),

    addDirectoryRole: builder.mutation<
      AddDirectoryRolesResponse,
      AddDirectoryRolesParams
    >({
      query: (params) => ({
        url: `directory_roles`,
        method: 'POST',
        body: {
          user_id: params.userId,
          role: params.role,
          directory_id: params.directoryId,
          directory_type: params.directoryType,
        },
      }),
      invalidatesTags: [DirectoryRolesTags.ListDirectoryRole],
    }),

    updateDirectoryRole: builder.mutation<
      UpdateDirectoryRolesResponse,
      UpdateDirectoryRolesParams
    >({
      query: (params) => ({
        url: `directory_roles/${params.directoryRoleId}`,
        method: 'PUT',
        body: {
          role: params.role,
        },
      }),
      invalidatesTags: [
        DirectoryRolesTags.ListDirectoryRole,
        DirectoryRolesTags.UpdateDirectoryRole,
        PlanogramDirectoryTags.ListPlanogramDirectory,
        RealogramDirectoryTags.ListRealogramDirectory,
      ],
    }),

    deleteDirectoryRole: builder.mutation<
      DeleteDirectoryRolesResponse,
      DeleteDirectoryRolesParams
    >({
      query: (params) => ({
        url: `directory_roles/${params.directoryRoleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        DirectoryRolesTags.ListDirectoryRole,
        PlanogramDirectoryTags.ListPlanogramDirectory,
        RealogramDirectoryTags.ListRealogramDirectory,
      ],
    }),
  }),
});

export const {
  useListDirectoryRolesQuery,
  useAddDirectoryRoleMutation,
  useUpdateDirectoryRoleMutation,
  useDeleteDirectoryRoleMutation,
} = directoryRolesApi;
