import { theme } from 'theme';
import { ColumnType, StatisticType } from '../statisticBox';

export const getTypographyStyle = (isEmpty: boolean) => {
  return {
    color: isEmpty
      ? theme.palette.textBlack.disabled
      : theme.palette.textBlack.primary,
    ...(isEmpty ? theme.typography.body3 : theme.typography.buttonText1),
  };
};

export const getDiffValueWithSign = (
  statisticType: StatisticType,
  value: string
) => {
  if (Number.isNaN(parseInt(value))) return value;
  if (value === '0') {
    return `±0`;
  }
  switch (statisticType) {
    case 'increase':
      return `+${value}`;
    case 'decrease':
      return `${value}`;
    case 'neutral':
      return `${value}`;
  }
};

export const getColor = (
  columnType: ColumnType,
  statisticType: StatisticType,
  diffValue: string,
  title: string
) => {
  if (
    diffValue === '0' ||
    diffValue === '' ||
    title === 'アイテム数' ||
    title === '段数' ||
    title === 'カゴ数'
  )
    return theme.palette.textBlack.secondary;
  switch (columnType) {
    case 'positive':
      if (statisticType === 'increase') {
        return theme.palette.confidence.high;
      }
      if (statisticType === 'decrease') {
        return theme.palette.confidence.low;
      }
      return theme.palette.textBlack.secondary;
    case 'negative':
      if (statisticType === 'increase') {
        return theme.palette.confidence.low;
      }
      if (statisticType === 'decrease') {
        return theme.palette.confidence.high;
      }

      return theme.palette.textBlack.secondary;
    case 'neutral':
      return theme.palette.textBlack.secondary;
  }
};
