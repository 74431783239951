import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const BowlRightIcon: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 80 80"
      sx={{ height: props.height, width: props.width }}
      fill="none"
    >
      <path
        d="M6 29H76L67.2321 46.7699C66.5583 48.1355 65.1677 49 63.645 49H18.355C16.8323 49 15.4417 48.1355 14.7679 46.7699L6 29Z"
        fill="white"
      />
      <path
        d="M4 28L1 34"
        stroke="#E6E6E6"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <rect x="3" y="27" width="76" height="2" rx="1" fill="white" />
    </SvgIcon>
  );
};
