import { ErrorOutline } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  cardMinHeight?: string;
};

export const FailedGetMostHighScoreCard: FC<Props> = ({
  cardMinHeight = '76px',
}) => {
  return (
    <Box
      component="div"
      border={`1px solid ${theme.palette.dividerBlack.medium}`}
      borderRadius={0.5}
      display="flex"
      alignItems="center"
      flexDirection="row"
      justifyContent="flex-start"
      py={1}
      px={1.5}
      gap={2}
      minHeight={cardMinHeight}
    >
      <Box
        component="div"
        pb={2.5}
        width="16px"
        height="16px"
        display="flex"
        justifyContent="center"
      >
        <ErrorOutline
          sx={{
            color: `${theme.palette.icons.secondary}`,
          }}
        />
      </Box>
      <Box component="div" width="100%" pr={1}>
        <Typography variant="body3" color={theme.palette.textBlack.secondary}>
          最も確信度の高い商品が取得できませんでした。
        </Typography>
      </Box>
    </Box>
  );
};
