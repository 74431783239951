import { FC } from 'react';
import { Box, List } from '@mui/material';
import { ChangeStep, RealogramType } from 'types/realogram';
import { RealogramTypeSelectButton } from './realogramTypeSelectButton';
import { StepLayout } from '../stepLayout';

type Props = {
  changeStep: ChangeStep;
  selectedRealogramType: RealogramType;
  isSetSelectedRealogramType: (realogarmType: RealogramType) => void;
  isFlat: boolean;
  isXs: boolean;
};

export const StepOne: FC<Props> = ({
  changeStep,
  selectedRealogramType,
  isSetSelectedRealogramType,
  isFlat,
  isXs,
}) => {
  return (
    <StepLayout
      changeStep={changeStep}
      selectedRealogramType={selectedRealogramType}
      currentStep="one"
      isFlat={isFlat}
      title="新規スキャン  撮影するゴンドラを選択"
    >
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={10}
        my={{
          xs: 3,
          md: 0,
        }}
      >
        <List sx={{ gap: 2 }}>
          <Box
            component="div"
            display="flex"
            justifyContent="space-between"
            gap={2}
          >
            <RealogramTypeSelectButton
              realogramType="normal"
              isSameTypeSelected={selectedRealogramType === 'normal'}
              isSetSelectedRealogramType={isSetSelectedRealogramType}
            />
            {!isXs && (
              <RealogramTypeSelectButton
                realogramType="flat"
                isSameTypeSelected={selectedRealogramType === 'flat'}
                isSetSelectedRealogramType={isSetSelectedRealogramType}
              />
            )}
          </Box>
        </List>
      </Box>
    </StepLayout>
  );
};
