import { Draggable } from '@components/organisms/draggable/draggable';
import { ProductCard } from '@components/organisms/productCard/productCard';
import { Grid } from '@mui/material';
import { FC } from 'react';
import { Product } from 'types/common';
import { ItemTypes } from 'types/rack';

type Props = {
  products?: Product[];
  isCompareMode: boolean;
  selectedItemId: number;
  handleClick: (item: Product) => void;
};

export const RecentProductsList: FC<Props> = ({
  products,
  isCompareMode,
  selectedItemId,
  handleClick,
}) => {
  return (
    <Grid container spacing={{ xs: 1 }} columns={{ xs: 12 }}>
      {products?.map((item) => {
        return (
          <Grid
            onClick={() => {
              handleClick(item);
            }}
            item
            key={item.id}
            xs={isCompareMode ? 6 : 4}
          >
            <Draggable data={{ product: item }} itemType={ItemTypes.ITEM}>
              <ProductCard
                isCompareMode={isCompareMode}
                product={item}
                isSelected={item.id === selectedItemId}
              />
            </Draggable>
          </Grid>
        );
      })}
    </Grid>
  );
};
