import { FolderMenu } from '@components/molecules/realogramsTableMenu/fragments/folderMenu';
import { SharePermissionMenu } from '@components/molecules/realogramsTableMenu/fragments/sharePermissionMenu';
import { Create, Delete } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { FC } from 'react';

type Props = {
  anchorEl: null | HTMLElement;
  isActionsAllowed: boolean;
  isStarred: boolean;
  handleClose: () => void;
  handleEdit: VoidFunction;
  handleDelete: VoidFunction;
  handleFavoriteClick: VoidFunction;
  handleSharePermission: VoidFunction;
};

export const StoreBayTableMenu: FC<Props> = ({
  anchorEl,
  isActionsAllowed,
  isStarred,
  handleClose,
  handleEdit,
  handleDelete,
  handleFavoriteClick,
  handleSharePermission,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      <MenuItem onClick={handleDelete} disabled={!isActionsAllowed}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <ListItemText>削除</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleEdit} disabled={!isActionsAllowed}>
        <ListItemIcon>
          <Create />
        </ListItemIcon>
        <ListItemText>編集</ListItemText>
      </MenuItem>
      <FolderMenu
        isStarred={isStarred}
        handleFavoriteClick={handleFavoriteClick}
      />
      <SharePermissionMenu
        //TODO: update to use ability
        isDisabled={!isActionsAllowed}
        handleSharePermission={handleSharePermission}
      />
    </Menu>
  );
};
