import { FC } from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { theme } from 'theme';

type Props = {
  title?: string;
  message?: string;
  color?: string;
  titleStyle?: SxProps;
  subTitleStyle?: SxProps;
  width?: string;
  height?: string;
  isErrorIcon?: boolean;
};

export const EmptyResult: FC<Props> = ({
  title = '結果がありません',
  message = '条件を変更してください。',
  color = '#9E9E9E',
  titleStyle = {
    fontWeight: 700,
    letterSpacing: '0.15px',
    mb: 1,
  },
  subTitleStyle = {
    fontSize: 14,
    letterSpacing: '0.15px',
    mb: 1,
  },
  width = '80px',
  height = '80px',
  isErrorIcon = false,
}) => {
  return (
    <Box
      component="div"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
      sx={{ color }}
    >
      {isErrorIcon && (
        <ErrorIcon
          sx={{
            width,
            height,
            mb: 1,
            color: theme.palette.icons.disabled,
          }}
        />
      )}
      <Typography
        variant="subtitle1"
        sx={{
          ...titleStyle,
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          ...subTitleStyle,
        }}
      >
        {message}
      </Typography>
    </Box>
  );
};

/**
 * planogramDirectoryEmptyMessage
 * planogramDirectory用EmptyMessage
 * @param hasQueries
 * @param isParentTypeDirectory
 * @returns
 */
export const planogramDirectoryEmptyMessage = (
  hasQueries: boolean,
  isParentTypeDirectory: boolean
) => {
  if (hasQueries) return '条件を変更してください。';

  return isParentTypeDirectory
    ? '左上の「棚割作成」・「フォルダ作成」ボタンから追加してください。'
    : '条件を変更してください。';
};
