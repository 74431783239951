import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const PreviewIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2H2V22H10.5738C9.6031 21.4937 8.73784 20.8131 8.01896 20H4V16H6.6829C7.03751 15.2724 7.48836 14.6001 8.01896 14H4V10H14V11.0248C14.2205 11.0084 14.4432 11 14.6679 11C15.1208 11 15.5656 11.0339 16 11.0994V2ZM14 4V8H4V4H14ZM7.33008 17C8.48341 14.0733 11.3301 12 14.6634 12C17.9967 12 20.8434 14.0733 21.9967 17C20.8434 19.9267 17.9967 22 14.6634 22C11.3301 22 8.48341 19.9267 7.33008 17ZM11.3301 17C11.3301 18.84 12.8234 20.3333 14.6634 20.3333C16.5034 20.3333 17.9967 18.84 17.9967 17C17.9967 15.16 16.5034 13.6667 14.6634 13.6667C12.8234 13.6667 11.3301 15.16 11.3301 17ZM12.6634 17C12.6634 15.8933 13.5567 15 14.6634 15C15.7701 15 16.6634 15.8933 16.6634 17C16.6634 18.1067 15.7701 19 14.6634 19C13.5567 19 12.6634 18.1067 12.6634 17Z"
        fill="black"
        fillOpacity="0.65"
      />
    </SvgIcon>
  );
};
