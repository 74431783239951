import { Box, Typography } from '@mui/material';
import { theme } from '../../../../theme';
import GroupsIcon from '@mui/icons-material/Groups';
import { FC } from 'react';

type Props = {
  name?: string;
};
export const NoticeScopePublic: FC<Props> = ({ name }) => {
  return (
    <>
      <Typography
        color={theme.palette.textBlack.primary}
        noWrap
        fontSize={16}
        sx={{
          fontWeight: '700',
        }}
      >
        公開範囲
      </Typography>
      <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
        <GroupsIcon htmlColor={theme.palette.icons.primary} />
        <Typography
          color={theme.palette.textBlack.primary}
          noWrap
          fontSize={16}
          sx={{
            fontWeight: '400',
            lineHeight: '16px',
            marginLeft: '5px',
            color: '#222222',
          }}
        >
          ユーザー全員に公開
        </Typography>
      </Box>
      <Typography
        color={theme.palette.textBlack.primary}
        fontSize={14}
        sx={{
          marginTop: '8px',
          marginBottom: '24px',
          fontWeight: '400',
          lineHeight: '21px',
          color: '#00000099',
        }}
      >
        [{name ?? ''}
        ]ユーザー全員が閲覧可能です。編集権限を付与されたユーザーのみ編集可能です。
      </Typography>
    </>
  );
};
