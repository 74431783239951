import { useProductImage } from '@hooks/useImage';
import { convertMeterToPixel, getFaceFrontById } from '@utils/planogram';
import { hasProductTag } from '@utils/product';
import { ImageProps } from 'next/legacy/image';
import { FC } from 'react';
import { FaceFrontId, ImageSizeType, Product } from 'types/common';
import { DynamicImage } from '../dynamicImage/dynamicImage';
import { DummyProduct } from './fragments/dummyProduct';

//NOTE: このコンポーネントで指定しているかつ上書きされないプロパティは型から除外する
type Props = Omit<ImageProps, 'src' | 'error' | 'isLoading'> & {
  product?: Product;
  faceFrontId?: FaceFrontId; //エディタでcompartmentの回転に必要
  imageSizeType?: ImageSizeType;
  baseSize?: number;
  fontSize?: number;
};

export const ProductImage: FC<Props> = ({
  product,
  faceFrontId,
  imageSizeType = 'small',
  draggable = false,
  baseSize = 54,
  fontSize = 8,
  ...props
}) => {
  const faceFront = faceFrontId
    ? getFaceFrontById(faceFrontId)
    : product?.detail?.face_direction ?? 'front';
  const { image, error, isLoading } = useProductImage(
    {
      productId: product?.id ?? 0,
      faceFront,
      size: imageSizeType,
    },
    {
      skip: !product,
      assetVersion: product?.asset_version,
    }
  );

  if (!image && hasProductTag('not_3D_scanned', product?.detail?.tags)) {
    const { width, height } = calcDummyProductSize(baseSize, product);
    return (
      <DummyProduct
        product={product}
        width={width}
        height={height}
        fontSize={fontSize}
      />
    );
  }
  return (
    <DynamicImage
      src={image}
      error={error}
      isLoading={isLoading}
      draggable={draggable}
      isDisableToucCallout
      loading="lazy"
      {...props}
    />
  );
};

const calcDummyProductSize = (
  baseSize: number,
  product?: Product
): { width: number; height: number } => {
  if (!product?.shape) return { width: 0, height: 0 };
  const { width: actualWidth, height: actualHeight } =
    product.shape.size.actual;
  if (actualHeight > actualWidth) {
    const height = baseSize;
    const width =
      height *
      (convertMeterToPixel(actualWidth) / convertMeterToPixel(actualHeight));
    return { width, height };
  }
  if (actualHeight < actualWidth) {
    const width = baseSize;
    const height =
      width *
      (convertMeterToPixel(actualHeight) / convertMeterToPixel(actualWidth));
    return { width, height };
  }
  return { width: 0, height: 0 };
};
