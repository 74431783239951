import { ArrowUpwardDownwardIcon } from '@components/molecules/arrowUpwardDownwardIcon/arrowUpwardDowneardIcon';
import { ColumnProps } from '@components/organisms/dataTable/dataTable';
import {
  Box,
  Divider,
  TableCell,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { GetUsersQueryParams } from '@reducers/shelfAppsApi';
import { FC } from 'react';
import { UserOrder, UserOrderBy } from 'types/user';

type Props = {
  handleChangeOrder: (order: UserOrder) => void;
  condition: GetUsersQueryParams;
};

const headerSx = {
  padding: '0px',
};

export const columns: ColumnProps<UserOrderBy>[] = [
  {
    headerName: '名前',
    orderBy: 'fullname',
    headerSx: { ...headerSx, pl: '12px', color: 'gray', width: '240px' },
  },
  {
    headerName: 'メールアドレス',
    orderBy: 'email',
    headerSx: { ...headerSx, width: '200px' },
    hasDivider: true,
  },
  {
    headerName: 'アカウント権限',
    headerSx: { ...headerSx, width: '160px' },
    hasDivider: true,
  },
  {
    headerName: 'サービス利用',
    headerSx: { ...headerSx, width: '120px' },
    hasDivider: true,
  },
  {
    headerName: '追加日時',
    orderBy: 'created_at',
    headerSx: { ...headerSx, width: '180px' },
    hasDivider: true,
  },
];

export const UsersTableHeader: FC<Props> = ({
  condition,
  handleChangeOrder,
}) => {
  return (
    <TableRow
      sx={{
        backgroundColor: '#F6FAFF',
        width: '100%',
        height: '48px',
      }}
    >
      {columns.map((item, index) => {
        const orderBy = item.orderBy;
        const isCurrentOrderBy = condition?.firstOrder?.startsWith(
          orderBy ?? ''
        );
        // 現在のソートカテゴリと異なる時は必ずascとなる。
        const currentOrder =
          isCurrentOrderBy && condition?.firstOrder?.endsWith('desc')
            ? 'desc'
            : 'asc';
        const nextOrder =
          isCurrentOrderBy && condition?.firstOrder?.endsWith('asc')
            ? 'desc'
            : 'asc';
        return (
          <TableCell
            key={index}
            sx={item.headerSx}
            sortDirection={currentOrder}
          >
            <Box component="div" display="flex" gap="16px" alignItems="center">
              {item.hasDivider && (
                <Box component="div" display="flex" width="2px" height="14px">
                  <Divider orientation="vertical" flexItem />
                </Box>
              )}
              <Box component="div">
                {orderBy ? (
                  <TableSortLabel
                    hideSortIcon={!isCurrentOrderBy}
                    active={isCurrentOrderBy}
                    direction={currentOrder}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      '& .MuiTableSortLabel-icon': {
                        width: 20,
                        height: 20,
                      },
                      ...item.headerSx,
                    }}
                    onClick={() => {
                      handleChangeOrder(`${orderBy}_${nextOrder}`);
                    }}
                  >
                    <Typography color="gray" fontSize="14px">
                      {item.headerName}
                    </Typography>
                    {!isCurrentOrderBy && (
                      <Box component="div" width={20} height={20}>
                        <ArrowUpwardDownwardIcon />
                      </Box>
                    )}
                  </TableSortLabel>
                ) : (
                  <Typography color="gray" fontSize="14px" sx={item.headerSx}>
                    {item.headerName}
                  </Typography>
                )}
              </Box>
            </Box>
          </TableCell>
        );
      })}
    </TableRow>
  );
};
